import {gql, useQuery} from "@apollo/client";

export const JOB_SUB_QUERY = gql`
    query JOB_SUB_QUERY {
        core_job {
            id
            ext_job_number
            core_site {
                name
            }
        }
    }
`;

export const useJobSubData = () => {
    const {data, error, loading, refetch} = useQuery(JOB_SUB_QUERY);
    return {data, error, loading, refetch};

};
