import {StyledMenuItem} from "../../../action-menu";
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, IconButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import {Block} from "@mui/icons-material";
import {ISite} from "../../../../../lib/interfaces";
import {useEffect, useState} from "react";
import {ShuriStyledDialog, ShuriStyledDialogBackdrop} from "../../../dialog";
import {BaseColor, ButtonBaseColor, ButtonBaseColorLight, errorBase, InputBaseColorLight} from "../../../../config";
import {useMutation} from "@apollo/client";
import {CHANGE_SITE_STATUS_MUTATION} from "../../../../../lib/hooks";
import {useNotification} from "../../../../../lib/context";
import {ShuriSpinnerText} from "../../../loading";

interface IActivationControlProps {
    onClose: () => void;
    onRefreshSites?: () => void;
    site: ISite
}

export function ActivationControl({onClose, site, onRefreshSites}: IActivationControlProps) {
    const [open, setOpen] = useState(false);
    const {notify} = useNotification();
    const [updateSite, {loading, error}] = useMutation(CHANGE_SITE_STATUS_MUTATION, {
        variables: {
            id: site.id,
            is_enabled: !site.is_enabled,
            status_changed_at: new Date().toISOString()
        },
    });
    const verb = !site.is_enabled ? 'Enable' : 'Disable';

    useEffect(() => {
        if (error) {
            notify({
                status: "error",
                open: true,
                message: `An error occurred trying to ${verb} site, try again later!`,
            });
        }
    }, [error])
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        onClose();
    };

    const handleStatusChange = async () => {
        await updateSite({
            variables: {
                id: site.id,
                is_enabled: !site.is_enabled,
                status_changed_at: new Date().toISOString()
            },
            onCompleted(updatedSiteData) {
                if (updatedSiteData) {
                    if (onRefreshSites) {
                        onRefreshSites()
                    }
                    onClose();
                    notify({
                        status: "success",
                        open: true,
                        message: `Site ${verb}d successfully!`,
                    });
                }
            },
            onError(error) {
                notify({
                    status: "error",
                    open: true,
                    message: `An error occurred trying to ${verb} site, try again later!`,
                });
            }
        })
    }

    return (
        <>
            <StyledMenuItem onClick={handleClickOpen}>
                <ListItemIcon>
                    <Block color="secondary"/>
                </ListItemIcon>
                <ListItemText>{verb} Site</ListItemText>
            </StyledMenuItem>

            <ShuriStyledDialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                BackdropComponent={ShuriStyledDialogBackdrop}
            >
                <DialogTitle id="alert-dialog-title">
                    <IconButton sx={{
                        background: `${errorBase}50`,
                        '&:hover': {
                            background: `${errorBase}60`,
                        },
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={errorBase}
                             viewBox="0 0 256 256">
                            <path
                                d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm-8-80V80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,172Z"></path>
                        </svg>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description-title" className="shuri-dialog-title">
                        {`${verb} site?`}
                    </DialogContentText>

                    <DialogContentText className="shuri-dialog-description" id="alert-dialog-description">
                        Are you sure you would like to {verb} site, {site.name}?
                        {
                            site.is_enabled ?
                                ' Enabling this site  will move into a enabled state and will be accessible in other modules.'
                                :
                                ' Disabling this site  will move into a disabled state and will not be accessible in other modules.'
                        }
                    </DialogContentText>

                    {
                        loading &&
                        <Box sx={{
                            marginTop: '30px'
                        }}>
                            <ShuriSpinnerText
                                loadingTextSx={{
                                    color: InputBaseColorLight,
                                }}
                                loadingText={"Submiting..."}
                            />
                        </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleStatusChange}
                            variant="contained"
                            autoFocus
                            size="large"
                            sx={{
                                color: BaseColor,
                                borderColor: errorBase,
                                backgroundColor: errorBase,
                                textTransform: 'capitalize',
                                '&:hover': {
                                    background: `${errorBase}95`,
                                },
                            }}
                    >Yes, {verb}</Button>

                    <Button onClick={handleClose}
                            variant="outlined"
                            size="large"
                            sx={{
                                color: ButtonBaseColorLight,
                                borderColor: ButtonBaseColor,
                                textTransform: 'capitalize',
                                '&:hover': {
                                    borderColor: `${ButtonBaseColor}95`,
                                },
                            }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </ShuriStyledDialog>
        </>
    )
}