import {styled} from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import {
    AppDarkBackgroundColor,
    BaseColor,
    ButtonBaseColor,
    TableBackgroundColor,
    tableBorder
} from "../../config";

export const StyledTableRow = styled(TableRow)(({theme}) => ({
    color: 'white',
    borderColor: tableBorder,
    borderBottom: '1px solid',
    backgroundColor: TableBackgroundColor,

    '&.shuri-row-highlighted': {
        backgroundColor: ButtonBaseColor,
    },
    '&.shuri-child-row': {
        backgroundColor: BaseColor,
        borderColor: ButtonBaseColor,
        '.MuiTableCell-root': {
            borderColor: 'inherit',
        }
    },
    '&.shuri-child-row-head': {
        backgroundColor: AppDarkBackgroundColor,
    },
}));