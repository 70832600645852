import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  Typography
} from "@mui/material";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useForm } from "react-hook-form";

type Props = {
  user: any;
  isVisible: boolean;
  onClose: () => void;
};

const SetPasswordModal = ({ user, isVisible, onClose }: Props) => {
  const defaultValues = {
    newPassword: "",
    confirmNewPassword: ""
  };

  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({ defaultValues });

  const onSetNewPassword = async ({
    newPassword,
    confirmNewPassword
  }: typeof defaultValues) => {
    try {
      const updatedUser = await Auth.completeNewPassword(user, newPassword);
      console.log({ updatedUser });
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <Modal
      open={isVisible}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Box
        padding={5}
        width="500px"
        bgcolor="black"
        alignSelf="center"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          fontSize={25}
          color="white"
          fontWeight="bold"
          textAlign="center"
        >
          Change password
        </Typography>
        <Box
          marginY={3}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box marginY={3}>
            <InputLabel sx={{ color: "white" }}>Password</InputLabel>
            <OutlinedInput
              color="info"
              sx={{ color: "white" }}
              {...register("newPassword", {
                required: "New password is required"
              })}
              fullWidth
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword((prev) => !prev)}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOff sx={{ color: "gray" }} />
                    ) : (
                      <Visibility sx={{ color: "gray" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </Box>
          <Box marginY={3}>
            <InputLabel sx={{ color: "white" }}>Confirm Password</InputLabel>
            <OutlinedInput
              color="info"
              sx={{ color: "white" }}
              {...register("confirmNewPassword", {
                required: "Confirm password is required"
              })}
              fullWidth
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword((prev) => !prev)}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOff sx={{ color: "gray" }} />
                    ) : (
                      <Visibility sx={{ color: "gray" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </Box>
          <Button
            variant="contained"
            sx={{
              color: "black",
              width: "360px",
              alignSelf: "center",
              backgroundColor: "#4490AE"
            }}
            onClick={handleSubmit(onSetNewPassword)}
          >
            {isSubmitting ? "Loading..." : "Change password"}
          </Button>
          <Typography
            marginY={3}
            color="#5BC0E9"
            textAlign="center"
            sx={{ cursor: "pointer" }}
          >
            Back to sign in
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default SetPasswordModal;
