import {styled} from "@mui/material/styles";
import {Drawer} from "@mui/material";
import {BaseDark} from "../../config";

export const ShuriStyledDrawer = styled(Drawer)(({theme}) => ({
    position: 'relative',
    right: 0,
    left: 0,
    bottom: 0,
    top: 0,
    backgroundColor: BaseDark,
    '.MuiDrawer-paper': {
        maxWidth: '500px'
    },
    '.shuri-drawer-wrapper': {
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        backgroundColor: BaseDark,
    },
    '.shuri-drawer-content': {
        flex: 1,
        backgroundColor: BaseDark,
    },
    '.shuri-drawer-actions': {
        textTransform: 'capitalise',
        backgroundColor: BaseDark,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxHeight: '70px',
        borderTop: '1px solid #373637',
        paddingTop: '20px'
    }
}));