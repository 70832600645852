import {Dialog, DialogContent} from "@mui/material";
import {ReactNode} from "react";

interface ICameraDialogProps {
    open: boolean;
    onClose: () => void;
    children: ReactNode;
}

export function CameraDialog({open, onClose, children}: ICameraDialogProps) {
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent sx={{
                padding: 0
            }}>
                {children}
            </DialogContent>
        </Dialog>
    )
}