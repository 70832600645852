import {
    AccordionDetails,
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Grid,
    Typography
} from "@mui/material";
import {SearchBox} from "../search-box";
import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from "react";
import {ICameraStream, useShuriPagination, useSiteCameras} from "../../../lib";
import {ShuriStyledAccordion, ShuriStyledAccordionSummary} from "../accordion";
import {ExpandMore} from "@mui/icons-material";
import {Core_Camera, Core_Site} from "../../../generated/graphql";
import {ShuriVideoMeta, ShuriVideoPlayer} from "../video";
import {ShuriSpinnerText} from "../loading";

interface ICameraCard {
    core_site: Core_Site
    core_camera: Core_Camera
    checked: boolean
    onChange: (core_camera: Core_Camera, core_site: Core_Site, checked: boolean) => void;
}

interface IAddCameraFormProps {
    selected: ICameraStream[]
    onCameraAdd: (core_camera: Core_Camera, core_site: Core_Site, checked: boolean) => void
}

export function AddCameraForm({selected, onCameraAdd}: IAddCameraFormProps) {
    const [filterValue, setFilterValue] = useState<string>('');
    const [siteCameras, setSiteCameras] = useState<Core_Site[]>([]);
    const [siteCamerasCount, setSiteCamerasCount] = useState<number>(0);
    const {page, rowsPerPage, handleChangePage} = useShuriPagination(10);
    const {data, loading, error} = useSiteCameras(
        page,
        rowsPerPage,
        filterValue ? filterValue : undefined,
    );
    const [expanded, setExpanded] = useState<string | false>('0');

    useEffect(() => {
        if (data) {
            const newSites: Core_Site[] = data.core_site ?? [];
            setSiteCamerasCount(data?.core_site_aggregate?.aggregate?.count ?? 0)

            if (filterValue) {
                return setSiteCameras(newSites)
            }

            setSiteCameras((prevStreams) => {
                const uniqueSiteIds = new Set(prevStreams.map(site => site.site_id));
                const filteredNewSites = newSites.filter(site => !uniqueSiteIds.has(site.site_id));
                return [...prevStreams, ...filteredNewSites];
            });
        }
    }, [data]);

    const loadMoreSites = () => {
        handleChangePage(null, page + 1)
    }

    const handleChange =
        (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFilterValue(event.target.value);
    }

    const CameraCard = ({core_camera, checked, onChange, core_site}: ICameraCard) => {
        const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(core_camera, core_site, event.target.checked);
        };
        return (
            <Card sx={{
                position: "relative",
                maxWidth: 300,
            }}>
                <CardContent sx={{
                    position: "relative",
                    height: '150px',
                    borderRadius: '8px',
                }}>
                    <ShuriVideoMeta
                        description={`${core_camera?.camera_name ?? ''}`}/>
                    <ShuriVideoPlayer
                        key={core_camera?.camera_id}
                        url={'xyz.mp4'}
                        h={'100px'}
                    />
                </CardContent>
                <Checkbox
                    sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        zIndex: 20
                    }}
                    checked={checked}
                    onChange={handleCheckboxChange}
                    color="primary"
                />
            </Card>
        );
    };

    return (
        <Box>
            <SearchBox
                placeholder="Search"
                onChange={handleInputChange}
                value={filterValue}
                id={'search-camera'}
            />

            <Box sx={{
                paddingTop: '20px',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Box sx={{
                    flex: 1,
                    paddingBottom: '15px'
                }}>
                    <Box sx={{
                        marginBottom: (error || loading) ? '15px' : '0'
                    }}>
                        {
                            error &&
                            <Alert
                                severity="error"
                                variant="outlined">
                                <>
                                    {error} — <strong>an error occurred!</strong>
                                </>
                            </Alert>
                        }
                        {
                            loading &&
                            <ShuriSpinnerText
                                loadingText={"Loading please wait..."}
                            />
                        }

                    </Box>

                    {
                        siteCameras.map(site => (
                            <ShuriStyledAccordion
                                sx={{
                                    marginBottom: '15px'
                                }}
                                key={site.site_id}
                                expanded={expanded === site.site_id}
                                onChange={handleChange(site.site_id ?? '')}>
                                <ShuriStyledAccordionSummary
                                    expandIcon={<ExpandMore/>}
                                    aria-controls="camera-content"
                                    id="camera-header">
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Typography>{site?.name ?? ''} - {site.core_boxes[0]?.core_cameras?.length ?? 0} Cameras </Typography>
                                    </Box>
                                </ShuriStyledAccordionSummary>

                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {
                                            site.core_boxes[0]?.core_cameras?.map(camera => (
                                                <Grid item xs={12} md={6} key={camera.camera_id}>
                                                    <CameraCard
                                                        core_site={site}
                                                        core_camera={camera}
                                                        checked={selected?.some(
                                                            (selectedCamera) =>
                                                                selectedCamera.core_camera.camera_id === camera.camera_id
                                                        )}
                                                        onChange={onCameraAdd}
                                                    />
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </AccordionDetails>
                            </ShuriStyledAccordion>
                        ))
                    }
                </Box>

                {
                    siteCamerasCount > siteCameras.length  &&
                    <Button onClick={loadMoreSites}
                            variant="outlined"
                            color="primary"
                            sx={{textTransform: 'capitalize'}}>
                        Load More
                    </Button>
                }

            </Box>
        </Box>
    )
}