import {gql, useQuery} from "@apollo/client";
import {perPage} from "../../../config";
import {uuid} from "aws-sdk/clients/customerprofiles";

export const ADD_CAMERA_STREAMS_MUTATION = gql`
    mutation ADD_CAMERA_STREAMS_MUTATION(
        $objects: [core_stream_layout_insert_input!]!
    ){
        insert_core_stream_layout(
            objects: $objects
            on_conflict: {
                constraint: core_stream_layout_camera_id_user_uuid_site_id_key
                update_columns: [
                    site_id
                    camera_id
                    user_uuid
                ]
            }
        ){
            returning {
                site_id
                camera_id
                user_uuid
            }
        }
    }
`;

export const REMOVE_CAMERA_STREAMS_MUTATION = gql`
    mutation REMOVE_CAMERA_STREAMS_MUTATION(
        $site_id: bigint,
        $camera_id: String,
        $user_uuid: uuid
    ){
        delete_core_stream_layout(where: {user_uuid: {_eq: $user_uuid}, site_id: {_eq: $site_id }, camera_id: {_eq: $camera_id}}) {
            affected_rows
        }
    }
`;

export const CAMERA_STREAMS_QUERY = gql`
    query CAMERA_STREAMS_QUERY($user_uuid: uuid) {
        core_stream_layout(
            where: {
                user_uuid: {_eq: $user_uuid}
                core_site: {is_enabled: {_eq: true}}
            }
        ) {
            id
            site_id
            user_uuid
            camera_id
            core_camera {
                camera_id
                camera_name
                video_stream_name
            }
            core_site {
                id
                site_id
                name
                core_sitestatus {
                    id
                    site_status
                }
            }
        }
    }
`;

export const useCameraStreams = (
    page = 1,
    limit = perPage,
    user_uuid: uuid
) => {
    // const offset = page * limit;
    const {data, loading, error, refetch} = useQuery(CAMERA_STREAMS_QUERY, {
        variables: {
            user_uuid
        },
        fetchPolicy: 'network-only',
    });
    return {data, loading, error, refetch};
}