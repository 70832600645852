import { Content, MenuBar, SearchBox } from "../../../components";
import { Box, IconButton, Typography, Button } from "@mui/material";
import { ArrowBack, KeyboardArrowRight, FilterAlt } from "@mui/icons-material";
import { AppBackgroundColor } from "../../../config";
import { useRequestsAccess } from "../../../../lib/hooks";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TablePagination, TableFooter } from "@mui/material";
import { useState } from "react";

export function AllAccessRequestsPage() {
  const { data, error, loading } = useRequestsAccess();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, data?.core_requestaccess.length - page * rowsPerPage);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  if (error) {
    return <h1>An error occurred </h1>;
  }
  if (loading) {
    return <p>Loading ....</p>;
  }

  return (
    <Box>
      <MenuBar>
        <IconButton color="secondary" onClick={() => window.history.back()}>
          <ArrowBack />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 0.98, paddingLeft: 10 }}
        >
          Access Requests
        </Typography>
      </MenuBar>

      <Content>
        <Box
          sx={{
            height: "auto",
            backgroundSize: "cover",
            backgroundColor: AppBackgroundColor,
            margin: 4,
          }}
        >
          <Box
            sx={{
              color: "white",
              marginBottom: 2,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <SearchBox placeholder="Search ..." />
            <Button
              startIcon={<FilterAlt />}
              variant="contained"
              sx={{
                alignSelf: "center",
                borderRadius: "4px",
                backgroundColor: "#ffffff26",
                color: "white",
              }}
            >
              Filter
            </Button>
          </Box>

          <Table>
            <TableHead sx={{ backgroundColor: "#110D19" }}>
              <TableRow
                sx={{
                  padding: 4,
                }}
              >
                <TableCell sx={{ color: "#4C576B" }}> Job</TableCell>
                <TableCell sx={{ color: "#4C576B" }}> Personale</TableCell>
                <TableCell sx={{ color: "#4C576B" }}>Site</TableCell>
                <TableCell sx={{ color: "#4C576B" }}>Request Date</TableCell>
                <TableCell sx={{ color: "#4C576B" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: "#302D38" }}>
              {data?.core_requestaccess
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((rqa: any) => {
                  return (
                    <TableRow key={rqa.id}>
                      <TableCell sx={{ color: "white" }}>
                        {rqa.core_vist.core_job.request_date}
                      </TableCell>        
                       <TableCell sx={{ color: "white" }}>
                        {rqa.core_vist.core_job.ext_job_number}
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        {rqa.core_personnelassigneds.core_noneuserpersonale.first_name}
                        {rqa.core_personnelassigneds.core_noneuserpersonale.last_name}
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        {rqa.core_site.core_sitestatus.site_status}
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        {rqa.core_sitestatus.name}
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        <KeyboardArrowRight />
                      </TableCell>
                    </TableRow>
                  );
                })} 
            </TableBody>
            <TableFooter>
              <TableRow sx={{ border: 0, m: 1 }}>
                <TablePagination
                  sx={{ color: "white" }}
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={6}
                  count={data?.core_requestaccess.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
          <Box>
            <Box sx={{ padding: "0 1rem" }}>
              <Typography sx={{ color: "#fff", fontSize: 28 }}></Typography>
            </Box>
          </Box>
        </Box>
      </Content>
    </Box>
  );
}
