import React, {useState, useRef, SyntheticEvent, useEffect, RefObject} from 'react';
import './shuri-player.scss';
import {Pause, PlayArrow, Stop} from "@mui/icons-material";
import {VideoBackground} from "../../../../assets";
import {IPlayerProps} from "../../../../lib";

interface IShuriPlayerSkinProps extends IPlayerProps {
    live: boolean;
    error?: string;
    loading?: boolean;
    play?: boolean;
    mediaRef: RefObject<HTMLVideoElement>,
}

export const ShuriPlayerSkin: React.FC<IShuriPlayerSkinProps> = (props) => {
    const {onPlay, onStop, live, url, error, loading, play, onLoaded, mediaRef, empty} = props;
    const [isPlaying, setIsPlaying] = useState(play);
    const [showControls, setShowControls] = useState(false);
    const playerRef = useRef<HTMLVideoElement>(null);
    const videoRef = mediaRef ?? playerRef;
    let controlsTimeout: NodeJS.Timeout;

    const [progress, setProgress] = useState(0);
    const [videoError, setVideoError] = useState<string | undefined>(error);

    useEffect(() => {
        validateProps();
    }, []);

    useEffect(() => {
        validateProps();
    }, [live, url, onPlay, onStop]);

    const validateProps = () => {
        if (live && (!onPlay || !onStop)) {
            setVideoError("Live video parameters are not met.");
        }
    };

    useEffect(()=> {
        setIsPlaying(play)
    }, [play])

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener('timeupdate', updateProgress);
            videoRef.current.addEventListener('mousemove', handleMouseMove);
        }
        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('timeupdate', updateProgress);
                videoRef.current.removeEventListener('mousemove', handleMouseMove);
            }
        };
    }, []);

    const updateProgress = () => {
        if (videoRef.current) {
            const currentTime = videoRef.current.currentTime;
            const duration = videoRef.current.duration;
            setProgress((currentTime / duration) * 100);
        }
    };

    const togglePlay = () => {
        if (isPlaying){
            playVideo();
            return;
        }
        if (live && onPlay) {
            onPlay();
        }
    };

    const playVideo = () => {
        if (videoRef.current?.paused) {
            videoRef.current?.play();
            setIsPlaying(true);
        } else {
            videoRef.current?.pause();
            setIsPlaying(false);
        }
    }

    const toggleStop = () => {
        if (live && onStop && isPlaying) {
            onStop();
        }
        stopVideo();
    };

    const stopVideo = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
            setIsPlaying(false);
        }
    }

    const onVideoClick = (e: SyntheticEvent) => {
        e.preventDefault();
        playVideo();
    };

    const handleMouseMove = () => {
        if (!showControls) {
            setShowControls(true);
        }

        clearTimeout(controlsTimeout);

        controlsTimeout = setTimeout(() => {
            setShowControls(false);
        }, 3000);
    };


    return (
        <div className={`video-player ${isPlaying ? 'playing' : ''}`}>
            {/*<div className="cover"></div>*/}
            <video
                ref={videoRef}
                key={url}
                poster={!empty ? VideoBackground : ''}
                src={url}
                onClick={onVideoClick}
                onLoadedData={onLoaded}
                autoPlay
            />

            {!empty && showControls && (
                <div className={`controls ${isPlaying ? 'playing' : ''}`}>
                    <button className="play-button" onClick={togglePlay}>
                        {isPlaying ? <Pause sx={{fontSize: '50px'}} fontSize="large"/> : <PlayArrow sx={{fontSize: '50px'}} fontSize="large"/>}
                    </button>
                    <div className="progress-bar">
                        <div className="progress" style={{width: `${progress}%`}}/>
                    </div>

                    <button className="stop-button" onClick={toggleStop}>
                        <Stop sx={{fontSize: '50px'}} fontSize="large"/>
                    </button>
                </div>
            )}

            {!empty && !isPlaying && (
                <button className="center-play-button" onClick={togglePlay}>
                    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M36 72C55.8823 72 72 55.8823 72 36C72 16.1177 55.8823 0 36 0C16.1177 0 0 16.1177 0 36C0 55.8823 16.1177 72 36 72ZM30.375 49.2049L50.625 37.8864C52.125 37.048 52.125 34.952 50.625 34.1136L30.375 22.7951C28.875 21.9567 27 23.0047 27 24.6815L27 47.3185C27 48.9953 28.875 50.0433 30.375 49.2049Z"
                              fill="#5BC0E9" fillOpacity="0.5"/>
                    </svg>
                </button>
            )}

        </div>
    );

};
