import { gql, useQuery } from "@apollo/client";

export const CALLOUTS_MESSAGES_QUERY = gql`
query MyQuery($callout_id: bigint) {
  core_calloutmessage(where: {callout_id: {_eq: $callout_id}}, order_by: {created_on: asc_nulls_first}) {
    message_body
    message_id
    outgoing
    id
    callout_id
    created_on
    core_user {
      first_name
      email
      last_name
    }
  }
}
`;

export const useCalloutMessages = (callout_id: number) => {
  const { data, loading, error, refetch } = useQuery(CALLOUTS_MESSAGES_QUERY, {
    variables: { callout_id },
  });
  return { data, loading, error, refetch };
};
