import { Box, Button } from "@mui/material";
import { AppBackgroundColor } from "../../../../config";
import { useShuriPagination } from "../../../../../lib/hooks";
import {
  Content,
  PageAppBar,
  ShuriStyledFilterButtons,
  SitesTable,
} from "../../../../components";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSiteExplorer } from "../../../../../lib/hooks/site/site.explorer.hook";
import { useApolloClient } from "@apollo/client";

export function SitesExplorer() {
  const client = useApolloClient();
  const { page, rowsPerPage, handleChangePage } = useShuriPagination(10);
  const { allAtRisk, error, allOnline, offlinesites, allDisabledSites, data} = useSiteExplorer(
    client,
    rowsPerPage,
    page
  );
  const { filter } = useParams();
  const handleOnFilter = (value: any) => {
    window.location.href = `/sites/${value}`;
  };
  const handleGetSites = async (newPage:number=0) => {
    if (filter === "atrisk") {
      await allAtRisk(newPage);
    } else if (filter === "online") {
      await allOnline(newPage);
    }else if (filter === "disabled") {
      await allDisabledSites(newPage);
    } else if (filter === "offline") {
     await offlinesites(newPage);
    }
  };
  const handleChangeNext=(event: unknown, newPage: number)=>{
    handleChangePage(event,newPage )
    console.log(newPage)
    let offset =newPage ===0?0:(newPage+1)*rowsPerPage;
    console.log(offset)
    handleGetSites(offset)
  }
  useEffect(() => {
    handleGetSites();
  }, []);
  const handleRefreshSites = async () => {
    await handleGetSites();
  };

  const RenderSitesData = () => {
    if (error) {
      return <h1>An error occurred </h1>;
    }
    if (data === null) {
      return <p>Loading ....</p>;
    }
    return (
      <SitesTable
        data={data}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangeNext}
        onRefresh={handleRefreshSites}
      />
    );
  };

  return (
    <Box>
      <PageAppBar
        title="Site Explorer"
        sub={`Explore your ${filter==='atrisk'?'at risk':filter}  sites and details here.`}
      />
      <Content>
        <Box
          sx={{
            height: "auto",
            backgroundSize: "cover",
            backgroundColor: AppBackgroundColor,
            margin: 4,
          }}
        >
          <ShuriStyledFilterButtons
            variant="outlined"
            aria-label="outlined button group"
            color="secondary"
            size="large"
          >
            <Button
              onClick={() => handleOnFilter("online")}
              className={filter === "online" ? "selected" : ""}
            >
              Online
            </Button>
            <Button
              onClick={() => handleOnFilter("offline")}
              className={filter === "offline" ? "selected" : ""}
            >
              Offline
            </Button>
            <Button
              onClick={() => handleOnFilter("disabled")}
              className={filter === "disabled" ? "selected" : ""}
            >
              Disabled
            </Button>
            <Button
              onClick={() => handleOnFilter("atrisk")}
              className={filter === "atrisk" ? "selected" : ""}
            >
              At Risk
            </Button>
          </ShuriStyledFilterButtons>
          <Box
            sx={{
              color: "white",
              marginBottom: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          ></Box>
          <Box
            sx={{
              "@media (max-width: 600px)": {
                objectFit: "scale-down",
                display: "flex",
                width: "100%",
                margin: 0,
                overflowX: "hidden",
              },
            }}
          >
            <Box sx={{ width: "100%", overflowX: "auto" }}>
              <RenderSitesData />
            </Box>
          </Box>
        </Box>
      </Content>
    </Box>
  );
}
