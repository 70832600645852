import gql from "graphql-tag";
import {useQuery} from "@apollo/client";

export const COMPANY_SUMMARY = gql`
    query {
        core_disruptions_aggregate {
            aggregate {
            count
            }
        }
        core_visit_aggregate {
            aggregate {
            count
            }
        }
    }
`

export const useCompanySummary = () => {
    const {data, error, loading} = useQuery(COMPANY_SUMMARY);
    return {data, error, loading};
}