import React, {createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState} from "react";
import { useGetNotifications } from "../../hooks/notifications/notification.hooks";
import { Auth } from "aws-amplify";
import { getClaims } from "../../authData";
import { useApolloClient } from "@apollo/client";


export const ApplicationContext = createContext<any>(null)

type ApplicationContextProps = {
    currentUser:any,
    children: ReactNode;
};


export const AppContextProvider:React.FC<ApplicationContextProps>=({children,currentUser})=>{

const client = useApolloClient();
  const {fetchData} =  useGetNotifications();
  const socketUrl = process.env.REACT_APP_SOCKET_ENDPOINT
  const [notifications,setNotifications] = useState<any>([])
  const [notificationCount,setNotificationCount] = useState<number>(0)
  const updateNotificationData =async(viewed_at: boolean,offset:number=0)=>{
    if(currentUser?.attributes?.email){
        const variables = {
            email: currentUser?.attributes?.email,
            viewed_at: viewed_at,
            offset:offset,
            limit:10
          };
          const results = await fetchData(client, variables);
          let notis = results?.core_notification;
          if (notis) {
            setNotifications([...notis]);
          }
    }
  }
  const handleInitialNotifications = async () => {
    if(currentUser?.attributes?.email){
        const variables = {
            email: currentUser?.attributes?.email,
            viewed_at: true,
            offset:0,
            limit:10
          };
          const results = await fetchData(client, variables);
          let notis = results?.core_notification;
          if (notis) {
            setNotifications([...notis]);
            setNotificationCount(results?.core_notification_aggregate?.aggregate?.count)
          }
    }
    
  };
const handleSetNotifications=(event:any)=>{
    let data = JSON.parse(event)
    data['created_at'] = new Date()
    let notis = notifications
    let count = notificationCount
    notis.push(data)
    // setNotifications([...notis])
    setNotificationCount(count+1)
}
  useEffect(()=>{
    handleInitialNotifications()
  },[currentUser])

    useEffect(() => {
    var ws = null as any
    const connectWebSocket = async () => {
    if(currentUser?.attributes?.email) {
        const claims = await getClaims();
        const companyID = claims.companyID;
      ws = new WebSocket(`${socketUrl + currentUser?.attributes?.email}&companyId=${companyID}`);
    }
    try {
      ws.onmessage = function (event:any) {
        handleSetNotifications(event.data);
      };
    } catch (error) {
      //pass quiet
    }
    }
    connectWebSocket();
    return ()=>{
      if(ws){
        ws.close()
        ws.onclose = function (event:any){
            //this should handle disconnecting from the server
          }
      }
    }
  }, [currentUser]);
  
    return(
        <ApplicationContext.Provider value={{notifications,updateNotificationData,notificationCount}}>
            {children}
        </ApplicationContext.Provider>
    )
}

export const useAppContext = () => {
    const context = useContext(ApplicationContext);
    if (!context) {
        throw new Error("Application context error");
    }
    return context;
};