import {gql, useQuery} from "@apollo/client";

const METRICS_FIELDS = `
            created_on
            device_serial_number
            device_type
            id
            site_id
            unit
            value
            measurement
`
export const METRICS_QUERY = gql`
    query METRICS_QUERY($date: timestamptz) {
        core_metric(distinct_on: [measurement], order_by: [{measurement: asc}, {created_on: desc }]) {
            ${METRICS_FIELDS}
        }
    }
`
export const SITE_METRICS_QUERY = gql`
    query SITE_METRICS_QUERY($siteId: bigint) {
        core_metric(where: {site_id: {_eq: $siteId}}, distinct_on: [measurement], order_by: [{measurement: asc},{created_on: desc }])  {
            ${METRICS_FIELDS}
        }
    }
`

export const useMetrics = (date = "2022-02-01") => {
    const {data, error, loading} = useQuery(METRICS_QUERY, {
        variables: {date}
    });
    return {data, error, loading};
}

export const useSiteMetrics = (siteId: bigint, date = "2022-02-01") => {
    let {data, error, loading, refetch} = useQuery(SITE_METRICS_QUERY, {
        variables: {siteId},
        skip: !siteId,
    });

    const handleRefresh = async (id: bigint = siteId, refreshDate: string = date) => {
        loading = true
        const response = await refetch({siteId: id});
        data = response.data;
        error = response.error;
        loading = false;
    }
    return {data, error, loading, handleRefresh};
}