import {useParams} from "react-router-dom";
import {useShuriPagination, useSiteInventory} from "../../../../lib/hooks";
import {getFirstDateOfCurrentMonth} from "../../../../lib/filters";
import {InventoriesTable} from "../../inventories";
import {useEffect} from "react";
import {eventBus} from "../../../../lib/helpers";

export function SiteInventories() {
    const {id} = useParams();
    const {page, rowsPerPage, handleChangePage} = useShuriPagination();
    const date = getFirstDateOfCurrentMonth();
    const {data, loading, error, handleRefetch} = useSiteInventory(id, date, page, rowsPerPage);

    useEffect(() => {
        eventBus.on('refresh_site_inventories', handleRefetch);
        return () => {
            eventBus.off('refresh_site_inventories', handleRefetch);
        };
    }, []);

    return (
        <InventoriesTable
            data={data}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            loading={loading}
            error={error}
            toggleRefresh={handleRefetch}
        />
    );
}