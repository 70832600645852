import {IJob, ITablePropsInterface, limitStringLength} from "../../../../lib";
import {Collapse, IconButton, Table, TableBody, TableFooter, TableHead, TableRow} from "@mui/material";
import {ShuriTableRowStates, StyledTableCell, StyledTableRow} from "../../table";
import ShuriTablePagination from "../../table/ShuriTablePagination";
import {getFullNameFromUser} from "../../../../lib";
import {ShuriStyledStatusChip} from "../../chip";
import {JobTableActions} from "./job-table-actions";
import {useState} from "react";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import {JobVisitsTable} from "../visits";

interface IJobsTable extends ITablePropsInterface {
    onRefresh?: () => void
}

export function JobsTable({
                              data,
                              page,
                              rowsPerPage,
                              handleChangePage,
                              handleChangeRowsPerPage,
                              loading,
                              error,
                              onRefresh
                          }: IJobsTable) {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <StyledTableCell/>
                    <StyledTableCell>Job Number</StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                    <StyledTableCell>Site</StyledTableCell>
                    <StyledTableCell>Worker</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Actions</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <ShuriTableRowStates
                    colSpan={8}
                    data={data?.core_job ?? []}
                    loading={loading}
                    error={error}
                >
                    <>
                        {
                            data?.core_job.map((job: IJob) => (
                                <JobsTableRow key={`job-row-${job.id}`} job={job}
                                              onRefresh={() => onRefresh ? onRefresh() : () => {
                                              }}/>
                            ))
                        }
                    </>
                </ShuriTableRowStates>
            </TableBody>
            {
                (data?.core_job_aggregate?.aggregate?.count || 0) > rowsPerPage &&
                <TableFooter>
                    <TableRow sx={{border: 0, m: 1}}>
                        <ShuriTablePagination
                            colSpan={8}
                            count={data?.core_job_aggregate?.aggregate?.count ?? 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            }
        </Table>
    )
}

const JobsTableRow = ({job, onRefresh}: { job: IJob, onRefresh: () => void }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <StyledTableRow
                className={open ? 'shuri-row-highlighted' : ''}
                key={job.id}
                sx={{
                    color: 'white'
                }}>
                <StyledTableCell>
                    <IconButton
                        onClick={() => setOpen(!open)}
                        color="secondary"
                        aria-label="expand row"
                        size="small"
                    >
                        {open ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                    {job?.ext_job_number ?? '--'}
                </StyledTableCell>
                <StyledTableCell>{limitStringLength(job.description ?? '--', 25)}</StyledTableCell>
                <StyledTableCell>{job?.core_site?.name ?? '--'}</StyledTableCell>
                <StyledTableCell>{getFullNameFromUser(job?.core_personnelassigneds[0]?.core_user)}</StyledTableCell>
                <StyledTableCell>
                    <ShuriStyledStatusChip
                        sx={{
                            textTransform: 'capitalize'
                        }}
                        label={
                            job?.core_resjobstatus?.status ?? ''
                        }
                        color={job?.core_resjobstatus?.status === 'open' ? 'success' : 'warning'}
                        size="small"
                        variant="outlined"
                    />
                </StyledTableCell>
                <StyledTableCell>
                    <JobTableActions job={job} onRefreshJobs={onRefresh}/>
                </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow style={{padding: 0, border: 'none'}}>
                <StyledTableCell colSpan={8} style={{padding: 0, border: 'none'}}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <JobVisitsTable visits={job?.core_visits ?? []}/>
                    </Collapse>
                </StyledTableCell>
            </StyledTableRow>
        </>
    )
}
