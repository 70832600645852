import {useState} from "react";
import {perPage} from "../../../config";
import * as React from "react";

export const useShuriPagination = (itemsPerPage = perPage) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(itemsPerPage);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (parseInt(event.target.value) < 1) {
            setRowsPerPage(10000000000);
            setPage(0);
            return;
        }

        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return {page, rowsPerPage, handleChangePage, handleChangeRowsPerPage};
}