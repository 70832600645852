import {styled} from "@mui/material/styles";
import {ButtonGroup} from "@mui/material";
import {BaseColorLight, ButtonBaseColor, InputBaseColor, InputBaseColorLight} from "../../config";

export const ShuriStyledFilterButtons = styled(ButtonGroup)(({ theme }) => ({
    borderRadius: '8px',
    borderColor: InputBaseColor,
    color: InputBaseColor,
    textTransform: 'capitalize',
    '& > .MuiButtonBase-root': {
        borderRadius: '8px',
        borderColor: InputBaseColor,
        color: InputBaseColor,
        paddingRight: '20px',
        paddingLeft: '20px',
        textTransform: 'capitalize',
        '& + .MuiButtonBase-root': {
            borderLeft: `1px solid ${BaseColorLight}`,
        },

        '&.selected': {
            backgroundColor: ButtonBaseColor,
            color: InputBaseColorLight,
        },
    },
}));