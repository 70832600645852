import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import moment from 'moment'
import {useSiteContext} from "../../../../lib/context";
import {linChartOptions} from "../../../../config";
import {Box} from "@mui/material";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export function ElectricUnits() {
    const {siteCharge} = useSiteContext();

    const data = {
        labels: siteCharge?.getsitechargecostandunitsbysiteid?.map((estimate: any) => {
            return moment(estimate.charge_period_start).format('MMMM YYYY')
        }),
        datasets: [
            {
                label: 'Electricity Units',
                data: siteCharge?.getsitechargecostandunitsbysiteid?.map((estimate: any) => {
                    return estimate.total_units
                }),
                fill: true,
                borderColor: '#4490AE',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                pointRadius: 2,
            }
        ]
    }

    return (
        <Box sx={{minHeight: '270px'}}>
            <Line options={linChartOptions('Months', 'Units')} data={data}/>
        </Box>
    )
}