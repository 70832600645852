import {Alert, Snackbar} from "@mui/material";
import {useEffect, useState} from "react";

export interface IShuriNotify {
    status?: "success" | "error" | "info" | "warning";
    open: boolean;
    message: string;
    vertical?: 'top' | 'bottom';
    horizontal?: 'left' | 'center' | 'right';
    signature?: string
}

export function ShuriNotify({
                                status = 'info',
                                open = false,
                                message = 'This is a success message!',
                                vertical = 'top',
                                horizontal = 'right',
                                signature="shuri-alert"
                            }: IShuriNotify) {
    const [alertOpen, setOpen] = useState(open);

    useEffect(()=>{
        setOpen(open)
    }, [open, status, message, signature])

    const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <Snackbar
            id={signature}
            key={signature}
            anchorOrigin={{vertical, horizontal}}
            open={alertOpen}
            autoHideDuration={6000}
            onClose={handleClose}>
            <Alert onClose={handleClose} severity={status} sx={{width: '100%'}}>
                {message}
            </Alert>
        </Snackbar>
    )
}