import {styled} from "@mui/material/styles";
import {Chip} from "@mui/material";
import {AppBackgroundColor} from "../../config";

export const ShuriStyledStatusChip = styled(Chip)(({theme}) => ({
    backgroundColor: AppBackgroundColor,
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '18px',
    border: 'none',
    padding: '2px 8px',
}));