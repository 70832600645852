import {gql} from "apollo-boost";
import {useQuery} from "@apollo/client";

export const CREATE_CAMERA = "gql``";
export const UPDATE_CAMERA = "gql``";
export const DELETE_CAMERA =" gql``";

export const CAMERA_BY_ID_QUERY = gql`
    query CAMERA_BY_ID_QUERY($camera_id: String!) {
        core_camera_by_pk(camera_id: $camera_id) {
            camera_id
            camera_name
            box_id
            audio_stream_uri
            ip_address
            video_stream_name
            video_stream_uri
            core_box {
                core_site {
                    name
                    site_id
                    id
                }
            }
        }
    }
`;

export const useCameraByIdQuery = (camera_id?: string) => {
    const {data, error, loading} = useQuery(CAMERA_BY_ID_QUERY, {
        variables: {camera_id},
        skip: !camera_id,
    });
    return {data, error, loading};
}