import { ITablePropsInterface, formatDate, } from "../../../../../lib";
import {Avatar, Box, Button, Chip, Table, TableBody, TableFooter, TableHead, TableRow} from "@mui/material";
import { ShuriTableRowStates, StyledTableCell, StyledTableRow } from "../../../table";
import ShuriTablePagination from "../../../table/ShuriTablePagination";
import * as React from "react";


interface IRectifierAlarmTableProps extends ITablePropsInterface {

}

export function GeneratorAlarmsTable({
                                    data,
                                    page,
                                    rowsPerPage,
                                    handleChangePage,
                                    loading,
                                    error,
    handleChangeRowsPerPage
                                }: IRectifierAlarmTableProps) {
    const renderColor=(status:any)=>{
        if(status?.toLowerCase()==='active'){
            return 'error';
        }
        
     }
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <StyledTableCell>Alarm ID</StyledTableCell>
                    <StyledTableCell>Alarm Name</StyledTableCell>
                    <StyledTableCell>Date & Time</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <ShuriTableRowStates
                    colSpan={6}
                    data={data ?? []}
                    loading={loading}
                    error={error}
                >
                    <>
                        {
                            data?.core_devicealarm?.map((alarms: any) => (
                                <StyledTableRow key={alarms.id} sx={{
                                    color: 'white'
                                }}>
                                    <StyledTableCell component="th" scope="row">
                                        {alarms.alarm_id}
                                    </StyledTableCell>

                                    <StyledTableCell component="th" scope="row">
                                        {alarms.alarm_name}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {formatDate(alarms.created_at)}
                                    </StyledTableCell>

                                    <StyledTableCell >
                                        <Box sx={{
                                            marginLeft: '10px',
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center'
                                        }}>
                                           
                                           <Chip
                    sx={{width:100}}
                      color={renderColor(alarms?.status                        )}
                     
                      avatar={
                        <Avatar>{alarms?.status?.substring(0, 1)}</Avatar>
                      }
                      label={alarms?.status
                      }
                    ></Chip>

                                        </Box>

                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        }
                    </>
                </ShuriTableRowStates>
            </TableBody>

            {
                (data?.core_devicealarm_aggregate?.aggregate?.count || 0) > rowsPerPage &&
                <TableFooter>
                    <TableRow sx={{border: 0, m: 1}}>
                        <ShuriTablePagination
                            colSpan={6}
                            count={data?.core_devicealarm_aggregate?.aggregate?.count ?? 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            }
        </Table>
    )
}