import {Box, Button, Grid} from "@mui/material";
import * as React from "react";
import {IEquipment} from "../../../lib/interfaces";
import {StyledDetailsContainer} from "../site/site-details/StyledDetailsContainer";
import {siteEquipmentStatusColor} from "../../../lib/helpers/site/equipment_status";
import {useEffect, useState} from "react";
import {getAuthData} from "../../../lib/authData";
import {API} from "aws-amplify";
import {mapInventoryImages} from "./inventories-form";
import {ShuriUploadPreviews} from "../form";
import {ShuriSpinnerText} from "../loading";
import {BaseColorLight} from "../../config";

interface IInventoryDetailsProps {
    inventory: IEquipment;
}

export function InventoryDetails({inventory}: IInventoryDetailsProps) {
    const [thumbnails, setThumbnails] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect( () => {
        const fetchThumbnails = async () => {
            setLoading(true)
            try {
                const equipment_images = inventory?.core_equipmentimages;
                if (!equipment_images) return;
                const {idToken} = await getAuthData();
                const token = idToken.jwtToken;
                const requestData = {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : "",
                    },
                };
                const previews = mapInventoryImages(equipment_images);
                const tempImageUrls = await Promise.all(
                    previews
                        .filter((preview) => preview)
                        .filter((preview) => preview.startsWith("inv/"))
                        .map(async (preview) => {
                            try {
                                const response = await API.get(
                                    "shurikonektfeapi",
                                    `/get-share-url?objectKey=${preview}`,
                                    requestData
                                );
                                return response;
                            } catch (error) {
                                console.error(`Failed to fetch URL:  ${error}:`);
                            }
                        })
                );
                setThumbnails(tempImageUrls);
            }
            catch (e) {
                console.error(`Error while fetching thumbnails: ${e}`);
            }
            finally {
                setLoading(false);
            }
        };
         fetchThumbnails();
    }, [inventory]);

    return (
        <StyledDetailsContainer>
            <Grid container spacing={2} sx={{marginBottom: '20px'}}>
                <Grid xs={12} md={6} item className="details-tile">
                    <Box className="details-title">Equipment Type</Box>
                    <Box className="details-value">{inventory?.core_equipmenttype?.name ?? '-'}</Box>
                </Grid>

                <Grid xs={12} md={6} item className="details-tile">
                    <Box className="details-title">Equipment Model</Box>
                    <Box className="details-value">{inventory?.core_equipmentmodel?.equipment_model ?? '-'}</Box>
                </Grid>

                <Grid xs={12} md={6} item className="details-tile">
                    <Box className="details-title">Serial Number</Box>
                    <Box className="details-value">{inventory?.serial_number ?? '-'}</Box>
                </Grid>

                <Grid xs={12} md={6} item className="details-tile">
                    <Box className="details-title">Condition</Box>
                    <Box className="details-value">{inventory?.core_equipmentcondition?.condition ?? '-'}</Box>
                </Grid>

                <Grid xs={12} md={6} item className="details-tile">
                    <Box className="details-title">Part</Box>
                    <Box className="details-value">{inventory?.core_equipmentpart?.equipment_part ?? '-'}</Box>
                </Grid>

                <Grid xs={12} md={6} item className="details-tile">
                    <Box className="details-title">Status</Box>
                    <Box className="details-value"
                         sx={{color: siteEquipmentStatusColor(inventory?.core_equipmentstatus?.equipment_status)}}>{inventory?.core_equipmentstatus?.equipment_status ?? '-'}</Box>
                </Grid>

                {
                    loading && (
                        <Grid xs={12} item className="details-tile">
                            <ShuriSpinnerText loadingText={"Loading images, ☕ please wait...."} loadingTextSx={{color: BaseColorLight}} />
                        </Grid>
                    )
                }

                {
                   !loading && thumbnails.length > 0 && (
                        <Grid xs={12} item className="details-tile">
                            <Box className="details-title">Inventory Images</Box>
                            <Box className="details-value">
                                <ShuriUploadPreviews previews={thumbnails ?? []}/>
                            </Box>
                        </Grid>
                    )
                }
            </Grid>
        </StyledDetailsContainer>

    )
}