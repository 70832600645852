import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";

import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Core_User,
  FetchUsersDocument,
  useFetchCompaniesQuery,
  useFetchUserRolesQuery,
  useUpdateUserMutation
} from "../../../../generated/graphql";
import { useNotification, getClaims, getAuthData } from "../../../../lib";
import { ShuriStyledDrawer } from "../../../components/drawer";
import PhotoCapture, { CaptureType } from "./PhotoCapture";
import { API } from "aws-amplify";


type Props = {
  user?: Core_User | null;
  isOpen: boolean;
  isEdit?: boolean;
  onClose: () => void;
  onComplete?: () => void;
};

const NewUserDrawer = ({
  isEdit,
  user,
  isOpen,
  onClose,
  onComplete
}: Props) => {
  const { data } = useFetchCompaniesQuery();
  const COMPANIES = data?.core_company.map((company) => ({
    value: company?.id,
    label: company?.name
  }));

  const { data: rolesData } = useFetchUserRolesQuery();
  const USER_TYPES = rolesData?.core_role.map((role) => ({
    value: role.id,
    label: role.title
  }));

  const defaultValues = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    mobileNumber: "",
    companyName: "",
    companyID: "",
    userType: "",
    accessType: "",
    endpoint: ""
  };

  const { register, watch, setValue, reset, handleSubmit } = useForm({
    defaultValues
  });
  useEffect(() => {
    if (user && isOpen) {
      reset({
        firstName: user?.first_name,
        lastName: user?.last_name,
        emailAddress: user?.email,
        mobileNumber: "",
        companyName: user?.core_company?.id,
        userType: user?.core_role?.id,
        accessType:
          user?.[
            "core_user_user_permissions"
          ][0]?.auth_permission?.id.toString()
      });
    }
    async function fetchData() {
        try {
          const claims= await getClaims();
          reset({
            endpoint: claims.endpoint,
            companyID: claims.companyID,
          });
        } catch (error) {}
    }

    fetchData();
  }, [user, isOpen, reset]);

  const defaultPhotosState = useMemo(() => {
    return [
      { name: "straight", file: { blob: null, dataUri: "", fileName: "" } },
      { name: "left", file: { blob: null, dataUri: "", fileName: "" } },
      { name: "right", file: { blob: null, dataUri: "", fileName: "" } },
      { name: "bottom", file: { blob: null, dataUri: "", fileName: "" } },
      { name: "top", file: { blob: null, dataUri: "", fileName: "" } }
    ];
  }, []);

  const { notify } = useNotification();
  const FACE_API_URL =
  process.env.REACT_APP_FACE_API_URL || "https://face-api-1231261094.eu-west-1.elb.amazonaws.com"

  const onCreateUser = async (userData: typeof defaultValues) => {
    try {
      if (photos[0].file.blob) {
        await Promise.all(
          photos.map(async (photo) => {
            const fd = new FormData();
            fd.append(
              "file",
              photo.file.blob as unknown as Blob,
              photo.file.fileName
            );
            await axios.post(
              `${FACE_API_URL}/enroll_face?name=` +
                userData.firstName +
                "-" +
                userData.lastName,
              fd,
              {
                headers: { "Content-Type": "multipart/form-data" }
              }
            );
          })
        );
      }
      const { idToken } = await getAuthData();
      const token = idToken.jwtToken;
      const requestData = {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
        body: userData,
      };

      const { body } =       await API.post(

        "shurikonektfeapi",
        "/create-user-api",
        requestData
      );
      if (data && body === "Success") {

        notify({
          status: "success",
          open: true,
          message: "User created successfully!"
        });
        onComplete && onComplete();
        onClose();
        reset();
        setPhotos(defaultPhotosState);
      }
    } catch (error) {
      notify({
        status: "error",
        open: true,
        message: "Failed to create user"
      });
      console.log({ error });
    }
  };

  const [updateUser] = useUpdateUserMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: FetchUsersDocument }],
    onCompleted: () => onClose()
  });

  const onUpdateUser = async (data: typeof defaultValues) => {
    try {
      await updateUser({
        variables: {
          id: user?.id,
          first_name: data.firstName,
          last_name: data.lastName,
          company_id: data.companyName,
          role_id: data.userType,
          is_active: true,
          is_staff: true
        }
      });
    } catch (error) {
      console.log({ error });
    }
  };

  const onSaveUser = (data: typeof defaultValues) => {
    if (user) {
      onUpdateUser(data);
    } else {
      onCreateUser(data);
    }
  };

  const [photos, setPhotos] = useState<typeof defaultPhotosState>([]);
  const [currentPosition, setCurrentPosition] = useState(
    defaultPhotosState[0].name
  );

  useEffect(() => {
    setPhotos([...defaultPhotosState]);
    if (!isOpen) {
      reset();
    }
  }, [isOpen, defaultPhotosState, reset]);

  const [showCaptureModal, setShowCaptureModal] = useState(false);

  const handlePhotoCapture = async (captureData: CaptureType) => {
    try {
      const fd = new FormData();
      fd.append("file", captureData.blob, captureData.fileName);
      const posIndex = defaultPhotosState.findIndex(
        (img) => img.name === currentPosition
      );
      const tempImgs = photos;
      tempImgs.splice(posIndex, 1, {
        name: currentPosition,
        // @ts-expect-error
        file: captureData
      });
      setPhotos(tempImgs);
      setShowCaptureModal(false);
    } catch (error) {
      console.log("catch", { error });
    }
  };

  const onRemoveImage = (position: string) => {
    const posIndex = photos.findIndex((img) => img.name === position);
    const tempImgs = photos;
    tempImgs.splice(posIndex, 1, {
      name: position,
      file: { blob: null, fileName: "", dataUri: "" }
    });
    setPhotos([...tempImgs]);
  };

  return (
    <>
      <ShuriStyledDrawer anchor="right" open={isOpen} onClose={() => onClose()}>
        <Box width={400} padding={5}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography fontSize={25} color="white" fontWeight="bold">
              {isEdit ? "Edit User" : "Create User"}
            </Typography>
            <CloseIcon sx={{ color: "white" }} onClick={() => onClose()} />
          </Box>
          {!isEdit && (
            <Typography color="secondary" fontSize={14}>
              Fill the details in below to create a new user
            </Typography>
          )}
          {isEdit && <Box height="1px" bgcolor="white" width="100%" />}
          <Box marginY={3}>
            <InputLabel sx={{ color: "white" }}>First Name</InputLabel>
            <TextField
              variant="outlined"
              placeholder="Enter first name"
              fullWidth
              {...register("firstName")}
              required
              InputLabelProps={{ style: { color: "gray" } }}
              InputProps={{ style: { color: "white" } }}
              color="info"
            />
          </Box>
          <Box marginY={3}>
            <InputLabel sx={{ color: "white" }}>Last Name</InputLabel>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Enter last name"
              {...register("lastName")}
              InputLabelProps={{ style: { color: "gray" } }}
              InputProps={{ style: { color: "white" } }}
              color="info"
            />
          </Box>
          <Box marginY={3}>
            <InputLabel sx={{ color: "white" }}>Email Address</InputLabel>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Enter email address"
              {...register("emailAddress")}
              InputLabelProps={{ style: { color: "gray" } }}
              InputProps={{ style: { color: "white" } }}
              color="info"
            />
          </Box>
          <Box marginY={3}>
            <InputLabel sx={{ color: "white" }}>Mobile Number</InputLabel>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Enter mobile number"
              {...register("mobileNumber")}
              InputLabelProps={{ style: { color: "gray" } }}
              InputProps={{ style: { color: "white" } }}
              color="info"
            />
          </Box>
          <Box marginY={3}>
            <InputLabel sx={{ color: "white" }}>Company Name</InputLabel>
            <Select
              value={watch("companyName")}
              onChange={({ target }) => setValue("companyName", target.value)}
              fullWidth
              placeholder="Select company"
              MenuProps={{ style: { color: "white" } }}
              inputProps={{ style: { color: "white" } }}
              sx={{ color: "white" }}
              color="info"
            >
              {COMPANIES?.map((company, i) => (
                <MenuItem
                  key={company.value}
                  value={company.value}
                  sx={{ color: "white" }}
                >
                  {company.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box marginY={3}>
            <InputLabel sx={{ color: "white" }}>User Type</InputLabel>
            <Select
              value={watch("userType")}
              onChange={({ target }) => setValue("userType", target.value)}
              fullWidth
              MenuProps={{ style: { color: "white" } }}
              inputProps={{ style: { color: "white" } }}
              sx={{ color: "white" }}
              color="info"
            >
              {USER_TYPES?.map((role) => (
                <MenuItem
                  key={role.value}
                  value={role.value}
                  sx={{ color: "white" }}
                >
                  {role.label}
                </MenuItem>
              ))}
            </Select>
          </Box>

          {!user && (
            <>
              <Typography sx={{ color: "white" }}>User Images*</Typography>
              <Typography sx={{ color: "gray" }}>
                PNG, JPG or JPEG (max. 800x400px)
              </Typography>
              {photos.map((photo, i) => (
                <Box
                  key={i}
                  marginY={3}
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Box display="flex" flexDirection="row" alignItems="center">
                    {photo.file.dataUri ? (
                      <img
                        src={photo.file.dataUri}
                        alt={photo.name}
                        style={{ width: 50, height: 50, objectFit: "contain" }}
                      />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="48"
                        height="48"
                        viewBox="0 0 48 48"
                        fill="none"
                      >
                        <path
                          d="M45.6 6.3999H2.4C1.07653 6.3999 0 7.47643 0 8.7999V39.1999C0 40.5234 1.07653 41.5999 2.4 41.5999H45.6C46.9235 41.5999 48 40.5234 48 39.1999V27.3359C48 27.3357 48 27.3356 48 27.3354V8.7999C48 7.47643 46.9235 6.3999 45.6 6.3999ZM2.4 39.9999C1.959 39.9999 1.60003 39.6409 1.60003 39.1999V29.2753L9.04106 21.8339C9.34181 21.5292 9.85434 21.5252 10.163 21.8378L18.0701 29.7377L7.80478 39.9999H2.4ZM46.4 39.1998C46.4 39.6408 46.041 39.9998 45.6 39.9998H10.0676L33.841 16.2338C34.1418 15.9291 34.6547 15.9252 34.9626 16.2377L46.4001 27.6676L46.4 39.1998ZM46.4 25.4052L36.0972 15.1097C35.1902 14.1917 33.6062 14.1956 32.7062 15.1057L19.2015 28.6066L11.2973 20.7097C10.3903 19.7917 8.80631 19.7953 7.90631 20.7062L1.60003 27.0128V8.7999C1.60003 8.3589 1.959 7.99993 2.4 7.99993H45.6C46.041 7.99993 46.4 8.3589 46.4 8.7999V25.4052Z"
                          fill="white"
                        />
                        <path
                          d="M20 12C17.3531 12 15.2 14.1532 15.2 16.8C15.2 19.4468 17.3531 21.6 20 21.6C22.6468 21.6 24.8 19.4468 24.8 16.8C24.8 14.1532 22.6469 12 20 12ZM20 20C18.2355 20 16.8 18.5644 16.8 16.8C16.8 15.0356 18.2355 13.6 20 13.6C21.7644 13.6 23.1999 15.0356 23.1999 16.8C23.1999 18.5644 21.7644 20 20 20Z"
                          fill="white"
                        />
                      </svg>
                    )}
                    <Typography
                      marginLeft={2}
                      textAlign="left"
                      sx={{ color: "gray" }}
                    >
                      Photo from the {photo.name.replace("straight", "front")}
                    </Typography>
                  </Box>
                  <Box width={60} justifyContent="flex-end" display="flex">
                    {photo.file.dataUri ? (
                      <CloseIcon
                        sx={{ color: "red", cursor: "pointer" }}
                        onClick={() => onRemoveImage(photo.name)}
                      />
                    ) : (
                      <>
                        <Tooltip
                          title="Capture Image"
                          arrow
                          placement="top"
                          sx={{ cursor: "pointer" }}
                        >
                          <Box
                            onClick={() => {
                              setCurrentPosition(photo.name);
                              setShowCaptureModal(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M19.1667 15.8333C19.1667 16.2754 18.9911 16.6993 18.6785 17.0118C18.366 17.3244 17.9421 17.5 17.5 17.5H2.50004C2.05801 17.5 1.63409 17.3244 1.32153 17.0118C1.00897 16.6993 0.833374 16.2754 0.833374 15.8333V6.66667C0.833374 6.22464 1.00897 5.80072 1.32153 5.48816C1.63409 5.17559 2.05801 5 2.50004 5H5.83337L7.50004 2.5H12.5L14.1667 5H17.5C17.9421 5 18.366 5.17559 18.6785 5.48816C18.9911 5.80072 19.1667 6.22464 19.1667 6.66667V15.8333Z"
                                stroke="#F5F5F5"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10 14.1667C11.841 14.1667 13.3334 12.6743 13.3334 10.8333C13.3334 8.99238 11.841 7.5 10 7.5C8.15909 7.5 6.66671 8.99238 6.66671 10.8333C6.66671 12.6743 8.15909 14.1667 10 14.1667Z"
                                stroke="#F5F5F5"
                                strokeWidth="1.66667"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Box>
                        </Tooltip>
                        <Tooltip
                          title="Upload Image"
                          arrow
                          placement="top"
                          sx={{ cursor: "pointer" }}
                        >
                          <Box>
                            <FileUploadIcon
                              sx={{ color: "white", marginLeft: 1 }}
                            />
                          </Box>
                        </Tooltip>
                      </>
                    )}
                  </Box>
                </Box>
              ))}
            </>
          )}
          <Box
            borderTop="1px solid gray"
            width="100%"
            display="flex"
            justifyContent="space-evenly"
            paddingTop="20px"
            marginTop="20px"
          >
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{ borderColor: "white", color: "white" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#4490AE", color: "black" }}
              onClick={handleSubmit(onSaveUser)}
            >
              Save User
            </Button>
          </Box>
        </Box>
      </ShuriStyledDrawer>
      <Modal
        open={showCaptureModal}
        onClose={() => setShowCaptureModal(false)}
        sx={modalStyle}
      >
        <Box bgcolor="black" borderRadius="12px" width="600px">
          <PhotoCapture
            position={currentPosition}
            onCapture={handlePhotoCapture}
          />
        </Box>
      </Modal>
    </>
  );
};

export default NewUserDrawer;

const modalStyle = {
  top: "10%",
  left: "25%",
  boxShadow: 24
};
