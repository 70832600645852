import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

export const COMPANY_RANGE_SUMMARY_METRICS = gql`
  query ($currentDate: timestamptz, $offsetDataBalance: float8) {
    core_simcardstatus_aggregate(
      where: {
        created_on: { _gt: $currentDate }
        data_balance_in_mb: { _lt: $offsetDataBalance }
      }
    ) {
      aggregate {
        count
      }
    }
    core_eventlog {
      event_priority
      event_log_type
    }
    core_jobstatus {
      core_jobstatusenum {
        id
        status
      }
    }
    core_visitstatusenum {
      status
      id
    }
    core_visit {
      core_visitstatusenum {
        status
      }
      status_id
      core_visitstatusenum {
        status
      }
      start_datetime
      job_id
      id
      end_datetime
      comment
    }
    core_job {
      id
    }
    core_simcardstatus(
      where: {
        created_on: { _gt: $currentDate }
        data_balance_in_mb: { _lt: $offsetDataBalance }
      }
    ) {
      id
      created_on
      data_balance_in_mb
      core_simcard {
        created_on
        network_name
        msisdn
        imsi
        id
        iccid
        core_router {
          core_box {
            core_site {
              site_id
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const useCompanyRangeMetricsSummary = (
  currentDate?: any,
  offsetDataBalance?: any
) => {
  const { data, error, loading } = useQuery(COMPANY_RANGE_SUMMARY_METRICS, {
    variables: { currentDate, offsetDataBalance },
  });
  return { data, error, loading };
};
