import AddIcon from "@mui/icons-material/Add";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import { Core_Securitycallouts } from "../../../../generated/graphql";
import { useCallouts, useSiteSecurityCoverage } from "../../../../lib/hooks";
import { CalloutsMap, PageAppBar } from "../../../components";
import { CalloutSummaryCard } from "../../../components/armed-response";
import { CreateCallout } from "../../../components/site/site-armed-response";

export const AllArmedResponsePage = () => {
  const { data, error, loading, refetch } = useCallouts();
  const [addOpen, setAddOpen] = useState(false);
  const {
    data: coverageData,
  } = useSiteSecurityCoverage();

  const [selectedCallout, setSelectedCallout] = useState<any>(null);
  const isCoverageDataAvailable: boolean =
    coverageData?.core_sitesecuritycoverage?.length > 0;

  const onMarkerClick = (callout: any) => {
    setSelectedCallout(callout);
  };

  const onCloseMarkInfo = () => {
    setSelectedCallout(null);
  };

  const handleRefreshCallouts = async () => {
    refetch();
  };

  if (error) {
    return <h1>An error occurred </h1>;
  }

  if (loading) {
    return <p>Loading ....</p>;
  }

  return (
    <Box maxWidth="96%">
      <PageAppBar
        title="Security"
        sub="Security Data"
        ActionButton={() => (
          <Button
            size="medium"
            variant="contained"
            onClick={() => setAddOpen(true)}
            color="info"
            startIcon={<AddIcon />}
            sx={{ borderRadius: "28px", marginRight: "10px" }}
          >
            Create Callout
          </Button>
        )}
      />
      <Box width="100%" sx={{"@media (max-width: 600px)":{display:'block',objectFit:'fill',overflowX:'hidden'}}}>
        <Box
          color="white"
          marginBottom={2}
          display="flex"
          // sx={{ overflowX: "scroll",
          // width:{xs:"calc(100vw)",sm:"calc(100vw - 350px)",md:"calc(100vw - 350px)",lg:"calc(100vw - 350px)",xl:"calc(100vw - 350px)"} }}
          flexDirection="row"
          margin={4}
        >
          {data?.core_securitycallouts?.filter((callout: any) => callout.closed_on === null)?.map(
            (callout: Core_Securitycallouts) => (
              <CalloutSummaryCard
                key={callout.id}
                callout={callout}
                onClick={() => onMarkerClick(callout)}
                handleRefreshCallouts ={handleRefreshCallouts}
              />
            )
          )}
        </Box>
        {isCoverageDataAvailable ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: "66vh",
            flexGrow: 1,
            backgroundColor: "#696a6b",
            border: "1px solid yellow",
            borderRadius: "20px",
            margin: "35px",
            overflow: "hidden",
            "@media (max-width: 600px)":{
                objectFit:'contain',
                display:'flex',
                width:'100%',
                margin:0
            }
          }}
        >
           <CalloutsMap
              data={data}
              onMarkerClick={onMarkerClick}
              selectedCallout={selectedCallout}
              onCloseMarkInfo={onCloseMarkInfo}
            />
        </Box>
          ) : (
            <h1>Subscribe for security callout feature</h1>
          )}
        <CreateCallout
          onRefresh={handleRefreshCallouts}
          addOpen={addOpen}
          setAddOpen={setAddOpen}
        />
      </Box>
    </Box>
  );
};
