import { Box } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { StyledTab } from "./styled-tab";
import { StyledTabs } from "./styled-tabs";
import { ShuriTabPanel } from "./tab-panel";

interface IShuriTab {
  title: string;
  component: ReactNode;
}

interface IShuriTabsProps {
  tabs: IShuriTab[];
  name: string;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export function ShuriTabs({ tabs, name }: IShuriTabsProps) {
  const [value, setValue] = useState(0);

  const { search } = useLocation();

  useEffect(() => {
    if (search && search.includes("armed-response")) {
      setValue(3);
    }
  }, [search]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Box>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="Shuri styled tabs"
        >
          {tabs.map((tab, index) => (
            <StyledTab
              key={`${name}-${index}-shuri-tab`}
              sx={{
                color: "white",
                boxShadow:
                  "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)"
              }}
              label={tab.title}
              {...a11yProps(index)}
            />
          ))}
        </StyledTabs>
      </Box>

      <Box>
        {tabs.map((tab, index) => (
          <ShuriTabPanel
            key={`${name}-${index}-shuri-tab-panel`}
            value={value}
            index={index}
          >
            {tab.component}
          </ShuriTabPanel>
        ))}
      </Box>
    </>
  );
}
