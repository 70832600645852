import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

export const SITE_COVERAGE_QUERY = gql`
  query MyQuery {
    core_sitesecuritycoverage {
      id
      site_id
      is_covered
      created_on
    }
  }
`;

export const useSiteSecurityCoverage = () => {
  const { data, error, loading } = useQuery(SITE_COVERAGE_QUERY);
  return { data, error, loading };
};
