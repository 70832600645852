import gql from "graphql-tag";
import {useQuery} from "@apollo/client";

export const JOB_DETAILS_QUERY = gql`
    query JOB_DETAILS_QUERY(
        $id: bigint!
    ) {
        core_job_by_pk(id: $id) {
            id
            created_on
            description
            ext_job_number
            site_id
            core_site {
                id
                site_id
                name
            }
            core_jobstatuses(order_by: { id: desc }, limit: 1) {
                id
                job_id
                status_id
                comment
                core_jobstatusenum {
                    id
                    status
                }
            }
            core_visits {
                id
                job_id
                status_id
                start_datetime
                end_datetime
                comment
                core_visitstatusenum {
                    id
                    status
                }
            }
            core_personnelassigneds(order_by: { id: desc }, limit: 1) {
                id
                job_id
                personale_id
                core_user {
                    first_name
                    last_name
                }
            }
        }
    }
`;

export const useJobDetails = (id?: string) => {
    const {data, error, loading, refetch} = useQuery(JOB_DETAILS_QUERY, {
        variables: {id},
        skip: !id,
    });

    const handleRefetch = () => {
        refetch({id})
    }
    return {data, error, loading, handleRefetch};
}
