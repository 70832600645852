import {StyledPageCard} from "../../page";
import {SiteDetailsInfo} from "./site-details-info";
import {SiteDetailsBox} from "./site-details-box";
import {SiteDetailsCameras} from "./site-details-cameras";

export function SiteDetails() {
    return (
        <StyledPageCard>
            <SiteDetailsInfo/>
            <SiteDetailsBox/>
            <SiteDetailsCameras />
        </StyledPageCard>
    )
}