import {Button} from "@mui/material"
import {ShuriFormCloseAction, StyledFormActionRow} from "../../../form"
import {FormEvent} from "react";

interface ICreateSiteFormActionProps {
    primaryLabel: string;
    secondaryLabel: string;
    onSave: (e: FormEvent<HTMLFormElement>) => void;
    onCancel: () => void;
    hasErrors?: boolean;
}

export const CreateSiteFormAction = ({
                                         primaryLabel = 'Save',
                                         secondaryLabel = 'Cancel',
                                         onCancel,
                                         hasErrors = false
                                     }: ICreateSiteFormActionProps) => {
    return (
        <StyledFormActionRow>
            <ShuriFormCloseAction label={secondaryLabel} handleClick={onCancel}/>
            <Button variant="contained" color="info" type='submit' disabled={hasErrors}>
                {primaryLabel}
            </Button>
        </StyledFormActionRow>
    )
}