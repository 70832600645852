import {Table, TableBody, TableFooter, TableHead, TableRow} from "@mui/material";
import {StyledTableCell, StyledTableRow} from "../table";
import {IDefaultVisits} from "../../../lib/interfaces";
import ShuriTablePagination from "../table/ShuriTablePagination";
import {ShuriTableRowStates} from "../table";
import {formatDate} from "../../../lib/filters";

interface IScheduledVisitsTableProps {
    data: {
        core_visit?: IDefaultVisits[];
        core_visit_aggregate: any;
    }
    page: number;
    rowsPerPage: number;
    handleChangePage: any;
    loading?: boolean;
    error?: any;
}

export function ScheduledVisitsTable({
                                         data,
                                         page,
                                         rowsPerPage,
                                         handleChangePage,
                                         loading,
                                         error
                                     }: IScheduledVisitsTableProps) {


    return (
        <Table>
            <TableHead>
                <TableRow>
                    <StyledTableCell>Date / Time</StyledTableCell>
                    <StyledTableCell sx={{color: 'white'}}>Job No#</StyledTableCell>
                    <StyledTableCell sx={{color: 'white'}}>Description</StyledTableCell>
                    <StyledTableCell sx={{color: 'white'}}>Personnel</StyledTableCell>
                    <StyledTableCell sx={{color: 'white'}}>Status</StyledTableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                <ShuriTableRowStates
                    colSpan={5}
                    data={data?.core_visit ?? []}
                    loading={loading}
                    error={error}
                >
                    <>
                        {
                            data?.core_visit?.map((visit) => (
                                <StyledTableRow key={visit.id} sx={{
                                    color: 'white'
                                }}>
                                    <StyledTableCell component="th" scope="row">
                                        {formatDate(visit?.start_datetime) ?? '-'}
                                    </StyledTableCell>
                                    <StyledTableCell>{visit?.core_job?.ext_job_number ?? '-'}</StyledTableCell>
                                    <StyledTableCell>{visit?.core_job?.description ?? '-'}</StyledTableCell>
                                    <StyledTableCell>{`${visit?.core_job?.core_personnelassigneds[0]?.core_user?.first_name ?? '-'} ${visit?.core_job?.core_personnelassigneds[0]?.core_user?.last_name ?? '-'}`}</StyledTableCell>
                                    <StyledTableCell>{visit?.core_visitstatusenum?.status ?? '-'}</StyledTableCell>
                                </StyledTableRow>
                            ))
                        }
                    </>
                </ShuriTableRowStates>
            </TableBody>

            {
                (data?.core_visit_aggregate?.aggregate?.count || 0) > rowsPerPage &&
                <TableFooter>
                    <TableRow sx={{border: 0, m: 1}}>
                        <ShuriTablePagination
                            colSpan={5}
                            count={data?.core_visit_aggregate?.aggregate?.count ?? 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    </TableRow>
                </TableFooter>
            }
        </Table>
    )
}