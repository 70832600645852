import {styled} from "@mui/material/styles";
import {Box} from "@mui/material";
import {InputBaseColor, primaryBlue} from "../../../config";

export const StyledFormRow = styled(Box)(() => ({
    color: '#FFFFFF',
    paddingBottom: '25px',
    borderBottom: '1px solid rgba(105, 104, 105, 0.2)',
    marginBottom: '30px',

    '.form-title-container': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    '.form-row-title': {
        fontFamily: 'Outfit',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '24px',
        marginBottom: '15px'
    },
    '.form-row-action': {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    '.form-row-action-button': {
        color: primaryBlue,
        textTransform: 'capitalize',
    },
    /**
    * On dynamic form rows, we might add a delete icon somewhere in the label, the following code should take care of that
    * */
    position: 'relative',
    '.label-action': {
        position: 'absolute',
        top: 10,
        right: 0,
        zIndex: 10,
        maxWidth: '30px'
    },

    /**
     * Media queries for when the size is xs
     */
    '@media (max-width: 600px)': { // Media query for xs screens
        '.form-row-action': {
            justifyContent: 'flex-start', // Override for xs screens
        },
    },
}));

export const StyledFormActionRow = styled(Box)(() => ({
    color: InputBaseColor,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '.MuiButtonBase-root': {
        textTransform: 'capitalize',
    }
}));
