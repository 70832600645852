import {Box, Grid, FormControl, Typography} from "@mui/material"
import {StyledFormRow, StyledFormLabel, StyledFormTextField, StyledFormFieldError} from "../../../form"
import {ShuriMuiFormSelect} from "../../../form/form-select/ShuriMuiFormSelect"
import {CreateSiteFormAction} from "../partials/CreateSiteFormAction"
import {FormEvent, memo, useEffect, useState} from "react"
import {useForm, useYupValidateForm} from "../../../../../lib/form/hooks"
import {getCurrentDate} from "../../../../../lib"
import {
    ADD_SITE_MUTATION,
    UPDATE_SITE_MUTATION,
    useRegion,
    useSiteStatus,
    useSiteTypes
} from "../../../../../lib/hooks"
import {ISelectInput, ISite} from "../../../../../lib/interfaces"
import {useMutation} from "@apollo/client"
import {useNavigate} from "react-router-dom"
import {useNotification} from "../../../../../lib/context";
import * as Yup from 'yup';

interface IAddSiteDetailsProps {
    site?: ISite;
    refreshSite?: () => void
}

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    site_type_id: Yup.number().typeError('Site type is required. Provide a valid value').required('Site Type is required'),
    region_id: Yup.number().typeError('Region is required. Provide a valid value').required('Region is required'),
    site_id: Yup.string().max(6, 'Site ID Length must not exceed 6').required('Site ID is required'),
    site_status_id: Yup.string().required('Site status is required'),
    municipality: Yup.string().required('Municipality is required'),
    latitude: Yup.string().required('Latitude is required'),
    longitude: Yup.string().required('Longitude is required'),
});

export const AddSiteDetails = memo(({site, refreshSite}: IAddSiteDetailsProps) => {
    const [site_types, setSiteTypes] = useState<ISelectInput[]>([]);
    const [regions, setRegions] = useState<ISelectInput[]>([]);
    const [status, setStatus] = useState<ISelectInput[]>([]);
    const {notify} = useNotification();

    const {data: siteTypesData} = useSiteTypes()
    const {data: regionData} = useRegion()
    const {data: statusData} = useSiteStatus()

    const navigate = useNavigate();
    const {inputs, handleChange, resetForm, clearForm, setInputs} = useForm({
        name: '',
        created_on : getCurrentDate(),
        site_type_id: '',
        region_id: '',
        site_id: '',
        site_status_id: '',
        municipality: '',
        latitude: '',
        longitude: '',
    });
    const {errors, validateField, validateForm, hasErrors} = useYupValidateForm(inputs, validationSchema);
    const [createSite, {error: createError}] = useMutation(ADD_SITE_MUTATION, {
        variables: inputs
    });

    const [updateSite, {error: updateError}] = useMutation(UPDATE_SITE_MUTATION, {
        variables: inputs
    });

    useEffect(()=>{
        if (createError){
            notify({
                status: "error",
                open: true,
                message: 'An error occurred trying to create site, try again later!',
            });
        }

        if (updateError){
            notify({
                status: "error",
                open: true,
                message: 'An error occurred trying to update site, try again later!',
            });
        }
    }, [createError, updateError])
    useEffect(() => {
        if (site) {
            setInputs({
                name: site.name || '',
                site_type_id: site.site_type_id || '',
                region_id: site.region_id || '',
                site_id: site.site_id || '',
                site_status_id: site.site_status_id || '',
                municipality: site?.municipality || '',
                latitude: site?.latitude || '',
                longitude: site?.longitude || '',
                created_on : site?.created_on || getCurrentDate(),
            });
        }
    }, [site, setInputs]);

    useEffect(() => {
        const mapped = siteTypesData?.core_sitetype?.map((type: { id: number; site_type: string }) => ({
            value: type.id,
            label: type.site_type
        })) ?? [];
        setSiteTypes(mapped);
    }, [siteTypesData])


    useEffect(() => {
        const mapped = regionData?.core_siteregion?.map((region: { id: number; region_name: string }) => ({
            value: region.id,
            label: region.region_name
        })) ?? [];
        setRegions(mapped);
    }, [regionData])

    useEffect(() => {
        const mapped = statusData?.core_sitestatus?.map((site_status: { id: number; site_status: string }) => ({
            value: site_status.id,
            label: site_status.site_status
        })) ?? [];
        setStatus(mapped);
    }, [statusData])

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (await validateForm()) {
            if (!site) {
                await createSite({
                    variables: {
                        ...inputs
                    },
                    onCompleted(createdSiteData) {
                        if (createdSiteData) {
                            clearForm();
                            notify({
                                status: "success",
                                open: true,
                                message: "Site created successfully!",
                            });
                            navigate(`/site/${createdSiteData?.insert_core_site?.returning[0]?.id}`)
                        }
                    },
                    onError(error) {
                        notify({
                            status: "error",
                            open: true,
                            message: 'An error occurred trying to create site, try again later!',
                        });
                    }
                });
            } else {
                await updateSite({
                    variables: {
                        ...inputs,
                        id: site.id,
                    },
                    onCompleted(updatedSiteData) {
                        if (updatedSiteData) {
                            if (refreshSite) {
                                refreshSite()
                            }
                            notify({
                                status: "success",
                                open: true,
                                message: "Site updated successfully!",
                            });
                        }
                    },
                    onError(error) {
                        notify({
                            status: "error",
                            open: true,
                            message: 'An error occurred trying to update site, try again later!',
                        });
                    }
                })
            }
        }
    }

    const handleCancel = ()=>{
        resetForm();
        navigate(`/sites`);
    }
    return (
        <form onSubmit={handleSubmit}>
            <StyledFormRow>
                <Box className='form-row-title'>
                    Site Details
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                        <FormControl color="secondary" fullWidth required>
                            <StyledFormLabel>Site Name</StyledFormLabel>
                            <StyledFormTextField
                                type="text"
                                size='small'
                                placeholder="Enter site name"
                                name="name"
                                value={inputs.name}
                                onChange={handleChange}
                                onBlur={() => validateField('name')}
                                error={!!errors.name}
                                helperText={errors.name}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <FormControl color="secondary" size="small" fullWidth required>
                            <StyledFormLabel>Site Location</StyledFormLabel>
                            <ShuriMuiFormSelect
                                placeholder='Select Location'
                                options={regions}
                                value={inputs.region_id}
                                handleChange={handleChange}
                                name='region_id'
                                handleBlur={() => validateField('region_id')}
                                error={!!errors.region_id}
                            />
                            {errors.region_id && <StyledFormFieldError>{errors.region_id}</StyledFormFieldError> }
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <FormControl color="secondary" size="small" fullWidth required>
                            <StyledFormLabel>Site Type</StyledFormLabel>
                            <ShuriMuiFormSelect
                                placeholder='Select Type'
                                options={site_types}
                                value={inputs.site_type_id}
                                handleChange={handleChange}
                                name='site_type_id'
                                handleBlur={() => validateField('site_type_id')}
                                error={!!errors.site_type_id}/>
                            {errors.site_type_id && <StyledFormFieldError>{errors.site_type_id}</StyledFormFieldError>}

                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <FormControl color="secondary" size="small" fullWidth required>
                            <StyledFormLabel>Site ID</StyledFormLabel>
                            <StyledFormTextField
                                type="text"
                                size='small'
                                placeholder="Enter site ID"
                                name="site_id"
                                value={inputs.site_id}
                                onChange={handleChange}
                                onBlur={() => validateField('site_id')}
                                error={!!errors.site_id}
                                helperText={errors.site_id}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <FormControl color="secondary" size="small" fullWidth required>
                            <StyledFormLabel>Site Status</StyledFormLabel>
                            <ShuriMuiFormSelect
                                placeholder='Select Status'
                                options={status}
                                value={inputs.site_status_id}
                                handleChange={handleChange}
                                name='site_status_id'
                                handleBlur={() => validateField('site_status_id')}
                                error={!!errors.site_status_id}/>
                            {errors.site_status_id && <StyledFormFieldError>{errors.site_status_id}</StyledFormFieldError>}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <FormControl color="secondary" fullWidth>
                            <StyledFormLabel>Site Municipality</StyledFormLabel>
                            <StyledFormTextField
                                type="text"
                                size='small'
                                placeholder="Enter site municipality"
                                name="municipality"
                                value={inputs.municipality}
                                onChange={handleChange}
                                onBlur={() => validateField('municipality')}
                                error={!!errors.municipality}
                                helperText={errors.municipality}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <FormControl color="secondary" fullWidth required>
                            <StyledFormLabel>Site latitude</StyledFormLabel>
                            <StyledFormTextField
                                type="text"
                                size='small'
                                placeholder="Enter site latitude"
                                name="latitude"
                                value={inputs.latitude}
                                onChange={handleChange}
                                onBlur={() => validateField('latitude')}
                                error={!!errors.latitude}
                                helperText={errors.latitude}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <FormControl color="secondary" fullWidth required>
                            <StyledFormLabel>Site longitude</StyledFormLabel>
                            <StyledFormTextField
                                type="text"
                                size='small'
                                placeholder="Enter site longitude"
                                name="longitude"
                                value={inputs.longitude}
                                onChange={handleChange}
                                onBlur={() => validateField('longitude')}
                                error={!!errors.longitude}
                                helperText={errors.longitude}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        <CreateSiteFormAction
                            primaryLabel={site ? "Update Details" : "Save"}
                            secondaryLabel={"Cancel"}
                            onSave={handleSubmit}
                            onCancel={handleCancel}
                            hasErrors={hasErrors}
                        />
                    </Grid>


                </Grid>
            </StyledFormRow>
        </form>
    )
});