import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

export const EVENT_LOGS_NOTIFICATIONS_QUERY = gql`
query EVENT_LOG_QUERY {
  core_eventlog(where: {viewed_at: {_is_null: true}, event_priority: {_eq: "High"}}) {
    id
    viewed_at
    event_name
    event_priority
    core_site {
      name
    }
  }
  core_eventlog_aggregate(where: {viewed_at: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}

`;

export const useEventLogsNotifications = () => {
  const { data, error, loading,refetch } = useQuery(EVENT_LOGS_NOTIFICATIONS_QUERY);
  const upEventLogsNotifications = async () => {
    await refetch();
};
  return { data, error, loading, upEventLogsNotifications };
};
