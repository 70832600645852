import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import {BaseColor, BaseColorLight, TableCellHeaderBackgroundColor} from "../../config";

export const StyledTableCell = styled(TableCell)(({theme}) => ({
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    color: BaseColorLight,
    borderColor: BaseColor,
    padding: '16px 24px',
    position: 'relative',
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: TableCellHeaderBackgroundColor,
        color: BaseColorLight,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
