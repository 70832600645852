import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { getFirstDateOfCurrentMonth } from "../../filters";

export const USER_QUERY = gql`
  query SITE_INFO_QUERY($id: bigint = "28", $date: timestamptz = "2022-03-01") {
    core_site(where: { id: { _eq: $id } }) {
      address
      id
      last_name
      is_active
      is_staff
      first_name
      email
    }
  }
`;

export const useUser = (id: any, date = getFirstDateOfCurrentMonth()) => {
  const { data, error, loading } = useQuery(USER_QUERY, {
    variables: { id, date },
  });
  return { data, error, loading };
};
