import axios from "axios";

export type EmailTemplate = "DELETE_JOB_EMAIL" | "NEW_JOB_EMAIL";
const SEND_MAIL_API_URL =
process.env.REACT_APP_SEND_MAIL_API_URL || "https://qfe4rmdx6g22afdyaoyk6q4osm0zdckf.lambda-url.eu-west-1.on.aws/"

const useEmail = () => {
  const sendEmail = async (
    template: EmailTemplate,
    {
      toAddress,
      subject,
      emailData
    }: {
      toAddress: string;
      subject: string;
      emailData: Object;
    }
  ) => {
    try {
      const { data } = await axios.post(
        SEND_MAIL_API_URL,
        {
          template,
          toAddress,
          subject,
          emailData
        }
      );
      return data;
    } catch (error) {
      console.log({ error });
    }
  };

  return { sendEmail };
};

export default useEmail;
