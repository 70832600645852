import { GoogleMap, useJsApiLoader, InfoWindow } from '@react-google-maps/api';
import {Link} from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { MarkerF as Marker } from '@react-google-maps/api';
import {googleApiKey} from "../../../config";
import {Box, Typography, Chip} from "@mui/material";
import Button from "@mui/material/Button";
import './styles.css'
import towerOffline  from '../../../assets/towerOffline.svg';
import towerOnline from '../../../assets/towerOnline.png'
import towerAtrisk from '../../../assets/towerOffline.png'
import {AppBackgroundColor} from '../../config'
import {formatDate} from "../../../lib/filters";

const containerStyle = {
    width: '100%',
    height: '120%'
};

const initPos = { lat: -26.902601816368087, lng: 27.457943115823962 }

export function Map(props: { data: any, onMarkerClick: (site: any) => void, selectedSiteId: string | null, onCloseMarkInfo: () => void , atRiskSites?: any[] }) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${googleApiKey}`,
        libraries: ['geometry', 'drawing'],
    });

    const checkStatus = (status: any, siteId:number) => {
        if (props?.atRiskSites?.map(site=>site.id)?.includes(siteId)){
            return towerAtrisk
        }
        if (status?.includes("online")) {
            return towerOnline
        }
        if (status?.includes('offline')) {
            return towerOffline
        }else{
            return towerOffline
        }

    }

    return (
        isLoaded ? <GoogleMap
            mapContainerStyle={containerStyle}
            center={initPos}
            zoom={8}>
            { props?.data?.core_site?.filter((site: any) => site.is_enabled).map((site: any) => {
                    const coreState = site?.core_sitestates;
                    const lastseen = coreState?.[0]?.lastseen;
                    const status = site?.core_sitestatus?.site_status;
                    const siteReasons = coreState?.[0]?.core_statereasons
                    return (
                        <Marker
                            options={{
                                icon: checkStatus(status, site?.id)
                            }}
                            key={site.id}
                            title={site.name}
                            onClick={() => props.onMarkerClick(site)}
                            position={{ lat: site.latitude, lng: site.longitude }} >

                            {props.selectedSiteId === site.id ? (
                                <InfoWindow onCloseClick={() => props.onCloseMarkInfo()}>
                                    <Box sx={{width:'350px', maxHeight:'180px', backgroundColor: 'secondary'}}>
                                        <Typography sx={{fontSize:'16px', fontWeight: '500'}}>{`${site.site_id} - ${site.name}`}</Typography>
                                        <Box sx={{display: 'flex', color: '#EE9408'}}>
                                            <Box sx={{marginTop: '12px'}}>
                                                <Typography sx={{fontSize:'16px'}}>Current Status:</Typography>
                                                    <Chip
                                                        sx={{
                                                        backgroundColor: AppBackgroundColor,
                                                        fontWeight: "500",
                                                        fontSize: "14px",
                                                        lineHeight: "18px",
                                                        border: "none",
                                                        padding: "2px 0px",
                                                        marginTop: "2px"
                                                        }}
                                                        label={site?.is_enabled ?
                                                            `${status?.charAt(0).toUpperCase() + status.slice(1)} : ${siteReasons?.map((reason: any) => {
                                                            return ( reason?.core_reasonenum?.reason)
                                                                })}` :
                                                                "Disabled"
                                                            }
                                                            color={status === "online" ? 'success' : 'error'}
                                                            size="small"
                                                        variant="outlined"
                                                    />
                                            </Box> 

                                        </Box>
                                        <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: '12px'}}>
                                            <Box>
                                                <Typography sx={{fontSize: '14px'}}>Last Seen</Typography>
                                                <Typography sx={{fontSize: '14px'}}>{lastseen ? formatDate(lastseen): "--"}</Typography>
                                            </Box>
                                            <Button sx={{color: '#5BC0E9', fontSize: '14px', textTransform: 'capitalize'}}>
                                                <Link to={`/site/${site.id}`}>View Site</Link>
                                                <ChevronRightIcon sx={{color: '#5BC0E9'}}/>
                                            </Button>
                                        </Box>
                                    </Box>
                                </InfoWindow>
                            ) : null}
                        </Marker>
                    )
                })
            }
        </GoogleMap> : null
    )
}
