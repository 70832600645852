import {
    Box, Button,
    Chip,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import {
    BaseColor, ButtonBaseColor,
    ButtonBaseColorLight,
    errorBase,
    InputBaseColor,
    InputBaseColorLight,
    MetricsCardBackground
} from "../../config";
import React, {useState} from "react";
import {ICameraStream} from "../../../lib";
import {ShuriStyledDialog, ShuriStyledDialogBackdrop} from "../dialog";
import {ShuriSpinnerText} from "../loading";

interface IShuriVideoMetaProps {
    camera?: ICameraStream;
    description: string;
    onFullScreen?: () => void
    onClose?: () => void
    onDelete?: (camera?: ICameraStream) => void
    has_close?: boolean
    has_delete?: boolean
    deleting?: boolean
    fullscreen?: boolean
    status?: string | null
}

export function ShuriVideoMeta({
                                   camera,
                                   description,
                                   onFullScreen,
                                   onClose = () => {
                                   },
                                   onDelete = () => {
                                   },
                                   has_close = false,
                                   has_delete = false,
                                   deleting = false,
                                   fullscreen = false,
                                   status,
                               }: IShuriVideoMetaProps) {
    const [deleteOpen, setDeleteOpen] = useState(false);
    return (
        <>
            <Box sx={{
                width: '100%',
                backgroundColor: 'rgba(0,0,0,.1)',
                padding: '5px',
                paddingTop: '1px',
                paddingBottom: '0',
                borderTopRightRadius: '8px',
                borderTopLeftRadius: '8px'
            }}>

                <Grid container spacing={2}>

                    <Grid item xs={8} sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center'
                    }}>
                        <IconButton>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={InputBaseColor}
                                 viewBox="0 0 256 256">
                                <path
                                    d="M251.77,73a8,8,0,0,0-8.21.39L208,97.05V72a16,16,0,0,0-16-16H32A16,16,0,0,0,16,72V184a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V159l35.56,23.71A8,8,0,0,0,248,184a8,8,0,0,0,8-8V80A8,8,0,0,0,251.77,73ZM192,184H32V72H192V184Zm48-22.95-32-21.33V116.28L240,95Z"></path>
                            </svg>
                        </IconButton>
                        <Typography sx={{
                            color: InputBaseColor,
                            fontSize: '10px'
                        }}>
                            {description}
                        </Typography>
                    </Grid>

                    <Grid item xs={4} sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    }}>
                        {
                            status &&
                            <Chip
                                sx={{
                                    backgroundColor: MetricsCardBackground,
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    border: 'none',
                                    padding: '2px 8px',
                                    marginRight: '20px'

                                }}
                                label={`${status.charAt(0).toUpperCase() + status.slice(1)}`}
                                color={status === "online" ? 'success' : 'error'}
                                size="small"
                                variant="outlined"
                            />
                        }
                        {
                            onFullScreen && (
                                <IconButton onClick={() => onFullScreen()}>
                                    {
                                        fullscreen ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     fill={InputBaseColorLight} viewBox="0 0 256 256">
                                                    <path
                                                        d="M152,96V48a8,8,0,0,1,16,0V88h40a8,8,0,0,1,0,16H160A8,8,0,0,1,152,96ZM96,152H48a8,8,0,0,0,0,16H88v40a8,8,0,0,0,16,0V160A8,8,0,0,0,96,152Zm112,0H160a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0V168h40a8,8,0,0,0,0-16ZM96,40a8,8,0,0,0-8,8V88H48a8,8,0,0,0,0,16H96a8,8,0,0,0,8-8V48A8,8,0,0,0,96,40Z"></path>
                                                </svg>
                                            ) :
                                            (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     fill={InputBaseColorLight}
                                                     viewBox="0 0 256 256">
                                                    <path
                                                        d="M200,80v32a8,8,0,0,1-16,0V88H160a8,8,0,0,1,0-16h32A8,8,0,0,1,200,80ZM96,168H72V144a8,8,0,0,0-16,0v32a8,8,0,0,0,8,8H96a8,8,0,0,0,0-16ZM232,56V200a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM216,200V56H40V200H216Z"></path>
                                                </svg>
                                            )
                                    }

                                </IconButton>
                            )
                        }
                        {
                            has_delete &&
                            <IconButton onClick={() => setDeleteOpen(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={errorBase}
                                     viewBox="0 0 256 256">
                                    <path
                                        d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path>
                                </svg>
                            </IconButton>
                        }
                        {
                            has_close &&
                            <IconButton onClick={() => onClose()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                     fill={InputBaseColorLight}
                                     viewBox="0 0 256 256">
                                    <path
                                        d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
                                </svg>
                            </IconButton>
                        }
                    </Grid>
                </Grid>
            </Box>

            <ShuriStyledDialog
                open={deleteOpen}
                onClose={() => setDeleteOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                BackdropComponent={ShuriStyledDialogBackdrop}
            >
                <DialogTitle id="alert-dialog-title">
                    <IconButton sx={{
                        background: `${errorBase}50`,
                        '&:hover': {
                            background: `${errorBase}60`,
                        },
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={errorBase}
                             viewBox="0 0 256 256">
                            <path
                                d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm-8-80V80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,172Z"></path>
                        </svg>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description-title" className="shuri-dialog-title">
                        {`Remove camera stream?`}
                    </DialogContentText>

                    <DialogContentText className="shuri-dialog-description" id="alert-dialog-description">
                        Are you sure you would like to remove the selected
                        camera <b>{camera?.core_camera?.camera_name ?? ''}?</b>
                        If you remove this camera, you will not be able to access it in this page until you add it
                        again.
                    </DialogContentText>

                    {
                        deleting &&
                        <Box sx={{
                            marginTop: '30px'
                        }}>
                            <ShuriSpinnerText
                                loadingTextSx={{
                                    color: InputBaseColorLight,
                                }}
                                loadingText={"Removing..."}
                            />
                        </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onDelete(camera)}
                            variant="contained"
                            autoFocus
                            size="large"
                            sx={{
                                color: BaseColor,
                                borderColor: errorBase,
                                backgroundColor: errorBase,
                                textTransform: 'capitalize',
                                '&:hover': {
                                    background: `${errorBase}95`,
                                },
                            }}
                    >Yes, Remove</Button>

                    <Button onClick={() => setDeleteOpen(false)}
                            variant="outlined"
                            size="large"
                            sx={{
                                color: ButtonBaseColorLight,
                                borderColor: ButtonBaseColor,
                                textTransform: 'capitalize',
                                '&:hover': {
                                    borderColor: `${ButtonBaseColor}95`,
                                },
                            }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </ShuriStyledDialog>
        </>
    )
}