import {styled} from "@mui/material/styles";
import {Box, emphasize} from "@mui/material";
import {BaseColorLight, primaryBlue} from "../../../config";

export const StyledBreadcrumbChild = styled(Box)(({ theme }) => ({
    color: BaseColorLight,
    height: theme.spacing(3),
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 'inherit',
    '&:hover, &:focus': {
        color: emphasize(BaseColorLight, 0.06),
    },
    '&:active, &:last-child': {
        color: emphasize(primaryBlue, 0.12),
        fontSize: '14px'
    },
}));