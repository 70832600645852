import { Button } from "@mui/material";
import { ShuriStyledFilterButtons } from "../../table";

export interface IFilterOption {
  label: string;
  value: string | number;
}

interface ISiteStatusFilterProps {
  onFilterUpdate: (filter: IFilterOption) => void;
  filters: IFilterOption[];
  selected?: string | number;
}

export function SiteStatusFilter({
  filters,
  selected,
  onFilterUpdate,
}: ISiteStatusFilterProps) {
  return (
    <ShuriStyledFilterButtons
      variant="outlined"
      aria-label="outlined button group"
      color="secondary"
      size="small"
    >
      {filters?.map((filter, index: number) => (
        <Button
          key={index}
          className={filter.value === selected ? "selected" : ""}
          onClick={() => onFilterUpdate(filter)}
        >
          {filter.label}
        </Button>
      ))}
    </ShuriStyledFilterButtons>
  );
}
