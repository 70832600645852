import React, {createContext, FC, ReactNode, useContext, useEffect, useState} from "react";
import {Auth} from "aws-amplify";
import SkeletonLoader from "../../../ui/components/skeleton-loader";
import {useNotification} from "../alerts";

interface IAuthUserContext {
    currentUser: any;
    loadingAuthUser: boolean;
    authUserError: any
}

interface IAuthUserProvider {
    children: ReactNode;
}

const AuthUserContext = createContext<IAuthUserContext | null>(null);

export const AuthUserContextProvider: FC<IAuthUserProvider> = ({children}) => {
    const [error, setError] = useState<string>()
    const [currentUser, setCurrentUser] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false);
    const { notify } = useNotification();

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const user = await Auth.currentUserInfo();
                setCurrentUser(user)
            } catch (e) {
                setError(`Error loading auth user: ${e}`);
                notify({
                    status: "error",
                    open: true,
                    message:
                        "An error occurred trying to load authenticated user, kindly refresh the browser page!"
                });
            } finally {
                setLoading(false);
            }
        })()
    }, [])

    return (
        <AuthUserContext.Provider value={{
            currentUser,
            loadingAuthUser: loading,
            authUserError: error
        }}>
            {children}
        </AuthUserContext.Provider>
    )
}

export const useAuthUserContext = () => {
    const context = useContext(AuthUserContext);
    if (!context) {
        throw new Error("useAuthUserContext must be used within a AuthUserContextProvider");
    }
    return context;
};