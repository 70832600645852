import {gql, useQuery} from "@apollo/client";

export const EVENT_LOG_QUERY = gql`
    query EVENT_LOG_QUERY($id: bigint!) {
        core_eventlog_by_pk(id: $id) {
            created_at
            viewed_at
            event_log_type
            event_name
            event_priority
            site_id
            id
            source
            core_logs {
                authorised
                camera_id
                date_recorded
                event_log_id
                id
                log_type
                pic
            }
            core_site {
                id
                name
                site_id
                up_since
                effective_date
                address
                municipality
                core_sitestatus {
                    site_status
                }
            }
            core_incident{
                created_on
                event_log_id
                id
                site_id
            }
        }
    }
`;
export const UPDATE_EVENT_LOG = gql`
mutation MyMutation($id: bigint!, $viewed_on: timestamptz) {
    update_core_eventlog_by_pk(pk_columns: {id: $id}, _set: {viewed_at: $viewed_on}) {
      site_id
      source
      id
    }
  }  
`;
export const useEventLog = (id: any) => {
    const {data, error, loading} = useQuery(EVENT_LOG_QUERY, {
        variables: {id},
        skip: !id,
    });
    return {data, error, loading};
}