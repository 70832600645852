export const linChartOptions = (xTitle: string, yTitle: string) => ({
    tension: 0.2,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        y: {
            grid: {
                display: true,
                drawOnChartArea: true,
                color: '#F2F4F7'
            },
            title: {
                display: true,
                text: yTitle,
                color: '#F2F4F7',
            },
            ticks: {
                color: '#F2F4F7',
            },
        },
        x: {
            title: {
                display: true,
                text: xTitle,
                color: '#F2F4F7',
            },
            ticks: {
                color: '#F2F4F7'
            }
        }
    },
    plugins: {
        legend: {
            position: 'top' as const,
            align: 'end' as const,
            labels: {
                usePointStyle: true,
                boxWidth: 20,
                boxHeight: 5,
                boxPadding: 100,
                pointStyle: 'circle' as const
            }
        },
        title: {
            display: true,
        },
        tooltip: {
            enabled: true,
            intersect: false,
        },
    },
});