import { useEffect, useState } from "react";
import { Core_User } from "../../../generated/graphql";
import {
  IFilter,
  IFilterOption,
  IFilterSearchResults
} from "../../../lib/interfaces";
import { primaryBlue } from "../../config";
import { ShuriTableFilterSearch } from "../table";

type UserFilterSearchProps = {
  users: Core_User[];
  onFilterUpdate: (filter: IFilter) => void;
};

const filter_options: IFilterOption[] = [
  {
    label: "Company Name",
    value: "companyName"
  },
  {
    label: "Users Name",
    value: "name"
  },
  {
    label: "User ID",
    value: "id"
  }
];

export const UserFilterSearch = ({
  users,
  onFilterUpdate
}: UserFilterSearchProps) => {
  const [results, setResults] = useState<IFilterSearchResults[]>([]);
  useEffect(() => {
    return setResults(
      users.map((user) => ({
        link: `user/${user.id}`,
        title: `${user.first_name} • ${user.id}`,
        unique: user.id,
        sub1: {
          label: user.core_company?.name as string,
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill={primaryBlue}
            >
              <g clip-path="url(#clip0_11999_25019)">
                <path
                  d="M8.16797 10.5V2.5C8.16797 2.23478 8.06261 1.98043 7.87508 1.79289C7.68754 1.60536 7.43319 1.5 7.16797 1.5H5.16797C4.90275 1.5 4.6484 1.60536 4.46086 1.79289C4.27333 1.98043 4.16797 2.23478 4.16797 2.5V10.5M2.16797 3.5H10.168C10.7203 3.5 11.168 3.94772 11.168 4.5V9.5C11.168 10.0523 10.7203 10.5 10.168 10.5H2.16797C1.61568 10.5 1.16797 10.0523 1.16797 9.5V4.5C1.16797 3.94772 1.61568 3.5 2.16797 3.5Z"
                  stroke="#4490AE"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_11999_25019">
                  <rect
                    width="12"
                    height="12"
                    fill="white"
                    transform="translate(0.167969)"
                  />
                </clipPath>
              </defs>
            </svg>
          )
        }
      }))
    );
  }, [users]);
  return (
    <ShuriTableFilterSearch
      filter_options={filter_options}
      results={results}
      onFilterUpdate={onFilterUpdate}
    />
  );
};
