import {Alert, AlertTitle, Box, Button} from "@mui/material";
import {AllCameraStreams, AddCameraStream, Content, LayoutToggle, PageAppBar} from "../../../../components";
import {AppBackgroundColor} from "../../../../config";
import AddIcon from "@mui/icons-material/Add";
import {CameraStreamsContextProvider, LayoutEnum, useCameraStreamsContext} from "../../../../../lib";
import SkeletonLoader from "../../../../components/skeleton-loader";
import {useAuthUserContext} from "../../../../../lib/context/user";

const AllCameraStreamsPageContainer = () => {
    const {streams, toggleOpenForm, layout, setLayout} = useCameraStreamsContext()
    const handleLayoutChange = (layoutUpdate: LayoutEnum) => {
        setLayout(layoutUpdate)
    }

    return (
        <Box>
            <PageAppBar
                title="Camera Streams"
                sub="View camera streams from sites across the platform"
                ActionButton={() => (
                    <Button size="medium"
                            variant="contained"
                            color="info"
                            onClick={() => toggleOpenForm(true)}
                            startIcon={<AddIcon/>} sx={{borderRadius: '28px', marginRight: '10px'}}>
                        Add Stream
                    </Button>
                )}
            />
            <Content>
                <Box
                    sx={{
                        height: "auto",
                        backgroundSize: "cover",
                        backgroundColor: AppBackgroundColor,
                        margin: 4,
                    }}
                >
                    <Box
                        sx={{
                            color: "white",
                            marginBottom: 2,
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <LayoutToggle
                            layout={layout}
                            onLayoutSelect={handleLayoutChange}
                        />
                    </Box>

                    <AllCameraStreams
                        streams={streams}
                    />

                </Box>
            </Content>
            <AddCameraStream/>
        </Box>
    )
}

export const AllCameraStreamsPage = () => {
    const {loadingAuthUser: loading, authUserError: error, currentUser} = useAuthUserContext();
    return (
        <>
            {
                loading ? <SkeletonLoader /> :
                error ?
                    <Alert severity="error" variant="outlined">
                        <AlertTitle>Error</AlertTitle>
                        {error} — <strong>an error occurred!</strong>
                    </Alert>
                    :
                    <CameraStreamsContextProvider user_uuid={currentUser?.attributes?.sub ?? ''}>
                        <AllCameraStreamsPageContainer/>
                    </CameraStreamsContextProvider>
            }
        </>
    )
}