import {useEventLogContext} from "../../../../../lib/context";
import {Grid} from "@mui/material";
import {SiteTitleCard} from "../../../site-card";
import {LogSummaryDetails} from "./log-summary-details";

export function LogSummary() {
    const {eventlog} = useEventLogContext();

    return (
        <Grid container>
            <Grid item xs={12} md={5}>
                <SiteTitleCard site={eventlog?.core_site ?? {}}/>
            </Grid>

            <Grid item xs={12} md={7}>
                <LogSummaryDetails/>
            </Grid>
        </Grid>
    )
}