import {IFilter, useSitesPaginated} from "../../../lib";
import {SiteFilterSearch} from "../sites";
import {styled} from "@mui/material/styles";
import {useState} from "react";


export const StyledSiteFilterSearch= styled(SiteFilterSearch)(({theme}) => ({
    '.shuri-search-wrapper': {
        backgroundColor: 'red',
        '.shuri-search': {
            border: 'none',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        }
    }
}));

export function GlobalSearchFilter() {
    const [limit, setLimit] = useState(0);
    const {data, error, loading, updateSitesData} = useSitesPaginated(
        0,
        limit
    );

    const handleFilterChange = ({mappedFilters}: IFilter) => {
        setLimit(10)
        updateSitesData(mappedFilters);
    }

    return (
        <div className={'shuri-search-wrap'}>
            <StyledSiteFilterSearch
                global
                loading={loading}
                error={error}
                onFilterUpdate={handleFilterChange}
                sites={data?.core_site ?? []}/>
        </div>
    )
}