// EventBus.ts
type EventCallback = (...args: any[]) => void;

class EventBus {
    private events: { [eventName: string]: EventCallback[] } = {};

    public on(eventName: string, callback: EventCallback) {
        if (!this.events[eventName]) {
            this.events[eventName] = [];
        }
        this.events[eventName].push(callback);
    }

    public off(eventName: string, callback: EventCallback) {
        const eventCallbacks = this.events[eventName];
        if (eventCallbacks) {
            this.events[eventName] = eventCallbacks.filter(cb => cb !== callback);
        }
    }

    public emit(eventName: string, ...args: any[]) {
        const eventCallbacks = this.events[eventName];
        if (eventCallbacks) {
            eventCallbacks.forEach(callback => callback(...args));
        }
    }
}

export const eventBus = new EventBus();
