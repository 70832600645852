import {IFilter, IFilterOption, IFilterSearchResults} from "../../../../lib";
import React, {useEffect, useState} from "react";
import {primaryBlue} from "../../../config";
import {ShuriTableFilterSearch} from "../../table";

interface ISiteFilterSearchProps {
    sites: any[],
    onFilterUpdate: (filter: IFilter) => void,
    loading?: boolean;
    error?: any;
    global?: boolean
}

const filter_options: IFilterOption[] = [
    {
        label: 'Location',
        value: 'region_name'
    },
    {
        label: 'Site ID',
        value: 'site_id'
    },
    {
        label: 'Site Name',
        value: 'name'
    },
    {
        label: 'Municipality',
        value: 'municipality'
    }
]

export function SiteFilterSearch({sites, onFilterUpdate, loading, error, global}: ISiteFilterSearchProps)
{
    const [results, setResults] = useState<IFilterSearchResults[]>([]);
    useEffect(()=>{
        return setResults(
            sites.map(site => ({
                link: global ? `/?selectedSiteId=${site.id}` : `/site/${site.id}`,
                title: `${site.name} - ${site.site_id}`,
                unique: site.site_id,
                sub1: {
                    label: site.core_siteregion.region_name,
                    icon: <svg xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill={primaryBlue} viewBox="0 0 256 256"><path d="M128,64a40,40,0,1,0,40,40A40,40,0,0,0,128,64Zm0,64a24,24,0,1,1,24-24A24,24,0,0,1,128,128Zm0-112a88.1,88.1,0,0,0-88,88c0,31.4,14.51,64.68,42,96.25a254.19,254.19,0,0,0,41.45,38.3,8,8,0,0,0,9.18,0A254.19,254.19,0,0,0,174,200.25c27.45-31.57,42-64.85,42-96.25A88.1,88.1,0,0,0,128,16Zm0,206c-16.53-13-72-60.75-72-118a72,72,0,0,1,144,0C200,161.23,144.53,209,128,222Z"></path></svg>
                },
                sub2: {
                    label: site.core_sitestatus.site_status,
                    icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={primaryBlue} viewBox="0 0 256 256"><path d="M128,88a40,40,0,1,0,40,40A40,40,0,0,0,128,88Zm0,64a24,24,0,1,1,24-24A24,24,0,0,1,128,152Zm73.71,7.14a80,80,0,0,1-14.08,22.2,8,8,0,0,1-11.92-10.67,63.95,63.95,0,0,0,0-85.33,8,8,0,1,1,11.92-10.67,80.08,80.08,0,0,1,14.08,84.47ZM69,103.09a64,64,0,0,0,11.26,67.58,8,8,0,0,1-11.92,10.67,79.93,79.93,0,0,1,0-106.67A8,8,0,1,1,80.29,85.34,63.77,63.77,0,0,0,69,103.09ZM248,128a119.58,119.58,0,0,1-34.29,84,8,8,0,1,1-11.42-11.2,103.9,103.9,0,0,0,0-145.56A8,8,0,1,1,213.71,44,119.58,119.58,0,0,1,248,128ZM53.71,200.78A8,8,0,1,1,42.29,212a119.87,119.87,0,0,1,0-168,8,8,0,1,1,11.42,11.2,103.9,103.9,0,0,0,0,145.56Z"></path></svg>
                }
            }))
        );
    }, [sites])
    return (
        <ShuriTableFilterSearch
            loading={loading}
            error={error}
            filter_options={filter_options}
            results={results}
            onFilterUpdate={onFilterUpdate}/>
    )
}