import {formatDate, formatDecimal, IMetric} from "../../../../lib";
import {Box, Grid, Typography} from "@mui/material";
import {ShuriSpinnerText} from "../../loading";
import {BaseColorLight, primaryBlue} from "../../../config";
import {MetricsCard} from "../metrics-card";

interface IEquipmentMetricsProps {
    device_type: string,
    created_on?: Date | string,
    metrics: IMetric[],
    loading?: boolean
}

export function EquipmentMetrics({device_type, created_on, metrics, loading}: IEquipmentMetricsProps)
{
    return (
        <Grid container spacing={2}>
            {loading &&
                <Box sx={{
                    paddingTop: '6px'
                }}>
                    <ShuriSpinnerText loadingText={"Loading metrics, ☕ please wait..."} />
                </Box>
            }

            <Box sx={{
                paddingTop: '6px',
                paddingLeft: '20px'
            }}>
                {device_type} data for <strong style={{
                    color: primaryBlue
            }}>{formatDate(created_on)}</strong>
            </Box>

            {metrics.length > 0 ?
                metrics.map(metric => (
                    <Grid xs={12} item key={`battery-metric-${metric.id}`}>
                        <MetricsCard
                            title={metric.measurement}
                            value={metric.measurement === "Health" ? `${metric.unit}` : `${formatDecimal(metric.value ?? 0, 2)} ${metric.unit}` }
                        />
                    </Grid>
                )) :
                <Typography sx={{color: BaseColorLight}}>No ${device_type} metrics found</Typography>
            }
        </Grid>
    )
}