import {perPage} from "../../../config";
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";
import {IDetectionFilters} from "../../interfaces";

const ALLOWED_CLASSES: string[] = [
    'person',
    'car',
    'motorbike',
    'bus',
    'truck'
]

export const QUERY_DETECTIONS = gql`
    query QUERY_DETECTIONS($limit: Int, $offset: Int, $site_id: bigint, $created_at: timestamp, $created_at_end: timestamp) {
        core_detections(
            offset: $offset, 
            limit: $limit, 
            where: {
                site_id: {_eq: $site_id}, 
                created_on: {_gte: $created_at, _lte: $created_at_end},
                _or: [
                    ${ALLOWED_CLASSES.map(item => `{description: { _ilike: "%${item}%" } }`)}
                ]
            }
            order_by: {updated_at: desc, created_on: desc}
        ) {
            id
            media
            camera_id
            payload
            site_id
            created_on
            description
            core_camera {
                camera_name
                camera_id
            }
        }

        core_detections_aggregate(
            where: {
                site_id: {_eq: $site_id}, 
                created_on: {_gte: $created_at, _lte: $created_at_end},
                _or: [
                    ${ALLOWED_CLASSES.map(item => `{description: { _ilike: "%${item}%" } }`)}
                ]
            }) {
            aggregate {
                count
            }
        }
    }
`;

export const useDetections = (filters?: IDetectionFilters, page = 1, limit = perPage) => {
    const offset = page * limit;
    const day = filters?.created_at ? new Date(filters?.created_at) : new Date();
    day.setHours(23, 59, 59, 999);

    const {data, error, loading, refetch} = useQuery(QUERY_DETECTIONS, {
        variables: {
            offset,
            limit,
            ...filters,
            created_at_end: day.toISOString(),
            event_log_type: undefined
        },
        skip: !filters
    });

    return {data, error, loading, refetch};
}