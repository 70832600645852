import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import {
  HoverSideBarButtonColor,
  HoverSideBarButtonTextColor,
  SideBarButtonTextColor
} from "../../config";

export interface SideMenuItemProps {
  label?: string;
  link?: string;
  icon?: React.ReactNode;
  children?:any[]
}

export function SideMenuItem(props: SideMenuItemProps) {
  const { pathname } = useLocation();

  const isActive = pathname === props.link;
  return (
    <ListItemButton
      component={Link}
      to={props?.link!}
      sx={{
        background: isActive ? HoverSideBarButtonColor : undefined,
        marginBottom: "15px",
        marginLeft: "12px",
        color: "white",
        height: 35,
        cursor: "pointer",
        "&:hover": {
          background: HoverSideBarButtonColor
        },
        borderRadius: "30px"
      }}
    >
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText
        sx={{
          color: SideBarButtonTextColor,
          fontFamily: "Outfit",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "16px",
          "&:hover": { color: HoverSideBarButtonTextColor },
        }}
        primary={props.label}
      />
    </ListItemButton>
  );
}
