import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

import ErrorIcon from "@mui/icons-material/Error";
import { ADD_VISTS } from "../../../lib/hooks/visit/visit.hooks";
import { useSites } from "../../../lib/hooks";
import { useUsers } from "../../../lib/hooks";
import { useState } from "react";
import { useMutation } from "@apollo/client";

const Div = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  padding: theme.spacing(1),
}));

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function AddJob() {
  const [addVisit, { data, loading, error }] = useMutation(ADD_VISTS);
  const sites_data = useSites();
  const users_data = useUsers();
  const initalState = {
    site_id: "",
    ext_job_number: "",
    description: "",
    start_datetime: "",
    end_datetime: "",
    personale_id: "",
    comment: "",
  };
  const [state, setState] = useState(initalState);
  const [success, setSuccess] = React.useState(false);

  const handleReset = () => {
    setState(initalState);
  };
  const handleToastClose = () => {
    setSuccess(false);
  };

  const handleChange = (event: any) => {
    const value = event.target.value;
    switch (event.target.name) {
      case "site_id":
        setState({
          ...state,
          site_id: value,
        });
        break;

      case "ext_job_number":
        setState({
          ...state,
          ext_job_number: value,
        });
        break;

      case "description":
        setState({
          ...state,
          description: value,
        });
        break;

      case "start_datetime":
        setState({
          ...state,
          start_datetime: value,
        });
        break;
      case "end_datetime":
        setState({
          ...state,
          end_datetime: value,
        });
        break;

      case "personale_id":
        setState({
          ...state,
          personale_id: value,
        });
        break;

      case "comment":
        setState({
          ...state,
          comment: value,
        });
        break;

      default:
    }
  };
  if (sites_data.error || users_data.error) {
    return <h1>An error occurred </h1>;
  }
  if (sites_data.loading || users_data.loading) {
    return <p>Loading ....</p>;
  }
  return (
    <Container maxWidth="xl">
      <Card className="container" sx={{ bgcolor: "#cfe8fc" }}>
        <CardHeader
          title="Schedule Visit"
          sx={{ bgcolor: "#161221", color: "#ffffff" }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            background: "#26222A",
          }}
        >
          <Box
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              addVisit({
                variables: {
                  ext_job_number: state.ext_job_number,
                  description: state.description,
                  comment: state.comment,
                  site_id: state.site_id,
                  personale_id: state.personale_id,
                  end_datetime: state.end_datetime,
                  start_datetime: state.start_datetime,
                  created_on: new Date(),
                },
              });
              handleReset();
              setSuccess(true);
            }}
            sx={{ margin: 3 }}
          >
            <Grid container spacing={3} item xs={12}>
              <Grid item xs={4}>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ color: "#ADACAF" }}
                >
                  Job Description
                </InputLabel>
                <TextField
                  value={state.description}
                  name="description"
                  id="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                  sx={{
                    input: {
                      color: "black",
                      background: "white",
                      borderRadius: 2,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ color: "#ADACAF" }}
                >
                  Start Time
                </InputLabel>
                <TextField
                  value={state.start_datetime}
                  name="start_datetime"
                  id="start_datetime"
                  type="datetime-local"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                  sx={{
                    input: {
                      color: "black",
                      background: "white",
                      borderRadius: 2,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ color: "#ADACAF" }}
                >
                  End Time
                </InputLabel>
                <TextField
                  value={state.end_datetime}
                  name="end_datetime"
                  id="end_datetime"
                  type="datetime-local"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                  sx={{
                    input: {
                      color: "black",
                      background: "white",
                      borderRadius: 2,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ color: "#ADACAF" }}
                >
                  Site
                </InputLabel>
                <TextField
                  value={state.site_id}
                  name="site_id"
                  onChange={handleChange}
                  fullWidth
                  select
                  sx={{
                    background: "white",
                    borderRadius: 2,
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          backgroundColor: "white",
                          maxHeight: 300,
                        },
                      },
                    },
                  }}
                >
                  {sites_data.data.core_site.map((item: any) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.site_id ? item.site_id : " "} --{" "}
                        {item.name ? item.name : item.site_id}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ color: "#ADACAF" }}
                >
                  Personnel
                </InputLabel>
                <TextField
                  value={state.personale_id}
                  name="personale_id"
                  onChange={handleChange}
                  fullWidth
                  select
                  sx={{
                    background: "white",
                    borderRadius: 2,
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          backgroundColor: "white",
                          maxHeight: 300,
                        },
                      },
                    },
                  }}
                >
                  {users_data.data.core_user.map((item: any) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.first_name}{" "}
                        {item.last_name ? item.last_name : item.email}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ color: "#ADACAF" }}
                >
                  External Job Number
                </InputLabel>
                <TextField
                  value={state.ext_job_number}
                  name="ext_job_number"
                  id="date"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                  sx={{
                    input: {
                      color: "black",
                      background: "white",
                      borderRadius: 2,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ color: "#ADACAF" }}
                >
                  Comment
                </InputLabel>
                <TextField
                  rows={4}
                  id="comment"
                  name="comment"
                  value={state.comment}
                  fullWidth
                  multiline
                  variant="filled"
                  onChange={handleChange}
                  sx={{
                    color: "#ADACAF",
                    background: "white",
                    borderRadius: 2,
                  }}
                />
              </Grid>
              <Grid item xs={10}>
                <span>
                  <Div sx={{ color: "white" }}>
                    <ErrorIcon color="warning" /> Note : The REF should be
                    requested on the day of the visit
                  </Div>
                </span>
              </Grid>
              <Grid item xs={2}>
                <Button variant="contained" type="submit" color="success">
                  Schedule
                </Button>
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={success}
                  autoHideDuration={6000}
                  onClose={handleToastClose}
                >
                  <Alert
                    onClose={handleToastClose}
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    Job created successfully!
                  </Alert>
                </Snackbar>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
    </Container>
  );
}
