import gql from "graphql-tag";
import { ApolloClient, useMutation } from '@apollo/client';
import { useApolloClient, useQuery } from "@apollo/client";
import axios from "axios";


export const CREATE_PRIORITY_MUTATION = gql`
  mutation CreatePriority($alarm_id: bigint, $alarm_name: String, $model: String, $priority: String) {
    insert_core_rectifier_alarm_priority(objects: {alarm_id: $alarm_id, alarm_name: $alarm_name, model: $model, priority: $priority}) {
      returning {
        alarm_id
        alarm_name
        id
        model
        priority
      }
    }
  }
`;
export const UPDATE_PRIORITY_MUTATION =gql`
mutation UPDATE_ALARM($id: bigint , $priority_override: String ) {
  update_core_alarm(where: {id: {_eq: $id}}, _set: {priority_override: $priority_override}) {
    affected_rows
  }
}`
export const UPDATE_NOTIFICATION_MUTATION = gql`
  mutation MyMutation($id: uuid!, $viewed_on: timestamptz) {
    update_core_notification_by_pk(
      pk_columns: { id: $id }
      _set: { viewed_at: $viewed_on }
    ) {
      id
    }
  }
`;
const FILTERED_NOTIFICATION_QUERY = gql`
query MyQuery2($limit: Int = 10, $offset: Int = 0, $email: String, $viewed_at: Boolean ) {
  core_notification(limit: $limit, offset: $offset, order_by: {created_at: desc}, where: {email: {_eq: $email}, viewed_at: {_is_null: $viewed_at}}) {
    viewed_at
    user_id
    object_type
    object_id
    notification_type
    is_archive
    id
    email
    created_at
  }
  core_notification_aggregate(where: {email: {_eq: $email}, viewed_at: {_is_null: $viewed_at}}) {
    aggregate {
      count
    }
  }
}

`;

const ALARM_PRIORITY_QUERY=gql`
query ALARM_QUERY {
  core_alarm {
    alarm_id
    alarm_name
    equipment_with_alarms_id
    id
    priority
    priority_override
  }
}
`

const EQUIPMENT_WITH_ALARM_QUERY=gql`
query EQUIPEMENT_QUERY {
  core_equipment_with_alarm {
    model_id
    id
    core_equipmentmodel {
      make_id
      model_number
      id
      equipment_model
    }
  }
}
`
const CORE_ALARM_QUERY=gql`
query MyQuery14($id: bigint ) {
  core_alarm(where: {equipment_with_alarms_id: {_eq: $id}}) {
    id
    priority
    priority_override
    alarm_name
    alarm_id
    equipment_with_alarms_id
  }
}
`

export const useGetNotifications = () => {


  const fetchData = async(
    client: ApolloClient<any>,
    variables?: QueryVariables
  )=>{
    try {
      const { data } = await client.query<any, QueryVariables>({
        query: FILTERED_NOTIFICATION_QUERY,
        variables,
      });
  
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
  const updateNotificationData = async (viewed_at: boolean,email:any) => {
    // await refetch({
    //   email,
    //   viewed_at,
    // });
  };
  return {updateNotificationData,fetchData };
};

export const useSendNotification = () => {
  const SEND_MESSAGE_API_URL:any =process.env.REACT_APP_SEND_MESSAGE_API_URL;
  const sendNotification = async (message: any) => {
    try {
      const { data } = await axios.post(SEND_MESSAGE_API_URL, message);
      return data;
    } catch (error) {}
  };
  return { sendNotification };
};




interface QueryVariables {
  // Define your query variables here
  email:any,
  viewed_at:any,
  offset:number,
  limit:number
}

interface QueryResult {
  is_archive: any;
  notification_type: any;
  object_id: any;
  object_type: any;
  viewed_at: any;
  created_at: any;
  id: any;
  email: any;
}


export const useCreateRectiferPriority=()=>{

}

export const useCreatePriorityMutation = () => {
  const [createPriorityMutation, { loading, error, data }] = useMutation(UPDATE_PRIORITY_MUTATION);
  const updateriority = async (variables:any) => {
    try {
      const response = await createPriorityMutation({ variables });
      return response.data;
    } catch (error) {
      // Handle error as needed
      console.error('Error creating priority:', error);
      throw new Error('Error creating priority');
    }
  };

  return { updateriority, loading, error, data };
};


export const useGetAlarmPriorities =()=>{
  const {loading,error,data,refetch} = useQuery(ALARM_PRIORITY_QUERY)
  return {loading,error,data,refetch}
}


interface GetAlarmVariables{
  id:number
}
export const useGetEquitmentWithAlarms=()=>{
  const {loading,error,data} = useQuery(EQUIPMENT_WITH_ALARM_QUERY)
  const fetch_alarms = async(
    client: ApolloClient<any>,
    variables: GetAlarmVariables
  )=>{
    try {
      const { data } = await client.query<any, any>({
        query: CORE_ALARM_QUERY,
        variables,
      });
  
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
  return {loading,error,data,fetch_alarms}
}
