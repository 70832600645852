import {Box, Button, Grid, IconButton, Typography} from "@mui/material";
import {BaseColorLight, ButtonBaseColor, ButtonBaseColorLight, InputBaseColorLight} from "../../config";
import {Close} from "@mui/icons-material";
import * as React from "react";
import {IEquipment} from "../../../lib/interfaces";
import {InventoryDetails} from "./inventory-details";

interface IViewInventoryProps {
    inventory: IEquipment;
    onDismiss?: () => void;
}

export function ViewInventory({onDismiss, inventory}: IViewInventoryProps) {
    return (
        <Box className="shuri-drawer-wrapper">
            <Box className="shuri-drawer-content">
                <Grid container spacing={2} sx={{
                    paddingBottom: '30px'
                }}>
                    <Grid item xs={10}>
                        <Typography sx={{
                            fontFamily: 'Outfit',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            fontSize: '18px',
                            lineHeight: '28px',
                            color: InputBaseColorLight
                        }}>Inventory Details </Typography>
                        <Typography sx={{
                            fontFamily: 'Outfit',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: BaseColorLight
                        }}>Inventory details view for: {inventory.serial_number}</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                    }}>
                        <IconButton aria-label="delete" size="large" color="secondary" onClick={onDismiss}>
                            <Close fontSize="inherit"/>
                        </IconButton>
                    </Grid>
                </Grid>

                <InventoryDetails
                    inventory={inventory}
                />

            </Box>
            <Box className="shuri-drawer-actions">
                <Box/>
                <Button
                    onClick={onDismiss}
                    sx={{
                        color: ButtonBaseColorLight,
                        borderColor: ButtonBaseColor
                    }}
                    variant="outlined"
                    size="large">
                    Close
                </Button>
            </Box>

        </Box>
    )
}