import {useForm, useYupValidateForm} from "../../../../../lib/form/hooks";
import {forwardRef, useEffect, useImperativeHandle} from "react";
import {FormControl, Grid, IconButton} from "@mui/material";
import {StyledFormFieldError, StyledFormLabel, StyledFormTextField} from "../../../form";
import {ICameraInput} from "../partials";
import {errorBase} from "../../../../config";
import * as Yup from "yup";

interface ICameraFormProps {
    index: number;
    camera: ICameraInput;
    onCameraChange: (index: number, camera: ICameraInput) => void,
    onRemove: (index: number) => void,
    onFormValidate?: (index: number, hasErrors: boolean) => void,
}

export interface ICameraFormRef {
    handleFormValidate: () => Promise<void>;
    hasErrors: boolean;
}

const validationSchema = Yup.object().shape({
    camera_name: Yup.string().required('Name is required'),
    camera_id: Yup.string().required('Camera ID is required'),
    box_id: Yup.string().required('Box Id is required'),
    video_stream_name: Yup.string().required('Video stream name is required'),
});

export const AddCameraForm = forwardRef<ICameraFormRef | null, ICameraFormProps>(
    ({camera, index, onCameraChange, onRemove, onFormValidate}, ref) => {
        const {inputs, handleChange} = useForm(camera);
        const {errors, validateField, validateForm, hasErrors} = useYupValidateForm(inputs, validationSchema);

        useEffect(() => {
            onCameraChange(index, inputs);
            handleFormValidate();
        }, [inputs]);

        const handleFormValidate = async () => {
            await validateForm();
        }

        useImperativeHandle(ref, () => ({
            handleFormValidate,
            hasErrors
        }));

        return (
            <Grid container spacing={2} sx={{marginBottom: '20px'}}>
                <Grid item xs={12} md={6} lg={4}>
                    <FormControl color="secondary" fullWidth required>
                        <StyledFormLabel>Camera Name</StyledFormLabel>
                        <StyledFormTextField
                            type="text"
                            size='small'
                            placeholder="Enter camera name"
                            name="camera_name"
                            value={inputs.camera_name}
                            onChange={handleChange}
                            onBlur={() => validateField('camera_name')}
                            error={!!errors.camera_name}
                            helperText={errors.camera_name}
                        />
                        {errors.box_id && <StyledFormFieldError>{errors.box_id}</StyledFormFieldError>}
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                    <FormControl color="secondary" fullWidth required>
                        <StyledFormLabel>Camera ID</StyledFormLabel>
                        <StyledFormTextField
                            disabled={!!camera.uid}
                            type="text"
                            size='small'
                            placeholder="Enter camera ID"
                            name='camera_id'
                            value={inputs.camera_id}
                            onChange={handleChange}
                            onBlur={() => validateField('camera_id')}
                            error={!!errors.camera_id}
                            helperText={errors.camera_id}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4} position="relative">
                    {!camera.uid &&
                        <IconButton size='small' aria-label="delete" color="primary" className='label-action'
                                    onClick={() => onRemove(index)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={errorBase}
                                 viewBox="0 0 256 256">
                                <path
                                    d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path>
                            </svg>
                        </IconButton>
                    }
                    <FormControl color="secondary" fullWidth required>
                        <StyledFormLabel>
                            Video Stream Name
                        </StyledFormLabel>
                        <StyledFormTextField
                            type="text"
                            size='small'
                            placeholder="Enter Video Stream Name"
                            name='video_stream_name'
                            value={inputs.video_stream_name}
                            onChange={handleChange}
                            onBlur={() => validateField('video_stream_name')}
                            error={!!errors.video_stream_name}
                            helperText={errors.video_stream_name}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        )
    });