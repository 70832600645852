import {IJob } from "../../../../../lib/interfaces";
import {useEffect, useState} from "react";
import {useJobsContext, useNotification, DELETE_JOB_MUTATION} from "../../../../../lib";
import {
    Box, Button, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from "@mui/material";
import {ShuriStyledDialog, ShuriStyledDialogBackdrop} from "../../../dialog";
import {
    BaseColor,
    ButtonBaseColor,
    ButtonBaseColorLight,
    errorBase,
    InputBaseColor,
    InputBaseColorLight
} from "../../../../config";
import {ShuriSpinnerText} from "../../../loading";
import {useMutation} from "@apollo/client";

interface IRemoveJobActionProps {
    onClose: () => void;
    onRefreshJobs?: () => void;
    job: IJob
}

export function RemoveJobAction({onClose, job, onRefreshJobs}: IRemoveJobActionProps)
{
    const [open, setOpen] = useState(false);
    const {notify} = useNotification();
    const {  onDeleteJob } = useJobsContext();

    const [deleteJob, {loading, error}] = useMutation(DELETE_JOB_MUTATION, {
        variables: {
            id: job.id
        }
    })

    useEffect(() => {
        if (error) {
            notify({
                status: "error",
                open: true,
                message: `An error occurred trying to remove job, try again later!`,
            });
        }
    }, [error])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        onClose();
    };

    const handleRemove = async ()=> {
        await deleteJob({
            variables: {
                id: job.id
            },
            onCompleted(removed){
                if (removed) {
                    if (onRefreshJobs) {
                        onRefreshJobs()
                    }
                    onDeleteJob(job);
                    onClose();
                    notify({
                        status: "success",
                        open: true,
                        message: `Job removed successfully!`,
                    });
                }
            },
            onError(error) {
                notify({
                    status: "error",
                    open: true,
                    message: `An error occurred trying to remove job, try again later!`,
                });
            }
        })
    }

    return (
        <>
            <IconButton
                onClick={() => handleClickOpen()}
                aria-label="Delete Job"
                size="small"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill={InputBaseColor}
                    viewBox="0 0 256 256"
                >
                    <path d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path>
                </svg>
            </IconButton>

            <ShuriStyledDialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                BackdropComponent={ShuriStyledDialogBackdrop}
            >
                <DialogTitle id="alert-dialog-title">
                    <IconButton sx={{
                        background: `${errorBase}50`,
                        '&:hover': {
                            background: `${errorBase}60`,
                        },
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={errorBase}
                             viewBox="0 0 256 256">
                            <path
                                d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm-8-80V80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,172Z"></path>
                        </svg>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description-title" className="shuri-dialog-title">
                        {`Remove job?`}
                    </DialogContentText>

                    <DialogContentText className="shuri-dialog-description" id="alert-dialog-description">
                        Are you sure you would like to remove job {job.ext_job_number}?
                        Removing this job will delete the job and it's children from the platform.
                    </DialogContentText>

                    {
                        loading &&
                        <Box sx={{
                            marginTop: '30px'
                        }}>
                            <ShuriSpinnerText
                                loadingTextSx={{
                                    color: InputBaseColorLight,
                                }}
                                loadingText={"Removing..."}
                            />
                        </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemove}
                            variant="contained"
                            autoFocus
                            size="large"
                            sx={{
                                color: BaseColor,
                                borderColor: errorBase,
                                backgroundColor: errorBase,
                                textTransform: 'capitalize',
                                '&:hover': {
                                    background: `${errorBase}95`,
                                },
                            }}
                    >Yes, Remove</Button>

                    <Button onClick={handleClose}
                            variant="outlined"
                            size="large"
                            sx={{
                                color: ButtonBaseColorLight,
                                borderColor: ButtonBaseColor,
                                textTransform: 'capitalize',
                                '&:hover': {
                                    borderColor: `${ButtonBaseColor}95`,
                                },
                            }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </ShuriStyledDialog>
        </>
    )
}