import gql from "graphql-tag";
import {getCurrentDate, getTomorrowDate} from "../../filters";
import {useQuery} from "@apollo/client";
import {perPage} from "../../../config";
import { off } from "process";

export const SITES_DISRUPTIONS_QUERY = gql`
    query SITES_DISRUPTIONS($site_id: bigint, $date: timestamptz, $limit: Int, $offset: Int) {
        core_disruptions(where: {start_date: {_gte: $date}, site_id: {_eq: $site_id}} limit: $limit, offset: $offset,order_by: {start_date: asc},distinct_on: start_date,) {
            id
            description
            end_date
            root_cause
            start_date
            core_disruptionstype {
                id
                name
            }
            core_site {
                id
                site_id
                name
            }
        }

        core_disruptions_aggregate(where: {start_date: {_gte: $date}, site_id: {_eq: $site_id}} limit: $limit, offset: $offset,order_by: {start_date: asc},distinct_on: start_date,) {
            aggregate {
                count
            }
        }
    }
`;

export const SITE_DISRUPTIONS_QUERY = gql`
query SITES_DISRUPTIONS($site_id: bigint, $start_date: timestamptz, $end_date: timestamptz) {
    core_disruptions(limit: 12, distinct_on: start_date, where: {start_date: {_gte: $start_date}, 
    end_date: {_lt: $end_date}, site_id: {_eq: $site_id}}, order_by: {start_date: desc}) {
      start_date
      end_date
    }
  }
`;




export const useSiteDisruptions = (site_id: any, date = getCurrentDate(), page=1, limit=perPage) => {
    const offset = page * limit;
    const {data, error, loading} = useQuery(SITES_DISRUPTIONS_QUERY, {
        variables: {site_id, date, offset, limit},
    });
    return {data, error, loading};
}
export const useSiteDisruption = (site_id: any, start_date = getCurrentDate(), end_date = getTomorrowDate()) => {
    const {data, error, loading} = useQuery(SITE_DISRUPTIONS_QUERY, {
        variables: {site_id, start_date, end_date},
    });
    return {data, error, loading};
}
