import {IEquipment, ITablePropsInterface} from "../../../lib/interfaces";
import {
    Table,
    TableBody,
    TableFooter,
    TableHead,
    TableRow
} from "@mui/material";
import {ShuriTableRowStates, StyledTableCell, StyledTableRow} from "../table";
import {siteEquipmentStatusColor} from "../../../lib/helpers/site/equipment_status";
import * as React from "react";
import ShuriTablePagination from "../table/ShuriTablePagination";
import {InventoriesTableAction} from "./inventories-table-action";

interface IInventoriesTableProps extends ITablePropsInterface {

}

export function InventoriesTable({
                                     data,
                                     page,
                                     rowsPerPage,
                                     handleChangePage,
                                     loading,
                                     error,
                                     toggleRefresh
                                 }: IInventoriesTableProps) {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <StyledTableCell>Equipment Type</StyledTableCell>
                    <StyledTableCell>Name & Model</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Condition</StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <ShuriTableRowStates
                    colSpan={5}
                    data={data?.core_equipment ?? []}
                    loading={loading}
                    error={error}
                >
                    <>
                        {data?.core_equipment.map((equipment: IEquipment) => (
                            <StyledTableRow key={equipment.id} sx={{
                                color: 'white'
                            }}>
                                <StyledTableCell component="th" scope="row">
                                    {equipment?.core_equipmenttype?.name}
                                </StyledTableCell>
                                <StyledTableCell>{equipment?.core_equipmentmodel?.equipment_model}</StyledTableCell>
                                <StyledTableCell
                                    sx={{color: siteEquipmentStatusColor(equipment?.core_equipmentstatus?.equipment_status)}}>{equipment?.core_equipmentstatus?.equipment_status}</StyledTableCell>
                                <StyledTableCell>{equipment?.core_equipmentcondition?.condition}</StyledTableCell>
                                <StyledTableCell align="right">
                                    <InventoriesTableAction equipment={equipment} refreshInventories={toggleRefresh}/>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </>
                </ShuriTableRowStates>
            </TableBody>

            {
                (data?.core_equipment_aggregate?.aggregate?.count || 0) > rowsPerPage &&
                <TableFooter>
                    <TableRow sx={{border: 0, m: 1}}>
                        <ShuriTablePagination
                            colSpan={5}
                            count={data?.core_equipment_aggregate?.aggregate?.count ?? 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    </TableRow>
                </TableFooter>
            }
        </Table>
    )
}