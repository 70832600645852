import {IEquipment, IEquipmentImage, ISelectInput} from "../../../lib/interfaces";
import {Button, FormControl, Grid} from "@mui/material";
import {
    StyledFormLabel,
    StyledFormTextField,
    ShuriFormUpload,
    useFileUploadPreviews,
    StyledFormFieldError
} from "../form";
import {ShuriMuiFormSelect} from "../form/form-select/ShuriMuiFormSelect";
import {useForm, useYupValidateForm} from "../../../lib/form/hooks";
import {FormEvent, useState} from "react";
import {IEquipmentInput} from "../sites";
import React, {useEffect} from "react";
import {API} from "aws-amplify";
import {getAuthData} from "../../../lib/authData";
import * as Yup from "yup";

interface IInventoriesForm {
    equipment?: IEquipmentInput,
    equipmentsTypes: ISelectInput[],
    equipmentModels: ISelectInput[],
    equipmentStatuses: ISelectInput[],
    equipmentConditions: ISelectInput[],
    equipmentParts: ISelectInput[],
    has_submit?: boolean,
    formRef?: React.Ref<HTMLFormElement>,
    onFormSubmit: (values: IEquipmentInput, files?: FileList) => void,
    onFormErrors?: (hasErrors: boolean) => void,
    previews?: string[],
    equipment_id?: number,
    uploading?: boolean
}

const inventoryValidationSchema = Yup.object().shape({
    type_id: Yup.number().required('Equipment Type is required'),
    model_id: Yup.number().required('Equipment Model is required'),
    serial_number: Yup.string().required('Serial number is required'),
    condition_id: Yup.number().required('Equipment Condition is required'),
    part_id: Yup.number().optional(),
    status_id: Yup.number().required('Equipment Status is required'),
});

export function InventoriesForm({
                                    equipment,
                                    equipmentsTypes,
                                    equipmentModels,
                                    equipmentStatuses,
                                    equipmentConditions,
                                    equipmentParts,
                                    onFormSubmit,
                                    onFormErrors,
                                    has_submit = true,
                                    formRef,
                                    previews = [],
                                    equipment_id,
                                    uploading
                                }: IInventoriesForm) {
    const {inputs, handleChange} = useForm(equipment);
    const {errors, validateField, validateForm, hasErrors} = useYupValidateForm(inputs, inventoryValidationSchema);
    const [files, setFiles] = useState<FileList | null>(null);
    const [previewsData, setPreviewsData] = useState<string[]>([]);
    const {filePreviews} = useFileUploadPreviews(files);
    const thumbnails = [...previewsData, ...filePreviews]
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (await validateForm()) {
            return onFormSubmit(inputs, files ? files : undefined);
        }
        return onFormErrors ? onFormErrors(true) : null
    };

    const handleFileChange = (images: FileList | null) => {
        setFiles(images)
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!previews) return;
            const {idToken} = await getAuthData();
            const token = idToken.jwtToken;
            const requestData = {
                headers: {
                    Authorization: token ? `Bearer ${token}` : "",
                },
            };
            const tempImageUrls = await Promise.all(
                previews
                    .filter((preview) => preview.startsWith("inv/"))
                    .map(async (preview) => {
                        try {
                            const response = await API.get(
                                "shurikonektfeapi",
                                `/get-share-url?objectKey=${preview}`,
                                requestData
                            );
                            return response;
                        } catch (error) {
                            console.error(`Failed to fetch URL:  ${error}:`);
                        }
                    })
            );
            setPreviewsData(tempImageUrls);
        };
        fetchData();
    }, [previews]);

    return (
        <form ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={2} sx={{marginBottom: '20px'}}>
                <Grid item xs={12}>
                    <FormControl color="secondary" size="small" fullWidth required>
                        <StyledFormLabel>Equipment Type</StyledFormLabel>
                        <ShuriMuiFormSelect
                            placeholder='Select Equipment Type'
                            options={equipmentsTypes}
                            value={inputs.type_id}
                            handleChange={handleChange}
                            name='type_id'
                            handleBlur={() => validateField('type_id')}
                            error={!!errors.type_id}
                        />
                        {errors.type_id && <StyledFormFieldError>{errors.type_id}</StyledFormFieldError>}
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl color="secondary" size="small" fullWidth required>
                        <StyledFormLabel>Model</StyledFormLabel>
                        <ShuriMuiFormSelect
                            placeholder='Select Equipment Model'
                            options={equipmentModels.filter(model => model.type_id === inputs.type_id)}
                            name="model_id"
                            value={inputs.model_id}
                            handleChange={handleChange}
                            handleBlur={() => validateField('model_id')}
                            error={!!errors.model_id}
                        />
                        {errors.model_id && <StyledFormFieldError>{errors.model_id}</StyledFormFieldError>}
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl color="secondary" fullWidth required>
                        <StyledFormLabel>
                            Serial Number
                        </StyledFormLabel>
                        <StyledFormTextField
                            type="text"
                            size='small'
                            placeholder="Enter Serial Number"
                            name="serial_number"
                            value={inputs.serial_number}
                            onChange={handleChange}
                            onBlur={() => validateField('serial_number')}
                            error={!!errors.serial_number}
                            helperText={errors.serial_number}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl color="secondary" fullWidth>
                        <StyledFormLabel>
                            Owner (Optional)
                        </StyledFormLabel>
                        <StyledFormTextField
                            type="text"
                            size='small'
                            placeholder="Select owner"
                            name="owner"
                            value={inputs.owner}
                            onChange={handleChange}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl color="secondary" size="small" fullWidth required>
                        <StyledFormLabel>
                            Condition
                        </StyledFormLabel>
                        <ShuriMuiFormSelect
                            placeholder='Select Condition'
                            options={equipmentConditions}
                            name="condition_id"
                            value={inputs.condition_id}
                            handleChange={handleChange}
                            handleBlur={() => validateField('condition_id')}
                            error={!!errors.condition_id}
                        />
                        {errors.condition_id && <StyledFormFieldError>{errors.condition_id}</StyledFormFieldError>}
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl color="secondary" size="small" fullWidth>
                        <StyledFormLabel>
                            Part
                        </StyledFormLabel>
                        <ShuriMuiFormSelect
                            placeholder='Select Part (Optional)'
                            options={equipmentParts}
                            name="part_id"
                            value={inputs.part_id}
                            handleChange={handleChange}
                            handleBlur={() => validateField('part_id')}
                            error={!!errors.part_id}
                        />
                        {errors.part_id && <StyledFormFieldError>{errors.part_id}</StyledFormFieldError>}
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl color="secondary" size="small" fullWidth required>
                        <StyledFormLabel>
                            Status
                        </StyledFormLabel>
                        <ShuriMuiFormSelect
                            placeholder='Select Status'
                            options={equipmentStatuses}
                            name="status_id"
                            value={inputs.status_id}
                            handleChange={handleChange}
                            handleBlur={() => validateField('status_id')}
                            error={!!errors.status_id}
                        />
                        {errors.status_id && <StyledFormFieldError>{errors.status_id}</StyledFormFieldError>}
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <ShuriFormUpload
                        uploading={uploading}
                        values={thumbnails}
                        onChange={handleFileChange}
                        onDrop={handleFileChange}
                    />
                </Grid>

                {
                    has_submit && <Grid item xs={12}>
                        <FormControl color="secondary" size="small" fullWidth required>
                            <Button
                                disabled={hasErrors}
                                type="submit"
                                color="info"
                                variant="contained"
                                size="large">
                                Save Changes
                            </Button>
                        </FormControl>
                    </Grid>
                }

            </Grid>
        </form>
    )
}


export function mapEquipmentInput(equipment?: IEquipment): IEquipmentInput {
    return {
        type_id: equipment?.type_id,
        model_id: equipment?.model_id,
        equipment_name: equipment?.equipment_name ?? '',
        owner: "",
        status_id: equipment?.status_id,
        condition_id: equipment?.condition_id,
        part_id: equipment?.part_id ?? undefined,
        serial_number: equipment?.serial_number ?? ''
    }
}

export const mapInventoryImages = (images: IEquipmentImage[]) => {
    if (!images) return [];
    return images.filter(image => image.image).map(image => image.image);
}