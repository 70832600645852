import React, {ReactNode} from "react";
import {Box, Chip, Typography} from "@mui/material";
import {
    AppBackgroundColor,
    errorBase,
    InputBaseColor,
    MetricsCardBackground,
    successBase
} from "../../config";
import {alarmColor} from "../rectifier";

interface MetricsCardProp {
    /**
     * Usage title
     */
    title: string,

    /**
     * Usage unit of measure e.g. ZAR, kWh, etc
     */
    value: string,

    /**
     * Usage percentage change value
     */
    percentageChange?: number

    /**
     * TODO: Used when a metrics card have a icon to add more context
     */
    icon?: ReactNode

    status?: string
}

export function MetricsCard({title, value, percentageChange, status}: MetricsCardProp) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: '24px',
                backgroundColor: MetricsCardBackground,
                boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
                borderRadius: '8px'
            }}>
            <Typography
                sx={{
                    width: '100%',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: InputBaseColor
                }}>
                {title}
            </Typography>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                <Typography
                    sx={{
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '28px',
                        lineHeight: '44px',
                        letterSpacing: '-0.02em',
                        color: 'white',
                        marginRight: '10px'
                    }}>
                    {value}
                </Typography>

                {
                    percentageChange && (
                        <Chip
                            sx={{
                                backgroundColor: AppBackgroundColor,
                                fontWeight: '500',
                                fontSize: '10px',
                                lineHeight: '18px',
                                border: 'none',
                                padding: '2px',
                            }}
                            icon={
                                percentageChange > 0 ?
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         width="10"
                                         height="10"
                                         fill={successBase}
                                         viewBox="0 0 256 256">
                                        <path
                                            d="M205.66,117.66a8,8,0,0,1-11.32,0L136,59.31V216a8,8,0,0,1-16,0V59.31L61.66,117.66a8,8,0,0,1-11.32-11.32l72-72a8,8,0,0,1,11.32,0l72,72A8,8,0,0,1,205.66,117.66Z"></path>
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         width="10"
                                         height="10"
                                         fill={errorBase}
                                         viewBox="0 0 256 256">
                                        <path
                                            d="M205.66,149.66l-72,72a8,8,0,0,1-11.32,0l-72-72a8,8,0,0,1,11.32-11.32L120,196.69V40a8,8,0,0,1,16,0V196.69l58.34-58.35a8,8,0,0,1,11.32,11.32Z"></path>
                                    </svg>
                            }
                            label={`${Math.abs(percentageChange)}%`}
                            color={percentageChange > 0 ? 'success' : 'error'}
                            size="small"
                            variant="outlined"
                        />
                    )
                }

                {
                    status && (
                        <Chip
                            sx={{
                                backgroundColor: AppBackgroundColor,
                                fontWeight: '500',
                                fontSize: '10px',
                                lineHeight: '18px',
                                border: 'none',
                                padding: '2px',
                            }}
                            label={status}
                            color={alarmColor(status) }
                            size="small"
                            variant="outlined"
                        />
                    )
                }
            </Box>
        </Box>
    )
}
