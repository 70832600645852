import {ScheduledVisitsTable} from "../../scheduled-visits";
import {useParams} from "react-router-dom";
import {useShuriPagination, useSiteVisits} from "../../../../lib/hooks";
import {getFirstDateOfCurrentMonth} from "../../../../lib/filters";

export default function SiteScheduledVisits()
{
    const {id} = useParams();
    const {page, rowsPerPage, handleChangePage } = useShuriPagination(4);
    const date = getFirstDateOfCurrentMonth();
    const {data, loading, error} = useSiteVisits(id, date, page, rowsPerPage);

    return (
        <>
            <ScheduledVisitsTable
                data={data}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                loading={loading}
                error={error}
            />
        </>
    )
}