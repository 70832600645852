import { Box, Button, InputLabel, TextField, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../../layout/AuthLayout";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const navigate = useNavigate();

  const onForgotPassword = async () => {
    setErrorMessage(null);
    if (!email.length) {
      return;
    }
    try {
      setIsLoading(true);
      await Auth.forgotPassword(email);
      window.sessionStorage.setItem("FORGOT_PASSWORD", email);
      navigate("/reset-password");
    } catch (error: any) {
      setErrorMessage(error.nessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout title="Forgot Password">
      <Box marginY={3}>
        <InputLabel sx={{ color: "white" }}>Email</InputLabel>
        <TextField
          fullWidth
          color="info"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          variant="outlined"
          placeholder="Enter password"
          InputLabelProps={{ style: { color: "gray" } }}
          InputProps={{ style: { color: "white" } }}
        />
      </Box>

      <Button
        variant="contained"
        sx={{ backgroundColor: "#4490AE", color: "black", width: "360px" }}
        onClick={onForgotPassword}
      >
        {isLoading ? "Loading" : "Continue"}
      </Button>
      {errorMessage && <Typography>{errorMessage}</Typography>}
    </AuthLayout>
  );
};

export default ForgotPassword;
