import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import {TableBackgroundColor} from "../../config";

export default function SkeletonLoader() {
    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            <Skeleton />
            <Skeleton animation="wave" sx={{backgroundColor: TableBackgroundColor}}  />
            <Skeleton animation={false} sx={{backgroundColor: TableBackgroundColor}}  />

            <Skeleton animation="wave" width={'100%'} height={"40vh"} sx={{backgroundColor: TableBackgroundColor}} />
            <Skeleton animation="wave" width={'100%'} height={"40vh"} sx={{backgroundColor: TableBackgroundColor}}  />
        </Box>
    );
}