import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

export const SITES_LAST_SEEN_QUERY = gql`
query MyQuery($id: bigint) {
  core_sitestate(order_by: {created_on: desc}, limit: 1, where: {site_id: {_eq: $id}}) {
    site_id
    lastseen
    created_on
    core_sitestatus {
      site_status
      id
    }
    core_statereasons {
      core_reasonenum {
        id
        reason
      }
    }
  }
}
`;

export const useGetSiteLastSeen = (id: any) => {
  const { data, error, loading } = useQuery(SITES_LAST_SEEN_QUERY, {
    variables: {
      id
    },
  });
  return { data, error, loading };
};
