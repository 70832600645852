import {useUploadMedia} from "../media";
import {useNotification} from "../../context";
import {useEffect, useState} from "react";

export const useAddInventoryImages = () => {
    const {uploadMedia, urls, error, loading: uploading} = useUploadMedia();
    const {notify} = useNotification();
    const [success, setSuccess] = useState(false);
    const addInventoryImages = async ({files,id}: { files: FileList, id: number }) => {
        try {
            const params = {
                files,
                data: {
                    equipmentID: id
                },
            };
            await uploadMedia(params)
        } catch (e) {
            notify({
                open: true,
                status: 'error',
                message: "::: Inventory images upload error!"
            });
        }
    }

    useEffect(()=>{
        if (urls){
            setSuccess(true);
            notify({
                open: true,
                status: 'success',
                message: "Inventory images upload successfully!"
            });
        }
        if (error){
            setSuccess(false);
            notify({
                open: true,
                status: 'error',
                message: `Inventory images upload error! ${error}`
            });
        }
    }, [urls, error])

    return {addInventoryImages, urls, error, success, uploading}
}