import {ActionRef, ShuriActionMenu, StyledMenuItem} from "../action-menu";
import {ListItemIcon, ListItemText} from "@mui/material";
import {Edit, Visibility} from "@mui/icons-material";
import * as React from "react";
import {IEquipment} from "../../../lib/interfaces";
import {useRef, useState} from "react";
import {ShuriStyledDrawer} from "../drawer";
import {CreateInventories} from "./create-inventories";
import {RemoveInventoryAction} from "./inventory-actions";
import {ViewInventory} from "./view-inventory";
interface IInventoriesTableActionProps {
    equipment: IEquipment;
    refreshInventories?: ()=> void
}
export function InventoriesTableAction({equipment, refreshInventories}: IInventoriesTableActionProps)
{
    const [editOpen, setEditOpen] = useState(false);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const actionRef = useRef<ActionRef>(null);

    const handleClose = ()=>{
        if (actionRef.current) {
            actionRef.current.handleClose();
        }
    }
    return (
        <>
            <ShuriActionMenu id={`inventories-table-action-${equipment.id}`} ref={actionRef}>
                <StyledMenuItem onClick={()=>{
                    setDetailsOpen(true);
                    handleClose();
                }}>
                    <ListItemIcon>
                        <Visibility color="secondary"/>
                    </ListItemIcon>
                    <ListItemText>View Inventory</ListItemText>
                </StyledMenuItem>
                <StyledMenuItem onClick={()=>{
                    setEditOpen(true);
                    handleClose();
                }}>
                    <ListItemIcon>
                        <Edit color="secondary" />
                    </ListItemIcon>
                    <ListItemText>Edit Inventory</ListItemText>
                </StyledMenuItem>
                <RemoveInventoryAction
                    inventory={equipment}
                    onClose={handleClose}
                    onRefresh={refreshInventories}
                />
            </ShuriActionMenu>

            <ShuriStyledDrawer
                anchor="right"
                open={editOpen}
                onClose={()=>setEditOpen(false)}
            >
                <CreateInventories
                    refreshInventories={refreshInventories}
                    dismissForm={()=> setEditOpen(false)}
                    equipment={equipment}
                />
            </ShuriStyledDrawer>

            <ShuriStyledDrawer
                anchor="right"
                open={detailsOpen}
                onClose={()=>setDetailsOpen(false)}
            >
                <ViewInventory
                    inventory={equipment}
                    onDismiss={()=> setDetailsOpen(false)}
                />
            </ShuriStyledDrawer>
        </>
    )
}