import { Core_Securitycallouts } from "../../../generated/graphql";
import { useEffect, useState } from "react";
import { useCalloutMessages, useGetUser } from "../../../lib";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { ShuriStyledDrawerMessage } from "../drawer";
import {
  ButtonBaseColor,
  ButtonBaseColorLight,
  InputBaseColorLight,
} from "../../config";
import { MessageOutlined } from "@mui/icons-material";
import { TextInput } from "./textinput";
import { MessageLeft, MessageRight } from "./message";
import { API } from "aws-amplify";
import { getClaims, getAuthData } from "../../../lib";
interface ICalloutChatProps {
  callout: Core_Securitycallouts;
}

interface IMessage {
  message_body: string;
  message_id: number;
  outgoing: boolean;
  created_on: string;
  loading?: boolean;
}

export function CalloutChat({ callout }: ICalloutChatProps) {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState(false);
  const { data, refetch } = useCalloutMessages(callout?.id);
  const {data: currentUser} = useGetUser(email)
  const [messages, setMessages] = useState<IMessage[]>(
    data?.core_calloutmessage ?? []
  );
  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    const fetchuser = async () => {
      const claims = await getClaims();
      setEmail(claims.email)
    };
    fetchuser()
  }, []);

  const sendMessage = async (
    message_body: IMessage,
    calloutID: number,
    calloutExtID: number
  ) => {
    const claims = await getClaims();
    try {
      const { idToken } = await getAuthData();
      const token = idToken.jwtToken;
      const requestData = {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
        body: {
          message_body: message_body,
          callout_id: calloutID,
          callout_ext_id: calloutExtID,
          endpoint: claims.endpoint,
          user_id: currentUser?.core_user?.[0]?.id
        },
      };
      await API.post("shurikonektfeapi", "/aura-send-message", requestData);
      refetch();
    } catch (error) {
      console.log({ error });
    }
  };
  useEffect(() => {
    if (data) {
      setMessages(data.core_calloutmessage);
    }
  }, [data?.core_calloutmessage, data]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAppend = (message: IMessage) => {
    setMessages([...messages, message]);
  };

  return (
    <div>
      <IconButton
        onClick={() => handleClickOpen()}
        aria-label="Cancel Callout"
        size="small"
      >
        <MessageOutlined color="secondary" />
      </IconButton>
      <ShuriStyledDrawerMessage
        anchor="right"
        open={open}
        onClose={() => false}
      >
        <Box className="shuri-drawer-wrapper">
          <Box className="shuri-drawer-content">
            <Grid
              container
              spacing={2}
              sx={{
                paddingBottom: "30px",
              }}
            >
              <Grid item xs={10}>
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "28px",
                    color: InputBaseColorLight,
                  }}
                >
                  Callout : {callout?.call_out_id}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              ></Grid>
            </Grid>

            {messages?.map((message: any, index: number) => {
              if (message?.outgoing) {
                return (
                  <MessageRight
                    key={index}
                    message={message?.message_body}
                    timestamp={message?.created_on}
                    sending={message?.sending}
                    photoURL=""
                    displayName={message?.core_user?.first_name}
                    avatarDisp={true}
                  />
                );
              } else {
                return (
                  <MessageLeft
                    key={index}
                    message={message?.message_body}
                    timestamp={message?.created_on}
                    photoURL=""
                    displayName="Aura"
                    avatarDisp={true}
                  />
                );
              }
            })}
          </Box>
          <TextInput
            handleAppendMessage={handleAppend}
            calloutID={callout?.id}
            calloutExtID={callout?.call_out_id}
            sendMessage={sendMessage}
            closed={callout?.closed_on}

          />
          <Box className="shuri-drawer-actions">
            <Button
              onClick={handleClose}
              sx={{
                color: ButtonBaseColorLight,
                borderColor: ButtonBaseColor,
                textTransform: "capitalize",
              }}
              variant="outlined"
              size="large"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </ShuriStyledDrawerMessage>
    </div>
  );
}
