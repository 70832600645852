export const formatName = (firstName: string, lastName: string) => {
    return `${firstName} ${lastName}`;
};

export const convertStringToSlug = (str: string) => {
    return str
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/(^-|-$)+/g, '');
};

export const limitStringLength = (str: string, maxLength: number) => {
    if (str.length > maxLength) {
        return str.slice(0, maxLength) + '...';
    }
    return str;
}