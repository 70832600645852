import {gql, useQuery} from "@apollo/client";
import {perPage} from "../../../config";

export const SITE_RECTIFIER_ALARMS_QUERY = gql`
    query RECTIFIER_ALARMS_QUERY(
        $site_id: bigint,         
        $limit: Int
        $offset: Int
    ) {
        core_rectifieralarm(
            order_by: {created_on: desc, alarm_name: asc},
            where: {site_id: {_eq: $site_id}}
            limit: $limit
            offset: $offset
        ) {
            id
            alarm_name
            alarm_state
            created_on
            priority
        }

        core_rectifieralarm_aggregate(where: {site_id: {_eq: $site_id}}) {
            aggregate {
                count
            }
        }
    }
`;


export const useSiteRectifiersAlarms = (site_id?: string, page = 0, limit = perPage) => {
    const offset = page * limit;
    const {loading, error, data, refetch} = useQuery(SITE_RECTIFIER_ALARMS_QUERY, {
        variables: {
            site_id,
            offset,
            limit,
        },
        skip: !site_id
    });

    return {loading, error, data, refetch};
}