import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

export const INVENTORY_QUERY = gql`
  query {
    core_equipment {
      serial_number
      id
      core_equipmenttype {
        name
        id
      }
      core_equipmentcondition {
        condition
      }
      core_equipmentstatus {
        equipment_status
        id
      }
      core_equipmentpart {
        equipment_part
        id
      }
      core_equipmentmodel {
        model_number
        core_equipmentmake {
          equipment_make
        }
        core_equipmentparts {
          equipment_part
        }
      }
      core_site {
        id
        name
        site_id
      }
      core_equipmentimages{
        id
        image
        created_on
        equipment_id
      }
    }
  }
`;

export const ADD_INVENTORY = gql`
  mutation (
    $equipment_name: String
    $serial_number: String
    $model_id: bigint
    $part_id: bigint
    $site_id: bigint
    $status_id: bigint
    $type_id: bigint
    $condition_id: bigint
    $image: String
    $created_on: timestamptz
  ) {
    insert_core_equipment(
      objects: {
        part_id: $part_id
        equipment_name: $equipment_name
        serial_number: $serial_number
        site_id: $site_id
        status_id: $status_id
        type_id: $type_id
        condition_id: $condition_id
        model_id: $model_id
        core_equipmentimages: {
          data: { image: $image, created_on: $created_on }
        }
      }
    ) {
      returning {
        id
        equipment_name
        type_id
        status_id
        site_id
        serial_number
        part_id
        model_id
      }
    }
  }
`;

export const UPDATE_INVENTORY = gql`
  mutation (
    $id: bigint!
    $serial_number: String
    $model_id: bigint
    $part_id: bigint
    $site_id: bigint
    $status_id: bigint
    $type_id: bigint
    $condition_id: bigint
    $created_on: timestamptz
    $equipment_name: String
  ) {
    update_core_equipment_by_pk(
      pk_columns: { id: $id }
      _set: {
        part_id: $part_id
        serial_number: $serial_number
        site_id: $site_id
        status_id: $status_id
        type_id: $type_id
        condition_id: $condition_id
        model_id: $model_id
        equipment_name: $equipment_name
      }
    ) {
      id
      serial_number
      part_id
      site_id
      status_id
      type_id
      condition_id
      model_id
    }
  }
`;

export const REMOVE_INVENTORY = gql`
    mutation REMOVE_INVENTORY($id: bigint!){
      delete_core_equipment(where: {id: {_eq: $id}}){
        returning {
          id
        }
      }
    }
`;

export const QUERY_INVENTORY_PARTIALS = gql`
    query QUERY_INVENTORY_PARTIALS {
      core_equipmenttype {
        name
        id
      }
      core_equipmentcondition {
        id
        condition
      }
      core_equipmentstatus {
        equipment_status
        id
      }
      core_equipmentpart {
        equipment_part
        id
      }
      core_equipmentmodel {
        id
        equipment_model
        model_number
        type_id
      }
    }
`

export const useInventory = () => {
  const { data, error, loading } = useQuery(INVENTORY_QUERY);
  return { data, error, loading };
};

export const useInventoryPartials = () => {
  const { data, error, loading } = useQuery(QUERY_INVENTORY_PARTIALS);
  return { data, error, loading };
};
