import { FilterAltOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import UpdateIcon from "@mui/icons-material/Update";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Menu,
  MenuItem,
  Modal,
  TableFooter,
  Tooltip,
  Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import {
  Core_User,
  FetchUsersDocument,
  useFetchUserRolesQuery,
  useUpdateUserMutation
} from "../../../generated/graphql";
import {
  AppBackgroundColor,
  MetricsCardBackground,
  primaryBlue
} from "../../config";
import NewUserDrawer from "../../pages/dashboard/users/NewUserDrawer";
import { ShuriStyledDrawer } from "../drawer/shuri-styled-drawer";
import { StyledTableCell, StyledTableRow } from "../table";
import ShuriTablePagination from "../table/ShuriTablePagination";
import { getClaims, getAuthData } from '../../../lib/authData';
import { API } from "aws-amplify";

type Props = {
  users: Core_User[];
  refetch:any;
  page: number;
  rowsPerPage: number;
  handleChangePage: any;
  userTypeId?: string | number;
  setUserTypeId: (value?: string | number) => void;
  accessTypeId?: string | number;
  setAccessTypeId: (value?: string | number) => void;
  accountStatus?: string | number;
  setAccountStatus: (value?: string | number) => void;
};

const UsersTable = ({
  users,
  refetch,
  page,
  rowsPerPage,
  handleChangePage,
  setUserTypeId,
  setAccessTypeId,
  setAccountStatus,
  accessTypeId,
  accountStatus,
  userTypeId
}: Props) => {
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showEditUser, setShowEditUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState<Core_User | null>(null);

  const { data } = useFetchUserRolesQuery();

  type OptionType = {
    value: number | string;
    label: string;
  };

  const [userRoles, setUserRoles] = useState<OptionType[]>([]);

  useEffect(() => {
    if (data && data.core_role) {
      const roles = data.core_role.map((role) => ({
        label: role.title,
        value: role.id
      }));
      setUserRoles(roles);
    }
  }, [data]);

  const [updateUser] = useUpdateUserMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: FetchUsersDocument }],
    onCompleted: () => {
      setShowRemoveModal(false);
      setShowDisableModal(false);
      setSelectedUser(null);
    }
  });

  const onRemoveUser  = async () => {
    try {
      await updateUser({
        variables: {
          id: selectedUser?.id,
          first_name: selectedUser?.first_name,
          last_name: selectedUser?.last_name,
          company_id: selectedUser?.core_company?.id,
          role_id: selectedUser?.core_role?.id,
          is_active: !selectedUser?.is_active,
          is_staff: true
        }
      });
    } catch (error) {
      console.log({ error });
    }
  };
  

  // making user status disable instead of hard deleting via lambda
  const onHandleUserStatus = async () => {
    const claims= await getClaims();

    try {
      const { idToken } = await getAuthData();
      const token = idToken.jwtToken;
      const requestData = {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
        body: {
          username: selectedUser?.email,
          status: !selectedUser?.is_active,
          endpoint: claims.endpoint
        },
      };
      await API.post(
        "shurikonektfeapi",
        "/update-user-api",
        requestData
      );
      setShowDisableModal(false)
      refetch()
    } catch (error) {
      console.log({ error });
    }
  };

  const [showUserTypes, setShowUserTypes] =
    useState<undefined | EventTarget>(undefined);
  const [showAccessTypes, setShowAccessTypes] =
    useState<undefined | EventTarget>(undefined);
  const [showAccountStatuses, setShowAccountStatuses] =
    useState<undefined | EventTarget>(undefined);

  const handleClose = () => {
    setShowAccessTypes(undefined);
    setShowAccountStatuses(undefined);
    setShowUserTypes(undefined);
  };

  const accessTypes = useMemo(() => {
    return [
      { value: "1", label: "Shuri Admin" },
      { value: "2", label: "Account Holder" },
      { value: "3", label: "Project Manager" },
      { value: "4", label: "Basic User" }
    ];
  }, []);

  const accountStatuses = useMemo(() => {
    return [
      { value: 1, label: "Active" },
      { value: 0, label: "Disabled" }
    ];
  }, []);

  const UserFilterMenu = ({
    options,
    anchorEl,
    isOpen,
    onSelect,
    selected
  }: {
    options: OptionType[];
    anchorEl?: EventTarget;
    isOpen: boolean;
    onSelect: (value: string | number) => void;
    selected: string | number;
  }) => {
    return (
      <Menu anchorEl={anchorEl as any} open={isOpen} onClose={handleClose}>
        {options.map((option) => {
          const isSelected = selected
            .toString()
            .includes(option.value.toString());
          return (
            <MenuItem
              key={option.value}
              onClick={() => onSelect(isSelected ? 0 : option.value)}
            >
              <Checkbox defaultChecked={isSelected} disabled />
              <Typography>{option.label}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>User ID</StyledTableCell>
            <StyledTableCell>First Name</StyledTableCell>
            <StyledTableCell>Last Name</StyledTableCell>
            <StyledTableCell>
              <Box alignItems="center" display="flex">
                User Type
                <FilterAltOutlined
                  sx={{ cursor: "pointer" }}
                  onClick={({ target }) => setShowUserTypes(target)}
                />
              </Box>
              <UserFilterMenu
                options={userRoles}
                anchorEl={showUserTypes}
                isOpen={Boolean(showUserTypes)}
                onSelect={setUserTypeId}
                selected={`${userTypeId}`}
              />
            </StyledTableCell>
            <StyledTableCell>
              <Box alignItems="center" display="flex">
                Access Type
                <FilterAltOutlined
                  sx={{ cursor: "pointer" }}
                  onClick={({ target }) => setShowAccessTypes(target)}
                />
              </Box>
              <UserFilterMenu
                options={accessTypes}
                anchorEl={showAccessTypes}
                isOpen={Boolean(showAccessTypes)}
                onSelect={setAccessTypeId}
                selected={`${accessTypeId}`}
              />
            </StyledTableCell>
            <StyledTableCell>Company Name</StyledTableCell>
            <StyledTableCell>
              <Box alignItems="center" display="flex">
                Account Status
                <FilterAltOutlined
                  sx={{ cursor: "pointer" }}
                  onClick={({ target }) => setShowAccountStatuses(target)}
                />
              </Box>
              <UserFilterMenu
                options={accountStatuses}
                anchorEl={showAccountStatuses}
                isOpen={Boolean(showAccountStatuses)}
                onSelect={setAccountStatus}
                selected={`${accountStatus}`}
              />
            </StyledTableCell>
            <StyledTableCell sx={{ color: "#4C576B" }}>Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.map((user) => {
            return (
              <StyledTableRow key={user?.id}>
                <StyledTableCell
                  style={{
                    color: primaryBlue,
                    textDecoration: "underline",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "20px",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    setSelectedUser(user);
                    setShowUserDetails(true);
                  }}
                >
                  {user?.id}
                </StyledTableCell>

                <StyledTableCell>{user?.first_name}</StyledTableCell>
                <StyledTableCell>{user?.last_name}</StyledTableCell>
                <StyledTableCell> {user?.core_role?.title}</StyledTableCell>
                <StyledTableCell>All</StyledTableCell>
                <StyledTableCell>{user?.core_company?.name}</StyledTableCell>
                <StyledTableCell>
                  <Chip
                    sx={{
                      backgroundColor: AppBackgroundColor,
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "18px",
                      border: "none",
                      padding: "2px 8px"
                    }}
                    label={user?.is_active ? "Enabled" : "Disabled"}
                    color={user?.is_active ? "success" : "error"}
                    size="small"
                    variant="outlined"
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <ModeEditIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedUser(user);
                        setShowEditUser(true);
                      }}
                    />
                    {user?.is_active ? (
                      <NotInterestedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedUser(user);
                          setShowDisableModal(true);
                        }}
                      />
                    ) : (
                      <UpdateIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setSelectedUser(user);
                          setShowDisableModal(true);
                        }}
                      />
                    )}
                    <DeleteOutlineIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedUser(user);
                        setShowRemoveModal(true);
                      }}
                    />
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow sx={{ border: 0, m: 0 }}>
            <ShuriTablePagination
              colSpan={8}
              count={users?.length ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
      <Modal
        open={showRemoveModal}
        onClose={() => setShowRemoveModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box
            borderRadius={48}
            width={48}
            justifyContent="center"
            display="flex"
            alignItems="center"
            height={48}
            sx={{ backgroundColor: "#523329" }}
            marginBottom={3}
          >
            <ErrorOutlineIcon sx={{ color: "#FF632B", fontSize: 24 }} />
          </Box>

          <Typography variant="h6" component="h2" color="white">
            Remove User?
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Are you sure you would like to remove user,{" "}
            <Typography color="#f5f5f5">
              {selectedUser?.first_name} {selectedUser?.last_name}?{" "}
            </Typography>{" "}
            If you proceed, all data associated with this user will be lost.
          </Typography>
          <Box marginTop={5} flexDirection="row" display="flex">
            <Button
              variant="contained"
              sx={{
                width: "50%",
                marginRight: 5,
                backgroundColor: "#FF632B",
                color: "black"
              }}
              onClick={onRemoveUser}
            >
              Confirm
            </Button>
            <Button
              sx={{ width: "50%", color: "white", borderColor: "white" }}
              variant="outlined"
              onClick={() => setShowRemoveModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={showDisableModal}
        onClose={() => setShowDisableModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box borderRadius={50} sx={{ backgroundColor: "warning" }} />
          <Box
            borderRadius={48}
            width={48}
            justifyContent="center"
            display="flex"
            alignItems="center"
            height={48}
            sx={{ backgroundColor: "#523329" }}
            marginBottom={3}
          >
            <ErrorOutlineIcon sx={{ color: "#FF632B", fontSize: 24 }} />
          </Box>
          <Typography variant="h6" component="h2" color="white">
            {selectedUser?.is_active ? "Disable" : "Enable"} User?
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Are you sure you would like to{" "}
            {selectedUser?.is_active ? "disable" : "enable"} user,{" "}
            <Typography color="#f5f5f5">
              {selectedUser?.first_name} {selectedUser?.last_name}?{" "}
            </Typography>
          </Typography>
          {selectedUser?.is_active && (
            <Typography sx={{ mt: 2 }}>
              If you proceed, the user will not be able to log into the
              application and depending on user access, admins will not be able
              to assign them any tasks till the account is restored.
            </Typography>
          )}
          <Box marginTop={5} flexDirection="row" display="flex">
            <Button
              variant="contained"
              sx={{
                width: "50%",
                marginRight: 5,
                backgroundColor: "#FF632B",
                color: "black"
              }}
              onClick={onHandleUserStatus}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              sx={{ width: "50%", color: "white", borderColor: "white" }}
              onClick={() => setShowDisableModal(false)}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <ShuriStyledDrawer
        anchor="right"
        open={showUserDetails}
        onClose={() => setShowUserDetails(false)}
      >
        <Box width={400} padding={5}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Typography fontSize={25} color="white" fontWeight="bold">
              User Account
            </Typography>
            <CloseIcon
              sx={{ color: "white" }}
              onClick={() => setShowUserDetails(false)}
            />
          </Box>
          <Box
            height={120}
            marginTop={5}
            marginX={-5}
            paddingX={5}
            display="flex"
            flexDirection="row"
            borderBottom="1px solid white"
            alignItems="center"
          >
            <Box
              width={80}
              height={80}
              bgcolor="#4490AE"
              border={2}
              borderColor="white"
              borderRadius={80}
            />
            <Box
              display="flex"
              marginLeft={2}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              flex={1}
            >
              <Box flexDirection="row">
                <Typography color="white" marginBottom={2}>
                  {selectedUser?.first_name} {selectedUser?.last_name}
                </Typography>
                <Box
                  display="flex"
                  width={120}
                  flexDirection="row"
                  justifyContent="space-around"
                >
                  <Tooltip
                    title="Edit User"
                    arrow
                    placement="top"
                    sx={{ cursor: "pointer" }}
                  >
                    <ModeEditIcon color="secondary" />
                  </Tooltip>
                  {selectedUser?.is_active ? (
                    <Tooltip
                      title="Disable User"
                      arrow
                      placement="top"
                      sx={{ cursor: "pointer" }}
                    >
                      <NotInterestedIcon
                        color="secondary"
                        onClick={() => {
                          setShowDisableModal(true);
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title="Enable User"
                      arrow
                      placement="top"
                      sx={{ cursor: "pointer" }}
                    >
                      <UpdateIcon color="secondary" />
                    </Tooltip>
                  )}
                  <Tooltip
                    title="Delete User"
                    arrow
                    placement="top"
                    sx={{ cursor: "pointer" }}
                  >
                    <DeleteOutlineIcon color="secondary" />
                  </Tooltip>
                </Box>
              </Box>
              <Chip
                sx={{
                  backgroundColor: MetricsCardBackground,
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "18px",
                  border: "none",
                  padding: "2px 8px"
                }}
                color={selectedUser?.is_active ? "success" : "error"}
                label={selectedUser?.is_active ? "active" : "disabled"}
                variant="outlined"
              />
            </Box>
          </Box>
          <Box marginTop={3}>
            <Typography color="secondary">User ID</Typography>
            <Typography color="white">{selectedUser?.id}</Typography>
          </Box>
          <Box marginTop={3}>
            <Typography color="secondary">User Role</Typography>
            <Typography color="white">
              {selectedUser?.core_role?.title}
            </Typography>
          </Box>
          <Box marginTop={3}>
            <Typography color="secondary">Access Type</Typography>
            <Typography color="white">Any</Typography>
          </Box>
          <Box marginTop={3}>
            <Typography color="secondary">Company Name</Typography>
            <Typography color="white">
              {selectedUser?.core_company?.name}
            </Typography>
          </Box>
        </Box>
      </ShuriStyledDrawer>
      <NewUserDrawer
        isEdit
        user={selectedUser}
        isOpen={showEditUser}
        onClose={() => setShowEditUser(false)}
      />
    </>
  );
};

export default UsersTable;

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "black",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4
};
