import { ArrowDropUp, ArrowRight, ArrowDropDown } from "@mui/icons-material";
import { ArrowUp, ArrowDown } from '@phosphor-icons/react';
import { Box } from "@mui/material";

function IconContainer(props: { children: React.ReactNode }) {
    return <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {props.children}
    </Box>;
}

interface PercentageChangeIconProps{
    value: number
}

export function PercentageChangeIcon(props: PercentageChangeIconProps) {
    if (props.value < 0)
        return <IconContainer>
            <ArrowUp color='#12B76A' />
            {Math.abs(props.value)}%
        </IconContainer>
    else if (props.value == 0)
        return <IconContainer>
            <ArrowRight sx={{ width: 40, height: 40, margin: 0, color: 'silver' }}  />
            {props.value}%
        </IconContainer>;
    else
        return <IconContainer>
            <ArrowDown color='#FF632B' />
            {Math.abs(props.value)}%
        </IconContainer>
}