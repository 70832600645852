import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { getFirstDateOfCurrentMonth } from "../../filters";
import { perPage } from "../../../config";

export const COMPANY_DISRUPTIONS_QUERY = gql`
  query SITES_DISRUPTIONS($date: timestamptz, $limit: Int, $offset: Int) {
    core_disruptions(
      where: { start_date: { _gte: $date } }
      limit: $limit
      offset: $offset
    ) {
      id
      description
      end_date
      root_cause
      start_date
      core_disruptionstype {
        id
        name
      }
      core_site {
        id
        site_id
        name
      }
      core_user {
        first_name
        email
        last_name
        is_active
        is_staff
        is_superuser
        last_login
      }
    }

    core_disruptions_aggregate(where: { start_date: { _gte: $date } }) {
      aggregate {
        count
      }
    }
  }
`;

export const useCompanyDisruptions= (
  date = getFirstDateOfCurrentMonth(),
  page = 1,
  limit = perPage
) => {
  const offset = page * limit;

  const { data, error, loading } = useQuery(COMPANY_DISRUPTIONS_QUERY, {
    variables: { date, offset, limit },
  });
  return { data, error, loading };
};
