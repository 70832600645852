import { Box, Button, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";

type CaptureType = {
  blob: Blob;
  dataUri: string;
  fileName: string;
};

type Props = {
  position: string;
  onCapture: (data: CaptureType) => void;
  width?: string | number;
};

const PhotoCapture = ({ position, onCapture, width }: Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [tempImage, setTempImage] = useState<string | null>(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const FACE_API_URL =
  process.env.REACT_APP_FACE_API_URL || "https://face-api-1231261094.eu-west-1.elb.amazonaws.com";

  const validateFace = async ({ blob, dataUri, fileName }: any) => {
    try {
      setIsVerifying(true);
      const fd = new FormData();
      fd.append("file", blob, fileName);
      await axios.post(
        `${FACE_API_URL}/validate_face?orientation=${position}`,
        fd,
        {
          headers: { "Content-Type": "multipart/form-data" }
        }
      );
      onCapture({
        blob,
        dataUri,
        fileName
      });
      setTempImage(null);
      setErrorMessage(null);
      setTempImage(null);
    } catch (error: any) {
      setErrorMessage(error?.response?.data?.message);
      console.log({ error });
    } finally {
      setIsVerifying(false);
    }
  };

  const onStartCamera = (): void => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      })
      .catch((error) => {
        console.error("Error accessing media devices.", error);
      });
  };

  const onCaptureImage = (): void => {
    if (isVerifying) {
      return;
    }
    if (tempImage) {
      setErrorMessage(null);
      setIsVerifying(false);
      setTempImage(null);
      onStartCamera();
      return;
    }
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.width = 1920;
      canvas.height = 1440;
    }
    const context = canvas?.getContext("2d");

    if (video && canvas && context) {
      // Draw the current video frame onto the canvas
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // for file saving
      const dataUri = canvas.toDataURL("image/jpeg", 1);
      const data = dataUri.split(",")[1];
      const mimeType = dataUri.split(";")[0].slice(5);

      const bytes = window.atob(data);
      const buf = new ArrayBuffer(bytes.length);
      const arr = new Uint8Array(buf);

      for (let i = 0; i < bytes.length; i++) {
        arr[i] = bytes.charCodeAt(i);
      }
      const blob = new Blob([arr], { type: mimeType });

      setTempImage(dataUri);
      validateFace({
        blob: blob,
        dataUri: dataUri,
        fileName: `image_${position}.png`
      });
    }
  };

  useEffect(() => {
    onStartCamera();
    return () => {
      const stream = videoRef.current?.srcObject as MediaStream | null;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      }
    };
  }, [videoRef.current?.srcObject]);

  return (
    <Box
      width="550px"
      padding={5}
      display="flex"
      minHeight="450px"
      alignSelf="center"
      justifySelf="center"
      flexDirection="column"
    >
      <Typography
        textAlign="center"
        fontWeight="bold"
        color="white"
        sx={{ textDecoration: "underline" }}
      >
        Take {position} photo
      </Typography>
      <Box alignSelf="center" height="450px">
        {!!tempImage ? (
          <img
            src={tempImage}
            alt={position}
            style={{ width: "550px", height: "450px" }}
          />
        ) : (
          <video
            ref={videoRef}
            style={{ width: "550px", height: "450px" }}
            autoPlay
            muted
          />
        )}
      </Box>
      <Button
        variant="contained"
        onClick={onCaptureImage}
        sx={{
          color: "black",
          backgroundColor: "#5BC0E9",
          alignSelf: "center",
          borderTopRadius: 0
        }}
      >
        {isVerifying ? (
          <CircularProgress />
        ) : errorMessage ? (
          "Try again"
        ) : (
          "Capture Image"
        )}
      </Button>
      {errorMessage && (
        <Box marginTop={2}>
          <Typography color="white" textAlign="center">
            {errorMessage}
          </Typography>
        </Box>
      )}
      <canvas ref={canvasRef} style={{ display: "none" }} />
    </Box>
  );
};
export type { CaptureType };
export default PhotoCapture;
