import React, {forwardRef, MouseEvent, ReactNode, useImperativeHandle, useState, Ref} from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { BaseColorLight } from "../../config";
import { styled } from "@mui/material/styles";

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    color: BaseColorLight,
    fontFamily: 'Outfit',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '18px'
}));

export interface ActionRef {
    handleClose: () => void;
}

interface IShuriActionProps {
    id: any;
    children?: ReactNode;
    icon?: ReactNode;
}

export const ShuriActionMenu = forwardRef<ActionRef, IShuriActionProps>(({ id, children, icon }: IShuriActionProps, ref: Ref<ActionRef>) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useImperativeHandle(ref, () => ({
        handleClose
    }));

    return (
        <>
            <IconButton
                sx={{
                    color: BaseColorLight
                }}
                aria-label={`more-${id}`}
                id={`shuri-action-${id}`}
                aria-controls={open ? `shuri-action-${id}` : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="false"
                onClick={handleClick}
            >
                {icon ? icon : <MoreVert />}
            </IconButton>

            <Menu
                id={`site-action-menu-${id}`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': `site-action-${id}`,
                    style: {
                        width: '20ch',
                    }
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                {children}
            </Menu>
        </>
    )
});
