import {FormControl, Grid, IconButton} from "@mui/material";
import {
    ShuriMuiFormDatePicker,
    ShuriMuiFormSelect,
    StyledFormFieldError,
    StyledFormLabel,
    StyledFormTextField
} from "../../../form";
import {Dayjs} from "dayjs";
import {useForm, useYupValidateForm} from "../../../../../lib/form/hooks";
import {date, number, object, ref, string} from "yup";
import {useEffect, useState} from "react";
import {BaseColor, errorBase} from "../../../../config";
import {ISelectInput, IVisit} from "../../../../../lib";
import dayjs from 'dayjs';

export interface IVisitInput {
    id?: number;
    job_id?: string | number;
    status_id?: string | number;
    comment: string;
    start_datetime: Dayjs | null | any;
    end_datetime: Dayjs | null | any;
    gen_key?: string;
}

interface IAddJobVisitFormProps {
    index: number;
    visit: IVisitInput;
    status: ISelectInput[];
    jobs?: ISelectInput[];
    onInputChange: (index: number, inputs: IVisitInput) => void;
    validateInputField?: (name: string) => void;
    onDelete: (index: number) => void;
    formErrors?: { [key: string]: string | null }
    isCreate?: boolean;
    jobId?: string|number|null;
}

export const jobVisitInitialState: IVisitInput = {
    status_id: '',
    comment: '',
    start_datetime: null,
    end_datetime: null
}

export const validationSchema = object().shape({
    job_id: number().typeError('Job is required. Provide a valid value').required('Job is required'),
    status_id: number().typeError('Status is required. Provide a valid value').required('Status is required'),
    start_datetime: date().typeError('Start Date is required. Provide a valid value').required('Start Date is required')
        .max(ref('end_datetime'), 'Start Date must be before End Date'),
    end_datetime: date().typeError('End Date is required. Provide a valid value').required('End Date is required')
        .min(ref('start_datetime'), 'End Date must be after Start Date'),
    comment: string().typeError('Comment is required. Provide a valid value').required('Comment is required'),
});

export function AddJobVisitForm({
                                    onInputChange,
                                    onDelete,
                                    visit,
                                    index,
                                    status,
                                    jobs,
                                    validateInputField,
                                    formErrors,
                                    isCreate,
                                    jobId
                                }: IAddJobVisitFormProps) {
    const {inputs, handleChange, setInputs} = useForm(visit);
    const [errors, setErrors] = useState<{ [key: string]: string | null }>(formErrors ?? {});
    const {errors: validationErrors, validateField} = useYupValidateForm(inputs, validationSchema);

    useEffect(() => {
        if (validationErrors) {
            setErrors(validationErrors)
        }
        if (formErrors) {
            setErrors(formErrors)
        }
    }, [validationErrors, formErrors])

    const handleDateChange = (name: string, value: Dayjs | null) => {
        setInputs({
            ...inputs,
            [name]: value
        });
        handleValidateInput(name)
    }

    useEffect(() => {
        onInputChange(index, inputs);
    }, [inputs]);

    useEffect(() => {
        setInputs(visit);
    }, [visit]);

    const handleValidateInput = (name: string) => {
        validateField(name);

        if (validateInputField) {
            validateInputField(name)
        }
    }

    return (
        <Grid container spacing={2} sx={ !isCreate ? {
            paddingTop: '40px',
            paddingBottom: '20px',
            position: 'relative',
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 'calc(100% + 5px)',
                left: '20px',
                right: '5px',
                height: '2px',
                backgroundImage: `
                radial-gradient(${BaseColor} 1px, transparent 1px),
                radial-gradient(${BaseColor} 1px, transparent 1px)
              `,
                backgroundSize: '4px 4px',
                backgroundPosition: '0 0, 2px 2px',
            },
        } : {}}>
            {
                !visit?.id && !isCreate &&
                <IconButton
                    onClick={() => onDelete(index)}
                    aria-label="delete"
                    sx={{
                        position: 'absolute',
                        top: '40px',
                        right: '0',
                        zIndex: 10
                    }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={errorBase}
                         viewBox="0 0 256 256">
                        <path
                            d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"></path>
                    </svg>
                </IconButton>
            }

            {
                isCreate &&
                <Grid item xs={12} md={12} lg={12}>
                    <FormControl color="secondary" size="small" fullWidth required>
                        <StyledFormLabel>Assign Job</StyledFormLabel>
                        <ShuriMuiFormSelect
                            disabled={!!jobId && jobId !== ''}
                            placeholder='Select a Job'
                            options={jobs ?? []}
                            value={inputs.job_id}
                            handleChange={handleChange}
                            name='job_id'
                            handleBlur={() => handleValidateInput('job_id')}
                            error={!!errors.job_id}
                        />
                        {errors.job_id && <StyledFormFieldError>{errors.job_id}</StyledFormFieldError>}
                    </FormControl>
                </Grid>
            }
            <Grid item xs={12} md={isCreate ? 12 : 6} lg={isCreate ? 12 : 4}>
                <FormControl color="secondary" size="small" fullWidth required>
                    <StyledFormLabel>Status</StyledFormLabel>
                    <ShuriMuiFormSelect
                        placeholder='Select Status'
                        options={status ?? []}
                        value={inputs.status_id}
                        handleChange={handleChange}
                        name='status_id'
                        handleBlur={() => handleValidateInput('status_id')}
                        error={!!errors.status_id}
                    />
                    {errors.status_id && <StyledFormFieldError>{errors.status_id}</StyledFormFieldError>}
                </FormControl>
            </Grid>

            <Grid item xs={12} md={isCreate ? 12 : 6} lg={isCreate ? 12 : 4}>
                <FormControl color="secondary" size="small" fullWidth required>
                    <StyledFormLabel>Start Date</StyledFormLabel>
                    <ShuriMuiFormDatePicker
                        type="datetimepicker"
                        placeholder='Select start date'
                        value={inputs.start_datetime}
                        handleChange={handleDateChange}
                        name='start_datetime'
                        handleBlur={() => {
                            handleValidateInput('start_datetime')
                            handleValidateInput('end_datetime')
                        }}
                        validateInputField={() => {
                            handleValidateInput('start_datetime')
                            handleValidateInput('end_datetime')
                        }}
                        error={errors.start_datetime}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} md={isCreate ? 12 : 6} lg={isCreate ? 12 : 4}>
                <FormControl color="secondary" size="small" fullWidth required>
                    <StyledFormLabel>End Date</StyledFormLabel>
                    <ShuriMuiFormDatePicker
                        type="datetimepicker"
                        placeholder='Select end date'
                        value={inputs.end_datetime}
                        handleChange={handleDateChange}
                        name='end_datetime'
                        handleBlur={() => {
                            handleValidateInput('end_datetime')
                            handleValidateInput('start_datetime')
                        }}
                        validateInputField={() => {
                            handleValidateInput('end_datetime')
                            handleValidateInput('start_datetime')
                        }}
                        error={errors.end_datetime}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <FormControl color="secondary" size="small" fullWidth required>
                    <StyledFormLabel>Comment</StyledFormLabel>
                    <StyledFormTextField
                        type="text"
                        size='small'
                        placeholder="A little about the company and the team you will be working with."
                        name="comment"
                        value={inputs.comment}
                        onChange={handleChange}
                        onBlur={() => handleValidateInput('comment')}
                        error={!!errors.comment}
                        helperText={errors.comment}
                        multiline
                        rows={5}
                    />
                </FormControl>
            </Grid>

        </Grid>
    )
}


export function mapVisitDetailsInput(visit?: IVisit): IVisitInput {
    return {
        id: visit?.id,
        job_id: visit?.job_id ?? '',
        status_id: visit?.status_id ?? '',
        comment: visit?.comment ?? '',
        start_datetime: visit?.start_datetime ? dayjs(visit.start_datetime) : null,
        end_datetime: visit?.end_datetime ? dayjs(visit.end_datetime) : null,
    }
}