import {Box, Chip, Grid} from "@mui/material";
import {StyledDetailsContainer} from "../../../site/site-details/StyledDetailsContainer";
import {useEventLogContext} from "../../../../../lib/context";
import {formatDate} from "../../../../../lib/filters";
import {AppBackgroundColor,} from "../../../../config";
import * as React from "react";
import {logColor} from "../../event-logs-table";

export function LogSummaryDetails() {
    const {eventlog} = useEventLogContext();
    return (
        <StyledDetailsContainer sx={{padding: 0}}>
            <Grid container spacing={2}>
                <Grid item xs={3} className="details-tile dotted-right">
                    <Box className="details-title">Event Date & Time</Box>
                    <Box className="details-value">{formatDate(eventlog.created_at)}</Box>
                </Grid>
                <Grid item xs={3} className="details-tile dotted-right">
                    <Box className="details-title">Event Type</Box>
                    <Box className="details-value">{eventlog.event_log_type}</Box>
                </Grid>
                <Grid item xs={3} className="details-tile dotted-right">
                    <Box className="details-title">Event Name</Box>
                    <Box className="details-value">{eventlog.event_name}</Box>
                </Grid>
                <Grid item xs={3} className="details-tile">
                    <Box className="details-title">Event Priority</Box>
                    <Box className="details-value">
                        <Chip
                            sx={{
                                backgroundColor: AppBackgroundColor,
                                fontWeight: '500',
                                fontSize: '12px',
                                lineHeight: '18px',
                                border: 'none',
                                padding: '2px 8px',

                            }}
                            label={eventlog.event_priority}
                            color={logColor(eventlog.event_priority)}
                            size="small"
                            variant="outlined"
                        />
                    </Box>
                </Grid>

            </Grid>
        </StyledDetailsContainer>
    )
}