import gql from "graphql-tag";
import {useQuery} from "@apollo/client";

export const COMPANY_SITE_CHARGE_ESTIMATE = gql`
    query {
        core_companysitechargeestimate_aggregate {
            aggregate {
                max {
                    charge_period_end
                }
            }
            nodes {
                charge_period_end
                charge_period_start
                id
                cost
                total_units
            }
        }
    }
`

export const useCompanySiteChargeEstimate = () => {
    const {data, error, loading} = useQuery(COMPANY_SITE_CHARGE_ESTIMATE);
    return {data, error, loading};
}