import {Box, Grid} from "@mui/material";
import {EventLogHeadSection} from "./event-log-head-section";
import {Content} from "../../content";
import {EventLogDetailsSection} from "./event-log-details-section";
import {EventLogInsightDetailsSection} from "./event-log-insight-details-section";

export function EventLogComponent()
{
    return (
        <Box sx={{paddingTop:{xs:5}}}>
            <EventLogHeadSection />
            <Content sx={{
                padding: 4,
                paddingTop: 0
            }}>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <EventLogDetailsSection />
                    </Grid>
                    <Grid item xs={12}>
                        <EventLogInsightDetailsSection />
                    </Grid>
                </Grid>

            </Content>
        </Box>
    )
}