import { Box, Grid } from "@mui/material";
import { AppBackgroundColor } from "../../config";
export function MainTileContainer(props: {
  siteId?: string;
  title?: string;
  titleColor?: string;
  subtitle: string;
  tiles: any[];
  dark?: boolean;
}) {
  const Component = (Element: any, props: any) => {
    return <Element {...props} />;
  };
  return (
    <Box
      sx={{
        backgroundColor: AppBackgroundColor,
        overflow: "hidden",
        marginBotton: "50px",
        padding: "0 20px",
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2}>
            <Grid item sm={4} md={4} lg={4} xl={4} xs={12}>
              {props.tiles[0] !== undefined && (
                <Box sx={{ borderRadius: "8px", display:{xs:'none',sm:'block',md:'block',lg:'block',xl:'block'}}}>
                  {Component(props.tiles[0]?.component, props.tiles[0]?.props)}
                </Box>
              )}
            </Grid>
            <Grid item sm={8} md={8} lg={8} xl={8} xs={12}>
              <Grid container spacing={2}>
                {props.tiles.slice(1).map((tile, index) => (
                  <Grid
                    item
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    xs={6}
                    key={index}
                    sx={{ borderRadius: "8px" }}
                  >
                    <tile.component
                      {...tile.props}
                      dark={props.dark}
                      color={props?.titleColor}
                      siteId={props.siteId}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
