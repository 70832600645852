import {Tabs} from "@mui/material";
import {styled} from "@mui/material/styles";
import React, {ReactNode, SyntheticEvent} from "react";
import {BaseColorLight} from "../../config";

interface StyledTabsProps {
    children?: ReactNode;
    value: number;
    onChange: (event: SyntheticEvent, newValue: number) => void;
}

export const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
        variant="scrollable"
        scrollButtons="auto"
        {...props}
        TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}
    />
))({
    background: '#282829',
    border: '1px solid #515050',
    borderRadius: '8px',
    padding: '5px',
    minHeight: '44px',
    maxWidth: '100%',
    '& .MuiTabs-indicator': {
        display: 'none',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        display: 'none'
    },
});

export const StyledCompactTabs = styled((props: StyledTabsProps) => (
    <Tabs
        variant="scrollable"
        scrollButtons="auto"
        {...props}
        TabIndicatorProps={{children: <span className="MuiTabs-indicatorSpan"/>}}
    />
))({
    background: '#282829',
    minHeight: '36px',
    maxWidth: '100%',
    '& .MuiTabs-indicator': {
        display: 'none',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        display: 'none'
    },
});