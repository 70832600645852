import {
  Chip,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TableRow
} from '@mui/material'
import { Link } from 'react-router-dom'
import { formatDate } from '../../../lib/filters'
import { ITablePropsInterface } from '../../../lib/interfaces'
import {
  BaseColorLight,
  MetricsCardBackground,
  primaryBlue
} from '../../config'
import { ShuriTableRowStates, StyledTableCell, StyledTableRow } from '../table'
import ShuriTablePagination from '../table/ShuriTablePagination'
import {CalloutTableActions} from './callout-table-actions'
export interface ICalloutLogsTable {
  data: any
  page: number;
  rowsPerPage: number;
  handleChangePage: any;
  refetch: ()=>void,
  handleChangeRowsPerPage?: any;
  loading?: boolean;
  error?: any;
  toggleRefresh?: ()=> void
  
}
export function CalloutLogsTable({
  data,
  page,
  rowsPerPage,
  handleChangePage,
  refetch,
  loading,
  error,
  handleChangeRowsPerPage,
}: ICalloutLogsTable) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell>Callout ID</StyledTableCell>
          <StyledTableCell>Callout Type</StyledTableCell>
          <StyledTableCell>Callout Details</StyledTableCell>
          <StyledTableCell>Site Name</StyledTableCell>
          <StyledTableCell>Date & Time</StyledTableCell>
          <StyledTableCell>Source</StyledTableCell>
          <StyledTableCell>Status</StyledTableCell>
          <StyledTableCell>Actions</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <ShuriTableRowStates
          colSpan={8}
          data={data?.core_securitycallouts ?? []}
          loading={loading}
          error={error}
        >
          <>
            {data?.core_securitycallouts.map((log: any, index: number) => (
              <StyledTableRow
                className={
                  log.resolved_at == null && log.id ? 'shuri-row-highlighted' : ''
                }
                key={log.id}
                sx={{
                  color: 'white'
                }}
              >
                <StyledTableCell component='th' scope='row'>
                  <Link
                    style={{
                      color: primaryBlue,
                      textDecoration: 'underline',
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '20px'
                    }}
                    to={`/site/${log.site_id}?armed-response`}
                  >
                    {log?.call_out_id}
                  </Link>
                </StyledTableCell>

                <StyledTableCell>{log.type_of_emergency}</StyledTableCell>
                <StyledTableCell>{log?.core_securitycalloutsstatuses?.[0]?.status}</StyledTableCell>
                <StyledTableCell>{log.core_site.name}</StyledTableCell>
                <StyledTableCell>{formatDate(log.created_on)}</StyledTableCell>
                <StyledTableCell>CCTV</StyledTableCell>
                <StyledTableCell>
                  <Chip
                    sx={{
                      backgroundColor: MetricsCardBackground,
                      fontWeight: '500',
                      fontSize: '12px',
                      lineHeight: '18px',
                      border: 'none',
                      padding: '2px 8px'
                    }}
                    label={log.closed_on ? 'Resolved' : 'Active'}
                    color={log.closed_on ? 'success' : 'error'}
                    size='small'
                    variant='outlined'
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Link
                    style={{
                      color: BaseColorLight,
                      fontSize: '14px',
                      lineHeight: '20px',
                      textTransform: 'capitalize',
                      whiteSpace: 'nowrap'
                    }}
                    to={`/site/${log.site_id}?armed-response`}
                  >
                  </Link>
                  <CalloutTableActions log={log} onRefreshLogs={refetch}/>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </>
        </ShuriTableRowStates>
      </TableBody>

      {(data?.core_securitycallouts_aggregate?.aggregate?.count || 0) >
        rowsPerPage && (
        <TableFooter>
          <TableRow sx={{ border: 0, m: 1 }}>
            <ShuriTablePagination
              colSpan={8}
              count={
                data?.core_securitycallouts_aggregate?.aggregate?.count ?? 0
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      )}
    </Table>
  )
}
