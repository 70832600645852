import {Box, Grid} from "@mui/material";
import { useEventLogContext, useShuriPagination} from "../../../../../lib";
import {useEffect, useState} from "react";
import { useRectifierAlarms } from "../../../../../lib/hooks/rectifier-alarms";
import { IRectifierAlarmFilters } from "../../../../../lib/hooks/rectifier-alarms";
import { RectifierAlarmsTable } from "./RectifierAlarmTable";
import { GeneratorAlarmsTable } from "./GeneratorAlarmTable";
import { useGeneratorAlarms } from "../../../../../lib/hooks/event-log/generator-alarm.hooks";
export function EventLogGeneratorAlarms() {
    const {eventlog} = useEventLogContext();
    const {page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = useShuriPagination(4);
    const [rectifierFilters, setRectifierFilters] = useState<IRectifierAlarmFilters>();

    const {
        data: generatorAlarms,
        refetch: refreshAlarms,
        error
    } = useGeneratorAlarms(rectifierFilters, page, rowsPerPage)
  
    useEffect(() => {
        if (Object.keys(eventlog).length !== 0) {
            refreshAlarms()
            let created_at = new Date(eventlog.created_at)
            created_at.setHours(0, 0, 0, 999);
            setRectifierFilters({
                site_id: eventlog.site_id,
                created_at: created_at.toISOString()
            });
        }
    }, [eventlog, refreshAlarms]);

    return (
        <Grid container spacing={2} sx={{
            paddingTop: '20px'
        }}>
            <Grid item xs={12}>
                <Box className="shuri-card-title sub">
                    Active Generator Alarms
                </Box>
                <GeneratorAlarmsTable
                    data={generatorAlarms}
                    handleChangePage={handleChangePage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
    )
}