import React, {createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState} from "react";
import {useGetSiteLastSeen, useSite, useGetSiteCharge, useEquipmentType, useInventoryPartials, useSiteMetrics,
    useGetSecurityCallouts, useGetSecurityCoverage, useSiteSimData} from "../../hooks";
import SkeletonLoader from "../../../ui/components/skeleton-loader";
import {IIncident, IInventoryPartials, IMetric} from "../../interfaces";
import {METRICS_REFRESH_INTERVAL} from "../../../config";


type SiteContextType = {
    id: any;
    date?: string;
    site: any;
    siteCharge: any;
    loading: boolean;
    equipmentTypesData: any;
    inventoryPartials: IInventoryPartials;
    error?: any;
    openLog?: IIncident | null;
    setOpenLog: Dispatch<SetStateAction<any | null>>,
    isPostPay: () => boolean;
    metrics: IMetric[];
    refreshMetrics: ()=> void;
    loadingMetrics: boolean;
    metricsError: any;
    callouts: any;
    coverage: any;
    updateCallouts: ()=>void;
    lastSeenData: any;
    simData: any;
};

const SiteContext = createContext<SiteContextType | null>(null);

type SiteContextProviderProps = {
    id: any;
    date?: string;
    children: ReactNode;
};

export const SiteContextProvider: React.FC<SiteContextProviderProps> = ({id, date, children}) => {
    const {data, error, loading} = useSite(id, date);
    const {data: siteChargeData, error: siteChargeError, loading: siteChargeLoading} = useGetSiteCharge(id);
    const {data: lastSeenData} = useGetSiteLastSeen(id);
    const {data: simData} = useSiteSimData(id);
    const {data: callouts, updateCallouts} = useGetSecurityCallouts(id);
    const {data: coverage} = useGetSecurityCoverage(id);
    const {data: equipmentTypesData} = useEquipmentType();
    const {data: inventoryPartials} = useInventoryPartials();

    const {data: metrics, handleRefresh: refreshMetrics, loading: loadingMetrics, error: metricsError} = useSiteMetrics(id, date)
    const [openLog, setOpenLog] = useState(null);
    const isPostPay = () => {
        return data?.core_agreement?.core_tariff?.payment_type === 'postpaid';
    };

    useEffect(() => {
        const interval = setInterval(() => {
            refreshMetrics(id);
        }, METRICS_REFRESH_INTERVAL);

        return () => {
            clearInterval(interval);
        };
    }, [refreshMetrics]);

    if (loading || siteChargeLoading) {
        return <SkeletonLoader/>;
    }
    if (error || siteChargeError) {
        return <div>Error: {error?.message}</div>;
    }

    return (
        <SiteContext.Provider value={{
            id,
            date,
            site: data,
            loading,
            error,
            siteCharge: siteChargeData,
            isPostPay,
            openLog,
            setOpenLog,
            equipmentTypesData,
            inventoryPartials,
            metrics: metrics?.core_metric ?? [],
            refreshMetrics,
            updateCallouts,
            loadingMetrics,
            metricsError,
            callouts: callouts,
            coverage: coverage,
            lastSeenData,
            simData
        }}>
            {children}
        </SiteContext.Provider>
    );
};

export const useSiteContext = () => {
    const context = useContext(SiteContext);
    if (!context) {
        throw new Error("useSiteContext must be used within a SiteContextProvider");
    }
    return context;
};
