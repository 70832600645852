import {IShuriMuiFormDatePicker} from "../../../../lib";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {StyledDateTimePicker} from "./StyledDateTimePicker";
import {StyledFormTextField} from "../form-input";
import {useCallback, useEffect, useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import {StyledDatePicker} from "./StyledDatePicker";
import {TextFieldProps as MuiTextFieldPropsType} from "@mui/material/TextField/TextField";

export function ShuriMuiFormDatePicker({
                                           name,
                                           handleChange,
                                           handleBlur,
                                           value,
                                           label,
                                           placeholder,
                                           disabled,
                                           error,
                                           validateInputField,
                                           type = 'datetimepicker',
                                           size = 'small'
                                       }: IShuriMuiFormDatePicker) {
    const [dateValue, setDateValue] = useState<Dayjs | null>(value ? dayjs(value) : null);
    const handleDateChange = useCallback(
        (newValue: unknown) => {
            const date = newValue as Dayjs | null;
            setDateValue(date);
            handleChange(name, date);
            if (validateInputField) {
                validateInputField();
            }
        },
        [handleChange, name, validateInputField]
    );

    useEffect(() => {
        setDateValue(value ? dayjs(value) : null);
    }, [value]);

    const renderInput = (params: MuiTextFieldPropsType) => {
        const inputProps = {
            ...params,
            size,
            onBlur: handleBlur,
            error: !!error,
            helperText: error,
            disabled,
            placeholder,
            name
        }
        return <StyledFormTextField {...inputProps}/>
    }
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                {
                    type === "datetimepicker" &&
                    <StyledDateTimePicker
                        label={label ? label : ''}
                        disabled={disabled}
                        value={dateValue}
                        onChange={handleDateChange}
                        renderInput={renderInput}
                        componentsProps={{
                            actionBar: {
                                actions: ['today', 'clear'],
                            },
                        }}
                    />
                }

                {
                    type === 'datepicker' &&
                    <StyledDatePicker
                        disabled={disabled}
                        label={placeholder}
                        value={value}
                        onChange={handleDateChange}
                        renderInput={renderInput}
                        componentsProps={{
                            actionBar: {
                                actions: ['today', 'clear'],
                            },
                        }}
                    />
                }
            </LocalizationProvider>
        </>
    )
}