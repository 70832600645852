import {gql} from "apollo-boost";

export const ADD_SITE_BOX = gql`
    mutation(
        $site_id: bigint
        $equipment_id: bigint
        $created_on: timestamptz
        $date_modified: timestamptz
    ){
        insert_core_box(
            objects: {
                site_id: $site_id
                equipment_id: $equipment_id
                created_on: $created_on
                date_modified: $date_modified
            }
        ){
            returning {
                id
                site_id
                equipment_id
            }
        }
    }
`;

export const UPDATE_SITE_BOX = gql`
    mutation(
        $id: bigint!
        $site_id: bigint
        $equipment_id: bigint
        $date_modified: timestamptz
    ){
        update_core_box_by_pk(
            pk_columns: { id: $id }
            _set: {
                site_id: $site_id
                equipment_id: $equipment_id
                date_modified: $date_modified
            }
        ){
            id
            site_id
            equipment_id
        }
    }
`;