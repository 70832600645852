import {Box, Button, Grid, IconButton, Typography} from "@mui/material";
import {BaseColorLight, ButtonBaseColor, ButtonBaseColorLight, InputBaseColorLight} from "../../config";
import {Close} from "@mui/icons-material";
import {InventoriesForm, mapEquipmentInput, mapInventoryImages} from "./inventories-form";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {
    IEquipment,
    IEquipmentCondition,
    IEquipmentModel, IEquipmentPart,
    IEquipmentStatus,
    ISelectInput,
    useNotification,
    eventBus, mapNameAndIdToSelect,
    ADD_INVENTORY, UPDATE_INVENTORY, useAddInventoryImages, useInventoryPartials
} from "../../../lib";
import {useMutation} from "@apollo/client";

import {IEquipmentInput} from "../sites";
import {useParams} from "react-router-dom";

interface ICreateInventoriesProps {
    equipment?: IEquipment;
    refreshInventories?: () => void
    dismissForm?: () => void
}

export function CreateInventories({equipment, refreshInventories, dismissForm}: ICreateInventoriesProps) {
    const [equipmentsTypes, setEquipmentsType] = useState<ISelectInput[]>([]);
    const [equipmentModels, setEquipmentModels] = useState<ISelectInput[]>([]);
    const [equipmentStatuses, setEquipmentStatuses] = useState<ISelectInput[]>([]);
    const [equipmentConditions, setEquipmentConditions] = useState<ISelectInput[]>([]);
    const [equipmentParts, setEquipmentParts] = useState<ISelectInput[]>([]);
    const {data: inventoryPartials} = useInventoryPartials();
    const formRef = useRef<HTMLFormElement>(null);
    const [isFormDirty] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [updateInventory] = useMutation(UPDATE_INVENTORY);
    const [createInventory] = useMutation(ADD_INVENTORY);
    const {id} = useParams();
    const {notify} = useNotification();
    const {addInventoryImages, success, uploading} = useAddInventoryImages();
    useEffect(() => {
        setEquipmentsType(mapNameAndIdToSelect(inventoryPartials?.core_equipmenttype ?? []));
        setEquipmentModels(inventoryPartials?.core_equipmentmodel?.map((model: IEquipmentModel) => ({
            label: model?.equipment_model,
            value: model?.id,
            type_id : model?.type_id
        })) ?? []);
        setEquipmentStatuses(inventoryPartials?.core_equipmentstatus?.map((model: IEquipmentStatus) => ({
            label: model?.equipment_status,
            value: model?.id
        })) ?? []);
        setEquipmentConditions(inventoryPartials?.core_equipmentcondition?.map((model: IEquipmentCondition) => ({
            label: model?.condition,
            value: model?.id
        })) ?? []);
        setEquipmentParts(inventoryPartials?.core_equipmentpart?.map((model: IEquipmentPart) => ({
            label: model?.equipment_part,
            value: model?.id
        })) ?? []);

    }, [inventoryPartials])

    useEffect(() => {
        if (success && refreshInventories) {
            refreshInventories()
        }
    }, [success])

    const handleSubmitForm = async (values: IEquipmentInput, files?: FileList) => {
        if (!equipment) {
            await createInventory({
                variables: {
                    ...values,
                    owner: undefined,
                    created_on: new Date(),
                    site_id: id
                },
                async onCompleted(createdData) {
                    if (createdData) {
                        eventBus.emit('refresh_site_inventories', {site_id: id});

                        notify({
                            status: "success",
                            open: true,
                            message: "Inventory created successfully!",
                        });

                        if (files) {
                            try {
                                const invId = createdData?.insert_core_equipment?.returning[0]?.id;
                                const params = {
                                    files,
                                    id: invId,
                                }
                                await addInventoryImages(params)
                            } catch (e) {
                                notify({
                                    status: "warning",
                                    open: true,
                                    message: 'Inventory created successfully but an occurred trying to create inventory images',
                                });
                            }
                        }

                        if (refreshInventories) {
                            refreshInventories()
                        }

                        if (dismissForm) {
                            dismissForm()
                        }
                    }

                },
                onError(error) {
                    notify({
                        status: "error",
                        open: true,
                        message: 'An error occurred trying to create inventory, try again later!',
                    });
                }
            })
        } else {
            await updateInventory({
                variables: {
                    ...values,
                    id: equipment.id,
                    owner: undefined,
                    site_id: equipment.site_id
                },
                async onCompleted(updatedData) {
                    if (updatedData) {
                        if (files) {
                            const params = {
                                files,
                                id: equipment.id,
                            }
                            await addInventoryImages(params)
                        }
                        if (refreshInventories) {
                            refreshInventories()
                        }

                        if (dismissForm) {
                            dismissForm()
                        }
                        notify({
                            status: "success",
                            open: true,
                            message: "Inventory updated successfully!",
                        });
                    }
                },
                onError(error) {
                    notify({
                        status: "error",
                        open: true,
                        message: "An error occurred when trying to update inventory, try again later!",
                    });
                }
            });
        }
    }

    const triggerEditSubmit = () => {
        if (formRef.current) {
            formRef.current.dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}));
        }
    };

    return (
        <Box className="shuri-drawer-wrapper">
            <Box className="shuri-drawer-content">
                <Grid container spacing={2} sx={{
                    paddingBottom: '30px'
                }}>
                    <Grid item xs={10}>
                        <Typography sx={{
                            fontFamily: 'Outfit',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            fontSize: '18px',
                            lineHeight: '28px',
                            color: InputBaseColorLight
                        }}>Edit Inventory </Typography>
                        <Typography sx={{
                            fontFamily: 'Outfit',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: BaseColorLight
                        }}>Use the form below to edit details for this inventory</Typography>
                    </Grid>
                    <Grid item xs={2} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                    }}>
                        <IconButton aria-label="delete" size="large" color="secondary" onClick={dismissForm}>
                            <Close fontSize="inherit"/>
                        </IconButton>
                    </Grid>
                </Grid>

                <InventoriesForm
                    equipmentsTypes={equipmentsTypes}
                    equipmentModels={equipmentModels}
                    equipmentConditions={equipmentConditions}
                    equipmentParts={equipmentParts}
                    equipmentStatuses={equipmentStatuses}
                    equipment={mapEquipmentInput(equipment)}
                    onFormSubmit={handleSubmitForm}
                    onFormErrors={(formHasErrors) => setHasErrors(formHasErrors)}
                    has_submit={false}
                    formRef={formRef}
                    previews={mapInventoryImages(equipment?.core_equipmentimages ?? [])}
                    equipment_id={equipment?.id}
                    uploading={uploading}
                />
            </Box>

            <Box className="shuri-drawer-actions">
                <Button
                    onClick={dismissForm}
                    sx={{
                        color: ButtonBaseColorLight,
                        borderColor: ButtonBaseColor
                    }}
                    variant="outlined"
                    size="large">
                    Cancel
                </Button>

                <Button
                    disabled={!isFormDirty || hasErrors}
                    onClick={() => triggerEditSubmit()}
                    color="info"
                    variant="contained"
                    size="large">
                    Save Changes
                </Button>
            </Box>
        </Box>
    )
}