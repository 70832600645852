import { ButtonGroup, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Visibility } from "@mui/icons-material";
import { Core_Securitycallouts } from "../../../generated/graphql";
import { CancelCalloutAction, CalloutChat } from "../calllout-actions";
interface IJobTableActionsProps {
  log: Core_Securitycallouts;
  onRefreshLogs: () => void;
}
export function CalloutTableActions({
  log,
  onRefreshLogs,
}: IJobTableActionsProps) {
  const navigate = useNavigate();

  return (
    <ButtonGroup
      size="small"
      disableElevation
      variant="text"
      aria-label=" Action Buttons"
    >
      <IconButton
        onClick={() => navigate(`/site/${log.site_id}?armed-response`)}
        aria-label="View Callout"
        size="small"
      >
        <Visibility color="secondary" />
      </IconButton>
      <CalloutChat callout={log} />
      <CancelCalloutAction
        callout={log}
        handleRefreshCallouts={onRefreshLogs}
      />
    </ButtonGroup>
  );
}
