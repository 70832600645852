import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button } from "@mui/material";
import { Send } from "@mui/icons-material";
import { FormEvent } from "react";
import { useForm } from "../../../lib/form/hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapForm: {
      display: "flex",
      justifyContent: "center",
      width: "95%",
      margin: `${theme.spacing(0)} auto`,
    },
    wrapText: {
      width: "100%",
      "& .MuiInputBase-input": {
        color: "white",
      },
    },
    button: {},
  })
);

interface IMessage {
  message_body: string;
  message_id: number;
  outgoing: boolean;
  created_on: string;
  loading?: boolean;
}

interface ITextInputProps {
  handleAppendMessage: (message: IMessage) => void;
  sendMessage: (
    message: IMessage,
    calloutID: number,
    calloutExtID: number
  ) => void;
  calloutID: number;
  calloutExtID: number;
  closed: boolean;
}

export const TextInput: React.FC<ITextInputProps> = ({
  handleAppendMessage,
  sendMessage,
  calloutID,
  calloutExtID,
  closed,
}) => {
  const classes = useStyles();
  const { inputs, handleChange, resetForm } = useForm({
    message_body: "",
    message_id: "",
    outgoing: true,
    created_on: Date.now(),
    sending: true,
  });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendMessage(inputs.message_body, calloutID, calloutExtID);
    handleAppendMessage(inputs);
    resetForm();
  };

  return (
    <form noValidate className={classes.wrapForm} onSubmit={handleSubmit}>
      <TextField
        id="standard-text"
        placeholder="Message..."
        type="text"
        value={inputs.message_body}
        name="message_body"
        className={classes.wrapText}
        onChange={handleChange}
        disabled={closed}
      />
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        type="submit"
        disabled={closed}
      >
        <Send />
      </Button>
    </form>
  );
};
