import {gql, useQuery} from "@apollo/client";
import {perPage} from "../../../../config";

export const INCIDENT_FACIAL_RECOGNITIONS_QUERY = gql`
    query INCIDENT_FACIAL_RECOGNITIONS_QUERY(
        $incident_id: bigint,
        $limit: Int
        $offset: Int) {
        core_logfacialrecognition(where: {incident_id: {_eq: $incident_id}}, limit: $limit, offset: $offset) {
            authorised
            camera_id
            confidence
            created_on
            id
            incident_id
            media
            personale_id
            subject
            core_camera {
                camera_name
                camera_id
                ip_address
            }
            core_user {
                first_name
                last_name
                is_staff
                email
                is_active
                id
                is_superuser
            }
            core_incident {
                site_id
                id
            }
        }
        core_logfacialrecognition_aggregate(where: {incident_id: {_eq: $incident_id}}) {
            aggregate {
                count
            }
        }
    }
`;

export const useIncidentFacialRecognitions = (incident_id?: number, page = 1, limit = perPage) => {
    const offset = page * limit;
    const {data, error, loading, refetch} = useQuery(INCIDENT_FACIAL_RECOGNITIONS_QUERY, {
        variables: {
            offset,
            limit,
            incident_id
        },
        skip: !incident_id,
    });

    return {data, error, loading, refetch};
}