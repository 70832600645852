import gql from "graphql-tag";
import {useQuery} from "@apollo/client";

export const REGION_QUERY = gql`
    query REGION_QUERY {
        core_siteregion {
            region_code
            region_name
            id
          }
    }
`;

export const useRegion = () => {
    const {data, error, loading} = useQuery(REGION_QUERY);
    return {data, error, loading};
}