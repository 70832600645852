import {Breadcrumbs, IconButton} from "@mui/material";
import {ReactNode} from "react";
import {NavigateNext} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {BaseColorLight} from "../../../config";

interface IPageBreadcrumbsProps {
    breadcrumbs: ReactNode[]
}
export function PageBreadcrumbs({breadcrumbs}: IPageBreadcrumbsProps) {
    const links = [
        <Link key="1" color="inherit" to="/" >
            <IconButton aria-label="delete" size="small">
                <svg xmlns="http://www.w3.org/2000/svg"
                     width="20"
                     height="20"
                     fill={BaseColorLight}
                     viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM101.63,168h52.74C149,186.34,140,202.87,128,215.89,116,202.87,107,186.34,101.63,168ZM98,152a145.72,145.72,0,0,1,0-48h60a145.72,145.72,0,0,1,0,48ZM40,128a87.61,87.61,0,0,1,3.33-24H81.79a161.79,161.79,0,0,0,0,48H43.33A87.61,87.61,0,0,1,40,128ZM154.37,88H101.63C107,69.66,116,53.13,128,40.11,140,53.13,149,69.66,154.37,88Zm19.84,16h38.46a88.15,88.15,0,0,1,0,48H174.21a161.79,161.79,0,0,0,0-48Zm32.16-16H170.94a142.39,142.39,0,0,0-20.26-45A88.37,88.37,0,0,1,206.37,88ZM105.32,43A142.39,142.39,0,0,0,85.06,88H49.63A88.37,88.37,0,0,1,105.32,43ZM49.63,168H85.06a142.39,142.39,0,0,0,20.26,45A88.37,88.37,0,0,1,49.63,168Zm101.05,45a142.39,142.39,0,0,0,20.26-45h35.43A88.37,88.37,0,0,1,150.68,213Z"></path></svg>            </IconButton>
        </Link>,
        ...breadcrumbs
    ];
    return (
        <Breadcrumbs
            separator={<NavigateNext fontSize="small" color='secondary' />} aria-label="breadcrumb">
            {links}
        </Breadcrumbs>
    )
}