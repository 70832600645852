import {styled} from "@mui/material/styles";
import {Select} from "@mui/material";
import {errorBase, InputBaseColor, InputBaseColorLight} from "../../../config";

export const StyledFormSelect = styled(Select)(({theme}) => ({
    fontFamily: 'Outfit',
    borderRadius: '8px',
    filter: 'drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05))',
    color: InputBaseColorLight,
    '&.has-error': {
        'fieldset': {
            borderColor: errorBase
        }
    },
    '&:hover': {
        'fieldset': {
            borderColor: `${InputBaseColor} !important`
        }
    },
    '&.Mui-focused': {
        'fieldset': {
            borderColor: InputBaseColor
        }
    },
    '&.Mui-disabled': {
        color: InputBaseColor,
        cursor: 'not-allowed',
        'fieldset': {
            borderColor: `${InputBaseColor} !important`
        },
        '.MuiOutlinedInput-input.Mui-disabled': {
            color: InputBaseColor,
            textColor: InputBaseColor,
            WebkitTextFillColor: InputBaseColor
        }
    },
    'fieldset': {
        borderColor: InputBaseColor
    },
    '.select-placeholder': {
        color: InputBaseColor,
        fontSize: '14px',
        opacity: .5
    },
    '.MuiSelect-icon':{
        color: InputBaseColor,
    },
    '.select-item': {
        color: InputBaseColorLight,
    }
}));