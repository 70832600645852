import { Button, Card, Box, Typography } from "@mui/material";
import { MetricsCardBackground, MetricsCardTitleColor } from "../../../config";

interface EventCounterTileProps {
    /**
     * Usage tile title
     */
    title: string,

    /**
     * Usage tile amount
     */
    value: number,

    /**
     * Usage tile unit of measure e.g. ZAR, kWh, etc
     */
    items: { label: string, value: number, color: string }[],

    dark?: boolean
}

export function EventCounterTile(props: EventCounterTileProps) {
    return (
        <Button sx={{ padding: 0, width: '100%', borderRadius: '8px' }}>
            <Card sx={{ minWidth: 220, height: '9.25rem', borderRadius: '8px', backgroundColor: MetricsCardBackground, '@media (min-width: 1369px)': {width: '100%'} }}>
                <Box>
                    <Typography sx={{ fontSize: 14, textAlign: 'left', marginTop: '20px',paddingTop: 0, paddingLeft: '10px', marginBottom: '10px', color: MetricsCardTitleColor }}>
                        {props.title}
                    </Typography>
                    <Box sx={{color: '#7F7E7E' }}>
                        
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-start', flexDirection: 'row' }}>
                        {
                            props.items.map(item => {
                                return <Box key={`count-item-${item.label}`} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' , marginBottom: '20px'}}>
                                    <Typography sx={{ fontSize: '30px', fontWeight: 600, color: item.color, marginLeft: '20px', marginRight: '20px' }} gutterBottom>
                                        {item.value}
                                    </Typography>
                                    <Typography sx={{ marginLeft: '20px', fontWeight: 500, fontSize: 14, color: item.color, paddingRight: 3, width: 95 }} gutterBottom>
                                        {item.label}
                                    </Typography>
                                </Box>;
                            })
                        }
                    </Box>
                </Box>
            </Card>
        </Button>
    );
}
