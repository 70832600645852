export const formatPercentage = (value: number) => {
    return `${value.toFixed(2)}%`;
};

export const formatDecimal = (value: number, decimalPlaces = 2) => {
    return value.toFixed(decimalPlaces);
};

export const formatWithCommas = (value: number) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
