import {IPlayerProps} from "../../../lib";
import {Box, Dialog, DialogContent} from "@mui/material";
import {ShuriVideoError} from "./shuri-video-error";
import {ShuriVideoMeta} from "./shuri-video-meta";
import {ShuriVideoPlayer} from "./shuri-video-player";
import React from "react";

interface IShuriVideoDialogProps extends IPlayerProps {
    error?: string;
    open: boolean;
    onClose: () => void
    title?: string;
}

export function ShuriVideoDialog({url, error, onLoaded, open, onClose, title}: IShuriVideoDialogProps) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent sx={{
                padding: 0
            }}>
                <Box style={{
                    position: 'relative',
                    height: '350px',
                    borderRadius: '8px',
                }}>
                    {
                        error &&
                        <ShuriVideoError
                            loading={false}
                            error={error}
                        />
                    }
                    {
                        !error && title &&
                        <ShuriVideoMeta
                            has_close={open}
                            onClose={onClose}
                            description={title}/>
                    }

                    <ShuriVideoPlayer
                        url={url}
                        h={'300px'}
                        onLoaded={onLoaded}
                    />
                </Box>
            </DialogContent>
        </Dialog>
    )
}