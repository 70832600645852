import {PageAppBar, StyledBreadcrumbChild} from "../page";
import {useSiteContext} from "../../../lib/context";
import {formatDate} from "../../../lib/filters";
import {Chip, Typography} from "@mui/material";
import { InputBaseColor, MetricsCardBackground, primaryBlue} from "../../config";
import {Box} from "@mui/system";

export default function SiteHeadSection() {
    const {site, lastSeenData, simData} = useSiteContext();
    const coreState = lastSeenData?.core_sitestate
    const lastseen =  coreState?.[0]?.lastseen
    const siteReasons = coreState?.[0]?.core_statereasons
    const status = site?.core_site?.[0].core_sitestatus?.site_status
    const simDataTotal = simData?.core_simcardstatus_aggregate?.aggregate?.sum?.data_balance_in_mb ?? '--';
    const SiteSub = () => {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingTop: '5px',
            }}>
                {
                    lastseen && (
                        <>                                        
                            <Typography
                                sx={{
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '24px',
                                    color: InputBaseColor
                            }}>
                                Last Seen {formatDate(lastseen)}
                            </Typography>
                        </>
                    )
                }
                <Chip
                sx={{
                    backgroundColor: MetricsCardBackground,
                    fontWeight: '500',
                    fontSize: '12px',
                    lineHeight: '18px',
                    border: 'none',
                    padding: '2px 0px',
                    marginRight: '2px'

                }}
                label={status === "online" ? 'Online' : 'Offline'}
                color={status === "online" ? 'success' : 'error'}
                size="small"
                variant="outlined"
            />
                {site?.core_site[0]?.is_enabled ? 
                
                siteReasons?.map((reason: any, index: number) => {
                        return(
                            <Chip
                            key = {index}
                            sx={{
                                backgroundColor: MetricsCardBackground,
                                fontWeight: '500',
                                fontSize: '12px',
                                lineHeight: '18px',
                                border: 'none',
                                padding: '2px 0px',
                                marginRight: '2px'
        
                            }}
                            label={reason?.core_reasonenum?.reason}
                            color={status === "online" ? 'success' : 'error'}
                            size="small"
                            variant="outlined"
                        />
                        )
                }):
                <Chip
                sx={{
                    backgroundColor: MetricsCardBackground,
                    fontWeight: '500',
                    fontSize: '12px',
                    lineHeight: '18px',
                    border: 'none',
                    padding: '2px 0px',
                    marginRight: '2px'

                }}
                label= "Disabled"
                color= 'error'
                size="small"
                variant="outlined"
            />
               
                }
            {site?.core_site[0]?.is_enabled &&
            <>
                Current Data Allocation
                <Chip
                    sx={{
                        backgroundColor: MetricsCardBackground,
                        fontWeight: '500',
                        fontSize: '12px',
                        lineHeight: '18px',
                        border: 'none',
                        padding: '2px 8px',
                        marginLeft: '2px',
                        marginRight: '20px',
                        color:primaryBlue
                    }}
                    label={ `${simDataTotal} MB` }
                    size="small"
                    variant="outlined"
                />
            </>
            }
            </Box>
        )
    }
    return (
        <PageAppBar
            title={`${site?.core_site[0]?.name} . ${site?.core_site[0]?.site_id} `}
            subcomponent={<SiteSub/>}
            breadcrumbs={[
                <StyledBreadcrumbChild key='site-details-title'>
                    {site?.core_site[0]?.site_id ?? 'Site Details'}
                </StyledBreadcrumbChild>
            ]}
        />
    )
}