import {EventLogContextProvider} from "../../../../../lib/context";
import {useParams} from "react-router-dom";
import {EventLogComponent} from "../../../../components";

export function EventLogPage() {
    let {id} = useParams();

    return (
        <EventLogContextProvider id={id} >
            <EventLogComponent />
        </EventLogContextProvider>
    )
}