import { useState, useRef, useEffect, SyntheticEvent } from "react";
import {
  Content,
  VisitTrafficTile,
  ElectricalBreakagesTile,
  UsageTile,
  EventCounterTile,
} from "../../../components";

import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  IconButton,
  AccordionDetails,
  Typography,
  Stack,
  Card,
  CardHeader,
  useMediaQuery,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { DashboardCustomize, ExpandMore } from "@mui/icons-material";

import { useSites, useCompanyRangeMetricsSummary } from "../../../../lib";

import { PageAppBar } from "../../../components";
import { getCurrentDate } from "../../../../lib";
import {
  ShuriStyledAccordion,
  ShuriStyledAccordionSummary,
} from "../../../components/accordion";
import { BaseColorLight } from "../../../config";
import { useParams } from "react-router-dom";
import DashboardTabs from "./components/DashboardTabs";
import DashboardGrid from "./components/DashboardGrid";

export function DashboardPage(props: any) {
  const { error: siteError, loading: siteLoading, data: siteData } = useSites();
  const { id } = useParams();
  const currentDate = getCurrentDate();
  const [expanded, setExpanded] = useState<string | false>("telcoms");
  const handleChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const {
    error: mectricSummaryError,
    loading: mectricSummaryLoading,
    data: mectricSummaryData,
  } = useCompanyRangeMetricsSummary(currentDate, 500);

  const [scrollPositionCards, setScrollPositionCards] = useState(0);
  const [scrollPositionTables, setScrollPositionTables] = useState(0);
  const scrollContainerRefCards = useRef<HTMLDivElement>(null);
  const scrollContainerRefTables = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollContainer = scrollContainerRefCards.current;
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollPositionCards;
    }
  }, []);

  useEffect(() => {
    const scrollContainer = scrollContainerRefTables.current;
    if (scrollContainer) {
      scrollContainer.scrollTop = scrollPositionTables;
    }
  }, []);
  const handleScrollCards = (event: React.UIEvent<HTMLDivElement>) => {
    setScrollPositionCards(event.currentTarget.scrollTop);
  };

  const handleScrollTables = (event: React.UIEvent<HTMLDivElement>) => {
    setScrollPositionTables(event.currentTarget.scrollTop);
  };

  const handleScrollUpCards = () => {
    setScrollPositionCards((prevScrollPosition) => prevScrollPosition - 200);
  };

  const handleScrollUpTables = () => {
    setScrollPositionTables((prevScrollPosition) => prevScrollPosition - 200);
  };

  const handleScrollDownCards = () => {
    setScrollPositionCards((prevScrollPosition) => prevScrollPosition + 200);
  };
  const handleScrollDownTables = () => {
    setScrollPositionTables((prevScrollPosition) => prevScrollPosition + 200);
  };

  const totalOnlineSites = siteData?.core_site.filter((site: any) => {
    return site?.core_sitestatus?.site_status === "online" && site?.is_enabled;
  }).length;

  const totalOfflineSites = siteData?.core_site.filter((site: any) => {
    return site?.core_sitestatus?.site_status === "offline" && site?.is_enabled;
  }).length;

  const AtRiskSites = siteData?.core_site.filter((site: any) => {
    return site?.at_risk && site?.is_enabled;
  }).length;

  const eventLogHighPriority = mectricSummaryData?.core_eventlog.filter(
    (eventlog: any) => eventlog.event_priority === "High"
  ).length;

  const eventLogLowPriority = mectricSummaryData?.core_eventlog.filter(
    (eventlog: any) => eventlog.event_priority === "Low"
  ).length;

  const eventLogMediumPriority = mectricSummaryData?.core_eventlog.filter(
    (eventlog: any) => eventlog.event_priority === "Medium"
  ).length;

  const jobsClosed =
    mectricSummaryData?.core_jobstatus?.filter(
      (job: any) => job.core_jobstatusenum.status === "closed"
    ).length;

  const jobsOngoing =
    mectricSummaryData?.core_jobstatus?.filter(
      (job: any) => job.core_jobstatusenum.status  === "open"
    ).length;

  const visitsOpen = mectricSummaryData?.core_visit?.filter(
    (visit: any) => visit.core_visitstatusenum.status=== "open"
  ).length;

  const visitsCompleted = mectricSummaryData?.core_visit?.filter(
    (visit: any) => visit?.core_visitstatusenum.status === "closed"
  ).length;

  const visitsPending =
    mectricSummaryData?.core_visit?.filter(
      (visit: any) => visit?.core_visitstatusenum.status === "pending"
    )?.length ?? 0;

  const jobsTotal = mectricSummaryData?.core_job?.length;

  const usageTiles = [
    {
      component: UsageTile,
      props: {
        title: "No. Of Sim Cards with less than 6000MB",
        amount:
          mectricSummaryData?.core_simcardstatus_aggregate.aggregate.count,
      },
    },
    {
      component: UsageTile,
      props: {
        title: "Number of sites",
        amount: siteData?.core_site.length,
      },
    },
  ];
  const eventCounterTiles = [
    {
      component: EventCounterTile,
      props: {
        title: "Sites",
        value: 0,
        items: [
          {
            label: "Online",
            value: totalOnlineSites,
            color: "#12B76A",
          },
          {
            label: "Offline",
            value: totalOfflineSites,
            color: "#FF632B",
          },
          {
            label: "At Risk",
            value: AtRiskSites,
            color: "#F79009",
          },
        ],
      },
    },
    {
      component: EventCounterTile,
      props: {
        title: "Total Events",
        value: 0,
        items: [
          {
            label: "Low",
            value: eventLogLowPriority,
            color: "#12B76A",
          },
          {
            label: "Medium",
            value: eventLogMediumPriority,
            color: "#FF632B",
          },
          {
            label: "High",
            value: eventLogHighPriority,
            color: "#F79009",
          },
        ],
      },
    },
    {
      component: EventCounterTile,
      props: {
        title: "Jobs",
        value: 0,
        items: [
          {
            label: "Total",
            value: jobsTotal ? jobsTotal : 0,
            color: "#12B76A",
          },
          {
            label: "Closed",
            value: jobsClosed ? jobsClosed : 0,
            color: "#FF632B",
          },
          {
            label: "Ongoing",
            value: jobsOngoing ? jobsOngoing : 0,
            color: "#F79009",
          },
        ],
      },
    },
    {
      component: EventCounterTile,
      props: {
        title: "Visits",
        value: 0,
        items: [
          {
            label: "Open",
            value: visitsOpen ? visitsOpen : 0,
            color: "#12B76A",
          },
          {
            label: "Completed",
            value: visitsCompleted ? visitsCompleted : 0,
            color: "#FF632B",
          },
          {
            label: "Pending",
            value: visitsPending ? visitsPending : 0,
            color: "#F79009",
          },
        ],
      },
    },
  ];
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [displayType, setDisplayType] = useState<string>("grid");

  const handleChangeViewType = () => {
    if (displayType === "tabs") {
      setDisplayType("grid");
    } else {
      setDisplayType("tabs");
    }
  };
  useEffect(()=>{
    if(isSmallScreen){
      setDisplayType("tabs");
    }
  })
  if (siteError || mectricSummaryError) {
    return <h1>An error occurred </h1>;
  }
  if (siteLoading || mectricSummaryLoading) {
    return <p>Loading .... </p>;
  }
  return (
    <Content>
      <PageAppBar
        title="Dashboard"
        sub={`All ${id} Data`}
        ActionButton={() => (
          <Stack sx={{ marginRight: 2 }} spacing={1} direction={"row"}>
            <Button
              size="medium"
              variant="contained"
              color="info"
              startIcon={<AddIcon />}
              sx={{
                borderRadius: "28px",
                marginRight: "10px",
              }}
            >
              Create Job
            </Button>
          </Stack>
        )}
      />
     
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          maxHeight: "100%",
        }}
      >
        <Box
          sx={{
            marginLeft: 2,
            display:{xs:'none',sm: "flex",md: "flex",lg: "flex",xl: "flex"},
            flexDirection: "column",
            
          }}
        >
          <Box
            sx={{
              height: "75vh",
              overflowY: "scroll",
              overflowX: "hidden",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                display: "none" /* Hide scrollbar in Chrome and Safari */,
              },
            }}
            ref={scrollContainerRefCards}
            // onScroll={handleScrollCards}
          >
            <ShuriStyledAccordion
              expanded={expanded === "telcoms"}
              onChange={handleChange("telcoms")}
            >
              <ShuriStyledAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="telcoms-content"
                id="telcoms-header"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <IconButton>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 20V10M12 20V4M6 20V14"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </IconButton>
                  <Typography>Telcoms</Typography>
                </Box>
              </ShuriStyledAccordionSummary>
              <AccordionDetails>
                {eventCounterTiles.map((tile, index) => (
                  <Box sx={{ paddingTop: 1, paddingBottom: 1 }} key={index}>
                    <tile.component {...tile.props}></tile.component>
                  </Box>
                ))}

                {usageTiles.map((tile, index) => (
                  <Box sx={{ paddingTop: 1, paddingBottom: 1 }} key={index}>
                    <tile.component {...tile.props}></tile.component>
                  </Box>
                ))}
              </AccordionDetails>
            </ShuriStyledAccordion>
            <ShuriStyledAccordion
              expanded={expanded === "battery"}
              onChange={handleChange("battery")}
            >
              <ShuriStyledAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="battery-content"
                id="battery-header"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <IconButton>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="#fff"
                      viewBox="0 0 256 256"
                    >
                      <path d="M200,56H32A24,24,0,0,0,8,80v96a24,24,0,0,0,24,24H200a24,24,0,0,0,24-24V80A24,24,0,0,0,200,56Zm8,120a8,8,0,0,1-8,8H32a8,8,0,0,1-8-8V80a8,8,0,0,1,8-8H200a8,8,0,0,1,8,8Zm48-80v64a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0Z"></path>
                    </svg>
                  </IconButton>
                  <Typography>Battery</Typography>
                </Box>
              </ShuriStyledAccordionSummary>
              <AccordionDetails>
                <Typography sx={{ color: BaseColorLight }}>
                  No Battery metrics found
                </Typography>
              </AccordionDetails>
            </ShuriStyledAccordion>
            <ShuriStyledAccordion
              expanded={expanded === "rectifier"}
              onChange={handleChange("rectifier")}
            >
              <ShuriStyledAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="rectifier-content"
                id="rectifier-header"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <IconButton>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_11922_30800)">
                        <g clipPath="url(#clip1_11922_30800)">
                          <path
                            d="M8.3699 5.77832H6.396L13.2787 18.2925L16.2008 12.9788"
                            stroke="white"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10.0107 5.77832H20.1615L16.9918 11.5411"
                            stroke="white"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.2788 5.77838V0.351562"
                            stroke="white"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.40625 18.2925H20.1511"
                            stroke="white"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.2788 23.6485V18.2217"
                            stroke="white"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10.4434 18.311L7.97344 20.781H4.5105"
                            stroke="white"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </g>
                      <defs>
                        <clipPath id="clip0_11922_30800">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                        <clipPath id="clip1_11922_30800">
                          <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="translate(0.335938)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </IconButton>
                  <Typography>Rectifier</Typography>
                </Box>
              </ShuriStyledAccordionSummary>
              <AccordionDetails>
                <Typography sx={{ color: BaseColorLight }}>
                  No Rectifier metrics found
                </Typography>
              </AccordionDetails>
            </ShuriStyledAccordion>
          </Box>
          {scrollContainerRefCards.current &&
            scrollContainerRefCards.current.scrollHeight >
              scrollContainerRefCards.current.clientHeight && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50px",
                }}
              >
                {scrollPositionCards > 0 && (
                  <IconButton
                    aria-label="scroll up"
                    size="medium"
                    sx={{ color: "white" }}
                    onClick={handleScrollUpCards}
                  >
                    <KeyboardArrowUpIcon />
                  </IconButton>
                )}

                {scrollPositionCards < 170 && (
                  <IconButton
                    aria-label="scroll down"
                    onClick={handleScrollDownCards}
                    size="medium"
                    sx={{ color: "white" }}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                )}
              </Box>
            )}
        </Box>
        <Box
          sx={{
            paddingTop: 1,
            paddingRight: 2,
            paddingLeft: 2,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            objectFit:'contain'
          }}
        >
          <Box
            sx={{
              height: "75vh",
              overflowY: "scroll",
              overflowX: "hidden",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                display: "none" /* Hide scrollbar in Chrome and Safari */,
              },
            }}
            ref={scrollContainerRefTables}
            // onScroll={handleScrollTables}
          >
            <Card sx={{display:{xs:'none',sm:'block',md:'block',lg:'block',xl:'block'}}}>
              <CardHeader
                action={
                 <Stack direction={'row'} spacing={1}>
      
                   <Button
                   endIcon={ <DashboardCustomize />}
                    size="small"
                    onClick={() => handleChangeViewType()}
                  >
                   Switch view
                  </Button>
                 </Stack>
                }
              />
            </Card>
            {displayType === "tabs" ? <DashboardTabs /> : <DashboardGrid />}
          </Box>
          {scrollContainerRefTables.current &&
            scrollContainerRefTables.current.scrollHeight >
              scrollContainerRefTables.current.clientHeight && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50px",
                }}
              >
                {scrollPositionTables > 0 && (
                  <IconButton
                    aria-label="scroll up"
                    size="medium"
                    sx={{ color: "white" }}
                    onClick={handleScrollUpTables}
                  >
                    <KeyboardArrowUpIcon />
                  </IconButton>
                )}

                {scrollPositionTables < 170 && (
                  <IconButton
                    aria-label="scroll down"
                    onClick={handleScrollDownTables}
                    size="medium"
                    sx={{ color: "white" }}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                )}
              </Box>
            )}
        </Box>
      </Box>
    </Content>
  );
}
