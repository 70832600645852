import {Content, PageAppBar} from "../../../components";
import Stack from "@mui/material/Stack";
import {Box, Typography, ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    IconButton,
    Link,
    Divider} from "@mui/material";
import { primaryDark} from "../../../config";
import { useAppContext } from "../../../../lib/context/application";
import { NotificationFilter, ShuriList } from "../../../components/page/page-app-bar/notifications";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { UPDATE_NOTIFICATION_MUTATION } from "../../../../lib/hooks/notifications/notification.hooks";
import { getCurrentDate } from "../../../../lib";
import {  ChevronRightOutlined, SettingsOutlined, TextSnippetOutlined } from "@mui/icons-material";
import { calculateHoursDifference } from "../../../components/page/page-app-bar/uitils";
import { CreateRectifierAlarmPriority } from "./components/createAlarmPriority";
import { ListPriorities } from "./components/ListAlarmPriorities";


export function NotificationsPage() {
    const {notifications,updateNotificationData} = useAppContext();
    const [filter,setFilter] = useState<String>('unread');
    const [isCreateNew,setIsCreateNew] = useState(false);
    const [currentAlarm,setCurrentAlarm] = useState<any>(null)
    const [isListPriorities,setIsListPriorities] = useState(false)
    const [offset,setOffset] = useState<number>(0)
    const handleViewAlarms =(event:any)=>{
        setIsListPriorities(true)
    }
   
    const handleNofiticationFilter = (value: any) => {
        setFilter(value);
        console.log(value)
        switch (value) {
          case "unread": {
            updateNotificationData(true)
            break;
          }
          case "read": {
            updateNotificationData(false)
            break;
          }
          case 'archieved':{
            updateNotificationData(false)
            break;
          }
        }
      };
    const handleChangeConfig=(alarm:any)=>{
        setCurrentAlarm(alarm)
        setIsCreateNew(true)
    }
    const handleFetchMore=()=>{
      updateNotificationData(true,offset+1)
      setOffset(offset+1)
      
    }
    return (
      <Box>
        <PageAppBar title="Notifications" />

        <Content>
          <Box
            sx={{
              display: "flex",
              right: "35px",
              width: "15%",
              float: "right",
              justifyContent: "space-between",
              marginTop: "80px",
              marginRight: "15rem",
            }}
          >
            <Stack direction={'row'} spacing={1}>
             <IconButton onClick={event=>handleViewAlarms(event.currentTarget)}> <SettingsOutlined /></IconButton>
              <NotificationFilter
                status={filter}
                handleChangeFilter={(value: any) =>
                  handleNofiticationFilter(value)
                }
              />
            </Stack>
          </Box>

          <ShuriList
            sx={{
              width: "100%",
              padding: "10px 200px",
              maxHeight: "100rem",
              overflowY: "scroll",
              color: "white",
            }}
          >
            {notifications?.length === 0 ? (
              <span>No {filter} notifications</span>
            ) : (
              notifications.map((item: any, key: number) => {
                return (
                  <EventNotification
                    type={item.notification_type}
                    date={item.created_at}
                    message={item?.object_type}
                    id={item.id}
                    logId={item.object_id}
                    key={key}
                  />
                );
              })
            )}
             <ListItemButton
            onClick={() => handleFetchMore()}
            sx={{ textAlign: "center" }}
      
          >
            <ListItemText primary={"View more"}  secondary={<Typography>{`current page: ${offset+1}`}</Typography>}/>
          </ListItemButton>
          </ShuriList>
        </Content>
        
        <CreateRectifierAlarmPriority currentAlarm={currentAlarm} addOpen={isCreateNew} setAddOpen={(state:any)=>setIsCreateNew(state)} />
        <ListPriorities changeConfig={(item:any)=>handleChangeConfig(item)} addOpen={isListPriorities} setAddOpen={state=>setIsListPriorities(state)} />
      
      </Box>
    );
}


export const EventNotification = (props: {
    type: string;
    message?: string;
    date?: any;
    id: number;
    logId: any;
    key:any;
  }) => {
    const navigate = useNavigate();
    const [updateEventLog] = useMutation(UPDATE_NOTIFICATION_MUTATION, {});
  
  
  
    const handleViewLog = (logId: any,object_type:any,notificationId:any) => {
      updateEventLog({
        variables: {
          id: notificationId,
          viewed_on: getCurrentDate()
        }
      })
      if(object_type==='core_eventlog'){
  
        navigate(`/event-log/${logId}`);
      }
      else if(object_type==='job'){
        navigate(`/jobs/${logId}/edit`);
      }
    };
    return (
      <>
        <ListItemButton
          onClick={() => handleViewLog(props.logId,props.message,props.id)}
          key={props.key}
          alignItems="flex-start"
        >
          <ListItemIcon>
          <IconButton sx={{backgroundColor: primaryDark}}>
            <TextSnippetOutlined  color={"primary"}/>
          </IconButton>
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography sx={{ fontWeight: "bolder" }}>{props.type}</Typography>
            }
            secondary={
              <Stack>
                <Typography sx={{ fontSize: "small" }}>
                  {props.message==='core_eventlog'?'Event Log':props.message==='job'?'Job':'Notification'}
                </Typography>
              </Stack>
            }
          />
          <Stack rowGap={4}>
            <Typography sx={{ fontSize: "small" }}>
              {calculateHoursDifference(
                new Date(props.date),
                new Date()
              ).toString()}
            </Typography>
            <Box
              sx={{
                width: "100%",
                position: "relative",
                justifyContent: "right",
                justifyItems: "right",
              }}
            >
              <Link
                sx={{
                  float: "right",
                  fontWeight: 600,
                  display: "flex",
                }}
              >
                View
                <ChevronRightOutlined />
              </Link>
            </Box>
          </Stack>
        </ListItemButton>
        <Divider variant="inset" component="li" />
      </>
    );
  };
  


