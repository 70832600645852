import {Box} from "@mui/material";
import {NoCameraStreams} from "./no-camera-streams";
import {CameraStreamsGrid} from "./camera-streams-grid";
import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {AddCameraDialog} from "../add-camera-dialog";
import {ICameraStream, LayoutEnum, useCameraStreamsContext} from "../../../../lib";

interface IAllCameraStreamsProps {
    streams: ICameraStream[]
}

export function AllCameraStreams({streams}: IAllCameraStreamsProps) {
    const [searchParams] = useSearchParams();
    const [cameraId, setCameraId] = useState<string>()
    const {setPreview, setLayout} = useCameraStreamsContext();

    useEffect(() => {
        if (searchParams) {
            const camera_id = searchParams.get("camera_id");
            if (camera_id){
                handlePreviewStream(camera_id);
            }
        }
    }, [searchParams]);

    const handlePreviewStream = (camera_id: string) => {
        const camera = streams.find(stream => stream.core_camera.camera_id === camera_id);
        if (!camera) {
            setCameraId(camera_id)
        }else{
            setPreview(camera);
            setLayout(LayoutEnum.HIGHLIGHT)
        }
    }

    return (
        <Box
            sx={{
                minHeight: '50vh',
                position: 'relative'
            }}
        >
            {
                streams.length === 0 ?
                    <NoCameraStreams/>
                    :
                    <CameraStreamsGrid
                        cameras={streams}/>
            }
            <AddCameraDialog
                camera_id={cameraId}
                open={!!cameraId}
            />
        </Box>
    )
}