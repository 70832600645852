import {formatDate, ITablePropsInterface} from "../../../lib";
import {useNavigate} from "react-router-dom";
import {Box, Button, Table, TableBody, TableFooter, TableHead, TableRow} from "@mui/material";
import {ShuriTableRowStates, StyledTableCell, StyledTableRow} from "../table";
import {BaseColorLight} from "../../config";
import ShuriTablePagination from "../table/ShuriTablePagination";
import * as React from "react";
import {DetectionVideoDialog} from "./detection-video-dialog";


interface IDetectionsTableProps extends ITablePropsInterface {

}

export function DetectionsTable({
                                    data,
                                    page,
                                    rowsPerPage,
                                    handleChangePage,
                                    loading,
                                    error,
    handleChangeRowsPerPage
                                }: IDetectionsTableProps) {
    const navigate = useNavigate();

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <StyledTableCell>Detection ID</StyledTableCell>
                    <StyledTableCell>Date & Time</StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                    <StyledTableCell>Actions</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <ShuriTableRowStates
                    colSpan={6}
                    data={data ?? []}
                    loading={loading}
                    error={error}
                >
                    <>
                        {
                            data?.core_detections?.map((detection: any) => (
                                <StyledTableRow key={detection.id} sx={{
                                    color: 'white'
                                }}>
                                    <StyledTableCell component="th" scope="row">
                                        DET-{detection.id}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {formatDate(detection.created_on)}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {detection.description}
                                    </StyledTableCell>

                                    <StyledTableCell >
                                        <Box sx={{
                                            marginLeft: '10px',
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center'
                                        }}>
                                            <DetectionVideoDialog
                                                key={detection.id}
                                                event={detection}>
                                                <Button
                                                    sx={{
                                                        color: BaseColorLight,
                                                        fontSize: '14px',
                                                        textTransform: 'capitalize'
                                                    }}
                                                    startIcon={
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                             fill={BaseColorLight} viewBox="0 0 256 256">
                                                            <path
                                                                d="M232.4,114.49,88.32,26.35a16,16,0,0,0-16.2-.3A15.86,15.86,0,0,0,64,39.87V216.13A15.94,15.94,0,0,0,80,232a16.07,16.07,0,0,0,8.36-2.35L232.4,141.51a15.81,15.81,0,0,0,0-27ZM80,215.94V40l143.83,88Z"></path>
                                                        </svg>
                                                    }
                                                >
                                                    Play
                                                </Button>
                                            </DetectionVideoDialog>
                                            <Button
                                                onClick={()=> navigate(`/camera-streams?camera_id=${detection?.camera_id}`)}
                                                sx={{
                                                    color: BaseColorLight,
                                                    fontSize: '14px',
                                                    textTransform: 'capitalize'
                                                }}
                                                startIcon={
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                         fill={BaseColorLight} viewBox="0 0 256 256">
                                                        <path
                                                            d="M168,104a40,40,0,1,0-40,40A40,40,0,0,0,168,104Zm-64,0a24,24,0,1,1,24,24A24,24,0,0,1,104,104Zm120,96H136V183.6a80,80,0,1,0-16,0V200H32a8,8,0,0,0,0,16H224a8,8,0,0,0,0-16ZM64,104a64,64,0,1,1,64,64A64.07,64.07,0,0,1,64,104Z"></path>
                                                    </svg>
                                                }
                                            >
                                                Live
                                            </Button>

                                        </Box>

                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        }
                    </>
                </ShuriTableRowStates>
            </TableBody>

            {
                (data?.core_detections_aggregate?.aggregate?.count || 0) > rowsPerPage &&
                <TableFooter>
                    <TableRow sx={{border: 0, m: 1}}>
                        <ShuriTablePagination
                            colSpan={6}
                            count={data?.core_detections_aggregate?.aggregate?.count ?? 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            }
        </Table>
    )
}