import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  float8: any;
  smallint: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "auth_group" */
export type Auth_Group = {
  __typename?: 'auth_group';
  /** An array relationship */
  auth_group_permissions: Array<Auth_Group_Permissions>;
  /** An aggregate relationship */
  auth_group_permissions_aggregate: Auth_Group_Permissions_Aggregate;
  /** An array relationship */
  core_user_groups: Array<Core_User_Groups>;
  /** An aggregate relationship */
  core_user_groups_aggregate: Core_User_Groups_Aggregate;
  id: Scalars['Int'];
  name: Scalars['String'];
};


/** columns and relationships of "auth_group" */
export type Auth_GroupAuth_Group_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Group_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Group_Permissions_Order_By>>;
  where?: InputMaybe<Auth_Group_Permissions_Bool_Exp>;
};


/** columns and relationships of "auth_group" */
export type Auth_GroupAuth_Group_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Group_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Group_Permissions_Order_By>>;
  where?: InputMaybe<Auth_Group_Permissions_Bool_Exp>;
};


/** columns and relationships of "auth_group" */
export type Auth_GroupCore_User_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Core_User_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_Groups_Order_By>>;
  where?: InputMaybe<Core_User_Groups_Bool_Exp>;
};


/** columns and relationships of "auth_group" */
export type Auth_GroupCore_User_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_User_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_Groups_Order_By>>;
  where?: InputMaybe<Core_User_Groups_Bool_Exp>;
};

/** aggregated selection of "auth_group" */
export type Auth_Group_Aggregate = {
  __typename?: 'auth_group_aggregate';
  aggregate?: Maybe<Auth_Group_Aggregate_Fields>;
  nodes: Array<Auth_Group>;
};

/** aggregate fields of "auth_group" */
export type Auth_Group_Aggregate_Fields = {
  __typename?: 'auth_group_aggregate_fields';
  avg?: Maybe<Auth_Group_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Auth_Group_Max_Fields>;
  min?: Maybe<Auth_Group_Min_Fields>;
  stddev?: Maybe<Auth_Group_Stddev_Fields>;
  stddev_pop?: Maybe<Auth_Group_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Auth_Group_Stddev_Samp_Fields>;
  sum?: Maybe<Auth_Group_Sum_Fields>;
  var_pop?: Maybe<Auth_Group_Var_Pop_Fields>;
  var_samp?: Maybe<Auth_Group_Var_Samp_Fields>;
  variance?: Maybe<Auth_Group_Variance_Fields>;
};


/** aggregate fields of "auth_group" */
export type Auth_Group_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Auth_Group_Avg_Fields = {
  __typename?: 'auth_group_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "auth_group". All fields are combined with a logical 'AND'. */
export type Auth_Group_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Group_Bool_Exp>>;
  _not?: InputMaybe<Auth_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Group_Bool_Exp>>;
  auth_group_permissions?: InputMaybe<Auth_Group_Permissions_Bool_Exp>;
  auth_group_permissions_aggregate?: InputMaybe<Auth_Group_Permissions_Aggregate_Bool_Exp>;
  core_user_groups?: InputMaybe<Core_User_Groups_Bool_Exp>;
  core_user_groups_aggregate?: InputMaybe<Core_User_Groups_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth_group" */
export enum Auth_Group_Constraint {
  /** unique or primary key constraint on columns "name" */
  AuthGroupNameKey = 'auth_group_name_key',
  /** unique or primary key constraint on columns "id" */
  AuthGroupPkey = 'auth_group_pkey'
}

/** input type for incrementing numeric columns in table "auth_group" */
export type Auth_Group_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "auth_group" */
export type Auth_Group_Insert_Input = {
  auth_group_permissions?: InputMaybe<Auth_Group_Permissions_Arr_Rel_Insert_Input>;
  core_user_groups?: InputMaybe<Core_User_Groups_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Auth_Group_Max_Fields = {
  __typename?: 'auth_group_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Auth_Group_Min_Fields = {
  __typename?: 'auth_group_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "auth_group" */
export type Auth_Group_Mutation_Response = {
  __typename?: 'auth_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Group>;
};

/** input type for inserting object relation for remote table "auth_group" */
export type Auth_Group_Obj_Rel_Insert_Input = {
  data: Auth_Group_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Group_On_Conflict>;
};

/** on_conflict condition type for table "auth_group" */
export type Auth_Group_On_Conflict = {
  constraint: Auth_Group_Constraint;
  update_columns?: Array<Auth_Group_Update_Column>;
  where?: InputMaybe<Auth_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "auth_group". */
export type Auth_Group_Order_By = {
  auth_group_permissions_aggregate?: InputMaybe<Auth_Group_Permissions_Aggregate_Order_By>;
  core_user_groups_aggregate?: InputMaybe<Core_User_Groups_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** columns and relationships of "auth_group_permissions" */
export type Auth_Group_Permissions = {
  __typename?: 'auth_group_permissions';
  /** An object relationship */
  auth_group: Auth_Group;
  /** An object relationship */
  auth_permission: Auth_Permission;
  group_id: Scalars['Int'];
  id: Scalars['bigint'];
  permission_id: Scalars['Int'];
};

/** aggregated selection of "auth_group_permissions" */
export type Auth_Group_Permissions_Aggregate = {
  __typename?: 'auth_group_permissions_aggregate';
  aggregate?: Maybe<Auth_Group_Permissions_Aggregate_Fields>;
  nodes: Array<Auth_Group_Permissions>;
};

export type Auth_Group_Permissions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Auth_Group_Permissions_Aggregate_Bool_Exp_Count>;
};

export type Auth_Group_Permissions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Auth_Group_Permissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Auth_Group_Permissions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth_group_permissions" */
export type Auth_Group_Permissions_Aggregate_Fields = {
  __typename?: 'auth_group_permissions_aggregate_fields';
  avg?: Maybe<Auth_Group_Permissions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Auth_Group_Permissions_Max_Fields>;
  min?: Maybe<Auth_Group_Permissions_Min_Fields>;
  stddev?: Maybe<Auth_Group_Permissions_Stddev_Fields>;
  stddev_pop?: Maybe<Auth_Group_Permissions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Auth_Group_Permissions_Stddev_Samp_Fields>;
  sum?: Maybe<Auth_Group_Permissions_Sum_Fields>;
  var_pop?: Maybe<Auth_Group_Permissions_Var_Pop_Fields>;
  var_samp?: Maybe<Auth_Group_Permissions_Var_Samp_Fields>;
  variance?: Maybe<Auth_Group_Permissions_Variance_Fields>;
};


/** aggregate fields of "auth_group_permissions" */
export type Auth_Group_Permissions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Group_Permissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth_group_permissions" */
export type Auth_Group_Permissions_Aggregate_Order_By = {
  avg?: InputMaybe<Auth_Group_Permissions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Group_Permissions_Max_Order_By>;
  min?: InputMaybe<Auth_Group_Permissions_Min_Order_By>;
  stddev?: InputMaybe<Auth_Group_Permissions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Auth_Group_Permissions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Auth_Group_Permissions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Auth_Group_Permissions_Sum_Order_By>;
  var_pop?: InputMaybe<Auth_Group_Permissions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Auth_Group_Permissions_Var_Samp_Order_By>;
  variance?: InputMaybe<Auth_Group_Permissions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "auth_group_permissions" */
export type Auth_Group_Permissions_Arr_Rel_Insert_Input = {
  data: Array<Auth_Group_Permissions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Group_Permissions_On_Conflict>;
};

/** aggregate avg on columns */
export type Auth_Group_Permissions_Avg_Fields = {
  __typename?: 'auth_group_permissions_avg_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "auth_group_permissions" */
export type Auth_Group_Permissions_Avg_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "auth_group_permissions". All fields are combined with a logical 'AND'. */
export type Auth_Group_Permissions_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Group_Permissions_Bool_Exp>>;
  _not?: InputMaybe<Auth_Group_Permissions_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Group_Permissions_Bool_Exp>>;
  auth_group?: InputMaybe<Auth_Group_Bool_Exp>;
  auth_permission?: InputMaybe<Auth_Permission_Bool_Exp>;
  group_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  permission_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth_group_permissions" */
export enum Auth_Group_Permissions_Constraint {
  /** unique or primary key constraint on columns "group_id", "permission_id" */
  AuthGroupPermissionsGroupIdPermissionId_0cd325b0Uniq = 'auth_group_permissions_group_id_permission_id_0cd325b0_uniq',
  /** unique or primary key constraint on columns "id" */
  AuthGroupPermissionsPkey = 'auth_group_permissions_pkey'
}

/** input type for incrementing numeric columns in table "auth_group_permissions" */
export type Auth_Group_Permissions_Inc_Input = {
  group_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  permission_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "auth_group_permissions" */
export type Auth_Group_Permissions_Insert_Input = {
  auth_group?: InputMaybe<Auth_Group_Obj_Rel_Insert_Input>;
  auth_permission?: InputMaybe<Auth_Permission_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  permission_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Auth_Group_Permissions_Max_Fields = {
  __typename?: 'auth_group_permissions_max_fields';
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  permission_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "auth_group_permissions" */
export type Auth_Group_Permissions_Max_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Group_Permissions_Min_Fields = {
  __typename?: 'auth_group_permissions_min_fields';
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  permission_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "auth_group_permissions" */
export type Auth_Group_Permissions_Min_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth_group_permissions" */
export type Auth_Group_Permissions_Mutation_Response = {
  __typename?: 'auth_group_permissions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Group_Permissions>;
};

/** on_conflict condition type for table "auth_group_permissions" */
export type Auth_Group_Permissions_On_Conflict = {
  constraint: Auth_Group_Permissions_Constraint;
  update_columns?: Array<Auth_Group_Permissions_Update_Column>;
  where?: InputMaybe<Auth_Group_Permissions_Bool_Exp>;
};

/** Ordering options when selecting data from "auth_group_permissions". */
export type Auth_Group_Permissions_Order_By = {
  auth_group?: InputMaybe<Auth_Group_Order_By>;
  auth_permission?: InputMaybe<Auth_Permission_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth_group_permissions */
export type Auth_Group_Permissions_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "auth_group_permissions" */
export enum Auth_Group_Permissions_Select_Column {
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  PermissionId = 'permission_id'
}

/** input type for updating data in table "auth_group_permissions" */
export type Auth_Group_Permissions_Set_Input = {
  group_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  permission_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Auth_Group_Permissions_Stddev_Fields = {
  __typename?: 'auth_group_permissions_stddev_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "auth_group_permissions" */
export type Auth_Group_Permissions_Stddev_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Auth_Group_Permissions_Stddev_Pop_Fields = {
  __typename?: 'auth_group_permissions_stddev_pop_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "auth_group_permissions" */
export type Auth_Group_Permissions_Stddev_Pop_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Auth_Group_Permissions_Stddev_Samp_Fields = {
  __typename?: 'auth_group_permissions_stddev_samp_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "auth_group_permissions" */
export type Auth_Group_Permissions_Stddev_Samp_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "auth_group_permissions" */
export type Auth_Group_Permissions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Group_Permissions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Group_Permissions_Stream_Cursor_Value_Input = {
  group_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  permission_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Auth_Group_Permissions_Sum_Fields = {
  __typename?: 'auth_group_permissions_sum_fields';
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  permission_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "auth_group_permissions" */
export type Auth_Group_Permissions_Sum_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** update columns of table "auth_group_permissions" */
export enum Auth_Group_Permissions_Update_Column {
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  PermissionId = 'permission_id'
}

export type Auth_Group_Permissions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Auth_Group_Permissions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Group_Permissions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Group_Permissions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Auth_Group_Permissions_Var_Pop_Fields = {
  __typename?: 'auth_group_permissions_var_pop_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "auth_group_permissions" */
export type Auth_Group_Permissions_Var_Pop_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Auth_Group_Permissions_Var_Samp_Fields = {
  __typename?: 'auth_group_permissions_var_samp_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "auth_group_permissions" */
export type Auth_Group_Permissions_Var_Samp_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Auth_Group_Permissions_Variance_Fields = {
  __typename?: 'auth_group_permissions_variance_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "auth_group_permissions" */
export type Auth_Group_Permissions_Variance_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth_group */
export type Auth_Group_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "auth_group" */
export enum Auth_Group_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "auth_group" */
export type Auth_Group_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Auth_Group_Stddev_Fields = {
  __typename?: 'auth_group_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Auth_Group_Stddev_Pop_Fields = {
  __typename?: 'auth_group_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Auth_Group_Stddev_Samp_Fields = {
  __typename?: 'auth_group_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "auth_group" */
export type Auth_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Group_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Group_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Auth_Group_Sum_Fields = {
  __typename?: 'auth_group_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "auth_group" */
export enum Auth_Group_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Auth_Group_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Auth_Group_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Group_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Group_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Auth_Group_Var_Pop_Fields = {
  __typename?: 'auth_group_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Auth_Group_Var_Samp_Fields = {
  __typename?: 'auth_group_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Auth_Group_Variance_Fields = {
  __typename?: 'auth_group_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "auth_permission" */
export type Auth_Permission = {
  __typename?: 'auth_permission';
  /** An array relationship */
  auth_group_permissions: Array<Auth_Group_Permissions>;
  /** An aggregate relationship */
  auth_group_permissions_aggregate: Auth_Group_Permissions_Aggregate;
  codename: Scalars['String'];
  content_type_id: Scalars['Int'];
  /** An array relationship */
  core_user_user_permissions: Array<Core_User_User_Permissions>;
  /** An aggregate relationship */
  core_user_user_permissions_aggregate: Core_User_User_Permissions_Aggregate;
  /** An object relationship */
  django_content_type: Django_Content_Type;
  id: Scalars['Int'];
  name: Scalars['String'];
};


/** columns and relationships of "auth_permission" */
export type Auth_PermissionAuth_Group_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Group_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Group_Permissions_Order_By>>;
  where?: InputMaybe<Auth_Group_Permissions_Bool_Exp>;
};


/** columns and relationships of "auth_permission" */
export type Auth_PermissionAuth_Group_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Group_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Group_Permissions_Order_By>>;
  where?: InputMaybe<Auth_Group_Permissions_Bool_Exp>;
};


/** columns and relationships of "auth_permission" */
export type Auth_PermissionCore_User_User_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Core_User_User_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_User_Permissions_Order_By>>;
  where?: InputMaybe<Core_User_User_Permissions_Bool_Exp>;
};


/** columns and relationships of "auth_permission" */
export type Auth_PermissionCore_User_User_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_User_User_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_User_Permissions_Order_By>>;
  where?: InputMaybe<Core_User_User_Permissions_Bool_Exp>;
};

/** aggregated selection of "auth_permission" */
export type Auth_Permission_Aggregate = {
  __typename?: 'auth_permission_aggregate';
  aggregate?: Maybe<Auth_Permission_Aggregate_Fields>;
  nodes: Array<Auth_Permission>;
};

export type Auth_Permission_Aggregate_Bool_Exp = {
  count?: InputMaybe<Auth_Permission_Aggregate_Bool_Exp_Count>;
};

export type Auth_Permission_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Auth_Permission_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Auth_Permission_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth_permission" */
export type Auth_Permission_Aggregate_Fields = {
  __typename?: 'auth_permission_aggregate_fields';
  avg?: Maybe<Auth_Permission_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Auth_Permission_Max_Fields>;
  min?: Maybe<Auth_Permission_Min_Fields>;
  stddev?: Maybe<Auth_Permission_Stddev_Fields>;
  stddev_pop?: Maybe<Auth_Permission_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Auth_Permission_Stddev_Samp_Fields>;
  sum?: Maybe<Auth_Permission_Sum_Fields>;
  var_pop?: Maybe<Auth_Permission_Var_Pop_Fields>;
  var_samp?: Maybe<Auth_Permission_Var_Samp_Fields>;
  variance?: Maybe<Auth_Permission_Variance_Fields>;
};


/** aggregate fields of "auth_permission" */
export type Auth_Permission_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Permission_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth_permission" */
export type Auth_Permission_Aggregate_Order_By = {
  avg?: InputMaybe<Auth_Permission_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Permission_Max_Order_By>;
  min?: InputMaybe<Auth_Permission_Min_Order_By>;
  stddev?: InputMaybe<Auth_Permission_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Auth_Permission_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Auth_Permission_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Auth_Permission_Sum_Order_By>;
  var_pop?: InputMaybe<Auth_Permission_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Auth_Permission_Var_Samp_Order_By>;
  variance?: InputMaybe<Auth_Permission_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "auth_permission" */
export type Auth_Permission_Arr_Rel_Insert_Input = {
  data: Array<Auth_Permission_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Permission_On_Conflict>;
};

/** aggregate avg on columns */
export type Auth_Permission_Avg_Fields = {
  __typename?: 'auth_permission_avg_fields';
  content_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "auth_permission" */
export type Auth_Permission_Avg_Order_By = {
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "auth_permission". All fields are combined with a logical 'AND'. */
export type Auth_Permission_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Permission_Bool_Exp>>;
  _not?: InputMaybe<Auth_Permission_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Permission_Bool_Exp>>;
  auth_group_permissions?: InputMaybe<Auth_Group_Permissions_Bool_Exp>;
  auth_group_permissions_aggregate?: InputMaybe<Auth_Group_Permissions_Aggregate_Bool_Exp>;
  codename?: InputMaybe<String_Comparison_Exp>;
  content_type_id?: InputMaybe<Int_Comparison_Exp>;
  core_user_user_permissions?: InputMaybe<Core_User_User_Permissions_Bool_Exp>;
  core_user_user_permissions_aggregate?: InputMaybe<Core_User_User_Permissions_Aggregate_Bool_Exp>;
  django_content_type?: InputMaybe<Django_Content_Type_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth_permission" */
export enum Auth_Permission_Constraint {
  /** unique or primary key constraint on columns "codename", "content_type_id" */
  AuthPermissionContentTypeIdCodename_01ab375aUniq = 'auth_permission_content_type_id_codename_01ab375a_uniq',
  /** unique or primary key constraint on columns "id" */
  AuthPermissionPkey = 'auth_permission_pkey'
}

/** input type for incrementing numeric columns in table "auth_permission" */
export type Auth_Permission_Inc_Input = {
  content_type_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "auth_permission" */
export type Auth_Permission_Insert_Input = {
  auth_group_permissions?: InputMaybe<Auth_Group_Permissions_Arr_Rel_Insert_Input>;
  codename?: InputMaybe<Scalars['String']>;
  content_type_id?: InputMaybe<Scalars['Int']>;
  core_user_user_permissions?: InputMaybe<Core_User_User_Permissions_Arr_Rel_Insert_Input>;
  django_content_type?: InputMaybe<Django_Content_Type_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Auth_Permission_Max_Fields = {
  __typename?: 'auth_permission_max_fields';
  codename?: Maybe<Scalars['String']>;
  content_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "auth_permission" */
export type Auth_Permission_Max_Order_By = {
  codename?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Permission_Min_Fields = {
  __typename?: 'auth_permission_min_fields';
  codename?: Maybe<Scalars['String']>;
  content_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "auth_permission" */
export type Auth_Permission_Min_Order_By = {
  codename?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth_permission" */
export type Auth_Permission_Mutation_Response = {
  __typename?: 'auth_permission_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Permission>;
};

/** input type for inserting object relation for remote table "auth_permission" */
export type Auth_Permission_Obj_Rel_Insert_Input = {
  data: Auth_Permission_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Permission_On_Conflict>;
};

/** on_conflict condition type for table "auth_permission" */
export type Auth_Permission_On_Conflict = {
  constraint: Auth_Permission_Constraint;
  update_columns?: Array<Auth_Permission_Update_Column>;
  where?: InputMaybe<Auth_Permission_Bool_Exp>;
};

/** Ordering options when selecting data from "auth_permission". */
export type Auth_Permission_Order_By = {
  auth_group_permissions_aggregate?: InputMaybe<Auth_Group_Permissions_Aggregate_Order_By>;
  codename?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  core_user_user_permissions_aggregate?: InputMaybe<Core_User_User_Permissions_Aggregate_Order_By>;
  django_content_type?: InputMaybe<Django_Content_Type_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth_permission */
export type Auth_Permission_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "auth_permission" */
export enum Auth_Permission_Select_Column {
  /** column name */
  Codename = 'codename',
  /** column name */
  ContentTypeId = 'content_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "auth_permission" */
export type Auth_Permission_Set_Input = {
  codename?: InputMaybe<Scalars['String']>;
  content_type_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Auth_Permission_Stddev_Fields = {
  __typename?: 'auth_permission_stddev_fields';
  content_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "auth_permission" */
export type Auth_Permission_Stddev_Order_By = {
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Auth_Permission_Stddev_Pop_Fields = {
  __typename?: 'auth_permission_stddev_pop_fields';
  content_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "auth_permission" */
export type Auth_Permission_Stddev_Pop_Order_By = {
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Auth_Permission_Stddev_Samp_Fields = {
  __typename?: 'auth_permission_stddev_samp_fields';
  content_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "auth_permission" */
export type Auth_Permission_Stddev_Samp_Order_By = {
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "auth_permission" */
export type Auth_Permission_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Permission_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Permission_Stream_Cursor_Value_Input = {
  codename?: InputMaybe<Scalars['String']>;
  content_type_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Auth_Permission_Sum_Fields = {
  __typename?: 'auth_permission_sum_fields';
  content_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "auth_permission" */
export type Auth_Permission_Sum_Order_By = {
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "auth_permission" */
export enum Auth_Permission_Update_Column {
  /** column name */
  Codename = 'codename',
  /** column name */
  ContentTypeId = 'content_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Auth_Permission_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Auth_Permission_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Permission_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Permission_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Auth_Permission_Var_Pop_Fields = {
  __typename?: 'auth_permission_var_pop_fields';
  content_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "auth_permission" */
export type Auth_Permission_Var_Pop_Order_By = {
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Auth_Permission_Var_Samp_Fields = {
  __typename?: 'auth_permission_var_samp_fields';
  content_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "auth_permission" */
export type Auth_Permission_Var_Samp_Order_By = {
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Auth_Permission_Variance_Fields = {
  __typename?: 'auth_permission_variance_fields';
  content_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "auth_permission" */
export type Auth_Permission_Variance_Order_By = {
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "authtoken_token" */
export type Authtoken_Token = {
  __typename?: 'authtoken_token';
  /** An object relationship */
  core_user: Core_User;
  created: Scalars['timestamptz'];
  key: Scalars['String'];
  user_id: Scalars['bigint'];
};

/** aggregated selection of "authtoken_token" */
export type Authtoken_Token_Aggregate = {
  __typename?: 'authtoken_token_aggregate';
  aggregate?: Maybe<Authtoken_Token_Aggregate_Fields>;
  nodes: Array<Authtoken_Token>;
};

/** aggregate fields of "authtoken_token" */
export type Authtoken_Token_Aggregate_Fields = {
  __typename?: 'authtoken_token_aggregate_fields';
  avg?: Maybe<Authtoken_Token_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Authtoken_Token_Max_Fields>;
  min?: Maybe<Authtoken_Token_Min_Fields>;
  stddev?: Maybe<Authtoken_Token_Stddev_Fields>;
  stddev_pop?: Maybe<Authtoken_Token_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Authtoken_Token_Stddev_Samp_Fields>;
  sum?: Maybe<Authtoken_Token_Sum_Fields>;
  var_pop?: Maybe<Authtoken_Token_Var_Pop_Fields>;
  var_samp?: Maybe<Authtoken_Token_Var_Samp_Fields>;
  variance?: Maybe<Authtoken_Token_Variance_Fields>;
};


/** aggregate fields of "authtoken_token" */
export type Authtoken_Token_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Authtoken_Token_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Authtoken_Token_Avg_Fields = {
  __typename?: 'authtoken_token_avg_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "authtoken_token". All fields are combined with a logical 'AND'. */
export type Authtoken_Token_Bool_Exp = {
  _and?: InputMaybe<Array<Authtoken_Token_Bool_Exp>>;
  _not?: InputMaybe<Authtoken_Token_Bool_Exp>;
  _or?: InputMaybe<Array<Authtoken_Token_Bool_Exp>>;
  core_user?: InputMaybe<Core_User_Bool_Exp>;
  created?: InputMaybe<Timestamptz_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "authtoken_token" */
export enum Authtoken_Token_Constraint {
  /** unique or primary key constraint on columns "key" */
  AuthtokenTokenPkey = 'authtoken_token_pkey',
  /** unique or primary key constraint on columns "user_id" */
  AuthtokenTokenUserIdKey = 'authtoken_token_user_id_key'
}

/** input type for incrementing numeric columns in table "authtoken_token" */
export type Authtoken_Token_Inc_Input = {
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "authtoken_token" */
export type Authtoken_Token_Insert_Input = {
  core_user?: InputMaybe<Core_User_Obj_Rel_Insert_Input>;
  created?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Authtoken_Token_Max_Fields = {
  __typename?: 'authtoken_token_max_fields';
  created?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Authtoken_Token_Min_Fields = {
  __typename?: 'authtoken_token_min_fields';
  created?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "authtoken_token" */
export type Authtoken_Token_Mutation_Response = {
  __typename?: 'authtoken_token_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Authtoken_Token>;
};

/** input type for inserting object relation for remote table "authtoken_token" */
export type Authtoken_Token_Obj_Rel_Insert_Input = {
  data: Authtoken_Token_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Authtoken_Token_On_Conflict>;
};

/** on_conflict condition type for table "authtoken_token" */
export type Authtoken_Token_On_Conflict = {
  constraint: Authtoken_Token_Constraint;
  update_columns?: Array<Authtoken_Token_Update_Column>;
  where?: InputMaybe<Authtoken_Token_Bool_Exp>;
};

/** Ordering options when selecting data from "authtoken_token". */
export type Authtoken_Token_Order_By = {
  core_user?: InputMaybe<Core_User_Order_By>;
  created?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: authtoken_token */
export type Authtoken_Token_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** select columns of table "authtoken_token" */
export enum Authtoken_Token_Select_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Key = 'key',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "authtoken_token" */
export type Authtoken_Token_Set_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Authtoken_Token_Stddev_Fields = {
  __typename?: 'authtoken_token_stddev_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Authtoken_Token_Stddev_Pop_Fields = {
  __typename?: 'authtoken_token_stddev_pop_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Authtoken_Token_Stddev_Samp_Fields = {
  __typename?: 'authtoken_token_stddev_samp_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "authtoken_token" */
export type Authtoken_Token_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Authtoken_Token_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Authtoken_Token_Stream_Cursor_Value_Input = {
  created?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Authtoken_Token_Sum_Fields = {
  __typename?: 'authtoken_token_sum_fields';
  user_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "authtoken_token" */
export enum Authtoken_Token_Update_Column {
  /** column name */
  Created = 'created',
  /** column name */
  Key = 'key',
  /** column name */
  UserId = 'user_id'
}

export type Authtoken_Token_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Authtoken_Token_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Authtoken_Token_Set_Input>;
  /** filter the rows which have to be updated */
  where: Authtoken_Token_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Authtoken_Token_Var_Pop_Fields = {
  __typename?: 'authtoken_token_var_pop_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Authtoken_Token_Var_Samp_Fields = {
  __typename?: 'authtoken_token_var_samp_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Authtoken_Token_Variance_Fields = {
  __typename?: 'authtoken_token_variance_fields';
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "core_agreement" */
export type Core_Agreement = {
  __typename?: 'core_agreement';
  /** An object relationship */
  core_site: Core_Site;
  /** An object relationship */
  core_tariff: Core_Tariff;
  created_on: Scalars['timestamptz'];
  id: Scalars['bigint'];
  site_id: Scalars['bigint'];
  tariff_id: Scalars['bigint'];
};

/** aggregated selection of "core_agreement" */
export type Core_Agreement_Aggregate = {
  __typename?: 'core_agreement_aggregate';
  aggregate?: Maybe<Core_Agreement_Aggregate_Fields>;
  nodes: Array<Core_Agreement>;
};

export type Core_Agreement_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Agreement_Aggregate_Bool_Exp_Count>;
};

export type Core_Agreement_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Agreement_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Agreement_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_agreement" */
export type Core_Agreement_Aggregate_Fields = {
  __typename?: 'core_agreement_aggregate_fields';
  avg?: Maybe<Core_Agreement_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Agreement_Max_Fields>;
  min?: Maybe<Core_Agreement_Min_Fields>;
  stddev?: Maybe<Core_Agreement_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Agreement_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Agreement_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Agreement_Sum_Fields>;
  var_pop?: Maybe<Core_Agreement_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Agreement_Var_Samp_Fields>;
  variance?: Maybe<Core_Agreement_Variance_Fields>;
};


/** aggregate fields of "core_agreement" */
export type Core_Agreement_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Agreement_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_agreement" */
export type Core_Agreement_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Agreement_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Agreement_Max_Order_By>;
  min?: InputMaybe<Core_Agreement_Min_Order_By>;
  stddev?: InputMaybe<Core_Agreement_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Agreement_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Agreement_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Agreement_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Agreement_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Agreement_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Agreement_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_agreement" */
export type Core_Agreement_Arr_Rel_Insert_Input = {
  data: Array<Core_Agreement_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Agreement_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Agreement_Avg_Fields = {
  __typename?: 'core_agreement_avg_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_agreement" */
export type Core_Agreement_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_agreement". All fields are combined with a logical 'AND'. */
export type Core_Agreement_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Agreement_Bool_Exp>>;
  _not?: InputMaybe<Core_Agreement_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Agreement_Bool_Exp>>;
  core_site?: InputMaybe<Core_Site_Bool_Exp>;
  core_tariff?: InputMaybe<Core_Tariff_Bool_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  site_id?: InputMaybe<Bigint_Comparison_Exp>;
  tariff_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_agreement" */
export enum Core_Agreement_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreAgreementPkey = 'core_agreement_pkey'
}

/** input type for incrementing numeric columns in table "core_agreement" */
export type Core_Agreement_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  tariff_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_agreement" */
export type Core_Agreement_Insert_Input = {
  core_site?: InputMaybe<Core_Site_Obj_Rel_Insert_Input>;
  core_tariff?: InputMaybe<Core_Tariff_Obj_Rel_Insert_Input>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  tariff_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_Agreement_Max_Fields = {
  __typename?: 'core_agreement_max_fields';
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
  tariff_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "core_agreement" */
export type Core_Agreement_Max_Order_By = {
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Agreement_Min_Fields = {
  __typename?: 'core_agreement_min_fields';
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
  tariff_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "core_agreement" */
export type Core_Agreement_Min_Order_By = {
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_agreement" */
export type Core_Agreement_Mutation_Response = {
  __typename?: 'core_agreement_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Agreement>;
};

/** on_conflict condition type for table "core_agreement" */
export type Core_Agreement_On_Conflict = {
  constraint: Core_Agreement_Constraint;
  update_columns?: Array<Core_Agreement_Update_Column>;
  where?: InputMaybe<Core_Agreement_Bool_Exp>;
};

/** Ordering options when selecting data from "core_agreement". */
export type Core_Agreement_Order_By = {
  core_site?: InputMaybe<Core_Site_Order_By>;
  core_tariff?: InputMaybe<Core_Tariff_Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_agreement */
export type Core_Agreement_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_agreement" */
export enum Core_Agreement_Select_Column {
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  TariffId = 'tariff_id'
}

/** input type for updating data in table "core_agreement" */
export type Core_Agreement_Set_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  tariff_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_Agreement_Stddev_Fields = {
  __typename?: 'core_agreement_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_agreement" */
export type Core_Agreement_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Agreement_Stddev_Pop_Fields = {
  __typename?: 'core_agreement_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_agreement" */
export type Core_Agreement_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Agreement_Stddev_Samp_Fields = {
  __typename?: 'core_agreement_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_agreement" */
export type Core_Agreement_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_agreement" */
export type Core_Agreement_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Agreement_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Agreement_Stream_Cursor_Value_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  tariff_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_Agreement_Sum_Fields = {
  __typename?: 'core_agreement_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
  tariff_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_agreement" */
export type Core_Agreement_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_agreement" */
export enum Core_Agreement_Update_Column {
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  TariffId = 'tariff_id'
}

export type Core_Agreement_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Agreement_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Agreement_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Agreement_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Agreement_Var_Pop_Fields = {
  __typename?: 'core_agreement_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_agreement" */
export type Core_Agreement_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Agreement_Var_Samp_Fields = {
  __typename?: 'core_agreement_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_agreement" */
export type Core_Agreement_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Agreement_Variance_Fields = {
  __typename?: 'core_agreement_variance_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_agreement" */
export type Core_Agreement_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_box" */
export type Core_Box = {
  __typename?: 'core_box';
  /** An array relationship */
  core_boxstatuses: Array<Core_Boxstatus>;
  /** An aggregate relationship */
  core_boxstatuses_aggregate: Core_Boxstatus_Aggregate;
  /** An array relationship */
  core_cabins: Array<Core_Cabin>;
  /** An aggregate relationship */
  core_cabins_aggregate: Core_Cabin_Aggregate;
  /** An array relationship */
  core_cameras: Array<Core_Camera>;
  /** An aggregate relationship */
  core_cameras_aggregate: Core_Camera_Aggregate;
  /** An object relationship */
  core_equipment?: Maybe<Core_Equipment>;
  /** An array relationship */
  core_meters: Array<Core_Meter>;
  /** An aggregate relationship */
  core_meters_aggregate: Core_Meter_Aggregate;
  /** An array relationship */
  core_routers: Array<Core_Router>;
  /** An aggregate relationship */
  core_routers_aggregate: Core_Router_Aggregate;
  /** An object relationship */
  core_site?: Maybe<Core_Site>;
  created_on?: Maybe<Scalars['timestamptz']>;
  date_modified?: Maybe<Scalars['timestamptz']>;
  equipment_id?: Maybe<Scalars['bigint']>;
  equipment_name?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  site_id?: Maybe<Scalars['bigint']>;
  upswift_id?: Maybe<Scalars['String']>;
  upswift_project?: Maybe<Scalars['String']>;
};


/** columns and relationships of "core_box" */
export type Core_BoxCore_BoxstatusesArgs = {
  distinct_on?: InputMaybe<Array<Core_Boxstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Boxstatus_Order_By>>;
  where?: InputMaybe<Core_Boxstatus_Bool_Exp>;
};


/** columns and relationships of "core_box" */
export type Core_BoxCore_Boxstatuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Boxstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Boxstatus_Order_By>>;
  where?: InputMaybe<Core_Boxstatus_Bool_Exp>;
};


/** columns and relationships of "core_box" */
export type Core_BoxCore_CabinsArgs = {
  distinct_on?: InputMaybe<Array<Core_Cabin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Cabin_Order_By>>;
  where?: InputMaybe<Core_Cabin_Bool_Exp>;
};


/** columns and relationships of "core_box" */
export type Core_BoxCore_Cabins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Cabin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Cabin_Order_By>>;
  where?: InputMaybe<Core_Cabin_Bool_Exp>;
};


/** columns and relationships of "core_box" */
export type Core_BoxCore_CamerasArgs = {
  distinct_on?: InputMaybe<Array<Core_Camera_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Camera_Order_By>>;
  where?: InputMaybe<Core_Camera_Bool_Exp>;
};


/** columns and relationships of "core_box" */
export type Core_BoxCore_Cameras_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Camera_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Camera_Order_By>>;
  where?: InputMaybe<Core_Camera_Bool_Exp>;
};


/** columns and relationships of "core_box" */
export type Core_BoxCore_MetersArgs = {
  distinct_on?: InputMaybe<Array<Core_Meter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Meter_Order_By>>;
  where?: InputMaybe<Core_Meter_Bool_Exp>;
};


/** columns and relationships of "core_box" */
export type Core_BoxCore_Meters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Meter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Meter_Order_By>>;
  where?: InputMaybe<Core_Meter_Bool_Exp>;
};


/** columns and relationships of "core_box" */
export type Core_BoxCore_RoutersArgs = {
  distinct_on?: InputMaybe<Array<Core_Router_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Router_Order_By>>;
  where?: InputMaybe<Core_Router_Bool_Exp>;
};


/** columns and relationships of "core_box" */
export type Core_BoxCore_Routers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Router_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Router_Order_By>>;
  where?: InputMaybe<Core_Router_Bool_Exp>;
};

/** aggregated selection of "core_box" */
export type Core_Box_Aggregate = {
  __typename?: 'core_box_aggregate';
  aggregate?: Maybe<Core_Box_Aggregate_Fields>;
  nodes: Array<Core_Box>;
};

export type Core_Box_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Box_Aggregate_Bool_Exp_Count>;
};

export type Core_Box_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Box_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Box_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_box" */
export type Core_Box_Aggregate_Fields = {
  __typename?: 'core_box_aggregate_fields';
  avg?: Maybe<Core_Box_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Box_Max_Fields>;
  min?: Maybe<Core_Box_Min_Fields>;
  stddev?: Maybe<Core_Box_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Box_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Box_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Box_Sum_Fields>;
  var_pop?: Maybe<Core_Box_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Box_Var_Samp_Fields>;
  variance?: Maybe<Core_Box_Variance_Fields>;
};


/** aggregate fields of "core_box" */
export type Core_Box_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Box_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_box" */
export type Core_Box_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Box_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Box_Max_Order_By>;
  min?: InputMaybe<Core_Box_Min_Order_By>;
  stddev?: InputMaybe<Core_Box_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Box_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Box_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Box_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Box_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Box_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Box_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_box" */
export type Core_Box_Arr_Rel_Insert_Input = {
  data: Array<Core_Box_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Box_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Box_Avg_Fields = {
  __typename?: 'core_box_avg_fields';
  equipment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_box" */
export type Core_Box_Avg_Order_By = {
  equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_box". All fields are combined with a logical 'AND'. */
export type Core_Box_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Box_Bool_Exp>>;
  _not?: InputMaybe<Core_Box_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Box_Bool_Exp>>;
  core_boxstatuses?: InputMaybe<Core_Boxstatus_Bool_Exp>;
  core_boxstatuses_aggregate?: InputMaybe<Core_Boxstatus_Aggregate_Bool_Exp>;
  core_cabins?: InputMaybe<Core_Cabin_Bool_Exp>;
  core_cabins_aggregate?: InputMaybe<Core_Cabin_Aggregate_Bool_Exp>;
  core_cameras?: InputMaybe<Core_Camera_Bool_Exp>;
  core_cameras_aggregate?: InputMaybe<Core_Camera_Aggregate_Bool_Exp>;
  core_equipment?: InputMaybe<Core_Equipment_Bool_Exp>;
  core_meters?: InputMaybe<Core_Meter_Bool_Exp>;
  core_meters_aggregate?: InputMaybe<Core_Meter_Aggregate_Bool_Exp>;
  core_routers?: InputMaybe<Core_Router_Bool_Exp>;
  core_routers_aggregate?: InputMaybe<Core_Router_Aggregate_Bool_Exp>;
  core_site?: InputMaybe<Core_Site_Bool_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_modified?: InputMaybe<Timestamptz_Comparison_Exp>;
  equipment_id?: InputMaybe<Bigint_Comparison_Exp>;
  equipment_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  site_id?: InputMaybe<Bigint_Comparison_Exp>;
  upswift_id?: InputMaybe<String_Comparison_Exp>;
  upswift_project?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_box" */
export enum Core_Box_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreBoxPkey = 'core_box_pkey'
}

/** input type for incrementing numeric columns in table "core_box" */
export type Core_Box_Inc_Input = {
  equipment_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_box" */
export type Core_Box_Insert_Input = {
  core_boxstatuses?: InputMaybe<Core_Boxstatus_Arr_Rel_Insert_Input>;
  core_cabins?: InputMaybe<Core_Cabin_Arr_Rel_Insert_Input>;
  core_cameras?: InputMaybe<Core_Camera_Arr_Rel_Insert_Input>;
  core_equipment?: InputMaybe<Core_Equipment_Obj_Rel_Insert_Input>;
  core_meters?: InputMaybe<Core_Meter_Arr_Rel_Insert_Input>;
  core_routers?: InputMaybe<Core_Router_Arr_Rel_Insert_Input>;
  core_site?: InputMaybe<Core_Site_Obj_Rel_Insert_Input>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  date_modified?: InputMaybe<Scalars['timestamptz']>;
  equipment_id?: InputMaybe<Scalars['bigint']>;
  equipment_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  upswift_id?: InputMaybe<Scalars['String']>;
  upswift_project?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Box_Max_Fields = {
  __typename?: 'core_box_max_fields';
  created_on?: Maybe<Scalars['timestamptz']>;
  date_modified?: Maybe<Scalars['timestamptz']>;
  equipment_id?: Maybe<Scalars['bigint']>;
  equipment_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
  upswift_id?: Maybe<Scalars['String']>;
  upswift_project?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "core_box" */
export type Core_Box_Max_Order_By = {
  created_on?: InputMaybe<Order_By>;
  date_modified?: InputMaybe<Order_By>;
  equipment_id?: InputMaybe<Order_By>;
  equipment_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  upswift_id?: InputMaybe<Order_By>;
  upswift_project?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Box_Min_Fields = {
  __typename?: 'core_box_min_fields';
  created_on?: Maybe<Scalars['timestamptz']>;
  date_modified?: Maybe<Scalars['timestamptz']>;
  equipment_id?: Maybe<Scalars['bigint']>;
  equipment_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
  upswift_id?: Maybe<Scalars['String']>;
  upswift_project?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "core_box" */
export type Core_Box_Min_Order_By = {
  created_on?: InputMaybe<Order_By>;
  date_modified?: InputMaybe<Order_By>;
  equipment_id?: InputMaybe<Order_By>;
  equipment_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  upswift_id?: InputMaybe<Order_By>;
  upswift_project?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_box" */
export type Core_Box_Mutation_Response = {
  __typename?: 'core_box_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Box>;
};

/** input type for inserting object relation for remote table "core_box" */
export type Core_Box_Obj_Rel_Insert_Input = {
  data: Core_Box_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Box_On_Conflict>;
};

/** on_conflict condition type for table "core_box" */
export type Core_Box_On_Conflict = {
  constraint: Core_Box_Constraint;
  update_columns?: Array<Core_Box_Update_Column>;
  where?: InputMaybe<Core_Box_Bool_Exp>;
};

/** Ordering options when selecting data from "core_box". */
export type Core_Box_Order_By = {
  core_boxstatuses_aggregate?: InputMaybe<Core_Boxstatus_Aggregate_Order_By>;
  core_cabins_aggregate?: InputMaybe<Core_Cabin_Aggregate_Order_By>;
  core_cameras_aggregate?: InputMaybe<Core_Camera_Aggregate_Order_By>;
  core_equipment?: InputMaybe<Core_Equipment_Order_By>;
  core_meters_aggregate?: InputMaybe<Core_Meter_Aggregate_Order_By>;
  core_routers_aggregate?: InputMaybe<Core_Router_Aggregate_Order_By>;
  core_site?: InputMaybe<Core_Site_Order_By>;
  created_on?: InputMaybe<Order_By>;
  date_modified?: InputMaybe<Order_By>;
  equipment_id?: InputMaybe<Order_By>;
  equipment_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  upswift_id?: InputMaybe<Order_By>;
  upswift_project?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_box */
export type Core_Box_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_box" */
export enum Core_Box_Select_Column {
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  DateModified = 'date_modified',
  /** column name */
  EquipmentId = 'equipment_id',
  /** column name */
  EquipmentName = 'equipment_name',
  /** column name */
  Id = 'id',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  UpswiftId = 'upswift_id',
  /** column name */
  UpswiftProject = 'upswift_project'
}

/** input type for updating data in table "core_box" */
export type Core_Box_Set_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  date_modified?: InputMaybe<Scalars['timestamptz']>;
  equipment_id?: InputMaybe<Scalars['bigint']>;
  equipment_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  upswift_id?: InputMaybe<Scalars['String']>;
  upswift_project?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Box_Stddev_Fields = {
  __typename?: 'core_box_stddev_fields';
  equipment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_box" */
export type Core_Box_Stddev_Order_By = {
  equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Box_Stddev_Pop_Fields = {
  __typename?: 'core_box_stddev_pop_fields';
  equipment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_box" */
export type Core_Box_Stddev_Pop_Order_By = {
  equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Box_Stddev_Samp_Fields = {
  __typename?: 'core_box_stddev_samp_fields';
  equipment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_box" */
export type Core_Box_Stddev_Samp_Order_By = {
  equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_box" */
export type Core_Box_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Box_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Box_Stream_Cursor_Value_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  date_modified?: InputMaybe<Scalars['timestamptz']>;
  equipment_id?: InputMaybe<Scalars['bigint']>;
  equipment_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  upswift_id?: InputMaybe<Scalars['String']>;
  upswift_project?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Box_Sum_Fields = {
  __typename?: 'core_box_sum_fields';
  equipment_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_box" */
export type Core_Box_Sum_Order_By = {
  equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_box" */
export enum Core_Box_Update_Column {
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  DateModified = 'date_modified',
  /** column name */
  EquipmentId = 'equipment_id',
  /** column name */
  EquipmentName = 'equipment_name',
  /** column name */
  Id = 'id',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  UpswiftId = 'upswift_id',
  /** column name */
  UpswiftProject = 'upswift_project'
}

export type Core_Box_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Box_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Box_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Box_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Box_Var_Pop_Fields = {
  __typename?: 'core_box_var_pop_fields';
  equipment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_box" */
export type Core_Box_Var_Pop_Order_By = {
  equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Box_Var_Samp_Fields = {
  __typename?: 'core_box_var_samp_fields';
  equipment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_box" */
export type Core_Box_Var_Samp_Order_By = {
  equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Box_Variance_Fields = {
  __typename?: 'core_box_variance_fields';
  equipment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_box" */
export type Core_Box_Variance_Order_By = {
  equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_boxstatus" */
export type Core_Boxstatus = {
  __typename?: 'core_boxstatus';
  box_id: Scalars['bigint'];
  /** An object relationship */
  core_box: Core_Box;
  id: Scalars['bigint'];
  status: Scalars['String'];
  status_time: Scalars['timestamptz'];
};

/** aggregated selection of "core_boxstatus" */
export type Core_Boxstatus_Aggregate = {
  __typename?: 'core_boxstatus_aggregate';
  aggregate?: Maybe<Core_Boxstatus_Aggregate_Fields>;
  nodes: Array<Core_Boxstatus>;
};

export type Core_Boxstatus_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Boxstatus_Aggregate_Bool_Exp_Count>;
};

export type Core_Boxstatus_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Boxstatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Boxstatus_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_boxstatus" */
export type Core_Boxstatus_Aggregate_Fields = {
  __typename?: 'core_boxstatus_aggregate_fields';
  avg?: Maybe<Core_Boxstatus_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Boxstatus_Max_Fields>;
  min?: Maybe<Core_Boxstatus_Min_Fields>;
  stddev?: Maybe<Core_Boxstatus_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Boxstatus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Boxstatus_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Boxstatus_Sum_Fields>;
  var_pop?: Maybe<Core_Boxstatus_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Boxstatus_Var_Samp_Fields>;
  variance?: Maybe<Core_Boxstatus_Variance_Fields>;
};


/** aggregate fields of "core_boxstatus" */
export type Core_Boxstatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Boxstatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_boxstatus" */
export type Core_Boxstatus_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Boxstatus_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Boxstatus_Max_Order_By>;
  min?: InputMaybe<Core_Boxstatus_Min_Order_By>;
  stddev?: InputMaybe<Core_Boxstatus_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Boxstatus_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Boxstatus_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Boxstatus_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Boxstatus_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Boxstatus_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Boxstatus_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_boxstatus" */
export type Core_Boxstatus_Arr_Rel_Insert_Input = {
  data: Array<Core_Boxstatus_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Boxstatus_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Boxstatus_Avg_Fields = {
  __typename?: 'core_boxstatus_avg_fields';
  box_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_boxstatus" */
export type Core_Boxstatus_Avg_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_boxstatus". All fields are combined with a logical 'AND'. */
export type Core_Boxstatus_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Boxstatus_Bool_Exp>>;
  _not?: InputMaybe<Core_Boxstatus_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Boxstatus_Bool_Exp>>;
  box_id?: InputMaybe<Bigint_Comparison_Exp>;
  core_box?: InputMaybe<Core_Box_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  status_time?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_boxstatus" */
export enum Core_Boxstatus_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreBoxstatusPkey = 'core_boxstatus_pkey'
}

/** input type for incrementing numeric columns in table "core_boxstatus" */
export type Core_Boxstatus_Inc_Input = {
  box_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_boxstatus" */
export type Core_Boxstatus_Insert_Input = {
  box_id?: InputMaybe<Scalars['bigint']>;
  core_box?: InputMaybe<Core_Box_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
  status_time?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Core_Boxstatus_Max_Fields = {
  __typename?: 'core_boxstatus_max_fields';
  box_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
  status_time?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "core_boxstatus" */
export type Core_Boxstatus_Max_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  status_time?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Boxstatus_Min_Fields = {
  __typename?: 'core_boxstatus_min_fields';
  box_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
  status_time?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "core_boxstatus" */
export type Core_Boxstatus_Min_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  status_time?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_boxstatus" */
export type Core_Boxstatus_Mutation_Response = {
  __typename?: 'core_boxstatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Boxstatus>;
};

/** on_conflict condition type for table "core_boxstatus" */
export type Core_Boxstatus_On_Conflict = {
  constraint: Core_Boxstatus_Constraint;
  update_columns?: Array<Core_Boxstatus_Update_Column>;
  where?: InputMaybe<Core_Boxstatus_Bool_Exp>;
};

/** Ordering options when selecting data from "core_boxstatus". */
export type Core_Boxstatus_Order_By = {
  box_id?: InputMaybe<Order_By>;
  core_box?: InputMaybe<Core_Box_Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  status_time?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_boxstatus */
export type Core_Boxstatus_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_boxstatus" */
export enum Core_Boxstatus_Select_Column {
  /** column name */
  BoxId = 'box_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusTime = 'status_time'
}

/** input type for updating data in table "core_boxstatus" */
export type Core_Boxstatus_Set_Input = {
  box_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
  status_time?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Core_Boxstatus_Stddev_Fields = {
  __typename?: 'core_boxstatus_stddev_fields';
  box_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_boxstatus" */
export type Core_Boxstatus_Stddev_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Boxstatus_Stddev_Pop_Fields = {
  __typename?: 'core_boxstatus_stddev_pop_fields';
  box_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_boxstatus" */
export type Core_Boxstatus_Stddev_Pop_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Boxstatus_Stddev_Samp_Fields = {
  __typename?: 'core_boxstatus_stddev_samp_fields';
  box_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_boxstatus" */
export type Core_Boxstatus_Stddev_Samp_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_boxstatus" */
export type Core_Boxstatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Boxstatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Boxstatus_Stream_Cursor_Value_Input = {
  box_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
  status_time?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Core_Boxstatus_Sum_Fields = {
  __typename?: 'core_boxstatus_sum_fields';
  box_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_boxstatus" */
export type Core_Boxstatus_Sum_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "core_boxstatus" */
export enum Core_Boxstatus_Update_Column {
  /** column name */
  BoxId = 'box_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusTime = 'status_time'
}

export type Core_Boxstatus_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Boxstatus_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Boxstatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Boxstatus_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Boxstatus_Var_Pop_Fields = {
  __typename?: 'core_boxstatus_var_pop_fields';
  box_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_boxstatus" */
export type Core_Boxstatus_Var_Pop_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Boxstatus_Var_Samp_Fields = {
  __typename?: 'core_boxstatus_var_samp_fields';
  box_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_boxstatus" */
export type Core_Boxstatus_Var_Samp_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Boxstatus_Variance_Fields = {
  __typename?: 'core_boxstatus_variance_fields';
  box_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_boxstatus" */
export type Core_Boxstatus_Variance_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_cabin" */
export type Core_Cabin = {
  __typename?: 'core_cabin';
  /** An object relationship */
  core_box: Core_Box;
  /** An array relationship */
  core_metrics: Array<Core_Metric>;
  /** An aggregate relationship */
  core_metrics_aggregate: Core_Metric_Aggregate;
  /** An array relationship */
  core_rectifieralarms: Array<Core_Rectifieralarm>;
  /** An aggregate relationship */
  core_rectifieralarms_aggregate: Core_Rectifieralarm_Aggregate;
  created_on: Scalars['timestamptz'];
  id: Scalars['bigint'];
  shuri_box_id: Scalars['bigint'];
};


/** columns and relationships of "core_cabin" */
export type Core_CabinCore_MetricsArgs = {
  distinct_on?: InputMaybe<Array<Core_Metric_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Metric_Order_By>>;
  where?: InputMaybe<Core_Metric_Bool_Exp>;
};


/** columns and relationships of "core_cabin" */
export type Core_CabinCore_Metrics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Metric_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Metric_Order_By>>;
  where?: InputMaybe<Core_Metric_Bool_Exp>;
};


/** columns and relationships of "core_cabin" */
export type Core_CabinCore_RectifieralarmsArgs = {
  distinct_on?: InputMaybe<Array<Core_Rectifieralarm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Rectifieralarm_Order_By>>;
  where?: InputMaybe<Core_Rectifieralarm_Bool_Exp>;
};


/** columns and relationships of "core_cabin" */
export type Core_CabinCore_Rectifieralarms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Rectifieralarm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Rectifieralarm_Order_By>>;
  where?: InputMaybe<Core_Rectifieralarm_Bool_Exp>;
};

/** aggregated selection of "core_cabin" */
export type Core_Cabin_Aggregate = {
  __typename?: 'core_cabin_aggregate';
  aggregate?: Maybe<Core_Cabin_Aggregate_Fields>;
  nodes: Array<Core_Cabin>;
};

export type Core_Cabin_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Cabin_Aggregate_Bool_Exp_Count>;
};

export type Core_Cabin_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Cabin_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Cabin_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_cabin" */
export type Core_Cabin_Aggregate_Fields = {
  __typename?: 'core_cabin_aggregate_fields';
  avg?: Maybe<Core_Cabin_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Cabin_Max_Fields>;
  min?: Maybe<Core_Cabin_Min_Fields>;
  stddev?: Maybe<Core_Cabin_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Cabin_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Cabin_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Cabin_Sum_Fields>;
  var_pop?: Maybe<Core_Cabin_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Cabin_Var_Samp_Fields>;
  variance?: Maybe<Core_Cabin_Variance_Fields>;
};


/** aggregate fields of "core_cabin" */
export type Core_Cabin_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Cabin_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_cabin" */
export type Core_Cabin_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Cabin_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Cabin_Max_Order_By>;
  min?: InputMaybe<Core_Cabin_Min_Order_By>;
  stddev?: InputMaybe<Core_Cabin_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Cabin_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Cabin_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Cabin_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Cabin_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Cabin_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Cabin_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_cabin" */
export type Core_Cabin_Arr_Rel_Insert_Input = {
  data: Array<Core_Cabin_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Cabin_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Cabin_Avg_Fields = {
  __typename?: 'core_cabin_avg_fields';
  id?: Maybe<Scalars['Float']>;
  shuri_box_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_cabin" */
export type Core_Cabin_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  shuri_box_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_cabin". All fields are combined with a logical 'AND'. */
export type Core_Cabin_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Cabin_Bool_Exp>>;
  _not?: InputMaybe<Core_Cabin_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Cabin_Bool_Exp>>;
  core_box?: InputMaybe<Core_Box_Bool_Exp>;
  core_metrics?: InputMaybe<Core_Metric_Bool_Exp>;
  core_metrics_aggregate?: InputMaybe<Core_Metric_Aggregate_Bool_Exp>;
  core_rectifieralarms?: InputMaybe<Core_Rectifieralarm_Bool_Exp>;
  core_rectifieralarms_aggregate?: InputMaybe<Core_Rectifieralarm_Aggregate_Bool_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  shuri_box_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_cabin" */
export enum Core_Cabin_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreCabinPkey = 'core_cabin_pkey'
}

/** input type for incrementing numeric columns in table "core_cabin" */
export type Core_Cabin_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  shuri_box_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_cabin" */
export type Core_Cabin_Insert_Input = {
  core_box?: InputMaybe<Core_Box_Obj_Rel_Insert_Input>;
  core_metrics?: InputMaybe<Core_Metric_Arr_Rel_Insert_Input>;
  core_rectifieralarms?: InputMaybe<Core_Rectifieralarm_Arr_Rel_Insert_Input>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  shuri_box_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_Cabin_Max_Fields = {
  __typename?: 'core_cabin_max_fields';
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  shuri_box_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "core_cabin" */
export type Core_Cabin_Max_Order_By = {
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  shuri_box_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Cabin_Min_Fields = {
  __typename?: 'core_cabin_min_fields';
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  shuri_box_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "core_cabin" */
export type Core_Cabin_Min_Order_By = {
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  shuri_box_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_cabin" */
export type Core_Cabin_Mutation_Response = {
  __typename?: 'core_cabin_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Cabin>;
};

/** input type for inserting object relation for remote table "core_cabin" */
export type Core_Cabin_Obj_Rel_Insert_Input = {
  data: Core_Cabin_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Cabin_On_Conflict>;
};

/** on_conflict condition type for table "core_cabin" */
export type Core_Cabin_On_Conflict = {
  constraint: Core_Cabin_Constraint;
  update_columns?: Array<Core_Cabin_Update_Column>;
  where?: InputMaybe<Core_Cabin_Bool_Exp>;
};

/** Ordering options when selecting data from "core_cabin". */
export type Core_Cabin_Order_By = {
  core_box?: InputMaybe<Core_Box_Order_By>;
  core_metrics_aggregate?: InputMaybe<Core_Metric_Aggregate_Order_By>;
  core_rectifieralarms_aggregate?: InputMaybe<Core_Rectifieralarm_Aggregate_Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  shuri_box_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_cabin */
export type Core_Cabin_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_cabin" */
export enum Core_Cabin_Select_Column {
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  ShuriBoxId = 'shuri_box_id'
}

/** input type for updating data in table "core_cabin" */
export type Core_Cabin_Set_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  shuri_box_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_Cabin_Stddev_Fields = {
  __typename?: 'core_cabin_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  shuri_box_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_cabin" */
export type Core_Cabin_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  shuri_box_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Cabin_Stddev_Pop_Fields = {
  __typename?: 'core_cabin_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  shuri_box_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_cabin" */
export type Core_Cabin_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  shuri_box_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Cabin_Stddev_Samp_Fields = {
  __typename?: 'core_cabin_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  shuri_box_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_cabin" */
export type Core_Cabin_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  shuri_box_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_cabin" */
export type Core_Cabin_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Cabin_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Cabin_Stream_Cursor_Value_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  shuri_box_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_Cabin_Sum_Fields = {
  __typename?: 'core_cabin_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  shuri_box_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_cabin" */
export type Core_Cabin_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  shuri_box_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_cabin" */
export enum Core_Cabin_Update_Column {
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  ShuriBoxId = 'shuri_box_id'
}

export type Core_Cabin_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Cabin_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Cabin_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Cabin_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Cabin_Var_Pop_Fields = {
  __typename?: 'core_cabin_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  shuri_box_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_cabin" */
export type Core_Cabin_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  shuri_box_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Cabin_Var_Samp_Fields = {
  __typename?: 'core_cabin_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  shuri_box_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_cabin" */
export type Core_Cabin_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  shuri_box_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Cabin_Variance_Fields = {
  __typename?: 'core_cabin_variance_fields';
  id?: Maybe<Scalars['Float']>;
  shuri_box_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_cabin" */
export type Core_Cabin_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  shuri_box_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_camera" */
export type Core_Camera = {
  __typename?: 'core_camera';
  audio_stream_uri?: Maybe<Scalars['String']>;
  box_id: Scalars['bigint'];
  camera_id: Scalars['String'];
  camera_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  core_box: Core_Box;
  /** An array relationship */
  core_logfacialrecognitions: Array<Core_Logfacialrecognition>;
  /** An aggregate relationship */
  core_logfacialrecognitions_aggregate: Core_Logfacialrecognition_Aggregate;
  /** An array relationship */
  core_logsuspiciosactivities: Array<Core_Logsuspiciosactivity>;
  /** An aggregate relationship */
  core_logsuspiciosactivities_aggregate: Core_Logsuspiciosactivity_Aggregate;
  ip_address?: Maybe<Scalars['String']>;
  video_stream_name: Scalars['String'];
  video_stream_uri?: Maybe<Scalars['String']>;
};


/** columns and relationships of "core_camera" */
export type Core_CameraCore_LogfacialrecognitionsArgs = {
  distinct_on?: InputMaybe<Array<Core_Logfacialrecognition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logfacialrecognition_Order_By>>;
  where?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
};


/** columns and relationships of "core_camera" */
export type Core_CameraCore_Logfacialrecognitions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Logfacialrecognition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logfacialrecognition_Order_By>>;
  where?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
};


/** columns and relationships of "core_camera" */
export type Core_CameraCore_LogsuspiciosactivitiesArgs = {
  distinct_on?: InputMaybe<Array<Core_Logsuspiciosactivity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logsuspiciosactivity_Order_By>>;
  where?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
};


/** columns and relationships of "core_camera" */
export type Core_CameraCore_Logsuspiciosactivities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Logsuspiciosactivity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logsuspiciosactivity_Order_By>>;
  where?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
};

/** aggregated selection of "core_camera" */
export type Core_Camera_Aggregate = {
  __typename?: 'core_camera_aggregate';
  aggregate?: Maybe<Core_Camera_Aggregate_Fields>;
  nodes: Array<Core_Camera>;
};

export type Core_Camera_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Camera_Aggregate_Bool_Exp_Count>;
};

export type Core_Camera_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Camera_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Camera_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_camera" */
export type Core_Camera_Aggregate_Fields = {
  __typename?: 'core_camera_aggregate_fields';
  avg?: Maybe<Core_Camera_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Camera_Max_Fields>;
  min?: Maybe<Core_Camera_Min_Fields>;
  stddev?: Maybe<Core_Camera_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Camera_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Camera_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Camera_Sum_Fields>;
  var_pop?: Maybe<Core_Camera_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Camera_Var_Samp_Fields>;
  variance?: Maybe<Core_Camera_Variance_Fields>;
};


/** aggregate fields of "core_camera" */
export type Core_Camera_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Camera_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_camera" */
export type Core_Camera_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Camera_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Camera_Max_Order_By>;
  min?: InputMaybe<Core_Camera_Min_Order_By>;
  stddev?: InputMaybe<Core_Camera_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Camera_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Camera_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Camera_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Camera_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Camera_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Camera_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_camera" */
export type Core_Camera_Arr_Rel_Insert_Input = {
  data: Array<Core_Camera_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Camera_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Camera_Avg_Fields = {
  __typename?: 'core_camera_avg_fields';
  box_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_camera" */
export type Core_Camera_Avg_Order_By = {
  box_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_camera". All fields are combined with a logical 'AND'. */
export type Core_Camera_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Camera_Bool_Exp>>;
  _not?: InputMaybe<Core_Camera_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Camera_Bool_Exp>>;
  audio_stream_uri?: InputMaybe<String_Comparison_Exp>;
  box_id?: InputMaybe<Bigint_Comparison_Exp>;
  camera_id?: InputMaybe<String_Comparison_Exp>;
  camera_name?: InputMaybe<String_Comparison_Exp>;
  core_box?: InputMaybe<Core_Box_Bool_Exp>;
  core_logfacialrecognitions?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
  core_logfacialrecognitions_aggregate?: InputMaybe<Core_Logfacialrecognition_Aggregate_Bool_Exp>;
  core_logsuspiciosactivities?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
  core_logsuspiciosactivities_aggregate?: InputMaybe<Core_Logsuspiciosactivity_Aggregate_Bool_Exp>;
  ip_address?: InputMaybe<String_Comparison_Exp>;
  video_stream_name?: InputMaybe<String_Comparison_Exp>;
  video_stream_uri?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_camera" */
export enum Core_Camera_Constraint {
  /** unique or primary key constraint on columns "camera_id" */
  CoreCameraPkey = 'core_camera_pkey',
  /** unique or primary key constraint on columns "video_stream_name" */
  CoreCameraVideoStreamNameKey = 'core_camera_video_stream_name_key'
}

/** input type for incrementing numeric columns in table "core_camera" */
export type Core_Camera_Inc_Input = {
  box_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_camera" */
export type Core_Camera_Insert_Input = {
  audio_stream_uri?: InputMaybe<Scalars['String']>;
  box_id?: InputMaybe<Scalars['bigint']>;
  camera_id?: InputMaybe<Scalars['String']>;
  camera_name?: InputMaybe<Scalars['String']>;
  core_box?: InputMaybe<Core_Box_Obj_Rel_Insert_Input>;
  core_logfacialrecognitions?: InputMaybe<Core_Logfacialrecognition_Arr_Rel_Insert_Input>;
  core_logsuspiciosactivities?: InputMaybe<Core_Logsuspiciosactivity_Arr_Rel_Insert_Input>;
  ip_address?: InputMaybe<Scalars['String']>;
  video_stream_name?: InputMaybe<Scalars['String']>;
  video_stream_uri?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Camera_Max_Fields = {
  __typename?: 'core_camera_max_fields';
  audio_stream_uri?: Maybe<Scalars['String']>;
  box_id?: Maybe<Scalars['bigint']>;
  camera_id?: Maybe<Scalars['String']>;
  camera_name?: Maybe<Scalars['String']>;
  ip_address?: Maybe<Scalars['String']>;
  video_stream_name?: Maybe<Scalars['String']>;
  video_stream_uri?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "core_camera" */
export type Core_Camera_Max_Order_By = {
  audio_stream_uri?: InputMaybe<Order_By>;
  box_id?: InputMaybe<Order_By>;
  camera_id?: InputMaybe<Order_By>;
  camera_name?: InputMaybe<Order_By>;
  ip_address?: InputMaybe<Order_By>;
  video_stream_name?: InputMaybe<Order_By>;
  video_stream_uri?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Camera_Min_Fields = {
  __typename?: 'core_camera_min_fields';
  audio_stream_uri?: Maybe<Scalars['String']>;
  box_id?: Maybe<Scalars['bigint']>;
  camera_id?: Maybe<Scalars['String']>;
  camera_name?: Maybe<Scalars['String']>;
  ip_address?: Maybe<Scalars['String']>;
  video_stream_name?: Maybe<Scalars['String']>;
  video_stream_uri?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "core_camera" */
export type Core_Camera_Min_Order_By = {
  audio_stream_uri?: InputMaybe<Order_By>;
  box_id?: InputMaybe<Order_By>;
  camera_id?: InputMaybe<Order_By>;
  camera_name?: InputMaybe<Order_By>;
  ip_address?: InputMaybe<Order_By>;
  video_stream_name?: InputMaybe<Order_By>;
  video_stream_uri?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_camera" */
export type Core_Camera_Mutation_Response = {
  __typename?: 'core_camera_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Camera>;
};

/** input type for inserting object relation for remote table "core_camera" */
export type Core_Camera_Obj_Rel_Insert_Input = {
  data: Core_Camera_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Camera_On_Conflict>;
};

/** on_conflict condition type for table "core_camera" */
export type Core_Camera_On_Conflict = {
  constraint: Core_Camera_Constraint;
  update_columns?: Array<Core_Camera_Update_Column>;
  where?: InputMaybe<Core_Camera_Bool_Exp>;
};

/** Ordering options when selecting data from "core_camera". */
export type Core_Camera_Order_By = {
  audio_stream_uri?: InputMaybe<Order_By>;
  box_id?: InputMaybe<Order_By>;
  camera_id?: InputMaybe<Order_By>;
  camera_name?: InputMaybe<Order_By>;
  core_box?: InputMaybe<Core_Box_Order_By>;
  core_logfacialrecognitions_aggregate?: InputMaybe<Core_Logfacialrecognition_Aggregate_Order_By>;
  core_logsuspiciosactivities_aggregate?: InputMaybe<Core_Logsuspiciosactivity_Aggregate_Order_By>;
  ip_address?: InputMaybe<Order_By>;
  video_stream_name?: InputMaybe<Order_By>;
  video_stream_uri?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_camera */
export type Core_Camera_Pk_Columns_Input = {
  camera_id: Scalars['String'];
};

/** select columns of table "core_camera" */
export enum Core_Camera_Select_Column {
  /** column name */
  AudioStreamUri = 'audio_stream_uri',
  /** column name */
  BoxId = 'box_id',
  /** column name */
  CameraId = 'camera_id',
  /** column name */
  CameraName = 'camera_name',
  /** column name */
  IpAddress = 'ip_address',
  /** column name */
  VideoStreamName = 'video_stream_name',
  /** column name */
  VideoStreamUri = 'video_stream_uri'
}

/** input type for updating data in table "core_camera" */
export type Core_Camera_Set_Input = {
  audio_stream_uri?: InputMaybe<Scalars['String']>;
  box_id?: InputMaybe<Scalars['bigint']>;
  camera_id?: InputMaybe<Scalars['String']>;
  camera_name?: InputMaybe<Scalars['String']>;
  ip_address?: InputMaybe<Scalars['String']>;
  video_stream_name?: InputMaybe<Scalars['String']>;
  video_stream_uri?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Camera_Stddev_Fields = {
  __typename?: 'core_camera_stddev_fields';
  box_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_camera" */
export type Core_Camera_Stddev_Order_By = {
  box_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Camera_Stddev_Pop_Fields = {
  __typename?: 'core_camera_stddev_pop_fields';
  box_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_camera" */
export type Core_Camera_Stddev_Pop_Order_By = {
  box_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Camera_Stddev_Samp_Fields = {
  __typename?: 'core_camera_stddev_samp_fields';
  box_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_camera" */
export type Core_Camera_Stddev_Samp_Order_By = {
  box_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_camera" */
export type Core_Camera_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Camera_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Camera_Stream_Cursor_Value_Input = {
  audio_stream_uri?: InputMaybe<Scalars['String']>;
  box_id?: InputMaybe<Scalars['bigint']>;
  camera_id?: InputMaybe<Scalars['String']>;
  camera_name?: InputMaybe<Scalars['String']>;
  ip_address?: InputMaybe<Scalars['String']>;
  video_stream_name?: InputMaybe<Scalars['String']>;
  video_stream_uri?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Camera_Sum_Fields = {
  __typename?: 'core_camera_sum_fields';
  box_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_camera" */
export type Core_Camera_Sum_Order_By = {
  box_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_camera" */
export enum Core_Camera_Update_Column {
  /** column name */
  AudioStreamUri = 'audio_stream_uri',
  /** column name */
  BoxId = 'box_id',
  /** column name */
  CameraId = 'camera_id',
  /** column name */
  CameraName = 'camera_name',
  /** column name */
  IpAddress = 'ip_address',
  /** column name */
  VideoStreamName = 'video_stream_name',
  /** column name */
  VideoStreamUri = 'video_stream_uri'
}

export type Core_Camera_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Camera_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Camera_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Camera_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Camera_Var_Pop_Fields = {
  __typename?: 'core_camera_var_pop_fields';
  box_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_camera" */
export type Core_Camera_Var_Pop_Order_By = {
  box_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Camera_Var_Samp_Fields = {
  __typename?: 'core_camera_var_samp_fields';
  box_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_camera" */
export type Core_Camera_Var_Samp_Order_By = {
  box_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Camera_Variance_Fields = {
  __typename?: 'core_camera_variance_fields';
  box_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_camera" */
export type Core_Camera_Variance_Order_By = {
  box_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_company" */
export type Core_Company = {
  __typename?: 'core_company';
  address?: Maybe<Scalars['String']>;
  /** An array relationship */
  core_companysitechargeestimates: Array<Core_Companysitechargeestimate>;
  /** An aggregate relationship */
  core_companysitechargeestimates_aggregate: Core_Companysitechargeestimate_Aggregate;
  /** An array relationship */
  core_noneuserpersonales: Array<Core_Noneuserpersonale>;
  /** An aggregate relationship */
  core_noneuserpersonales_aggregate: Core_Noneuserpersonale_Aggregate;
  /** An array relationship */
  core_users: Array<Core_User>;
  /** An aggregate relationship */
  core_users_aggregate: Core_User_Aggregate;
  created_on: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['bigint'];
  name: Scalars['String'];
  phone: Scalars['String'];
};


/** columns and relationships of "core_company" */
export type Core_CompanyCore_CompanysitechargeestimatesArgs = {
  distinct_on?: InputMaybe<Array<Core_Companysitechargeestimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Companysitechargeestimate_Order_By>>;
  where?: InputMaybe<Core_Companysitechargeestimate_Bool_Exp>;
};


/** columns and relationships of "core_company" */
export type Core_CompanyCore_Companysitechargeestimates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Companysitechargeestimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Companysitechargeestimate_Order_By>>;
  where?: InputMaybe<Core_Companysitechargeestimate_Bool_Exp>;
};


/** columns and relationships of "core_company" */
export type Core_CompanyCore_NoneuserpersonalesArgs = {
  distinct_on?: InputMaybe<Array<Core_Noneuserpersonale_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Noneuserpersonale_Order_By>>;
  where?: InputMaybe<Core_Noneuserpersonale_Bool_Exp>;
};


/** columns and relationships of "core_company" */
export type Core_CompanyCore_Noneuserpersonales_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Noneuserpersonale_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Noneuserpersonale_Order_By>>;
  where?: InputMaybe<Core_Noneuserpersonale_Bool_Exp>;
};


/** columns and relationships of "core_company" */
export type Core_CompanyCore_UsersArgs = {
  distinct_on?: InputMaybe<Array<Core_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_Order_By>>;
  where?: InputMaybe<Core_User_Bool_Exp>;
};


/** columns and relationships of "core_company" */
export type Core_CompanyCore_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_Order_By>>;
  where?: InputMaybe<Core_User_Bool_Exp>;
};

/** aggregated selection of "core_company" */
export type Core_Company_Aggregate = {
  __typename?: 'core_company_aggregate';
  aggregate?: Maybe<Core_Company_Aggregate_Fields>;
  nodes: Array<Core_Company>;
};

/** aggregate fields of "core_company" */
export type Core_Company_Aggregate_Fields = {
  __typename?: 'core_company_aggregate_fields';
  avg?: Maybe<Core_Company_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Company_Max_Fields>;
  min?: Maybe<Core_Company_Min_Fields>;
  stddev?: Maybe<Core_Company_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Company_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Company_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Company_Sum_Fields>;
  var_pop?: Maybe<Core_Company_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Company_Var_Samp_Fields>;
  variance?: Maybe<Core_Company_Variance_Fields>;
};


/** aggregate fields of "core_company" */
export type Core_Company_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Company_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Core_Company_Avg_Fields = {
  __typename?: 'core_company_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "core_company". All fields are combined with a logical 'AND'. */
export type Core_Company_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Company_Bool_Exp>>;
  _not?: InputMaybe<Core_Company_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Company_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  core_companysitechargeestimates?: InputMaybe<Core_Companysitechargeestimate_Bool_Exp>;
  core_companysitechargeestimates_aggregate?: InputMaybe<Core_Companysitechargeestimate_Aggregate_Bool_Exp>;
  core_noneuserpersonales?: InputMaybe<Core_Noneuserpersonale_Bool_Exp>;
  core_noneuserpersonales_aggregate?: InputMaybe<Core_Noneuserpersonale_Aggregate_Bool_Exp>;
  core_users?: InputMaybe<Core_User_Bool_Exp>;
  core_users_aggregate?: InputMaybe<Core_User_Aggregate_Bool_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_company" */
export enum Core_Company_Constraint {
  /** unique or primary key constraint on columns "email" */
  CoreCompanyEmailKey = 'core_company_email_key',
  /** unique or primary key constraint on columns "id" */
  CoreCompanyPkey = 'core_company_pkey'
}

/** input type for incrementing numeric columns in table "core_company" */
export type Core_Company_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_company" */
export type Core_Company_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  core_companysitechargeestimates?: InputMaybe<Core_Companysitechargeestimate_Arr_Rel_Insert_Input>;
  core_noneuserpersonales?: InputMaybe<Core_Noneuserpersonale_Arr_Rel_Insert_Input>;
  core_users?: InputMaybe<Core_User_Arr_Rel_Insert_Input>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Company_Max_Fields = {
  __typename?: 'core_company_max_fields';
  address?: Maybe<Scalars['String']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Core_Company_Min_Fields = {
  __typename?: 'core_company_min_fields';
  address?: Maybe<Scalars['String']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "core_company" */
export type Core_Company_Mutation_Response = {
  __typename?: 'core_company_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Company>;
};

/** input type for inserting object relation for remote table "core_company" */
export type Core_Company_Obj_Rel_Insert_Input = {
  data: Core_Company_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Company_On_Conflict>;
};

/** on_conflict condition type for table "core_company" */
export type Core_Company_On_Conflict = {
  constraint: Core_Company_Constraint;
  update_columns?: Array<Core_Company_Update_Column>;
  where?: InputMaybe<Core_Company_Bool_Exp>;
};

/** Ordering options when selecting data from "core_company". */
export type Core_Company_Order_By = {
  address?: InputMaybe<Order_By>;
  core_companysitechargeestimates_aggregate?: InputMaybe<Core_Companysitechargeestimate_Aggregate_Order_By>;
  core_noneuserpersonales_aggregate?: InputMaybe<Core_Noneuserpersonale_Aggregate_Order_By>;
  core_users_aggregate?: InputMaybe<Core_User_Aggregate_Order_By>;
  created_on?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_company */
export type Core_Company_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_company" */
export enum Core_Company_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone'
}

/** input type for updating data in table "core_company" */
export type Core_Company_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Company_Stddev_Fields = {
  __typename?: 'core_company_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Core_Company_Stddev_Pop_Fields = {
  __typename?: 'core_company_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Core_Company_Stddev_Samp_Fields = {
  __typename?: 'core_company_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "core_company" */
export type Core_Company_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Company_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Company_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Company_Sum_Fields = {
  __typename?: 'core_company_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "core_company" */
export enum Core_Company_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Phone = 'phone'
}

export type Core_Company_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Company_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Company_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Company_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Company_Var_Pop_Fields = {
  __typename?: 'core_company_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Core_Company_Var_Samp_Fields = {
  __typename?: 'core_company_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Core_Company_Variance_Fields = {
  __typename?: 'core_company_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "core_companysitechargeestimate" */
export type Core_Companysitechargeestimate = {
  __typename?: 'core_companysitechargeestimate';
  charge_period_end: Scalars['timestamptz'];
  charge_period_start: Scalars['timestamptz'];
  company_id: Scalars['bigint'];
  /** An object relationship */
  core_company: Core_Company;
  cost: Scalars['float8'];
  created_on: Scalars['timestamptz'];
  id: Scalars['bigint'];
  total_units: Scalars['float8'];
};

/** aggregated selection of "core_companysitechargeestimate" */
export type Core_Companysitechargeestimate_Aggregate = {
  __typename?: 'core_companysitechargeestimate_aggregate';
  aggregate?: Maybe<Core_Companysitechargeestimate_Aggregate_Fields>;
  nodes: Array<Core_Companysitechargeestimate>;
};

export type Core_Companysitechargeestimate_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Core_Companysitechargeestimate_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Core_Companysitechargeestimate_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Core_Companysitechargeestimate_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Core_Companysitechargeestimate_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Core_Companysitechargeestimate_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Core_Companysitechargeestimate_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Core_Companysitechargeestimate_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Core_Companysitechargeestimate_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Core_Companysitechargeestimate_Aggregate_Bool_Exp_Var_Samp>;
};

export type Core_Companysitechargeestimate_Aggregate_Bool_Exp_Avg = {
  arguments: Core_Companysitechargeestimate_Select_Column_Core_Companysitechargeestimate_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Companysitechargeestimate_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Companysitechargeestimate_Aggregate_Bool_Exp_Corr = {
  arguments: Core_Companysitechargeestimate_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Companysitechargeestimate_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Companysitechargeestimate_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Core_Companysitechargeestimate_Select_Column_Core_Companysitechargeestimate_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Core_Companysitechargeestimate_Select_Column_Core_Companysitechargeestimate_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Core_Companysitechargeestimate_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Companysitechargeestimate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Companysitechargeestimate_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Core_Companysitechargeestimate_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Core_Companysitechargeestimate_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Companysitechargeestimate_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Companysitechargeestimate_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Core_Companysitechargeestimate_Select_Column_Core_Companysitechargeestimate_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Core_Companysitechargeestimate_Select_Column_Core_Companysitechargeestimate_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Core_Companysitechargeestimate_Aggregate_Bool_Exp_Max = {
  arguments: Core_Companysitechargeestimate_Select_Column_Core_Companysitechargeestimate_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Companysitechargeestimate_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Companysitechargeestimate_Aggregate_Bool_Exp_Min = {
  arguments: Core_Companysitechargeestimate_Select_Column_Core_Companysitechargeestimate_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Companysitechargeestimate_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Companysitechargeestimate_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Core_Companysitechargeestimate_Select_Column_Core_Companysitechargeestimate_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Companysitechargeestimate_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Companysitechargeestimate_Aggregate_Bool_Exp_Sum = {
  arguments: Core_Companysitechargeestimate_Select_Column_Core_Companysitechargeestimate_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Companysitechargeestimate_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Companysitechargeestimate_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Core_Companysitechargeestimate_Select_Column_Core_Companysitechargeestimate_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Companysitechargeestimate_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "core_companysitechargeestimate" */
export type Core_Companysitechargeestimate_Aggregate_Fields = {
  __typename?: 'core_companysitechargeestimate_aggregate_fields';
  avg?: Maybe<Core_Companysitechargeestimate_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Companysitechargeestimate_Max_Fields>;
  min?: Maybe<Core_Companysitechargeestimate_Min_Fields>;
  stddev?: Maybe<Core_Companysitechargeestimate_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Companysitechargeestimate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Companysitechargeestimate_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Companysitechargeestimate_Sum_Fields>;
  var_pop?: Maybe<Core_Companysitechargeestimate_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Companysitechargeestimate_Var_Samp_Fields>;
  variance?: Maybe<Core_Companysitechargeestimate_Variance_Fields>;
};


/** aggregate fields of "core_companysitechargeestimate" */
export type Core_Companysitechargeestimate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Companysitechargeestimate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_companysitechargeestimate" */
export type Core_Companysitechargeestimate_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Companysitechargeestimate_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Companysitechargeestimate_Max_Order_By>;
  min?: InputMaybe<Core_Companysitechargeestimate_Min_Order_By>;
  stddev?: InputMaybe<Core_Companysitechargeestimate_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Companysitechargeestimate_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Companysitechargeestimate_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Companysitechargeestimate_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Companysitechargeestimate_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Companysitechargeestimate_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Companysitechargeestimate_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_companysitechargeestimate" */
export type Core_Companysitechargeestimate_Arr_Rel_Insert_Input = {
  data: Array<Core_Companysitechargeestimate_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Companysitechargeestimate_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Companysitechargeestimate_Avg_Fields = {
  __typename?: 'core_companysitechargeestimate_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total_units?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_companysitechargeestimate" */
export type Core_Companysitechargeestimate_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  total_units?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_companysitechargeestimate". All fields are combined with a logical 'AND'. */
export type Core_Companysitechargeestimate_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Companysitechargeestimate_Bool_Exp>>;
  _not?: InputMaybe<Core_Companysitechargeestimate_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Companysitechargeestimate_Bool_Exp>>;
  charge_period_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  charge_period_start?: InputMaybe<Timestamptz_Comparison_Exp>;
  company_id?: InputMaybe<Bigint_Comparison_Exp>;
  core_company?: InputMaybe<Core_Company_Bool_Exp>;
  cost?: InputMaybe<Float8_Comparison_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  total_units?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_companysitechargeestimate" */
export enum Core_Companysitechargeestimate_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreCompanysitechargeestimatePkey = 'core_companysitechargeestimate_pkey'
}

/** input type for incrementing numeric columns in table "core_companysitechargeestimate" */
export type Core_Companysitechargeestimate_Inc_Input = {
  company_id?: InputMaybe<Scalars['bigint']>;
  cost?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['bigint']>;
  total_units?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "core_companysitechargeestimate" */
export type Core_Companysitechargeestimate_Insert_Input = {
  charge_period_end?: InputMaybe<Scalars['timestamptz']>;
  charge_period_start?: InputMaybe<Scalars['timestamptz']>;
  company_id?: InputMaybe<Scalars['bigint']>;
  core_company?: InputMaybe<Core_Company_Obj_Rel_Insert_Input>;
  cost?: InputMaybe<Scalars['float8']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  total_units?: InputMaybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Core_Companysitechargeestimate_Max_Fields = {
  __typename?: 'core_companysitechargeestimate_max_fields';
  charge_period_end?: Maybe<Scalars['timestamptz']>;
  charge_period_start?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['bigint']>;
  cost?: Maybe<Scalars['float8']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  total_units?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "core_companysitechargeestimate" */
export type Core_Companysitechargeestimate_Max_Order_By = {
  charge_period_end?: InputMaybe<Order_By>;
  charge_period_start?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  total_units?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Companysitechargeestimate_Min_Fields = {
  __typename?: 'core_companysitechargeestimate_min_fields';
  charge_period_end?: Maybe<Scalars['timestamptz']>;
  charge_period_start?: Maybe<Scalars['timestamptz']>;
  company_id?: Maybe<Scalars['bigint']>;
  cost?: Maybe<Scalars['float8']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  total_units?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "core_companysitechargeestimate" */
export type Core_Companysitechargeestimate_Min_Order_By = {
  charge_period_end?: InputMaybe<Order_By>;
  charge_period_start?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  total_units?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_companysitechargeestimate" */
export type Core_Companysitechargeestimate_Mutation_Response = {
  __typename?: 'core_companysitechargeestimate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Companysitechargeestimate>;
};

/** on_conflict condition type for table "core_companysitechargeestimate" */
export type Core_Companysitechargeestimate_On_Conflict = {
  constraint: Core_Companysitechargeestimate_Constraint;
  update_columns?: Array<Core_Companysitechargeestimate_Update_Column>;
  where?: InputMaybe<Core_Companysitechargeestimate_Bool_Exp>;
};

/** Ordering options when selecting data from "core_companysitechargeestimate". */
export type Core_Companysitechargeestimate_Order_By = {
  charge_period_end?: InputMaybe<Order_By>;
  charge_period_start?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  core_company?: InputMaybe<Core_Company_Order_By>;
  cost?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  total_units?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_companysitechargeestimate */
export type Core_Companysitechargeestimate_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_companysitechargeestimate" */
export enum Core_Companysitechargeestimate_Select_Column {
  /** column name */
  ChargePeriodEnd = 'charge_period_end',
  /** column name */
  ChargePeriodStart = 'charge_period_start',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  TotalUnits = 'total_units'
}

/** select "core_companysitechargeestimate_aggregate_bool_exp_avg_arguments_columns" columns of table "core_companysitechargeestimate" */
export enum Core_Companysitechargeestimate_Select_Column_Core_Companysitechargeestimate_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Cost = 'cost',
  /** column name */
  TotalUnits = 'total_units'
}

/** select "core_companysitechargeestimate_aggregate_bool_exp_corr_arguments_columns" columns of table "core_companysitechargeestimate" */
export enum Core_Companysitechargeestimate_Select_Column_Core_Companysitechargeestimate_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Cost = 'cost',
  /** column name */
  TotalUnits = 'total_units'
}

/** select "core_companysitechargeestimate_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "core_companysitechargeestimate" */
export enum Core_Companysitechargeestimate_Select_Column_Core_Companysitechargeestimate_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Cost = 'cost',
  /** column name */
  TotalUnits = 'total_units'
}

/** select "core_companysitechargeestimate_aggregate_bool_exp_max_arguments_columns" columns of table "core_companysitechargeestimate" */
export enum Core_Companysitechargeestimate_Select_Column_Core_Companysitechargeestimate_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Cost = 'cost',
  /** column name */
  TotalUnits = 'total_units'
}

/** select "core_companysitechargeestimate_aggregate_bool_exp_min_arguments_columns" columns of table "core_companysitechargeestimate" */
export enum Core_Companysitechargeestimate_Select_Column_Core_Companysitechargeestimate_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Cost = 'cost',
  /** column name */
  TotalUnits = 'total_units'
}

/** select "core_companysitechargeestimate_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "core_companysitechargeestimate" */
export enum Core_Companysitechargeestimate_Select_Column_Core_Companysitechargeestimate_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Cost = 'cost',
  /** column name */
  TotalUnits = 'total_units'
}

/** select "core_companysitechargeestimate_aggregate_bool_exp_sum_arguments_columns" columns of table "core_companysitechargeestimate" */
export enum Core_Companysitechargeestimate_Select_Column_Core_Companysitechargeestimate_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Cost = 'cost',
  /** column name */
  TotalUnits = 'total_units'
}

/** select "core_companysitechargeestimate_aggregate_bool_exp_var_samp_arguments_columns" columns of table "core_companysitechargeestimate" */
export enum Core_Companysitechargeestimate_Select_Column_Core_Companysitechargeestimate_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Cost = 'cost',
  /** column name */
  TotalUnits = 'total_units'
}

/** input type for updating data in table "core_companysitechargeestimate" */
export type Core_Companysitechargeestimate_Set_Input = {
  charge_period_end?: InputMaybe<Scalars['timestamptz']>;
  charge_period_start?: InputMaybe<Scalars['timestamptz']>;
  company_id?: InputMaybe<Scalars['bigint']>;
  cost?: InputMaybe<Scalars['float8']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  total_units?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Core_Companysitechargeestimate_Stddev_Fields = {
  __typename?: 'core_companysitechargeestimate_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total_units?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_companysitechargeestimate" */
export type Core_Companysitechargeestimate_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  total_units?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Companysitechargeestimate_Stddev_Pop_Fields = {
  __typename?: 'core_companysitechargeestimate_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total_units?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_companysitechargeestimate" */
export type Core_Companysitechargeestimate_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  total_units?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Companysitechargeestimate_Stddev_Samp_Fields = {
  __typename?: 'core_companysitechargeestimate_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total_units?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_companysitechargeestimate" */
export type Core_Companysitechargeestimate_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  total_units?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_companysitechargeestimate" */
export type Core_Companysitechargeestimate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Companysitechargeestimate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Companysitechargeestimate_Stream_Cursor_Value_Input = {
  charge_period_end?: InputMaybe<Scalars['timestamptz']>;
  charge_period_start?: InputMaybe<Scalars['timestamptz']>;
  company_id?: InputMaybe<Scalars['bigint']>;
  cost?: InputMaybe<Scalars['float8']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  total_units?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Core_Companysitechargeestimate_Sum_Fields = {
  __typename?: 'core_companysitechargeestimate_sum_fields';
  company_id?: Maybe<Scalars['bigint']>;
  cost?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  total_units?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "core_companysitechargeestimate" */
export type Core_Companysitechargeestimate_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  total_units?: InputMaybe<Order_By>;
};

/** update columns of table "core_companysitechargeestimate" */
export enum Core_Companysitechargeestimate_Update_Column {
  /** column name */
  ChargePeriodEnd = 'charge_period_end',
  /** column name */
  ChargePeriodStart = 'charge_period_start',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  TotalUnits = 'total_units'
}

export type Core_Companysitechargeestimate_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Companysitechargeestimate_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Companysitechargeestimate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Companysitechargeestimate_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Companysitechargeestimate_Var_Pop_Fields = {
  __typename?: 'core_companysitechargeestimate_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total_units?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_companysitechargeestimate" */
export type Core_Companysitechargeestimate_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  total_units?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Companysitechargeestimate_Var_Samp_Fields = {
  __typename?: 'core_companysitechargeestimate_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total_units?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_companysitechargeestimate" */
export type Core_Companysitechargeestimate_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  total_units?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Companysitechargeestimate_Variance_Fields = {
  __typename?: 'core_companysitechargeestimate_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  total_units?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_companysitechargeestimate" */
export type Core_Companysitechargeestimate_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  total_units?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_customertype" */
export type Core_Customertype = {
  __typename?: 'core_customertype';
  /** An array relationship */
  core_tariffs: Array<Core_Tariff>;
  /** An aggregate relationship */
  core_tariffs_aggregate: Core_Tariff_Aggregate;
  customer_type: Scalars['String'];
  id: Scalars['bigint'];
};


/** columns and relationships of "core_customertype" */
export type Core_CustomertypeCore_TariffsArgs = {
  distinct_on?: InputMaybe<Array<Core_Tariff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Tariff_Order_By>>;
  where?: InputMaybe<Core_Tariff_Bool_Exp>;
};


/** columns and relationships of "core_customertype" */
export type Core_CustomertypeCore_Tariffs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Tariff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Tariff_Order_By>>;
  where?: InputMaybe<Core_Tariff_Bool_Exp>;
};

/** aggregated selection of "core_customertype" */
export type Core_Customertype_Aggregate = {
  __typename?: 'core_customertype_aggregate';
  aggregate?: Maybe<Core_Customertype_Aggregate_Fields>;
  nodes: Array<Core_Customertype>;
};

/** aggregate fields of "core_customertype" */
export type Core_Customertype_Aggregate_Fields = {
  __typename?: 'core_customertype_aggregate_fields';
  avg?: Maybe<Core_Customertype_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Customertype_Max_Fields>;
  min?: Maybe<Core_Customertype_Min_Fields>;
  stddev?: Maybe<Core_Customertype_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Customertype_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Customertype_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Customertype_Sum_Fields>;
  var_pop?: Maybe<Core_Customertype_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Customertype_Var_Samp_Fields>;
  variance?: Maybe<Core_Customertype_Variance_Fields>;
};


/** aggregate fields of "core_customertype" */
export type Core_Customertype_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Customertype_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Core_Customertype_Avg_Fields = {
  __typename?: 'core_customertype_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "core_customertype". All fields are combined with a logical 'AND'. */
export type Core_Customertype_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Customertype_Bool_Exp>>;
  _not?: InputMaybe<Core_Customertype_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Customertype_Bool_Exp>>;
  core_tariffs?: InputMaybe<Core_Tariff_Bool_Exp>;
  core_tariffs_aggregate?: InputMaybe<Core_Tariff_Aggregate_Bool_Exp>;
  customer_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_customertype" */
export enum Core_Customertype_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreCustomertypePkey = 'core_customertype_pkey'
}

/** input type for incrementing numeric columns in table "core_customertype" */
export type Core_Customertype_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_customertype" */
export type Core_Customertype_Insert_Input = {
  core_tariffs?: InputMaybe<Core_Tariff_Arr_Rel_Insert_Input>;
  customer_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_Customertype_Max_Fields = {
  __typename?: 'core_customertype_max_fields';
  customer_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Core_Customertype_Min_Fields = {
  __typename?: 'core_customertype_min_fields';
  customer_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "core_customertype" */
export type Core_Customertype_Mutation_Response = {
  __typename?: 'core_customertype_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Customertype>;
};

/** input type for inserting object relation for remote table "core_customertype" */
export type Core_Customertype_Obj_Rel_Insert_Input = {
  data: Core_Customertype_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Customertype_On_Conflict>;
};

/** on_conflict condition type for table "core_customertype" */
export type Core_Customertype_On_Conflict = {
  constraint: Core_Customertype_Constraint;
  update_columns?: Array<Core_Customertype_Update_Column>;
  where?: InputMaybe<Core_Customertype_Bool_Exp>;
};

/** Ordering options when selecting data from "core_customertype". */
export type Core_Customertype_Order_By = {
  core_tariffs_aggregate?: InputMaybe<Core_Tariff_Aggregate_Order_By>;
  customer_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_customertype */
export type Core_Customertype_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_customertype" */
export enum Core_Customertype_Select_Column {
  /** column name */
  CustomerType = 'customer_type',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "core_customertype" */
export type Core_Customertype_Set_Input = {
  customer_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_Customertype_Stddev_Fields = {
  __typename?: 'core_customertype_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Core_Customertype_Stddev_Pop_Fields = {
  __typename?: 'core_customertype_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Core_Customertype_Stddev_Samp_Fields = {
  __typename?: 'core_customertype_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "core_customertype" */
export type Core_Customertype_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Customertype_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Customertype_Stream_Cursor_Value_Input = {
  customer_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_Customertype_Sum_Fields = {
  __typename?: 'core_customertype_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "core_customertype" */
export enum Core_Customertype_Update_Column {
  /** column name */
  CustomerType = 'customer_type',
  /** column name */
  Id = 'id'
}

export type Core_Customertype_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Customertype_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Customertype_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Customertype_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Customertype_Var_Pop_Fields = {
  __typename?: 'core_customertype_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Core_Customertype_Var_Samp_Fields = {
  __typename?: 'core_customertype_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Core_Customertype_Variance_Fields = {
  __typename?: 'core_customertype_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "core_disruptions" */
export type Core_Disruptions = {
  __typename?: 'core_disruptions';
  /** An object relationship */
  core_disruptionstype: Core_Disruptionstype;
  /** An object relationship */
  core_site: Core_Site;
  /** An object relationship */
  core_user: Core_User;
  description: Scalars['String'];
  disruption_type_id: Scalars['bigint'];
  end_date: Scalars['timestamptz'];
  id: Scalars['bigint'];
  reported_by_id: Scalars['bigint'];
  root_cause: Scalars['String'];
  site_id: Scalars['bigint'];
  start_date: Scalars['timestamptz'];
};

/** aggregated selection of "core_disruptions" */
export type Core_Disruptions_Aggregate = {
  __typename?: 'core_disruptions_aggregate';
  aggregate?: Maybe<Core_Disruptions_Aggregate_Fields>;
  nodes: Array<Core_Disruptions>;
};

export type Core_Disruptions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Disruptions_Aggregate_Bool_Exp_Count>;
};

export type Core_Disruptions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Disruptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Disruptions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_disruptions" */
export type Core_Disruptions_Aggregate_Fields = {
  __typename?: 'core_disruptions_aggregate_fields';
  avg?: Maybe<Core_Disruptions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Disruptions_Max_Fields>;
  min?: Maybe<Core_Disruptions_Min_Fields>;
  stddev?: Maybe<Core_Disruptions_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Disruptions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Disruptions_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Disruptions_Sum_Fields>;
  var_pop?: Maybe<Core_Disruptions_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Disruptions_Var_Samp_Fields>;
  variance?: Maybe<Core_Disruptions_Variance_Fields>;
};


/** aggregate fields of "core_disruptions" */
export type Core_Disruptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Disruptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_disruptions" */
export type Core_Disruptions_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Disruptions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Disruptions_Max_Order_By>;
  min?: InputMaybe<Core_Disruptions_Min_Order_By>;
  stddev?: InputMaybe<Core_Disruptions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Disruptions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Disruptions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Disruptions_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Disruptions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Disruptions_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Disruptions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_disruptions" */
export type Core_Disruptions_Arr_Rel_Insert_Input = {
  data: Array<Core_Disruptions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Disruptions_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Disruptions_Avg_Fields = {
  __typename?: 'core_disruptions_avg_fields';
  disruption_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  reported_by_id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_disruptions" */
export type Core_Disruptions_Avg_Order_By = {
  disruption_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reported_by_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_disruptions". All fields are combined with a logical 'AND'. */
export type Core_Disruptions_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Disruptions_Bool_Exp>>;
  _not?: InputMaybe<Core_Disruptions_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Disruptions_Bool_Exp>>;
  core_disruptionstype?: InputMaybe<Core_Disruptionstype_Bool_Exp>;
  core_site?: InputMaybe<Core_Site_Bool_Exp>;
  core_user?: InputMaybe<Core_User_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  disruption_type_id?: InputMaybe<Bigint_Comparison_Exp>;
  end_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  reported_by_id?: InputMaybe<Bigint_Comparison_Exp>;
  root_cause?: InputMaybe<String_Comparison_Exp>;
  site_id?: InputMaybe<Bigint_Comparison_Exp>;
  start_date?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_disruptions" */
export enum Core_Disruptions_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreDisruptionsPkey = 'core_disruptions_pkey'
}

/** input type for incrementing numeric columns in table "core_disruptions" */
export type Core_Disruptions_Inc_Input = {
  disruption_type_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  reported_by_id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_disruptions" */
export type Core_Disruptions_Insert_Input = {
  core_disruptionstype?: InputMaybe<Core_Disruptionstype_Obj_Rel_Insert_Input>;
  core_site?: InputMaybe<Core_Site_Obj_Rel_Insert_Input>;
  core_user?: InputMaybe<Core_User_Obj_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']>;
  disruption_type_id?: InputMaybe<Scalars['bigint']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  reported_by_id?: InputMaybe<Scalars['bigint']>;
  root_cause?: InputMaybe<Scalars['String']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Core_Disruptions_Max_Fields = {
  __typename?: 'core_disruptions_max_fields';
  description?: Maybe<Scalars['String']>;
  disruption_type_id?: Maybe<Scalars['bigint']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  reported_by_id?: Maybe<Scalars['bigint']>;
  root_cause?: Maybe<Scalars['String']>;
  site_id?: Maybe<Scalars['bigint']>;
  start_date?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "core_disruptions" */
export type Core_Disruptions_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  disruption_type_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reported_by_id?: InputMaybe<Order_By>;
  root_cause?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Disruptions_Min_Fields = {
  __typename?: 'core_disruptions_min_fields';
  description?: Maybe<Scalars['String']>;
  disruption_type_id?: Maybe<Scalars['bigint']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  reported_by_id?: Maybe<Scalars['bigint']>;
  root_cause?: Maybe<Scalars['String']>;
  site_id?: Maybe<Scalars['bigint']>;
  start_date?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "core_disruptions" */
export type Core_Disruptions_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  disruption_type_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reported_by_id?: InputMaybe<Order_By>;
  root_cause?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_disruptions" */
export type Core_Disruptions_Mutation_Response = {
  __typename?: 'core_disruptions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Disruptions>;
};

/** on_conflict condition type for table "core_disruptions" */
export type Core_Disruptions_On_Conflict = {
  constraint: Core_Disruptions_Constraint;
  update_columns?: Array<Core_Disruptions_Update_Column>;
  where?: InputMaybe<Core_Disruptions_Bool_Exp>;
};

/** Ordering options when selecting data from "core_disruptions". */
export type Core_Disruptions_Order_By = {
  core_disruptionstype?: InputMaybe<Core_Disruptionstype_Order_By>;
  core_site?: InputMaybe<Core_Site_Order_By>;
  core_user?: InputMaybe<Core_User_Order_By>;
  description?: InputMaybe<Order_By>;
  disruption_type_id?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reported_by_id?: InputMaybe<Order_By>;
  root_cause?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_disruptions */
export type Core_Disruptions_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_disruptions" */
export enum Core_Disruptions_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  DisruptionTypeId = 'disruption_type_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  ReportedById = 'reported_by_id',
  /** column name */
  RootCause = 'root_cause',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  StartDate = 'start_date'
}

/** input type for updating data in table "core_disruptions" */
export type Core_Disruptions_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  disruption_type_id?: InputMaybe<Scalars['bigint']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  reported_by_id?: InputMaybe<Scalars['bigint']>;
  root_cause?: InputMaybe<Scalars['String']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Core_Disruptions_Stddev_Fields = {
  __typename?: 'core_disruptions_stddev_fields';
  disruption_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  reported_by_id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_disruptions" */
export type Core_Disruptions_Stddev_Order_By = {
  disruption_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reported_by_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Disruptions_Stddev_Pop_Fields = {
  __typename?: 'core_disruptions_stddev_pop_fields';
  disruption_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  reported_by_id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_disruptions" */
export type Core_Disruptions_Stddev_Pop_Order_By = {
  disruption_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reported_by_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Disruptions_Stddev_Samp_Fields = {
  __typename?: 'core_disruptions_stddev_samp_fields';
  disruption_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  reported_by_id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_disruptions" */
export type Core_Disruptions_Stddev_Samp_Order_By = {
  disruption_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reported_by_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_disruptions" */
export type Core_Disruptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Disruptions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Disruptions_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  disruption_type_id?: InputMaybe<Scalars['bigint']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  reported_by_id?: InputMaybe<Scalars['bigint']>;
  root_cause?: InputMaybe<Scalars['String']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Core_Disruptions_Sum_Fields = {
  __typename?: 'core_disruptions_sum_fields';
  disruption_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  reported_by_id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_disruptions" */
export type Core_Disruptions_Sum_Order_By = {
  disruption_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reported_by_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_disruptions" */
export enum Core_Disruptions_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  DisruptionTypeId = 'disruption_type_id',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  ReportedById = 'reported_by_id',
  /** column name */
  RootCause = 'root_cause',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  StartDate = 'start_date'
}

export type Core_Disruptions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Disruptions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Disruptions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Disruptions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Disruptions_Var_Pop_Fields = {
  __typename?: 'core_disruptions_var_pop_fields';
  disruption_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  reported_by_id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_disruptions" */
export type Core_Disruptions_Var_Pop_Order_By = {
  disruption_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reported_by_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Disruptions_Var_Samp_Fields = {
  __typename?: 'core_disruptions_var_samp_fields';
  disruption_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  reported_by_id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_disruptions" */
export type Core_Disruptions_Var_Samp_Order_By = {
  disruption_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reported_by_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Disruptions_Variance_Fields = {
  __typename?: 'core_disruptions_variance_fields';
  disruption_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  reported_by_id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_disruptions" */
export type Core_Disruptions_Variance_Order_By = {
  disruption_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reported_by_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_disruptionstype" */
export type Core_Disruptionstype = {
  __typename?: 'core_disruptionstype';
  /** An array relationship */
  core_disruptions: Array<Core_Disruptions>;
  /** An aggregate relationship */
  core_disruptions_aggregate: Core_Disruptions_Aggregate;
  id: Scalars['bigint'];
  name: Scalars['String'];
};


/** columns and relationships of "core_disruptionstype" */
export type Core_DisruptionstypeCore_DisruptionsArgs = {
  distinct_on?: InputMaybe<Array<Core_Disruptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Disruptions_Order_By>>;
  where?: InputMaybe<Core_Disruptions_Bool_Exp>;
};


/** columns and relationships of "core_disruptionstype" */
export type Core_DisruptionstypeCore_Disruptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Disruptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Disruptions_Order_By>>;
  where?: InputMaybe<Core_Disruptions_Bool_Exp>;
};

/** aggregated selection of "core_disruptionstype" */
export type Core_Disruptionstype_Aggregate = {
  __typename?: 'core_disruptionstype_aggregate';
  aggregate?: Maybe<Core_Disruptionstype_Aggregate_Fields>;
  nodes: Array<Core_Disruptionstype>;
};

/** aggregate fields of "core_disruptionstype" */
export type Core_Disruptionstype_Aggregate_Fields = {
  __typename?: 'core_disruptionstype_aggregate_fields';
  avg?: Maybe<Core_Disruptionstype_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Disruptionstype_Max_Fields>;
  min?: Maybe<Core_Disruptionstype_Min_Fields>;
  stddev?: Maybe<Core_Disruptionstype_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Disruptionstype_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Disruptionstype_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Disruptionstype_Sum_Fields>;
  var_pop?: Maybe<Core_Disruptionstype_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Disruptionstype_Var_Samp_Fields>;
  variance?: Maybe<Core_Disruptionstype_Variance_Fields>;
};


/** aggregate fields of "core_disruptionstype" */
export type Core_Disruptionstype_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Disruptionstype_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Core_Disruptionstype_Avg_Fields = {
  __typename?: 'core_disruptionstype_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "core_disruptionstype". All fields are combined with a logical 'AND'. */
export type Core_Disruptionstype_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Disruptionstype_Bool_Exp>>;
  _not?: InputMaybe<Core_Disruptionstype_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Disruptionstype_Bool_Exp>>;
  core_disruptions?: InputMaybe<Core_Disruptions_Bool_Exp>;
  core_disruptions_aggregate?: InputMaybe<Core_Disruptions_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_disruptionstype" */
export enum Core_Disruptionstype_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreDisruptionstypePkey = 'core_disruptionstype_pkey'
}

/** input type for incrementing numeric columns in table "core_disruptionstype" */
export type Core_Disruptionstype_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_disruptionstype" */
export type Core_Disruptionstype_Insert_Input = {
  core_disruptions?: InputMaybe<Core_Disruptions_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Disruptionstype_Max_Fields = {
  __typename?: 'core_disruptionstype_max_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Core_Disruptionstype_Min_Fields = {
  __typename?: 'core_disruptionstype_min_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "core_disruptionstype" */
export type Core_Disruptionstype_Mutation_Response = {
  __typename?: 'core_disruptionstype_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Disruptionstype>;
};

/** input type for inserting object relation for remote table "core_disruptionstype" */
export type Core_Disruptionstype_Obj_Rel_Insert_Input = {
  data: Core_Disruptionstype_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Disruptionstype_On_Conflict>;
};

/** on_conflict condition type for table "core_disruptionstype" */
export type Core_Disruptionstype_On_Conflict = {
  constraint: Core_Disruptionstype_Constraint;
  update_columns?: Array<Core_Disruptionstype_Update_Column>;
  where?: InputMaybe<Core_Disruptionstype_Bool_Exp>;
};

/** Ordering options when selecting data from "core_disruptionstype". */
export type Core_Disruptionstype_Order_By = {
  core_disruptions_aggregate?: InputMaybe<Core_Disruptions_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_disruptionstype */
export type Core_Disruptionstype_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_disruptionstype" */
export enum Core_Disruptionstype_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "core_disruptionstype" */
export type Core_Disruptionstype_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Disruptionstype_Stddev_Fields = {
  __typename?: 'core_disruptionstype_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Core_Disruptionstype_Stddev_Pop_Fields = {
  __typename?: 'core_disruptionstype_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Core_Disruptionstype_Stddev_Samp_Fields = {
  __typename?: 'core_disruptionstype_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "core_disruptionstype" */
export type Core_Disruptionstype_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Disruptionstype_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Disruptionstype_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Disruptionstype_Sum_Fields = {
  __typename?: 'core_disruptionstype_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "core_disruptionstype" */
export enum Core_Disruptionstype_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Core_Disruptionstype_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Disruptionstype_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Disruptionstype_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Disruptionstype_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Disruptionstype_Var_Pop_Fields = {
  __typename?: 'core_disruptionstype_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Core_Disruptionstype_Var_Samp_Fields = {
  __typename?: 'core_disruptionstype_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Core_Disruptionstype_Variance_Fields = {
  __typename?: 'core_disruptionstype_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "core_energycharge" */
export type Core_Energycharge = {
  __typename?: 'core_energycharge';
  block_max: Scalars['float8'];
  block_min: Scalars['float8'];
  charge: Scalars['float8'];
  /** An object relationship */
  core_tariff: Core_Tariff;
  effective_date?: Maybe<Scalars['date']>;
  end_date?: Maybe<Scalars['date']>;
  id: Scalars['bigint'];
  tariff_id: Scalars['bigint'];
};

/** aggregated selection of "core_energycharge" */
export type Core_Energycharge_Aggregate = {
  __typename?: 'core_energycharge_aggregate';
  aggregate?: Maybe<Core_Energycharge_Aggregate_Fields>;
  nodes: Array<Core_Energycharge>;
};

export type Core_Energycharge_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Core_Energycharge_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Core_Energycharge_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Core_Energycharge_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Core_Energycharge_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Core_Energycharge_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Core_Energycharge_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Core_Energycharge_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Core_Energycharge_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Core_Energycharge_Aggregate_Bool_Exp_Var_Samp>;
};

export type Core_Energycharge_Aggregate_Bool_Exp_Avg = {
  arguments: Core_Energycharge_Select_Column_Core_Energycharge_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Energycharge_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Energycharge_Aggregate_Bool_Exp_Corr = {
  arguments: Core_Energycharge_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Energycharge_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Energycharge_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Core_Energycharge_Select_Column_Core_Energycharge_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Core_Energycharge_Select_Column_Core_Energycharge_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Core_Energycharge_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Energycharge_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Energycharge_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Core_Energycharge_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Core_Energycharge_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Energycharge_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Energycharge_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Core_Energycharge_Select_Column_Core_Energycharge_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Core_Energycharge_Select_Column_Core_Energycharge_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Core_Energycharge_Aggregate_Bool_Exp_Max = {
  arguments: Core_Energycharge_Select_Column_Core_Energycharge_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Energycharge_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Energycharge_Aggregate_Bool_Exp_Min = {
  arguments: Core_Energycharge_Select_Column_Core_Energycharge_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Energycharge_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Energycharge_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Core_Energycharge_Select_Column_Core_Energycharge_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Energycharge_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Energycharge_Aggregate_Bool_Exp_Sum = {
  arguments: Core_Energycharge_Select_Column_Core_Energycharge_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Energycharge_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Energycharge_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Core_Energycharge_Select_Column_Core_Energycharge_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Energycharge_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "core_energycharge" */
export type Core_Energycharge_Aggregate_Fields = {
  __typename?: 'core_energycharge_aggregate_fields';
  avg?: Maybe<Core_Energycharge_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Energycharge_Max_Fields>;
  min?: Maybe<Core_Energycharge_Min_Fields>;
  stddev?: Maybe<Core_Energycharge_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Energycharge_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Energycharge_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Energycharge_Sum_Fields>;
  var_pop?: Maybe<Core_Energycharge_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Energycharge_Var_Samp_Fields>;
  variance?: Maybe<Core_Energycharge_Variance_Fields>;
};


/** aggregate fields of "core_energycharge" */
export type Core_Energycharge_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Energycharge_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_energycharge" */
export type Core_Energycharge_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Energycharge_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Energycharge_Max_Order_By>;
  min?: InputMaybe<Core_Energycharge_Min_Order_By>;
  stddev?: InputMaybe<Core_Energycharge_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Energycharge_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Energycharge_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Energycharge_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Energycharge_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Energycharge_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Energycharge_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_energycharge" */
export type Core_Energycharge_Arr_Rel_Insert_Input = {
  data: Array<Core_Energycharge_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Energycharge_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Energycharge_Avg_Fields = {
  __typename?: 'core_energycharge_avg_fields';
  block_max?: Maybe<Scalars['Float']>;
  block_min?: Maybe<Scalars['Float']>;
  charge?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_energycharge" */
export type Core_Energycharge_Avg_Order_By = {
  block_max?: InputMaybe<Order_By>;
  block_min?: InputMaybe<Order_By>;
  charge?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_energycharge". All fields are combined with a logical 'AND'. */
export type Core_Energycharge_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Energycharge_Bool_Exp>>;
  _not?: InputMaybe<Core_Energycharge_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Energycharge_Bool_Exp>>;
  block_max?: InputMaybe<Float8_Comparison_Exp>;
  block_min?: InputMaybe<Float8_Comparison_Exp>;
  charge?: InputMaybe<Float8_Comparison_Exp>;
  core_tariff?: InputMaybe<Core_Tariff_Bool_Exp>;
  effective_date?: InputMaybe<Date_Comparison_Exp>;
  end_date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  tariff_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_energycharge" */
export enum Core_Energycharge_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreEnergychargePkey = 'core_energycharge_pkey'
}

/** input type for incrementing numeric columns in table "core_energycharge" */
export type Core_Energycharge_Inc_Input = {
  block_max?: InputMaybe<Scalars['float8']>;
  block_min?: InputMaybe<Scalars['float8']>;
  charge?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['bigint']>;
  tariff_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_energycharge" */
export type Core_Energycharge_Insert_Input = {
  block_max?: InputMaybe<Scalars['float8']>;
  block_min?: InputMaybe<Scalars['float8']>;
  charge?: InputMaybe<Scalars['float8']>;
  core_tariff?: InputMaybe<Core_Tariff_Obj_Rel_Insert_Input>;
  effective_date?: InputMaybe<Scalars['date']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  tariff_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_Energycharge_Max_Fields = {
  __typename?: 'core_energycharge_max_fields';
  block_max?: Maybe<Scalars['float8']>;
  block_min?: Maybe<Scalars['float8']>;
  charge?: Maybe<Scalars['float8']>;
  effective_date?: Maybe<Scalars['date']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  tariff_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "core_energycharge" */
export type Core_Energycharge_Max_Order_By = {
  block_max?: InputMaybe<Order_By>;
  block_min?: InputMaybe<Order_By>;
  charge?: InputMaybe<Order_By>;
  effective_date?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Energycharge_Min_Fields = {
  __typename?: 'core_energycharge_min_fields';
  block_max?: Maybe<Scalars['float8']>;
  block_min?: Maybe<Scalars['float8']>;
  charge?: Maybe<Scalars['float8']>;
  effective_date?: Maybe<Scalars['date']>;
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  tariff_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "core_energycharge" */
export type Core_Energycharge_Min_Order_By = {
  block_max?: InputMaybe<Order_By>;
  block_min?: InputMaybe<Order_By>;
  charge?: InputMaybe<Order_By>;
  effective_date?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_energycharge" */
export type Core_Energycharge_Mutation_Response = {
  __typename?: 'core_energycharge_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Energycharge>;
};

/** on_conflict condition type for table "core_energycharge" */
export type Core_Energycharge_On_Conflict = {
  constraint: Core_Energycharge_Constraint;
  update_columns?: Array<Core_Energycharge_Update_Column>;
  where?: InputMaybe<Core_Energycharge_Bool_Exp>;
};

/** Ordering options when selecting data from "core_energycharge". */
export type Core_Energycharge_Order_By = {
  block_max?: InputMaybe<Order_By>;
  block_min?: InputMaybe<Order_By>;
  charge?: InputMaybe<Order_By>;
  core_tariff?: InputMaybe<Core_Tariff_Order_By>;
  effective_date?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_energycharge */
export type Core_Energycharge_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_energycharge" */
export enum Core_Energycharge_Select_Column {
  /** column name */
  BlockMax = 'block_max',
  /** column name */
  BlockMin = 'block_min',
  /** column name */
  Charge = 'charge',
  /** column name */
  EffectiveDate = 'effective_date',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  TariffId = 'tariff_id'
}

/** select "core_energycharge_aggregate_bool_exp_avg_arguments_columns" columns of table "core_energycharge" */
export enum Core_Energycharge_Select_Column_Core_Energycharge_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  BlockMax = 'block_max',
  /** column name */
  BlockMin = 'block_min',
  /** column name */
  Charge = 'charge'
}

/** select "core_energycharge_aggregate_bool_exp_corr_arguments_columns" columns of table "core_energycharge" */
export enum Core_Energycharge_Select_Column_Core_Energycharge_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  BlockMax = 'block_max',
  /** column name */
  BlockMin = 'block_min',
  /** column name */
  Charge = 'charge'
}

/** select "core_energycharge_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "core_energycharge" */
export enum Core_Energycharge_Select_Column_Core_Energycharge_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  BlockMax = 'block_max',
  /** column name */
  BlockMin = 'block_min',
  /** column name */
  Charge = 'charge'
}

/** select "core_energycharge_aggregate_bool_exp_max_arguments_columns" columns of table "core_energycharge" */
export enum Core_Energycharge_Select_Column_Core_Energycharge_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  BlockMax = 'block_max',
  /** column name */
  BlockMin = 'block_min',
  /** column name */
  Charge = 'charge'
}

/** select "core_energycharge_aggregate_bool_exp_min_arguments_columns" columns of table "core_energycharge" */
export enum Core_Energycharge_Select_Column_Core_Energycharge_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  BlockMax = 'block_max',
  /** column name */
  BlockMin = 'block_min',
  /** column name */
  Charge = 'charge'
}

/** select "core_energycharge_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "core_energycharge" */
export enum Core_Energycharge_Select_Column_Core_Energycharge_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  BlockMax = 'block_max',
  /** column name */
  BlockMin = 'block_min',
  /** column name */
  Charge = 'charge'
}

/** select "core_energycharge_aggregate_bool_exp_sum_arguments_columns" columns of table "core_energycharge" */
export enum Core_Energycharge_Select_Column_Core_Energycharge_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  BlockMax = 'block_max',
  /** column name */
  BlockMin = 'block_min',
  /** column name */
  Charge = 'charge'
}

/** select "core_energycharge_aggregate_bool_exp_var_samp_arguments_columns" columns of table "core_energycharge" */
export enum Core_Energycharge_Select_Column_Core_Energycharge_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  BlockMax = 'block_max',
  /** column name */
  BlockMin = 'block_min',
  /** column name */
  Charge = 'charge'
}

/** input type for updating data in table "core_energycharge" */
export type Core_Energycharge_Set_Input = {
  block_max?: InputMaybe<Scalars['float8']>;
  block_min?: InputMaybe<Scalars['float8']>;
  charge?: InputMaybe<Scalars['float8']>;
  effective_date?: InputMaybe<Scalars['date']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  tariff_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_Energycharge_Stddev_Fields = {
  __typename?: 'core_energycharge_stddev_fields';
  block_max?: Maybe<Scalars['Float']>;
  block_min?: Maybe<Scalars['Float']>;
  charge?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_energycharge" */
export type Core_Energycharge_Stddev_Order_By = {
  block_max?: InputMaybe<Order_By>;
  block_min?: InputMaybe<Order_By>;
  charge?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Energycharge_Stddev_Pop_Fields = {
  __typename?: 'core_energycharge_stddev_pop_fields';
  block_max?: Maybe<Scalars['Float']>;
  block_min?: Maybe<Scalars['Float']>;
  charge?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_energycharge" */
export type Core_Energycharge_Stddev_Pop_Order_By = {
  block_max?: InputMaybe<Order_By>;
  block_min?: InputMaybe<Order_By>;
  charge?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Energycharge_Stddev_Samp_Fields = {
  __typename?: 'core_energycharge_stddev_samp_fields';
  block_max?: Maybe<Scalars['Float']>;
  block_min?: Maybe<Scalars['Float']>;
  charge?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_energycharge" */
export type Core_Energycharge_Stddev_Samp_Order_By = {
  block_max?: InputMaybe<Order_By>;
  block_min?: InputMaybe<Order_By>;
  charge?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_energycharge" */
export type Core_Energycharge_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Energycharge_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Energycharge_Stream_Cursor_Value_Input = {
  block_max?: InputMaybe<Scalars['float8']>;
  block_min?: InputMaybe<Scalars['float8']>;
  charge?: InputMaybe<Scalars['float8']>;
  effective_date?: InputMaybe<Scalars['date']>;
  end_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  tariff_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_Energycharge_Sum_Fields = {
  __typename?: 'core_energycharge_sum_fields';
  block_max?: Maybe<Scalars['float8']>;
  block_min?: Maybe<Scalars['float8']>;
  charge?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  tariff_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_energycharge" */
export type Core_Energycharge_Sum_Order_By = {
  block_max?: InputMaybe<Order_By>;
  block_min?: InputMaybe<Order_By>;
  charge?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_energycharge" */
export enum Core_Energycharge_Update_Column {
  /** column name */
  BlockMax = 'block_max',
  /** column name */
  BlockMin = 'block_min',
  /** column name */
  Charge = 'charge',
  /** column name */
  EffectiveDate = 'effective_date',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  TariffId = 'tariff_id'
}

export type Core_Energycharge_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Energycharge_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Energycharge_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Energycharge_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Energycharge_Var_Pop_Fields = {
  __typename?: 'core_energycharge_var_pop_fields';
  block_max?: Maybe<Scalars['Float']>;
  block_min?: Maybe<Scalars['Float']>;
  charge?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_energycharge" */
export type Core_Energycharge_Var_Pop_Order_By = {
  block_max?: InputMaybe<Order_By>;
  block_min?: InputMaybe<Order_By>;
  charge?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Energycharge_Var_Samp_Fields = {
  __typename?: 'core_energycharge_var_samp_fields';
  block_max?: Maybe<Scalars['Float']>;
  block_min?: Maybe<Scalars['Float']>;
  charge?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_energycharge" */
export type Core_Energycharge_Var_Samp_Order_By = {
  block_max?: InputMaybe<Order_By>;
  block_min?: InputMaybe<Order_By>;
  charge?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Energycharge_Variance_Fields = {
  __typename?: 'core_energycharge_variance_fields';
  block_max?: Maybe<Scalars['Float']>;
  block_min?: Maybe<Scalars['Float']>;
  charge?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_energycharge" */
export type Core_Energycharge_Variance_Order_By = {
  block_max?: InputMaybe<Order_By>;
  block_min?: InputMaybe<Order_By>;
  charge?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_equipment" */
export type Core_Equipment = {
  __typename?: 'core_equipment';
  condition_id: Scalars['bigint'];
  /** An array relationship */
  core_boxes: Array<Core_Box>;
  /** An aggregate relationship */
  core_boxes_aggregate: Core_Box_Aggregate;
  /** An object relationship */
  core_equipmentcondition: Core_Equipmentcondition;
  /** An array relationship */
  core_equipmentimages: Array<Core_Equipmentimage>;
  /** An aggregate relationship */
  core_equipmentimages_aggregate: Core_Equipmentimage_Aggregate;
  /** An object relationship */
  core_equipmentmodel: Core_Equipmentmodel;
  /** An object relationship */
  core_equipmentpart: Core_Equipmentpart;
  /** An object relationship */
  core_equipmentstatus: Core_Equipmentstatus;
  /** An object relationship */
  core_equipmenttype: Core_Equipmenttype;
  /** An object relationship */
  core_site: Core_Site;
  equipment_name?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  model_id: Scalars['bigint'];
  part_id: Scalars['bigint'];
  serial_number: Scalars['String'];
  site_id: Scalars['bigint'];
  status_id: Scalars['bigint'];
  type_id: Scalars['bigint'];
};


/** columns and relationships of "core_equipment" */
export type Core_EquipmentCore_BoxesArgs = {
  distinct_on?: InputMaybe<Array<Core_Box_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Box_Order_By>>;
  where?: InputMaybe<Core_Box_Bool_Exp>;
};


/** columns and relationships of "core_equipment" */
export type Core_EquipmentCore_Boxes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Box_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Box_Order_By>>;
  where?: InputMaybe<Core_Box_Bool_Exp>;
};


/** columns and relationships of "core_equipment" */
export type Core_EquipmentCore_EquipmentimagesArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentimage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentimage_Order_By>>;
  where?: InputMaybe<Core_Equipmentimage_Bool_Exp>;
};


/** columns and relationships of "core_equipment" */
export type Core_EquipmentCore_Equipmentimages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentimage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentimage_Order_By>>;
  where?: InputMaybe<Core_Equipmentimage_Bool_Exp>;
};

/** aggregated selection of "core_equipment" */
export type Core_Equipment_Aggregate = {
  __typename?: 'core_equipment_aggregate';
  aggregate?: Maybe<Core_Equipment_Aggregate_Fields>;
  nodes: Array<Core_Equipment>;
};

export type Core_Equipment_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Equipment_Aggregate_Bool_Exp_Count>;
};

export type Core_Equipment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Equipment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Equipment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_equipment" */
export type Core_Equipment_Aggregate_Fields = {
  __typename?: 'core_equipment_aggregate_fields';
  avg?: Maybe<Core_Equipment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Equipment_Max_Fields>;
  min?: Maybe<Core_Equipment_Min_Fields>;
  stddev?: Maybe<Core_Equipment_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Equipment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Equipment_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Equipment_Sum_Fields>;
  var_pop?: Maybe<Core_Equipment_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Equipment_Var_Samp_Fields>;
  variance?: Maybe<Core_Equipment_Variance_Fields>;
};


/** aggregate fields of "core_equipment" */
export type Core_Equipment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Equipment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_equipment" */
export type Core_Equipment_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Equipment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Equipment_Max_Order_By>;
  min?: InputMaybe<Core_Equipment_Min_Order_By>;
  stddev?: InputMaybe<Core_Equipment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Equipment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Equipment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Equipment_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Equipment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Equipment_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Equipment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_equipment" */
export type Core_Equipment_Arr_Rel_Insert_Input = {
  data: Array<Core_Equipment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Equipment_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Equipment_Avg_Fields = {
  __typename?: 'core_equipment_avg_fields';
  condition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  model_id?: Maybe<Scalars['Float']>;
  part_id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_equipment" */
export type Core_Equipment_Avg_Order_By = {
  condition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
  part_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_equipment". All fields are combined with a logical 'AND'. */
export type Core_Equipment_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Equipment_Bool_Exp>>;
  _not?: InputMaybe<Core_Equipment_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Equipment_Bool_Exp>>;
  condition_id?: InputMaybe<Bigint_Comparison_Exp>;
  core_boxes?: InputMaybe<Core_Box_Bool_Exp>;
  core_boxes_aggregate?: InputMaybe<Core_Box_Aggregate_Bool_Exp>;
  core_equipmentcondition?: InputMaybe<Core_Equipmentcondition_Bool_Exp>;
  core_equipmentimages?: InputMaybe<Core_Equipmentimage_Bool_Exp>;
  core_equipmentimages_aggregate?: InputMaybe<Core_Equipmentimage_Aggregate_Bool_Exp>;
  core_equipmentmodel?: InputMaybe<Core_Equipmentmodel_Bool_Exp>;
  core_equipmentpart?: InputMaybe<Core_Equipmentpart_Bool_Exp>;
  core_equipmentstatus?: InputMaybe<Core_Equipmentstatus_Bool_Exp>;
  core_equipmenttype?: InputMaybe<Core_Equipmenttype_Bool_Exp>;
  core_site?: InputMaybe<Core_Site_Bool_Exp>;
  equipment_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  model_id?: InputMaybe<Bigint_Comparison_Exp>;
  part_id?: InputMaybe<Bigint_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
  site_id?: InputMaybe<Bigint_Comparison_Exp>;
  status_id?: InputMaybe<Bigint_Comparison_Exp>;
  type_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_equipment" */
export enum Core_Equipment_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreEquipmentPkey = 'core_equipment_pkey'
}

/** input type for incrementing numeric columns in table "core_equipment" */
export type Core_Equipment_Inc_Input = {
  condition_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  model_id?: InputMaybe<Scalars['bigint']>;
  part_id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  status_id?: InputMaybe<Scalars['bigint']>;
  type_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_equipment" */
export type Core_Equipment_Insert_Input = {
  condition_id?: InputMaybe<Scalars['bigint']>;
  core_boxes?: InputMaybe<Core_Box_Arr_Rel_Insert_Input>;
  core_equipmentcondition?: InputMaybe<Core_Equipmentcondition_Obj_Rel_Insert_Input>;
  core_equipmentimages?: InputMaybe<Core_Equipmentimage_Arr_Rel_Insert_Input>;
  core_equipmentmodel?: InputMaybe<Core_Equipmentmodel_Obj_Rel_Insert_Input>;
  core_equipmentpart?: InputMaybe<Core_Equipmentpart_Obj_Rel_Insert_Input>;
  core_equipmentstatus?: InputMaybe<Core_Equipmentstatus_Obj_Rel_Insert_Input>;
  core_equipmenttype?: InputMaybe<Core_Equipmenttype_Obj_Rel_Insert_Input>;
  core_site?: InputMaybe<Core_Site_Obj_Rel_Insert_Input>;
  equipment_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  model_id?: InputMaybe<Scalars['bigint']>;
  part_id?: InputMaybe<Scalars['bigint']>;
  serial_number?: InputMaybe<Scalars['String']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  status_id?: InputMaybe<Scalars['bigint']>;
  type_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_Equipment_Max_Fields = {
  __typename?: 'core_equipment_max_fields';
  condition_id?: Maybe<Scalars['bigint']>;
  equipment_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  model_id?: Maybe<Scalars['bigint']>;
  part_id?: Maybe<Scalars['bigint']>;
  serial_number?: Maybe<Scalars['String']>;
  site_id?: Maybe<Scalars['bigint']>;
  status_id?: Maybe<Scalars['bigint']>;
  type_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "core_equipment" */
export type Core_Equipment_Max_Order_By = {
  condition_id?: InputMaybe<Order_By>;
  equipment_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
  part_id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Equipment_Min_Fields = {
  __typename?: 'core_equipment_min_fields';
  condition_id?: Maybe<Scalars['bigint']>;
  equipment_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  model_id?: Maybe<Scalars['bigint']>;
  part_id?: Maybe<Scalars['bigint']>;
  serial_number?: Maybe<Scalars['String']>;
  site_id?: Maybe<Scalars['bigint']>;
  status_id?: Maybe<Scalars['bigint']>;
  type_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "core_equipment" */
export type Core_Equipment_Min_Order_By = {
  condition_id?: InputMaybe<Order_By>;
  equipment_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
  part_id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_equipment" */
export type Core_Equipment_Mutation_Response = {
  __typename?: 'core_equipment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Equipment>;
};

/** input type for inserting object relation for remote table "core_equipment" */
export type Core_Equipment_Obj_Rel_Insert_Input = {
  data: Core_Equipment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Equipment_On_Conflict>;
};

/** on_conflict condition type for table "core_equipment" */
export type Core_Equipment_On_Conflict = {
  constraint: Core_Equipment_Constraint;
  update_columns?: Array<Core_Equipment_Update_Column>;
  where?: InputMaybe<Core_Equipment_Bool_Exp>;
};

/** Ordering options when selecting data from "core_equipment". */
export type Core_Equipment_Order_By = {
  condition_id?: InputMaybe<Order_By>;
  core_boxes_aggregate?: InputMaybe<Core_Box_Aggregate_Order_By>;
  core_equipmentcondition?: InputMaybe<Core_Equipmentcondition_Order_By>;
  core_equipmentimages_aggregate?: InputMaybe<Core_Equipmentimage_Aggregate_Order_By>;
  core_equipmentmodel?: InputMaybe<Core_Equipmentmodel_Order_By>;
  core_equipmentpart?: InputMaybe<Core_Equipmentpart_Order_By>;
  core_equipmentstatus?: InputMaybe<Core_Equipmentstatus_Order_By>;
  core_equipmenttype?: InputMaybe<Core_Equipmenttype_Order_By>;
  core_site?: InputMaybe<Core_Site_Order_By>;
  equipment_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
  part_id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_equipment */
export type Core_Equipment_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_equipment" */
export enum Core_Equipment_Select_Column {
  /** column name */
  ConditionId = 'condition_id',
  /** column name */
  EquipmentName = 'equipment_name',
  /** column name */
  Id = 'id',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  PartId = 'part_id',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  TypeId = 'type_id'
}

/** input type for updating data in table "core_equipment" */
export type Core_Equipment_Set_Input = {
  condition_id?: InputMaybe<Scalars['bigint']>;
  equipment_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  model_id?: InputMaybe<Scalars['bigint']>;
  part_id?: InputMaybe<Scalars['bigint']>;
  serial_number?: InputMaybe<Scalars['String']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  status_id?: InputMaybe<Scalars['bigint']>;
  type_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_Equipment_Stddev_Fields = {
  __typename?: 'core_equipment_stddev_fields';
  condition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  model_id?: Maybe<Scalars['Float']>;
  part_id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_equipment" */
export type Core_Equipment_Stddev_Order_By = {
  condition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
  part_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Equipment_Stddev_Pop_Fields = {
  __typename?: 'core_equipment_stddev_pop_fields';
  condition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  model_id?: Maybe<Scalars['Float']>;
  part_id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_equipment" */
export type Core_Equipment_Stddev_Pop_Order_By = {
  condition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
  part_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Equipment_Stddev_Samp_Fields = {
  __typename?: 'core_equipment_stddev_samp_fields';
  condition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  model_id?: Maybe<Scalars['Float']>;
  part_id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_equipment" */
export type Core_Equipment_Stddev_Samp_Order_By = {
  condition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
  part_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_equipment" */
export type Core_Equipment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Equipment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Equipment_Stream_Cursor_Value_Input = {
  condition_id?: InputMaybe<Scalars['bigint']>;
  equipment_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  model_id?: InputMaybe<Scalars['bigint']>;
  part_id?: InputMaybe<Scalars['bigint']>;
  serial_number?: InputMaybe<Scalars['String']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  status_id?: InputMaybe<Scalars['bigint']>;
  type_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_Equipment_Sum_Fields = {
  __typename?: 'core_equipment_sum_fields';
  condition_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  model_id?: Maybe<Scalars['bigint']>;
  part_id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
  status_id?: Maybe<Scalars['bigint']>;
  type_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_equipment" */
export type Core_Equipment_Sum_Order_By = {
  condition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
  part_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_equipment" */
export enum Core_Equipment_Update_Column {
  /** column name */
  ConditionId = 'condition_id',
  /** column name */
  EquipmentName = 'equipment_name',
  /** column name */
  Id = 'id',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  PartId = 'part_id',
  /** column name */
  SerialNumber = 'serial_number',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  TypeId = 'type_id'
}

export type Core_Equipment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Equipment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Equipment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Equipment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Equipment_Var_Pop_Fields = {
  __typename?: 'core_equipment_var_pop_fields';
  condition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  model_id?: Maybe<Scalars['Float']>;
  part_id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_equipment" */
export type Core_Equipment_Var_Pop_Order_By = {
  condition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
  part_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Equipment_Var_Samp_Fields = {
  __typename?: 'core_equipment_var_samp_fields';
  condition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  model_id?: Maybe<Scalars['Float']>;
  part_id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_equipment" */
export type Core_Equipment_Var_Samp_Order_By = {
  condition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
  part_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Equipment_Variance_Fields = {
  __typename?: 'core_equipment_variance_fields';
  condition_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  model_id?: Maybe<Scalars['Float']>;
  part_id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_equipment" */
export type Core_Equipment_Variance_Order_By = {
  condition_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
  part_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  type_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_equipmentcondition" */
export type Core_Equipmentcondition = {
  __typename?: 'core_equipmentcondition';
  condition: Scalars['String'];
  /** An array relationship */
  core_equipments: Array<Core_Equipment>;
  /** An aggregate relationship */
  core_equipments_aggregate: Core_Equipment_Aggregate;
  id: Scalars['bigint'];
};


/** columns and relationships of "core_equipmentcondition" */
export type Core_EquipmentconditionCore_EquipmentsArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipment_Order_By>>;
  where?: InputMaybe<Core_Equipment_Bool_Exp>;
};


/** columns and relationships of "core_equipmentcondition" */
export type Core_EquipmentconditionCore_Equipments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipment_Order_By>>;
  where?: InputMaybe<Core_Equipment_Bool_Exp>;
};

/** aggregated selection of "core_equipmentcondition" */
export type Core_Equipmentcondition_Aggregate = {
  __typename?: 'core_equipmentcondition_aggregate';
  aggregate?: Maybe<Core_Equipmentcondition_Aggregate_Fields>;
  nodes: Array<Core_Equipmentcondition>;
};

/** aggregate fields of "core_equipmentcondition" */
export type Core_Equipmentcondition_Aggregate_Fields = {
  __typename?: 'core_equipmentcondition_aggregate_fields';
  avg?: Maybe<Core_Equipmentcondition_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Equipmentcondition_Max_Fields>;
  min?: Maybe<Core_Equipmentcondition_Min_Fields>;
  stddev?: Maybe<Core_Equipmentcondition_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Equipmentcondition_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Equipmentcondition_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Equipmentcondition_Sum_Fields>;
  var_pop?: Maybe<Core_Equipmentcondition_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Equipmentcondition_Var_Samp_Fields>;
  variance?: Maybe<Core_Equipmentcondition_Variance_Fields>;
};


/** aggregate fields of "core_equipmentcondition" */
export type Core_Equipmentcondition_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Equipmentcondition_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Core_Equipmentcondition_Avg_Fields = {
  __typename?: 'core_equipmentcondition_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "core_equipmentcondition". All fields are combined with a logical 'AND'. */
export type Core_Equipmentcondition_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Equipmentcondition_Bool_Exp>>;
  _not?: InputMaybe<Core_Equipmentcondition_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Equipmentcondition_Bool_Exp>>;
  condition?: InputMaybe<String_Comparison_Exp>;
  core_equipments?: InputMaybe<Core_Equipment_Bool_Exp>;
  core_equipments_aggregate?: InputMaybe<Core_Equipment_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_equipmentcondition" */
export enum Core_Equipmentcondition_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreEquipmentconditionPkey = 'core_equipmentcondition_pkey'
}

/** input type for incrementing numeric columns in table "core_equipmentcondition" */
export type Core_Equipmentcondition_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_equipmentcondition" */
export type Core_Equipmentcondition_Insert_Input = {
  condition?: InputMaybe<Scalars['String']>;
  core_equipments?: InputMaybe<Core_Equipment_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_Equipmentcondition_Max_Fields = {
  __typename?: 'core_equipmentcondition_max_fields';
  condition?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Core_Equipmentcondition_Min_Fields = {
  __typename?: 'core_equipmentcondition_min_fields';
  condition?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "core_equipmentcondition" */
export type Core_Equipmentcondition_Mutation_Response = {
  __typename?: 'core_equipmentcondition_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Equipmentcondition>;
};

/** input type for inserting object relation for remote table "core_equipmentcondition" */
export type Core_Equipmentcondition_Obj_Rel_Insert_Input = {
  data: Core_Equipmentcondition_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Equipmentcondition_On_Conflict>;
};

/** on_conflict condition type for table "core_equipmentcondition" */
export type Core_Equipmentcondition_On_Conflict = {
  constraint: Core_Equipmentcondition_Constraint;
  update_columns?: Array<Core_Equipmentcondition_Update_Column>;
  where?: InputMaybe<Core_Equipmentcondition_Bool_Exp>;
};

/** Ordering options when selecting data from "core_equipmentcondition". */
export type Core_Equipmentcondition_Order_By = {
  condition?: InputMaybe<Order_By>;
  core_equipments_aggregate?: InputMaybe<Core_Equipment_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_equipmentcondition */
export type Core_Equipmentcondition_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_equipmentcondition" */
export enum Core_Equipmentcondition_Select_Column {
  /** column name */
  Condition = 'condition',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "core_equipmentcondition" */
export type Core_Equipmentcondition_Set_Input = {
  condition?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_Equipmentcondition_Stddev_Fields = {
  __typename?: 'core_equipmentcondition_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Core_Equipmentcondition_Stddev_Pop_Fields = {
  __typename?: 'core_equipmentcondition_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Core_Equipmentcondition_Stddev_Samp_Fields = {
  __typename?: 'core_equipmentcondition_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "core_equipmentcondition" */
export type Core_Equipmentcondition_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Equipmentcondition_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Equipmentcondition_Stream_Cursor_Value_Input = {
  condition?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_Equipmentcondition_Sum_Fields = {
  __typename?: 'core_equipmentcondition_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "core_equipmentcondition" */
export enum Core_Equipmentcondition_Update_Column {
  /** column name */
  Condition = 'condition',
  /** column name */
  Id = 'id'
}

export type Core_Equipmentcondition_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Equipmentcondition_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Equipmentcondition_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Equipmentcondition_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Equipmentcondition_Var_Pop_Fields = {
  __typename?: 'core_equipmentcondition_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Core_Equipmentcondition_Var_Samp_Fields = {
  __typename?: 'core_equipmentcondition_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Core_Equipmentcondition_Variance_Fields = {
  __typename?: 'core_equipmentcondition_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "core_equipmentimage" */
export type Core_Equipmentimage = {
  __typename?: 'core_equipmentimage';
  /** An object relationship */
  core_equipment: Core_Equipment;
  created_on: Scalars['timestamptz'];
  equipment_id: Scalars['bigint'];
  id: Scalars['bigint'];
  image?: Maybe<Scalars['String']>;
};

/** aggregated selection of "core_equipmentimage" */
export type Core_Equipmentimage_Aggregate = {
  __typename?: 'core_equipmentimage_aggregate';
  aggregate?: Maybe<Core_Equipmentimage_Aggregate_Fields>;
  nodes: Array<Core_Equipmentimage>;
};

export type Core_Equipmentimage_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Equipmentimage_Aggregate_Bool_Exp_Count>;
};

export type Core_Equipmentimage_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Equipmentimage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Equipmentimage_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_equipmentimage" */
export type Core_Equipmentimage_Aggregate_Fields = {
  __typename?: 'core_equipmentimage_aggregate_fields';
  avg?: Maybe<Core_Equipmentimage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Equipmentimage_Max_Fields>;
  min?: Maybe<Core_Equipmentimage_Min_Fields>;
  stddev?: Maybe<Core_Equipmentimage_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Equipmentimage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Equipmentimage_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Equipmentimage_Sum_Fields>;
  var_pop?: Maybe<Core_Equipmentimage_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Equipmentimage_Var_Samp_Fields>;
  variance?: Maybe<Core_Equipmentimage_Variance_Fields>;
};


/** aggregate fields of "core_equipmentimage" */
export type Core_Equipmentimage_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Equipmentimage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_equipmentimage" */
export type Core_Equipmentimage_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Equipmentimage_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Equipmentimage_Max_Order_By>;
  min?: InputMaybe<Core_Equipmentimage_Min_Order_By>;
  stddev?: InputMaybe<Core_Equipmentimage_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Equipmentimage_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Equipmentimage_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Equipmentimage_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Equipmentimage_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Equipmentimage_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Equipmentimage_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_equipmentimage" */
export type Core_Equipmentimage_Arr_Rel_Insert_Input = {
  data: Array<Core_Equipmentimage_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Equipmentimage_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Equipmentimage_Avg_Fields = {
  __typename?: 'core_equipmentimage_avg_fields';
  equipment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_equipmentimage" */
export type Core_Equipmentimage_Avg_Order_By = {
  equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_equipmentimage". All fields are combined with a logical 'AND'. */
export type Core_Equipmentimage_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Equipmentimage_Bool_Exp>>;
  _not?: InputMaybe<Core_Equipmentimage_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Equipmentimage_Bool_Exp>>;
  core_equipment?: InputMaybe<Core_Equipment_Bool_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  equipment_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_equipmentimage" */
export enum Core_Equipmentimage_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreEquipmentimagePkey = 'core_equipmentimage_pkey'
}

/** input type for incrementing numeric columns in table "core_equipmentimage" */
export type Core_Equipmentimage_Inc_Input = {
  equipment_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_equipmentimage" */
export type Core_Equipmentimage_Insert_Input = {
  core_equipment?: InputMaybe<Core_Equipment_Obj_Rel_Insert_Input>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  equipment_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  image?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Equipmentimage_Max_Fields = {
  __typename?: 'core_equipmentimage_max_fields';
  created_on?: Maybe<Scalars['timestamptz']>;
  equipment_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  image?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "core_equipmentimage" */
export type Core_Equipmentimage_Max_Order_By = {
  created_on?: InputMaybe<Order_By>;
  equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Equipmentimage_Min_Fields = {
  __typename?: 'core_equipmentimage_min_fields';
  created_on?: Maybe<Scalars['timestamptz']>;
  equipment_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  image?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "core_equipmentimage" */
export type Core_Equipmentimage_Min_Order_By = {
  created_on?: InputMaybe<Order_By>;
  equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_equipmentimage" */
export type Core_Equipmentimage_Mutation_Response = {
  __typename?: 'core_equipmentimage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Equipmentimage>;
};

/** on_conflict condition type for table "core_equipmentimage" */
export type Core_Equipmentimage_On_Conflict = {
  constraint: Core_Equipmentimage_Constraint;
  update_columns?: Array<Core_Equipmentimage_Update_Column>;
  where?: InputMaybe<Core_Equipmentimage_Bool_Exp>;
};

/** Ordering options when selecting data from "core_equipmentimage". */
export type Core_Equipmentimage_Order_By = {
  core_equipment?: InputMaybe<Core_Equipment_Order_By>;
  created_on?: InputMaybe<Order_By>;
  equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_equipmentimage */
export type Core_Equipmentimage_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_equipmentimage" */
export enum Core_Equipmentimage_Select_Column {
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  EquipmentId = 'equipment_id',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image'
}

/** input type for updating data in table "core_equipmentimage" */
export type Core_Equipmentimage_Set_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  equipment_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  image?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Equipmentimage_Stddev_Fields = {
  __typename?: 'core_equipmentimage_stddev_fields';
  equipment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_equipmentimage" */
export type Core_Equipmentimage_Stddev_Order_By = {
  equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Equipmentimage_Stddev_Pop_Fields = {
  __typename?: 'core_equipmentimage_stddev_pop_fields';
  equipment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_equipmentimage" */
export type Core_Equipmentimage_Stddev_Pop_Order_By = {
  equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Equipmentimage_Stddev_Samp_Fields = {
  __typename?: 'core_equipmentimage_stddev_samp_fields';
  equipment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_equipmentimage" */
export type Core_Equipmentimage_Stddev_Samp_Order_By = {
  equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_equipmentimage" */
export type Core_Equipmentimage_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Equipmentimage_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Equipmentimage_Stream_Cursor_Value_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  equipment_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  image?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Equipmentimage_Sum_Fields = {
  __typename?: 'core_equipmentimage_sum_fields';
  equipment_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_equipmentimage" */
export type Core_Equipmentimage_Sum_Order_By = {
  equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "core_equipmentimage" */
export enum Core_Equipmentimage_Update_Column {
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  EquipmentId = 'equipment_id',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image'
}

export type Core_Equipmentimage_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Equipmentimage_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Equipmentimage_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Equipmentimage_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Equipmentimage_Var_Pop_Fields = {
  __typename?: 'core_equipmentimage_var_pop_fields';
  equipment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_equipmentimage" */
export type Core_Equipmentimage_Var_Pop_Order_By = {
  equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Equipmentimage_Var_Samp_Fields = {
  __typename?: 'core_equipmentimage_var_samp_fields';
  equipment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_equipmentimage" */
export type Core_Equipmentimage_Var_Samp_Order_By = {
  equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Equipmentimage_Variance_Fields = {
  __typename?: 'core_equipmentimage_variance_fields';
  equipment_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_equipmentimage" */
export type Core_Equipmentimage_Variance_Order_By = {
  equipment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_equipmentmake" */
export type Core_Equipmentmake = {
  __typename?: 'core_equipmentmake';
  /** An array relationship */
  core_equipmentmodels: Array<Core_Equipmentmodel>;
  /** An aggregate relationship */
  core_equipmentmodels_aggregate: Core_Equipmentmodel_Aggregate;
  equipment_make: Scalars['String'];
  id: Scalars['bigint'];
};


/** columns and relationships of "core_equipmentmake" */
export type Core_EquipmentmakeCore_EquipmentmodelsArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentmodel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentmodel_Order_By>>;
  where?: InputMaybe<Core_Equipmentmodel_Bool_Exp>;
};


/** columns and relationships of "core_equipmentmake" */
export type Core_EquipmentmakeCore_Equipmentmodels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentmodel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentmodel_Order_By>>;
  where?: InputMaybe<Core_Equipmentmodel_Bool_Exp>;
};

/** aggregated selection of "core_equipmentmake" */
export type Core_Equipmentmake_Aggregate = {
  __typename?: 'core_equipmentmake_aggregate';
  aggregate?: Maybe<Core_Equipmentmake_Aggregate_Fields>;
  nodes: Array<Core_Equipmentmake>;
};

/** aggregate fields of "core_equipmentmake" */
export type Core_Equipmentmake_Aggregate_Fields = {
  __typename?: 'core_equipmentmake_aggregate_fields';
  avg?: Maybe<Core_Equipmentmake_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Equipmentmake_Max_Fields>;
  min?: Maybe<Core_Equipmentmake_Min_Fields>;
  stddev?: Maybe<Core_Equipmentmake_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Equipmentmake_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Equipmentmake_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Equipmentmake_Sum_Fields>;
  var_pop?: Maybe<Core_Equipmentmake_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Equipmentmake_Var_Samp_Fields>;
  variance?: Maybe<Core_Equipmentmake_Variance_Fields>;
};


/** aggregate fields of "core_equipmentmake" */
export type Core_Equipmentmake_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Equipmentmake_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Core_Equipmentmake_Avg_Fields = {
  __typename?: 'core_equipmentmake_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "core_equipmentmake". All fields are combined with a logical 'AND'. */
export type Core_Equipmentmake_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Equipmentmake_Bool_Exp>>;
  _not?: InputMaybe<Core_Equipmentmake_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Equipmentmake_Bool_Exp>>;
  core_equipmentmodels?: InputMaybe<Core_Equipmentmodel_Bool_Exp>;
  core_equipmentmodels_aggregate?: InputMaybe<Core_Equipmentmodel_Aggregate_Bool_Exp>;
  equipment_make?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_equipmentmake" */
export enum Core_Equipmentmake_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreEquipmentmakePkey = 'core_equipmentmake_pkey'
}

/** input type for incrementing numeric columns in table "core_equipmentmake" */
export type Core_Equipmentmake_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_equipmentmake" */
export type Core_Equipmentmake_Insert_Input = {
  core_equipmentmodels?: InputMaybe<Core_Equipmentmodel_Arr_Rel_Insert_Input>;
  equipment_make?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_Equipmentmake_Max_Fields = {
  __typename?: 'core_equipmentmake_max_fields';
  equipment_make?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Core_Equipmentmake_Min_Fields = {
  __typename?: 'core_equipmentmake_min_fields';
  equipment_make?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "core_equipmentmake" */
export type Core_Equipmentmake_Mutation_Response = {
  __typename?: 'core_equipmentmake_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Equipmentmake>;
};

/** input type for inserting object relation for remote table "core_equipmentmake" */
export type Core_Equipmentmake_Obj_Rel_Insert_Input = {
  data: Core_Equipmentmake_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Equipmentmake_On_Conflict>;
};

/** on_conflict condition type for table "core_equipmentmake" */
export type Core_Equipmentmake_On_Conflict = {
  constraint: Core_Equipmentmake_Constraint;
  update_columns?: Array<Core_Equipmentmake_Update_Column>;
  where?: InputMaybe<Core_Equipmentmake_Bool_Exp>;
};

/** Ordering options when selecting data from "core_equipmentmake". */
export type Core_Equipmentmake_Order_By = {
  core_equipmentmodels_aggregate?: InputMaybe<Core_Equipmentmodel_Aggregate_Order_By>;
  equipment_make?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_equipmentmake */
export type Core_Equipmentmake_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_equipmentmake" */
export enum Core_Equipmentmake_Select_Column {
  /** column name */
  EquipmentMake = 'equipment_make',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "core_equipmentmake" */
export type Core_Equipmentmake_Set_Input = {
  equipment_make?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_Equipmentmake_Stddev_Fields = {
  __typename?: 'core_equipmentmake_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Core_Equipmentmake_Stddev_Pop_Fields = {
  __typename?: 'core_equipmentmake_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Core_Equipmentmake_Stddev_Samp_Fields = {
  __typename?: 'core_equipmentmake_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "core_equipmentmake" */
export type Core_Equipmentmake_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Equipmentmake_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Equipmentmake_Stream_Cursor_Value_Input = {
  equipment_make?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_Equipmentmake_Sum_Fields = {
  __typename?: 'core_equipmentmake_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "core_equipmentmake" */
export enum Core_Equipmentmake_Update_Column {
  /** column name */
  EquipmentMake = 'equipment_make',
  /** column name */
  Id = 'id'
}

export type Core_Equipmentmake_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Equipmentmake_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Equipmentmake_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Equipmentmake_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Equipmentmake_Var_Pop_Fields = {
  __typename?: 'core_equipmentmake_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Core_Equipmentmake_Var_Samp_Fields = {
  __typename?: 'core_equipmentmake_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Core_Equipmentmake_Variance_Fields = {
  __typename?: 'core_equipmentmake_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "core_equipmentmodel" */
export type Core_Equipmentmodel = {
  __typename?: 'core_equipmentmodel';
  /** An object relationship */
  core_equipmentmake: Core_Equipmentmake;
  /** An array relationship */
  core_equipmentparts: Array<Core_Equipmentpart>;
  /** An aggregate relationship */
  core_equipmentparts_aggregate: Core_Equipmentpart_Aggregate;
  /** An array relationship */
  core_equipments: Array<Core_Equipment>;
  /** An aggregate relationship */
  core_equipments_aggregate: Core_Equipment_Aggregate;
  equipment_model: Scalars['String'];
  id: Scalars['bigint'];
  make_id: Scalars['bigint'];
  model_number: Scalars['String'];
};


/** columns and relationships of "core_equipmentmodel" */
export type Core_EquipmentmodelCore_EquipmentpartsArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentpart_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentpart_Order_By>>;
  where?: InputMaybe<Core_Equipmentpart_Bool_Exp>;
};


/** columns and relationships of "core_equipmentmodel" */
export type Core_EquipmentmodelCore_Equipmentparts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentpart_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentpart_Order_By>>;
  where?: InputMaybe<Core_Equipmentpart_Bool_Exp>;
};


/** columns and relationships of "core_equipmentmodel" */
export type Core_EquipmentmodelCore_EquipmentsArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipment_Order_By>>;
  where?: InputMaybe<Core_Equipment_Bool_Exp>;
};


/** columns and relationships of "core_equipmentmodel" */
export type Core_EquipmentmodelCore_Equipments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipment_Order_By>>;
  where?: InputMaybe<Core_Equipment_Bool_Exp>;
};

/** aggregated selection of "core_equipmentmodel" */
export type Core_Equipmentmodel_Aggregate = {
  __typename?: 'core_equipmentmodel_aggregate';
  aggregate?: Maybe<Core_Equipmentmodel_Aggregate_Fields>;
  nodes: Array<Core_Equipmentmodel>;
};

export type Core_Equipmentmodel_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Equipmentmodel_Aggregate_Bool_Exp_Count>;
};

export type Core_Equipmentmodel_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Equipmentmodel_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Equipmentmodel_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_equipmentmodel" */
export type Core_Equipmentmodel_Aggregate_Fields = {
  __typename?: 'core_equipmentmodel_aggregate_fields';
  avg?: Maybe<Core_Equipmentmodel_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Equipmentmodel_Max_Fields>;
  min?: Maybe<Core_Equipmentmodel_Min_Fields>;
  stddev?: Maybe<Core_Equipmentmodel_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Equipmentmodel_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Equipmentmodel_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Equipmentmodel_Sum_Fields>;
  var_pop?: Maybe<Core_Equipmentmodel_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Equipmentmodel_Var_Samp_Fields>;
  variance?: Maybe<Core_Equipmentmodel_Variance_Fields>;
};


/** aggregate fields of "core_equipmentmodel" */
export type Core_Equipmentmodel_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Equipmentmodel_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_equipmentmodel" */
export type Core_Equipmentmodel_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Equipmentmodel_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Equipmentmodel_Max_Order_By>;
  min?: InputMaybe<Core_Equipmentmodel_Min_Order_By>;
  stddev?: InputMaybe<Core_Equipmentmodel_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Equipmentmodel_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Equipmentmodel_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Equipmentmodel_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Equipmentmodel_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Equipmentmodel_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Equipmentmodel_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_equipmentmodel" */
export type Core_Equipmentmodel_Arr_Rel_Insert_Input = {
  data: Array<Core_Equipmentmodel_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Equipmentmodel_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Equipmentmodel_Avg_Fields = {
  __typename?: 'core_equipmentmodel_avg_fields';
  id?: Maybe<Scalars['Float']>;
  make_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_equipmentmodel" */
export type Core_Equipmentmodel_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  make_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_equipmentmodel". All fields are combined with a logical 'AND'. */
export type Core_Equipmentmodel_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Equipmentmodel_Bool_Exp>>;
  _not?: InputMaybe<Core_Equipmentmodel_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Equipmentmodel_Bool_Exp>>;
  core_equipmentmake?: InputMaybe<Core_Equipmentmake_Bool_Exp>;
  core_equipmentparts?: InputMaybe<Core_Equipmentpart_Bool_Exp>;
  core_equipmentparts_aggregate?: InputMaybe<Core_Equipmentpart_Aggregate_Bool_Exp>;
  core_equipments?: InputMaybe<Core_Equipment_Bool_Exp>;
  core_equipments_aggregate?: InputMaybe<Core_Equipment_Aggregate_Bool_Exp>;
  equipment_model?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  make_id?: InputMaybe<Bigint_Comparison_Exp>;
  model_number?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_equipmentmodel" */
export enum Core_Equipmentmodel_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreEquipmentmodelPkey = 'core_equipmentmodel_pkey'
}

/** input type for incrementing numeric columns in table "core_equipmentmodel" */
export type Core_Equipmentmodel_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  make_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_equipmentmodel" */
export type Core_Equipmentmodel_Insert_Input = {
  core_equipmentmake?: InputMaybe<Core_Equipmentmake_Obj_Rel_Insert_Input>;
  core_equipmentparts?: InputMaybe<Core_Equipmentpart_Arr_Rel_Insert_Input>;
  core_equipments?: InputMaybe<Core_Equipment_Arr_Rel_Insert_Input>;
  equipment_model?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  make_id?: InputMaybe<Scalars['bigint']>;
  model_number?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Equipmentmodel_Max_Fields = {
  __typename?: 'core_equipmentmodel_max_fields';
  equipment_model?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  make_id?: Maybe<Scalars['bigint']>;
  model_number?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "core_equipmentmodel" */
export type Core_Equipmentmodel_Max_Order_By = {
  equipment_model?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  make_id?: InputMaybe<Order_By>;
  model_number?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Equipmentmodel_Min_Fields = {
  __typename?: 'core_equipmentmodel_min_fields';
  equipment_model?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  make_id?: Maybe<Scalars['bigint']>;
  model_number?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "core_equipmentmodel" */
export type Core_Equipmentmodel_Min_Order_By = {
  equipment_model?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  make_id?: InputMaybe<Order_By>;
  model_number?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_equipmentmodel" */
export type Core_Equipmentmodel_Mutation_Response = {
  __typename?: 'core_equipmentmodel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Equipmentmodel>;
};

/** input type for inserting object relation for remote table "core_equipmentmodel" */
export type Core_Equipmentmodel_Obj_Rel_Insert_Input = {
  data: Core_Equipmentmodel_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Equipmentmodel_On_Conflict>;
};

/** on_conflict condition type for table "core_equipmentmodel" */
export type Core_Equipmentmodel_On_Conflict = {
  constraint: Core_Equipmentmodel_Constraint;
  update_columns?: Array<Core_Equipmentmodel_Update_Column>;
  where?: InputMaybe<Core_Equipmentmodel_Bool_Exp>;
};

/** Ordering options when selecting data from "core_equipmentmodel". */
export type Core_Equipmentmodel_Order_By = {
  core_equipmentmake?: InputMaybe<Core_Equipmentmake_Order_By>;
  core_equipmentparts_aggregate?: InputMaybe<Core_Equipmentpart_Aggregate_Order_By>;
  core_equipments_aggregate?: InputMaybe<Core_Equipment_Aggregate_Order_By>;
  equipment_model?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  make_id?: InputMaybe<Order_By>;
  model_number?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_equipmentmodel */
export type Core_Equipmentmodel_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_equipmentmodel" */
export enum Core_Equipmentmodel_Select_Column {
  /** column name */
  EquipmentModel = 'equipment_model',
  /** column name */
  Id = 'id',
  /** column name */
  MakeId = 'make_id',
  /** column name */
  ModelNumber = 'model_number'
}

/** input type for updating data in table "core_equipmentmodel" */
export type Core_Equipmentmodel_Set_Input = {
  equipment_model?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  make_id?: InputMaybe<Scalars['bigint']>;
  model_number?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Equipmentmodel_Stddev_Fields = {
  __typename?: 'core_equipmentmodel_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  make_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_equipmentmodel" */
export type Core_Equipmentmodel_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  make_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Equipmentmodel_Stddev_Pop_Fields = {
  __typename?: 'core_equipmentmodel_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  make_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_equipmentmodel" */
export type Core_Equipmentmodel_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  make_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Equipmentmodel_Stddev_Samp_Fields = {
  __typename?: 'core_equipmentmodel_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  make_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_equipmentmodel" */
export type Core_Equipmentmodel_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  make_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_equipmentmodel" */
export type Core_Equipmentmodel_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Equipmentmodel_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Equipmentmodel_Stream_Cursor_Value_Input = {
  equipment_model?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  make_id?: InputMaybe<Scalars['bigint']>;
  model_number?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Equipmentmodel_Sum_Fields = {
  __typename?: 'core_equipmentmodel_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  make_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_equipmentmodel" */
export type Core_Equipmentmodel_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  make_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_equipmentmodel" */
export enum Core_Equipmentmodel_Update_Column {
  /** column name */
  EquipmentModel = 'equipment_model',
  /** column name */
  Id = 'id',
  /** column name */
  MakeId = 'make_id',
  /** column name */
  ModelNumber = 'model_number'
}

export type Core_Equipmentmodel_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Equipmentmodel_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Equipmentmodel_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Equipmentmodel_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Equipmentmodel_Var_Pop_Fields = {
  __typename?: 'core_equipmentmodel_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  make_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_equipmentmodel" */
export type Core_Equipmentmodel_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  make_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Equipmentmodel_Var_Samp_Fields = {
  __typename?: 'core_equipmentmodel_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  make_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_equipmentmodel" */
export type Core_Equipmentmodel_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  make_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Equipmentmodel_Variance_Fields = {
  __typename?: 'core_equipmentmodel_variance_fields';
  id?: Maybe<Scalars['Float']>;
  make_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_equipmentmodel" */
export type Core_Equipmentmodel_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  make_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_equipmentpart" */
export type Core_Equipmentpart = {
  __typename?: 'core_equipmentpart';
  /** An object relationship */
  core_equipmentmodel: Core_Equipmentmodel;
  /** An array relationship */
  core_equipments: Array<Core_Equipment>;
  /** An aggregate relationship */
  core_equipments_aggregate: Core_Equipment_Aggregate;
  equipment_part: Scalars['String'];
  id: Scalars['bigint'];
  model_id: Scalars['bigint'];
};


/** columns and relationships of "core_equipmentpart" */
export type Core_EquipmentpartCore_EquipmentsArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipment_Order_By>>;
  where?: InputMaybe<Core_Equipment_Bool_Exp>;
};


/** columns and relationships of "core_equipmentpart" */
export type Core_EquipmentpartCore_Equipments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipment_Order_By>>;
  where?: InputMaybe<Core_Equipment_Bool_Exp>;
};

/** aggregated selection of "core_equipmentpart" */
export type Core_Equipmentpart_Aggregate = {
  __typename?: 'core_equipmentpart_aggregate';
  aggregate?: Maybe<Core_Equipmentpart_Aggregate_Fields>;
  nodes: Array<Core_Equipmentpart>;
};

export type Core_Equipmentpart_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Equipmentpart_Aggregate_Bool_Exp_Count>;
};

export type Core_Equipmentpart_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Equipmentpart_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Equipmentpart_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_equipmentpart" */
export type Core_Equipmentpart_Aggregate_Fields = {
  __typename?: 'core_equipmentpart_aggregate_fields';
  avg?: Maybe<Core_Equipmentpart_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Equipmentpart_Max_Fields>;
  min?: Maybe<Core_Equipmentpart_Min_Fields>;
  stddev?: Maybe<Core_Equipmentpart_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Equipmentpart_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Equipmentpart_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Equipmentpart_Sum_Fields>;
  var_pop?: Maybe<Core_Equipmentpart_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Equipmentpart_Var_Samp_Fields>;
  variance?: Maybe<Core_Equipmentpart_Variance_Fields>;
};


/** aggregate fields of "core_equipmentpart" */
export type Core_Equipmentpart_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Equipmentpart_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_equipmentpart" */
export type Core_Equipmentpart_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Equipmentpart_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Equipmentpart_Max_Order_By>;
  min?: InputMaybe<Core_Equipmentpart_Min_Order_By>;
  stddev?: InputMaybe<Core_Equipmentpart_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Equipmentpart_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Equipmentpart_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Equipmentpart_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Equipmentpart_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Equipmentpart_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Equipmentpart_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_equipmentpart" */
export type Core_Equipmentpart_Arr_Rel_Insert_Input = {
  data: Array<Core_Equipmentpart_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Equipmentpart_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Equipmentpart_Avg_Fields = {
  __typename?: 'core_equipmentpart_avg_fields';
  id?: Maybe<Scalars['Float']>;
  model_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_equipmentpart" */
export type Core_Equipmentpart_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_equipmentpart". All fields are combined with a logical 'AND'. */
export type Core_Equipmentpart_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Equipmentpart_Bool_Exp>>;
  _not?: InputMaybe<Core_Equipmentpart_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Equipmentpart_Bool_Exp>>;
  core_equipmentmodel?: InputMaybe<Core_Equipmentmodel_Bool_Exp>;
  core_equipments?: InputMaybe<Core_Equipment_Bool_Exp>;
  core_equipments_aggregate?: InputMaybe<Core_Equipment_Aggregate_Bool_Exp>;
  equipment_part?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  model_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_equipmentpart" */
export enum Core_Equipmentpart_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreEquipmentpartPkey = 'core_equipmentpart_pkey'
}

/** input type for incrementing numeric columns in table "core_equipmentpart" */
export type Core_Equipmentpart_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  model_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_equipmentpart" */
export type Core_Equipmentpart_Insert_Input = {
  core_equipmentmodel?: InputMaybe<Core_Equipmentmodel_Obj_Rel_Insert_Input>;
  core_equipments?: InputMaybe<Core_Equipment_Arr_Rel_Insert_Input>;
  equipment_part?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  model_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_Equipmentpart_Max_Fields = {
  __typename?: 'core_equipmentpart_max_fields';
  equipment_part?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  model_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "core_equipmentpart" */
export type Core_Equipmentpart_Max_Order_By = {
  equipment_part?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Equipmentpart_Min_Fields = {
  __typename?: 'core_equipmentpart_min_fields';
  equipment_part?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  model_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "core_equipmentpart" */
export type Core_Equipmentpart_Min_Order_By = {
  equipment_part?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_equipmentpart" */
export type Core_Equipmentpart_Mutation_Response = {
  __typename?: 'core_equipmentpart_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Equipmentpart>;
};

/** input type for inserting object relation for remote table "core_equipmentpart" */
export type Core_Equipmentpart_Obj_Rel_Insert_Input = {
  data: Core_Equipmentpart_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Equipmentpart_On_Conflict>;
};

/** on_conflict condition type for table "core_equipmentpart" */
export type Core_Equipmentpart_On_Conflict = {
  constraint: Core_Equipmentpart_Constraint;
  update_columns?: Array<Core_Equipmentpart_Update_Column>;
  where?: InputMaybe<Core_Equipmentpart_Bool_Exp>;
};

/** Ordering options when selecting data from "core_equipmentpart". */
export type Core_Equipmentpart_Order_By = {
  core_equipmentmodel?: InputMaybe<Core_Equipmentmodel_Order_By>;
  core_equipments_aggregate?: InputMaybe<Core_Equipment_Aggregate_Order_By>;
  equipment_part?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_equipmentpart */
export type Core_Equipmentpart_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_equipmentpart" */
export enum Core_Equipmentpart_Select_Column {
  /** column name */
  EquipmentPart = 'equipment_part',
  /** column name */
  Id = 'id',
  /** column name */
  ModelId = 'model_id'
}

/** input type for updating data in table "core_equipmentpart" */
export type Core_Equipmentpart_Set_Input = {
  equipment_part?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  model_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_Equipmentpart_Stddev_Fields = {
  __typename?: 'core_equipmentpart_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  model_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_equipmentpart" */
export type Core_Equipmentpart_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Equipmentpart_Stddev_Pop_Fields = {
  __typename?: 'core_equipmentpart_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  model_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_equipmentpart" */
export type Core_Equipmentpart_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Equipmentpart_Stddev_Samp_Fields = {
  __typename?: 'core_equipmentpart_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  model_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_equipmentpart" */
export type Core_Equipmentpart_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_equipmentpart" */
export type Core_Equipmentpart_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Equipmentpart_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Equipmentpart_Stream_Cursor_Value_Input = {
  equipment_part?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  model_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_Equipmentpart_Sum_Fields = {
  __typename?: 'core_equipmentpart_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  model_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_equipmentpart" */
export type Core_Equipmentpart_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_equipmentpart" */
export enum Core_Equipmentpart_Update_Column {
  /** column name */
  EquipmentPart = 'equipment_part',
  /** column name */
  Id = 'id',
  /** column name */
  ModelId = 'model_id'
}

export type Core_Equipmentpart_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Equipmentpart_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Equipmentpart_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Equipmentpart_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Equipmentpart_Var_Pop_Fields = {
  __typename?: 'core_equipmentpart_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  model_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_equipmentpart" */
export type Core_Equipmentpart_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Equipmentpart_Var_Samp_Fields = {
  __typename?: 'core_equipmentpart_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  model_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_equipmentpart" */
export type Core_Equipmentpart_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Equipmentpart_Variance_Fields = {
  __typename?: 'core_equipmentpart_variance_fields';
  id?: Maybe<Scalars['Float']>;
  model_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_equipmentpart" */
export type Core_Equipmentpart_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_equipmentstatus" */
export type Core_Equipmentstatus = {
  __typename?: 'core_equipmentstatus';
  /** An array relationship */
  core_equipments: Array<Core_Equipment>;
  /** An aggregate relationship */
  core_equipments_aggregate: Core_Equipment_Aggregate;
  equipment_status: Scalars['String'];
  id: Scalars['bigint'];
};


/** columns and relationships of "core_equipmentstatus" */
export type Core_EquipmentstatusCore_EquipmentsArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipment_Order_By>>;
  where?: InputMaybe<Core_Equipment_Bool_Exp>;
};


/** columns and relationships of "core_equipmentstatus" */
export type Core_EquipmentstatusCore_Equipments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipment_Order_By>>;
  where?: InputMaybe<Core_Equipment_Bool_Exp>;
};

/** aggregated selection of "core_equipmentstatus" */
export type Core_Equipmentstatus_Aggregate = {
  __typename?: 'core_equipmentstatus_aggregate';
  aggregate?: Maybe<Core_Equipmentstatus_Aggregate_Fields>;
  nodes: Array<Core_Equipmentstatus>;
};

/** aggregate fields of "core_equipmentstatus" */
export type Core_Equipmentstatus_Aggregate_Fields = {
  __typename?: 'core_equipmentstatus_aggregate_fields';
  avg?: Maybe<Core_Equipmentstatus_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Equipmentstatus_Max_Fields>;
  min?: Maybe<Core_Equipmentstatus_Min_Fields>;
  stddev?: Maybe<Core_Equipmentstatus_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Equipmentstatus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Equipmentstatus_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Equipmentstatus_Sum_Fields>;
  var_pop?: Maybe<Core_Equipmentstatus_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Equipmentstatus_Var_Samp_Fields>;
  variance?: Maybe<Core_Equipmentstatus_Variance_Fields>;
};


/** aggregate fields of "core_equipmentstatus" */
export type Core_Equipmentstatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Equipmentstatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Core_Equipmentstatus_Avg_Fields = {
  __typename?: 'core_equipmentstatus_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "core_equipmentstatus". All fields are combined with a logical 'AND'. */
export type Core_Equipmentstatus_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Equipmentstatus_Bool_Exp>>;
  _not?: InputMaybe<Core_Equipmentstatus_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Equipmentstatus_Bool_Exp>>;
  core_equipments?: InputMaybe<Core_Equipment_Bool_Exp>;
  core_equipments_aggregate?: InputMaybe<Core_Equipment_Aggregate_Bool_Exp>;
  equipment_status?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_equipmentstatus" */
export enum Core_Equipmentstatus_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreEquipmentstatusPkey = 'core_equipmentstatus_pkey'
}

/** input type for incrementing numeric columns in table "core_equipmentstatus" */
export type Core_Equipmentstatus_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_equipmentstatus" */
export type Core_Equipmentstatus_Insert_Input = {
  core_equipments?: InputMaybe<Core_Equipment_Arr_Rel_Insert_Input>;
  equipment_status?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_Equipmentstatus_Max_Fields = {
  __typename?: 'core_equipmentstatus_max_fields';
  equipment_status?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Core_Equipmentstatus_Min_Fields = {
  __typename?: 'core_equipmentstatus_min_fields';
  equipment_status?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "core_equipmentstatus" */
export type Core_Equipmentstatus_Mutation_Response = {
  __typename?: 'core_equipmentstatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Equipmentstatus>;
};

/** input type for inserting object relation for remote table "core_equipmentstatus" */
export type Core_Equipmentstatus_Obj_Rel_Insert_Input = {
  data: Core_Equipmentstatus_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Equipmentstatus_On_Conflict>;
};

/** on_conflict condition type for table "core_equipmentstatus" */
export type Core_Equipmentstatus_On_Conflict = {
  constraint: Core_Equipmentstatus_Constraint;
  update_columns?: Array<Core_Equipmentstatus_Update_Column>;
  where?: InputMaybe<Core_Equipmentstatus_Bool_Exp>;
};

/** Ordering options when selecting data from "core_equipmentstatus". */
export type Core_Equipmentstatus_Order_By = {
  core_equipments_aggregate?: InputMaybe<Core_Equipment_Aggregate_Order_By>;
  equipment_status?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_equipmentstatus */
export type Core_Equipmentstatus_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_equipmentstatus" */
export enum Core_Equipmentstatus_Select_Column {
  /** column name */
  EquipmentStatus = 'equipment_status',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "core_equipmentstatus" */
export type Core_Equipmentstatus_Set_Input = {
  equipment_status?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_Equipmentstatus_Stddev_Fields = {
  __typename?: 'core_equipmentstatus_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Core_Equipmentstatus_Stddev_Pop_Fields = {
  __typename?: 'core_equipmentstatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Core_Equipmentstatus_Stddev_Samp_Fields = {
  __typename?: 'core_equipmentstatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "core_equipmentstatus" */
export type Core_Equipmentstatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Equipmentstatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Equipmentstatus_Stream_Cursor_Value_Input = {
  equipment_status?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_Equipmentstatus_Sum_Fields = {
  __typename?: 'core_equipmentstatus_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "core_equipmentstatus" */
export enum Core_Equipmentstatus_Update_Column {
  /** column name */
  EquipmentStatus = 'equipment_status',
  /** column name */
  Id = 'id'
}

export type Core_Equipmentstatus_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Equipmentstatus_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Equipmentstatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Equipmentstatus_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Equipmentstatus_Var_Pop_Fields = {
  __typename?: 'core_equipmentstatus_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Core_Equipmentstatus_Var_Samp_Fields = {
  __typename?: 'core_equipmentstatus_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Core_Equipmentstatus_Variance_Fields = {
  __typename?: 'core_equipmentstatus_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "core_equipmenttype" */
export type Core_Equipmenttype = {
  __typename?: 'core_equipmenttype';
  /** An array relationship */
  core_equipments: Array<Core_Equipment>;
  /** An aggregate relationship */
  core_equipments_aggregate: Core_Equipment_Aggregate;
  id: Scalars['bigint'];
  name: Scalars['String'];
};


/** columns and relationships of "core_equipmenttype" */
export type Core_EquipmenttypeCore_EquipmentsArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipment_Order_By>>;
  where?: InputMaybe<Core_Equipment_Bool_Exp>;
};


/** columns and relationships of "core_equipmenttype" */
export type Core_EquipmenttypeCore_Equipments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipment_Order_By>>;
  where?: InputMaybe<Core_Equipment_Bool_Exp>;
};

/** aggregated selection of "core_equipmenttype" */
export type Core_Equipmenttype_Aggregate = {
  __typename?: 'core_equipmenttype_aggregate';
  aggregate?: Maybe<Core_Equipmenttype_Aggregate_Fields>;
  nodes: Array<Core_Equipmenttype>;
};

/** aggregate fields of "core_equipmenttype" */
export type Core_Equipmenttype_Aggregate_Fields = {
  __typename?: 'core_equipmenttype_aggregate_fields';
  avg?: Maybe<Core_Equipmenttype_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Equipmenttype_Max_Fields>;
  min?: Maybe<Core_Equipmenttype_Min_Fields>;
  stddev?: Maybe<Core_Equipmenttype_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Equipmenttype_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Equipmenttype_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Equipmenttype_Sum_Fields>;
  var_pop?: Maybe<Core_Equipmenttype_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Equipmenttype_Var_Samp_Fields>;
  variance?: Maybe<Core_Equipmenttype_Variance_Fields>;
};


/** aggregate fields of "core_equipmenttype" */
export type Core_Equipmenttype_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Equipmenttype_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Core_Equipmenttype_Avg_Fields = {
  __typename?: 'core_equipmenttype_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "core_equipmenttype". All fields are combined with a logical 'AND'. */
export type Core_Equipmenttype_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Equipmenttype_Bool_Exp>>;
  _not?: InputMaybe<Core_Equipmenttype_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Equipmenttype_Bool_Exp>>;
  core_equipments?: InputMaybe<Core_Equipment_Bool_Exp>;
  core_equipments_aggregate?: InputMaybe<Core_Equipment_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_equipmenttype" */
export enum Core_Equipmenttype_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreEquipmenttypePkey = 'core_equipmenttype_pkey'
}

/** input type for incrementing numeric columns in table "core_equipmenttype" */
export type Core_Equipmenttype_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_equipmenttype" */
export type Core_Equipmenttype_Insert_Input = {
  core_equipments?: InputMaybe<Core_Equipment_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Equipmenttype_Max_Fields = {
  __typename?: 'core_equipmenttype_max_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Core_Equipmenttype_Min_Fields = {
  __typename?: 'core_equipmenttype_min_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "core_equipmenttype" */
export type Core_Equipmenttype_Mutation_Response = {
  __typename?: 'core_equipmenttype_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Equipmenttype>;
};

/** input type for inserting object relation for remote table "core_equipmenttype" */
export type Core_Equipmenttype_Obj_Rel_Insert_Input = {
  data: Core_Equipmenttype_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Equipmenttype_On_Conflict>;
};

/** on_conflict condition type for table "core_equipmenttype" */
export type Core_Equipmenttype_On_Conflict = {
  constraint: Core_Equipmenttype_Constraint;
  update_columns?: Array<Core_Equipmenttype_Update_Column>;
  where?: InputMaybe<Core_Equipmenttype_Bool_Exp>;
};

/** Ordering options when selecting data from "core_equipmenttype". */
export type Core_Equipmenttype_Order_By = {
  core_equipments_aggregate?: InputMaybe<Core_Equipment_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_equipmenttype */
export type Core_Equipmenttype_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_equipmenttype" */
export enum Core_Equipmenttype_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "core_equipmenttype" */
export type Core_Equipmenttype_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Equipmenttype_Stddev_Fields = {
  __typename?: 'core_equipmenttype_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Core_Equipmenttype_Stddev_Pop_Fields = {
  __typename?: 'core_equipmenttype_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Core_Equipmenttype_Stddev_Samp_Fields = {
  __typename?: 'core_equipmenttype_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "core_equipmenttype" */
export type Core_Equipmenttype_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Equipmenttype_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Equipmenttype_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Equipmenttype_Sum_Fields = {
  __typename?: 'core_equipmenttype_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "core_equipmenttype" */
export enum Core_Equipmenttype_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Core_Equipmenttype_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Equipmenttype_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Equipmenttype_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Equipmenttype_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Equipmenttype_Var_Pop_Fields = {
  __typename?: 'core_equipmenttype_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Core_Equipmenttype_Var_Samp_Fields = {
  __typename?: 'core_equipmenttype_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Core_Equipmenttype_Variance_Fields = {
  __typename?: 'core_equipmenttype_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "core_esp" */
export type Core_Esp = {
  __typename?: 'core_esp';
  /** An object relationship */
  core_site: Core_Site;
  esp_id: Scalars['String'];
  id: Scalars['bigint'];
  site_id: Scalars['bigint'];
};

/** aggregated selection of "core_esp" */
export type Core_Esp_Aggregate = {
  __typename?: 'core_esp_aggregate';
  aggregate?: Maybe<Core_Esp_Aggregate_Fields>;
  nodes: Array<Core_Esp>;
};

export type Core_Esp_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Esp_Aggregate_Bool_Exp_Count>;
};

export type Core_Esp_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Esp_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Esp_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_esp" */
export type Core_Esp_Aggregate_Fields = {
  __typename?: 'core_esp_aggregate_fields';
  avg?: Maybe<Core_Esp_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Esp_Max_Fields>;
  min?: Maybe<Core_Esp_Min_Fields>;
  stddev?: Maybe<Core_Esp_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Esp_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Esp_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Esp_Sum_Fields>;
  var_pop?: Maybe<Core_Esp_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Esp_Var_Samp_Fields>;
  variance?: Maybe<Core_Esp_Variance_Fields>;
};


/** aggregate fields of "core_esp" */
export type Core_Esp_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Esp_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_esp" */
export type Core_Esp_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Esp_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Esp_Max_Order_By>;
  min?: InputMaybe<Core_Esp_Min_Order_By>;
  stddev?: InputMaybe<Core_Esp_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Esp_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Esp_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Esp_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Esp_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Esp_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Esp_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_esp" */
export type Core_Esp_Arr_Rel_Insert_Input = {
  data: Array<Core_Esp_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Esp_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Esp_Avg_Fields = {
  __typename?: 'core_esp_avg_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_esp" */
export type Core_Esp_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_esp". All fields are combined with a logical 'AND'. */
export type Core_Esp_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Esp_Bool_Exp>>;
  _not?: InputMaybe<Core_Esp_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Esp_Bool_Exp>>;
  core_site?: InputMaybe<Core_Site_Bool_Exp>;
  esp_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  site_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_esp" */
export enum Core_Esp_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreEspPkey = 'core_esp_pkey',
  /** unique or primary key constraint on columns "esp_id", "site_id" */
  CoreEspSiteIdEspIdDbf0f7e9Uniq = 'core_esp_site_id_esp_id_dbf0f7e9_uniq'
}

/** input type for incrementing numeric columns in table "core_esp" */
export type Core_Esp_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_esp" */
export type Core_Esp_Insert_Input = {
  core_site?: InputMaybe<Core_Site_Obj_Rel_Insert_Input>;
  esp_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_Esp_Max_Fields = {
  __typename?: 'core_esp_max_fields';
  esp_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "core_esp" */
export type Core_Esp_Max_Order_By = {
  esp_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Esp_Min_Fields = {
  __typename?: 'core_esp_min_fields';
  esp_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "core_esp" */
export type Core_Esp_Min_Order_By = {
  esp_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_esp" */
export type Core_Esp_Mutation_Response = {
  __typename?: 'core_esp_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Esp>;
};

/** on_conflict condition type for table "core_esp" */
export type Core_Esp_On_Conflict = {
  constraint: Core_Esp_Constraint;
  update_columns?: Array<Core_Esp_Update_Column>;
  where?: InputMaybe<Core_Esp_Bool_Exp>;
};

/** Ordering options when selecting data from "core_esp". */
export type Core_Esp_Order_By = {
  core_site?: InputMaybe<Core_Site_Order_By>;
  esp_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_esp */
export type Core_Esp_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_esp" */
export enum Core_Esp_Select_Column {
  /** column name */
  EspId = 'esp_id',
  /** column name */
  Id = 'id',
  /** column name */
  SiteId = 'site_id'
}

/** input type for updating data in table "core_esp" */
export type Core_Esp_Set_Input = {
  esp_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_Esp_Stddev_Fields = {
  __typename?: 'core_esp_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_esp" */
export type Core_Esp_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Esp_Stddev_Pop_Fields = {
  __typename?: 'core_esp_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_esp" */
export type Core_Esp_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Esp_Stddev_Samp_Fields = {
  __typename?: 'core_esp_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_esp" */
export type Core_Esp_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_esp" */
export type Core_Esp_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Esp_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Esp_Stream_Cursor_Value_Input = {
  esp_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_Esp_Sum_Fields = {
  __typename?: 'core_esp_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_esp" */
export type Core_Esp_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_esp" */
export enum Core_Esp_Update_Column {
  /** column name */
  EspId = 'esp_id',
  /** column name */
  Id = 'id',
  /** column name */
  SiteId = 'site_id'
}

export type Core_Esp_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Esp_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Esp_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Esp_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Esp_Var_Pop_Fields = {
  __typename?: 'core_esp_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_esp" */
export type Core_Esp_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Esp_Var_Samp_Fields = {
  __typename?: 'core_esp_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_esp" */
export type Core_Esp_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Esp_Variance_Fields = {
  __typename?: 'core_esp_variance_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_esp" */
export type Core_Esp_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_espstatus" */
export type Core_Espstatus = {
  __typename?: 'core_espstatus';
  /** An array relationship */
  core_espstatusnextstages: Array<Core_Espstatusnextstages>;
  /** An aggregate relationship */
  core_espstatusnextstages_aggregate: Core_Espstatusnextstages_Aggregate;
  id: Scalars['bigint'];
  scope: Scalars['String'];
  stage: Scalars['Int'];
  stage_updated: Scalars['timestamptz'];
};


/** columns and relationships of "core_espstatus" */
export type Core_EspstatusCore_EspstatusnextstagesArgs = {
  distinct_on?: InputMaybe<Array<Core_Espstatusnextstages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Espstatusnextstages_Order_By>>;
  where?: InputMaybe<Core_Espstatusnextstages_Bool_Exp>;
};


/** columns and relationships of "core_espstatus" */
export type Core_EspstatusCore_Espstatusnextstages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Espstatusnextstages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Espstatusnextstages_Order_By>>;
  where?: InputMaybe<Core_Espstatusnextstages_Bool_Exp>;
};

/** aggregated selection of "core_espstatus" */
export type Core_Espstatus_Aggregate = {
  __typename?: 'core_espstatus_aggregate';
  aggregate?: Maybe<Core_Espstatus_Aggregate_Fields>;
  nodes: Array<Core_Espstatus>;
};

/** aggregate fields of "core_espstatus" */
export type Core_Espstatus_Aggregate_Fields = {
  __typename?: 'core_espstatus_aggregate_fields';
  avg?: Maybe<Core_Espstatus_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Espstatus_Max_Fields>;
  min?: Maybe<Core_Espstatus_Min_Fields>;
  stddev?: Maybe<Core_Espstatus_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Espstatus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Espstatus_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Espstatus_Sum_Fields>;
  var_pop?: Maybe<Core_Espstatus_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Espstatus_Var_Samp_Fields>;
  variance?: Maybe<Core_Espstatus_Variance_Fields>;
};


/** aggregate fields of "core_espstatus" */
export type Core_Espstatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Espstatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Core_Espstatus_Avg_Fields = {
  __typename?: 'core_espstatus_avg_fields';
  id?: Maybe<Scalars['Float']>;
  stage?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "core_espstatus". All fields are combined with a logical 'AND'. */
export type Core_Espstatus_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Espstatus_Bool_Exp>>;
  _not?: InputMaybe<Core_Espstatus_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Espstatus_Bool_Exp>>;
  core_espstatusnextstages?: InputMaybe<Core_Espstatusnextstages_Bool_Exp>;
  core_espstatusnextstages_aggregate?: InputMaybe<Core_Espstatusnextstages_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  scope?: InputMaybe<String_Comparison_Exp>;
  stage?: InputMaybe<Int_Comparison_Exp>;
  stage_updated?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_espstatus" */
export enum Core_Espstatus_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreEspstatusPkey = 'core_espstatus_pkey'
}

/** input type for incrementing numeric columns in table "core_espstatus" */
export type Core_Espstatus_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  stage?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "core_espstatus" */
export type Core_Espstatus_Insert_Input = {
  core_espstatusnextstages?: InputMaybe<Core_Espstatusnextstages_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  scope?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Scalars['Int']>;
  stage_updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Core_Espstatus_Max_Fields = {
  __typename?: 'core_espstatus_max_fields';
  id?: Maybe<Scalars['bigint']>;
  scope?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['Int']>;
  stage_updated?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Core_Espstatus_Min_Fields = {
  __typename?: 'core_espstatus_min_fields';
  id?: Maybe<Scalars['bigint']>;
  scope?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['Int']>;
  stage_updated?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "core_espstatus" */
export type Core_Espstatus_Mutation_Response = {
  __typename?: 'core_espstatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Espstatus>;
};

/** input type for inserting object relation for remote table "core_espstatus" */
export type Core_Espstatus_Obj_Rel_Insert_Input = {
  data: Core_Espstatus_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Espstatus_On_Conflict>;
};

/** on_conflict condition type for table "core_espstatus" */
export type Core_Espstatus_On_Conflict = {
  constraint: Core_Espstatus_Constraint;
  update_columns?: Array<Core_Espstatus_Update_Column>;
  where?: InputMaybe<Core_Espstatus_Bool_Exp>;
};

/** Ordering options when selecting data from "core_espstatus". */
export type Core_Espstatus_Order_By = {
  core_espstatusnextstages_aggregate?: InputMaybe<Core_Espstatusnextstages_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
  stage_updated?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_espstatus */
export type Core_Espstatus_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_espstatus" */
export enum Core_Espstatus_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Scope = 'scope',
  /** column name */
  Stage = 'stage',
  /** column name */
  StageUpdated = 'stage_updated'
}

/** input type for updating data in table "core_espstatus" */
export type Core_Espstatus_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  scope?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Scalars['Int']>;
  stage_updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Core_Espstatus_Stddev_Fields = {
  __typename?: 'core_espstatus_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  stage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Core_Espstatus_Stddev_Pop_Fields = {
  __typename?: 'core_espstatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  stage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Core_Espstatus_Stddev_Samp_Fields = {
  __typename?: 'core_espstatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  stage?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "core_espstatus" */
export type Core_Espstatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Espstatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Espstatus_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  scope?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Scalars['Int']>;
  stage_updated?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Core_Espstatus_Sum_Fields = {
  __typename?: 'core_espstatus_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  stage?: Maybe<Scalars['Int']>;
};

/** update columns of table "core_espstatus" */
export enum Core_Espstatus_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Scope = 'scope',
  /** column name */
  Stage = 'stage',
  /** column name */
  StageUpdated = 'stage_updated'
}

export type Core_Espstatus_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Espstatus_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Espstatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Espstatus_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Espstatus_Var_Pop_Fields = {
  __typename?: 'core_espstatus_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  stage?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Core_Espstatus_Var_Samp_Fields = {
  __typename?: 'core_espstatus_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  stage?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Core_Espstatus_Variance_Fields = {
  __typename?: 'core_espstatus_variance_fields';
  id?: Maybe<Scalars['Float']>;
  stage?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "core_espstatusnextstages" */
export type Core_Espstatusnextstages = {
  __typename?: 'core_espstatusnextstages';
  /** An object relationship */
  core_espstatus: Core_Espstatus;
  esp_status_id: Scalars['bigint'];
  id: Scalars['bigint'];
  stage: Scalars['Int'];
  start_date: Scalars['timestamptz'];
};

/** aggregated selection of "core_espstatusnextstages" */
export type Core_Espstatusnextstages_Aggregate = {
  __typename?: 'core_espstatusnextstages_aggregate';
  aggregate?: Maybe<Core_Espstatusnextstages_Aggregate_Fields>;
  nodes: Array<Core_Espstatusnextstages>;
};

export type Core_Espstatusnextstages_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Espstatusnextstages_Aggregate_Bool_Exp_Count>;
};

export type Core_Espstatusnextstages_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Espstatusnextstages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Espstatusnextstages_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_espstatusnextstages" */
export type Core_Espstatusnextstages_Aggregate_Fields = {
  __typename?: 'core_espstatusnextstages_aggregate_fields';
  avg?: Maybe<Core_Espstatusnextstages_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Espstatusnextstages_Max_Fields>;
  min?: Maybe<Core_Espstatusnextstages_Min_Fields>;
  stddev?: Maybe<Core_Espstatusnextstages_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Espstatusnextstages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Espstatusnextstages_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Espstatusnextstages_Sum_Fields>;
  var_pop?: Maybe<Core_Espstatusnextstages_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Espstatusnextstages_Var_Samp_Fields>;
  variance?: Maybe<Core_Espstatusnextstages_Variance_Fields>;
};


/** aggregate fields of "core_espstatusnextstages" */
export type Core_Espstatusnextstages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Espstatusnextstages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_espstatusnextstages" */
export type Core_Espstatusnextstages_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Espstatusnextstages_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Espstatusnextstages_Max_Order_By>;
  min?: InputMaybe<Core_Espstatusnextstages_Min_Order_By>;
  stddev?: InputMaybe<Core_Espstatusnextstages_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Espstatusnextstages_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Espstatusnextstages_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Espstatusnextstages_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Espstatusnextstages_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Espstatusnextstages_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Espstatusnextstages_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_espstatusnextstages" */
export type Core_Espstatusnextstages_Arr_Rel_Insert_Input = {
  data: Array<Core_Espstatusnextstages_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Espstatusnextstages_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Espstatusnextstages_Avg_Fields = {
  __typename?: 'core_espstatusnextstages_avg_fields';
  esp_status_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stage?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_espstatusnextstages" */
export type Core_Espstatusnextstages_Avg_Order_By = {
  esp_status_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_espstatusnextstages". All fields are combined with a logical 'AND'. */
export type Core_Espstatusnextstages_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Espstatusnextstages_Bool_Exp>>;
  _not?: InputMaybe<Core_Espstatusnextstages_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Espstatusnextstages_Bool_Exp>>;
  core_espstatus?: InputMaybe<Core_Espstatus_Bool_Exp>;
  esp_status_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  stage?: InputMaybe<Int_Comparison_Exp>;
  start_date?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_espstatusnextstages" */
export enum Core_Espstatusnextstages_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreEspstatusnextstagesPkey = 'core_espstatusnextstages_pkey'
}

/** input type for incrementing numeric columns in table "core_espstatusnextstages" */
export type Core_Espstatusnextstages_Inc_Input = {
  esp_status_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  stage?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "core_espstatusnextstages" */
export type Core_Espstatusnextstages_Insert_Input = {
  core_espstatus?: InputMaybe<Core_Espstatus_Obj_Rel_Insert_Input>;
  esp_status_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  stage?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Core_Espstatusnextstages_Max_Fields = {
  __typename?: 'core_espstatusnextstages_max_fields';
  esp_status_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  stage?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "core_espstatusnextstages" */
export type Core_Espstatusnextstages_Max_Order_By = {
  esp_status_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Espstatusnextstages_Min_Fields = {
  __typename?: 'core_espstatusnextstages_min_fields';
  esp_status_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  stage?: Maybe<Scalars['Int']>;
  start_date?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "core_espstatusnextstages" */
export type Core_Espstatusnextstages_Min_Order_By = {
  esp_status_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_espstatusnextstages" */
export type Core_Espstatusnextstages_Mutation_Response = {
  __typename?: 'core_espstatusnextstages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Espstatusnextstages>;
};

/** on_conflict condition type for table "core_espstatusnextstages" */
export type Core_Espstatusnextstages_On_Conflict = {
  constraint: Core_Espstatusnextstages_Constraint;
  update_columns?: Array<Core_Espstatusnextstages_Update_Column>;
  where?: InputMaybe<Core_Espstatusnextstages_Bool_Exp>;
};

/** Ordering options when selecting data from "core_espstatusnextstages". */
export type Core_Espstatusnextstages_Order_By = {
  core_espstatus?: InputMaybe<Core_Espstatus_Order_By>;
  esp_status_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_espstatusnextstages */
export type Core_Espstatusnextstages_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_espstatusnextstages" */
export enum Core_Espstatusnextstages_Select_Column {
  /** column name */
  EspStatusId = 'esp_status_id',
  /** column name */
  Id = 'id',
  /** column name */
  Stage = 'stage',
  /** column name */
  StartDate = 'start_date'
}

/** input type for updating data in table "core_espstatusnextstages" */
export type Core_Espstatusnextstages_Set_Input = {
  esp_status_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  stage?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Core_Espstatusnextstages_Stddev_Fields = {
  __typename?: 'core_espstatusnextstages_stddev_fields';
  esp_status_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stage?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_espstatusnextstages" */
export type Core_Espstatusnextstages_Stddev_Order_By = {
  esp_status_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Espstatusnextstages_Stddev_Pop_Fields = {
  __typename?: 'core_espstatusnextstages_stddev_pop_fields';
  esp_status_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stage?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_espstatusnextstages" */
export type Core_Espstatusnextstages_Stddev_Pop_Order_By = {
  esp_status_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Espstatusnextstages_Stddev_Samp_Fields = {
  __typename?: 'core_espstatusnextstages_stddev_samp_fields';
  esp_status_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stage?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_espstatusnextstages" */
export type Core_Espstatusnextstages_Stddev_Samp_Order_By = {
  esp_status_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_espstatusnextstages" */
export type Core_Espstatusnextstages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Espstatusnextstages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Espstatusnextstages_Stream_Cursor_Value_Input = {
  esp_status_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  stage?: InputMaybe<Scalars['Int']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Core_Espstatusnextstages_Sum_Fields = {
  __typename?: 'core_espstatusnextstages_sum_fields';
  esp_status_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  stage?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "core_espstatusnextstages" */
export type Core_Espstatusnextstages_Sum_Order_By = {
  esp_status_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
};

/** update columns of table "core_espstatusnextstages" */
export enum Core_Espstatusnextstages_Update_Column {
  /** column name */
  EspStatusId = 'esp_status_id',
  /** column name */
  Id = 'id',
  /** column name */
  Stage = 'stage',
  /** column name */
  StartDate = 'start_date'
}

export type Core_Espstatusnextstages_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Espstatusnextstages_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Espstatusnextstages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Espstatusnextstages_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Espstatusnextstages_Var_Pop_Fields = {
  __typename?: 'core_espstatusnextstages_var_pop_fields';
  esp_status_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stage?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_espstatusnextstages" */
export type Core_Espstatusnextstages_Var_Pop_Order_By = {
  esp_status_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Espstatusnextstages_Var_Samp_Fields = {
  __typename?: 'core_espstatusnextstages_var_samp_fields';
  esp_status_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stage?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_espstatusnextstages" */
export type Core_Espstatusnextstages_Var_Samp_Order_By = {
  esp_status_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Espstatusnextstages_Variance_Fields = {
  __typename?: 'core_espstatusnextstages_variance_fields';
  esp_status_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  stage?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_espstatusnextstages" */
export type Core_Espstatusnextstages_Variance_Order_By = {
  esp_status_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_eventlog" */
export type Core_Eventlog = {
  __typename?: 'core_eventlog';
  /** An object relationship */
  core_incident?: Maybe<Core_Incident>;
  /** An array relationship */
  core_incidents: Array<Core_Incident>;
  /** An aggregate relationship */
  core_incidents_aggregate: Core_Incident_Aggregate;
  /** An array relationship */
  core_logs: Array<Core_Log>;
  /** An aggregate relationship */
  core_logs_aggregate: Core_Log_Aggregate;
  /** An object relationship */
  core_rectifieralarm?: Maybe<Core_Rectifieralarm>;
  /** An object relationship */
  core_site: Core_Site;
  created_at: Scalars['timestamptz'];
  event_log_type: Scalars['String'];
  event_name: Scalars['String'];
  event_priority: Scalars['String'];
  id: Scalars['bigint'];
  site_id: Scalars['bigint'];
  source: Scalars['String'];
  viewed_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "core_eventlog" */
export type Core_EventlogCore_IncidentsArgs = {
  distinct_on?: InputMaybe<Array<Core_Incident_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Incident_Order_By>>;
  where?: InputMaybe<Core_Incident_Bool_Exp>;
};


/** columns and relationships of "core_eventlog" */
export type Core_EventlogCore_Incidents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Incident_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Incident_Order_By>>;
  where?: InputMaybe<Core_Incident_Bool_Exp>;
};


/** columns and relationships of "core_eventlog" */
export type Core_EventlogCore_LogsArgs = {
  distinct_on?: InputMaybe<Array<Core_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Log_Order_By>>;
  where?: InputMaybe<Core_Log_Bool_Exp>;
};


/** columns and relationships of "core_eventlog" */
export type Core_EventlogCore_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Log_Order_By>>;
  where?: InputMaybe<Core_Log_Bool_Exp>;
};

/** aggregated selection of "core_eventlog" */
export type Core_Eventlog_Aggregate = {
  __typename?: 'core_eventlog_aggregate';
  aggregate?: Maybe<Core_Eventlog_Aggregate_Fields>;
  nodes: Array<Core_Eventlog>;
};

export type Core_Eventlog_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Eventlog_Aggregate_Bool_Exp_Count>;
};

export type Core_Eventlog_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Eventlog_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Eventlog_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_eventlog" */
export type Core_Eventlog_Aggregate_Fields = {
  __typename?: 'core_eventlog_aggregate_fields';
  avg?: Maybe<Core_Eventlog_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Eventlog_Max_Fields>;
  min?: Maybe<Core_Eventlog_Min_Fields>;
  stddev?: Maybe<Core_Eventlog_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Eventlog_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Eventlog_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Eventlog_Sum_Fields>;
  var_pop?: Maybe<Core_Eventlog_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Eventlog_Var_Samp_Fields>;
  variance?: Maybe<Core_Eventlog_Variance_Fields>;
};


/** aggregate fields of "core_eventlog" */
export type Core_Eventlog_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Eventlog_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_eventlog" */
export type Core_Eventlog_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Eventlog_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Eventlog_Max_Order_By>;
  min?: InputMaybe<Core_Eventlog_Min_Order_By>;
  stddev?: InputMaybe<Core_Eventlog_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Eventlog_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Eventlog_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Eventlog_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Eventlog_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Eventlog_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Eventlog_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_eventlog" */
export type Core_Eventlog_Arr_Rel_Insert_Input = {
  data: Array<Core_Eventlog_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Eventlog_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Eventlog_Avg_Fields = {
  __typename?: 'core_eventlog_avg_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_eventlog" */
export type Core_Eventlog_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_eventlog". All fields are combined with a logical 'AND'. */
export type Core_Eventlog_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Eventlog_Bool_Exp>>;
  _not?: InputMaybe<Core_Eventlog_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Eventlog_Bool_Exp>>;
  core_incident?: InputMaybe<Core_Incident_Bool_Exp>;
  core_incidents?: InputMaybe<Core_Incident_Bool_Exp>;
  core_incidents_aggregate?: InputMaybe<Core_Incident_Aggregate_Bool_Exp>;
  core_logs?: InputMaybe<Core_Log_Bool_Exp>;
  core_logs_aggregate?: InputMaybe<Core_Log_Aggregate_Bool_Exp>;
  core_rectifieralarm?: InputMaybe<Core_Rectifieralarm_Bool_Exp>;
  core_site?: InputMaybe<Core_Site_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_log_type?: InputMaybe<String_Comparison_Exp>;
  event_name?: InputMaybe<String_Comparison_Exp>;
  event_priority?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  site_id?: InputMaybe<Bigint_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  viewed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_eventlog" */
export enum Core_Eventlog_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreEventlogPkey = 'core_eventlog_pkey'
}

/** input type for incrementing numeric columns in table "core_eventlog" */
export type Core_Eventlog_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_eventlog" */
export type Core_Eventlog_Insert_Input = {
  core_incident?: InputMaybe<Core_Incident_Obj_Rel_Insert_Input>;
  core_incidents?: InputMaybe<Core_Incident_Arr_Rel_Insert_Input>;
  core_logs?: InputMaybe<Core_Log_Arr_Rel_Insert_Input>;
  core_rectifieralarm?: InputMaybe<Core_Rectifieralarm_Obj_Rel_Insert_Input>;
  core_site?: InputMaybe<Core_Site_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_log_type?: InputMaybe<Scalars['String']>;
  event_name?: InputMaybe<Scalars['String']>;
  event_priority?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  source?: InputMaybe<Scalars['String']>;
  viewed_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Core_Eventlog_Max_Fields = {
  __typename?: 'core_eventlog_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  event_log_type?: Maybe<Scalars['String']>;
  event_name?: Maybe<Scalars['String']>;
  event_priority?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
  source?: Maybe<Scalars['String']>;
  viewed_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "core_eventlog" */
export type Core_Eventlog_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  event_log_type?: InputMaybe<Order_By>;
  event_name?: InputMaybe<Order_By>;
  event_priority?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  viewed_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Eventlog_Min_Fields = {
  __typename?: 'core_eventlog_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  event_log_type?: Maybe<Scalars['String']>;
  event_name?: Maybe<Scalars['String']>;
  event_priority?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
  source?: Maybe<Scalars['String']>;
  viewed_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "core_eventlog" */
export type Core_Eventlog_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  event_log_type?: InputMaybe<Order_By>;
  event_name?: InputMaybe<Order_By>;
  event_priority?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  viewed_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_eventlog" */
export type Core_Eventlog_Mutation_Response = {
  __typename?: 'core_eventlog_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Eventlog>;
};

/** input type for inserting object relation for remote table "core_eventlog" */
export type Core_Eventlog_Obj_Rel_Insert_Input = {
  data: Core_Eventlog_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Eventlog_On_Conflict>;
};

/** on_conflict condition type for table "core_eventlog" */
export type Core_Eventlog_On_Conflict = {
  constraint: Core_Eventlog_Constraint;
  update_columns?: Array<Core_Eventlog_Update_Column>;
  where?: InputMaybe<Core_Eventlog_Bool_Exp>;
};

/** Ordering options when selecting data from "core_eventlog". */
export type Core_Eventlog_Order_By = {
  core_incident?: InputMaybe<Core_Incident_Order_By>;
  core_incidents_aggregate?: InputMaybe<Core_Incident_Aggregate_Order_By>;
  core_logs_aggregate?: InputMaybe<Core_Log_Aggregate_Order_By>;
  core_rectifieralarm?: InputMaybe<Core_Rectifieralarm_Order_By>;
  core_site?: InputMaybe<Core_Site_Order_By>;
  created_at?: InputMaybe<Order_By>;
  event_log_type?: InputMaybe<Order_By>;
  event_name?: InputMaybe<Order_By>;
  event_priority?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  viewed_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_eventlog */
export type Core_Eventlog_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_eventlog" */
export enum Core_Eventlog_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventLogType = 'event_log_type',
  /** column name */
  EventName = 'event_name',
  /** column name */
  EventPriority = 'event_priority',
  /** column name */
  Id = 'id',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  Source = 'source',
  /** column name */
  ViewedAt = 'viewed_at'
}

/** input type for updating data in table "core_eventlog" */
export type Core_Eventlog_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_log_type?: InputMaybe<Scalars['String']>;
  event_name?: InputMaybe<Scalars['String']>;
  event_priority?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  source?: InputMaybe<Scalars['String']>;
  viewed_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Core_Eventlog_Stddev_Fields = {
  __typename?: 'core_eventlog_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_eventlog" */
export type Core_Eventlog_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Eventlog_Stddev_Pop_Fields = {
  __typename?: 'core_eventlog_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_eventlog" */
export type Core_Eventlog_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Eventlog_Stddev_Samp_Fields = {
  __typename?: 'core_eventlog_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_eventlog" */
export type Core_Eventlog_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_eventlog" */
export type Core_Eventlog_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Eventlog_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Eventlog_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_log_type?: InputMaybe<Scalars['String']>;
  event_name?: InputMaybe<Scalars['String']>;
  event_priority?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  source?: InputMaybe<Scalars['String']>;
  viewed_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Core_Eventlog_Sum_Fields = {
  __typename?: 'core_eventlog_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_eventlog" */
export type Core_Eventlog_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_eventlog" */
export enum Core_Eventlog_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventLogType = 'event_log_type',
  /** column name */
  EventName = 'event_name',
  /** column name */
  EventPriority = 'event_priority',
  /** column name */
  Id = 'id',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  Source = 'source',
  /** column name */
  ViewedAt = 'viewed_at'
}

export type Core_Eventlog_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Eventlog_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Eventlog_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Eventlog_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Eventlog_Var_Pop_Fields = {
  __typename?: 'core_eventlog_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_eventlog" */
export type Core_Eventlog_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Eventlog_Var_Samp_Fields = {
  __typename?: 'core_eventlog_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_eventlog" */
export type Core_Eventlog_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Eventlog_Variance_Fields = {
  __typename?: 'core_eventlog_variance_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_eventlog" */
export type Core_Eventlog_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_incident" */
export type Core_Incident = {
  __typename?: 'core_incident';
  /** An object relationship */
  core_eventlog?: Maybe<Core_Eventlog>;
  /** An array relationship */
  core_logfacialrecognitions: Array<Core_Logfacialrecognition>;
  /** An aggregate relationship */
  core_logfacialrecognitions_aggregate: Core_Logfacialrecognition_Aggregate;
  /** An array relationship */
  core_logsuspiciosactivities: Array<Core_Logsuspiciosactivity>;
  /** An aggregate relationship */
  core_logsuspiciosactivities_aggregate: Core_Logsuspiciosactivity_Aggregate;
  /** An object relationship */
  core_site: Core_Site;
  created_on: Scalars['timestamptz'];
  event_log_id?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  site_id: Scalars['bigint'];
};


/** columns and relationships of "core_incident" */
export type Core_IncidentCore_LogfacialrecognitionsArgs = {
  distinct_on?: InputMaybe<Array<Core_Logfacialrecognition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logfacialrecognition_Order_By>>;
  where?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
};


/** columns and relationships of "core_incident" */
export type Core_IncidentCore_Logfacialrecognitions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Logfacialrecognition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logfacialrecognition_Order_By>>;
  where?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
};


/** columns and relationships of "core_incident" */
export type Core_IncidentCore_LogsuspiciosactivitiesArgs = {
  distinct_on?: InputMaybe<Array<Core_Logsuspiciosactivity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logsuspiciosactivity_Order_By>>;
  where?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
};


/** columns and relationships of "core_incident" */
export type Core_IncidentCore_Logsuspiciosactivities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Logsuspiciosactivity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logsuspiciosactivity_Order_By>>;
  where?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
};

/** aggregated selection of "core_incident" */
export type Core_Incident_Aggregate = {
  __typename?: 'core_incident_aggregate';
  aggregate?: Maybe<Core_Incident_Aggregate_Fields>;
  nodes: Array<Core_Incident>;
};

export type Core_Incident_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Incident_Aggregate_Bool_Exp_Count>;
};

export type Core_Incident_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Incident_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Incident_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_incident" */
export type Core_Incident_Aggregate_Fields = {
  __typename?: 'core_incident_aggregate_fields';
  avg?: Maybe<Core_Incident_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Incident_Max_Fields>;
  min?: Maybe<Core_Incident_Min_Fields>;
  stddev?: Maybe<Core_Incident_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Incident_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Incident_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Incident_Sum_Fields>;
  var_pop?: Maybe<Core_Incident_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Incident_Var_Samp_Fields>;
  variance?: Maybe<Core_Incident_Variance_Fields>;
};


/** aggregate fields of "core_incident" */
export type Core_Incident_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Incident_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_incident" */
export type Core_Incident_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Incident_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Incident_Max_Order_By>;
  min?: InputMaybe<Core_Incident_Min_Order_By>;
  stddev?: InputMaybe<Core_Incident_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Incident_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Incident_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Incident_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Incident_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Incident_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Incident_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_incident" */
export type Core_Incident_Arr_Rel_Insert_Input = {
  data: Array<Core_Incident_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Incident_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Incident_Avg_Fields = {
  __typename?: 'core_incident_avg_fields';
  event_log_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_incident" */
export type Core_Incident_Avg_Order_By = {
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_incident". All fields are combined with a logical 'AND'. */
export type Core_Incident_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Incident_Bool_Exp>>;
  _not?: InputMaybe<Core_Incident_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Incident_Bool_Exp>>;
  core_eventlog?: InputMaybe<Core_Eventlog_Bool_Exp>;
  core_logfacialrecognitions?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
  core_logfacialrecognitions_aggregate?: InputMaybe<Core_Logfacialrecognition_Aggregate_Bool_Exp>;
  core_logsuspiciosactivities?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
  core_logsuspiciosactivities_aggregate?: InputMaybe<Core_Logsuspiciosactivity_Aggregate_Bool_Exp>;
  core_site?: InputMaybe<Core_Site_Bool_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_log_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  site_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_incident" */
export enum Core_Incident_Constraint {
  /** unique or primary key constraint on columns "event_log_id" */
  CoreIncidentEventLogIdE817890eUniq = 'core_incident_event_log_id_e817890e_uniq',
  /** unique or primary key constraint on columns "id" */
  CoreIncidentPkey = 'core_incident_pkey'
}

/** input type for incrementing numeric columns in table "core_incident" */
export type Core_Incident_Inc_Input = {
  event_log_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_incident" */
export type Core_Incident_Insert_Input = {
  core_eventlog?: InputMaybe<Core_Eventlog_Obj_Rel_Insert_Input>;
  core_logfacialrecognitions?: InputMaybe<Core_Logfacialrecognition_Arr_Rel_Insert_Input>;
  core_logsuspiciosactivities?: InputMaybe<Core_Logsuspiciosactivity_Arr_Rel_Insert_Input>;
  core_site?: InputMaybe<Core_Site_Obj_Rel_Insert_Input>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  event_log_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_Incident_Max_Fields = {
  __typename?: 'core_incident_max_fields';
  created_on?: Maybe<Scalars['timestamptz']>;
  event_log_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "core_incident" */
export type Core_Incident_Max_Order_By = {
  created_on?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Incident_Min_Fields = {
  __typename?: 'core_incident_min_fields';
  created_on?: Maybe<Scalars['timestamptz']>;
  event_log_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "core_incident" */
export type Core_Incident_Min_Order_By = {
  created_on?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_incident" */
export type Core_Incident_Mutation_Response = {
  __typename?: 'core_incident_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Incident>;
};

/** input type for inserting object relation for remote table "core_incident" */
export type Core_Incident_Obj_Rel_Insert_Input = {
  data: Core_Incident_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Incident_On_Conflict>;
};

/** on_conflict condition type for table "core_incident" */
export type Core_Incident_On_Conflict = {
  constraint: Core_Incident_Constraint;
  update_columns?: Array<Core_Incident_Update_Column>;
  where?: InputMaybe<Core_Incident_Bool_Exp>;
};

/** Ordering options when selecting data from "core_incident". */
export type Core_Incident_Order_By = {
  core_eventlog?: InputMaybe<Core_Eventlog_Order_By>;
  core_logfacialrecognitions_aggregate?: InputMaybe<Core_Logfacialrecognition_Aggregate_Order_By>;
  core_logsuspiciosactivities_aggregate?: InputMaybe<Core_Logsuspiciosactivity_Aggregate_Order_By>;
  core_site?: InputMaybe<Core_Site_Order_By>;
  created_on?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_incident */
export type Core_Incident_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_incident" */
export enum Core_Incident_Select_Column {
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  EventLogId = 'event_log_id',
  /** column name */
  Id = 'id',
  /** column name */
  SiteId = 'site_id'
}

/** input type for updating data in table "core_incident" */
export type Core_Incident_Set_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  event_log_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_Incident_Stddev_Fields = {
  __typename?: 'core_incident_stddev_fields';
  event_log_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_incident" */
export type Core_Incident_Stddev_Order_By = {
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Incident_Stddev_Pop_Fields = {
  __typename?: 'core_incident_stddev_pop_fields';
  event_log_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_incident" */
export type Core_Incident_Stddev_Pop_Order_By = {
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Incident_Stddev_Samp_Fields = {
  __typename?: 'core_incident_stddev_samp_fields';
  event_log_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_incident" */
export type Core_Incident_Stddev_Samp_Order_By = {
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_incident" */
export type Core_Incident_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Incident_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Incident_Stream_Cursor_Value_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  event_log_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_Incident_Sum_Fields = {
  __typename?: 'core_incident_sum_fields';
  event_log_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_incident" */
export type Core_Incident_Sum_Order_By = {
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_incident" */
export enum Core_Incident_Update_Column {
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  EventLogId = 'event_log_id',
  /** column name */
  Id = 'id',
  /** column name */
  SiteId = 'site_id'
}

export type Core_Incident_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Incident_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Incident_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Incident_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Incident_Var_Pop_Fields = {
  __typename?: 'core_incident_var_pop_fields';
  event_log_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_incident" */
export type Core_Incident_Var_Pop_Order_By = {
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Incident_Var_Samp_Fields = {
  __typename?: 'core_incident_var_samp_fields';
  event_log_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_incident" */
export type Core_Incident_Var_Samp_Order_By = {
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Incident_Variance_Fields = {
  __typename?: 'core_incident_variance_fields';
  event_log_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_incident" */
export type Core_Incident_Variance_Order_By = {
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_job" */
export type Core_Job = {
  __typename?: 'core_job';
  /** An array relationship */
  core_jobstatuses: Array<Core_Jobstatus>;
  /** An aggregate relationship */
  core_jobstatuses_aggregate: Core_Jobstatus_Aggregate;
  /** An array relationship */
  core_personnelassigneds: Array<Core_Personnelassigned>;
  /** An aggregate relationship */
  core_personnelassigneds_aggregate: Core_Personnelassigned_Aggregate;
  /** An object relationship */
  core_resjobstatus?: Maybe<Core_Resjobstatus>;
  /** An object relationship */
  core_site: Core_Site;
  /** An array relationship */
  core_visits: Array<Core_Visit>;
  /** An aggregate relationship */
  core_visits_aggregate: Core_Visit_Aggregate;
  created_on: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  ext_job_number: Scalars['String'];
  id: Scalars['bigint'];
  site_id: Scalars['bigint'];
};


/** columns and relationships of "core_job" */
export type Core_JobCore_JobstatusesArgs = {
  distinct_on?: InputMaybe<Array<Core_Jobstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Jobstatus_Order_By>>;
  where?: InputMaybe<Core_Jobstatus_Bool_Exp>;
};


/** columns and relationships of "core_job" */
export type Core_JobCore_Jobstatuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Jobstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Jobstatus_Order_By>>;
  where?: InputMaybe<Core_Jobstatus_Bool_Exp>;
};


/** columns and relationships of "core_job" */
export type Core_JobCore_PersonnelassignedsArgs = {
  distinct_on?: InputMaybe<Array<Core_Personnelassigned_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Personnelassigned_Order_By>>;
  where?: InputMaybe<Core_Personnelassigned_Bool_Exp>;
};


/** columns and relationships of "core_job" */
export type Core_JobCore_Personnelassigneds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Personnelassigned_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Personnelassigned_Order_By>>;
  where?: InputMaybe<Core_Personnelassigned_Bool_Exp>;
};


/** columns and relationships of "core_job" */
export type Core_JobCore_VisitsArgs = {
  distinct_on?: InputMaybe<Array<Core_Visit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Visit_Order_By>>;
  where?: InputMaybe<Core_Visit_Bool_Exp>;
};


/** columns and relationships of "core_job" */
export type Core_JobCore_Visits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Visit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Visit_Order_By>>;
  where?: InputMaybe<Core_Visit_Bool_Exp>;
};

/** aggregated selection of "core_job" */
export type Core_Job_Aggregate = {
  __typename?: 'core_job_aggregate';
  aggregate?: Maybe<Core_Job_Aggregate_Fields>;
  nodes: Array<Core_Job>;
};

export type Core_Job_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Job_Aggregate_Bool_Exp_Count>;
};

export type Core_Job_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Job_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_job" */
export type Core_Job_Aggregate_Fields = {
  __typename?: 'core_job_aggregate_fields';
  avg?: Maybe<Core_Job_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Job_Max_Fields>;
  min?: Maybe<Core_Job_Min_Fields>;
  stddev?: Maybe<Core_Job_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Job_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Job_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Job_Sum_Fields>;
  var_pop?: Maybe<Core_Job_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Job_Var_Samp_Fields>;
  variance?: Maybe<Core_Job_Variance_Fields>;
};


/** aggregate fields of "core_job" */
export type Core_Job_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_job" */
export type Core_Job_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Job_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Job_Max_Order_By>;
  min?: InputMaybe<Core_Job_Min_Order_By>;
  stddev?: InputMaybe<Core_Job_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Job_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Job_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Job_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Job_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Job_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Job_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_job" */
export type Core_Job_Arr_Rel_Insert_Input = {
  data: Array<Core_Job_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Job_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Job_Avg_Fields = {
  __typename?: 'core_job_avg_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_job" */
export type Core_Job_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_job". All fields are combined with a logical 'AND'. */
export type Core_Job_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Job_Bool_Exp>>;
  _not?: InputMaybe<Core_Job_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Job_Bool_Exp>>;
  core_jobstatuses?: InputMaybe<Core_Jobstatus_Bool_Exp>;
  core_jobstatuses_aggregate?: InputMaybe<Core_Jobstatus_Aggregate_Bool_Exp>;
  core_personnelassigneds?: InputMaybe<Core_Personnelassigned_Bool_Exp>;
  core_personnelassigneds_aggregate?: InputMaybe<Core_Personnelassigned_Aggregate_Bool_Exp>;
  core_resjobstatus?: InputMaybe<Core_Resjobstatus_Bool_Exp>;
  core_site?: InputMaybe<Core_Site_Bool_Exp>;
  core_visits?: InputMaybe<Core_Visit_Bool_Exp>;
  core_visits_aggregate?: InputMaybe<Core_Visit_Aggregate_Bool_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  ext_job_number?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  site_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_job" */
export enum Core_Job_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreJobPkey = 'core_job_pkey'
}

/** input type for incrementing numeric columns in table "core_job" */
export type Core_Job_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_job" */
export type Core_Job_Insert_Input = {
  core_jobstatuses?: InputMaybe<Core_Jobstatus_Arr_Rel_Insert_Input>;
  core_personnelassigneds?: InputMaybe<Core_Personnelassigned_Arr_Rel_Insert_Input>;
  core_resjobstatus?: InputMaybe<Core_Resjobstatus_Obj_Rel_Insert_Input>;
  core_site?: InputMaybe<Core_Site_Obj_Rel_Insert_Input>;
  core_visits?: InputMaybe<Core_Visit_Arr_Rel_Insert_Input>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  ext_job_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_Job_Max_Fields = {
  __typename?: 'core_job_max_fields';
  created_on?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  ext_job_number?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "core_job" */
export type Core_Job_Max_Order_By = {
  created_on?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  ext_job_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Job_Min_Fields = {
  __typename?: 'core_job_min_fields';
  created_on?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  ext_job_number?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "core_job" */
export type Core_Job_Min_Order_By = {
  created_on?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  ext_job_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_job" */
export type Core_Job_Mutation_Response = {
  __typename?: 'core_job_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Job>;
};

/** input type for inserting object relation for remote table "core_job" */
export type Core_Job_Obj_Rel_Insert_Input = {
  data: Core_Job_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Job_On_Conflict>;
};

/** on_conflict condition type for table "core_job" */
export type Core_Job_On_Conflict = {
  constraint: Core_Job_Constraint;
  update_columns?: Array<Core_Job_Update_Column>;
  where?: InputMaybe<Core_Job_Bool_Exp>;
};

/** Ordering options when selecting data from "core_job". */
export type Core_Job_Order_By = {
  core_jobstatuses_aggregate?: InputMaybe<Core_Jobstatus_Aggregate_Order_By>;
  core_personnelassigneds_aggregate?: InputMaybe<Core_Personnelassigned_Aggregate_Order_By>;
  core_resjobstatus?: InputMaybe<Core_Resjobstatus_Order_By>;
  core_site?: InputMaybe<Core_Site_Order_By>;
  core_visits_aggregate?: InputMaybe<Core_Visit_Aggregate_Order_By>;
  created_on?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  ext_job_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_job */
export type Core_Job_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_job" */
export enum Core_Job_Select_Column {
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Description = 'description',
  /** column name */
  ExtJobNumber = 'ext_job_number',
  /** column name */
  Id = 'id',
  /** column name */
  SiteId = 'site_id'
}

/** input type for updating data in table "core_job" */
export type Core_Job_Set_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  ext_job_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_Job_Stddev_Fields = {
  __typename?: 'core_job_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_job" */
export type Core_Job_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Job_Stddev_Pop_Fields = {
  __typename?: 'core_job_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_job" */
export type Core_Job_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Job_Stddev_Samp_Fields = {
  __typename?: 'core_job_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_job" */
export type Core_Job_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_job" */
export type Core_Job_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Job_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Job_Stream_Cursor_Value_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  ext_job_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_Job_Sum_Fields = {
  __typename?: 'core_job_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_job" */
export type Core_Job_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_job" */
export enum Core_Job_Update_Column {
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Description = 'description',
  /** column name */
  ExtJobNumber = 'ext_job_number',
  /** column name */
  Id = 'id',
  /** column name */
  SiteId = 'site_id'
}

export type Core_Job_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Job_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Job_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Job_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Job_Var_Pop_Fields = {
  __typename?: 'core_job_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_job" */
export type Core_Job_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Job_Var_Samp_Fields = {
  __typename?: 'core_job_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_job" */
export type Core_Job_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Job_Variance_Fields = {
  __typename?: 'core_job_variance_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_job" */
export type Core_Job_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_jobstatus" */
export type Core_Jobstatus = {
  __typename?: 'core_jobstatus';
  comment?: Maybe<Scalars['String']>;
  /** An object relationship */
  core_job: Core_Job;
  /** An object relationship */
  core_jobstatusenum: Core_Jobstatusenum;
  created_on: Scalars['timestamptz'];
  id: Scalars['bigint'];
  job_id: Scalars['bigint'];
  status_id: Scalars['bigint'];
};

/** aggregated selection of "core_jobstatus" */
export type Core_Jobstatus_Aggregate = {
  __typename?: 'core_jobstatus_aggregate';
  aggregate?: Maybe<Core_Jobstatus_Aggregate_Fields>;
  nodes: Array<Core_Jobstatus>;
};

export type Core_Jobstatus_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Jobstatus_Aggregate_Bool_Exp_Count>;
};

export type Core_Jobstatus_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Jobstatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Jobstatus_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_jobstatus" */
export type Core_Jobstatus_Aggregate_Fields = {
  __typename?: 'core_jobstatus_aggregate_fields';
  avg?: Maybe<Core_Jobstatus_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Jobstatus_Max_Fields>;
  min?: Maybe<Core_Jobstatus_Min_Fields>;
  stddev?: Maybe<Core_Jobstatus_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Jobstatus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Jobstatus_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Jobstatus_Sum_Fields>;
  var_pop?: Maybe<Core_Jobstatus_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Jobstatus_Var_Samp_Fields>;
  variance?: Maybe<Core_Jobstatus_Variance_Fields>;
};


/** aggregate fields of "core_jobstatus" */
export type Core_Jobstatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Jobstatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_jobstatus" */
export type Core_Jobstatus_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Jobstatus_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Jobstatus_Max_Order_By>;
  min?: InputMaybe<Core_Jobstatus_Min_Order_By>;
  stddev?: InputMaybe<Core_Jobstatus_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Jobstatus_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Jobstatus_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Jobstatus_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Jobstatus_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Jobstatus_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Jobstatus_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_jobstatus" */
export type Core_Jobstatus_Arr_Rel_Insert_Input = {
  data: Array<Core_Jobstatus_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Jobstatus_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Jobstatus_Avg_Fields = {
  __typename?: 'core_jobstatus_avg_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_jobstatus" */
export type Core_Jobstatus_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_jobstatus". All fields are combined with a logical 'AND'. */
export type Core_Jobstatus_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Jobstatus_Bool_Exp>>;
  _not?: InputMaybe<Core_Jobstatus_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Jobstatus_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  core_job?: InputMaybe<Core_Job_Bool_Exp>;
  core_jobstatusenum?: InputMaybe<Core_Jobstatusenum_Bool_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  job_id?: InputMaybe<Bigint_Comparison_Exp>;
  status_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_jobstatus" */
export enum Core_Jobstatus_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreJobstatusIdIdx = 'core_jobstatus_id_idx',
  /** unique or primary key constraint on columns "id" */
  CoreJobstatusPkey = 'core_jobstatus_pkey'
}

/** input type for incrementing numeric columns in table "core_jobstatus" */
export type Core_Jobstatus_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  job_id?: InputMaybe<Scalars['bigint']>;
  status_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_jobstatus" */
export type Core_Jobstatus_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  core_job?: InputMaybe<Core_Job_Obj_Rel_Insert_Input>;
  core_jobstatusenum?: InputMaybe<Core_Jobstatusenum_Obj_Rel_Insert_Input>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  job_id?: InputMaybe<Scalars['bigint']>;
  status_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_Jobstatus_Max_Fields = {
  __typename?: 'core_jobstatus_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  job_id?: Maybe<Scalars['bigint']>;
  status_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "core_jobstatus" */
export type Core_Jobstatus_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Jobstatus_Min_Fields = {
  __typename?: 'core_jobstatus_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  job_id?: Maybe<Scalars['bigint']>;
  status_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "core_jobstatus" */
export type Core_Jobstatus_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_jobstatus" */
export type Core_Jobstatus_Mutation_Response = {
  __typename?: 'core_jobstatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Jobstatus>;
};

/** on_conflict condition type for table "core_jobstatus" */
export type Core_Jobstatus_On_Conflict = {
  constraint: Core_Jobstatus_Constraint;
  update_columns?: Array<Core_Jobstatus_Update_Column>;
  where?: InputMaybe<Core_Jobstatus_Bool_Exp>;
};

/** Ordering options when selecting data from "core_jobstatus". */
export type Core_Jobstatus_Order_By = {
  comment?: InputMaybe<Order_By>;
  core_job?: InputMaybe<Core_Job_Order_By>;
  core_jobstatusenum?: InputMaybe<Core_Jobstatusenum_Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_jobstatus */
export type Core_Jobstatus_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_jobstatus" */
export enum Core_Jobstatus_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  StatusId = 'status_id'
}

/** input type for updating data in table "core_jobstatus" */
export type Core_Jobstatus_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  job_id?: InputMaybe<Scalars['bigint']>;
  status_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_Jobstatus_Stddev_Fields = {
  __typename?: 'core_jobstatus_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_jobstatus" */
export type Core_Jobstatus_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Jobstatus_Stddev_Pop_Fields = {
  __typename?: 'core_jobstatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_jobstatus" */
export type Core_Jobstatus_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Jobstatus_Stddev_Samp_Fields = {
  __typename?: 'core_jobstatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_jobstatus" */
export type Core_Jobstatus_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_jobstatus" */
export type Core_Jobstatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Jobstatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Jobstatus_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  job_id?: InputMaybe<Scalars['bigint']>;
  status_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_Jobstatus_Sum_Fields = {
  __typename?: 'core_jobstatus_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  job_id?: Maybe<Scalars['bigint']>;
  status_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_jobstatus" */
export type Core_Jobstatus_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_jobstatus" */
export enum Core_Jobstatus_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  StatusId = 'status_id'
}

export type Core_Jobstatus_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Jobstatus_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Jobstatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Jobstatus_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Jobstatus_Var_Pop_Fields = {
  __typename?: 'core_jobstatus_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_jobstatus" */
export type Core_Jobstatus_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Jobstatus_Var_Samp_Fields = {
  __typename?: 'core_jobstatus_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_jobstatus" */
export type Core_Jobstatus_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Jobstatus_Variance_Fields = {
  __typename?: 'core_jobstatus_variance_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_jobstatus" */
export type Core_Jobstatus_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_jobstatusenum" */
export type Core_Jobstatusenum = {
  __typename?: 'core_jobstatusenum';
  /** An array relationship */
  core_jobstatuses: Array<Core_Jobstatus>;
  /** An aggregate relationship */
  core_jobstatuses_aggregate: Core_Jobstatus_Aggregate;
  id: Scalars['bigint'];
  status: Scalars['String'];
};


/** columns and relationships of "core_jobstatusenum" */
export type Core_JobstatusenumCore_JobstatusesArgs = {
  distinct_on?: InputMaybe<Array<Core_Jobstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Jobstatus_Order_By>>;
  where?: InputMaybe<Core_Jobstatus_Bool_Exp>;
};


/** columns and relationships of "core_jobstatusenum" */
export type Core_JobstatusenumCore_Jobstatuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Jobstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Jobstatus_Order_By>>;
  where?: InputMaybe<Core_Jobstatus_Bool_Exp>;
};

/** aggregated selection of "core_jobstatusenum" */
export type Core_Jobstatusenum_Aggregate = {
  __typename?: 'core_jobstatusenum_aggregate';
  aggregate?: Maybe<Core_Jobstatusenum_Aggregate_Fields>;
  nodes: Array<Core_Jobstatusenum>;
};

/** aggregate fields of "core_jobstatusenum" */
export type Core_Jobstatusenum_Aggregate_Fields = {
  __typename?: 'core_jobstatusenum_aggregate_fields';
  avg?: Maybe<Core_Jobstatusenum_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Jobstatusenum_Max_Fields>;
  min?: Maybe<Core_Jobstatusenum_Min_Fields>;
  stddev?: Maybe<Core_Jobstatusenum_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Jobstatusenum_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Jobstatusenum_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Jobstatusenum_Sum_Fields>;
  var_pop?: Maybe<Core_Jobstatusenum_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Jobstatusenum_Var_Samp_Fields>;
  variance?: Maybe<Core_Jobstatusenum_Variance_Fields>;
};


/** aggregate fields of "core_jobstatusenum" */
export type Core_Jobstatusenum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Jobstatusenum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Core_Jobstatusenum_Avg_Fields = {
  __typename?: 'core_jobstatusenum_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "core_jobstatusenum". All fields are combined with a logical 'AND'. */
export type Core_Jobstatusenum_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Jobstatusenum_Bool_Exp>>;
  _not?: InputMaybe<Core_Jobstatusenum_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Jobstatusenum_Bool_Exp>>;
  core_jobstatuses?: InputMaybe<Core_Jobstatus_Bool_Exp>;
  core_jobstatuses_aggregate?: InputMaybe<Core_Jobstatus_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_jobstatusenum" */
export enum Core_Jobstatusenum_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreJobstatusenumPkey = 'core_jobstatusenum_pkey'
}

/** input type for incrementing numeric columns in table "core_jobstatusenum" */
export type Core_Jobstatusenum_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_jobstatusenum" */
export type Core_Jobstatusenum_Insert_Input = {
  core_jobstatuses?: InputMaybe<Core_Jobstatus_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Jobstatusenum_Max_Fields = {
  __typename?: 'core_jobstatusenum_max_fields';
  id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Core_Jobstatusenum_Min_Fields = {
  __typename?: 'core_jobstatusenum_min_fields';
  id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "core_jobstatusenum" */
export type Core_Jobstatusenum_Mutation_Response = {
  __typename?: 'core_jobstatusenum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Jobstatusenum>;
};

/** input type for inserting object relation for remote table "core_jobstatusenum" */
export type Core_Jobstatusenum_Obj_Rel_Insert_Input = {
  data: Core_Jobstatusenum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Jobstatusenum_On_Conflict>;
};

/** on_conflict condition type for table "core_jobstatusenum" */
export type Core_Jobstatusenum_On_Conflict = {
  constraint: Core_Jobstatusenum_Constraint;
  update_columns?: Array<Core_Jobstatusenum_Update_Column>;
  where?: InputMaybe<Core_Jobstatusenum_Bool_Exp>;
};

/** Ordering options when selecting data from "core_jobstatusenum". */
export type Core_Jobstatusenum_Order_By = {
  core_jobstatuses_aggregate?: InputMaybe<Core_Jobstatus_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_jobstatusenum */
export type Core_Jobstatusenum_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_jobstatusenum" */
export enum Core_Jobstatusenum_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "core_jobstatusenum" */
export type Core_Jobstatusenum_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Jobstatusenum_Stddev_Fields = {
  __typename?: 'core_jobstatusenum_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Core_Jobstatusenum_Stddev_Pop_Fields = {
  __typename?: 'core_jobstatusenum_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Core_Jobstatusenum_Stddev_Samp_Fields = {
  __typename?: 'core_jobstatusenum_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "core_jobstatusenum" */
export type Core_Jobstatusenum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Jobstatusenum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Jobstatusenum_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Jobstatusenum_Sum_Fields = {
  __typename?: 'core_jobstatusenum_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "core_jobstatusenum" */
export enum Core_Jobstatusenum_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status'
}

export type Core_Jobstatusenum_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Jobstatusenum_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Jobstatusenum_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Jobstatusenum_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Jobstatusenum_Var_Pop_Fields = {
  __typename?: 'core_jobstatusenum_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Core_Jobstatusenum_Var_Samp_Fields = {
  __typename?: 'core_jobstatusenum_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Core_Jobstatusenum_Variance_Fields = {
  __typename?: 'core_jobstatusenum_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "core_log" */
export type Core_Log = {
  __typename?: 'core_log';
  authorised: Scalars['Boolean'];
  camera_id: Scalars['bigint'];
  /** An object relationship */
  core_eventlog: Core_Eventlog;
  /** An array relationship */
  core_logimages: Array<Core_Logimage>;
  /** An aggregate relationship */
  core_logimages_aggregate: Core_Logimage_Aggregate;
  date_recorded: Scalars['timestamptz'];
  event_log_id: Scalars['bigint'];
  id: Scalars['bigint'];
  log_type: Scalars['String'];
  pic: Scalars['String'];
};


/** columns and relationships of "core_log" */
export type Core_LogCore_LogimagesArgs = {
  distinct_on?: InputMaybe<Array<Core_Logimage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logimage_Order_By>>;
  where?: InputMaybe<Core_Logimage_Bool_Exp>;
};


/** columns and relationships of "core_log" */
export type Core_LogCore_Logimages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Logimage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logimage_Order_By>>;
  where?: InputMaybe<Core_Logimage_Bool_Exp>;
};

/** aggregated selection of "core_log" */
export type Core_Log_Aggregate = {
  __typename?: 'core_log_aggregate';
  aggregate?: Maybe<Core_Log_Aggregate_Fields>;
  nodes: Array<Core_Log>;
};

export type Core_Log_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Core_Log_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Core_Log_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Core_Log_Aggregate_Bool_Exp_Count>;
};

export type Core_Log_Aggregate_Bool_Exp_Bool_And = {
  arguments: Core_Log_Select_Column_Core_Log_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Log_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Core_Log_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Core_Log_Select_Column_Core_Log_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Log_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Core_Log_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Log_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_log" */
export type Core_Log_Aggregate_Fields = {
  __typename?: 'core_log_aggregate_fields';
  avg?: Maybe<Core_Log_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Log_Max_Fields>;
  min?: Maybe<Core_Log_Min_Fields>;
  stddev?: Maybe<Core_Log_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Log_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Log_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Log_Sum_Fields>;
  var_pop?: Maybe<Core_Log_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Log_Var_Samp_Fields>;
  variance?: Maybe<Core_Log_Variance_Fields>;
};


/** aggregate fields of "core_log" */
export type Core_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_log" */
export type Core_Log_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Log_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Log_Max_Order_By>;
  min?: InputMaybe<Core_Log_Min_Order_By>;
  stddev?: InputMaybe<Core_Log_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Log_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Log_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Log_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Log_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Log_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Log_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_log" */
export type Core_Log_Arr_Rel_Insert_Input = {
  data: Array<Core_Log_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Log_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Log_Avg_Fields = {
  __typename?: 'core_log_avg_fields';
  camera_id?: Maybe<Scalars['Float']>;
  event_log_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_log" */
export type Core_Log_Avg_Order_By = {
  camera_id?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_log". All fields are combined with a logical 'AND'. */
export type Core_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Log_Bool_Exp>>;
  _not?: InputMaybe<Core_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Log_Bool_Exp>>;
  authorised?: InputMaybe<Boolean_Comparison_Exp>;
  camera_id?: InputMaybe<Bigint_Comparison_Exp>;
  core_eventlog?: InputMaybe<Core_Eventlog_Bool_Exp>;
  core_logimages?: InputMaybe<Core_Logimage_Bool_Exp>;
  core_logimages_aggregate?: InputMaybe<Core_Logimage_Aggregate_Bool_Exp>;
  date_recorded?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_log_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  log_type?: InputMaybe<String_Comparison_Exp>;
  pic?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_log" */
export enum Core_Log_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreLogPkey = 'core_log_pkey'
}

/** input type for incrementing numeric columns in table "core_log" */
export type Core_Log_Inc_Input = {
  camera_id?: InputMaybe<Scalars['bigint']>;
  event_log_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_log" */
export type Core_Log_Insert_Input = {
  authorised?: InputMaybe<Scalars['Boolean']>;
  camera_id?: InputMaybe<Scalars['bigint']>;
  core_eventlog?: InputMaybe<Core_Eventlog_Obj_Rel_Insert_Input>;
  core_logimages?: InputMaybe<Core_Logimage_Arr_Rel_Insert_Input>;
  date_recorded?: InputMaybe<Scalars['timestamptz']>;
  event_log_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  log_type?: InputMaybe<Scalars['String']>;
  pic?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Log_Max_Fields = {
  __typename?: 'core_log_max_fields';
  camera_id?: Maybe<Scalars['bigint']>;
  date_recorded?: Maybe<Scalars['timestamptz']>;
  event_log_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  log_type?: Maybe<Scalars['String']>;
  pic?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "core_log" */
export type Core_Log_Max_Order_By = {
  camera_id?: InputMaybe<Order_By>;
  date_recorded?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  log_type?: InputMaybe<Order_By>;
  pic?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Log_Min_Fields = {
  __typename?: 'core_log_min_fields';
  camera_id?: Maybe<Scalars['bigint']>;
  date_recorded?: Maybe<Scalars['timestamptz']>;
  event_log_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  log_type?: Maybe<Scalars['String']>;
  pic?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "core_log" */
export type Core_Log_Min_Order_By = {
  camera_id?: InputMaybe<Order_By>;
  date_recorded?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  log_type?: InputMaybe<Order_By>;
  pic?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_log" */
export type Core_Log_Mutation_Response = {
  __typename?: 'core_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Log>;
};

/** input type for inserting object relation for remote table "core_log" */
export type Core_Log_Obj_Rel_Insert_Input = {
  data: Core_Log_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Log_On_Conflict>;
};

/** on_conflict condition type for table "core_log" */
export type Core_Log_On_Conflict = {
  constraint: Core_Log_Constraint;
  update_columns?: Array<Core_Log_Update_Column>;
  where?: InputMaybe<Core_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "core_log". */
export type Core_Log_Order_By = {
  authorised?: InputMaybe<Order_By>;
  camera_id?: InputMaybe<Order_By>;
  core_eventlog?: InputMaybe<Core_Eventlog_Order_By>;
  core_logimages_aggregate?: InputMaybe<Core_Logimage_Aggregate_Order_By>;
  date_recorded?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  log_type?: InputMaybe<Order_By>;
  pic?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_log */
export type Core_Log_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_log" */
export enum Core_Log_Select_Column {
  /** column name */
  Authorised = 'authorised',
  /** column name */
  CameraId = 'camera_id',
  /** column name */
  DateRecorded = 'date_recorded',
  /** column name */
  EventLogId = 'event_log_id',
  /** column name */
  Id = 'id',
  /** column name */
  LogType = 'log_type',
  /** column name */
  Pic = 'pic'
}

/** select "core_log_aggregate_bool_exp_bool_and_arguments_columns" columns of table "core_log" */
export enum Core_Log_Select_Column_Core_Log_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Authorised = 'authorised'
}

/** select "core_log_aggregate_bool_exp_bool_or_arguments_columns" columns of table "core_log" */
export enum Core_Log_Select_Column_Core_Log_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Authorised = 'authorised'
}

/** input type for updating data in table "core_log" */
export type Core_Log_Set_Input = {
  authorised?: InputMaybe<Scalars['Boolean']>;
  camera_id?: InputMaybe<Scalars['bigint']>;
  date_recorded?: InputMaybe<Scalars['timestamptz']>;
  event_log_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  log_type?: InputMaybe<Scalars['String']>;
  pic?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Log_Stddev_Fields = {
  __typename?: 'core_log_stddev_fields';
  camera_id?: Maybe<Scalars['Float']>;
  event_log_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_log" */
export type Core_Log_Stddev_Order_By = {
  camera_id?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Log_Stddev_Pop_Fields = {
  __typename?: 'core_log_stddev_pop_fields';
  camera_id?: Maybe<Scalars['Float']>;
  event_log_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_log" */
export type Core_Log_Stddev_Pop_Order_By = {
  camera_id?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Log_Stddev_Samp_Fields = {
  __typename?: 'core_log_stddev_samp_fields';
  camera_id?: Maybe<Scalars['Float']>;
  event_log_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_log" */
export type Core_Log_Stddev_Samp_Order_By = {
  camera_id?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_log" */
export type Core_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Log_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Log_Stream_Cursor_Value_Input = {
  authorised?: InputMaybe<Scalars['Boolean']>;
  camera_id?: InputMaybe<Scalars['bigint']>;
  date_recorded?: InputMaybe<Scalars['timestamptz']>;
  event_log_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  log_type?: InputMaybe<Scalars['String']>;
  pic?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Log_Sum_Fields = {
  __typename?: 'core_log_sum_fields';
  camera_id?: Maybe<Scalars['bigint']>;
  event_log_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_log" */
export type Core_Log_Sum_Order_By = {
  camera_id?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "core_log" */
export enum Core_Log_Update_Column {
  /** column name */
  Authorised = 'authorised',
  /** column name */
  CameraId = 'camera_id',
  /** column name */
  DateRecorded = 'date_recorded',
  /** column name */
  EventLogId = 'event_log_id',
  /** column name */
  Id = 'id',
  /** column name */
  LogType = 'log_type',
  /** column name */
  Pic = 'pic'
}

export type Core_Log_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Log_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Log_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Log_Var_Pop_Fields = {
  __typename?: 'core_log_var_pop_fields';
  camera_id?: Maybe<Scalars['Float']>;
  event_log_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_log" */
export type Core_Log_Var_Pop_Order_By = {
  camera_id?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Log_Var_Samp_Fields = {
  __typename?: 'core_log_var_samp_fields';
  camera_id?: Maybe<Scalars['Float']>;
  event_log_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_log" */
export type Core_Log_Var_Samp_Order_By = {
  camera_id?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Log_Variance_Fields = {
  __typename?: 'core_log_variance_fields';
  camera_id?: Maybe<Scalars['Float']>;
  event_log_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_log" */
export type Core_Log_Variance_Order_By = {
  camera_id?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_logfacialrecognition" */
export type Core_Logfacialrecognition = {
  __typename?: 'core_logfacialrecognition';
  authorised: Scalars['Boolean'];
  camera_id: Scalars['String'];
  confidence: Scalars['float8'];
  /** An object relationship */
  core_camera: Core_Camera;
  /** An object relationship */
  core_incident: Core_Incident;
  /** An object relationship */
  core_user: Core_User;
  created_on: Scalars['timestamptz'];
  id: Scalars['bigint'];
  incident_id: Scalars['bigint'];
  media?: Maybe<Scalars['String']>;
  personale_id: Scalars['bigint'];
  subject: Scalars['String'];
};

/** aggregated selection of "core_logfacialrecognition" */
export type Core_Logfacialrecognition_Aggregate = {
  __typename?: 'core_logfacialrecognition_aggregate';
  aggregate?: Maybe<Core_Logfacialrecognition_Aggregate_Fields>;
  nodes: Array<Core_Logfacialrecognition>;
};

export type Core_Logfacialrecognition_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Core_Logfacialrecognition_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Core_Logfacialrecognition_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Core_Logfacialrecognition_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Core_Logfacialrecognition_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Core_Logfacialrecognition_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Core_Logfacialrecognition_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Core_Logfacialrecognition_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Core_Logfacialrecognition_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Core_Logfacialrecognition_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Core_Logfacialrecognition_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Core_Logfacialrecognition_Aggregate_Bool_Exp_Var_Samp>;
};

export type Core_Logfacialrecognition_Aggregate_Bool_Exp_Avg = {
  arguments: Core_Logfacialrecognition_Select_Column_Core_Logfacialrecognition_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Logfacialrecognition_Aggregate_Bool_Exp_Bool_And = {
  arguments: Core_Logfacialrecognition_Select_Column_Core_Logfacialrecognition_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Core_Logfacialrecognition_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Core_Logfacialrecognition_Select_Column_Core_Logfacialrecognition_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Core_Logfacialrecognition_Aggregate_Bool_Exp_Corr = {
  arguments: Core_Logfacialrecognition_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Logfacialrecognition_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Core_Logfacialrecognition_Select_Column_Core_Logfacialrecognition_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Core_Logfacialrecognition_Select_Column_Core_Logfacialrecognition_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Core_Logfacialrecognition_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Logfacialrecognition_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Core_Logfacialrecognition_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Core_Logfacialrecognition_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Logfacialrecognition_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Core_Logfacialrecognition_Select_Column_Core_Logfacialrecognition_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Core_Logfacialrecognition_Select_Column_Core_Logfacialrecognition_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Core_Logfacialrecognition_Aggregate_Bool_Exp_Max = {
  arguments: Core_Logfacialrecognition_Select_Column_Core_Logfacialrecognition_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Logfacialrecognition_Aggregate_Bool_Exp_Min = {
  arguments: Core_Logfacialrecognition_Select_Column_Core_Logfacialrecognition_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Logfacialrecognition_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Core_Logfacialrecognition_Select_Column_Core_Logfacialrecognition_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Logfacialrecognition_Aggregate_Bool_Exp_Sum = {
  arguments: Core_Logfacialrecognition_Select_Column_Core_Logfacialrecognition_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Logfacialrecognition_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Core_Logfacialrecognition_Select_Column_Core_Logfacialrecognition_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "core_logfacialrecognition" */
export type Core_Logfacialrecognition_Aggregate_Fields = {
  __typename?: 'core_logfacialrecognition_aggregate_fields';
  avg?: Maybe<Core_Logfacialrecognition_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Logfacialrecognition_Max_Fields>;
  min?: Maybe<Core_Logfacialrecognition_Min_Fields>;
  stddev?: Maybe<Core_Logfacialrecognition_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Logfacialrecognition_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Logfacialrecognition_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Logfacialrecognition_Sum_Fields>;
  var_pop?: Maybe<Core_Logfacialrecognition_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Logfacialrecognition_Var_Samp_Fields>;
  variance?: Maybe<Core_Logfacialrecognition_Variance_Fields>;
};


/** aggregate fields of "core_logfacialrecognition" */
export type Core_Logfacialrecognition_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Logfacialrecognition_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_logfacialrecognition" */
export type Core_Logfacialrecognition_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Logfacialrecognition_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Logfacialrecognition_Max_Order_By>;
  min?: InputMaybe<Core_Logfacialrecognition_Min_Order_By>;
  stddev?: InputMaybe<Core_Logfacialrecognition_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Logfacialrecognition_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Logfacialrecognition_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Logfacialrecognition_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Logfacialrecognition_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Logfacialrecognition_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Logfacialrecognition_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_logfacialrecognition" */
export type Core_Logfacialrecognition_Arr_Rel_Insert_Input = {
  data: Array<Core_Logfacialrecognition_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Logfacialrecognition_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Logfacialrecognition_Avg_Fields = {
  __typename?: 'core_logfacialrecognition_avg_fields';
  confidence?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  incident_id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_logfacialrecognition" */
export type Core_Logfacialrecognition_Avg_Order_By = {
  confidence?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incident_id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_logfacialrecognition". All fields are combined with a logical 'AND'. */
export type Core_Logfacialrecognition_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Logfacialrecognition_Bool_Exp>>;
  _not?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Logfacialrecognition_Bool_Exp>>;
  authorised?: InputMaybe<Boolean_Comparison_Exp>;
  camera_id?: InputMaybe<String_Comparison_Exp>;
  confidence?: InputMaybe<Float8_Comparison_Exp>;
  core_camera?: InputMaybe<Core_Camera_Bool_Exp>;
  core_incident?: InputMaybe<Core_Incident_Bool_Exp>;
  core_user?: InputMaybe<Core_User_Bool_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  incident_id?: InputMaybe<Bigint_Comparison_Exp>;
  media?: InputMaybe<String_Comparison_Exp>;
  personale_id?: InputMaybe<Bigint_Comparison_Exp>;
  subject?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_logfacialrecognition" */
export enum Core_Logfacialrecognition_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreLogfacialrecognitionPkey = 'core_logfacialrecognition_pkey'
}

/** input type for incrementing numeric columns in table "core_logfacialrecognition" */
export type Core_Logfacialrecognition_Inc_Input = {
  confidence?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['bigint']>;
  incident_id?: InputMaybe<Scalars['bigint']>;
  personale_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_logfacialrecognition" */
export type Core_Logfacialrecognition_Insert_Input = {
  authorised?: InputMaybe<Scalars['Boolean']>;
  camera_id?: InputMaybe<Scalars['String']>;
  confidence?: InputMaybe<Scalars['float8']>;
  core_camera?: InputMaybe<Core_Camera_Obj_Rel_Insert_Input>;
  core_incident?: InputMaybe<Core_Incident_Obj_Rel_Insert_Input>;
  core_user?: InputMaybe<Core_User_Obj_Rel_Insert_Input>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  incident_id?: InputMaybe<Scalars['bigint']>;
  media?: InputMaybe<Scalars['String']>;
  personale_id?: InputMaybe<Scalars['bigint']>;
  subject?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Logfacialrecognition_Max_Fields = {
  __typename?: 'core_logfacialrecognition_max_fields';
  camera_id?: Maybe<Scalars['String']>;
  confidence?: Maybe<Scalars['float8']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  incident_id?: Maybe<Scalars['bigint']>;
  media?: Maybe<Scalars['String']>;
  personale_id?: Maybe<Scalars['bigint']>;
  subject?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "core_logfacialrecognition" */
export type Core_Logfacialrecognition_Max_Order_By = {
  camera_id?: InputMaybe<Order_By>;
  confidence?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incident_id?: InputMaybe<Order_By>;
  media?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Logfacialrecognition_Min_Fields = {
  __typename?: 'core_logfacialrecognition_min_fields';
  camera_id?: Maybe<Scalars['String']>;
  confidence?: Maybe<Scalars['float8']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  incident_id?: Maybe<Scalars['bigint']>;
  media?: Maybe<Scalars['String']>;
  personale_id?: Maybe<Scalars['bigint']>;
  subject?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "core_logfacialrecognition" */
export type Core_Logfacialrecognition_Min_Order_By = {
  camera_id?: InputMaybe<Order_By>;
  confidence?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incident_id?: InputMaybe<Order_By>;
  media?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_logfacialrecognition" */
export type Core_Logfacialrecognition_Mutation_Response = {
  __typename?: 'core_logfacialrecognition_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Logfacialrecognition>;
};

/** on_conflict condition type for table "core_logfacialrecognition" */
export type Core_Logfacialrecognition_On_Conflict = {
  constraint: Core_Logfacialrecognition_Constraint;
  update_columns?: Array<Core_Logfacialrecognition_Update_Column>;
  where?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
};

/** Ordering options when selecting data from "core_logfacialrecognition". */
export type Core_Logfacialrecognition_Order_By = {
  authorised?: InputMaybe<Order_By>;
  camera_id?: InputMaybe<Order_By>;
  confidence?: InputMaybe<Order_By>;
  core_camera?: InputMaybe<Core_Camera_Order_By>;
  core_incident?: InputMaybe<Core_Incident_Order_By>;
  core_user?: InputMaybe<Core_User_Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incident_id?: InputMaybe<Order_By>;
  media?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_logfacialrecognition */
export type Core_Logfacialrecognition_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_logfacialrecognition" */
export enum Core_Logfacialrecognition_Select_Column {
  /** column name */
  Authorised = 'authorised',
  /** column name */
  CameraId = 'camera_id',
  /** column name */
  Confidence = 'confidence',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  IncidentId = 'incident_id',
  /** column name */
  Media = 'media',
  /** column name */
  PersonaleId = 'personale_id',
  /** column name */
  Subject = 'subject'
}

/** select "core_logfacialrecognition_aggregate_bool_exp_avg_arguments_columns" columns of table "core_logfacialrecognition" */
export enum Core_Logfacialrecognition_Select_Column_Core_Logfacialrecognition_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Confidence = 'confidence'
}

/** select "core_logfacialrecognition_aggregate_bool_exp_bool_and_arguments_columns" columns of table "core_logfacialrecognition" */
export enum Core_Logfacialrecognition_Select_Column_Core_Logfacialrecognition_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Authorised = 'authorised'
}

/** select "core_logfacialrecognition_aggregate_bool_exp_bool_or_arguments_columns" columns of table "core_logfacialrecognition" */
export enum Core_Logfacialrecognition_Select_Column_Core_Logfacialrecognition_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Authorised = 'authorised'
}

/** select "core_logfacialrecognition_aggregate_bool_exp_corr_arguments_columns" columns of table "core_logfacialrecognition" */
export enum Core_Logfacialrecognition_Select_Column_Core_Logfacialrecognition_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Confidence = 'confidence'
}

/** select "core_logfacialrecognition_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "core_logfacialrecognition" */
export enum Core_Logfacialrecognition_Select_Column_Core_Logfacialrecognition_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Confidence = 'confidence'
}

/** select "core_logfacialrecognition_aggregate_bool_exp_max_arguments_columns" columns of table "core_logfacialrecognition" */
export enum Core_Logfacialrecognition_Select_Column_Core_Logfacialrecognition_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Confidence = 'confidence'
}

/** select "core_logfacialrecognition_aggregate_bool_exp_min_arguments_columns" columns of table "core_logfacialrecognition" */
export enum Core_Logfacialrecognition_Select_Column_Core_Logfacialrecognition_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Confidence = 'confidence'
}

/** select "core_logfacialrecognition_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "core_logfacialrecognition" */
export enum Core_Logfacialrecognition_Select_Column_Core_Logfacialrecognition_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Confidence = 'confidence'
}

/** select "core_logfacialrecognition_aggregate_bool_exp_sum_arguments_columns" columns of table "core_logfacialrecognition" */
export enum Core_Logfacialrecognition_Select_Column_Core_Logfacialrecognition_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Confidence = 'confidence'
}

/** select "core_logfacialrecognition_aggregate_bool_exp_var_samp_arguments_columns" columns of table "core_logfacialrecognition" */
export enum Core_Logfacialrecognition_Select_Column_Core_Logfacialrecognition_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Confidence = 'confidence'
}

/** input type for updating data in table "core_logfacialrecognition" */
export type Core_Logfacialrecognition_Set_Input = {
  authorised?: InputMaybe<Scalars['Boolean']>;
  camera_id?: InputMaybe<Scalars['String']>;
  confidence?: InputMaybe<Scalars['float8']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  incident_id?: InputMaybe<Scalars['bigint']>;
  media?: InputMaybe<Scalars['String']>;
  personale_id?: InputMaybe<Scalars['bigint']>;
  subject?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Logfacialrecognition_Stddev_Fields = {
  __typename?: 'core_logfacialrecognition_stddev_fields';
  confidence?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  incident_id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_logfacialrecognition" */
export type Core_Logfacialrecognition_Stddev_Order_By = {
  confidence?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incident_id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Logfacialrecognition_Stddev_Pop_Fields = {
  __typename?: 'core_logfacialrecognition_stddev_pop_fields';
  confidence?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  incident_id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_logfacialrecognition" */
export type Core_Logfacialrecognition_Stddev_Pop_Order_By = {
  confidence?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incident_id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Logfacialrecognition_Stddev_Samp_Fields = {
  __typename?: 'core_logfacialrecognition_stddev_samp_fields';
  confidence?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  incident_id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_logfacialrecognition" */
export type Core_Logfacialrecognition_Stddev_Samp_Order_By = {
  confidence?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incident_id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_logfacialrecognition" */
export type Core_Logfacialrecognition_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Logfacialrecognition_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Logfacialrecognition_Stream_Cursor_Value_Input = {
  authorised?: InputMaybe<Scalars['Boolean']>;
  camera_id?: InputMaybe<Scalars['String']>;
  confidence?: InputMaybe<Scalars['float8']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  incident_id?: InputMaybe<Scalars['bigint']>;
  media?: InputMaybe<Scalars['String']>;
  personale_id?: InputMaybe<Scalars['bigint']>;
  subject?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Logfacialrecognition_Sum_Fields = {
  __typename?: 'core_logfacialrecognition_sum_fields';
  confidence?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  incident_id?: Maybe<Scalars['bigint']>;
  personale_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_logfacialrecognition" */
export type Core_Logfacialrecognition_Sum_Order_By = {
  confidence?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incident_id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_logfacialrecognition" */
export enum Core_Logfacialrecognition_Update_Column {
  /** column name */
  Authorised = 'authorised',
  /** column name */
  CameraId = 'camera_id',
  /** column name */
  Confidence = 'confidence',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  IncidentId = 'incident_id',
  /** column name */
  Media = 'media',
  /** column name */
  PersonaleId = 'personale_id',
  /** column name */
  Subject = 'subject'
}

export type Core_Logfacialrecognition_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Logfacialrecognition_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Logfacialrecognition_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Logfacialrecognition_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Logfacialrecognition_Var_Pop_Fields = {
  __typename?: 'core_logfacialrecognition_var_pop_fields';
  confidence?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  incident_id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_logfacialrecognition" */
export type Core_Logfacialrecognition_Var_Pop_Order_By = {
  confidence?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incident_id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Logfacialrecognition_Var_Samp_Fields = {
  __typename?: 'core_logfacialrecognition_var_samp_fields';
  confidence?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  incident_id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_logfacialrecognition" */
export type Core_Logfacialrecognition_Var_Samp_Order_By = {
  confidence?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incident_id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Logfacialrecognition_Variance_Fields = {
  __typename?: 'core_logfacialrecognition_variance_fields';
  confidence?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  incident_id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_logfacialrecognition" */
export type Core_Logfacialrecognition_Variance_Order_By = {
  confidence?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incident_id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_logimage" */
export type Core_Logimage = {
  __typename?: 'core_logimage';
  /** An object relationship */
  core_log: Core_Log;
  id: Scalars['bigint'];
  log_id: Scalars['bigint'];
  media: Scalars['String'];
};

/** aggregated selection of "core_logimage" */
export type Core_Logimage_Aggregate = {
  __typename?: 'core_logimage_aggregate';
  aggregate?: Maybe<Core_Logimage_Aggregate_Fields>;
  nodes: Array<Core_Logimage>;
};

export type Core_Logimage_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Logimage_Aggregate_Bool_Exp_Count>;
};

export type Core_Logimage_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Logimage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logimage_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_logimage" */
export type Core_Logimage_Aggregate_Fields = {
  __typename?: 'core_logimage_aggregate_fields';
  avg?: Maybe<Core_Logimage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Logimage_Max_Fields>;
  min?: Maybe<Core_Logimage_Min_Fields>;
  stddev?: Maybe<Core_Logimage_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Logimage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Logimage_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Logimage_Sum_Fields>;
  var_pop?: Maybe<Core_Logimage_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Logimage_Var_Samp_Fields>;
  variance?: Maybe<Core_Logimage_Variance_Fields>;
};


/** aggregate fields of "core_logimage" */
export type Core_Logimage_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Logimage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_logimage" */
export type Core_Logimage_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Logimage_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Logimage_Max_Order_By>;
  min?: InputMaybe<Core_Logimage_Min_Order_By>;
  stddev?: InputMaybe<Core_Logimage_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Logimage_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Logimage_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Logimage_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Logimage_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Logimage_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Logimage_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_logimage" */
export type Core_Logimage_Arr_Rel_Insert_Input = {
  data: Array<Core_Logimage_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Logimage_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Logimage_Avg_Fields = {
  __typename?: 'core_logimage_avg_fields';
  id?: Maybe<Scalars['Float']>;
  log_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_logimage" */
export type Core_Logimage_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  log_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_logimage". All fields are combined with a logical 'AND'. */
export type Core_Logimage_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Logimage_Bool_Exp>>;
  _not?: InputMaybe<Core_Logimage_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Logimage_Bool_Exp>>;
  core_log?: InputMaybe<Core_Log_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  log_id?: InputMaybe<Bigint_Comparison_Exp>;
  media?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_logimage" */
export enum Core_Logimage_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreLogimagePkey = 'core_logimage_pkey'
}

/** input type for incrementing numeric columns in table "core_logimage" */
export type Core_Logimage_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  log_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_logimage" */
export type Core_Logimage_Insert_Input = {
  core_log?: InputMaybe<Core_Log_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  log_id?: InputMaybe<Scalars['bigint']>;
  media?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Logimage_Max_Fields = {
  __typename?: 'core_logimage_max_fields';
  id?: Maybe<Scalars['bigint']>;
  log_id?: Maybe<Scalars['bigint']>;
  media?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "core_logimage" */
export type Core_Logimage_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  log_id?: InputMaybe<Order_By>;
  media?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Logimage_Min_Fields = {
  __typename?: 'core_logimage_min_fields';
  id?: Maybe<Scalars['bigint']>;
  log_id?: Maybe<Scalars['bigint']>;
  media?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "core_logimage" */
export type Core_Logimage_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  log_id?: InputMaybe<Order_By>;
  media?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_logimage" */
export type Core_Logimage_Mutation_Response = {
  __typename?: 'core_logimage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Logimage>;
};

/** on_conflict condition type for table "core_logimage" */
export type Core_Logimage_On_Conflict = {
  constraint: Core_Logimage_Constraint;
  update_columns?: Array<Core_Logimage_Update_Column>;
  where?: InputMaybe<Core_Logimage_Bool_Exp>;
};

/** Ordering options when selecting data from "core_logimage". */
export type Core_Logimage_Order_By = {
  core_log?: InputMaybe<Core_Log_Order_By>;
  id?: InputMaybe<Order_By>;
  log_id?: InputMaybe<Order_By>;
  media?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_logimage */
export type Core_Logimage_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_logimage" */
export enum Core_Logimage_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LogId = 'log_id',
  /** column name */
  Media = 'media'
}

/** input type for updating data in table "core_logimage" */
export type Core_Logimage_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  log_id?: InputMaybe<Scalars['bigint']>;
  media?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Logimage_Stddev_Fields = {
  __typename?: 'core_logimage_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  log_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_logimage" */
export type Core_Logimage_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  log_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Logimage_Stddev_Pop_Fields = {
  __typename?: 'core_logimage_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  log_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_logimage" */
export type Core_Logimage_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  log_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Logimage_Stddev_Samp_Fields = {
  __typename?: 'core_logimage_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  log_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_logimage" */
export type Core_Logimage_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  log_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_logimage" */
export type Core_Logimage_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Logimage_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Logimage_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  log_id?: InputMaybe<Scalars['bigint']>;
  media?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Logimage_Sum_Fields = {
  __typename?: 'core_logimage_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  log_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_logimage" */
export type Core_Logimage_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  log_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_logimage" */
export enum Core_Logimage_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LogId = 'log_id',
  /** column name */
  Media = 'media'
}

export type Core_Logimage_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Logimage_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Logimage_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Logimage_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Logimage_Var_Pop_Fields = {
  __typename?: 'core_logimage_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  log_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_logimage" */
export type Core_Logimage_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  log_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Logimage_Var_Samp_Fields = {
  __typename?: 'core_logimage_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  log_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_logimage" */
export type Core_Logimage_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  log_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Logimage_Variance_Fields = {
  __typename?: 'core_logimage_variance_fields';
  id?: Maybe<Scalars['Float']>;
  log_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_logimage" */
export type Core_Logimage_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  log_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_logsuspiciosactivity" */
export type Core_Logsuspiciosactivity = {
  __typename?: 'core_logsuspiciosactivity';
  camera_id: Scalars['String'];
  confidence: Scalars['float8'];
  /** An object relationship */
  core_camera: Core_Camera;
  /** An object relationship */
  core_incident: Core_Incident;
  created_on: Scalars['timestamptz'];
  description: Scalars['String'];
  distance: Scalars['float8'];
  id: Scalars['bigint'];
  image?: Maybe<Scalars['String']>;
  incident_id: Scalars['bigint'];
  is_suspicious: Scalars['Boolean'];
  label: Scalars['String'];
  media?: Maybe<Scalars['String']>;
};

/** aggregated selection of "core_logsuspiciosactivity" */
export type Core_Logsuspiciosactivity_Aggregate = {
  __typename?: 'core_logsuspiciosactivity_aggregate';
  aggregate?: Maybe<Core_Logsuspiciosactivity_Aggregate_Fields>;
  nodes: Array<Core_Logsuspiciosactivity>;
};

export type Core_Logsuspiciosactivity_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Var_Samp>;
};

export type Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Avg = {
  arguments: Core_Logsuspiciosactivity_Select_Column_Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Bool_And = {
  arguments: Core_Logsuspiciosactivity_Select_Column_Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Core_Logsuspiciosactivity_Select_Column_Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Corr = {
  arguments: Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Core_Logsuspiciosactivity_Select_Column_Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Core_Logsuspiciosactivity_Select_Column_Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Logsuspiciosactivity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Core_Logsuspiciosactivity_Select_Column_Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Core_Logsuspiciosactivity_Select_Column_Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Max = {
  arguments: Core_Logsuspiciosactivity_Select_Column_Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Min = {
  arguments: Core_Logsuspiciosactivity_Select_Column_Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Core_Logsuspiciosactivity_Select_Column_Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Sum = {
  arguments: Core_Logsuspiciosactivity_Select_Column_Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Core_Logsuspiciosactivity_Select_Column_Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "core_logsuspiciosactivity" */
export type Core_Logsuspiciosactivity_Aggregate_Fields = {
  __typename?: 'core_logsuspiciosactivity_aggregate_fields';
  avg?: Maybe<Core_Logsuspiciosactivity_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Logsuspiciosactivity_Max_Fields>;
  min?: Maybe<Core_Logsuspiciosactivity_Min_Fields>;
  stddev?: Maybe<Core_Logsuspiciosactivity_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Logsuspiciosactivity_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Logsuspiciosactivity_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Logsuspiciosactivity_Sum_Fields>;
  var_pop?: Maybe<Core_Logsuspiciosactivity_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Logsuspiciosactivity_Var_Samp_Fields>;
  variance?: Maybe<Core_Logsuspiciosactivity_Variance_Fields>;
};


/** aggregate fields of "core_logsuspiciosactivity" */
export type Core_Logsuspiciosactivity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Logsuspiciosactivity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_logsuspiciosactivity" */
export type Core_Logsuspiciosactivity_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Logsuspiciosactivity_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Logsuspiciosactivity_Max_Order_By>;
  min?: InputMaybe<Core_Logsuspiciosactivity_Min_Order_By>;
  stddev?: InputMaybe<Core_Logsuspiciosactivity_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Logsuspiciosactivity_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Logsuspiciosactivity_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Logsuspiciosactivity_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Logsuspiciosactivity_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Logsuspiciosactivity_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Logsuspiciosactivity_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_logsuspiciosactivity" */
export type Core_Logsuspiciosactivity_Arr_Rel_Insert_Input = {
  data: Array<Core_Logsuspiciosactivity_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Logsuspiciosactivity_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Logsuspiciosactivity_Avg_Fields = {
  __typename?: 'core_logsuspiciosactivity_avg_fields';
  confidence?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  incident_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_logsuspiciosactivity" */
export type Core_Logsuspiciosactivity_Avg_Order_By = {
  confidence?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incident_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_logsuspiciosactivity". All fields are combined with a logical 'AND'. */
export type Core_Logsuspiciosactivity_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Logsuspiciosactivity_Bool_Exp>>;
  _not?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Logsuspiciosactivity_Bool_Exp>>;
  camera_id?: InputMaybe<String_Comparison_Exp>;
  confidence?: InputMaybe<Float8_Comparison_Exp>;
  core_camera?: InputMaybe<Core_Camera_Bool_Exp>;
  core_incident?: InputMaybe<Core_Incident_Bool_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  distance?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  incident_id?: InputMaybe<Bigint_Comparison_Exp>;
  is_suspicious?: InputMaybe<Boolean_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
  media?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_logsuspiciosactivity" */
export enum Core_Logsuspiciosactivity_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreLogsuspiciosactivityPkey = 'core_logsuspiciosactivity_pkey'
}

/** input type for incrementing numeric columns in table "core_logsuspiciosactivity" */
export type Core_Logsuspiciosactivity_Inc_Input = {
  confidence?: InputMaybe<Scalars['float8']>;
  distance?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['bigint']>;
  incident_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_logsuspiciosactivity" */
export type Core_Logsuspiciosactivity_Insert_Input = {
  camera_id?: InputMaybe<Scalars['String']>;
  confidence?: InputMaybe<Scalars['float8']>;
  core_camera?: InputMaybe<Core_Camera_Obj_Rel_Insert_Input>;
  core_incident?: InputMaybe<Core_Incident_Obj_Rel_Insert_Input>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  distance?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['bigint']>;
  image?: InputMaybe<Scalars['String']>;
  incident_id?: InputMaybe<Scalars['bigint']>;
  is_suspicious?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Logsuspiciosactivity_Max_Fields = {
  __typename?: 'core_logsuspiciosactivity_max_fields';
  camera_id?: Maybe<Scalars['String']>;
  confidence?: Maybe<Scalars['float8']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  image?: Maybe<Scalars['String']>;
  incident_id?: Maybe<Scalars['bigint']>;
  label?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "core_logsuspiciosactivity" */
export type Core_Logsuspiciosactivity_Max_Order_By = {
  camera_id?: InputMaybe<Order_By>;
  confidence?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  incident_id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  media?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Logsuspiciosactivity_Min_Fields = {
  __typename?: 'core_logsuspiciosactivity_min_fields';
  camera_id?: Maybe<Scalars['String']>;
  confidence?: Maybe<Scalars['float8']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  image?: Maybe<Scalars['String']>;
  incident_id?: Maybe<Scalars['bigint']>;
  label?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "core_logsuspiciosactivity" */
export type Core_Logsuspiciosactivity_Min_Order_By = {
  camera_id?: InputMaybe<Order_By>;
  confidence?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  incident_id?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  media?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_logsuspiciosactivity" */
export type Core_Logsuspiciosactivity_Mutation_Response = {
  __typename?: 'core_logsuspiciosactivity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Logsuspiciosactivity>;
};

/** on_conflict condition type for table "core_logsuspiciosactivity" */
export type Core_Logsuspiciosactivity_On_Conflict = {
  constraint: Core_Logsuspiciosactivity_Constraint;
  update_columns?: Array<Core_Logsuspiciosactivity_Update_Column>;
  where?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
};

/** Ordering options when selecting data from "core_logsuspiciosactivity". */
export type Core_Logsuspiciosactivity_Order_By = {
  camera_id?: InputMaybe<Order_By>;
  confidence?: InputMaybe<Order_By>;
  core_camera?: InputMaybe<Core_Camera_Order_By>;
  core_incident?: InputMaybe<Core_Incident_Order_By>;
  created_on?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  incident_id?: InputMaybe<Order_By>;
  is_suspicious?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
  media?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_logsuspiciosactivity */
export type Core_Logsuspiciosactivity_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_logsuspiciosactivity" */
export enum Core_Logsuspiciosactivity_Select_Column {
  /** column name */
  CameraId = 'camera_id',
  /** column name */
  Confidence = 'confidence',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Description = 'description',
  /** column name */
  Distance = 'distance',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  IncidentId = 'incident_id',
  /** column name */
  IsSuspicious = 'is_suspicious',
  /** column name */
  Label = 'label',
  /** column name */
  Media = 'media'
}

/** select "core_logsuspiciosactivity_aggregate_bool_exp_avg_arguments_columns" columns of table "core_logsuspiciosactivity" */
export enum Core_Logsuspiciosactivity_Select_Column_Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Confidence = 'confidence',
  /** column name */
  Distance = 'distance'
}

/** select "core_logsuspiciosactivity_aggregate_bool_exp_bool_and_arguments_columns" columns of table "core_logsuspiciosactivity" */
export enum Core_Logsuspiciosactivity_Select_Column_Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsSuspicious = 'is_suspicious'
}

/** select "core_logsuspiciosactivity_aggregate_bool_exp_bool_or_arguments_columns" columns of table "core_logsuspiciosactivity" */
export enum Core_Logsuspiciosactivity_Select_Column_Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsSuspicious = 'is_suspicious'
}

/** select "core_logsuspiciosactivity_aggregate_bool_exp_corr_arguments_columns" columns of table "core_logsuspiciosactivity" */
export enum Core_Logsuspiciosactivity_Select_Column_Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Confidence = 'confidence',
  /** column name */
  Distance = 'distance'
}

/** select "core_logsuspiciosactivity_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "core_logsuspiciosactivity" */
export enum Core_Logsuspiciosactivity_Select_Column_Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Confidence = 'confidence',
  /** column name */
  Distance = 'distance'
}

/** select "core_logsuspiciosactivity_aggregate_bool_exp_max_arguments_columns" columns of table "core_logsuspiciosactivity" */
export enum Core_Logsuspiciosactivity_Select_Column_Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Confidence = 'confidence',
  /** column name */
  Distance = 'distance'
}

/** select "core_logsuspiciosactivity_aggregate_bool_exp_min_arguments_columns" columns of table "core_logsuspiciosactivity" */
export enum Core_Logsuspiciosactivity_Select_Column_Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Confidence = 'confidence',
  /** column name */
  Distance = 'distance'
}

/** select "core_logsuspiciosactivity_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "core_logsuspiciosactivity" */
export enum Core_Logsuspiciosactivity_Select_Column_Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Confidence = 'confidence',
  /** column name */
  Distance = 'distance'
}

/** select "core_logsuspiciosactivity_aggregate_bool_exp_sum_arguments_columns" columns of table "core_logsuspiciosactivity" */
export enum Core_Logsuspiciosactivity_Select_Column_Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Confidence = 'confidence',
  /** column name */
  Distance = 'distance'
}

/** select "core_logsuspiciosactivity_aggregate_bool_exp_var_samp_arguments_columns" columns of table "core_logsuspiciosactivity" */
export enum Core_Logsuspiciosactivity_Select_Column_Core_Logsuspiciosactivity_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Confidence = 'confidence',
  /** column name */
  Distance = 'distance'
}

/** input type for updating data in table "core_logsuspiciosactivity" */
export type Core_Logsuspiciosactivity_Set_Input = {
  camera_id?: InputMaybe<Scalars['String']>;
  confidence?: InputMaybe<Scalars['float8']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  distance?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['bigint']>;
  image?: InputMaybe<Scalars['String']>;
  incident_id?: InputMaybe<Scalars['bigint']>;
  is_suspicious?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Logsuspiciosactivity_Stddev_Fields = {
  __typename?: 'core_logsuspiciosactivity_stddev_fields';
  confidence?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  incident_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_logsuspiciosactivity" */
export type Core_Logsuspiciosactivity_Stddev_Order_By = {
  confidence?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incident_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Logsuspiciosactivity_Stddev_Pop_Fields = {
  __typename?: 'core_logsuspiciosactivity_stddev_pop_fields';
  confidence?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  incident_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_logsuspiciosactivity" */
export type Core_Logsuspiciosactivity_Stddev_Pop_Order_By = {
  confidence?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incident_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Logsuspiciosactivity_Stddev_Samp_Fields = {
  __typename?: 'core_logsuspiciosactivity_stddev_samp_fields';
  confidence?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  incident_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_logsuspiciosactivity" */
export type Core_Logsuspiciosactivity_Stddev_Samp_Order_By = {
  confidence?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incident_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_logsuspiciosactivity" */
export type Core_Logsuspiciosactivity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Logsuspiciosactivity_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Logsuspiciosactivity_Stream_Cursor_Value_Input = {
  camera_id?: InputMaybe<Scalars['String']>;
  confidence?: InputMaybe<Scalars['float8']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  distance?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['bigint']>;
  image?: InputMaybe<Scalars['String']>;
  incident_id?: InputMaybe<Scalars['bigint']>;
  is_suspicious?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Logsuspiciosactivity_Sum_Fields = {
  __typename?: 'core_logsuspiciosactivity_sum_fields';
  confidence?: Maybe<Scalars['float8']>;
  distance?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  incident_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_logsuspiciosactivity" */
export type Core_Logsuspiciosactivity_Sum_Order_By = {
  confidence?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incident_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_logsuspiciosactivity" */
export enum Core_Logsuspiciosactivity_Update_Column {
  /** column name */
  CameraId = 'camera_id',
  /** column name */
  Confidence = 'confidence',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Description = 'description',
  /** column name */
  Distance = 'distance',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  IncidentId = 'incident_id',
  /** column name */
  IsSuspicious = 'is_suspicious',
  /** column name */
  Label = 'label',
  /** column name */
  Media = 'media'
}

export type Core_Logsuspiciosactivity_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Logsuspiciosactivity_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Logsuspiciosactivity_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Logsuspiciosactivity_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Logsuspiciosactivity_Var_Pop_Fields = {
  __typename?: 'core_logsuspiciosactivity_var_pop_fields';
  confidence?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  incident_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_logsuspiciosactivity" */
export type Core_Logsuspiciosactivity_Var_Pop_Order_By = {
  confidence?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incident_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Logsuspiciosactivity_Var_Samp_Fields = {
  __typename?: 'core_logsuspiciosactivity_var_samp_fields';
  confidence?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  incident_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_logsuspiciosactivity" */
export type Core_Logsuspiciosactivity_Var_Samp_Order_By = {
  confidence?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incident_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Logsuspiciosactivity_Variance_Fields = {
  __typename?: 'core_logsuspiciosactivity_variance_fields';
  confidence?: Maybe<Scalars['Float']>;
  distance?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  incident_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_logsuspiciosactivity" */
export type Core_Logsuspiciosactivity_Variance_Order_By = {
  confidence?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  incident_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_meter" */
export type Core_Meter = {
  __typename?: 'core_meter';
  box_id: Scalars['bigint'];
  /** An object relationship */
  core_box: Core_Box;
  /** An array relationship */
  core_meterreadings: Array<Core_Meterreading>;
  /** An aggregate relationship */
  core_meterreadings_aggregate: Core_Meterreading_Aggregate;
  created_on: Scalars['timestamptz'];
  id?: Maybe<Scalars['bigint']>;
  serial_number: Scalars['String'];
};


/** columns and relationships of "core_meter" */
export type Core_MeterCore_MeterreadingsArgs = {
  distinct_on?: InputMaybe<Array<Core_Meterreading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Meterreading_Order_By>>;
  where?: InputMaybe<Core_Meterreading_Bool_Exp>;
};


/** columns and relationships of "core_meter" */
export type Core_MeterCore_Meterreadings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Meterreading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Meterreading_Order_By>>;
  where?: InputMaybe<Core_Meterreading_Bool_Exp>;
};

/** aggregated selection of "core_meter" */
export type Core_Meter_Aggregate = {
  __typename?: 'core_meter_aggregate';
  aggregate?: Maybe<Core_Meter_Aggregate_Fields>;
  nodes: Array<Core_Meter>;
};

export type Core_Meter_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Meter_Aggregate_Bool_Exp_Count>;
};

export type Core_Meter_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Meter_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Meter_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_meter" */
export type Core_Meter_Aggregate_Fields = {
  __typename?: 'core_meter_aggregate_fields';
  avg?: Maybe<Core_Meter_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Meter_Max_Fields>;
  min?: Maybe<Core_Meter_Min_Fields>;
  stddev?: Maybe<Core_Meter_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Meter_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Meter_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Meter_Sum_Fields>;
  var_pop?: Maybe<Core_Meter_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Meter_Var_Samp_Fields>;
  variance?: Maybe<Core_Meter_Variance_Fields>;
};


/** aggregate fields of "core_meter" */
export type Core_Meter_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Meter_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_meter" */
export type Core_Meter_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Meter_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Meter_Max_Order_By>;
  min?: InputMaybe<Core_Meter_Min_Order_By>;
  stddev?: InputMaybe<Core_Meter_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Meter_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Meter_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Meter_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Meter_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Meter_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Meter_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_meter" */
export type Core_Meter_Arr_Rel_Insert_Input = {
  data: Array<Core_Meter_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Meter_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Meter_Avg_Fields = {
  __typename?: 'core_meter_avg_fields';
  box_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_meter" */
export type Core_Meter_Avg_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_meter". All fields are combined with a logical 'AND'. */
export type Core_Meter_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Meter_Bool_Exp>>;
  _not?: InputMaybe<Core_Meter_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Meter_Bool_Exp>>;
  box_id?: InputMaybe<Bigint_Comparison_Exp>;
  core_box?: InputMaybe<Core_Box_Bool_Exp>;
  core_meterreadings?: InputMaybe<Core_Meterreading_Bool_Exp>;
  core_meterreadings_aggregate?: InputMaybe<Core_Meterreading_Aggregate_Bool_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_meter" */
export enum Core_Meter_Constraint {
  /** unique or primary key constraint on columns "serial_number" */
  CoreMeterMeterNumber_4287af96Pk = 'core_meter_meter_number_4287af96_pk',
  /** unique or primary key constraint on columns "serial_number" */
  CoreMeterMeterNumber_4287af96Uniq = 'core_meter_meter_number_4287af96_uniq'
}

/** input type for incrementing numeric columns in table "core_meter" */
export type Core_Meter_Inc_Input = {
  box_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_meter" */
export type Core_Meter_Insert_Input = {
  box_id?: InputMaybe<Scalars['bigint']>;
  core_box?: InputMaybe<Core_Box_Obj_Rel_Insert_Input>;
  core_meterreadings?: InputMaybe<Core_Meterreading_Arr_Rel_Insert_Input>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  serial_number?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Meter_Max_Fields = {
  __typename?: 'core_meter_max_fields';
  box_id?: Maybe<Scalars['bigint']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  serial_number?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "core_meter" */
export type Core_Meter_Max_Order_By = {
  box_id?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Meter_Min_Fields = {
  __typename?: 'core_meter_min_fields';
  box_id?: Maybe<Scalars['bigint']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  serial_number?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "core_meter" */
export type Core_Meter_Min_Order_By = {
  box_id?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_meter" */
export type Core_Meter_Mutation_Response = {
  __typename?: 'core_meter_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Meter>;
};

/** input type for inserting object relation for remote table "core_meter" */
export type Core_Meter_Obj_Rel_Insert_Input = {
  data: Core_Meter_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Meter_On_Conflict>;
};

/** on_conflict condition type for table "core_meter" */
export type Core_Meter_On_Conflict = {
  constraint: Core_Meter_Constraint;
  update_columns?: Array<Core_Meter_Update_Column>;
  where?: InputMaybe<Core_Meter_Bool_Exp>;
};

/** Ordering options when selecting data from "core_meter". */
export type Core_Meter_Order_By = {
  box_id?: InputMaybe<Order_By>;
  core_box?: InputMaybe<Core_Box_Order_By>;
  core_meterreadings_aggregate?: InputMaybe<Core_Meterreading_Aggregate_Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_meter */
export type Core_Meter_Pk_Columns_Input = {
  serial_number: Scalars['String'];
};

/** select columns of table "core_meter" */
export enum Core_Meter_Select_Column {
  /** column name */
  BoxId = 'box_id',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  SerialNumber = 'serial_number'
}

/** input type for updating data in table "core_meter" */
export type Core_Meter_Set_Input = {
  box_id?: InputMaybe<Scalars['bigint']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  serial_number?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Meter_Stddev_Fields = {
  __typename?: 'core_meter_stddev_fields';
  box_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_meter" */
export type Core_Meter_Stddev_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Meter_Stddev_Pop_Fields = {
  __typename?: 'core_meter_stddev_pop_fields';
  box_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_meter" */
export type Core_Meter_Stddev_Pop_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Meter_Stddev_Samp_Fields = {
  __typename?: 'core_meter_stddev_samp_fields';
  box_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_meter" */
export type Core_Meter_Stddev_Samp_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_meter" */
export type Core_Meter_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Meter_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Meter_Stream_Cursor_Value_Input = {
  box_id?: InputMaybe<Scalars['bigint']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  serial_number?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Meter_Sum_Fields = {
  __typename?: 'core_meter_sum_fields';
  box_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_meter" */
export type Core_Meter_Sum_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "core_meter" */
export enum Core_Meter_Update_Column {
  /** column name */
  BoxId = 'box_id',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  SerialNumber = 'serial_number'
}

export type Core_Meter_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Meter_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Meter_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Meter_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Meter_Var_Pop_Fields = {
  __typename?: 'core_meter_var_pop_fields';
  box_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_meter" */
export type Core_Meter_Var_Pop_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Meter_Var_Samp_Fields = {
  __typename?: 'core_meter_var_samp_fields';
  box_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_meter" */
export type Core_Meter_Var_Samp_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Meter_Variance_Fields = {
  __typename?: 'core_meter_variance_fields';
  box_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_meter" */
export type Core_Meter_Variance_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_meterreading" */
export type Core_Meterreading = {
  __typename?: 'core_meterreading';
  /** An object relationship */
  core_meter?: Maybe<Core_Meter>;
  created_on: Scalars['timestamptz'];
  id: Scalars['bigint'];
  meter_id?: Maybe<Scalars['String']>;
  reading: Scalars['float8'];
};

/** aggregated selection of "core_meterreading" */
export type Core_Meterreading_Aggregate = {
  __typename?: 'core_meterreading_aggregate';
  aggregate?: Maybe<Core_Meterreading_Aggregate_Fields>;
  nodes: Array<Core_Meterreading>;
};

export type Core_Meterreading_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Core_Meterreading_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Core_Meterreading_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Core_Meterreading_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Core_Meterreading_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Core_Meterreading_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Core_Meterreading_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Core_Meterreading_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Core_Meterreading_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Core_Meterreading_Aggregate_Bool_Exp_Var_Samp>;
};

export type Core_Meterreading_Aggregate_Bool_Exp_Avg = {
  arguments: Core_Meterreading_Select_Column_Core_Meterreading_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Meterreading_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Meterreading_Aggregate_Bool_Exp_Corr = {
  arguments: Core_Meterreading_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Meterreading_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Meterreading_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Core_Meterreading_Select_Column_Core_Meterreading_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Core_Meterreading_Select_Column_Core_Meterreading_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Core_Meterreading_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Meterreading_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Meterreading_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Core_Meterreading_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Core_Meterreading_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Meterreading_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Meterreading_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Core_Meterreading_Select_Column_Core_Meterreading_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Core_Meterreading_Select_Column_Core_Meterreading_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Core_Meterreading_Aggregate_Bool_Exp_Max = {
  arguments: Core_Meterreading_Select_Column_Core_Meterreading_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Meterreading_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Meterreading_Aggregate_Bool_Exp_Min = {
  arguments: Core_Meterreading_Select_Column_Core_Meterreading_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Meterreading_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Meterreading_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Core_Meterreading_Select_Column_Core_Meterreading_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Meterreading_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Meterreading_Aggregate_Bool_Exp_Sum = {
  arguments: Core_Meterreading_Select_Column_Core_Meterreading_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Meterreading_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Meterreading_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Core_Meterreading_Select_Column_Core_Meterreading_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Meterreading_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "core_meterreading" */
export type Core_Meterreading_Aggregate_Fields = {
  __typename?: 'core_meterreading_aggregate_fields';
  avg?: Maybe<Core_Meterreading_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Meterreading_Max_Fields>;
  min?: Maybe<Core_Meterreading_Min_Fields>;
  stddev?: Maybe<Core_Meterreading_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Meterreading_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Meterreading_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Meterreading_Sum_Fields>;
  var_pop?: Maybe<Core_Meterreading_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Meterreading_Var_Samp_Fields>;
  variance?: Maybe<Core_Meterreading_Variance_Fields>;
};


/** aggregate fields of "core_meterreading" */
export type Core_Meterreading_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Meterreading_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_meterreading" */
export type Core_Meterreading_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Meterreading_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Meterreading_Max_Order_By>;
  min?: InputMaybe<Core_Meterreading_Min_Order_By>;
  stddev?: InputMaybe<Core_Meterreading_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Meterreading_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Meterreading_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Meterreading_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Meterreading_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Meterreading_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Meterreading_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_meterreading" */
export type Core_Meterreading_Arr_Rel_Insert_Input = {
  data: Array<Core_Meterreading_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Meterreading_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Meterreading_Avg_Fields = {
  __typename?: 'core_meterreading_avg_fields';
  id?: Maybe<Scalars['Float']>;
  reading?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_meterreading" */
export type Core_Meterreading_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  reading?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_meterreading". All fields are combined with a logical 'AND'. */
export type Core_Meterreading_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Meterreading_Bool_Exp>>;
  _not?: InputMaybe<Core_Meterreading_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Meterreading_Bool_Exp>>;
  core_meter?: InputMaybe<Core_Meter_Bool_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  meter_id?: InputMaybe<String_Comparison_Exp>;
  reading?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_meterreading" */
export enum Core_Meterreading_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreMeterreadingPkey = 'core_meterreading_pkey'
}

/** input type for incrementing numeric columns in table "core_meterreading" */
export type Core_Meterreading_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  reading?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "core_meterreading" */
export type Core_Meterreading_Insert_Input = {
  core_meter?: InputMaybe<Core_Meter_Obj_Rel_Insert_Input>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  meter_id?: InputMaybe<Scalars['String']>;
  reading?: InputMaybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Core_Meterreading_Max_Fields = {
  __typename?: 'core_meterreading_max_fields';
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  meter_id?: Maybe<Scalars['String']>;
  reading?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "core_meterreading" */
export type Core_Meterreading_Max_Order_By = {
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meter_id?: InputMaybe<Order_By>;
  reading?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Meterreading_Min_Fields = {
  __typename?: 'core_meterreading_min_fields';
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  meter_id?: Maybe<Scalars['String']>;
  reading?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "core_meterreading" */
export type Core_Meterreading_Min_Order_By = {
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meter_id?: InputMaybe<Order_By>;
  reading?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_meterreading" */
export type Core_Meterreading_Mutation_Response = {
  __typename?: 'core_meterreading_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Meterreading>;
};

/** on_conflict condition type for table "core_meterreading" */
export type Core_Meterreading_On_Conflict = {
  constraint: Core_Meterreading_Constraint;
  update_columns?: Array<Core_Meterreading_Update_Column>;
  where?: InputMaybe<Core_Meterreading_Bool_Exp>;
};

/** Ordering options when selecting data from "core_meterreading". */
export type Core_Meterreading_Order_By = {
  core_meter?: InputMaybe<Core_Meter_Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meter_id?: InputMaybe<Order_By>;
  reading?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_meterreading */
export type Core_Meterreading_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_meterreading" */
export enum Core_Meterreading_Select_Column {
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  MeterId = 'meter_id',
  /** column name */
  Reading = 'reading'
}

/** select "core_meterreading_aggregate_bool_exp_avg_arguments_columns" columns of table "core_meterreading" */
export enum Core_Meterreading_Select_Column_Core_Meterreading_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Reading = 'reading'
}

/** select "core_meterreading_aggregate_bool_exp_corr_arguments_columns" columns of table "core_meterreading" */
export enum Core_Meterreading_Select_Column_Core_Meterreading_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Reading = 'reading'
}

/** select "core_meterreading_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "core_meterreading" */
export enum Core_Meterreading_Select_Column_Core_Meterreading_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Reading = 'reading'
}

/** select "core_meterreading_aggregate_bool_exp_max_arguments_columns" columns of table "core_meterreading" */
export enum Core_Meterreading_Select_Column_Core_Meterreading_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Reading = 'reading'
}

/** select "core_meterreading_aggregate_bool_exp_min_arguments_columns" columns of table "core_meterreading" */
export enum Core_Meterreading_Select_Column_Core_Meterreading_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Reading = 'reading'
}

/** select "core_meterreading_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "core_meterreading" */
export enum Core_Meterreading_Select_Column_Core_Meterreading_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Reading = 'reading'
}

/** select "core_meterreading_aggregate_bool_exp_sum_arguments_columns" columns of table "core_meterreading" */
export enum Core_Meterreading_Select_Column_Core_Meterreading_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Reading = 'reading'
}

/** select "core_meterreading_aggregate_bool_exp_var_samp_arguments_columns" columns of table "core_meterreading" */
export enum Core_Meterreading_Select_Column_Core_Meterreading_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Reading = 'reading'
}

/** input type for updating data in table "core_meterreading" */
export type Core_Meterreading_Set_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  meter_id?: InputMaybe<Scalars['String']>;
  reading?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Core_Meterreading_Stddev_Fields = {
  __typename?: 'core_meterreading_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  reading?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_meterreading" */
export type Core_Meterreading_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  reading?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Meterreading_Stddev_Pop_Fields = {
  __typename?: 'core_meterreading_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  reading?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_meterreading" */
export type Core_Meterreading_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  reading?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Meterreading_Stddev_Samp_Fields = {
  __typename?: 'core_meterreading_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  reading?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_meterreading" */
export type Core_Meterreading_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  reading?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_meterreading" */
export type Core_Meterreading_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Meterreading_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Meterreading_Stream_Cursor_Value_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  meter_id?: InputMaybe<Scalars['String']>;
  reading?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Core_Meterreading_Sum_Fields = {
  __typename?: 'core_meterreading_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  reading?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "core_meterreading" */
export type Core_Meterreading_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  reading?: InputMaybe<Order_By>;
};

/** update columns of table "core_meterreading" */
export enum Core_Meterreading_Update_Column {
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  MeterId = 'meter_id',
  /** column name */
  Reading = 'reading'
}

export type Core_Meterreading_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Meterreading_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Meterreading_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Meterreading_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Meterreading_Var_Pop_Fields = {
  __typename?: 'core_meterreading_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  reading?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_meterreading" */
export type Core_Meterreading_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  reading?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Meterreading_Var_Samp_Fields = {
  __typename?: 'core_meterreading_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  reading?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_meterreading" */
export type Core_Meterreading_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  reading?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Meterreading_Variance_Fields = {
  __typename?: 'core_meterreading_variance_fields';
  id?: Maybe<Scalars['Float']>;
  reading?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_meterreading" */
export type Core_Meterreading_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  reading?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_metric" */
export type Core_Metric = {
  __typename?: 'core_metric';
  cabin_id: Scalars['bigint'];
  /** An object relationship */
  core_cabin: Core_Cabin;
  /** An object relationship */
  core_site: Core_Site;
  created_on: Scalars['timestamptz'];
  device_serial_number: Scalars['String'];
  device_type: Scalars['String'];
  id: Scalars['bigint'];
  measurement: Scalars['String'];
  site_id: Scalars['bigint'];
  unit: Scalars['String'];
  value: Scalars['float8'];
};

/** aggregated selection of "core_metric" */
export type Core_Metric_Aggregate = {
  __typename?: 'core_metric_aggregate';
  aggregate?: Maybe<Core_Metric_Aggregate_Fields>;
  nodes: Array<Core_Metric>;
};

export type Core_Metric_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Core_Metric_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Core_Metric_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Core_Metric_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Core_Metric_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Core_Metric_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Core_Metric_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Core_Metric_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Core_Metric_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Core_Metric_Aggregate_Bool_Exp_Var_Samp>;
};

export type Core_Metric_Aggregate_Bool_Exp_Avg = {
  arguments: Core_Metric_Select_Column_Core_Metric_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Metric_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Metric_Aggregate_Bool_Exp_Corr = {
  arguments: Core_Metric_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Metric_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Metric_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Core_Metric_Select_Column_Core_Metric_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Core_Metric_Select_Column_Core_Metric_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Core_Metric_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Metric_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Metric_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Core_Metric_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Core_Metric_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Metric_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Metric_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Core_Metric_Select_Column_Core_Metric_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Core_Metric_Select_Column_Core_Metric_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Core_Metric_Aggregate_Bool_Exp_Max = {
  arguments: Core_Metric_Select_Column_Core_Metric_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Metric_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Metric_Aggregate_Bool_Exp_Min = {
  arguments: Core_Metric_Select_Column_Core_Metric_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Metric_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Metric_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Core_Metric_Select_Column_Core_Metric_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Metric_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Metric_Aggregate_Bool_Exp_Sum = {
  arguments: Core_Metric_Select_Column_Core_Metric_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Metric_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Metric_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Core_Metric_Select_Column_Core_Metric_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Metric_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "core_metric" */
export type Core_Metric_Aggregate_Fields = {
  __typename?: 'core_metric_aggregate_fields';
  avg?: Maybe<Core_Metric_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Metric_Max_Fields>;
  min?: Maybe<Core_Metric_Min_Fields>;
  stddev?: Maybe<Core_Metric_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Metric_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Metric_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Metric_Sum_Fields>;
  var_pop?: Maybe<Core_Metric_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Metric_Var_Samp_Fields>;
  variance?: Maybe<Core_Metric_Variance_Fields>;
};


/** aggregate fields of "core_metric" */
export type Core_Metric_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Metric_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_metric" */
export type Core_Metric_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Metric_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Metric_Max_Order_By>;
  min?: InputMaybe<Core_Metric_Min_Order_By>;
  stddev?: InputMaybe<Core_Metric_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Metric_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Metric_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Metric_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Metric_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Metric_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Metric_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_metric" */
export type Core_Metric_Arr_Rel_Insert_Input = {
  data: Array<Core_Metric_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Metric_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Metric_Avg_Fields = {
  __typename?: 'core_metric_avg_fields';
  cabin_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_metric" */
export type Core_Metric_Avg_Order_By = {
  cabin_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_metric". All fields are combined with a logical 'AND'. */
export type Core_Metric_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Metric_Bool_Exp>>;
  _not?: InputMaybe<Core_Metric_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Metric_Bool_Exp>>;
  cabin_id?: InputMaybe<Bigint_Comparison_Exp>;
  core_cabin?: InputMaybe<Core_Cabin_Bool_Exp>;
  core_site?: InputMaybe<Core_Site_Bool_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  device_serial_number?: InputMaybe<String_Comparison_Exp>;
  device_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  measurement?: InputMaybe<String_Comparison_Exp>;
  site_id?: InputMaybe<Bigint_Comparison_Exp>;
  unit?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_metric" */
export enum Core_Metric_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreMetricPkey = 'core_metric_pkey'
}

/** input type for incrementing numeric columns in table "core_metric" */
export type Core_Metric_Inc_Input = {
  cabin_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  value?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "core_metric" */
export type Core_Metric_Insert_Input = {
  cabin_id?: InputMaybe<Scalars['bigint']>;
  core_cabin?: InputMaybe<Core_Cabin_Obj_Rel_Insert_Input>;
  core_site?: InputMaybe<Core_Site_Obj_Rel_Insert_Input>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  device_serial_number?: InputMaybe<Scalars['String']>;
  device_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  measurement?: InputMaybe<Scalars['String']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  unit?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Core_Metric_Max_Fields = {
  __typename?: 'core_metric_max_fields';
  cabin_id?: Maybe<Scalars['bigint']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  device_serial_number?: Maybe<Scalars['String']>;
  device_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  measurement?: Maybe<Scalars['String']>;
  site_id?: Maybe<Scalars['bigint']>;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "core_metric" */
export type Core_Metric_Max_Order_By = {
  cabin_id?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  device_serial_number?: InputMaybe<Order_By>;
  device_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  measurement?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Metric_Min_Fields = {
  __typename?: 'core_metric_min_fields';
  cabin_id?: Maybe<Scalars['bigint']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  device_serial_number?: Maybe<Scalars['String']>;
  device_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  measurement?: Maybe<Scalars['String']>;
  site_id?: Maybe<Scalars['bigint']>;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "core_metric" */
export type Core_Metric_Min_Order_By = {
  cabin_id?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  device_serial_number?: InputMaybe<Order_By>;
  device_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  measurement?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_metric" */
export type Core_Metric_Mutation_Response = {
  __typename?: 'core_metric_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Metric>;
};

/** on_conflict condition type for table "core_metric" */
export type Core_Metric_On_Conflict = {
  constraint: Core_Metric_Constraint;
  update_columns?: Array<Core_Metric_Update_Column>;
  where?: InputMaybe<Core_Metric_Bool_Exp>;
};

/** Ordering options when selecting data from "core_metric". */
export type Core_Metric_Order_By = {
  cabin_id?: InputMaybe<Order_By>;
  core_cabin?: InputMaybe<Core_Cabin_Order_By>;
  core_site?: InputMaybe<Core_Site_Order_By>;
  created_on?: InputMaybe<Order_By>;
  device_serial_number?: InputMaybe<Order_By>;
  device_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  measurement?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_metric */
export type Core_Metric_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_metric" */
export enum Core_Metric_Select_Column {
  /** column name */
  CabinId = 'cabin_id',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  DeviceSerialNumber = 'device_serial_number',
  /** column name */
  DeviceType = 'device_type',
  /** column name */
  Id = 'id',
  /** column name */
  Measurement = 'measurement',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  Unit = 'unit',
  /** column name */
  Value = 'value'
}

/** select "core_metric_aggregate_bool_exp_avg_arguments_columns" columns of table "core_metric" */
export enum Core_Metric_Select_Column_Core_Metric_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Value = 'value'
}

/** select "core_metric_aggregate_bool_exp_corr_arguments_columns" columns of table "core_metric" */
export enum Core_Metric_Select_Column_Core_Metric_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Value = 'value'
}

/** select "core_metric_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "core_metric" */
export enum Core_Metric_Select_Column_Core_Metric_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Value = 'value'
}

/** select "core_metric_aggregate_bool_exp_max_arguments_columns" columns of table "core_metric" */
export enum Core_Metric_Select_Column_Core_Metric_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Value = 'value'
}

/** select "core_metric_aggregate_bool_exp_min_arguments_columns" columns of table "core_metric" */
export enum Core_Metric_Select_Column_Core_Metric_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Value = 'value'
}

/** select "core_metric_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "core_metric" */
export enum Core_Metric_Select_Column_Core_Metric_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Value = 'value'
}

/** select "core_metric_aggregate_bool_exp_sum_arguments_columns" columns of table "core_metric" */
export enum Core_Metric_Select_Column_Core_Metric_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Value = 'value'
}

/** select "core_metric_aggregate_bool_exp_var_samp_arguments_columns" columns of table "core_metric" */
export enum Core_Metric_Select_Column_Core_Metric_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "core_metric" */
export type Core_Metric_Set_Input = {
  cabin_id?: InputMaybe<Scalars['bigint']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  device_serial_number?: InputMaybe<Scalars['String']>;
  device_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  measurement?: InputMaybe<Scalars['String']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  unit?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Core_Metric_Stddev_Fields = {
  __typename?: 'core_metric_stddev_fields';
  cabin_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_metric" */
export type Core_Metric_Stddev_Order_By = {
  cabin_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Metric_Stddev_Pop_Fields = {
  __typename?: 'core_metric_stddev_pop_fields';
  cabin_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_metric" */
export type Core_Metric_Stddev_Pop_Order_By = {
  cabin_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Metric_Stddev_Samp_Fields = {
  __typename?: 'core_metric_stddev_samp_fields';
  cabin_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_metric" */
export type Core_Metric_Stddev_Samp_Order_By = {
  cabin_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_metric" */
export type Core_Metric_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Metric_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Metric_Stream_Cursor_Value_Input = {
  cabin_id?: InputMaybe<Scalars['bigint']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  device_serial_number?: InputMaybe<Scalars['String']>;
  device_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  measurement?: InputMaybe<Scalars['String']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  unit?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Core_Metric_Sum_Fields = {
  __typename?: 'core_metric_sum_fields';
  cabin_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
  value?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "core_metric" */
export type Core_Metric_Sum_Order_By = {
  cabin_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "core_metric" */
export enum Core_Metric_Update_Column {
  /** column name */
  CabinId = 'cabin_id',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  DeviceSerialNumber = 'device_serial_number',
  /** column name */
  DeviceType = 'device_type',
  /** column name */
  Id = 'id',
  /** column name */
  Measurement = 'measurement',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  Unit = 'unit',
  /** column name */
  Value = 'value'
}

export type Core_Metric_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Metric_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Metric_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Metric_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Metric_Var_Pop_Fields = {
  __typename?: 'core_metric_var_pop_fields';
  cabin_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_metric" */
export type Core_Metric_Var_Pop_Order_By = {
  cabin_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Metric_Var_Samp_Fields = {
  __typename?: 'core_metric_var_samp_fields';
  cabin_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_metric" */
export type Core_Metric_Var_Samp_Order_By = {
  cabin_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Metric_Variance_Fields = {
  __typename?: 'core_metric_variance_fields';
  cabin_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_metric" */
export type Core_Metric_Variance_Order_By = {
  cabin_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_municipality" */
export type Core_Municipality = {
  __typename?: 'core_municipality';
  /** An array relationship */
  core_powerproviders: Array<Core_Powerprovider>;
  /** An aggregate relationship */
  core_powerproviders_aggregate: Core_Powerprovider_Aggregate;
  /** An object relationship */
  core_province: Core_Province;
  id: Scalars['bigint'];
  municipality: Scalars['String'];
  province_id: Scalars['bigint'];
};


/** columns and relationships of "core_municipality" */
export type Core_MunicipalityCore_PowerprovidersArgs = {
  distinct_on?: InputMaybe<Array<Core_Powerprovider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Powerprovider_Order_By>>;
  where?: InputMaybe<Core_Powerprovider_Bool_Exp>;
};


/** columns and relationships of "core_municipality" */
export type Core_MunicipalityCore_Powerproviders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Powerprovider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Powerprovider_Order_By>>;
  where?: InputMaybe<Core_Powerprovider_Bool_Exp>;
};

/** aggregated selection of "core_municipality" */
export type Core_Municipality_Aggregate = {
  __typename?: 'core_municipality_aggregate';
  aggregate?: Maybe<Core_Municipality_Aggregate_Fields>;
  nodes: Array<Core_Municipality>;
};

export type Core_Municipality_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Municipality_Aggregate_Bool_Exp_Count>;
};

export type Core_Municipality_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Municipality_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Municipality_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_municipality" */
export type Core_Municipality_Aggregate_Fields = {
  __typename?: 'core_municipality_aggregate_fields';
  avg?: Maybe<Core_Municipality_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Municipality_Max_Fields>;
  min?: Maybe<Core_Municipality_Min_Fields>;
  stddev?: Maybe<Core_Municipality_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Municipality_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Municipality_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Municipality_Sum_Fields>;
  var_pop?: Maybe<Core_Municipality_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Municipality_Var_Samp_Fields>;
  variance?: Maybe<Core_Municipality_Variance_Fields>;
};


/** aggregate fields of "core_municipality" */
export type Core_Municipality_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Municipality_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_municipality" */
export type Core_Municipality_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Municipality_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Municipality_Max_Order_By>;
  min?: InputMaybe<Core_Municipality_Min_Order_By>;
  stddev?: InputMaybe<Core_Municipality_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Municipality_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Municipality_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Municipality_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Municipality_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Municipality_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Municipality_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_municipality" */
export type Core_Municipality_Arr_Rel_Insert_Input = {
  data: Array<Core_Municipality_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Municipality_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Municipality_Avg_Fields = {
  __typename?: 'core_municipality_avg_fields';
  id?: Maybe<Scalars['Float']>;
  province_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_municipality" */
export type Core_Municipality_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  province_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_municipality". All fields are combined with a logical 'AND'. */
export type Core_Municipality_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Municipality_Bool_Exp>>;
  _not?: InputMaybe<Core_Municipality_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Municipality_Bool_Exp>>;
  core_powerproviders?: InputMaybe<Core_Powerprovider_Bool_Exp>;
  core_powerproviders_aggregate?: InputMaybe<Core_Powerprovider_Aggregate_Bool_Exp>;
  core_province?: InputMaybe<Core_Province_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  municipality?: InputMaybe<String_Comparison_Exp>;
  province_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_municipality" */
export enum Core_Municipality_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreMunicipalityPkey = 'core_municipality_pkey'
}

/** input type for incrementing numeric columns in table "core_municipality" */
export type Core_Municipality_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  province_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_municipality" */
export type Core_Municipality_Insert_Input = {
  core_powerproviders?: InputMaybe<Core_Powerprovider_Arr_Rel_Insert_Input>;
  core_province?: InputMaybe<Core_Province_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  municipality?: InputMaybe<Scalars['String']>;
  province_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_Municipality_Max_Fields = {
  __typename?: 'core_municipality_max_fields';
  id?: Maybe<Scalars['bigint']>;
  municipality?: Maybe<Scalars['String']>;
  province_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "core_municipality" */
export type Core_Municipality_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  municipality?: InputMaybe<Order_By>;
  province_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Municipality_Min_Fields = {
  __typename?: 'core_municipality_min_fields';
  id?: Maybe<Scalars['bigint']>;
  municipality?: Maybe<Scalars['String']>;
  province_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "core_municipality" */
export type Core_Municipality_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  municipality?: InputMaybe<Order_By>;
  province_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_municipality" */
export type Core_Municipality_Mutation_Response = {
  __typename?: 'core_municipality_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Municipality>;
};

/** input type for inserting object relation for remote table "core_municipality" */
export type Core_Municipality_Obj_Rel_Insert_Input = {
  data: Core_Municipality_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Municipality_On_Conflict>;
};

/** on_conflict condition type for table "core_municipality" */
export type Core_Municipality_On_Conflict = {
  constraint: Core_Municipality_Constraint;
  update_columns?: Array<Core_Municipality_Update_Column>;
  where?: InputMaybe<Core_Municipality_Bool_Exp>;
};

/** Ordering options when selecting data from "core_municipality". */
export type Core_Municipality_Order_By = {
  core_powerproviders_aggregate?: InputMaybe<Core_Powerprovider_Aggregate_Order_By>;
  core_province?: InputMaybe<Core_Province_Order_By>;
  id?: InputMaybe<Order_By>;
  municipality?: InputMaybe<Order_By>;
  province_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_municipality */
export type Core_Municipality_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_municipality" */
export enum Core_Municipality_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Municipality = 'municipality',
  /** column name */
  ProvinceId = 'province_id'
}

/** input type for updating data in table "core_municipality" */
export type Core_Municipality_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  municipality?: InputMaybe<Scalars['String']>;
  province_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_Municipality_Stddev_Fields = {
  __typename?: 'core_municipality_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  province_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_municipality" */
export type Core_Municipality_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  province_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Municipality_Stddev_Pop_Fields = {
  __typename?: 'core_municipality_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  province_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_municipality" */
export type Core_Municipality_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  province_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Municipality_Stddev_Samp_Fields = {
  __typename?: 'core_municipality_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  province_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_municipality" */
export type Core_Municipality_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  province_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_municipality" */
export type Core_Municipality_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Municipality_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Municipality_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  municipality?: InputMaybe<Scalars['String']>;
  province_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_Municipality_Sum_Fields = {
  __typename?: 'core_municipality_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  province_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_municipality" */
export type Core_Municipality_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  province_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_municipality" */
export enum Core_Municipality_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Municipality = 'municipality',
  /** column name */
  ProvinceId = 'province_id'
}

export type Core_Municipality_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Municipality_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Municipality_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Municipality_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Municipality_Var_Pop_Fields = {
  __typename?: 'core_municipality_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  province_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_municipality" */
export type Core_Municipality_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  province_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Municipality_Var_Samp_Fields = {
  __typename?: 'core_municipality_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  province_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_municipality" */
export type Core_Municipality_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  province_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Municipality_Variance_Fields = {
  __typename?: 'core_municipality_variance_fields';
  id?: Maybe<Scalars['Float']>;
  province_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_municipality" */
export type Core_Municipality_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  province_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_noneuserpersonale" */
export type Core_Noneuserpersonale = {
  __typename?: 'core_noneuserpersonale';
  company_id: Scalars['bigint'];
  /** An object relationship */
  core_company: Core_Company;
  first_name: Scalars['String'];
  id: Scalars['uuid'];
  last_name: Scalars['String'];
};

/** aggregated selection of "core_noneuserpersonale" */
export type Core_Noneuserpersonale_Aggregate = {
  __typename?: 'core_noneuserpersonale_aggregate';
  aggregate?: Maybe<Core_Noneuserpersonale_Aggregate_Fields>;
  nodes: Array<Core_Noneuserpersonale>;
};

export type Core_Noneuserpersonale_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Noneuserpersonale_Aggregate_Bool_Exp_Count>;
};

export type Core_Noneuserpersonale_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Noneuserpersonale_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Noneuserpersonale_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_noneuserpersonale" */
export type Core_Noneuserpersonale_Aggregate_Fields = {
  __typename?: 'core_noneuserpersonale_aggregate_fields';
  avg?: Maybe<Core_Noneuserpersonale_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Noneuserpersonale_Max_Fields>;
  min?: Maybe<Core_Noneuserpersonale_Min_Fields>;
  stddev?: Maybe<Core_Noneuserpersonale_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Noneuserpersonale_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Noneuserpersonale_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Noneuserpersonale_Sum_Fields>;
  var_pop?: Maybe<Core_Noneuserpersonale_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Noneuserpersonale_Var_Samp_Fields>;
  variance?: Maybe<Core_Noneuserpersonale_Variance_Fields>;
};


/** aggregate fields of "core_noneuserpersonale" */
export type Core_Noneuserpersonale_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Noneuserpersonale_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_noneuserpersonale" */
export type Core_Noneuserpersonale_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Noneuserpersonale_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Noneuserpersonale_Max_Order_By>;
  min?: InputMaybe<Core_Noneuserpersonale_Min_Order_By>;
  stddev?: InputMaybe<Core_Noneuserpersonale_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Noneuserpersonale_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Noneuserpersonale_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Noneuserpersonale_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Noneuserpersonale_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Noneuserpersonale_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Noneuserpersonale_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_noneuserpersonale" */
export type Core_Noneuserpersonale_Arr_Rel_Insert_Input = {
  data: Array<Core_Noneuserpersonale_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Noneuserpersonale_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Noneuserpersonale_Avg_Fields = {
  __typename?: 'core_noneuserpersonale_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_noneuserpersonale" */
export type Core_Noneuserpersonale_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_noneuserpersonale". All fields are combined with a logical 'AND'. */
export type Core_Noneuserpersonale_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Noneuserpersonale_Bool_Exp>>;
  _not?: InputMaybe<Core_Noneuserpersonale_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Noneuserpersonale_Bool_Exp>>;
  company_id?: InputMaybe<Bigint_Comparison_Exp>;
  core_company?: InputMaybe<Core_Company_Bool_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_noneuserpersonale" */
export enum Core_Noneuserpersonale_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreNoneuserpersonalePkey = 'core_noneuserpersonale_pkey'
}

/** input type for incrementing numeric columns in table "core_noneuserpersonale" */
export type Core_Noneuserpersonale_Inc_Input = {
  company_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_noneuserpersonale" */
export type Core_Noneuserpersonale_Insert_Input = {
  company_id?: InputMaybe<Scalars['bigint']>;
  core_company?: InputMaybe<Core_Company_Obj_Rel_Insert_Input>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Noneuserpersonale_Max_Fields = {
  __typename?: 'core_noneuserpersonale_max_fields';
  company_id?: Maybe<Scalars['bigint']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "core_noneuserpersonale" */
export type Core_Noneuserpersonale_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Noneuserpersonale_Min_Fields = {
  __typename?: 'core_noneuserpersonale_min_fields';
  company_id?: Maybe<Scalars['bigint']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "core_noneuserpersonale" */
export type Core_Noneuserpersonale_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_noneuserpersonale" */
export type Core_Noneuserpersonale_Mutation_Response = {
  __typename?: 'core_noneuserpersonale_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Noneuserpersonale>;
};

/** on_conflict condition type for table "core_noneuserpersonale" */
export type Core_Noneuserpersonale_On_Conflict = {
  constraint: Core_Noneuserpersonale_Constraint;
  update_columns?: Array<Core_Noneuserpersonale_Update_Column>;
  where?: InputMaybe<Core_Noneuserpersonale_Bool_Exp>;
};

/** Ordering options when selecting data from "core_noneuserpersonale". */
export type Core_Noneuserpersonale_Order_By = {
  company_id?: InputMaybe<Order_By>;
  core_company?: InputMaybe<Core_Company_Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_noneuserpersonale */
export type Core_Noneuserpersonale_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "core_noneuserpersonale" */
export enum Core_Noneuserpersonale_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name'
}

/** input type for updating data in table "core_noneuserpersonale" */
export type Core_Noneuserpersonale_Set_Input = {
  company_id?: InputMaybe<Scalars['bigint']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Noneuserpersonale_Stddev_Fields = {
  __typename?: 'core_noneuserpersonale_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_noneuserpersonale" */
export type Core_Noneuserpersonale_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Noneuserpersonale_Stddev_Pop_Fields = {
  __typename?: 'core_noneuserpersonale_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_noneuserpersonale" */
export type Core_Noneuserpersonale_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Noneuserpersonale_Stddev_Samp_Fields = {
  __typename?: 'core_noneuserpersonale_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_noneuserpersonale" */
export type Core_Noneuserpersonale_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_noneuserpersonale" */
export type Core_Noneuserpersonale_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Noneuserpersonale_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Noneuserpersonale_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['bigint']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Noneuserpersonale_Sum_Fields = {
  __typename?: 'core_noneuserpersonale_sum_fields';
  company_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_noneuserpersonale" */
export type Core_Noneuserpersonale_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_noneuserpersonale" */
export enum Core_Noneuserpersonale_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name'
}

export type Core_Noneuserpersonale_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Noneuserpersonale_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Noneuserpersonale_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Noneuserpersonale_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Noneuserpersonale_Var_Pop_Fields = {
  __typename?: 'core_noneuserpersonale_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_noneuserpersonale" */
export type Core_Noneuserpersonale_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Noneuserpersonale_Var_Samp_Fields = {
  __typename?: 'core_noneuserpersonale_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_noneuserpersonale" */
export type Core_Noneuserpersonale_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Noneuserpersonale_Variance_Fields = {
  __typename?: 'core_noneuserpersonale_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_noneuserpersonale" */
export type Core_Noneuserpersonale_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_paymenttype" */
export type Core_Paymenttype = {
  __typename?: 'core_paymenttype';
  id: Scalars['bigint'];
  payment_type: Scalars['String'];
};

/** aggregated selection of "core_paymenttype" */
export type Core_Paymenttype_Aggregate = {
  __typename?: 'core_paymenttype_aggregate';
  aggregate?: Maybe<Core_Paymenttype_Aggregate_Fields>;
  nodes: Array<Core_Paymenttype>;
};

/** aggregate fields of "core_paymenttype" */
export type Core_Paymenttype_Aggregate_Fields = {
  __typename?: 'core_paymenttype_aggregate_fields';
  avg?: Maybe<Core_Paymenttype_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Paymenttype_Max_Fields>;
  min?: Maybe<Core_Paymenttype_Min_Fields>;
  stddev?: Maybe<Core_Paymenttype_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Paymenttype_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Paymenttype_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Paymenttype_Sum_Fields>;
  var_pop?: Maybe<Core_Paymenttype_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Paymenttype_Var_Samp_Fields>;
  variance?: Maybe<Core_Paymenttype_Variance_Fields>;
};


/** aggregate fields of "core_paymenttype" */
export type Core_Paymenttype_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Paymenttype_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Core_Paymenttype_Avg_Fields = {
  __typename?: 'core_paymenttype_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "core_paymenttype". All fields are combined with a logical 'AND'. */
export type Core_Paymenttype_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Paymenttype_Bool_Exp>>;
  _not?: InputMaybe<Core_Paymenttype_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Paymenttype_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  payment_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_paymenttype" */
export enum Core_Paymenttype_Constraint {
  /** unique or primary key constraint on columns "id" */
  CorePaymenttypePkey = 'core_paymenttype_pkey'
}

/** input type for incrementing numeric columns in table "core_paymenttype" */
export type Core_Paymenttype_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_paymenttype" */
export type Core_Paymenttype_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  payment_type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Paymenttype_Max_Fields = {
  __typename?: 'core_paymenttype_max_fields';
  id?: Maybe<Scalars['bigint']>;
  payment_type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Core_Paymenttype_Min_Fields = {
  __typename?: 'core_paymenttype_min_fields';
  id?: Maybe<Scalars['bigint']>;
  payment_type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "core_paymenttype" */
export type Core_Paymenttype_Mutation_Response = {
  __typename?: 'core_paymenttype_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Paymenttype>;
};

/** on_conflict condition type for table "core_paymenttype" */
export type Core_Paymenttype_On_Conflict = {
  constraint: Core_Paymenttype_Constraint;
  update_columns?: Array<Core_Paymenttype_Update_Column>;
  where?: InputMaybe<Core_Paymenttype_Bool_Exp>;
};

/** Ordering options when selecting data from "core_paymenttype". */
export type Core_Paymenttype_Order_By = {
  id?: InputMaybe<Order_By>;
  payment_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_paymenttype */
export type Core_Paymenttype_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_paymenttype" */
export enum Core_Paymenttype_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PaymentType = 'payment_type'
}

/** input type for updating data in table "core_paymenttype" */
export type Core_Paymenttype_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  payment_type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Paymenttype_Stddev_Fields = {
  __typename?: 'core_paymenttype_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Core_Paymenttype_Stddev_Pop_Fields = {
  __typename?: 'core_paymenttype_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Core_Paymenttype_Stddev_Samp_Fields = {
  __typename?: 'core_paymenttype_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "core_paymenttype" */
export type Core_Paymenttype_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Paymenttype_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Paymenttype_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  payment_type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Paymenttype_Sum_Fields = {
  __typename?: 'core_paymenttype_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "core_paymenttype" */
export enum Core_Paymenttype_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PaymentType = 'payment_type'
}

export type Core_Paymenttype_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Paymenttype_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Paymenttype_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Paymenttype_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Paymenttype_Var_Pop_Fields = {
  __typename?: 'core_paymenttype_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Core_Paymenttype_Var_Samp_Fields = {
  __typename?: 'core_paymenttype_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Core_Paymenttype_Variance_Fields = {
  __typename?: 'core_paymenttype_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "core_personaleimage" */
export type Core_Personaleimage = {
  __typename?: 'core_personaleimage';
  /** An object relationship */
  core_user: Core_User;
  id: Scalars['bigint'];
  image: Scalars['String'];
  personale_id: Scalars['bigint'];
};

/** aggregated selection of "core_personaleimage" */
export type Core_Personaleimage_Aggregate = {
  __typename?: 'core_personaleimage_aggregate';
  aggregate?: Maybe<Core_Personaleimage_Aggregate_Fields>;
  nodes: Array<Core_Personaleimage>;
};

export type Core_Personaleimage_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Personaleimage_Aggregate_Bool_Exp_Count>;
};

export type Core_Personaleimage_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Personaleimage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Personaleimage_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_personaleimage" */
export type Core_Personaleimage_Aggregate_Fields = {
  __typename?: 'core_personaleimage_aggregate_fields';
  avg?: Maybe<Core_Personaleimage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Personaleimage_Max_Fields>;
  min?: Maybe<Core_Personaleimage_Min_Fields>;
  stddev?: Maybe<Core_Personaleimage_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Personaleimage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Personaleimage_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Personaleimage_Sum_Fields>;
  var_pop?: Maybe<Core_Personaleimage_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Personaleimage_Var_Samp_Fields>;
  variance?: Maybe<Core_Personaleimage_Variance_Fields>;
};


/** aggregate fields of "core_personaleimage" */
export type Core_Personaleimage_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Personaleimage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_personaleimage" */
export type Core_Personaleimage_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Personaleimage_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Personaleimage_Max_Order_By>;
  min?: InputMaybe<Core_Personaleimage_Min_Order_By>;
  stddev?: InputMaybe<Core_Personaleimage_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Personaleimage_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Personaleimage_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Personaleimage_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Personaleimage_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Personaleimage_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Personaleimage_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_personaleimage" */
export type Core_Personaleimage_Arr_Rel_Insert_Input = {
  data: Array<Core_Personaleimage_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Personaleimage_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Personaleimage_Avg_Fields = {
  __typename?: 'core_personaleimage_avg_fields';
  id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_personaleimage" */
export type Core_Personaleimage_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_personaleimage". All fields are combined with a logical 'AND'. */
export type Core_Personaleimage_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Personaleimage_Bool_Exp>>;
  _not?: InputMaybe<Core_Personaleimage_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Personaleimage_Bool_Exp>>;
  core_user?: InputMaybe<Core_User_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  personale_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_personaleimage" */
export enum Core_Personaleimage_Constraint {
  /** unique or primary key constraint on columns "id" */
  CorePersonaleimagePkey = 'core_personaleimage_pkey'
}

/** input type for incrementing numeric columns in table "core_personaleimage" */
export type Core_Personaleimage_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  personale_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_personaleimage" */
export type Core_Personaleimage_Insert_Input = {
  core_user?: InputMaybe<Core_User_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  image?: InputMaybe<Scalars['String']>;
  personale_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_Personaleimage_Max_Fields = {
  __typename?: 'core_personaleimage_max_fields';
  id?: Maybe<Scalars['bigint']>;
  image?: Maybe<Scalars['String']>;
  personale_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "core_personaleimage" */
export type Core_Personaleimage_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Personaleimage_Min_Fields = {
  __typename?: 'core_personaleimage_min_fields';
  id?: Maybe<Scalars['bigint']>;
  image?: Maybe<Scalars['String']>;
  personale_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "core_personaleimage" */
export type Core_Personaleimage_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_personaleimage" */
export type Core_Personaleimage_Mutation_Response = {
  __typename?: 'core_personaleimage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Personaleimage>;
};

/** on_conflict condition type for table "core_personaleimage" */
export type Core_Personaleimage_On_Conflict = {
  constraint: Core_Personaleimage_Constraint;
  update_columns?: Array<Core_Personaleimage_Update_Column>;
  where?: InputMaybe<Core_Personaleimage_Bool_Exp>;
};

/** Ordering options when selecting data from "core_personaleimage". */
export type Core_Personaleimage_Order_By = {
  core_user?: InputMaybe<Core_User_Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_personaleimage */
export type Core_Personaleimage_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_personaleimage" */
export enum Core_Personaleimage_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  PersonaleId = 'personale_id'
}

/** input type for updating data in table "core_personaleimage" */
export type Core_Personaleimage_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  image?: InputMaybe<Scalars['String']>;
  personale_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_Personaleimage_Stddev_Fields = {
  __typename?: 'core_personaleimage_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_personaleimage" */
export type Core_Personaleimage_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Personaleimage_Stddev_Pop_Fields = {
  __typename?: 'core_personaleimage_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_personaleimage" */
export type Core_Personaleimage_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Personaleimage_Stddev_Samp_Fields = {
  __typename?: 'core_personaleimage_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_personaleimage" */
export type Core_Personaleimage_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_personaleimage" */
export type Core_Personaleimage_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Personaleimage_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Personaleimage_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  image?: InputMaybe<Scalars['String']>;
  personale_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_Personaleimage_Sum_Fields = {
  __typename?: 'core_personaleimage_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  personale_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_personaleimage" */
export type Core_Personaleimage_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_personaleimage" */
export enum Core_Personaleimage_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  PersonaleId = 'personale_id'
}

export type Core_Personaleimage_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Personaleimage_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Personaleimage_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Personaleimage_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Personaleimage_Var_Pop_Fields = {
  __typename?: 'core_personaleimage_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_personaleimage" */
export type Core_Personaleimage_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Personaleimage_Var_Samp_Fields = {
  __typename?: 'core_personaleimage_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_personaleimage" */
export type Core_Personaleimage_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Personaleimage_Variance_Fields = {
  __typename?: 'core_personaleimage_variance_fields';
  id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_personaleimage" */
export type Core_Personaleimage_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_personnelassigned" */
export type Core_Personnelassigned = {
  __typename?: 'core_personnelassigned';
  /** An object relationship */
  core_job: Core_Job;
  /** An object relationship */
  core_user: Core_User;
  id: Scalars['bigint'];
  job_id: Scalars['bigint'];
  personale_id: Scalars['bigint'];
};

/** aggregated selection of "core_personnelassigned" */
export type Core_Personnelassigned_Aggregate = {
  __typename?: 'core_personnelassigned_aggregate';
  aggregate?: Maybe<Core_Personnelassigned_Aggregate_Fields>;
  nodes: Array<Core_Personnelassigned>;
};

export type Core_Personnelassigned_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Personnelassigned_Aggregate_Bool_Exp_Count>;
};

export type Core_Personnelassigned_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Personnelassigned_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Personnelassigned_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_personnelassigned" */
export type Core_Personnelassigned_Aggregate_Fields = {
  __typename?: 'core_personnelassigned_aggregate_fields';
  avg?: Maybe<Core_Personnelassigned_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Personnelassigned_Max_Fields>;
  min?: Maybe<Core_Personnelassigned_Min_Fields>;
  stddev?: Maybe<Core_Personnelassigned_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Personnelassigned_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Personnelassigned_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Personnelassigned_Sum_Fields>;
  var_pop?: Maybe<Core_Personnelassigned_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Personnelassigned_Var_Samp_Fields>;
  variance?: Maybe<Core_Personnelassigned_Variance_Fields>;
};


/** aggregate fields of "core_personnelassigned" */
export type Core_Personnelassigned_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Personnelassigned_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_personnelassigned" */
export type Core_Personnelassigned_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Personnelassigned_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Personnelassigned_Max_Order_By>;
  min?: InputMaybe<Core_Personnelassigned_Min_Order_By>;
  stddev?: InputMaybe<Core_Personnelassigned_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Personnelassigned_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Personnelassigned_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Personnelassigned_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Personnelassigned_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Personnelassigned_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Personnelassigned_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_personnelassigned" */
export type Core_Personnelassigned_Arr_Rel_Insert_Input = {
  data: Array<Core_Personnelassigned_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Personnelassigned_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Personnelassigned_Avg_Fields = {
  __typename?: 'core_personnelassigned_avg_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_personnelassigned" */
export type Core_Personnelassigned_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_personnelassigned". All fields are combined with a logical 'AND'. */
export type Core_Personnelassigned_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Personnelassigned_Bool_Exp>>;
  _not?: InputMaybe<Core_Personnelassigned_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Personnelassigned_Bool_Exp>>;
  core_job?: InputMaybe<Core_Job_Bool_Exp>;
  core_user?: InputMaybe<Core_User_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  job_id?: InputMaybe<Bigint_Comparison_Exp>;
  personale_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_personnelassigned" */
export enum Core_Personnelassigned_Constraint {
  /** unique or primary key constraint on columns "id" */
  CorePersonnelassignedPkey = 'core_personnelassigned_pkey'
}

/** input type for incrementing numeric columns in table "core_personnelassigned" */
export type Core_Personnelassigned_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  job_id?: InputMaybe<Scalars['bigint']>;
  personale_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_personnelassigned" */
export type Core_Personnelassigned_Insert_Input = {
  core_job?: InputMaybe<Core_Job_Obj_Rel_Insert_Input>;
  core_user?: InputMaybe<Core_User_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  job_id?: InputMaybe<Scalars['bigint']>;
  personale_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_Personnelassigned_Max_Fields = {
  __typename?: 'core_personnelassigned_max_fields';
  id?: Maybe<Scalars['bigint']>;
  job_id?: Maybe<Scalars['bigint']>;
  personale_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "core_personnelassigned" */
export type Core_Personnelassigned_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Personnelassigned_Min_Fields = {
  __typename?: 'core_personnelassigned_min_fields';
  id?: Maybe<Scalars['bigint']>;
  job_id?: Maybe<Scalars['bigint']>;
  personale_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "core_personnelassigned" */
export type Core_Personnelassigned_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_personnelassigned" */
export type Core_Personnelassigned_Mutation_Response = {
  __typename?: 'core_personnelassigned_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Personnelassigned>;
};

/** on_conflict condition type for table "core_personnelassigned" */
export type Core_Personnelassigned_On_Conflict = {
  constraint: Core_Personnelassigned_Constraint;
  update_columns?: Array<Core_Personnelassigned_Update_Column>;
  where?: InputMaybe<Core_Personnelassigned_Bool_Exp>;
};

/** Ordering options when selecting data from "core_personnelassigned". */
export type Core_Personnelassigned_Order_By = {
  core_job?: InputMaybe<Core_Job_Order_By>;
  core_user?: InputMaybe<Core_User_Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_personnelassigned */
export type Core_Personnelassigned_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_personnelassigned" */
export enum Core_Personnelassigned_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  PersonaleId = 'personale_id'
}

/** input type for updating data in table "core_personnelassigned" */
export type Core_Personnelassigned_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  job_id?: InputMaybe<Scalars['bigint']>;
  personale_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_Personnelassigned_Stddev_Fields = {
  __typename?: 'core_personnelassigned_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_personnelassigned" */
export type Core_Personnelassigned_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Personnelassigned_Stddev_Pop_Fields = {
  __typename?: 'core_personnelassigned_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_personnelassigned" */
export type Core_Personnelassigned_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Personnelassigned_Stddev_Samp_Fields = {
  __typename?: 'core_personnelassigned_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_personnelassigned" */
export type Core_Personnelassigned_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_personnelassigned" */
export type Core_Personnelassigned_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Personnelassigned_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Personnelassigned_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  job_id?: InputMaybe<Scalars['bigint']>;
  personale_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_Personnelassigned_Sum_Fields = {
  __typename?: 'core_personnelassigned_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  job_id?: Maybe<Scalars['bigint']>;
  personale_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_personnelassigned" */
export type Core_Personnelassigned_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_personnelassigned" */
export enum Core_Personnelassigned_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  PersonaleId = 'personale_id'
}

export type Core_Personnelassigned_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Personnelassigned_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Personnelassigned_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Personnelassigned_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Personnelassigned_Var_Pop_Fields = {
  __typename?: 'core_personnelassigned_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_personnelassigned" */
export type Core_Personnelassigned_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Personnelassigned_Var_Samp_Fields = {
  __typename?: 'core_personnelassigned_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_personnelassigned" */
export type Core_Personnelassigned_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Personnelassigned_Variance_Fields = {
  __typename?: 'core_personnelassigned_variance_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_personnelassigned" */
export type Core_Personnelassigned_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_powerprovider" */
export type Core_Powerprovider = {
  __typename?: 'core_powerprovider';
  /** An object relationship */
  core_municipality: Core_Municipality;
  id: Scalars['bigint'];
  municipality_id: Scalars['bigint'];
  provider: Scalars['String'];
};

/** aggregated selection of "core_powerprovider" */
export type Core_Powerprovider_Aggregate = {
  __typename?: 'core_powerprovider_aggregate';
  aggregate?: Maybe<Core_Powerprovider_Aggregate_Fields>;
  nodes: Array<Core_Powerprovider>;
};

export type Core_Powerprovider_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Powerprovider_Aggregate_Bool_Exp_Count>;
};

export type Core_Powerprovider_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Powerprovider_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Powerprovider_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_powerprovider" */
export type Core_Powerprovider_Aggregate_Fields = {
  __typename?: 'core_powerprovider_aggregate_fields';
  avg?: Maybe<Core_Powerprovider_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Powerprovider_Max_Fields>;
  min?: Maybe<Core_Powerprovider_Min_Fields>;
  stddev?: Maybe<Core_Powerprovider_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Powerprovider_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Powerprovider_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Powerprovider_Sum_Fields>;
  var_pop?: Maybe<Core_Powerprovider_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Powerprovider_Var_Samp_Fields>;
  variance?: Maybe<Core_Powerprovider_Variance_Fields>;
};


/** aggregate fields of "core_powerprovider" */
export type Core_Powerprovider_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Powerprovider_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_powerprovider" */
export type Core_Powerprovider_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Powerprovider_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Powerprovider_Max_Order_By>;
  min?: InputMaybe<Core_Powerprovider_Min_Order_By>;
  stddev?: InputMaybe<Core_Powerprovider_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Powerprovider_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Powerprovider_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Powerprovider_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Powerprovider_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Powerprovider_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Powerprovider_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_powerprovider" */
export type Core_Powerprovider_Arr_Rel_Insert_Input = {
  data: Array<Core_Powerprovider_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Powerprovider_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Powerprovider_Avg_Fields = {
  __typename?: 'core_powerprovider_avg_fields';
  id?: Maybe<Scalars['Float']>;
  municipality_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_powerprovider" */
export type Core_Powerprovider_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  municipality_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_powerprovider". All fields are combined with a logical 'AND'. */
export type Core_Powerprovider_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Powerprovider_Bool_Exp>>;
  _not?: InputMaybe<Core_Powerprovider_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Powerprovider_Bool_Exp>>;
  core_municipality?: InputMaybe<Core_Municipality_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  municipality_id?: InputMaybe<Bigint_Comparison_Exp>;
  provider?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_powerprovider" */
export enum Core_Powerprovider_Constraint {
  /** unique or primary key constraint on columns "id" */
  CorePowerproviderPkey = 'core_powerprovider_pkey'
}

/** input type for incrementing numeric columns in table "core_powerprovider" */
export type Core_Powerprovider_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  municipality_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_powerprovider" */
export type Core_Powerprovider_Insert_Input = {
  core_municipality?: InputMaybe<Core_Municipality_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  municipality_id?: InputMaybe<Scalars['bigint']>;
  provider?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Powerprovider_Max_Fields = {
  __typename?: 'core_powerprovider_max_fields';
  id?: Maybe<Scalars['bigint']>;
  municipality_id?: Maybe<Scalars['bigint']>;
  provider?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "core_powerprovider" */
export type Core_Powerprovider_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  municipality_id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Powerprovider_Min_Fields = {
  __typename?: 'core_powerprovider_min_fields';
  id?: Maybe<Scalars['bigint']>;
  municipality_id?: Maybe<Scalars['bigint']>;
  provider?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "core_powerprovider" */
export type Core_Powerprovider_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  municipality_id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_powerprovider" */
export type Core_Powerprovider_Mutation_Response = {
  __typename?: 'core_powerprovider_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Powerprovider>;
};

/** on_conflict condition type for table "core_powerprovider" */
export type Core_Powerprovider_On_Conflict = {
  constraint: Core_Powerprovider_Constraint;
  update_columns?: Array<Core_Powerprovider_Update_Column>;
  where?: InputMaybe<Core_Powerprovider_Bool_Exp>;
};

/** Ordering options when selecting data from "core_powerprovider". */
export type Core_Powerprovider_Order_By = {
  core_municipality?: InputMaybe<Core_Municipality_Order_By>;
  id?: InputMaybe<Order_By>;
  municipality_id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_powerprovider */
export type Core_Powerprovider_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_powerprovider" */
export enum Core_Powerprovider_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  MunicipalityId = 'municipality_id',
  /** column name */
  Provider = 'provider'
}

/** input type for updating data in table "core_powerprovider" */
export type Core_Powerprovider_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  municipality_id?: InputMaybe<Scalars['bigint']>;
  provider?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Powerprovider_Stddev_Fields = {
  __typename?: 'core_powerprovider_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  municipality_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_powerprovider" */
export type Core_Powerprovider_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  municipality_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Powerprovider_Stddev_Pop_Fields = {
  __typename?: 'core_powerprovider_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  municipality_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_powerprovider" */
export type Core_Powerprovider_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  municipality_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Powerprovider_Stddev_Samp_Fields = {
  __typename?: 'core_powerprovider_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  municipality_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_powerprovider" */
export type Core_Powerprovider_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  municipality_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_powerprovider" */
export type Core_Powerprovider_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Powerprovider_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Powerprovider_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  municipality_id?: InputMaybe<Scalars['bigint']>;
  provider?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Powerprovider_Sum_Fields = {
  __typename?: 'core_powerprovider_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  municipality_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_powerprovider" */
export type Core_Powerprovider_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  municipality_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_powerprovider" */
export enum Core_Powerprovider_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  MunicipalityId = 'municipality_id',
  /** column name */
  Provider = 'provider'
}

export type Core_Powerprovider_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Powerprovider_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Powerprovider_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Powerprovider_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Powerprovider_Var_Pop_Fields = {
  __typename?: 'core_powerprovider_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  municipality_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_powerprovider" */
export type Core_Powerprovider_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  municipality_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Powerprovider_Var_Samp_Fields = {
  __typename?: 'core_powerprovider_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  municipality_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_powerprovider" */
export type Core_Powerprovider_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  municipality_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Powerprovider_Variance_Fields = {
  __typename?: 'core_powerprovider_variance_fields';
  id?: Maybe<Scalars['Float']>;
  municipality_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_powerprovider" */
export type Core_Powerprovider_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  municipality_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_powersourcetype" */
export type Core_Powersourcetype = {
  __typename?: 'core_powersourcetype';
  id: Scalars['bigint'];
  source: Scalars['String'];
};

/** aggregated selection of "core_powersourcetype" */
export type Core_Powersourcetype_Aggregate = {
  __typename?: 'core_powersourcetype_aggregate';
  aggregate?: Maybe<Core_Powersourcetype_Aggregate_Fields>;
  nodes: Array<Core_Powersourcetype>;
};

/** aggregate fields of "core_powersourcetype" */
export type Core_Powersourcetype_Aggregate_Fields = {
  __typename?: 'core_powersourcetype_aggregate_fields';
  avg?: Maybe<Core_Powersourcetype_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Powersourcetype_Max_Fields>;
  min?: Maybe<Core_Powersourcetype_Min_Fields>;
  stddev?: Maybe<Core_Powersourcetype_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Powersourcetype_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Powersourcetype_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Powersourcetype_Sum_Fields>;
  var_pop?: Maybe<Core_Powersourcetype_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Powersourcetype_Var_Samp_Fields>;
  variance?: Maybe<Core_Powersourcetype_Variance_Fields>;
};


/** aggregate fields of "core_powersourcetype" */
export type Core_Powersourcetype_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Powersourcetype_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Core_Powersourcetype_Avg_Fields = {
  __typename?: 'core_powersourcetype_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "core_powersourcetype". All fields are combined with a logical 'AND'. */
export type Core_Powersourcetype_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Powersourcetype_Bool_Exp>>;
  _not?: InputMaybe<Core_Powersourcetype_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Powersourcetype_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_powersourcetype" */
export enum Core_Powersourcetype_Constraint {
  /** unique or primary key constraint on columns "id" */
  CorePowersourcetypePkey = 'core_powersourcetype_pkey'
}

/** input type for incrementing numeric columns in table "core_powersourcetype" */
export type Core_Powersourcetype_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_powersourcetype" */
export type Core_Powersourcetype_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  source?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Powersourcetype_Max_Fields = {
  __typename?: 'core_powersourcetype_max_fields';
  id?: Maybe<Scalars['bigint']>;
  source?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Core_Powersourcetype_Min_Fields = {
  __typename?: 'core_powersourcetype_min_fields';
  id?: Maybe<Scalars['bigint']>;
  source?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "core_powersourcetype" */
export type Core_Powersourcetype_Mutation_Response = {
  __typename?: 'core_powersourcetype_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Powersourcetype>;
};

/** on_conflict condition type for table "core_powersourcetype" */
export type Core_Powersourcetype_On_Conflict = {
  constraint: Core_Powersourcetype_Constraint;
  update_columns?: Array<Core_Powersourcetype_Update_Column>;
  where?: InputMaybe<Core_Powersourcetype_Bool_Exp>;
};

/** Ordering options when selecting data from "core_powersourcetype". */
export type Core_Powersourcetype_Order_By = {
  id?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_powersourcetype */
export type Core_Powersourcetype_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_powersourcetype" */
export enum Core_Powersourcetype_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Source = 'source'
}

/** input type for updating data in table "core_powersourcetype" */
export type Core_Powersourcetype_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  source?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Powersourcetype_Stddev_Fields = {
  __typename?: 'core_powersourcetype_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Core_Powersourcetype_Stddev_Pop_Fields = {
  __typename?: 'core_powersourcetype_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Core_Powersourcetype_Stddev_Samp_Fields = {
  __typename?: 'core_powersourcetype_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "core_powersourcetype" */
export type Core_Powersourcetype_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Powersourcetype_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Powersourcetype_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  source?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Powersourcetype_Sum_Fields = {
  __typename?: 'core_powersourcetype_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "core_powersourcetype" */
export enum Core_Powersourcetype_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Source = 'source'
}

export type Core_Powersourcetype_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Powersourcetype_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Powersourcetype_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Powersourcetype_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Powersourcetype_Var_Pop_Fields = {
  __typename?: 'core_powersourcetype_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Core_Powersourcetype_Var_Samp_Fields = {
  __typename?: 'core_powersourcetype_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Core_Powersourcetype_Variance_Fields = {
  __typename?: 'core_powersourcetype_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "core_province" */
export type Core_Province = {
  __typename?: 'core_province';
  /** An array relationship */
  core_municipalities: Array<Core_Municipality>;
  /** An aggregate relationship */
  core_municipalities_aggregate: Core_Municipality_Aggregate;
  id: Scalars['bigint'];
  province: Scalars['String'];
};


/** columns and relationships of "core_province" */
export type Core_ProvinceCore_MunicipalitiesArgs = {
  distinct_on?: InputMaybe<Array<Core_Municipality_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Municipality_Order_By>>;
  where?: InputMaybe<Core_Municipality_Bool_Exp>;
};


/** columns and relationships of "core_province" */
export type Core_ProvinceCore_Municipalities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Municipality_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Municipality_Order_By>>;
  where?: InputMaybe<Core_Municipality_Bool_Exp>;
};

/** aggregated selection of "core_province" */
export type Core_Province_Aggregate = {
  __typename?: 'core_province_aggregate';
  aggregate?: Maybe<Core_Province_Aggregate_Fields>;
  nodes: Array<Core_Province>;
};

/** aggregate fields of "core_province" */
export type Core_Province_Aggregate_Fields = {
  __typename?: 'core_province_aggregate_fields';
  avg?: Maybe<Core_Province_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Province_Max_Fields>;
  min?: Maybe<Core_Province_Min_Fields>;
  stddev?: Maybe<Core_Province_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Province_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Province_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Province_Sum_Fields>;
  var_pop?: Maybe<Core_Province_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Province_Var_Samp_Fields>;
  variance?: Maybe<Core_Province_Variance_Fields>;
};


/** aggregate fields of "core_province" */
export type Core_Province_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Province_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Core_Province_Avg_Fields = {
  __typename?: 'core_province_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "core_province". All fields are combined with a logical 'AND'. */
export type Core_Province_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Province_Bool_Exp>>;
  _not?: InputMaybe<Core_Province_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Province_Bool_Exp>>;
  core_municipalities?: InputMaybe<Core_Municipality_Bool_Exp>;
  core_municipalities_aggregate?: InputMaybe<Core_Municipality_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  province?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_province" */
export enum Core_Province_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreProvincePkey = 'core_province_pkey'
}

/** input type for incrementing numeric columns in table "core_province" */
export type Core_Province_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_province" */
export type Core_Province_Insert_Input = {
  core_municipalities?: InputMaybe<Core_Municipality_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  province?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Province_Max_Fields = {
  __typename?: 'core_province_max_fields';
  id?: Maybe<Scalars['bigint']>;
  province?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Core_Province_Min_Fields = {
  __typename?: 'core_province_min_fields';
  id?: Maybe<Scalars['bigint']>;
  province?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "core_province" */
export type Core_Province_Mutation_Response = {
  __typename?: 'core_province_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Province>;
};

/** input type for inserting object relation for remote table "core_province" */
export type Core_Province_Obj_Rel_Insert_Input = {
  data: Core_Province_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Province_On_Conflict>;
};

/** on_conflict condition type for table "core_province" */
export type Core_Province_On_Conflict = {
  constraint: Core_Province_Constraint;
  update_columns?: Array<Core_Province_Update_Column>;
  where?: InputMaybe<Core_Province_Bool_Exp>;
};

/** Ordering options when selecting data from "core_province". */
export type Core_Province_Order_By = {
  core_municipalities_aggregate?: InputMaybe<Core_Municipality_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  province?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_province */
export type Core_Province_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_province" */
export enum Core_Province_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Province = 'province'
}

/** input type for updating data in table "core_province" */
export type Core_Province_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  province?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Province_Stddev_Fields = {
  __typename?: 'core_province_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Core_Province_Stddev_Pop_Fields = {
  __typename?: 'core_province_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Core_Province_Stddev_Samp_Fields = {
  __typename?: 'core_province_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "core_province" */
export type Core_Province_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Province_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Province_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  province?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Province_Sum_Fields = {
  __typename?: 'core_province_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "core_province" */
export enum Core_Province_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Province = 'province'
}

export type Core_Province_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Province_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Province_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Province_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Province_Var_Pop_Fields = {
  __typename?: 'core_province_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Core_Province_Var_Samp_Fields = {
  __typename?: 'core_province_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Core_Province_Variance_Fields = {
  __typename?: 'core_province_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "core_rectifieralarm" */
export type Core_Rectifieralarm = {
  __typename?: 'core_rectifieralarm';
  alarm_id: Scalars['String'];
  alarm_name: Scalars['String'];
  alarm_state: Scalars['String'];
  cabin_id: Scalars['bigint'];
  /** An object relationship */
  core_cabin: Core_Cabin;
  /** An object relationship */
  core_eventlog?: Maybe<Core_Eventlog>;
  /** An object relationship */
  core_site: Core_Site;
  created_on: Scalars['timestamptz'];
  event_log_id?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  site_id: Scalars['bigint'];
};

/** aggregated selection of "core_rectifieralarm" */
export type Core_Rectifieralarm_Aggregate = {
  __typename?: 'core_rectifieralarm_aggregate';
  aggregate?: Maybe<Core_Rectifieralarm_Aggregate_Fields>;
  nodes: Array<Core_Rectifieralarm>;
};

export type Core_Rectifieralarm_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Rectifieralarm_Aggregate_Bool_Exp_Count>;
};

export type Core_Rectifieralarm_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Rectifieralarm_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Rectifieralarm_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_rectifieralarm" */
export type Core_Rectifieralarm_Aggregate_Fields = {
  __typename?: 'core_rectifieralarm_aggregate_fields';
  avg?: Maybe<Core_Rectifieralarm_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Rectifieralarm_Max_Fields>;
  min?: Maybe<Core_Rectifieralarm_Min_Fields>;
  stddev?: Maybe<Core_Rectifieralarm_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Rectifieralarm_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Rectifieralarm_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Rectifieralarm_Sum_Fields>;
  var_pop?: Maybe<Core_Rectifieralarm_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Rectifieralarm_Var_Samp_Fields>;
  variance?: Maybe<Core_Rectifieralarm_Variance_Fields>;
};


/** aggregate fields of "core_rectifieralarm" */
export type Core_Rectifieralarm_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Rectifieralarm_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_rectifieralarm" */
export type Core_Rectifieralarm_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Rectifieralarm_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Rectifieralarm_Max_Order_By>;
  min?: InputMaybe<Core_Rectifieralarm_Min_Order_By>;
  stddev?: InputMaybe<Core_Rectifieralarm_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Rectifieralarm_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Rectifieralarm_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Rectifieralarm_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Rectifieralarm_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Rectifieralarm_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Rectifieralarm_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_rectifieralarm" */
export type Core_Rectifieralarm_Arr_Rel_Insert_Input = {
  data: Array<Core_Rectifieralarm_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Rectifieralarm_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Rectifieralarm_Avg_Fields = {
  __typename?: 'core_rectifieralarm_avg_fields';
  cabin_id?: Maybe<Scalars['Float']>;
  event_log_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_rectifieralarm" */
export type Core_Rectifieralarm_Avg_Order_By = {
  cabin_id?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_rectifieralarm". All fields are combined with a logical 'AND'. */
export type Core_Rectifieralarm_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Rectifieralarm_Bool_Exp>>;
  _not?: InputMaybe<Core_Rectifieralarm_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Rectifieralarm_Bool_Exp>>;
  alarm_id?: InputMaybe<String_Comparison_Exp>;
  alarm_name?: InputMaybe<String_Comparison_Exp>;
  alarm_state?: InputMaybe<String_Comparison_Exp>;
  cabin_id?: InputMaybe<Bigint_Comparison_Exp>;
  core_cabin?: InputMaybe<Core_Cabin_Bool_Exp>;
  core_eventlog?: InputMaybe<Core_Eventlog_Bool_Exp>;
  core_site?: InputMaybe<Core_Site_Bool_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_log_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  site_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_rectifieralarm" */
export enum Core_Rectifieralarm_Constraint {
  /** unique or primary key constraint on columns "event_log_id" */
  CoreRectifieralarmEventLogIdKey = 'core_rectifieralarm_event_log_id_key',
  /** unique or primary key constraint on columns "id" */
  CoreRectifieralarmPkey = 'core_rectifieralarm_pkey'
}

/** input type for incrementing numeric columns in table "core_rectifieralarm" */
export type Core_Rectifieralarm_Inc_Input = {
  cabin_id?: InputMaybe<Scalars['bigint']>;
  event_log_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_rectifieralarm" */
export type Core_Rectifieralarm_Insert_Input = {
  alarm_id?: InputMaybe<Scalars['String']>;
  alarm_name?: InputMaybe<Scalars['String']>;
  alarm_state?: InputMaybe<Scalars['String']>;
  cabin_id?: InputMaybe<Scalars['bigint']>;
  core_cabin?: InputMaybe<Core_Cabin_Obj_Rel_Insert_Input>;
  core_eventlog?: InputMaybe<Core_Eventlog_Obj_Rel_Insert_Input>;
  core_site?: InputMaybe<Core_Site_Obj_Rel_Insert_Input>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  event_log_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_Rectifieralarm_Max_Fields = {
  __typename?: 'core_rectifieralarm_max_fields';
  alarm_id?: Maybe<Scalars['String']>;
  alarm_name?: Maybe<Scalars['String']>;
  alarm_state?: Maybe<Scalars['String']>;
  cabin_id?: Maybe<Scalars['bigint']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  event_log_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "core_rectifieralarm" */
export type Core_Rectifieralarm_Max_Order_By = {
  alarm_id?: InputMaybe<Order_By>;
  alarm_name?: InputMaybe<Order_By>;
  alarm_state?: InputMaybe<Order_By>;
  cabin_id?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Rectifieralarm_Min_Fields = {
  __typename?: 'core_rectifieralarm_min_fields';
  alarm_id?: Maybe<Scalars['String']>;
  alarm_name?: Maybe<Scalars['String']>;
  alarm_state?: Maybe<Scalars['String']>;
  cabin_id?: Maybe<Scalars['bigint']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  event_log_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "core_rectifieralarm" */
export type Core_Rectifieralarm_Min_Order_By = {
  alarm_id?: InputMaybe<Order_By>;
  alarm_name?: InputMaybe<Order_By>;
  alarm_state?: InputMaybe<Order_By>;
  cabin_id?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_rectifieralarm" */
export type Core_Rectifieralarm_Mutation_Response = {
  __typename?: 'core_rectifieralarm_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Rectifieralarm>;
};

/** input type for inserting object relation for remote table "core_rectifieralarm" */
export type Core_Rectifieralarm_Obj_Rel_Insert_Input = {
  data: Core_Rectifieralarm_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Rectifieralarm_On_Conflict>;
};

/** on_conflict condition type for table "core_rectifieralarm" */
export type Core_Rectifieralarm_On_Conflict = {
  constraint: Core_Rectifieralarm_Constraint;
  update_columns?: Array<Core_Rectifieralarm_Update_Column>;
  where?: InputMaybe<Core_Rectifieralarm_Bool_Exp>;
};

/** Ordering options when selecting data from "core_rectifieralarm". */
export type Core_Rectifieralarm_Order_By = {
  alarm_id?: InputMaybe<Order_By>;
  alarm_name?: InputMaybe<Order_By>;
  alarm_state?: InputMaybe<Order_By>;
  cabin_id?: InputMaybe<Order_By>;
  core_cabin?: InputMaybe<Core_Cabin_Order_By>;
  core_eventlog?: InputMaybe<Core_Eventlog_Order_By>;
  core_site?: InputMaybe<Core_Site_Order_By>;
  created_on?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_rectifieralarm */
export type Core_Rectifieralarm_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_rectifieralarm" */
export enum Core_Rectifieralarm_Select_Column {
  /** column name */
  AlarmId = 'alarm_id',
  /** column name */
  AlarmName = 'alarm_name',
  /** column name */
  AlarmState = 'alarm_state',
  /** column name */
  CabinId = 'cabin_id',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  EventLogId = 'event_log_id',
  /** column name */
  Id = 'id',
  /** column name */
  SiteId = 'site_id'
}

/** input type for updating data in table "core_rectifieralarm" */
export type Core_Rectifieralarm_Set_Input = {
  alarm_id?: InputMaybe<Scalars['String']>;
  alarm_name?: InputMaybe<Scalars['String']>;
  alarm_state?: InputMaybe<Scalars['String']>;
  cabin_id?: InputMaybe<Scalars['bigint']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  event_log_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_Rectifieralarm_Stddev_Fields = {
  __typename?: 'core_rectifieralarm_stddev_fields';
  cabin_id?: Maybe<Scalars['Float']>;
  event_log_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_rectifieralarm" */
export type Core_Rectifieralarm_Stddev_Order_By = {
  cabin_id?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Rectifieralarm_Stddev_Pop_Fields = {
  __typename?: 'core_rectifieralarm_stddev_pop_fields';
  cabin_id?: Maybe<Scalars['Float']>;
  event_log_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_rectifieralarm" */
export type Core_Rectifieralarm_Stddev_Pop_Order_By = {
  cabin_id?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Rectifieralarm_Stddev_Samp_Fields = {
  __typename?: 'core_rectifieralarm_stddev_samp_fields';
  cabin_id?: Maybe<Scalars['Float']>;
  event_log_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_rectifieralarm" */
export type Core_Rectifieralarm_Stddev_Samp_Order_By = {
  cabin_id?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_rectifieralarm" */
export type Core_Rectifieralarm_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Rectifieralarm_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Rectifieralarm_Stream_Cursor_Value_Input = {
  alarm_id?: InputMaybe<Scalars['String']>;
  alarm_name?: InputMaybe<Scalars['String']>;
  alarm_state?: InputMaybe<Scalars['String']>;
  cabin_id?: InputMaybe<Scalars['bigint']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  event_log_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_Rectifieralarm_Sum_Fields = {
  __typename?: 'core_rectifieralarm_sum_fields';
  cabin_id?: Maybe<Scalars['bigint']>;
  event_log_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_rectifieralarm" */
export type Core_Rectifieralarm_Sum_Order_By = {
  cabin_id?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_rectifieralarm" */
export enum Core_Rectifieralarm_Update_Column {
  /** column name */
  AlarmId = 'alarm_id',
  /** column name */
  AlarmName = 'alarm_name',
  /** column name */
  AlarmState = 'alarm_state',
  /** column name */
  CabinId = 'cabin_id',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  EventLogId = 'event_log_id',
  /** column name */
  Id = 'id',
  /** column name */
  SiteId = 'site_id'
}

export type Core_Rectifieralarm_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Rectifieralarm_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Rectifieralarm_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Rectifieralarm_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Rectifieralarm_Var_Pop_Fields = {
  __typename?: 'core_rectifieralarm_var_pop_fields';
  cabin_id?: Maybe<Scalars['Float']>;
  event_log_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_rectifieralarm" */
export type Core_Rectifieralarm_Var_Pop_Order_By = {
  cabin_id?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Rectifieralarm_Var_Samp_Fields = {
  __typename?: 'core_rectifieralarm_var_samp_fields';
  cabin_id?: Maybe<Scalars['Float']>;
  event_log_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_rectifieralarm" */
export type Core_Rectifieralarm_Var_Samp_Order_By = {
  cabin_id?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Rectifieralarm_Variance_Fields = {
  __typename?: 'core_rectifieralarm_variance_fields';
  cabin_id?: Maybe<Scalars['Float']>;
  event_log_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_rectifieralarm" */
export type Core_Rectifieralarm_Variance_Order_By = {
  cabin_id?: InputMaybe<Order_By>;
  event_log_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_requestaccess" */
export type Core_Requestaccess = {
  __typename?: 'core_requestaccess';
  access_reference: Scalars['String'];
  /** An object relationship */
  core_site: Core_Site;
  /** An object relationship */
  core_user: Core_User;
  /** An object relationship */
  core_visit: Core_Visit;
  id: Scalars['bigint'];
  personale_id: Scalars['bigint'];
  request_date: Scalars['timestamptz'];
  site_id: Scalars['bigint'];
  visit_id: Scalars['bigint'];
};

/** aggregated selection of "core_requestaccess" */
export type Core_Requestaccess_Aggregate = {
  __typename?: 'core_requestaccess_aggregate';
  aggregate?: Maybe<Core_Requestaccess_Aggregate_Fields>;
  nodes: Array<Core_Requestaccess>;
};

export type Core_Requestaccess_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Requestaccess_Aggregate_Bool_Exp_Count>;
};

export type Core_Requestaccess_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Requestaccess_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Requestaccess_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_requestaccess" */
export type Core_Requestaccess_Aggregate_Fields = {
  __typename?: 'core_requestaccess_aggregate_fields';
  avg?: Maybe<Core_Requestaccess_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Requestaccess_Max_Fields>;
  min?: Maybe<Core_Requestaccess_Min_Fields>;
  stddev?: Maybe<Core_Requestaccess_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Requestaccess_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Requestaccess_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Requestaccess_Sum_Fields>;
  var_pop?: Maybe<Core_Requestaccess_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Requestaccess_Var_Samp_Fields>;
  variance?: Maybe<Core_Requestaccess_Variance_Fields>;
};


/** aggregate fields of "core_requestaccess" */
export type Core_Requestaccess_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Requestaccess_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_requestaccess" */
export type Core_Requestaccess_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Requestaccess_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Requestaccess_Max_Order_By>;
  min?: InputMaybe<Core_Requestaccess_Min_Order_By>;
  stddev?: InputMaybe<Core_Requestaccess_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Requestaccess_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Requestaccess_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Requestaccess_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Requestaccess_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Requestaccess_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Requestaccess_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_requestaccess" */
export type Core_Requestaccess_Arr_Rel_Insert_Input = {
  data: Array<Core_Requestaccess_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Requestaccess_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Requestaccess_Avg_Fields = {
  __typename?: 'core_requestaccess_avg_fields';
  id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_requestaccess" */
export type Core_Requestaccess_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_requestaccess". All fields are combined with a logical 'AND'. */
export type Core_Requestaccess_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Requestaccess_Bool_Exp>>;
  _not?: InputMaybe<Core_Requestaccess_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Requestaccess_Bool_Exp>>;
  access_reference?: InputMaybe<String_Comparison_Exp>;
  core_site?: InputMaybe<Core_Site_Bool_Exp>;
  core_user?: InputMaybe<Core_User_Bool_Exp>;
  core_visit?: InputMaybe<Core_Visit_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  personale_id?: InputMaybe<Bigint_Comparison_Exp>;
  request_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  site_id?: InputMaybe<Bigint_Comparison_Exp>;
  visit_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_requestaccess" */
export enum Core_Requestaccess_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreRequestaccessPkey = 'core_requestaccess_pkey'
}

/** input type for incrementing numeric columns in table "core_requestaccess" */
export type Core_Requestaccess_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  personale_id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  visit_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_requestaccess" */
export type Core_Requestaccess_Insert_Input = {
  access_reference?: InputMaybe<Scalars['String']>;
  core_site?: InputMaybe<Core_Site_Obj_Rel_Insert_Input>;
  core_user?: InputMaybe<Core_User_Obj_Rel_Insert_Input>;
  core_visit?: InputMaybe<Core_Visit_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  personale_id?: InputMaybe<Scalars['bigint']>;
  request_date?: InputMaybe<Scalars['timestamptz']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  visit_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_Requestaccess_Max_Fields = {
  __typename?: 'core_requestaccess_max_fields';
  access_reference?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  personale_id?: Maybe<Scalars['bigint']>;
  request_date?: Maybe<Scalars['timestamptz']>;
  site_id?: Maybe<Scalars['bigint']>;
  visit_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "core_requestaccess" */
export type Core_Requestaccess_Max_Order_By = {
  access_reference?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
  request_date?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Requestaccess_Min_Fields = {
  __typename?: 'core_requestaccess_min_fields';
  access_reference?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  personale_id?: Maybe<Scalars['bigint']>;
  request_date?: Maybe<Scalars['timestamptz']>;
  site_id?: Maybe<Scalars['bigint']>;
  visit_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "core_requestaccess" */
export type Core_Requestaccess_Min_Order_By = {
  access_reference?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
  request_date?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_requestaccess" */
export type Core_Requestaccess_Mutation_Response = {
  __typename?: 'core_requestaccess_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Requestaccess>;
};

/** on_conflict condition type for table "core_requestaccess" */
export type Core_Requestaccess_On_Conflict = {
  constraint: Core_Requestaccess_Constraint;
  update_columns?: Array<Core_Requestaccess_Update_Column>;
  where?: InputMaybe<Core_Requestaccess_Bool_Exp>;
};

/** Ordering options when selecting data from "core_requestaccess". */
export type Core_Requestaccess_Order_By = {
  access_reference?: InputMaybe<Order_By>;
  core_site?: InputMaybe<Core_Site_Order_By>;
  core_user?: InputMaybe<Core_User_Order_By>;
  core_visit?: InputMaybe<Core_Visit_Order_By>;
  id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
  request_date?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_requestaccess */
export type Core_Requestaccess_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_requestaccess" */
export enum Core_Requestaccess_Select_Column {
  /** column name */
  AccessReference = 'access_reference',
  /** column name */
  Id = 'id',
  /** column name */
  PersonaleId = 'personale_id',
  /** column name */
  RequestDate = 'request_date',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  VisitId = 'visit_id'
}

/** input type for updating data in table "core_requestaccess" */
export type Core_Requestaccess_Set_Input = {
  access_reference?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  personale_id?: InputMaybe<Scalars['bigint']>;
  request_date?: InputMaybe<Scalars['timestamptz']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  visit_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_Requestaccess_Stddev_Fields = {
  __typename?: 'core_requestaccess_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_requestaccess" */
export type Core_Requestaccess_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Requestaccess_Stddev_Pop_Fields = {
  __typename?: 'core_requestaccess_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_requestaccess" */
export type Core_Requestaccess_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Requestaccess_Stddev_Samp_Fields = {
  __typename?: 'core_requestaccess_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_requestaccess" */
export type Core_Requestaccess_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_requestaccess" */
export type Core_Requestaccess_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Requestaccess_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Requestaccess_Stream_Cursor_Value_Input = {
  access_reference?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  personale_id?: InputMaybe<Scalars['bigint']>;
  request_date?: InputMaybe<Scalars['timestamptz']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  visit_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_Requestaccess_Sum_Fields = {
  __typename?: 'core_requestaccess_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  personale_id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
  visit_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_requestaccess" */
export type Core_Requestaccess_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_requestaccess" */
export enum Core_Requestaccess_Update_Column {
  /** column name */
  AccessReference = 'access_reference',
  /** column name */
  Id = 'id',
  /** column name */
  PersonaleId = 'personale_id',
  /** column name */
  RequestDate = 'request_date',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  VisitId = 'visit_id'
}

export type Core_Requestaccess_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Requestaccess_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Requestaccess_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Requestaccess_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Requestaccess_Var_Pop_Fields = {
  __typename?: 'core_requestaccess_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_requestaccess" */
export type Core_Requestaccess_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Requestaccess_Var_Samp_Fields = {
  __typename?: 'core_requestaccess_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_requestaccess" */
export type Core_Requestaccess_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Requestaccess_Variance_Fields = {
  __typename?: 'core_requestaccess_variance_fields';
  id?: Maybe<Scalars['Float']>;
  personale_id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  visit_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_requestaccess" */
export type Core_Requestaccess_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  personale_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_res_company_wide_charge_estimate" */
export type Core_Res_Company_Wide_Charge_Estimate = {
  __typename?: 'core_res_company_wide_charge_estimate';
  cost?: Maybe<Scalars['float8']>;
  units?: Maybe<Scalars['float8']>;
  year_month?: Maybe<Scalars['String']>;
};

/** aggregated selection of "core_res_company_wide_charge_estimate" */
export type Core_Res_Company_Wide_Charge_Estimate_Aggregate = {
  __typename?: 'core_res_company_wide_charge_estimate_aggregate';
  aggregate?: Maybe<Core_Res_Company_Wide_Charge_Estimate_Aggregate_Fields>;
  nodes: Array<Core_Res_Company_Wide_Charge_Estimate>;
};

/** aggregate fields of "core_res_company_wide_charge_estimate" */
export type Core_Res_Company_Wide_Charge_Estimate_Aggregate_Fields = {
  __typename?: 'core_res_company_wide_charge_estimate_aggregate_fields';
  avg?: Maybe<Core_Res_Company_Wide_Charge_Estimate_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Res_Company_Wide_Charge_Estimate_Max_Fields>;
  min?: Maybe<Core_Res_Company_Wide_Charge_Estimate_Min_Fields>;
  stddev?: Maybe<Core_Res_Company_Wide_Charge_Estimate_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Res_Company_Wide_Charge_Estimate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Res_Company_Wide_Charge_Estimate_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Res_Company_Wide_Charge_Estimate_Sum_Fields>;
  var_pop?: Maybe<Core_Res_Company_Wide_Charge_Estimate_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Res_Company_Wide_Charge_Estimate_Var_Samp_Fields>;
  variance?: Maybe<Core_Res_Company_Wide_Charge_Estimate_Variance_Fields>;
};


/** aggregate fields of "core_res_company_wide_charge_estimate" */
export type Core_Res_Company_Wide_Charge_Estimate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Res_Company_Wide_Charge_Estimate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Core_Res_Company_Wide_Charge_Estimate_Avg_Fields = {
  __typename?: 'core_res_company_wide_charge_estimate_avg_fields';
  cost?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "core_res_company_wide_charge_estimate". All fields are combined with a logical 'AND'. */
export type Core_Res_Company_Wide_Charge_Estimate_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Res_Company_Wide_Charge_Estimate_Bool_Exp>>;
  _not?: InputMaybe<Core_Res_Company_Wide_Charge_Estimate_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Res_Company_Wide_Charge_Estimate_Bool_Exp>>;
  cost?: InputMaybe<Float8_Comparison_Exp>;
  units?: InputMaybe<Float8_Comparison_Exp>;
  year_month?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "core_res_company_wide_charge_estimate" */
export type Core_Res_Company_Wide_Charge_Estimate_Inc_Input = {
  cost?: InputMaybe<Scalars['float8']>;
  units?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "core_res_company_wide_charge_estimate" */
export type Core_Res_Company_Wide_Charge_Estimate_Insert_Input = {
  cost?: InputMaybe<Scalars['float8']>;
  units?: InputMaybe<Scalars['float8']>;
  year_month?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Res_Company_Wide_Charge_Estimate_Max_Fields = {
  __typename?: 'core_res_company_wide_charge_estimate_max_fields';
  cost?: Maybe<Scalars['float8']>;
  units?: Maybe<Scalars['float8']>;
  year_month?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Core_Res_Company_Wide_Charge_Estimate_Min_Fields = {
  __typename?: 'core_res_company_wide_charge_estimate_min_fields';
  cost?: Maybe<Scalars['float8']>;
  units?: Maybe<Scalars['float8']>;
  year_month?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "core_res_company_wide_charge_estimate" */
export type Core_Res_Company_Wide_Charge_Estimate_Mutation_Response = {
  __typename?: 'core_res_company_wide_charge_estimate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Res_Company_Wide_Charge_Estimate>;
};

/** Ordering options when selecting data from "core_res_company_wide_charge_estimate". */
export type Core_Res_Company_Wide_Charge_Estimate_Order_By = {
  cost?: InputMaybe<Order_By>;
  units?: InputMaybe<Order_By>;
  year_month?: InputMaybe<Order_By>;
};

/** select columns of table "core_res_company_wide_charge_estimate" */
export enum Core_Res_Company_Wide_Charge_Estimate_Select_Column {
  /** column name */
  Cost = 'cost',
  /** column name */
  Units = 'units',
  /** column name */
  YearMonth = 'year_month'
}

/** input type for updating data in table "core_res_company_wide_charge_estimate" */
export type Core_Res_Company_Wide_Charge_Estimate_Set_Input = {
  cost?: InputMaybe<Scalars['float8']>;
  units?: InputMaybe<Scalars['float8']>;
  year_month?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Res_Company_Wide_Charge_Estimate_Stddev_Fields = {
  __typename?: 'core_res_company_wide_charge_estimate_stddev_fields';
  cost?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Core_Res_Company_Wide_Charge_Estimate_Stddev_Pop_Fields = {
  __typename?: 'core_res_company_wide_charge_estimate_stddev_pop_fields';
  cost?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Core_Res_Company_Wide_Charge_Estimate_Stddev_Samp_Fields = {
  __typename?: 'core_res_company_wide_charge_estimate_stddev_samp_fields';
  cost?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "core_res_company_wide_charge_estimate" */
export type Core_Res_Company_Wide_Charge_Estimate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Res_Company_Wide_Charge_Estimate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Res_Company_Wide_Charge_Estimate_Stream_Cursor_Value_Input = {
  cost?: InputMaybe<Scalars['float8']>;
  units?: InputMaybe<Scalars['float8']>;
  year_month?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Res_Company_Wide_Charge_Estimate_Sum_Fields = {
  __typename?: 'core_res_company_wide_charge_estimate_sum_fields';
  cost?: Maybe<Scalars['float8']>;
  units?: Maybe<Scalars['float8']>;
};

export type Core_Res_Company_Wide_Charge_Estimate_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Res_Company_Wide_Charge_Estimate_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Res_Company_Wide_Charge_Estimate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Res_Company_Wide_Charge_Estimate_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Res_Company_Wide_Charge_Estimate_Var_Pop_Fields = {
  __typename?: 'core_res_company_wide_charge_estimate_var_pop_fields';
  cost?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Core_Res_Company_Wide_Charge_Estimate_Var_Samp_Fields = {
  __typename?: 'core_res_company_wide_charge_estimate_var_samp_fields';
  cost?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Core_Res_Company_Wide_Charge_Estimate_Variance_Fields = {
  __typename?: 'core_res_company_wide_charge_estimate_variance_fields';
  cost?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "core_resjobstatus" */
export type Core_Resjobstatus = {
  __typename?: 'core_resjobstatus';
  comment?: Maybe<Scalars['String']>;
  /** An object relationship */
  core_job: Core_Job;
  created_on: Scalars['timestamptz'];
  description: Scalars['String'];
  job_id: Scalars['bigint'];
  status: Scalars['String'];
  status_id: Scalars['bigint'];
};

/** aggregated selection of "core_resjobstatus" */
export type Core_Resjobstatus_Aggregate = {
  __typename?: 'core_resjobstatus_aggregate';
  aggregate?: Maybe<Core_Resjobstatus_Aggregate_Fields>;
  nodes: Array<Core_Resjobstatus>;
};

/** aggregate fields of "core_resjobstatus" */
export type Core_Resjobstatus_Aggregate_Fields = {
  __typename?: 'core_resjobstatus_aggregate_fields';
  avg?: Maybe<Core_Resjobstatus_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Resjobstatus_Max_Fields>;
  min?: Maybe<Core_Resjobstatus_Min_Fields>;
  stddev?: Maybe<Core_Resjobstatus_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Resjobstatus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Resjobstatus_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Resjobstatus_Sum_Fields>;
  var_pop?: Maybe<Core_Resjobstatus_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Resjobstatus_Var_Samp_Fields>;
  variance?: Maybe<Core_Resjobstatus_Variance_Fields>;
};


/** aggregate fields of "core_resjobstatus" */
export type Core_Resjobstatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Resjobstatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Core_Resjobstatus_Avg_Fields = {
  __typename?: 'core_resjobstatus_avg_fields';
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "core_resjobstatus". All fields are combined with a logical 'AND'. */
export type Core_Resjobstatus_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Resjobstatus_Bool_Exp>>;
  _not?: InputMaybe<Core_Resjobstatus_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Resjobstatus_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  core_job?: InputMaybe<Core_Job_Bool_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  job_id?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  status_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_resjobstatus" */
export enum Core_Resjobstatus_Constraint {
  /** unique or primary key constraint on columns "job_id" */
  CoreResjobstatusPkey = 'core_resjobstatus_pkey'
}

/** input type for incrementing numeric columns in table "core_resjobstatus" */
export type Core_Resjobstatus_Inc_Input = {
  job_id?: InputMaybe<Scalars['bigint']>;
  status_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_resjobstatus" */
export type Core_Resjobstatus_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  core_job?: InputMaybe<Core_Job_Obj_Rel_Insert_Input>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  job_id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
  status_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_Resjobstatus_Max_Fields = {
  __typename?: 'core_resjobstatus_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  job_id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
  status_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Core_Resjobstatus_Min_Fields = {
  __typename?: 'core_resjobstatus_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  job_id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
  status_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "core_resjobstatus" */
export type Core_Resjobstatus_Mutation_Response = {
  __typename?: 'core_resjobstatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Resjobstatus>;
};

/** input type for inserting object relation for remote table "core_resjobstatus" */
export type Core_Resjobstatus_Obj_Rel_Insert_Input = {
  data: Core_Resjobstatus_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Resjobstatus_On_Conflict>;
};

/** on_conflict condition type for table "core_resjobstatus" */
export type Core_Resjobstatus_On_Conflict = {
  constraint: Core_Resjobstatus_Constraint;
  update_columns?: Array<Core_Resjobstatus_Update_Column>;
  where?: InputMaybe<Core_Resjobstatus_Bool_Exp>;
};

/** Ordering options when selecting data from "core_resjobstatus". */
export type Core_Resjobstatus_Order_By = {
  comment?: InputMaybe<Order_By>;
  core_job?: InputMaybe<Core_Job_Order_By>;
  created_on?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_resjobstatus */
export type Core_Resjobstatus_Pk_Columns_Input = {
  job_id: Scalars['bigint'];
};

/** select columns of table "core_resjobstatus" */
export enum Core_Resjobstatus_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Description = 'description',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusId = 'status_id'
}

/** input type for updating data in table "core_resjobstatus" */
export type Core_Resjobstatus_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  job_id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
  status_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_Resjobstatus_Stddev_Fields = {
  __typename?: 'core_resjobstatus_stddev_fields';
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Core_Resjobstatus_Stddev_Pop_Fields = {
  __typename?: 'core_resjobstatus_stddev_pop_fields';
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Core_Resjobstatus_Stddev_Samp_Fields = {
  __typename?: 'core_resjobstatus_stddev_samp_fields';
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "core_resjobstatus" */
export type Core_Resjobstatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Resjobstatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Resjobstatus_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  job_id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
  status_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_Resjobstatus_Sum_Fields = {
  __typename?: 'core_resjobstatus_sum_fields';
  job_id?: Maybe<Scalars['bigint']>;
  status_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "core_resjobstatus" */
export enum Core_Resjobstatus_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Description = 'description',
  /** column name */
  JobId = 'job_id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusId = 'status_id'
}

export type Core_Resjobstatus_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Resjobstatus_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Resjobstatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Resjobstatus_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Resjobstatus_Var_Pop_Fields = {
  __typename?: 'core_resjobstatus_var_pop_fields';
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Core_Resjobstatus_Var_Samp_Fields = {
  __typename?: 'core_resjobstatus_var_samp_fields';
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Core_Resjobstatus_Variance_Fields = {
  __typename?: 'core_resjobstatus_variance_fields';
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "core_role" */
export type Core_Role = {
  __typename?: 'core_role';
  /** An array relationship */
  core_users: Array<Core_User>;
  /** An aggregate relationship */
  core_users_aggregate: Core_User_Aggregate;
  created_on: Scalars['timestamptz'];
  id: Scalars['bigint'];
  title: Scalars['String'];
};


/** columns and relationships of "core_role" */
export type Core_RoleCore_UsersArgs = {
  distinct_on?: InputMaybe<Array<Core_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_Order_By>>;
  where?: InputMaybe<Core_User_Bool_Exp>;
};


/** columns and relationships of "core_role" */
export type Core_RoleCore_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_Order_By>>;
  where?: InputMaybe<Core_User_Bool_Exp>;
};

/** aggregated selection of "core_role" */
export type Core_Role_Aggregate = {
  __typename?: 'core_role_aggregate';
  aggregate?: Maybe<Core_Role_Aggregate_Fields>;
  nodes: Array<Core_Role>;
};

/** aggregate fields of "core_role" */
export type Core_Role_Aggregate_Fields = {
  __typename?: 'core_role_aggregate_fields';
  avg?: Maybe<Core_Role_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Role_Max_Fields>;
  min?: Maybe<Core_Role_Min_Fields>;
  stddev?: Maybe<Core_Role_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Role_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Role_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Role_Sum_Fields>;
  var_pop?: Maybe<Core_Role_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Role_Var_Samp_Fields>;
  variance?: Maybe<Core_Role_Variance_Fields>;
};


/** aggregate fields of "core_role" */
export type Core_Role_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Role_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Core_Role_Avg_Fields = {
  __typename?: 'core_role_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "core_role". All fields are combined with a logical 'AND'. */
export type Core_Role_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Role_Bool_Exp>>;
  _not?: InputMaybe<Core_Role_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Role_Bool_Exp>>;
  core_users?: InputMaybe<Core_User_Bool_Exp>;
  core_users_aggregate?: InputMaybe<Core_User_Aggregate_Bool_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_role" */
export enum Core_Role_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreRolePkey = 'core_role_pkey'
}

/** input type for incrementing numeric columns in table "core_role" */
export type Core_Role_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_role" */
export type Core_Role_Insert_Input = {
  core_users?: InputMaybe<Core_User_Arr_Rel_Insert_Input>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Role_Max_Fields = {
  __typename?: 'core_role_max_fields';
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Core_Role_Min_Fields = {
  __typename?: 'core_role_min_fields';
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  title?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "core_role" */
export type Core_Role_Mutation_Response = {
  __typename?: 'core_role_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Role>;
};

/** input type for inserting object relation for remote table "core_role" */
export type Core_Role_Obj_Rel_Insert_Input = {
  data: Core_Role_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Role_On_Conflict>;
};

/** on_conflict condition type for table "core_role" */
export type Core_Role_On_Conflict = {
  constraint: Core_Role_Constraint;
  update_columns?: Array<Core_Role_Update_Column>;
  where?: InputMaybe<Core_Role_Bool_Exp>;
};

/** Ordering options when selecting data from "core_role". */
export type Core_Role_Order_By = {
  core_users_aggregate?: InputMaybe<Core_User_Aggregate_Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_role */
export type Core_Role_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_role" */
export enum Core_Role_Select_Column {
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "core_role" */
export type Core_Role_Set_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Role_Stddev_Fields = {
  __typename?: 'core_role_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Core_Role_Stddev_Pop_Fields = {
  __typename?: 'core_role_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Core_Role_Stddev_Samp_Fields = {
  __typename?: 'core_role_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "core_role" */
export type Core_Role_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Role_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Role_Stream_Cursor_Value_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  title?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Role_Sum_Fields = {
  __typename?: 'core_role_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "core_role" */
export enum Core_Role_Update_Column {
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title'
}

export type Core_Role_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Role_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Role_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Role_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Role_Var_Pop_Fields = {
  __typename?: 'core_role_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Core_Role_Var_Samp_Fields = {
  __typename?: 'core_role_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Core_Role_Variance_Fields = {
  __typename?: 'core_role_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "core_router" */
export type Core_Router = {
  __typename?: 'core_router';
  box_id: Scalars['bigint'];
  /** An object relationship */
  core_box: Core_Box;
  /** An array relationship */
  core_simcards: Array<Core_Simcard>;
  /** An aggregate relationship */
  core_simcards_aggregate: Core_Simcard_Aggregate;
  id: Scalars['bigint'];
  serial_number: Scalars['String'];
};


/** columns and relationships of "core_router" */
export type Core_RouterCore_SimcardsArgs = {
  distinct_on?: InputMaybe<Array<Core_Simcard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Simcard_Order_By>>;
  where?: InputMaybe<Core_Simcard_Bool_Exp>;
};


/** columns and relationships of "core_router" */
export type Core_RouterCore_Simcards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Simcard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Simcard_Order_By>>;
  where?: InputMaybe<Core_Simcard_Bool_Exp>;
};

/** aggregated selection of "core_router" */
export type Core_Router_Aggregate = {
  __typename?: 'core_router_aggregate';
  aggregate?: Maybe<Core_Router_Aggregate_Fields>;
  nodes: Array<Core_Router>;
};

export type Core_Router_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Router_Aggregate_Bool_Exp_Count>;
};

export type Core_Router_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Router_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Router_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_router" */
export type Core_Router_Aggregate_Fields = {
  __typename?: 'core_router_aggregate_fields';
  avg?: Maybe<Core_Router_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Router_Max_Fields>;
  min?: Maybe<Core_Router_Min_Fields>;
  stddev?: Maybe<Core_Router_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Router_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Router_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Router_Sum_Fields>;
  var_pop?: Maybe<Core_Router_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Router_Var_Samp_Fields>;
  variance?: Maybe<Core_Router_Variance_Fields>;
};


/** aggregate fields of "core_router" */
export type Core_Router_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Router_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_router" */
export type Core_Router_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Router_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Router_Max_Order_By>;
  min?: InputMaybe<Core_Router_Min_Order_By>;
  stddev?: InputMaybe<Core_Router_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Router_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Router_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Router_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Router_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Router_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Router_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_router" */
export type Core_Router_Arr_Rel_Insert_Input = {
  data: Array<Core_Router_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Router_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Router_Avg_Fields = {
  __typename?: 'core_router_avg_fields';
  box_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_router" */
export type Core_Router_Avg_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_router". All fields are combined with a logical 'AND'. */
export type Core_Router_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Router_Bool_Exp>>;
  _not?: InputMaybe<Core_Router_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Router_Bool_Exp>>;
  box_id?: InputMaybe<Bigint_Comparison_Exp>;
  core_box?: InputMaybe<Core_Box_Bool_Exp>;
  core_simcards?: InputMaybe<Core_Simcard_Bool_Exp>;
  core_simcards_aggregate?: InputMaybe<Core_Simcard_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  serial_number?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_router" */
export enum Core_Router_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreRouterPkey = 'core_router_pkey',
  /** unique or primary key constraint on columns "serial_number" */
  CoreRouterSerialNumberKey = 'core_router_serial_number_key'
}

/** input type for incrementing numeric columns in table "core_router" */
export type Core_Router_Inc_Input = {
  box_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_router" */
export type Core_Router_Insert_Input = {
  box_id?: InputMaybe<Scalars['bigint']>;
  core_box?: InputMaybe<Core_Box_Obj_Rel_Insert_Input>;
  core_simcards?: InputMaybe<Core_Simcard_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  serial_number?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Router_Max_Fields = {
  __typename?: 'core_router_max_fields';
  box_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  serial_number?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "core_router" */
export type Core_Router_Max_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Router_Min_Fields = {
  __typename?: 'core_router_min_fields';
  box_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  serial_number?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "core_router" */
export type Core_Router_Min_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_router" */
export type Core_Router_Mutation_Response = {
  __typename?: 'core_router_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Router>;
};

/** input type for inserting object relation for remote table "core_router" */
export type Core_Router_Obj_Rel_Insert_Input = {
  data: Core_Router_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Router_On_Conflict>;
};

/** on_conflict condition type for table "core_router" */
export type Core_Router_On_Conflict = {
  constraint: Core_Router_Constraint;
  update_columns?: Array<Core_Router_Update_Column>;
  where?: InputMaybe<Core_Router_Bool_Exp>;
};

/** Ordering options when selecting data from "core_router". */
export type Core_Router_Order_By = {
  box_id?: InputMaybe<Order_By>;
  core_box?: InputMaybe<Core_Box_Order_By>;
  core_simcards_aggregate?: InputMaybe<Core_Simcard_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  serial_number?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_router */
export type Core_Router_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_router" */
export enum Core_Router_Select_Column {
  /** column name */
  BoxId = 'box_id',
  /** column name */
  Id = 'id',
  /** column name */
  SerialNumber = 'serial_number'
}

/** input type for updating data in table "core_router" */
export type Core_Router_Set_Input = {
  box_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  serial_number?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Router_Stddev_Fields = {
  __typename?: 'core_router_stddev_fields';
  box_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_router" */
export type Core_Router_Stddev_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Router_Stddev_Pop_Fields = {
  __typename?: 'core_router_stddev_pop_fields';
  box_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_router" */
export type Core_Router_Stddev_Pop_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Router_Stddev_Samp_Fields = {
  __typename?: 'core_router_stddev_samp_fields';
  box_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_router" */
export type Core_Router_Stddev_Samp_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_router" */
export type Core_Router_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Router_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Router_Stream_Cursor_Value_Input = {
  box_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  serial_number?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Router_Sum_Fields = {
  __typename?: 'core_router_sum_fields';
  box_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_router" */
export type Core_Router_Sum_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "core_router" */
export enum Core_Router_Update_Column {
  /** column name */
  BoxId = 'box_id',
  /** column name */
  Id = 'id',
  /** column name */
  SerialNumber = 'serial_number'
}

export type Core_Router_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Router_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Router_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Router_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Router_Var_Pop_Fields = {
  __typename?: 'core_router_var_pop_fields';
  box_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_router" */
export type Core_Router_Var_Pop_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Router_Var_Samp_Fields = {
  __typename?: 'core_router_var_samp_fields';
  box_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_router" */
export type Core_Router_Var_Samp_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Router_Variance_Fields = {
  __typename?: 'core_router_variance_fields';
  box_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_router" */
export type Core_Router_Variance_Order_By = {
  box_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_securitycallouts" */
export type Core_Securitycallouts = {
  __typename?: 'core_securitycallouts';
  call_out_id: Scalars['float8'];
  classification: Scalars['String'];
  closed_on?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  core_securitycalloutsstatuses: Array<Core_Securitycalloutsstatus>;
  /** An aggregate relationship */
  core_securitycalloutsstatuses_aggregate: Core_Securitycalloutsstatus_Aggregate;
  /** An object relationship */
  core_site?: Maybe<Core_Site>;
  created_on: Scalars['timestamptz'];
  id: Scalars['bigint'];
  latitude?: Maybe<Scalars['float8']>;
  logged_at?: Maybe<Scalars['timestamptz']>;
  longitude?: Maybe<Scalars['float8']>;
  resolved_at?: Maybe<Scalars['timestamptz']>;
  site_id?: Maybe<Scalars['bigint']>;
  type_of_emergency: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "core_securitycallouts" */
export type Core_SecuritycalloutsCore_SecuritycalloutsstatusesArgs = {
  distinct_on?: InputMaybe<Array<Core_Securitycalloutsstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Securitycalloutsstatus_Order_By>>;
  where?: InputMaybe<Core_Securitycalloutsstatus_Bool_Exp>;
};


/** columns and relationships of "core_securitycallouts" */
export type Core_SecuritycalloutsCore_Securitycalloutsstatuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Securitycalloutsstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Securitycalloutsstatus_Order_By>>;
  where?: InputMaybe<Core_Securitycalloutsstatus_Bool_Exp>;
};

/** aggregated selection of "core_securitycallouts" */
export type Core_Securitycallouts_Aggregate = {
  __typename?: 'core_securitycallouts_aggregate';
  aggregate?: Maybe<Core_Securitycallouts_Aggregate_Fields>;
  nodes: Array<Core_Securitycallouts>;
};

export type Core_Securitycallouts_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Core_Securitycallouts_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Core_Securitycallouts_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Core_Securitycallouts_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Core_Securitycallouts_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Core_Securitycallouts_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Core_Securitycallouts_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Core_Securitycallouts_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Core_Securitycallouts_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Core_Securitycallouts_Aggregate_Bool_Exp_Var_Samp>;
};

export type Core_Securitycallouts_Aggregate_Bool_Exp_Avg = {
  arguments: Core_Securitycallouts_Select_Column_Core_Securitycallouts_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Securitycallouts_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Securitycallouts_Aggregate_Bool_Exp_Corr = {
  arguments: Core_Securitycallouts_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Securitycallouts_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Securitycallouts_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Core_Securitycallouts_Select_Column_Core_Securitycallouts_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Core_Securitycallouts_Select_Column_Core_Securitycallouts_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Core_Securitycallouts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Securitycallouts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Securitycallouts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Core_Securitycallouts_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Core_Securitycallouts_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Securitycallouts_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Securitycallouts_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Core_Securitycallouts_Select_Column_Core_Securitycallouts_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Core_Securitycallouts_Select_Column_Core_Securitycallouts_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Core_Securitycallouts_Aggregate_Bool_Exp_Max = {
  arguments: Core_Securitycallouts_Select_Column_Core_Securitycallouts_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Securitycallouts_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Securitycallouts_Aggregate_Bool_Exp_Min = {
  arguments: Core_Securitycallouts_Select_Column_Core_Securitycallouts_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Securitycallouts_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Securitycallouts_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Core_Securitycallouts_Select_Column_Core_Securitycallouts_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Securitycallouts_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Securitycallouts_Aggregate_Bool_Exp_Sum = {
  arguments: Core_Securitycallouts_Select_Column_Core_Securitycallouts_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Securitycallouts_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Securitycallouts_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Core_Securitycallouts_Select_Column_Core_Securitycallouts_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Securitycallouts_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "core_securitycallouts" */
export type Core_Securitycallouts_Aggregate_Fields = {
  __typename?: 'core_securitycallouts_aggregate_fields';
  avg?: Maybe<Core_Securitycallouts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Securitycallouts_Max_Fields>;
  min?: Maybe<Core_Securitycallouts_Min_Fields>;
  stddev?: Maybe<Core_Securitycallouts_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Securitycallouts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Securitycallouts_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Securitycallouts_Sum_Fields>;
  var_pop?: Maybe<Core_Securitycallouts_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Securitycallouts_Var_Samp_Fields>;
  variance?: Maybe<Core_Securitycallouts_Variance_Fields>;
};


/** aggregate fields of "core_securitycallouts" */
export type Core_Securitycallouts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Securitycallouts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_securitycallouts" */
export type Core_Securitycallouts_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Securitycallouts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Securitycallouts_Max_Order_By>;
  min?: InputMaybe<Core_Securitycallouts_Min_Order_By>;
  stddev?: InputMaybe<Core_Securitycallouts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Securitycallouts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Securitycallouts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Securitycallouts_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Securitycallouts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Securitycallouts_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Securitycallouts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_securitycallouts" */
export type Core_Securitycallouts_Arr_Rel_Insert_Input = {
  data: Array<Core_Securitycallouts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Securitycallouts_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Securitycallouts_Avg_Fields = {
  __typename?: 'core_securitycallouts_avg_fields';
  call_out_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_securitycallouts" */
export type Core_Securitycallouts_Avg_Order_By = {
  call_out_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_securitycallouts". All fields are combined with a logical 'AND'. */
export type Core_Securitycallouts_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Securitycallouts_Bool_Exp>>;
  _not?: InputMaybe<Core_Securitycallouts_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Securitycallouts_Bool_Exp>>;
  call_out_id?: InputMaybe<Float8_Comparison_Exp>;
  classification?: InputMaybe<String_Comparison_Exp>;
  closed_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  core_securitycalloutsstatuses?: InputMaybe<Core_Securitycalloutsstatus_Bool_Exp>;
  core_securitycalloutsstatuses_aggregate?: InputMaybe<Core_Securitycalloutsstatus_Aggregate_Bool_Exp>;
  core_site?: InputMaybe<Core_Site_Bool_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  latitude?: InputMaybe<Float8_Comparison_Exp>;
  logged_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  longitude?: InputMaybe<Float8_Comparison_Exp>;
  resolved_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  site_id?: InputMaybe<Bigint_Comparison_Exp>;
  type_of_emergency?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_securitycallouts" */
export enum Core_Securitycallouts_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreSecuritycalloutsPkey = 'core_securitycallouts_pkey'
}

/** input type for incrementing numeric columns in table "core_securitycallouts" */
export type Core_Securitycallouts_Inc_Input = {
  call_out_id?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['bigint']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_securitycallouts" */
export type Core_Securitycallouts_Insert_Input = {
  call_out_id?: InputMaybe<Scalars['float8']>;
  classification?: InputMaybe<Scalars['String']>;
  closed_on?: InputMaybe<Scalars['timestamptz']>;
  core_securitycalloutsstatuses?: InputMaybe<Core_Securitycalloutsstatus_Arr_Rel_Insert_Input>;
  core_site?: InputMaybe<Core_Site_Obj_Rel_Insert_Input>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  latitude?: InputMaybe<Scalars['float8']>;
  logged_at?: InputMaybe<Scalars['timestamptz']>;
  longitude?: InputMaybe<Scalars['float8']>;
  resolved_at?: InputMaybe<Scalars['timestamptz']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  type_of_emergency?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Core_Securitycallouts_Max_Fields = {
  __typename?: 'core_securitycallouts_max_fields';
  call_out_id?: Maybe<Scalars['float8']>;
  classification?: Maybe<Scalars['String']>;
  closed_on?: Maybe<Scalars['timestamptz']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  latitude?: Maybe<Scalars['float8']>;
  logged_at?: Maybe<Scalars['timestamptz']>;
  longitude?: Maybe<Scalars['float8']>;
  resolved_at?: Maybe<Scalars['timestamptz']>;
  site_id?: Maybe<Scalars['bigint']>;
  type_of_emergency?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "core_securitycallouts" */
export type Core_Securitycallouts_Max_Order_By = {
  call_out_id?: InputMaybe<Order_By>;
  classification?: InputMaybe<Order_By>;
  closed_on?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  logged_at?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  resolved_at?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  type_of_emergency?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Securitycallouts_Min_Fields = {
  __typename?: 'core_securitycallouts_min_fields';
  call_out_id?: Maybe<Scalars['float8']>;
  classification?: Maybe<Scalars['String']>;
  closed_on?: Maybe<Scalars['timestamptz']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  latitude?: Maybe<Scalars['float8']>;
  logged_at?: Maybe<Scalars['timestamptz']>;
  longitude?: Maybe<Scalars['float8']>;
  resolved_at?: Maybe<Scalars['timestamptz']>;
  site_id?: Maybe<Scalars['bigint']>;
  type_of_emergency?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "core_securitycallouts" */
export type Core_Securitycallouts_Min_Order_By = {
  call_out_id?: InputMaybe<Order_By>;
  classification?: InputMaybe<Order_By>;
  closed_on?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  logged_at?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  resolved_at?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  type_of_emergency?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_securitycallouts" */
export type Core_Securitycallouts_Mutation_Response = {
  __typename?: 'core_securitycallouts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Securitycallouts>;
};

/** input type for inserting object relation for remote table "core_securitycallouts" */
export type Core_Securitycallouts_Obj_Rel_Insert_Input = {
  data: Core_Securitycallouts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Securitycallouts_On_Conflict>;
};

/** on_conflict condition type for table "core_securitycallouts" */
export type Core_Securitycallouts_On_Conflict = {
  constraint: Core_Securitycallouts_Constraint;
  update_columns?: Array<Core_Securitycallouts_Update_Column>;
  where?: InputMaybe<Core_Securitycallouts_Bool_Exp>;
};

/** Ordering options when selecting data from "core_securitycallouts". */
export type Core_Securitycallouts_Order_By = {
  call_out_id?: InputMaybe<Order_By>;
  classification?: InputMaybe<Order_By>;
  closed_on?: InputMaybe<Order_By>;
  core_securitycalloutsstatuses_aggregate?: InputMaybe<Core_Securitycalloutsstatus_Aggregate_Order_By>;
  core_site?: InputMaybe<Core_Site_Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  logged_at?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  resolved_at?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  type_of_emergency?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_securitycallouts */
export type Core_Securitycallouts_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_securitycallouts" */
export enum Core_Securitycallouts_Select_Column {
  /** column name */
  CallOutId = 'call_out_id',
  /** column name */
  Classification = 'classification',
  /** column name */
  ClosedOn = 'closed_on',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  LoggedAt = 'logged_at',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  ResolvedAt = 'resolved_at',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  TypeOfEmergency = 'type_of_emergency',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "core_securitycallouts_aggregate_bool_exp_avg_arguments_columns" columns of table "core_securitycallouts" */
export enum Core_Securitycallouts_Select_Column_Core_Securitycallouts_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  CallOutId = 'call_out_id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** select "core_securitycallouts_aggregate_bool_exp_corr_arguments_columns" columns of table "core_securitycallouts" */
export enum Core_Securitycallouts_Select_Column_Core_Securitycallouts_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  CallOutId = 'call_out_id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** select "core_securitycallouts_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "core_securitycallouts" */
export enum Core_Securitycallouts_Select_Column_Core_Securitycallouts_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  CallOutId = 'call_out_id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** select "core_securitycallouts_aggregate_bool_exp_max_arguments_columns" columns of table "core_securitycallouts" */
export enum Core_Securitycallouts_Select_Column_Core_Securitycallouts_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  CallOutId = 'call_out_id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** select "core_securitycallouts_aggregate_bool_exp_min_arguments_columns" columns of table "core_securitycallouts" */
export enum Core_Securitycallouts_Select_Column_Core_Securitycallouts_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  CallOutId = 'call_out_id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** select "core_securitycallouts_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "core_securitycallouts" */
export enum Core_Securitycallouts_Select_Column_Core_Securitycallouts_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  CallOutId = 'call_out_id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** select "core_securitycallouts_aggregate_bool_exp_sum_arguments_columns" columns of table "core_securitycallouts" */
export enum Core_Securitycallouts_Select_Column_Core_Securitycallouts_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  CallOutId = 'call_out_id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** select "core_securitycallouts_aggregate_bool_exp_var_samp_arguments_columns" columns of table "core_securitycallouts" */
export enum Core_Securitycallouts_Select_Column_Core_Securitycallouts_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  CallOutId = 'call_out_id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** input type for updating data in table "core_securitycallouts" */
export type Core_Securitycallouts_Set_Input = {
  call_out_id?: InputMaybe<Scalars['float8']>;
  classification?: InputMaybe<Scalars['String']>;
  closed_on?: InputMaybe<Scalars['timestamptz']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  latitude?: InputMaybe<Scalars['float8']>;
  logged_at?: InputMaybe<Scalars['timestamptz']>;
  longitude?: InputMaybe<Scalars['float8']>;
  resolved_at?: InputMaybe<Scalars['timestamptz']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  type_of_emergency?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Core_Securitycallouts_Stddev_Fields = {
  __typename?: 'core_securitycallouts_stddev_fields';
  call_out_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_securitycallouts" */
export type Core_Securitycallouts_Stddev_Order_By = {
  call_out_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Securitycallouts_Stddev_Pop_Fields = {
  __typename?: 'core_securitycallouts_stddev_pop_fields';
  call_out_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_securitycallouts" */
export type Core_Securitycallouts_Stddev_Pop_Order_By = {
  call_out_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Securitycallouts_Stddev_Samp_Fields = {
  __typename?: 'core_securitycallouts_stddev_samp_fields';
  call_out_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_securitycallouts" */
export type Core_Securitycallouts_Stddev_Samp_Order_By = {
  call_out_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_securitycallouts" */
export type Core_Securitycallouts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Securitycallouts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Securitycallouts_Stream_Cursor_Value_Input = {
  call_out_id?: InputMaybe<Scalars['float8']>;
  classification?: InputMaybe<Scalars['String']>;
  closed_on?: InputMaybe<Scalars['timestamptz']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  latitude?: InputMaybe<Scalars['float8']>;
  logged_at?: InputMaybe<Scalars['timestamptz']>;
  longitude?: InputMaybe<Scalars['float8']>;
  resolved_at?: InputMaybe<Scalars['timestamptz']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  type_of_emergency?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Core_Securitycallouts_Sum_Fields = {
  __typename?: 'core_securitycallouts_sum_fields';
  call_out_id?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  site_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_securitycallouts" */
export type Core_Securitycallouts_Sum_Order_By = {
  call_out_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_securitycallouts" */
export enum Core_Securitycallouts_Update_Column {
  /** column name */
  CallOutId = 'call_out_id',
  /** column name */
  Classification = 'classification',
  /** column name */
  ClosedOn = 'closed_on',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  LoggedAt = 'logged_at',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  ResolvedAt = 'resolved_at',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  TypeOfEmergency = 'type_of_emergency',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Core_Securitycallouts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Securitycallouts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Securitycallouts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Securitycallouts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Securitycallouts_Var_Pop_Fields = {
  __typename?: 'core_securitycallouts_var_pop_fields';
  call_out_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_securitycallouts" */
export type Core_Securitycallouts_Var_Pop_Order_By = {
  call_out_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Securitycallouts_Var_Samp_Fields = {
  __typename?: 'core_securitycallouts_var_samp_fields';
  call_out_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_securitycallouts" */
export type Core_Securitycallouts_Var_Samp_Order_By = {
  call_out_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Securitycallouts_Variance_Fields = {
  __typename?: 'core_securitycallouts_variance_fields';
  call_out_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_securitycallouts" */
export type Core_Securitycallouts_Variance_Order_By = {
  call_out_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_securitycalloutsstatus" */
export type Core_Securitycalloutsstatus = {
  __typename?: 'core_securitycalloutsstatus';
  callout_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  core_securitycallout?: Maybe<Core_Securitycallouts>;
  id: Scalars['bigint'];
  status?: Maybe<Scalars['String']>;
};

/** aggregated selection of "core_securitycalloutsstatus" */
export type Core_Securitycalloutsstatus_Aggregate = {
  __typename?: 'core_securitycalloutsstatus_aggregate';
  aggregate?: Maybe<Core_Securitycalloutsstatus_Aggregate_Fields>;
  nodes: Array<Core_Securitycalloutsstatus>;
};

export type Core_Securitycalloutsstatus_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Securitycalloutsstatus_Aggregate_Bool_Exp_Count>;
};

export type Core_Securitycalloutsstatus_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Securitycalloutsstatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Securitycalloutsstatus_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_securitycalloutsstatus" */
export type Core_Securitycalloutsstatus_Aggregate_Fields = {
  __typename?: 'core_securitycalloutsstatus_aggregate_fields';
  avg?: Maybe<Core_Securitycalloutsstatus_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Securitycalloutsstatus_Max_Fields>;
  min?: Maybe<Core_Securitycalloutsstatus_Min_Fields>;
  stddev?: Maybe<Core_Securitycalloutsstatus_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Securitycalloutsstatus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Securitycalloutsstatus_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Securitycalloutsstatus_Sum_Fields>;
  var_pop?: Maybe<Core_Securitycalloutsstatus_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Securitycalloutsstatus_Var_Samp_Fields>;
  variance?: Maybe<Core_Securitycalloutsstatus_Variance_Fields>;
};


/** aggregate fields of "core_securitycalloutsstatus" */
export type Core_Securitycalloutsstatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Securitycalloutsstatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_securitycalloutsstatus" */
export type Core_Securitycalloutsstatus_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Securitycalloutsstatus_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Securitycalloutsstatus_Max_Order_By>;
  min?: InputMaybe<Core_Securitycalloutsstatus_Min_Order_By>;
  stddev?: InputMaybe<Core_Securitycalloutsstatus_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Securitycalloutsstatus_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Securitycalloutsstatus_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Securitycalloutsstatus_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Securitycalloutsstatus_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Securitycalloutsstatus_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Securitycalloutsstatus_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_securitycalloutsstatus" */
export type Core_Securitycalloutsstatus_Arr_Rel_Insert_Input = {
  data: Array<Core_Securitycalloutsstatus_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Securitycalloutsstatus_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Securitycalloutsstatus_Avg_Fields = {
  __typename?: 'core_securitycalloutsstatus_avg_fields';
  callout_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_securitycalloutsstatus" */
export type Core_Securitycalloutsstatus_Avg_Order_By = {
  callout_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_securitycalloutsstatus". All fields are combined with a logical 'AND'. */
export type Core_Securitycalloutsstatus_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Securitycalloutsstatus_Bool_Exp>>;
  _not?: InputMaybe<Core_Securitycalloutsstatus_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Securitycalloutsstatus_Bool_Exp>>;
  callout_id?: InputMaybe<Bigint_Comparison_Exp>;
  core_securitycallout?: InputMaybe<Core_Securitycallouts_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_securitycalloutsstatus" */
export enum Core_Securitycalloutsstatus_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreSecuritycalloutsstatusPkey = 'core_securitycalloutsstatus_pkey'
}

/** input type for incrementing numeric columns in table "core_securitycalloutsstatus" */
export type Core_Securitycalloutsstatus_Inc_Input = {
  callout_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_securitycalloutsstatus" */
export type Core_Securitycalloutsstatus_Insert_Input = {
  callout_id?: InputMaybe<Scalars['bigint']>;
  core_securitycallout?: InputMaybe<Core_Securitycallouts_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Securitycalloutsstatus_Max_Fields = {
  __typename?: 'core_securitycalloutsstatus_max_fields';
  callout_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "core_securitycalloutsstatus" */
export type Core_Securitycalloutsstatus_Max_Order_By = {
  callout_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Securitycalloutsstatus_Min_Fields = {
  __typename?: 'core_securitycalloutsstatus_min_fields';
  callout_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "core_securitycalloutsstatus" */
export type Core_Securitycalloutsstatus_Min_Order_By = {
  callout_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_securitycalloutsstatus" */
export type Core_Securitycalloutsstatus_Mutation_Response = {
  __typename?: 'core_securitycalloutsstatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Securitycalloutsstatus>;
};

/** on_conflict condition type for table "core_securitycalloutsstatus" */
export type Core_Securitycalloutsstatus_On_Conflict = {
  constraint: Core_Securitycalloutsstatus_Constraint;
  update_columns?: Array<Core_Securitycalloutsstatus_Update_Column>;
  where?: InputMaybe<Core_Securitycalloutsstatus_Bool_Exp>;
};

/** Ordering options when selecting data from "core_securitycalloutsstatus". */
export type Core_Securitycalloutsstatus_Order_By = {
  callout_id?: InputMaybe<Order_By>;
  core_securitycallout?: InputMaybe<Core_Securitycallouts_Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_securitycalloutsstatus */
export type Core_Securitycalloutsstatus_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_securitycalloutsstatus" */
export enum Core_Securitycalloutsstatus_Select_Column {
  /** column name */
  CalloutId = 'callout_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "core_securitycalloutsstatus" */
export type Core_Securitycalloutsstatus_Set_Input = {
  callout_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Securitycalloutsstatus_Stddev_Fields = {
  __typename?: 'core_securitycalloutsstatus_stddev_fields';
  callout_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_securitycalloutsstatus" */
export type Core_Securitycalloutsstatus_Stddev_Order_By = {
  callout_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Securitycalloutsstatus_Stddev_Pop_Fields = {
  __typename?: 'core_securitycalloutsstatus_stddev_pop_fields';
  callout_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_securitycalloutsstatus" */
export type Core_Securitycalloutsstatus_Stddev_Pop_Order_By = {
  callout_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Securitycalloutsstatus_Stddev_Samp_Fields = {
  __typename?: 'core_securitycalloutsstatus_stddev_samp_fields';
  callout_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_securitycalloutsstatus" */
export type Core_Securitycalloutsstatus_Stddev_Samp_Order_By = {
  callout_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_securitycalloutsstatus" */
export type Core_Securitycalloutsstatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Securitycalloutsstatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Securitycalloutsstatus_Stream_Cursor_Value_Input = {
  callout_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Securitycalloutsstatus_Sum_Fields = {
  __typename?: 'core_securitycalloutsstatus_sum_fields';
  callout_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_securitycalloutsstatus" */
export type Core_Securitycalloutsstatus_Sum_Order_By = {
  callout_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "core_securitycalloutsstatus" */
export enum Core_Securitycalloutsstatus_Update_Column {
  /** column name */
  CalloutId = 'callout_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status'
}

export type Core_Securitycalloutsstatus_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Securitycalloutsstatus_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Securitycalloutsstatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Securitycalloutsstatus_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Securitycalloutsstatus_Var_Pop_Fields = {
  __typename?: 'core_securitycalloutsstatus_var_pop_fields';
  callout_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_securitycalloutsstatus" */
export type Core_Securitycalloutsstatus_Var_Pop_Order_By = {
  callout_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Securitycalloutsstatus_Var_Samp_Fields = {
  __typename?: 'core_securitycalloutsstatus_var_samp_fields';
  callout_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_securitycalloutsstatus" */
export type Core_Securitycalloutsstatus_Var_Samp_Order_By = {
  callout_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Securitycalloutsstatus_Variance_Fields = {
  __typename?: 'core_securitycalloutsstatus_variance_fields';
  callout_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_securitycalloutsstatus" */
export type Core_Securitycalloutsstatus_Variance_Order_By = {
  callout_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_simcard" */
export type Core_Simcard = {
  __typename?: 'core_simcard';
  /** An object relationship */
  core_router: Core_Router;
  /** An array relationship */
  core_simcardstatuses: Array<Core_Simcardstatus>;
  /** An aggregate relationship */
  core_simcardstatuses_aggregate: Core_Simcardstatus_Aggregate;
  created_on: Scalars['timestamptz'];
  description: Scalars['String'];
  ext_id: Scalars['String'];
  iccid: Scalars['String'];
  id: Scalars['bigint'];
  imsi: Scalars['String'];
  msisdn: Scalars['String'];
  network_name: Scalars['String'];
  router_id: Scalars['bigint'];
};


/** columns and relationships of "core_simcard" */
export type Core_SimcardCore_SimcardstatusesArgs = {
  distinct_on?: InputMaybe<Array<Core_Simcardstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Simcardstatus_Order_By>>;
  where?: InputMaybe<Core_Simcardstatus_Bool_Exp>;
};


/** columns and relationships of "core_simcard" */
export type Core_SimcardCore_Simcardstatuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Simcardstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Simcardstatus_Order_By>>;
  where?: InputMaybe<Core_Simcardstatus_Bool_Exp>;
};

/** aggregated selection of "core_simcard" */
export type Core_Simcard_Aggregate = {
  __typename?: 'core_simcard_aggregate';
  aggregate?: Maybe<Core_Simcard_Aggregate_Fields>;
  nodes: Array<Core_Simcard>;
};

export type Core_Simcard_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Simcard_Aggregate_Bool_Exp_Count>;
};

export type Core_Simcard_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Simcard_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Simcard_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_simcard" */
export type Core_Simcard_Aggregate_Fields = {
  __typename?: 'core_simcard_aggregate_fields';
  avg?: Maybe<Core_Simcard_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Simcard_Max_Fields>;
  min?: Maybe<Core_Simcard_Min_Fields>;
  stddev?: Maybe<Core_Simcard_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Simcard_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Simcard_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Simcard_Sum_Fields>;
  var_pop?: Maybe<Core_Simcard_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Simcard_Var_Samp_Fields>;
  variance?: Maybe<Core_Simcard_Variance_Fields>;
};


/** aggregate fields of "core_simcard" */
export type Core_Simcard_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Simcard_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_simcard" */
export type Core_Simcard_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Simcard_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Simcard_Max_Order_By>;
  min?: InputMaybe<Core_Simcard_Min_Order_By>;
  stddev?: InputMaybe<Core_Simcard_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Simcard_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Simcard_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Simcard_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Simcard_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Simcard_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Simcard_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_simcard" */
export type Core_Simcard_Arr_Rel_Insert_Input = {
  data: Array<Core_Simcard_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Simcard_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Simcard_Avg_Fields = {
  __typename?: 'core_simcard_avg_fields';
  id?: Maybe<Scalars['Float']>;
  router_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_simcard" */
export type Core_Simcard_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  router_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_simcard". All fields are combined with a logical 'AND'. */
export type Core_Simcard_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Simcard_Bool_Exp>>;
  _not?: InputMaybe<Core_Simcard_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Simcard_Bool_Exp>>;
  core_router?: InputMaybe<Core_Router_Bool_Exp>;
  core_simcardstatuses?: InputMaybe<Core_Simcardstatus_Bool_Exp>;
  core_simcardstatuses_aggregate?: InputMaybe<Core_Simcardstatus_Aggregate_Bool_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  ext_id?: InputMaybe<String_Comparison_Exp>;
  iccid?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  imsi?: InputMaybe<String_Comparison_Exp>;
  msisdn?: InputMaybe<String_Comparison_Exp>;
  network_name?: InputMaybe<String_Comparison_Exp>;
  router_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_simcard" */
export enum Core_Simcard_Constraint {
  /** unique or primary key constraint on columns "ext_id" */
  CoreSimcardExtIdKey = 'core_simcard_ext_id_key',
  /** unique or primary key constraint on columns "imsi", "msisdn" */
  CoreSimcardMsisdnImsiBf2801a1Uniq = 'core_simcard_msisdn_imsi_bf2801a1_uniq',
  /** unique or primary key constraint on columns "id" */
  CoreSimcardPkey = 'core_simcard_pkey'
}

/** input type for incrementing numeric columns in table "core_simcard" */
export type Core_Simcard_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  router_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_simcard" */
export type Core_Simcard_Insert_Input = {
  core_router?: InputMaybe<Core_Router_Obj_Rel_Insert_Input>;
  core_simcardstatuses?: InputMaybe<Core_Simcardstatus_Arr_Rel_Insert_Input>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  ext_id?: InputMaybe<Scalars['String']>;
  iccid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  imsi?: InputMaybe<Scalars['String']>;
  msisdn?: InputMaybe<Scalars['String']>;
  network_name?: InputMaybe<Scalars['String']>;
  router_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_Simcard_Max_Fields = {
  __typename?: 'core_simcard_max_fields';
  created_on?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  ext_id?: Maybe<Scalars['String']>;
  iccid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  imsi?: Maybe<Scalars['String']>;
  msisdn?: Maybe<Scalars['String']>;
  network_name?: Maybe<Scalars['String']>;
  router_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "core_simcard" */
export type Core_Simcard_Max_Order_By = {
  created_on?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  ext_id?: InputMaybe<Order_By>;
  iccid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imsi?: InputMaybe<Order_By>;
  msisdn?: InputMaybe<Order_By>;
  network_name?: InputMaybe<Order_By>;
  router_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Simcard_Min_Fields = {
  __typename?: 'core_simcard_min_fields';
  created_on?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  ext_id?: Maybe<Scalars['String']>;
  iccid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  imsi?: Maybe<Scalars['String']>;
  msisdn?: Maybe<Scalars['String']>;
  network_name?: Maybe<Scalars['String']>;
  router_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "core_simcard" */
export type Core_Simcard_Min_Order_By = {
  created_on?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  ext_id?: InputMaybe<Order_By>;
  iccid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imsi?: InputMaybe<Order_By>;
  msisdn?: InputMaybe<Order_By>;
  network_name?: InputMaybe<Order_By>;
  router_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_simcard" */
export type Core_Simcard_Mutation_Response = {
  __typename?: 'core_simcard_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Simcard>;
};

/** input type for inserting object relation for remote table "core_simcard" */
export type Core_Simcard_Obj_Rel_Insert_Input = {
  data: Core_Simcard_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Simcard_On_Conflict>;
};

/** on_conflict condition type for table "core_simcard" */
export type Core_Simcard_On_Conflict = {
  constraint: Core_Simcard_Constraint;
  update_columns?: Array<Core_Simcard_Update_Column>;
  where?: InputMaybe<Core_Simcard_Bool_Exp>;
};

/** Ordering options when selecting data from "core_simcard". */
export type Core_Simcard_Order_By = {
  core_router?: InputMaybe<Core_Router_Order_By>;
  core_simcardstatuses_aggregate?: InputMaybe<Core_Simcardstatus_Aggregate_Order_By>;
  created_on?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  ext_id?: InputMaybe<Order_By>;
  iccid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imsi?: InputMaybe<Order_By>;
  msisdn?: InputMaybe<Order_By>;
  network_name?: InputMaybe<Order_By>;
  router_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_simcard */
export type Core_Simcard_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_simcard" */
export enum Core_Simcard_Select_Column {
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Description = 'description',
  /** column name */
  ExtId = 'ext_id',
  /** column name */
  Iccid = 'iccid',
  /** column name */
  Id = 'id',
  /** column name */
  Imsi = 'imsi',
  /** column name */
  Msisdn = 'msisdn',
  /** column name */
  NetworkName = 'network_name',
  /** column name */
  RouterId = 'router_id'
}

/** input type for updating data in table "core_simcard" */
export type Core_Simcard_Set_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  ext_id?: InputMaybe<Scalars['String']>;
  iccid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  imsi?: InputMaybe<Scalars['String']>;
  msisdn?: InputMaybe<Scalars['String']>;
  network_name?: InputMaybe<Scalars['String']>;
  router_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_Simcard_Stddev_Fields = {
  __typename?: 'core_simcard_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  router_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_simcard" */
export type Core_Simcard_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  router_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Simcard_Stddev_Pop_Fields = {
  __typename?: 'core_simcard_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  router_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_simcard" */
export type Core_Simcard_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  router_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Simcard_Stddev_Samp_Fields = {
  __typename?: 'core_simcard_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  router_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_simcard" */
export type Core_Simcard_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  router_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_simcard" */
export type Core_Simcard_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Simcard_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Simcard_Stream_Cursor_Value_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  ext_id?: InputMaybe<Scalars['String']>;
  iccid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  imsi?: InputMaybe<Scalars['String']>;
  msisdn?: InputMaybe<Scalars['String']>;
  network_name?: InputMaybe<Scalars['String']>;
  router_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_Simcard_Sum_Fields = {
  __typename?: 'core_simcard_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  router_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_simcard" */
export type Core_Simcard_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  router_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_simcard" */
export enum Core_Simcard_Update_Column {
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Description = 'description',
  /** column name */
  ExtId = 'ext_id',
  /** column name */
  Iccid = 'iccid',
  /** column name */
  Id = 'id',
  /** column name */
  Imsi = 'imsi',
  /** column name */
  Msisdn = 'msisdn',
  /** column name */
  NetworkName = 'network_name',
  /** column name */
  RouterId = 'router_id'
}

export type Core_Simcard_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Simcard_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Simcard_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Simcard_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Simcard_Var_Pop_Fields = {
  __typename?: 'core_simcard_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  router_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_simcard" */
export type Core_Simcard_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  router_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Simcard_Var_Samp_Fields = {
  __typename?: 'core_simcard_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  router_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_simcard" */
export type Core_Simcard_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  router_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Simcard_Variance_Fields = {
  __typename?: 'core_simcard_variance_fields';
  id?: Maybe<Scalars['Float']>;
  router_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_simcard" */
export type Core_Simcard_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  router_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_simcardstatus" */
export type Core_Simcardstatus = {
  __typename?: 'core_simcardstatus';
  active: Scalars['Boolean'];
  airtime_balance: Scalars['float8'];
  /** An object relationship */
  core_simcard: Core_Simcard;
  created_on: Scalars['timestamptz'];
  data_balance_in_mb: Scalars['float8'];
  id: Scalars['bigint'];
  simcard_id: Scalars['bigint'];
  sms_balance: Scalars['float8'];
};

/** aggregated selection of "core_simcardstatus" */
export type Core_Simcardstatus_Aggregate = {
  __typename?: 'core_simcardstatus_aggregate';
  aggregate?: Maybe<Core_Simcardstatus_Aggregate_Fields>;
  nodes: Array<Core_Simcardstatus>;
};

export type Core_Simcardstatus_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Core_Simcardstatus_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Core_Simcardstatus_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Core_Simcardstatus_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Core_Simcardstatus_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Core_Simcardstatus_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Core_Simcardstatus_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Core_Simcardstatus_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Core_Simcardstatus_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Core_Simcardstatus_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Core_Simcardstatus_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Core_Simcardstatus_Aggregate_Bool_Exp_Var_Samp>;
};

export type Core_Simcardstatus_Aggregate_Bool_Exp_Avg = {
  arguments: Core_Simcardstatus_Select_Column_Core_Simcardstatus_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Simcardstatus_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Simcardstatus_Aggregate_Bool_Exp_Bool_And = {
  arguments: Core_Simcardstatus_Select_Column_Core_Simcardstatus_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Simcardstatus_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Core_Simcardstatus_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Core_Simcardstatus_Select_Column_Core_Simcardstatus_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Simcardstatus_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Core_Simcardstatus_Aggregate_Bool_Exp_Corr = {
  arguments: Core_Simcardstatus_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Simcardstatus_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Simcardstatus_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Core_Simcardstatus_Select_Column_Core_Simcardstatus_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Core_Simcardstatus_Select_Column_Core_Simcardstatus_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Core_Simcardstatus_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Simcardstatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Simcardstatus_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Core_Simcardstatus_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Core_Simcardstatus_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Simcardstatus_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Simcardstatus_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Core_Simcardstatus_Select_Column_Core_Simcardstatus_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Core_Simcardstatus_Select_Column_Core_Simcardstatus_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Core_Simcardstatus_Aggregate_Bool_Exp_Max = {
  arguments: Core_Simcardstatus_Select_Column_Core_Simcardstatus_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Simcardstatus_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Simcardstatus_Aggregate_Bool_Exp_Min = {
  arguments: Core_Simcardstatus_Select_Column_Core_Simcardstatus_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Simcardstatus_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Simcardstatus_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Core_Simcardstatus_Select_Column_Core_Simcardstatus_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Simcardstatus_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Simcardstatus_Aggregate_Bool_Exp_Sum = {
  arguments: Core_Simcardstatus_Select_Column_Core_Simcardstatus_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Simcardstatus_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Simcardstatus_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Core_Simcardstatus_Select_Column_Core_Simcardstatus_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Simcardstatus_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "core_simcardstatus" */
export type Core_Simcardstatus_Aggregate_Fields = {
  __typename?: 'core_simcardstatus_aggregate_fields';
  avg?: Maybe<Core_Simcardstatus_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Simcardstatus_Max_Fields>;
  min?: Maybe<Core_Simcardstatus_Min_Fields>;
  stddev?: Maybe<Core_Simcardstatus_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Simcardstatus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Simcardstatus_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Simcardstatus_Sum_Fields>;
  var_pop?: Maybe<Core_Simcardstatus_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Simcardstatus_Var_Samp_Fields>;
  variance?: Maybe<Core_Simcardstatus_Variance_Fields>;
};


/** aggregate fields of "core_simcardstatus" */
export type Core_Simcardstatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Simcardstatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_simcardstatus" */
export type Core_Simcardstatus_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Simcardstatus_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Simcardstatus_Max_Order_By>;
  min?: InputMaybe<Core_Simcardstatus_Min_Order_By>;
  stddev?: InputMaybe<Core_Simcardstatus_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Simcardstatus_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Simcardstatus_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Simcardstatus_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Simcardstatus_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Simcardstatus_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Simcardstatus_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_simcardstatus" */
export type Core_Simcardstatus_Arr_Rel_Insert_Input = {
  data: Array<Core_Simcardstatus_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Simcardstatus_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Simcardstatus_Avg_Fields = {
  __typename?: 'core_simcardstatus_avg_fields';
  airtime_balance?: Maybe<Scalars['Float']>;
  data_balance_in_mb?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  simcard_id?: Maybe<Scalars['Float']>;
  sms_balance?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_simcardstatus" */
export type Core_Simcardstatus_Avg_Order_By = {
  airtime_balance?: InputMaybe<Order_By>;
  data_balance_in_mb?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  simcard_id?: InputMaybe<Order_By>;
  sms_balance?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_simcardstatus". All fields are combined with a logical 'AND'. */
export type Core_Simcardstatus_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Simcardstatus_Bool_Exp>>;
  _not?: InputMaybe<Core_Simcardstatus_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Simcardstatus_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  airtime_balance?: InputMaybe<Float8_Comparison_Exp>;
  core_simcard?: InputMaybe<Core_Simcard_Bool_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  data_balance_in_mb?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  simcard_id?: InputMaybe<Bigint_Comparison_Exp>;
  sms_balance?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_simcardstatus" */
export enum Core_Simcardstatus_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreSimcardstatusPkey = 'core_simcardstatus_pkey'
}

/** input type for incrementing numeric columns in table "core_simcardstatus" */
export type Core_Simcardstatus_Inc_Input = {
  airtime_balance?: InputMaybe<Scalars['float8']>;
  data_balance_in_mb?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['bigint']>;
  simcard_id?: InputMaybe<Scalars['bigint']>;
  sms_balance?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "core_simcardstatus" */
export type Core_Simcardstatus_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  airtime_balance?: InputMaybe<Scalars['float8']>;
  core_simcard?: InputMaybe<Core_Simcard_Obj_Rel_Insert_Input>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  data_balance_in_mb?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['bigint']>;
  simcard_id?: InputMaybe<Scalars['bigint']>;
  sms_balance?: InputMaybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Core_Simcardstatus_Max_Fields = {
  __typename?: 'core_simcardstatus_max_fields';
  airtime_balance?: Maybe<Scalars['float8']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  data_balance_in_mb?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  simcard_id?: Maybe<Scalars['bigint']>;
  sms_balance?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "core_simcardstatus" */
export type Core_Simcardstatus_Max_Order_By = {
  airtime_balance?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  data_balance_in_mb?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  simcard_id?: InputMaybe<Order_By>;
  sms_balance?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Simcardstatus_Min_Fields = {
  __typename?: 'core_simcardstatus_min_fields';
  airtime_balance?: Maybe<Scalars['float8']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  data_balance_in_mb?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  simcard_id?: Maybe<Scalars['bigint']>;
  sms_balance?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "core_simcardstatus" */
export type Core_Simcardstatus_Min_Order_By = {
  airtime_balance?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  data_balance_in_mb?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  simcard_id?: InputMaybe<Order_By>;
  sms_balance?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_simcardstatus" */
export type Core_Simcardstatus_Mutation_Response = {
  __typename?: 'core_simcardstatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Simcardstatus>;
};

/** on_conflict condition type for table "core_simcardstatus" */
export type Core_Simcardstatus_On_Conflict = {
  constraint: Core_Simcardstatus_Constraint;
  update_columns?: Array<Core_Simcardstatus_Update_Column>;
  where?: InputMaybe<Core_Simcardstatus_Bool_Exp>;
};

/** Ordering options when selecting data from "core_simcardstatus". */
export type Core_Simcardstatus_Order_By = {
  active?: InputMaybe<Order_By>;
  airtime_balance?: InputMaybe<Order_By>;
  core_simcard?: InputMaybe<Core_Simcard_Order_By>;
  created_on?: InputMaybe<Order_By>;
  data_balance_in_mb?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  simcard_id?: InputMaybe<Order_By>;
  sms_balance?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_simcardstatus */
export type Core_Simcardstatus_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_simcardstatus" */
export enum Core_Simcardstatus_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AirtimeBalance = 'airtime_balance',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  DataBalanceInMb = 'data_balance_in_mb',
  /** column name */
  Id = 'id',
  /** column name */
  SimcardId = 'simcard_id',
  /** column name */
  SmsBalance = 'sms_balance'
}

/** select "core_simcardstatus_aggregate_bool_exp_avg_arguments_columns" columns of table "core_simcardstatus" */
export enum Core_Simcardstatus_Select_Column_Core_Simcardstatus_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  AirtimeBalance = 'airtime_balance',
  /** column name */
  DataBalanceInMb = 'data_balance_in_mb',
  /** column name */
  SmsBalance = 'sms_balance'
}

/** select "core_simcardstatus_aggregate_bool_exp_bool_and_arguments_columns" columns of table "core_simcardstatus" */
export enum Core_Simcardstatus_Select_Column_Core_Simcardstatus_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "core_simcardstatus_aggregate_bool_exp_bool_or_arguments_columns" columns of table "core_simcardstatus" */
export enum Core_Simcardstatus_Select_Column_Core_Simcardstatus_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "core_simcardstatus_aggregate_bool_exp_corr_arguments_columns" columns of table "core_simcardstatus" */
export enum Core_Simcardstatus_Select_Column_Core_Simcardstatus_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  AirtimeBalance = 'airtime_balance',
  /** column name */
  DataBalanceInMb = 'data_balance_in_mb',
  /** column name */
  SmsBalance = 'sms_balance'
}

/** select "core_simcardstatus_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "core_simcardstatus" */
export enum Core_Simcardstatus_Select_Column_Core_Simcardstatus_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  AirtimeBalance = 'airtime_balance',
  /** column name */
  DataBalanceInMb = 'data_balance_in_mb',
  /** column name */
  SmsBalance = 'sms_balance'
}

/** select "core_simcardstatus_aggregate_bool_exp_max_arguments_columns" columns of table "core_simcardstatus" */
export enum Core_Simcardstatus_Select_Column_Core_Simcardstatus_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  AirtimeBalance = 'airtime_balance',
  /** column name */
  DataBalanceInMb = 'data_balance_in_mb',
  /** column name */
  SmsBalance = 'sms_balance'
}

/** select "core_simcardstatus_aggregate_bool_exp_min_arguments_columns" columns of table "core_simcardstatus" */
export enum Core_Simcardstatus_Select_Column_Core_Simcardstatus_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  AirtimeBalance = 'airtime_balance',
  /** column name */
  DataBalanceInMb = 'data_balance_in_mb',
  /** column name */
  SmsBalance = 'sms_balance'
}

/** select "core_simcardstatus_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "core_simcardstatus" */
export enum Core_Simcardstatus_Select_Column_Core_Simcardstatus_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  AirtimeBalance = 'airtime_balance',
  /** column name */
  DataBalanceInMb = 'data_balance_in_mb',
  /** column name */
  SmsBalance = 'sms_balance'
}

/** select "core_simcardstatus_aggregate_bool_exp_sum_arguments_columns" columns of table "core_simcardstatus" */
export enum Core_Simcardstatus_Select_Column_Core_Simcardstatus_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  AirtimeBalance = 'airtime_balance',
  /** column name */
  DataBalanceInMb = 'data_balance_in_mb',
  /** column name */
  SmsBalance = 'sms_balance'
}

/** select "core_simcardstatus_aggregate_bool_exp_var_samp_arguments_columns" columns of table "core_simcardstatus" */
export enum Core_Simcardstatus_Select_Column_Core_Simcardstatus_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  AirtimeBalance = 'airtime_balance',
  /** column name */
  DataBalanceInMb = 'data_balance_in_mb',
  /** column name */
  SmsBalance = 'sms_balance'
}

/** input type for updating data in table "core_simcardstatus" */
export type Core_Simcardstatus_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  airtime_balance?: InputMaybe<Scalars['float8']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  data_balance_in_mb?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['bigint']>;
  simcard_id?: InputMaybe<Scalars['bigint']>;
  sms_balance?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Core_Simcardstatus_Stddev_Fields = {
  __typename?: 'core_simcardstatus_stddev_fields';
  airtime_balance?: Maybe<Scalars['Float']>;
  data_balance_in_mb?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  simcard_id?: Maybe<Scalars['Float']>;
  sms_balance?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_simcardstatus" */
export type Core_Simcardstatus_Stddev_Order_By = {
  airtime_balance?: InputMaybe<Order_By>;
  data_balance_in_mb?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  simcard_id?: InputMaybe<Order_By>;
  sms_balance?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Simcardstatus_Stddev_Pop_Fields = {
  __typename?: 'core_simcardstatus_stddev_pop_fields';
  airtime_balance?: Maybe<Scalars['Float']>;
  data_balance_in_mb?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  simcard_id?: Maybe<Scalars['Float']>;
  sms_balance?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_simcardstatus" */
export type Core_Simcardstatus_Stddev_Pop_Order_By = {
  airtime_balance?: InputMaybe<Order_By>;
  data_balance_in_mb?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  simcard_id?: InputMaybe<Order_By>;
  sms_balance?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Simcardstatus_Stddev_Samp_Fields = {
  __typename?: 'core_simcardstatus_stddev_samp_fields';
  airtime_balance?: Maybe<Scalars['Float']>;
  data_balance_in_mb?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  simcard_id?: Maybe<Scalars['Float']>;
  sms_balance?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_simcardstatus" */
export type Core_Simcardstatus_Stddev_Samp_Order_By = {
  airtime_balance?: InputMaybe<Order_By>;
  data_balance_in_mb?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  simcard_id?: InputMaybe<Order_By>;
  sms_balance?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_simcardstatus" */
export type Core_Simcardstatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Simcardstatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Simcardstatus_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  airtime_balance?: InputMaybe<Scalars['float8']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  data_balance_in_mb?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['bigint']>;
  simcard_id?: InputMaybe<Scalars['bigint']>;
  sms_balance?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Core_Simcardstatus_Sum_Fields = {
  __typename?: 'core_simcardstatus_sum_fields';
  airtime_balance?: Maybe<Scalars['float8']>;
  data_balance_in_mb?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  simcard_id?: Maybe<Scalars['bigint']>;
  sms_balance?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "core_simcardstatus" */
export type Core_Simcardstatus_Sum_Order_By = {
  airtime_balance?: InputMaybe<Order_By>;
  data_balance_in_mb?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  simcard_id?: InputMaybe<Order_By>;
  sms_balance?: InputMaybe<Order_By>;
};

/** update columns of table "core_simcardstatus" */
export enum Core_Simcardstatus_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AirtimeBalance = 'airtime_balance',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  DataBalanceInMb = 'data_balance_in_mb',
  /** column name */
  Id = 'id',
  /** column name */
  SimcardId = 'simcard_id',
  /** column name */
  SmsBalance = 'sms_balance'
}

export type Core_Simcardstatus_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Simcardstatus_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Simcardstatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Simcardstatus_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Simcardstatus_Var_Pop_Fields = {
  __typename?: 'core_simcardstatus_var_pop_fields';
  airtime_balance?: Maybe<Scalars['Float']>;
  data_balance_in_mb?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  simcard_id?: Maybe<Scalars['Float']>;
  sms_balance?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_simcardstatus" */
export type Core_Simcardstatus_Var_Pop_Order_By = {
  airtime_balance?: InputMaybe<Order_By>;
  data_balance_in_mb?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  simcard_id?: InputMaybe<Order_By>;
  sms_balance?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Simcardstatus_Var_Samp_Fields = {
  __typename?: 'core_simcardstatus_var_samp_fields';
  airtime_balance?: Maybe<Scalars['Float']>;
  data_balance_in_mb?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  simcard_id?: Maybe<Scalars['Float']>;
  sms_balance?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_simcardstatus" */
export type Core_Simcardstatus_Var_Samp_Order_By = {
  airtime_balance?: InputMaybe<Order_By>;
  data_balance_in_mb?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  simcard_id?: InputMaybe<Order_By>;
  sms_balance?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Simcardstatus_Variance_Fields = {
  __typename?: 'core_simcardstatus_variance_fields';
  airtime_balance?: Maybe<Scalars['Float']>;
  data_balance_in_mb?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  simcard_id?: Maybe<Scalars['Float']>;
  sms_balance?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_simcardstatus" */
export type Core_Simcardstatus_Variance_Order_By = {
  airtime_balance?: InputMaybe<Order_By>;
  data_balance_in_mb?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  simcard_id?: InputMaybe<Order_By>;
  sms_balance?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_site" */
export type Core_Site = {
  __typename?: 'core_site';
  address?: Maybe<Scalars['String']>;
  /** An array relationship */
  core_agreements: Array<Core_Agreement>;
  /** An aggregate relationship */
  core_agreements_aggregate: Core_Agreement_Aggregate;
  /** An array relationship */
  core_boxes: Array<Core_Box>;
  /** An aggregate relationship */
  core_boxes_aggregate: Core_Box_Aggregate;
  /** An array relationship */
  core_disruptions: Array<Core_Disruptions>;
  /** An aggregate relationship */
  core_disruptions_aggregate: Core_Disruptions_Aggregate;
  /** An array relationship */
  core_equipments: Array<Core_Equipment>;
  /** An aggregate relationship */
  core_equipments_aggregate: Core_Equipment_Aggregate;
  /** An array relationship */
  core_esps: Array<Core_Esp>;
  /** An aggregate relationship */
  core_esps_aggregate: Core_Esp_Aggregate;
  /** An array relationship */
  core_eventlogs: Array<Core_Eventlog>;
  /** An aggregate relationship */
  core_eventlogs_aggregate: Core_Eventlog_Aggregate;
  /** An array relationship */
  core_incidents: Array<Core_Incident>;
  /** An aggregate relationship */
  core_incidents_aggregate: Core_Incident_Aggregate;
  /** An array relationship */
  core_jobs: Array<Core_Job>;
  /** An aggregate relationship */
  core_jobs_aggregate: Core_Job_Aggregate;
  /** An array relationship */
  core_metrics: Array<Core_Metric>;
  /** An aggregate relationship */
  core_metrics_aggregate: Core_Metric_Aggregate;
  /** An array relationship */
  core_rectifieralarms: Array<Core_Rectifieralarm>;
  /** An aggregate relationship */
  core_rectifieralarms_aggregate: Core_Rectifieralarm_Aggregate;
  /** An array relationship */
  core_requestaccesses: Array<Core_Requestaccess>;
  /** An aggregate relationship */
  core_requestaccesses_aggregate: Core_Requestaccess_Aggregate;
  /** An object relationship */
  core_securitycallout?: Maybe<Core_Securitycallouts>;
  /** An array relationship */
  core_securitycallouts: Array<Core_Securitycallouts>;
  /** An aggregate relationship */
  core_securitycallouts_aggregate: Core_Securitycallouts_Aggregate;
  /** An array relationship */
  core_sitechargeestimates: Array<Core_Sitechargeestimate>;
  /** An aggregate relationship */
  core_sitechargeestimates_aggregate: Core_Sitechargeestimate_Aggregate;
  /** An object relationship */
  core_siteregion: Core_Siteregion;
  /** An object relationship */
  core_sitesecuritycoverage?: Maybe<Core_Sitesecuritycoverage>;
  /** An array relationship */
  core_sitesecuritycoverages: Array<Core_Sitesecuritycoverage>;
  /** An aggregate relationship */
  core_sitesecuritycoverages_aggregate: Core_Sitesecuritycoverage_Aggregate;
  /** An object relationship */
  core_sitestatus: Core_Sitestatus;
  /** An object relationship */
  core_sitetype: Core_Sitetype;
  created_on?: Maybe<Scalars['timestamptz']>;
  effective_date?: Maybe<Scalars['date']>;
  id: Scalars['bigint'];
  is_enabled?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  municipality?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  region_id: Scalars['bigint'];
  site_id?: Maybe<Scalars['String']>;
  site_status_id: Scalars['bigint'];
  site_type_id: Scalars['bigint'];
  status_changed_at?: Maybe<Scalars['timestamptz']>;
  up_since?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_AgreementsArgs = {
  distinct_on?: InputMaybe<Array<Core_Agreement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Agreement_Order_By>>;
  where?: InputMaybe<Core_Agreement_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_Agreements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Agreement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Agreement_Order_By>>;
  where?: InputMaybe<Core_Agreement_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_BoxesArgs = {
  distinct_on?: InputMaybe<Array<Core_Box_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Box_Order_By>>;
  where?: InputMaybe<Core_Box_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_Boxes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Box_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Box_Order_By>>;
  where?: InputMaybe<Core_Box_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_DisruptionsArgs = {
  distinct_on?: InputMaybe<Array<Core_Disruptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Disruptions_Order_By>>;
  where?: InputMaybe<Core_Disruptions_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_Disruptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Disruptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Disruptions_Order_By>>;
  where?: InputMaybe<Core_Disruptions_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_EquipmentsArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipment_Order_By>>;
  where?: InputMaybe<Core_Equipment_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_Equipments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipment_Order_By>>;
  where?: InputMaybe<Core_Equipment_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_EspsArgs = {
  distinct_on?: InputMaybe<Array<Core_Esp_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Esp_Order_By>>;
  where?: InputMaybe<Core_Esp_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_Esps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Esp_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Esp_Order_By>>;
  where?: InputMaybe<Core_Esp_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_EventlogsArgs = {
  distinct_on?: InputMaybe<Array<Core_Eventlog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Eventlog_Order_By>>;
  where?: InputMaybe<Core_Eventlog_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_Eventlogs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Eventlog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Eventlog_Order_By>>;
  where?: InputMaybe<Core_Eventlog_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_IncidentsArgs = {
  distinct_on?: InputMaybe<Array<Core_Incident_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Incident_Order_By>>;
  where?: InputMaybe<Core_Incident_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_Incidents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Incident_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Incident_Order_By>>;
  where?: InputMaybe<Core_Incident_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_JobsArgs = {
  distinct_on?: InputMaybe<Array<Core_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Job_Order_By>>;
  where?: InputMaybe<Core_Job_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_Jobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Job_Order_By>>;
  where?: InputMaybe<Core_Job_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_MetricsArgs = {
  distinct_on?: InputMaybe<Array<Core_Metric_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Metric_Order_By>>;
  where?: InputMaybe<Core_Metric_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_Metrics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Metric_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Metric_Order_By>>;
  where?: InputMaybe<Core_Metric_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_RectifieralarmsArgs = {
  distinct_on?: InputMaybe<Array<Core_Rectifieralarm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Rectifieralarm_Order_By>>;
  where?: InputMaybe<Core_Rectifieralarm_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_Rectifieralarms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Rectifieralarm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Rectifieralarm_Order_By>>;
  where?: InputMaybe<Core_Rectifieralarm_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_RequestaccessesArgs = {
  distinct_on?: InputMaybe<Array<Core_Requestaccess_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Requestaccess_Order_By>>;
  where?: InputMaybe<Core_Requestaccess_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_Requestaccesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Requestaccess_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Requestaccess_Order_By>>;
  where?: InputMaybe<Core_Requestaccess_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_SecuritycalloutsArgs = {
  distinct_on?: InputMaybe<Array<Core_Securitycallouts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Securitycallouts_Order_By>>;
  where?: InputMaybe<Core_Securitycallouts_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_Securitycallouts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Securitycallouts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Securitycallouts_Order_By>>;
  where?: InputMaybe<Core_Securitycallouts_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_SitechargeestimatesArgs = {
  distinct_on?: InputMaybe<Array<Core_Sitechargeestimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitechargeestimate_Order_By>>;
  where?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_Sitechargeestimates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Sitechargeestimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitechargeestimate_Order_By>>;
  where?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_SitesecuritycoveragesArgs = {
  distinct_on?: InputMaybe<Array<Core_Sitesecuritycoverage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitesecuritycoverage_Order_By>>;
  where?: InputMaybe<Core_Sitesecuritycoverage_Bool_Exp>;
};


/** columns and relationships of "core_site" */
export type Core_SiteCore_Sitesecuritycoverages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Sitesecuritycoverage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitesecuritycoverage_Order_By>>;
  where?: InputMaybe<Core_Sitesecuritycoverage_Bool_Exp>;
};

/** aggregated selection of "core_site" */
export type Core_Site_Aggregate = {
  __typename?: 'core_site_aggregate';
  aggregate?: Maybe<Core_Site_Aggregate_Fields>;
  nodes: Array<Core_Site>;
};

export type Core_Site_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Core_Site_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Core_Site_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Core_Site_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Core_Site_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Core_Site_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Core_Site_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Core_Site_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Core_Site_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Core_Site_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Core_Site_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Core_Site_Aggregate_Bool_Exp_Var_Samp>;
};

export type Core_Site_Aggregate_Bool_Exp_Avg = {
  arguments: Core_Site_Select_Column_Core_Site_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Site_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Site_Aggregate_Bool_Exp_Bool_And = {
  arguments: Core_Site_Select_Column_Core_Site_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Site_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Core_Site_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Core_Site_Select_Column_Core_Site_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Site_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Core_Site_Aggregate_Bool_Exp_Corr = {
  arguments: Core_Site_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Site_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Site_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Core_Site_Select_Column_Core_Site_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Core_Site_Select_Column_Core_Site_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Core_Site_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Site_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Site_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Core_Site_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Core_Site_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Site_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Site_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Core_Site_Select_Column_Core_Site_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Core_Site_Select_Column_Core_Site_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Core_Site_Aggregate_Bool_Exp_Max = {
  arguments: Core_Site_Select_Column_Core_Site_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Site_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Site_Aggregate_Bool_Exp_Min = {
  arguments: Core_Site_Select_Column_Core_Site_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Site_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Site_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Core_Site_Select_Column_Core_Site_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Site_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Site_Aggregate_Bool_Exp_Sum = {
  arguments: Core_Site_Select_Column_Core_Site_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Site_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Site_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Core_Site_Select_Column_Core_Site_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Site_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "core_site" */
export type Core_Site_Aggregate_Fields = {
  __typename?: 'core_site_aggregate_fields';
  avg?: Maybe<Core_Site_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Site_Max_Fields>;
  min?: Maybe<Core_Site_Min_Fields>;
  stddev?: Maybe<Core_Site_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Site_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Site_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Site_Sum_Fields>;
  var_pop?: Maybe<Core_Site_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Site_Var_Samp_Fields>;
  variance?: Maybe<Core_Site_Variance_Fields>;
};


/** aggregate fields of "core_site" */
export type Core_Site_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Site_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_site" */
export type Core_Site_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Site_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Site_Max_Order_By>;
  min?: InputMaybe<Core_Site_Min_Order_By>;
  stddev?: InputMaybe<Core_Site_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Site_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Site_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Site_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Site_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Site_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Site_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_site" */
export type Core_Site_Arr_Rel_Insert_Input = {
  data: Array<Core_Site_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Site_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Site_Avg_Fields = {
  __typename?: 'core_site_avg_fields';
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  site_status_id?: Maybe<Scalars['Float']>;
  site_type_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_site" */
export type Core_Site_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
  site_status_id?: InputMaybe<Order_By>;
  site_type_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_site". All fields are combined with a logical 'AND'. */
export type Core_Site_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Site_Bool_Exp>>;
  _not?: InputMaybe<Core_Site_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Site_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  core_agreements?: InputMaybe<Core_Agreement_Bool_Exp>;
  core_agreements_aggregate?: InputMaybe<Core_Agreement_Aggregate_Bool_Exp>;
  core_boxes?: InputMaybe<Core_Box_Bool_Exp>;
  core_boxes_aggregate?: InputMaybe<Core_Box_Aggregate_Bool_Exp>;
  core_disruptions?: InputMaybe<Core_Disruptions_Bool_Exp>;
  core_disruptions_aggregate?: InputMaybe<Core_Disruptions_Aggregate_Bool_Exp>;
  core_equipments?: InputMaybe<Core_Equipment_Bool_Exp>;
  core_equipments_aggregate?: InputMaybe<Core_Equipment_Aggregate_Bool_Exp>;
  core_esps?: InputMaybe<Core_Esp_Bool_Exp>;
  core_esps_aggregate?: InputMaybe<Core_Esp_Aggregate_Bool_Exp>;
  core_eventlogs?: InputMaybe<Core_Eventlog_Bool_Exp>;
  core_eventlogs_aggregate?: InputMaybe<Core_Eventlog_Aggregate_Bool_Exp>;
  core_incidents?: InputMaybe<Core_Incident_Bool_Exp>;
  core_incidents_aggregate?: InputMaybe<Core_Incident_Aggregate_Bool_Exp>;
  core_jobs?: InputMaybe<Core_Job_Bool_Exp>;
  core_jobs_aggregate?: InputMaybe<Core_Job_Aggregate_Bool_Exp>;
  core_metrics?: InputMaybe<Core_Metric_Bool_Exp>;
  core_metrics_aggregate?: InputMaybe<Core_Metric_Aggregate_Bool_Exp>;
  core_rectifieralarms?: InputMaybe<Core_Rectifieralarm_Bool_Exp>;
  core_rectifieralarms_aggregate?: InputMaybe<Core_Rectifieralarm_Aggregate_Bool_Exp>;
  core_requestaccesses?: InputMaybe<Core_Requestaccess_Bool_Exp>;
  core_requestaccesses_aggregate?: InputMaybe<Core_Requestaccess_Aggregate_Bool_Exp>;
  core_securitycallout?: InputMaybe<Core_Securitycallouts_Bool_Exp>;
  core_securitycallouts?: InputMaybe<Core_Securitycallouts_Bool_Exp>;
  core_securitycallouts_aggregate?: InputMaybe<Core_Securitycallouts_Aggregate_Bool_Exp>;
  core_sitechargeestimates?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
  core_sitechargeestimates_aggregate?: InputMaybe<Core_Sitechargeestimate_Aggregate_Bool_Exp>;
  core_siteregion?: InputMaybe<Core_Siteregion_Bool_Exp>;
  core_sitesecuritycoverage?: InputMaybe<Core_Sitesecuritycoverage_Bool_Exp>;
  core_sitesecuritycoverages?: InputMaybe<Core_Sitesecuritycoverage_Bool_Exp>;
  core_sitesecuritycoverages_aggregate?: InputMaybe<Core_Sitesecuritycoverage_Aggregate_Bool_Exp>;
  core_sitestatus?: InputMaybe<Core_Sitestatus_Bool_Exp>;
  core_sitetype?: InputMaybe<Core_Sitetype_Bool_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  effective_date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  latitude?: InputMaybe<Float8_Comparison_Exp>;
  longitude?: InputMaybe<Float8_Comparison_Exp>;
  municipality?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  region_id?: InputMaybe<Bigint_Comparison_Exp>;
  site_id?: InputMaybe<String_Comparison_Exp>;
  site_status_id?: InputMaybe<Bigint_Comparison_Exp>;
  site_type_id?: InputMaybe<Bigint_Comparison_Exp>;
  status_changed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  up_since?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_site" */
export enum Core_Site_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreSitePkey = 'core_site_pkey'
}

/** input type for incrementing numeric columns in table "core_site" */
export type Core_Site_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  region_id?: InputMaybe<Scalars['bigint']>;
  site_status_id?: InputMaybe<Scalars['bigint']>;
  site_type_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_site" */
export type Core_Site_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  core_agreements?: InputMaybe<Core_Agreement_Arr_Rel_Insert_Input>;
  core_boxes?: InputMaybe<Core_Box_Arr_Rel_Insert_Input>;
  core_disruptions?: InputMaybe<Core_Disruptions_Arr_Rel_Insert_Input>;
  core_equipments?: InputMaybe<Core_Equipment_Arr_Rel_Insert_Input>;
  core_esps?: InputMaybe<Core_Esp_Arr_Rel_Insert_Input>;
  core_eventlogs?: InputMaybe<Core_Eventlog_Arr_Rel_Insert_Input>;
  core_incidents?: InputMaybe<Core_Incident_Arr_Rel_Insert_Input>;
  core_jobs?: InputMaybe<Core_Job_Arr_Rel_Insert_Input>;
  core_metrics?: InputMaybe<Core_Metric_Arr_Rel_Insert_Input>;
  core_rectifieralarms?: InputMaybe<Core_Rectifieralarm_Arr_Rel_Insert_Input>;
  core_requestaccesses?: InputMaybe<Core_Requestaccess_Arr_Rel_Insert_Input>;
  core_securitycallout?: InputMaybe<Core_Securitycallouts_Obj_Rel_Insert_Input>;
  core_securitycallouts?: InputMaybe<Core_Securitycallouts_Arr_Rel_Insert_Input>;
  core_sitechargeestimates?: InputMaybe<Core_Sitechargeestimate_Arr_Rel_Insert_Input>;
  core_siteregion?: InputMaybe<Core_Siteregion_Obj_Rel_Insert_Input>;
  core_sitesecuritycoverage?: InputMaybe<Core_Sitesecuritycoverage_Obj_Rel_Insert_Input>;
  core_sitesecuritycoverages?: InputMaybe<Core_Sitesecuritycoverage_Arr_Rel_Insert_Input>;
  core_sitestatus?: InputMaybe<Core_Sitestatus_Obj_Rel_Insert_Input>;
  core_sitetype?: InputMaybe<Core_Sitetype_Obj_Rel_Insert_Input>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  effective_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  municipality?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  region_id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['String']>;
  site_status_id?: InputMaybe<Scalars['bigint']>;
  site_type_id?: InputMaybe<Scalars['bigint']>;
  status_changed_at?: InputMaybe<Scalars['timestamptz']>;
  up_since?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Core_Site_Max_Fields = {
  __typename?: 'core_site_max_fields';
  address?: Maybe<Scalars['String']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  effective_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  municipality?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['String']>;
  site_status_id?: Maybe<Scalars['bigint']>;
  site_type_id?: Maybe<Scalars['bigint']>;
  status_changed_at?: Maybe<Scalars['timestamptz']>;
  up_since?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "core_site" */
export type Core_Site_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  effective_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  municipality?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  site_status_id?: InputMaybe<Order_By>;
  site_type_id?: InputMaybe<Order_By>;
  status_changed_at?: InputMaybe<Order_By>;
  up_since?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Site_Min_Fields = {
  __typename?: 'core_site_min_fields';
  address?: Maybe<Scalars['String']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  effective_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['bigint']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  municipality?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['String']>;
  site_status_id?: Maybe<Scalars['bigint']>;
  site_type_id?: Maybe<Scalars['bigint']>;
  status_changed_at?: Maybe<Scalars['timestamptz']>;
  up_since?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "core_site" */
export type Core_Site_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  effective_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  municipality?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  site_status_id?: InputMaybe<Order_By>;
  site_type_id?: InputMaybe<Order_By>;
  status_changed_at?: InputMaybe<Order_By>;
  up_since?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_site" */
export type Core_Site_Mutation_Response = {
  __typename?: 'core_site_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Site>;
};

/** input type for inserting object relation for remote table "core_site" */
export type Core_Site_Obj_Rel_Insert_Input = {
  data: Core_Site_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Site_On_Conflict>;
};

/** on_conflict condition type for table "core_site" */
export type Core_Site_On_Conflict = {
  constraint: Core_Site_Constraint;
  update_columns?: Array<Core_Site_Update_Column>;
  where?: InputMaybe<Core_Site_Bool_Exp>;
};

/** Ordering options when selecting data from "core_site". */
export type Core_Site_Order_By = {
  address?: InputMaybe<Order_By>;
  core_agreements_aggregate?: InputMaybe<Core_Agreement_Aggregate_Order_By>;
  core_boxes_aggregate?: InputMaybe<Core_Box_Aggregate_Order_By>;
  core_disruptions_aggregate?: InputMaybe<Core_Disruptions_Aggregate_Order_By>;
  core_equipments_aggregate?: InputMaybe<Core_Equipment_Aggregate_Order_By>;
  core_esps_aggregate?: InputMaybe<Core_Esp_Aggregate_Order_By>;
  core_eventlogs_aggregate?: InputMaybe<Core_Eventlog_Aggregate_Order_By>;
  core_incidents_aggregate?: InputMaybe<Core_Incident_Aggregate_Order_By>;
  core_jobs_aggregate?: InputMaybe<Core_Job_Aggregate_Order_By>;
  core_metrics_aggregate?: InputMaybe<Core_Metric_Aggregate_Order_By>;
  core_rectifieralarms_aggregate?: InputMaybe<Core_Rectifieralarm_Aggregate_Order_By>;
  core_requestaccesses_aggregate?: InputMaybe<Core_Requestaccess_Aggregate_Order_By>;
  core_securitycallout?: InputMaybe<Core_Securitycallouts_Order_By>;
  core_securitycallouts_aggregate?: InputMaybe<Core_Securitycallouts_Aggregate_Order_By>;
  core_sitechargeestimates_aggregate?: InputMaybe<Core_Sitechargeestimate_Aggregate_Order_By>;
  core_siteregion?: InputMaybe<Core_Siteregion_Order_By>;
  core_sitesecuritycoverage?: InputMaybe<Core_Sitesecuritycoverage_Order_By>;
  core_sitesecuritycoverages_aggregate?: InputMaybe<Core_Sitesecuritycoverage_Aggregate_Order_By>;
  core_sitestatus?: InputMaybe<Core_Sitestatus_Order_By>;
  core_sitetype?: InputMaybe<Core_Sitetype_Order_By>;
  created_on?: InputMaybe<Order_By>;
  effective_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_enabled?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  municipality?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  site_status_id?: InputMaybe<Order_By>;
  site_type_id?: InputMaybe<Order_By>;
  status_changed_at?: InputMaybe<Order_By>;
  up_since?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_site */
export type Core_Site_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_site" */
export enum Core_Site_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  EffectiveDate = 'effective_date',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Municipality = 'municipality',
  /** column name */
  Name = 'name',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  SiteStatusId = 'site_status_id',
  /** column name */
  SiteTypeId = 'site_type_id',
  /** column name */
  StatusChangedAt = 'status_changed_at',
  /** column name */
  UpSince = 'up_since'
}

/** select "core_site_aggregate_bool_exp_avg_arguments_columns" columns of table "core_site" */
export enum Core_Site_Select_Column_Core_Site_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** select "core_site_aggregate_bool_exp_bool_and_arguments_columns" columns of table "core_site" */
export enum Core_Site_Select_Column_Core_Site_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsEnabled = 'is_enabled'
}

/** select "core_site_aggregate_bool_exp_bool_or_arguments_columns" columns of table "core_site" */
export enum Core_Site_Select_Column_Core_Site_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsEnabled = 'is_enabled'
}

/** select "core_site_aggregate_bool_exp_corr_arguments_columns" columns of table "core_site" */
export enum Core_Site_Select_Column_Core_Site_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** select "core_site_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "core_site" */
export enum Core_Site_Select_Column_Core_Site_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** select "core_site_aggregate_bool_exp_max_arguments_columns" columns of table "core_site" */
export enum Core_Site_Select_Column_Core_Site_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** select "core_site_aggregate_bool_exp_min_arguments_columns" columns of table "core_site" */
export enum Core_Site_Select_Column_Core_Site_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** select "core_site_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "core_site" */
export enum Core_Site_Select_Column_Core_Site_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** select "core_site_aggregate_bool_exp_sum_arguments_columns" columns of table "core_site" */
export enum Core_Site_Select_Column_Core_Site_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** select "core_site_aggregate_bool_exp_var_samp_arguments_columns" columns of table "core_site" */
export enum Core_Site_Select_Column_Core_Site_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude'
}

/** input type for updating data in table "core_site" */
export type Core_Site_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  effective_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  municipality?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  region_id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['String']>;
  site_status_id?: InputMaybe<Scalars['bigint']>;
  site_type_id?: InputMaybe<Scalars['bigint']>;
  status_changed_at?: InputMaybe<Scalars['timestamptz']>;
  up_since?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Core_Site_Stddev_Fields = {
  __typename?: 'core_site_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  site_status_id?: Maybe<Scalars['Float']>;
  site_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_site" */
export type Core_Site_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
  site_status_id?: InputMaybe<Order_By>;
  site_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Site_Stddev_Pop_Fields = {
  __typename?: 'core_site_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  site_status_id?: Maybe<Scalars['Float']>;
  site_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_site" */
export type Core_Site_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
  site_status_id?: InputMaybe<Order_By>;
  site_type_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Site_Stddev_Samp_Fields = {
  __typename?: 'core_site_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  site_status_id?: Maybe<Scalars['Float']>;
  site_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_site" */
export type Core_Site_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
  site_status_id?: InputMaybe<Order_By>;
  site_type_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_site" */
export type Core_Site_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Site_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Site_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  effective_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['float8']>;
  longitude?: InputMaybe<Scalars['float8']>;
  municipality?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  region_id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['String']>;
  site_status_id?: InputMaybe<Scalars['bigint']>;
  site_type_id?: InputMaybe<Scalars['bigint']>;
  status_changed_at?: InputMaybe<Scalars['timestamptz']>;
  up_since?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Core_Site_Sum_Fields = {
  __typename?: 'core_site_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
  region_id?: Maybe<Scalars['bigint']>;
  site_status_id?: Maybe<Scalars['bigint']>;
  site_type_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_site" */
export type Core_Site_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
  site_status_id?: InputMaybe<Order_By>;
  site_type_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_site" */
export enum Core_Site_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  EffectiveDate = 'effective_date',
  /** column name */
  Id = 'id',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  Municipality = 'municipality',
  /** column name */
  Name = 'name',
  /** column name */
  RegionId = 'region_id',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  SiteStatusId = 'site_status_id',
  /** column name */
  SiteTypeId = 'site_type_id',
  /** column name */
  StatusChangedAt = 'status_changed_at',
  /** column name */
  UpSince = 'up_since'
}

export type Core_Site_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Site_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Site_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Site_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Site_Var_Pop_Fields = {
  __typename?: 'core_site_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  site_status_id?: Maybe<Scalars['Float']>;
  site_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_site" */
export type Core_Site_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
  site_status_id?: InputMaybe<Order_By>;
  site_type_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Site_Var_Samp_Fields = {
  __typename?: 'core_site_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  site_status_id?: Maybe<Scalars['Float']>;
  site_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_site" */
export type Core_Site_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
  site_status_id?: InputMaybe<Order_By>;
  site_type_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Site_Variance_Fields = {
  __typename?: 'core_site_variance_fields';
  id?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  region_id?: Maybe<Scalars['Float']>;
  site_status_id?: Maybe<Scalars['Float']>;
  site_type_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_site" */
export type Core_Site_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  region_id?: InputMaybe<Order_By>;
  site_status_id?: InputMaybe<Order_By>;
  site_type_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_sitechargeestimate" */
export type Core_Sitechargeestimate = {
  __typename?: 'core_sitechargeestimate';
  charge_period_end: Scalars['timestamptz'];
  charge_period_start: Scalars['timestamptz'];
  /** An object relationship */
  core_site: Core_Site;
  /** An object relationship */
  core_tariff: Core_Tariff;
  cost: Scalars['float8'];
  created_on: Scalars['timestamptz'];
  id: Scalars['bigint'];
  site_id: Scalars['bigint'];
  tariff_id: Scalars['bigint'];
  total_units: Scalars['float8'];
};

/** aggregated selection of "core_sitechargeestimate" */
export type Core_Sitechargeestimate_Aggregate = {
  __typename?: 'core_sitechargeestimate_aggregate';
  aggregate?: Maybe<Core_Sitechargeestimate_Aggregate_Fields>;
  nodes: Array<Core_Sitechargeestimate>;
};

export type Core_Sitechargeestimate_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Core_Sitechargeestimate_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Core_Sitechargeestimate_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Core_Sitechargeestimate_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Core_Sitechargeestimate_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Core_Sitechargeestimate_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Core_Sitechargeestimate_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Core_Sitechargeestimate_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Core_Sitechargeestimate_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Core_Sitechargeestimate_Aggregate_Bool_Exp_Var_Samp>;
};

export type Core_Sitechargeestimate_Aggregate_Bool_Exp_Avg = {
  arguments: Core_Sitechargeestimate_Select_Column_Core_Sitechargeestimate_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Sitechargeestimate_Aggregate_Bool_Exp_Corr = {
  arguments: Core_Sitechargeestimate_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Sitechargeestimate_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Core_Sitechargeestimate_Select_Column_Core_Sitechargeestimate_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Core_Sitechargeestimate_Select_Column_Core_Sitechargeestimate_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Core_Sitechargeestimate_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Sitechargeestimate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Core_Sitechargeestimate_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Core_Sitechargeestimate_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Sitechargeestimate_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Core_Sitechargeestimate_Select_Column_Core_Sitechargeestimate_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Core_Sitechargeestimate_Select_Column_Core_Sitechargeestimate_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Core_Sitechargeestimate_Aggregate_Bool_Exp_Max = {
  arguments: Core_Sitechargeestimate_Select_Column_Core_Sitechargeestimate_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Sitechargeestimate_Aggregate_Bool_Exp_Min = {
  arguments: Core_Sitechargeestimate_Select_Column_Core_Sitechargeestimate_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Sitechargeestimate_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Core_Sitechargeestimate_Select_Column_Core_Sitechargeestimate_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Sitechargeestimate_Aggregate_Bool_Exp_Sum = {
  arguments: Core_Sitechargeestimate_Select_Column_Core_Sitechargeestimate_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Sitechargeestimate_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Core_Sitechargeestimate_Select_Column_Core_Sitechargeestimate_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "core_sitechargeestimate" */
export type Core_Sitechargeestimate_Aggregate_Fields = {
  __typename?: 'core_sitechargeestimate_aggregate_fields';
  avg?: Maybe<Core_Sitechargeestimate_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Sitechargeestimate_Max_Fields>;
  min?: Maybe<Core_Sitechargeestimate_Min_Fields>;
  stddev?: Maybe<Core_Sitechargeestimate_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Sitechargeestimate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Sitechargeestimate_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Sitechargeestimate_Sum_Fields>;
  var_pop?: Maybe<Core_Sitechargeestimate_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Sitechargeestimate_Var_Samp_Fields>;
  variance?: Maybe<Core_Sitechargeestimate_Variance_Fields>;
};


/** aggregate fields of "core_sitechargeestimate" */
export type Core_Sitechargeestimate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Sitechargeestimate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_sitechargeestimate" */
export type Core_Sitechargeestimate_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Sitechargeestimate_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Sitechargeestimate_Max_Order_By>;
  min?: InputMaybe<Core_Sitechargeestimate_Min_Order_By>;
  stddev?: InputMaybe<Core_Sitechargeestimate_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Sitechargeestimate_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Sitechargeestimate_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Sitechargeestimate_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Sitechargeestimate_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Sitechargeestimate_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Sitechargeestimate_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_sitechargeestimate" */
export type Core_Sitechargeestimate_Arr_Rel_Insert_Input = {
  data: Array<Core_Sitechargeestimate_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Sitechargeestimate_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Sitechargeestimate_Avg_Fields = {
  __typename?: 'core_sitechargeestimate_avg_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
  total_units?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_sitechargeestimate" */
export type Core_Sitechargeestimate_Avg_Order_By = {
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
  total_units?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_sitechargeestimate". All fields are combined with a logical 'AND'. */
export type Core_Sitechargeestimate_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Sitechargeestimate_Bool_Exp>>;
  _not?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Sitechargeestimate_Bool_Exp>>;
  charge_period_end?: InputMaybe<Timestamptz_Comparison_Exp>;
  charge_period_start?: InputMaybe<Timestamptz_Comparison_Exp>;
  core_site?: InputMaybe<Core_Site_Bool_Exp>;
  core_tariff?: InputMaybe<Core_Tariff_Bool_Exp>;
  cost?: InputMaybe<Float8_Comparison_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  site_id?: InputMaybe<Bigint_Comparison_Exp>;
  tariff_id?: InputMaybe<Bigint_Comparison_Exp>;
  total_units?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_sitechargeestimate" */
export enum Core_Sitechargeestimate_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreSitechargeestimatePkey = 'core_sitechargeestimate_pkey'
}

/** input type for incrementing numeric columns in table "core_sitechargeestimate" */
export type Core_Sitechargeestimate_Inc_Input = {
  cost?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  tariff_id?: InputMaybe<Scalars['bigint']>;
  total_units?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "core_sitechargeestimate" */
export type Core_Sitechargeestimate_Insert_Input = {
  charge_period_end?: InputMaybe<Scalars['timestamptz']>;
  charge_period_start?: InputMaybe<Scalars['timestamptz']>;
  core_site?: InputMaybe<Core_Site_Obj_Rel_Insert_Input>;
  core_tariff?: InputMaybe<Core_Tariff_Obj_Rel_Insert_Input>;
  cost?: InputMaybe<Scalars['float8']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  tariff_id?: InputMaybe<Scalars['bigint']>;
  total_units?: InputMaybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Core_Sitechargeestimate_Max_Fields = {
  __typename?: 'core_sitechargeestimate_max_fields';
  charge_period_end?: Maybe<Scalars['timestamptz']>;
  charge_period_start?: Maybe<Scalars['timestamptz']>;
  cost?: Maybe<Scalars['float8']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
  tariff_id?: Maybe<Scalars['bigint']>;
  total_units?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "core_sitechargeestimate" */
export type Core_Sitechargeestimate_Max_Order_By = {
  charge_period_end?: InputMaybe<Order_By>;
  charge_period_start?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
  total_units?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Sitechargeestimate_Min_Fields = {
  __typename?: 'core_sitechargeestimate_min_fields';
  charge_period_end?: Maybe<Scalars['timestamptz']>;
  charge_period_start?: Maybe<Scalars['timestamptz']>;
  cost?: Maybe<Scalars['float8']>;
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
  tariff_id?: Maybe<Scalars['bigint']>;
  total_units?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "core_sitechargeestimate" */
export type Core_Sitechargeestimate_Min_Order_By = {
  charge_period_end?: InputMaybe<Order_By>;
  charge_period_start?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
  total_units?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_sitechargeestimate" */
export type Core_Sitechargeestimate_Mutation_Response = {
  __typename?: 'core_sitechargeestimate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Sitechargeestimate>;
};

/** on_conflict condition type for table "core_sitechargeestimate" */
export type Core_Sitechargeestimate_On_Conflict = {
  constraint: Core_Sitechargeestimate_Constraint;
  update_columns?: Array<Core_Sitechargeestimate_Update_Column>;
  where?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
};

/** Ordering options when selecting data from "core_sitechargeestimate". */
export type Core_Sitechargeestimate_Order_By = {
  charge_period_end?: InputMaybe<Order_By>;
  charge_period_start?: InputMaybe<Order_By>;
  core_site?: InputMaybe<Core_Site_Order_By>;
  core_tariff?: InputMaybe<Core_Tariff_Order_By>;
  cost?: InputMaybe<Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
  total_units?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_sitechargeestimate */
export type Core_Sitechargeestimate_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_sitechargeestimate" */
export enum Core_Sitechargeestimate_Select_Column {
  /** column name */
  ChargePeriodEnd = 'charge_period_end',
  /** column name */
  ChargePeriodStart = 'charge_period_start',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  TariffId = 'tariff_id',
  /** column name */
  TotalUnits = 'total_units'
}

/** select "core_sitechargeestimate_aggregate_bool_exp_avg_arguments_columns" columns of table "core_sitechargeestimate" */
export enum Core_Sitechargeestimate_Select_Column_Core_Sitechargeestimate_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Cost = 'cost',
  /** column name */
  TotalUnits = 'total_units'
}

/** select "core_sitechargeestimate_aggregate_bool_exp_corr_arguments_columns" columns of table "core_sitechargeestimate" */
export enum Core_Sitechargeestimate_Select_Column_Core_Sitechargeestimate_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Cost = 'cost',
  /** column name */
  TotalUnits = 'total_units'
}

/** select "core_sitechargeestimate_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "core_sitechargeestimate" */
export enum Core_Sitechargeestimate_Select_Column_Core_Sitechargeestimate_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Cost = 'cost',
  /** column name */
  TotalUnits = 'total_units'
}

/** select "core_sitechargeestimate_aggregate_bool_exp_max_arguments_columns" columns of table "core_sitechargeestimate" */
export enum Core_Sitechargeestimate_Select_Column_Core_Sitechargeestimate_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Cost = 'cost',
  /** column name */
  TotalUnits = 'total_units'
}

/** select "core_sitechargeestimate_aggregate_bool_exp_min_arguments_columns" columns of table "core_sitechargeestimate" */
export enum Core_Sitechargeestimate_Select_Column_Core_Sitechargeestimate_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Cost = 'cost',
  /** column name */
  TotalUnits = 'total_units'
}

/** select "core_sitechargeestimate_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "core_sitechargeestimate" */
export enum Core_Sitechargeestimate_Select_Column_Core_Sitechargeestimate_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Cost = 'cost',
  /** column name */
  TotalUnits = 'total_units'
}

/** select "core_sitechargeestimate_aggregate_bool_exp_sum_arguments_columns" columns of table "core_sitechargeestimate" */
export enum Core_Sitechargeestimate_Select_Column_Core_Sitechargeestimate_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Cost = 'cost',
  /** column name */
  TotalUnits = 'total_units'
}

/** select "core_sitechargeestimate_aggregate_bool_exp_var_samp_arguments_columns" columns of table "core_sitechargeestimate" */
export enum Core_Sitechargeestimate_Select_Column_Core_Sitechargeestimate_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Cost = 'cost',
  /** column name */
  TotalUnits = 'total_units'
}

/** input type for updating data in table "core_sitechargeestimate" */
export type Core_Sitechargeestimate_Set_Input = {
  charge_period_end?: InputMaybe<Scalars['timestamptz']>;
  charge_period_start?: InputMaybe<Scalars['timestamptz']>;
  cost?: InputMaybe<Scalars['float8']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  tariff_id?: InputMaybe<Scalars['bigint']>;
  total_units?: InputMaybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Core_Sitechargeestimate_Stddev_Fields = {
  __typename?: 'core_sitechargeestimate_stddev_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
  total_units?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_sitechargeestimate" */
export type Core_Sitechargeestimate_Stddev_Order_By = {
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
  total_units?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Sitechargeestimate_Stddev_Pop_Fields = {
  __typename?: 'core_sitechargeestimate_stddev_pop_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
  total_units?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_sitechargeestimate" */
export type Core_Sitechargeestimate_Stddev_Pop_Order_By = {
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
  total_units?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Sitechargeestimate_Stddev_Samp_Fields = {
  __typename?: 'core_sitechargeestimate_stddev_samp_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
  total_units?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_sitechargeestimate" */
export type Core_Sitechargeestimate_Stddev_Samp_Order_By = {
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
  total_units?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_sitechargeestimate" */
export type Core_Sitechargeestimate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Sitechargeestimate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Sitechargeestimate_Stream_Cursor_Value_Input = {
  charge_period_end?: InputMaybe<Scalars['timestamptz']>;
  charge_period_start?: InputMaybe<Scalars['timestamptz']>;
  cost?: InputMaybe<Scalars['float8']>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
  tariff_id?: InputMaybe<Scalars['bigint']>;
  total_units?: InputMaybe<Scalars['float8']>;
};

/** aggregate sum on columns */
export type Core_Sitechargeestimate_Sum_Fields = {
  __typename?: 'core_sitechargeestimate_sum_fields';
  cost?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
  tariff_id?: Maybe<Scalars['bigint']>;
  total_units?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "core_sitechargeestimate" */
export type Core_Sitechargeestimate_Sum_Order_By = {
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
  total_units?: InputMaybe<Order_By>;
};

/** update columns of table "core_sitechargeestimate" */
export enum Core_Sitechargeestimate_Update_Column {
  /** column name */
  ChargePeriodEnd = 'charge_period_end',
  /** column name */
  ChargePeriodStart = 'charge_period_start',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  SiteId = 'site_id',
  /** column name */
  TariffId = 'tariff_id',
  /** column name */
  TotalUnits = 'total_units'
}

export type Core_Sitechargeestimate_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Sitechargeestimate_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Sitechargeestimate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Sitechargeestimate_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Sitechargeestimate_Var_Pop_Fields = {
  __typename?: 'core_sitechargeestimate_var_pop_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
  total_units?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_sitechargeestimate" */
export type Core_Sitechargeestimate_Var_Pop_Order_By = {
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
  total_units?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Sitechargeestimate_Var_Samp_Fields = {
  __typename?: 'core_sitechargeestimate_var_samp_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
  total_units?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_sitechargeestimate" */
export type Core_Sitechargeestimate_Var_Samp_Order_By = {
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
  total_units?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Sitechargeestimate_Variance_Fields = {
  __typename?: 'core_sitechargeestimate_variance_fields';
  cost?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
  total_units?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_sitechargeestimate" */
export type Core_Sitechargeestimate_Variance_Order_By = {
  cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
  total_units?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_siteregion" */
export type Core_Siteregion = {
  __typename?: 'core_siteregion';
  /** An array relationship */
  core_sites: Array<Core_Site>;
  /** An aggregate relationship */
  core_sites_aggregate: Core_Site_Aggregate;
  id: Scalars['bigint'];
  region_code: Scalars['String'];
  region_name?: Maybe<Scalars['String']>;
};


/** columns and relationships of "core_siteregion" */
export type Core_SiteregionCore_SitesArgs = {
  distinct_on?: InputMaybe<Array<Core_Site_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Site_Order_By>>;
  where?: InputMaybe<Core_Site_Bool_Exp>;
};


/** columns and relationships of "core_siteregion" */
export type Core_SiteregionCore_Sites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Site_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Site_Order_By>>;
  where?: InputMaybe<Core_Site_Bool_Exp>;
};

/** aggregated selection of "core_siteregion" */
export type Core_Siteregion_Aggregate = {
  __typename?: 'core_siteregion_aggregate';
  aggregate?: Maybe<Core_Siteregion_Aggregate_Fields>;
  nodes: Array<Core_Siteregion>;
};

/** aggregate fields of "core_siteregion" */
export type Core_Siteregion_Aggregate_Fields = {
  __typename?: 'core_siteregion_aggregate_fields';
  avg?: Maybe<Core_Siteregion_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Siteregion_Max_Fields>;
  min?: Maybe<Core_Siteregion_Min_Fields>;
  stddev?: Maybe<Core_Siteregion_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Siteregion_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Siteregion_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Siteregion_Sum_Fields>;
  var_pop?: Maybe<Core_Siteregion_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Siteregion_Var_Samp_Fields>;
  variance?: Maybe<Core_Siteregion_Variance_Fields>;
};


/** aggregate fields of "core_siteregion" */
export type Core_Siteregion_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Siteregion_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Core_Siteregion_Avg_Fields = {
  __typename?: 'core_siteregion_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "core_siteregion". All fields are combined with a logical 'AND'. */
export type Core_Siteregion_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Siteregion_Bool_Exp>>;
  _not?: InputMaybe<Core_Siteregion_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Siteregion_Bool_Exp>>;
  core_sites?: InputMaybe<Core_Site_Bool_Exp>;
  core_sites_aggregate?: InputMaybe<Core_Site_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  region_code?: InputMaybe<String_Comparison_Exp>;
  region_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_siteregion" */
export enum Core_Siteregion_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreSiteregionPkey = 'core_siteregion_pkey',
  /** unique or primary key constraint on columns "region_code" */
  CoreSiteregionRegionCode_0dcb022bUniq = 'core_siteregion_region_code_0dcb022b_uniq'
}

/** input type for incrementing numeric columns in table "core_siteregion" */
export type Core_Siteregion_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_siteregion" */
export type Core_Siteregion_Insert_Input = {
  core_sites?: InputMaybe<Core_Site_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  region_code?: InputMaybe<Scalars['String']>;
  region_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Siteregion_Max_Fields = {
  __typename?: 'core_siteregion_max_fields';
  id?: Maybe<Scalars['bigint']>;
  region_code?: Maybe<Scalars['String']>;
  region_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Core_Siteregion_Min_Fields = {
  __typename?: 'core_siteregion_min_fields';
  id?: Maybe<Scalars['bigint']>;
  region_code?: Maybe<Scalars['String']>;
  region_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "core_siteregion" */
export type Core_Siteregion_Mutation_Response = {
  __typename?: 'core_siteregion_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Siteregion>;
};

/** input type for inserting object relation for remote table "core_siteregion" */
export type Core_Siteregion_Obj_Rel_Insert_Input = {
  data: Core_Siteregion_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Siteregion_On_Conflict>;
};

/** on_conflict condition type for table "core_siteregion" */
export type Core_Siteregion_On_Conflict = {
  constraint: Core_Siteregion_Constraint;
  update_columns?: Array<Core_Siteregion_Update_Column>;
  where?: InputMaybe<Core_Siteregion_Bool_Exp>;
};

/** Ordering options when selecting data from "core_siteregion". */
export type Core_Siteregion_Order_By = {
  core_sites_aggregate?: InputMaybe<Core_Site_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  region_code?: InputMaybe<Order_By>;
  region_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_siteregion */
export type Core_Siteregion_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_siteregion" */
export enum Core_Siteregion_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  RegionCode = 'region_code',
  /** column name */
  RegionName = 'region_name'
}

/** input type for updating data in table "core_siteregion" */
export type Core_Siteregion_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  region_code?: InputMaybe<Scalars['String']>;
  region_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Siteregion_Stddev_Fields = {
  __typename?: 'core_siteregion_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Core_Siteregion_Stddev_Pop_Fields = {
  __typename?: 'core_siteregion_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Core_Siteregion_Stddev_Samp_Fields = {
  __typename?: 'core_siteregion_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "core_siteregion" */
export type Core_Siteregion_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Siteregion_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Siteregion_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  region_code?: InputMaybe<Scalars['String']>;
  region_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Siteregion_Sum_Fields = {
  __typename?: 'core_siteregion_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "core_siteregion" */
export enum Core_Siteregion_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  RegionCode = 'region_code',
  /** column name */
  RegionName = 'region_name'
}

export type Core_Siteregion_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Siteregion_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Siteregion_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Siteregion_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Siteregion_Var_Pop_Fields = {
  __typename?: 'core_siteregion_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Core_Siteregion_Var_Samp_Fields = {
  __typename?: 'core_siteregion_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Core_Siteregion_Variance_Fields = {
  __typename?: 'core_siteregion_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage = {
  __typename?: 'core_sitesecuritycoverage';
  /** An object relationship */
  core_site?: Maybe<Core_Site>;
  created_on: Scalars['timestamptz'];
  id: Scalars['bigint'];
  is_covered: Scalars['Boolean'];
  site_id?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage_Aggregate = {
  __typename?: 'core_sitesecuritycoverage_aggregate';
  aggregate?: Maybe<Core_Sitesecuritycoverage_Aggregate_Fields>;
  nodes: Array<Core_Sitesecuritycoverage>;
};

export type Core_Sitesecuritycoverage_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Core_Sitesecuritycoverage_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Core_Sitesecuritycoverage_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Core_Sitesecuritycoverage_Aggregate_Bool_Exp_Count>;
};

export type Core_Sitesecuritycoverage_Aggregate_Bool_Exp_Bool_And = {
  arguments: Core_Sitesecuritycoverage_Select_Column_Core_Sitesecuritycoverage_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Sitesecuritycoverage_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Core_Sitesecuritycoverage_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Core_Sitesecuritycoverage_Select_Column_Core_Sitesecuritycoverage_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Sitesecuritycoverage_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Core_Sitesecuritycoverage_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Sitesecuritycoverage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Sitesecuritycoverage_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage_Aggregate_Fields = {
  __typename?: 'core_sitesecuritycoverage_aggregate_fields';
  avg?: Maybe<Core_Sitesecuritycoverage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Sitesecuritycoverage_Max_Fields>;
  min?: Maybe<Core_Sitesecuritycoverage_Min_Fields>;
  stddev?: Maybe<Core_Sitesecuritycoverage_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Sitesecuritycoverage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Sitesecuritycoverage_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Sitesecuritycoverage_Sum_Fields>;
  var_pop?: Maybe<Core_Sitesecuritycoverage_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Sitesecuritycoverage_Var_Samp_Fields>;
  variance?: Maybe<Core_Sitesecuritycoverage_Variance_Fields>;
};


/** aggregate fields of "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Sitesecuritycoverage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Sitesecuritycoverage_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Sitesecuritycoverage_Max_Order_By>;
  min?: InputMaybe<Core_Sitesecuritycoverage_Min_Order_By>;
  stddev?: InputMaybe<Core_Sitesecuritycoverage_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Sitesecuritycoverage_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Sitesecuritycoverage_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Sitesecuritycoverage_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Sitesecuritycoverage_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Sitesecuritycoverage_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Sitesecuritycoverage_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage_Arr_Rel_Insert_Input = {
  data: Array<Core_Sitesecuritycoverage_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Sitesecuritycoverage_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Sitesecuritycoverage_Avg_Fields = {
  __typename?: 'core_sitesecuritycoverage_avg_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_sitesecuritycoverage". All fields are combined with a logical 'AND'. */
export type Core_Sitesecuritycoverage_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Sitesecuritycoverage_Bool_Exp>>;
  _not?: InputMaybe<Core_Sitesecuritycoverage_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Sitesecuritycoverage_Bool_Exp>>;
  core_site?: InputMaybe<Core_Site_Bool_Exp>;
  created_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_covered?: InputMaybe<Boolean_Comparison_Exp>;
  site_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_sitesecuritycoverage" */
export enum Core_Sitesecuritycoverage_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreSitecoveragePkey = 'core_sitecoverage_pkey'
}

/** input type for incrementing numeric columns in table "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage_Insert_Input = {
  core_site?: InputMaybe<Core_Site_Obj_Rel_Insert_Input>;
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_covered?: InputMaybe<Scalars['Boolean']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_Sitesecuritycoverage_Max_Fields = {
  __typename?: 'core_sitesecuritycoverage_max_fields';
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage_Max_Order_By = {
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Sitesecuritycoverage_Min_Fields = {
  __typename?: 'core_sitesecuritycoverage_min_fields';
  created_on?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage_Min_Order_By = {
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage_Mutation_Response = {
  __typename?: 'core_sitesecuritycoverage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Sitesecuritycoverage>;
};

/** input type for inserting object relation for remote table "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage_Obj_Rel_Insert_Input = {
  data: Core_Sitesecuritycoverage_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Sitesecuritycoverage_On_Conflict>;
};

/** on_conflict condition type for table "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage_On_Conflict = {
  constraint: Core_Sitesecuritycoverage_Constraint;
  update_columns?: Array<Core_Sitesecuritycoverage_Update_Column>;
  where?: InputMaybe<Core_Sitesecuritycoverage_Bool_Exp>;
};

/** Ordering options when selecting data from "core_sitesecuritycoverage". */
export type Core_Sitesecuritycoverage_Order_By = {
  core_site?: InputMaybe<Core_Site_Order_By>;
  created_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_covered?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_sitesecuritycoverage */
export type Core_Sitesecuritycoverage_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_sitesecuritycoverage" */
export enum Core_Sitesecuritycoverage_Select_Column {
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  IsCovered = 'is_covered',
  /** column name */
  SiteId = 'site_id'
}

/** select "core_sitesecuritycoverage_aggregate_bool_exp_bool_and_arguments_columns" columns of table "core_sitesecuritycoverage" */
export enum Core_Sitesecuritycoverage_Select_Column_Core_Sitesecuritycoverage_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsCovered = 'is_covered'
}

/** select "core_sitesecuritycoverage_aggregate_bool_exp_bool_or_arguments_columns" columns of table "core_sitesecuritycoverage" */
export enum Core_Sitesecuritycoverage_Select_Column_Core_Sitesecuritycoverage_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsCovered = 'is_covered'
}

/** input type for updating data in table "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage_Set_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_covered?: InputMaybe<Scalars['Boolean']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_Sitesecuritycoverage_Stddev_Fields = {
  __typename?: 'core_sitesecuritycoverage_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Sitesecuritycoverage_Stddev_Pop_Fields = {
  __typename?: 'core_sitesecuritycoverage_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Sitesecuritycoverage_Stddev_Samp_Fields = {
  __typename?: 'core_sitesecuritycoverage_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Sitesecuritycoverage_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Sitesecuritycoverage_Stream_Cursor_Value_Input = {
  created_on?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_covered?: InputMaybe<Scalars['Boolean']>;
  site_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_Sitesecuritycoverage_Sum_Fields = {
  __typename?: 'core_sitesecuritycoverage_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  site_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_sitesecuritycoverage" */
export enum Core_Sitesecuritycoverage_Update_Column {
  /** column name */
  CreatedOn = 'created_on',
  /** column name */
  Id = 'id',
  /** column name */
  IsCovered = 'is_covered',
  /** column name */
  SiteId = 'site_id'
}

export type Core_Sitesecuritycoverage_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Sitesecuritycoverage_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Sitesecuritycoverage_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Sitesecuritycoverage_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Sitesecuritycoverage_Var_Pop_Fields = {
  __typename?: 'core_sitesecuritycoverage_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Sitesecuritycoverage_Var_Samp_Fields = {
  __typename?: 'core_sitesecuritycoverage_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Sitesecuritycoverage_Variance_Fields = {
  __typename?: 'core_sitesecuritycoverage_variance_fields';
  id?: Maybe<Scalars['Float']>;
  site_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_sitesecuritycoverage" */
export type Core_Sitesecuritycoverage_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  site_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_sitestatus" */
export type Core_Sitestatus = {
  __typename?: 'core_sitestatus';
  /** An array relationship */
  core_sites: Array<Core_Site>;
  /** An aggregate relationship */
  core_sites_aggregate: Core_Site_Aggregate;
  id: Scalars['bigint'];
  site_status?: Maybe<Scalars['String']>;
};


/** columns and relationships of "core_sitestatus" */
export type Core_SitestatusCore_SitesArgs = {
  distinct_on?: InputMaybe<Array<Core_Site_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Site_Order_By>>;
  where?: InputMaybe<Core_Site_Bool_Exp>;
};


/** columns and relationships of "core_sitestatus" */
export type Core_SitestatusCore_Sites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Site_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Site_Order_By>>;
  where?: InputMaybe<Core_Site_Bool_Exp>;
};

/** aggregated selection of "core_sitestatus" */
export type Core_Sitestatus_Aggregate = {
  __typename?: 'core_sitestatus_aggregate';
  aggregate?: Maybe<Core_Sitestatus_Aggregate_Fields>;
  nodes: Array<Core_Sitestatus>;
};

/** aggregate fields of "core_sitestatus" */
export type Core_Sitestatus_Aggregate_Fields = {
  __typename?: 'core_sitestatus_aggregate_fields';
  avg?: Maybe<Core_Sitestatus_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Sitestatus_Max_Fields>;
  min?: Maybe<Core_Sitestatus_Min_Fields>;
  stddev?: Maybe<Core_Sitestatus_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Sitestatus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Sitestatus_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Sitestatus_Sum_Fields>;
  var_pop?: Maybe<Core_Sitestatus_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Sitestatus_Var_Samp_Fields>;
  variance?: Maybe<Core_Sitestatus_Variance_Fields>;
};


/** aggregate fields of "core_sitestatus" */
export type Core_Sitestatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Sitestatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Core_Sitestatus_Avg_Fields = {
  __typename?: 'core_sitestatus_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "core_sitestatus". All fields are combined with a logical 'AND'. */
export type Core_Sitestatus_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Sitestatus_Bool_Exp>>;
  _not?: InputMaybe<Core_Sitestatus_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Sitestatus_Bool_Exp>>;
  core_sites?: InputMaybe<Core_Site_Bool_Exp>;
  core_sites_aggregate?: InputMaybe<Core_Site_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  site_status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_sitestatus" */
export enum Core_Sitestatus_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreSitestatusPkey = 'core_sitestatus_pkey'
}

/** input type for incrementing numeric columns in table "core_sitestatus" */
export type Core_Sitestatus_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_sitestatus" */
export type Core_Sitestatus_Insert_Input = {
  core_sites?: InputMaybe<Core_Site_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  site_status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Sitestatus_Max_Fields = {
  __typename?: 'core_sitestatus_max_fields';
  id?: Maybe<Scalars['bigint']>;
  site_status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Core_Sitestatus_Min_Fields = {
  __typename?: 'core_sitestatus_min_fields';
  id?: Maybe<Scalars['bigint']>;
  site_status?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "core_sitestatus" */
export type Core_Sitestatus_Mutation_Response = {
  __typename?: 'core_sitestatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Sitestatus>;
};

/** input type for inserting object relation for remote table "core_sitestatus" */
export type Core_Sitestatus_Obj_Rel_Insert_Input = {
  data: Core_Sitestatus_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Sitestatus_On_Conflict>;
};

/** on_conflict condition type for table "core_sitestatus" */
export type Core_Sitestatus_On_Conflict = {
  constraint: Core_Sitestatus_Constraint;
  update_columns?: Array<Core_Sitestatus_Update_Column>;
  where?: InputMaybe<Core_Sitestatus_Bool_Exp>;
};

/** Ordering options when selecting data from "core_sitestatus". */
export type Core_Sitestatus_Order_By = {
  core_sites_aggregate?: InputMaybe<Core_Site_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  site_status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_sitestatus */
export type Core_Sitestatus_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_sitestatus" */
export enum Core_Sitestatus_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SiteStatus = 'site_status'
}

/** input type for updating data in table "core_sitestatus" */
export type Core_Sitestatus_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  site_status?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Sitestatus_Stddev_Fields = {
  __typename?: 'core_sitestatus_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Core_Sitestatus_Stddev_Pop_Fields = {
  __typename?: 'core_sitestatus_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Core_Sitestatus_Stddev_Samp_Fields = {
  __typename?: 'core_sitestatus_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "core_sitestatus" */
export type Core_Sitestatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Sitestatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Sitestatus_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  site_status?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Sitestatus_Sum_Fields = {
  __typename?: 'core_sitestatus_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "core_sitestatus" */
export enum Core_Sitestatus_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SiteStatus = 'site_status'
}

export type Core_Sitestatus_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Sitestatus_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Sitestatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Sitestatus_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Sitestatus_Var_Pop_Fields = {
  __typename?: 'core_sitestatus_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Core_Sitestatus_Var_Samp_Fields = {
  __typename?: 'core_sitestatus_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Core_Sitestatus_Variance_Fields = {
  __typename?: 'core_sitestatus_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "core_sitetype" */
export type Core_Sitetype = {
  __typename?: 'core_sitetype';
  /** An array relationship */
  core_sites: Array<Core_Site>;
  /** An aggregate relationship */
  core_sites_aggregate: Core_Site_Aggregate;
  id: Scalars['bigint'];
  site_type: Scalars['String'];
};


/** columns and relationships of "core_sitetype" */
export type Core_SitetypeCore_SitesArgs = {
  distinct_on?: InputMaybe<Array<Core_Site_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Site_Order_By>>;
  where?: InputMaybe<Core_Site_Bool_Exp>;
};


/** columns and relationships of "core_sitetype" */
export type Core_SitetypeCore_Sites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Site_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Site_Order_By>>;
  where?: InputMaybe<Core_Site_Bool_Exp>;
};

/** aggregated selection of "core_sitetype" */
export type Core_Sitetype_Aggregate = {
  __typename?: 'core_sitetype_aggregate';
  aggregate?: Maybe<Core_Sitetype_Aggregate_Fields>;
  nodes: Array<Core_Sitetype>;
};

/** aggregate fields of "core_sitetype" */
export type Core_Sitetype_Aggregate_Fields = {
  __typename?: 'core_sitetype_aggregate_fields';
  avg?: Maybe<Core_Sitetype_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Sitetype_Max_Fields>;
  min?: Maybe<Core_Sitetype_Min_Fields>;
  stddev?: Maybe<Core_Sitetype_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Sitetype_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Sitetype_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Sitetype_Sum_Fields>;
  var_pop?: Maybe<Core_Sitetype_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Sitetype_Var_Samp_Fields>;
  variance?: Maybe<Core_Sitetype_Variance_Fields>;
};


/** aggregate fields of "core_sitetype" */
export type Core_Sitetype_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Sitetype_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Core_Sitetype_Avg_Fields = {
  __typename?: 'core_sitetype_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "core_sitetype". All fields are combined with a logical 'AND'. */
export type Core_Sitetype_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Sitetype_Bool_Exp>>;
  _not?: InputMaybe<Core_Sitetype_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Sitetype_Bool_Exp>>;
  core_sites?: InputMaybe<Core_Site_Bool_Exp>;
  core_sites_aggregate?: InputMaybe<Core_Site_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  site_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_sitetype" */
export enum Core_Sitetype_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreSitetypePkey = 'core_sitetype_pkey',
  /** unique or primary key constraint on columns "site_type" */
  CoreSitetypeSiteType_0ba2bca8Uniq = 'core_sitetype_site_type_0ba2bca8_uniq'
}

/** input type for incrementing numeric columns in table "core_sitetype" */
export type Core_Sitetype_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_sitetype" */
export type Core_Sitetype_Insert_Input = {
  core_sites?: InputMaybe<Core_Site_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  site_type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Sitetype_Max_Fields = {
  __typename?: 'core_sitetype_max_fields';
  id?: Maybe<Scalars['bigint']>;
  site_type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Core_Sitetype_Min_Fields = {
  __typename?: 'core_sitetype_min_fields';
  id?: Maybe<Scalars['bigint']>;
  site_type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "core_sitetype" */
export type Core_Sitetype_Mutation_Response = {
  __typename?: 'core_sitetype_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Sitetype>;
};

/** input type for inserting object relation for remote table "core_sitetype" */
export type Core_Sitetype_Obj_Rel_Insert_Input = {
  data: Core_Sitetype_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Sitetype_On_Conflict>;
};

/** on_conflict condition type for table "core_sitetype" */
export type Core_Sitetype_On_Conflict = {
  constraint: Core_Sitetype_Constraint;
  update_columns?: Array<Core_Sitetype_Update_Column>;
  where?: InputMaybe<Core_Sitetype_Bool_Exp>;
};

/** Ordering options when selecting data from "core_sitetype". */
export type Core_Sitetype_Order_By = {
  core_sites_aggregate?: InputMaybe<Core_Site_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  site_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_sitetype */
export type Core_Sitetype_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_sitetype" */
export enum Core_Sitetype_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SiteType = 'site_type'
}

/** input type for updating data in table "core_sitetype" */
export type Core_Sitetype_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  site_type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Sitetype_Stddev_Fields = {
  __typename?: 'core_sitetype_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Core_Sitetype_Stddev_Pop_Fields = {
  __typename?: 'core_sitetype_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Core_Sitetype_Stddev_Samp_Fields = {
  __typename?: 'core_sitetype_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "core_sitetype" */
export type Core_Sitetype_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Sitetype_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Sitetype_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  site_type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Sitetype_Sum_Fields = {
  __typename?: 'core_sitetype_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "core_sitetype" */
export enum Core_Sitetype_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SiteType = 'site_type'
}

export type Core_Sitetype_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Sitetype_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Sitetype_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Sitetype_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Sitetype_Var_Pop_Fields = {
  __typename?: 'core_sitetype_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Core_Sitetype_Var_Samp_Fields = {
  __typename?: 'core_sitetype_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Core_Sitetype_Variance_Fields = {
  __typename?: 'core_sitetype_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "core_sundrycharge" */
export type Core_Sundrycharge = {
  __typename?: 'core_sundrycharge';
  charge: Scalars['float8'];
  charge_name: Scalars['String'];
  /** An object relationship */
  core_tariff: Core_Tariff;
  id: Scalars['bigint'];
  sundry_charge_type: Scalars['String'];
  tariff_id: Scalars['bigint'];
};

/** aggregated selection of "core_sundrycharge" */
export type Core_Sundrycharge_Aggregate = {
  __typename?: 'core_sundrycharge_aggregate';
  aggregate?: Maybe<Core_Sundrycharge_Aggregate_Fields>;
  nodes: Array<Core_Sundrycharge>;
};

export type Core_Sundrycharge_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Core_Sundrycharge_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Core_Sundrycharge_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Core_Sundrycharge_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Core_Sundrycharge_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Core_Sundrycharge_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Core_Sundrycharge_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Core_Sundrycharge_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Core_Sundrycharge_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Core_Sundrycharge_Aggregate_Bool_Exp_Var_Samp>;
};

export type Core_Sundrycharge_Aggregate_Bool_Exp_Avg = {
  arguments: Core_Sundrycharge_Select_Column_Core_Sundrycharge_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Sundrycharge_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Sundrycharge_Aggregate_Bool_Exp_Corr = {
  arguments: Core_Sundrycharge_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Sundrycharge_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Sundrycharge_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Core_Sundrycharge_Select_Column_Core_Sundrycharge_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Core_Sundrycharge_Select_Column_Core_Sundrycharge_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Core_Sundrycharge_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Sundrycharge_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Sundrycharge_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Core_Sundrycharge_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Core_Sundrycharge_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Sundrycharge_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Sundrycharge_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Core_Sundrycharge_Select_Column_Core_Sundrycharge_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Core_Sundrycharge_Select_Column_Core_Sundrycharge_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Core_Sundrycharge_Aggregate_Bool_Exp_Max = {
  arguments: Core_Sundrycharge_Select_Column_Core_Sundrycharge_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Sundrycharge_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Sundrycharge_Aggregate_Bool_Exp_Min = {
  arguments: Core_Sundrycharge_Select_Column_Core_Sundrycharge_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Sundrycharge_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Sundrycharge_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Core_Sundrycharge_Select_Column_Core_Sundrycharge_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Sundrycharge_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Sundrycharge_Aggregate_Bool_Exp_Sum = {
  arguments: Core_Sundrycharge_Select_Column_Core_Sundrycharge_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Sundrycharge_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Core_Sundrycharge_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Core_Sundrycharge_Select_Column_Core_Sundrycharge_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Sundrycharge_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "core_sundrycharge" */
export type Core_Sundrycharge_Aggregate_Fields = {
  __typename?: 'core_sundrycharge_aggregate_fields';
  avg?: Maybe<Core_Sundrycharge_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Sundrycharge_Max_Fields>;
  min?: Maybe<Core_Sundrycharge_Min_Fields>;
  stddev?: Maybe<Core_Sundrycharge_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Sundrycharge_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Sundrycharge_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Sundrycharge_Sum_Fields>;
  var_pop?: Maybe<Core_Sundrycharge_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Sundrycharge_Var_Samp_Fields>;
  variance?: Maybe<Core_Sundrycharge_Variance_Fields>;
};


/** aggregate fields of "core_sundrycharge" */
export type Core_Sundrycharge_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Sundrycharge_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_sundrycharge" */
export type Core_Sundrycharge_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Sundrycharge_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Sundrycharge_Max_Order_By>;
  min?: InputMaybe<Core_Sundrycharge_Min_Order_By>;
  stddev?: InputMaybe<Core_Sundrycharge_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Sundrycharge_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Sundrycharge_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Sundrycharge_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Sundrycharge_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Sundrycharge_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Sundrycharge_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_sundrycharge" */
export type Core_Sundrycharge_Arr_Rel_Insert_Input = {
  data: Array<Core_Sundrycharge_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Sundrycharge_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Sundrycharge_Avg_Fields = {
  __typename?: 'core_sundrycharge_avg_fields';
  charge?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_sundrycharge" */
export type Core_Sundrycharge_Avg_Order_By = {
  charge?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_sundrycharge". All fields are combined with a logical 'AND'. */
export type Core_Sundrycharge_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Sundrycharge_Bool_Exp>>;
  _not?: InputMaybe<Core_Sundrycharge_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Sundrycharge_Bool_Exp>>;
  charge?: InputMaybe<Float8_Comparison_Exp>;
  charge_name?: InputMaybe<String_Comparison_Exp>;
  core_tariff?: InputMaybe<Core_Tariff_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  sundry_charge_type?: InputMaybe<String_Comparison_Exp>;
  tariff_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_sundrycharge" */
export enum Core_Sundrycharge_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreSundrychargePkey = 'core_sundrycharge_pkey'
}

/** input type for incrementing numeric columns in table "core_sundrycharge" */
export type Core_Sundrycharge_Inc_Input = {
  charge?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['bigint']>;
  tariff_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_sundrycharge" */
export type Core_Sundrycharge_Insert_Input = {
  charge?: InputMaybe<Scalars['float8']>;
  charge_name?: InputMaybe<Scalars['String']>;
  core_tariff?: InputMaybe<Core_Tariff_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  sundry_charge_type?: InputMaybe<Scalars['String']>;
  tariff_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_Sundrycharge_Max_Fields = {
  __typename?: 'core_sundrycharge_max_fields';
  charge?: Maybe<Scalars['float8']>;
  charge_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  sundry_charge_type?: Maybe<Scalars['String']>;
  tariff_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "core_sundrycharge" */
export type Core_Sundrycharge_Max_Order_By = {
  charge?: InputMaybe<Order_By>;
  charge_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sundry_charge_type?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Sundrycharge_Min_Fields = {
  __typename?: 'core_sundrycharge_min_fields';
  charge?: Maybe<Scalars['float8']>;
  charge_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  sundry_charge_type?: Maybe<Scalars['String']>;
  tariff_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "core_sundrycharge" */
export type Core_Sundrycharge_Min_Order_By = {
  charge?: InputMaybe<Order_By>;
  charge_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sundry_charge_type?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_sundrycharge" */
export type Core_Sundrycharge_Mutation_Response = {
  __typename?: 'core_sundrycharge_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Sundrycharge>;
};

/** on_conflict condition type for table "core_sundrycharge" */
export type Core_Sundrycharge_On_Conflict = {
  constraint: Core_Sundrycharge_Constraint;
  update_columns?: Array<Core_Sundrycharge_Update_Column>;
  where?: InputMaybe<Core_Sundrycharge_Bool_Exp>;
};

/** Ordering options when selecting data from "core_sundrycharge". */
export type Core_Sundrycharge_Order_By = {
  charge?: InputMaybe<Order_By>;
  charge_name?: InputMaybe<Order_By>;
  core_tariff?: InputMaybe<Core_Tariff_Order_By>;
  id?: InputMaybe<Order_By>;
  sundry_charge_type?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_sundrycharge */
export type Core_Sundrycharge_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_sundrycharge" */
export enum Core_Sundrycharge_Select_Column {
  /** column name */
  Charge = 'charge',
  /** column name */
  ChargeName = 'charge_name',
  /** column name */
  Id = 'id',
  /** column name */
  SundryChargeType = 'sundry_charge_type',
  /** column name */
  TariffId = 'tariff_id'
}

/** select "core_sundrycharge_aggregate_bool_exp_avg_arguments_columns" columns of table "core_sundrycharge" */
export enum Core_Sundrycharge_Select_Column_Core_Sundrycharge_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Charge = 'charge'
}

/** select "core_sundrycharge_aggregate_bool_exp_corr_arguments_columns" columns of table "core_sundrycharge" */
export enum Core_Sundrycharge_Select_Column_Core_Sundrycharge_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Charge = 'charge'
}

/** select "core_sundrycharge_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "core_sundrycharge" */
export enum Core_Sundrycharge_Select_Column_Core_Sundrycharge_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Charge = 'charge'
}

/** select "core_sundrycharge_aggregate_bool_exp_max_arguments_columns" columns of table "core_sundrycharge" */
export enum Core_Sundrycharge_Select_Column_Core_Sundrycharge_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Charge = 'charge'
}

/** select "core_sundrycharge_aggregate_bool_exp_min_arguments_columns" columns of table "core_sundrycharge" */
export enum Core_Sundrycharge_Select_Column_Core_Sundrycharge_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Charge = 'charge'
}

/** select "core_sundrycharge_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "core_sundrycharge" */
export enum Core_Sundrycharge_Select_Column_Core_Sundrycharge_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Charge = 'charge'
}

/** select "core_sundrycharge_aggregate_bool_exp_sum_arguments_columns" columns of table "core_sundrycharge" */
export enum Core_Sundrycharge_Select_Column_Core_Sundrycharge_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Charge = 'charge'
}

/** select "core_sundrycharge_aggregate_bool_exp_var_samp_arguments_columns" columns of table "core_sundrycharge" */
export enum Core_Sundrycharge_Select_Column_Core_Sundrycharge_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Charge = 'charge'
}

/** input type for updating data in table "core_sundrycharge" */
export type Core_Sundrycharge_Set_Input = {
  charge?: InputMaybe<Scalars['float8']>;
  charge_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  sundry_charge_type?: InputMaybe<Scalars['String']>;
  tariff_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_Sundrycharge_Stddev_Fields = {
  __typename?: 'core_sundrycharge_stddev_fields';
  charge?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_sundrycharge" */
export type Core_Sundrycharge_Stddev_Order_By = {
  charge?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Sundrycharge_Stddev_Pop_Fields = {
  __typename?: 'core_sundrycharge_stddev_pop_fields';
  charge?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_sundrycharge" */
export type Core_Sundrycharge_Stddev_Pop_Order_By = {
  charge?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Sundrycharge_Stddev_Samp_Fields = {
  __typename?: 'core_sundrycharge_stddev_samp_fields';
  charge?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_sundrycharge" */
export type Core_Sundrycharge_Stddev_Samp_Order_By = {
  charge?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_sundrycharge" */
export type Core_Sundrycharge_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Sundrycharge_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Sundrycharge_Stream_Cursor_Value_Input = {
  charge?: InputMaybe<Scalars['float8']>;
  charge_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  sundry_charge_type?: InputMaybe<Scalars['String']>;
  tariff_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_Sundrycharge_Sum_Fields = {
  __typename?: 'core_sundrycharge_sum_fields';
  charge?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['bigint']>;
  tariff_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_sundrycharge" */
export type Core_Sundrycharge_Sum_Order_By = {
  charge?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_sundrycharge" */
export enum Core_Sundrycharge_Update_Column {
  /** column name */
  Charge = 'charge',
  /** column name */
  ChargeName = 'charge_name',
  /** column name */
  Id = 'id',
  /** column name */
  SundryChargeType = 'sundry_charge_type',
  /** column name */
  TariffId = 'tariff_id'
}

export type Core_Sundrycharge_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Sundrycharge_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Sundrycharge_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Sundrycharge_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Sundrycharge_Var_Pop_Fields = {
  __typename?: 'core_sundrycharge_var_pop_fields';
  charge?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_sundrycharge" */
export type Core_Sundrycharge_Var_Pop_Order_By = {
  charge?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Sundrycharge_Var_Samp_Fields = {
  __typename?: 'core_sundrycharge_var_samp_fields';
  charge?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_sundrycharge" */
export type Core_Sundrycharge_Var_Samp_Order_By = {
  charge?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Sundrycharge_Variance_Fields = {
  __typename?: 'core_sundrycharge_variance_fields';
  charge?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tariff_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_sundrycharge" */
export type Core_Sundrycharge_Variance_Order_By = {
  charge?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tariff_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_sundrychargetype" */
export type Core_Sundrychargetype = {
  __typename?: 'core_sundrychargetype';
  id: Scalars['bigint'];
  sundry_charge_type: Scalars['String'];
};

/** aggregated selection of "core_sundrychargetype" */
export type Core_Sundrychargetype_Aggregate = {
  __typename?: 'core_sundrychargetype_aggregate';
  aggregate?: Maybe<Core_Sundrychargetype_Aggregate_Fields>;
  nodes: Array<Core_Sundrychargetype>;
};

/** aggregate fields of "core_sundrychargetype" */
export type Core_Sundrychargetype_Aggregate_Fields = {
  __typename?: 'core_sundrychargetype_aggregate_fields';
  avg?: Maybe<Core_Sundrychargetype_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Sundrychargetype_Max_Fields>;
  min?: Maybe<Core_Sundrychargetype_Min_Fields>;
  stddev?: Maybe<Core_Sundrychargetype_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Sundrychargetype_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Sundrychargetype_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Sundrychargetype_Sum_Fields>;
  var_pop?: Maybe<Core_Sundrychargetype_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Sundrychargetype_Var_Samp_Fields>;
  variance?: Maybe<Core_Sundrychargetype_Variance_Fields>;
};


/** aggregate fields of "core_sundrychargetype" */
export type Core_Sundrychargetype_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Sundrychargetype_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Core_Sundrychargetype_Avg_Fields = {
  __typename?: 'core_sundrychargetype_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "core_sundrychargetype". All fields are combined with a logical 'AND'. */
export type Core_Sundrychargetype_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Sundrychargetype_Bool_Exp>>;
  _not?: InputMaybe<Core_Sundrychargetype_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Sundrychargetype_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  sundry_charge_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_sundrychargetype" */
export enum Core_Sundrychargetype_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreSundrychargetypePkey = 'core_sundrychargetype_pkey'
}

/** input type for incrementing numeric columns in table "core_sundrychargetype" */
export type Core_Sundrychargetype_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_sundrychargetype" */
export type Core_Sundrychargetype_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  sundry_charge_type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Sundrychargetype_Max_Fields = {
  __typename?: 'core_sundrychargetype_max_fields';
  id?: Maybe<Scalars['bigint']>;
  sundry_charge_type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Core_Sundrychargetype_Min_Fields = {
  __typename?: 'core_sundrychargetype_min_fields';
  id?: Maybe<Scalars['bigint']>;
  sundry_charge_type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "core_sundrychargetype" */
export type Core_Sundrychargetype_Mutation_Response = {
  __typename?: 'core_sundrychargetype_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Sundrychargetype>;
};

/** on_conflict condition type for table "core_sundrychargetype" */
export type Core_Sundrychargetype_On_Conflict = {
  constraint: Core_Sundrychargetype_Constraint;
  update_columns?: Array<Core_Sundrychargetype_Update_Column>;
  where?: InputMaybe<Core_Sundrychargetype_Bool_Exp>;
};

/** Ordering options when selecting data from "core_sundrychargetype". */
export type Core_Sundrychargetype_Order_By = {
  id?: InputMaybe<Order_By>;
  sundry_charge_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_sundrychargetype */
export type Core_Sundrychargetype_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_sundrychargetype" */
export enum Core_Sundrychargetype_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SundryChargeType = 'sundry_charge_type'
}

/** input type for updating data in table "core_sundrychargetype" */
export type Core_Sundrychargetype_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  sundry_charge_type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Sundrychargetype_Stddev_Fields = {
  __typename?: 'core_sundrychargetype_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Core_Sundrychargetype_Stddev_Pop_Fields = {
  __typename?: 'core_sundrychargetype_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Core_Sundrychargetype_Stddev_Samp_Fields = {
  __typename?: 'core_sundrychargetype_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "core_sundrychargetype" */
export type Core_Sundrychargetype_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Sundrychargetype_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Sundrychargetype_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  sundry_charge_type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Sundrychargetype_Sum_Fields = {
  __typename?: 'core_sundrychargetype_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "core_sundrychargetype" */
export enum Core_Sundrychargetype_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SundryChargeType = 'sundry_charge_type'
}

export type Core_Sundrychargetype_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Sundrychargetype_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Sundrychargetype_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Sundrychargetype_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Sundrychargetype_Var_Pop_Fields = {
  __typename?: 'core_sundrychargetype_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Core_Sundrychargetype_Var_Samp_Fields = {
  __typename?: 'core_sundrychargetype_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Core_Sundrychargetype_Variance_Fields = {
  __typename?: 'core_sundrychargetype_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "core_tariff" */
export type Core_Tariff = {
  __typename?: 'core_tariff';
  /** An array relationship */
  core_agreements: Array<Core_Agreement>;
  /** An aggregate relationship */
  core_agreements_aggregate: Core_Agreement_Aggregate;
  /** An object relationship */
  core_customertype: Core_Customertype;
  /** An array relationship */
  core_energycharges: Array<Core_Energycharge>;
  /** An aggregate relationship */
  core_energycharges_aggregate: Core_Energycharge_Aggregate;
  /** An array relationship */
  core_sitechargeestimates: Array<Core_Sitechargeestimate>;
  /** An aggregate relationship */
  core_sitechargeestimates_aggregate: Core_Sitechargeestimate_Aggregate;
  /** An array relationship */
  core_sundrycharges: Array<Core_Sundrycharge>;
  /** An aggregate relationship */
  core_sundrycharges_aggregate: Core_Sundrycharge_Aggregate;
  customer_type_id: Scalars['bigint'];
  id: Scalars['bigint'];
  payment_type: Scalars['String'];
  tariff_name: Scalars['String'];
};


/** columns and relationships of "core_tariff" */
export type Core_TariffCore_AgreementsArgs = {
  distinct_on?: InputMaybe<Array<Core_Agreement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Agreement_Order_By>>;
  where?: InputMaybe<Core_Agreement_Bool_Exp>;
};


/** columns and relationships of "core_tariff" */
export type Core_TariffCore_Agreements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Agreement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Agreement_Order_By>>;
  where?: InputMaybe<Core_Agreement_Bool_Exp>;
};


/** columns and relationships of "core_tariff" */
export type Core_TariffCore_EnergychargesArgs = {
  distinct_on?: InputMaybe<Array<Core_Energycharge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Energycharge_Order_By>>;
  where?: InputMaybe<Core_Energycharge_Bool_Exp>;
};


/** columns and relationships of "core_tariff" */
export type Core_TariffCore_Energycharges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Energycharge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Energycharge_Order_By>>;
  where?: InputMaybe<Core_Energycharge_Bool_Exp>;
};


/** columns and relationships of "core_tariff" */
export type Core_TariffCore_SitechargeestimatesArgs = {
  distinct_on?: InputMaybe<Array<Core_Sitechargeestimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitechargeestimate_Order_By>>;
  where?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
};


/** columns and relationships of "core_tariff" */
export type Core_TariffCore_Sitechargeestimates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Sitechargeestimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitechargeestimate_Order_By>>;
  where?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
};


/** columns and relationships of "core_tariff" */
export type Core_TariffCore_SundrychargesArgs = {
  distinct_on?: InputMaybe<Array<Core_Sundrycharge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sundrycharge_Order_By>>;
  where?: InputMaybe<Core_Sundrycharge_Bool_Exp>;
};


/** columns and relationships of "core_tariff" */
export type Core_TariffCore_Sundrycharges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Sundrycharge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sundrycharge_Order_By>>;
  where?: InputMaybe<Core_Sundrycharge_Bool_Exp>;
};

/** aggregated selection of "core_tariff" */
export type Core_Tariff_Aggregate = {
  __typename?: 'core_tariff_aggregate';
  aggregate?: Maybe<Core_Tariff_Aggregate_Fields>;
  nodes: Array<Core_Tariff>;
};

export type Core_Tariff_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Tariff_Aggregate_Bool_Exp_Count>;
};

export type Core_Tariff_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Tariff_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Tariff_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_tariff" */
export type Core_Tariff_Aggregate_Fields = {
  __typename?: 'core_tariff_aggregate_fields';
  avg?: Maybe<Core_Tariff_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Tariff_Max_Fields>;
  min?: Maybe<Core_Tariff_Min_Fields>;
  stddev?: Maybe<Core_Tariff_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Tariff_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Tariff_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Tariff_Sum_Fields>;
  var_pop?: Maybe<Core_Tariff_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Tariff_Var_Samp_Fields>;
  variance?: Maybe<Core_Tariff_Variance_Fields>;
};


/** aggregate fields of "core_tariff" */
export type Core_Tariff_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Tariff_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_tariff" */
export type Core_Tariff_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Tariff_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Tariff_Max_Order_By>;
  min?: InputMaybe<Core_Tariff_Min_Order_By>;
  stddev?: InputMaybe<Core_Tariff_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Tariff_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Tariff_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Tariff_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Tariff_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Tariff_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Tariff_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_tariff" */
export type Core_Tariff_Arr_Rel_Insert_Input = {
  data: Array<Core_Tariff_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Tariff_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Tariff_Avg_Fields = {
  __typename?: 'core_tariff_avg_fields';
  customer_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_tariff" */
export type Core_Tariff_Avg_Order_By = {
  customer_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_tariff". All fields are combined with a logical 'AND'. */
export type Core_Tariff_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Tariff_Bool_Exp>>;
  _not?: InputMaybe<Core_Tariff_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Tariff_Bool_Exp>>;
  core_agreements?: InputMaybe<Core_Agreement_Bool_Exp>;
  core_agreements_aggregate?: InputMaybe<Core_Agreement_Aggregate_Bool_Exp>;
  core_customertype?: InputMaybe<Core_Customertype_Bool_Exp>;
  core_energycharges?: InputMaybe<Core_Energycharge_Bool_Exp>;
  core_energycharges_aggregate?: InputMaybe<Core_Energycharge_Aggregate_Bool_Exp>;
  core_sitechargeestimates?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
  core_sitechargeestimates_aggregate?: InputMaybe<Core_Sitechargeestimate_Aggregate_Bool_Exp>;
  core_sundrycharges?: InputMaybe<Core_Sundrycharge_Bool_Exp>;
  core_sundrycharges_aggregate?: InputMaybe<Core_Sundrycharge_Aggregate_Bool_Exp>;
  customer_type_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  payment_type?: InputMaybe<String_Comparison_Exp>;
  tariff_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_tariff" */
export enum Core_Tariff_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreTariffsPkey = 'core_tariffs_pkey'
}

/** input type for incrementing numeric columns in table "core_tariff" */
export type Core_Tariff_Inc_Input = {
  customer_type_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_tariff" */
export type Core_Tariff_Insert_Input = {
  core_agreements?: InputMaybe<Core_Agreement_Arr_Rel_Insert_Input>;
  core_customertype?: InputMaybe<Core_Customertype_Obj_Rel_Insert_Input>;
  core_energycharges?: InputMaybe<Core_Energycharge_Arr_Rel_Insert_Input>;
  core_sitechargeestimates?: InputMaybe<Core_Sitechargeestimate_Arr_Rel_Insert_Input>;
  core_sundrycharges?: InputMaybe<Core_Sundrycharge_Arr_Rel_Insert_Input>;
  customer_type_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  payment_type?: InputMaybe<Scalars['String']>;
  tariff_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Tariff_Max_Fields = {
  __typename?: 'core_tariff_max_fields';
  customer_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  payment_type?: Maybe<Scalars['String']>;
  tariff_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "core_tariff" */
export type Core_Tariff_Max_Order_By = {
  customer_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_type?: InputMaybe<Order_By>;
  tariff_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Tariff_Min_Fields = {
  __typename?: 'core_tariff_min_fields';
  customer_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  payment_type?: Maybe<Scalars['String']>;
  tariff_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "core_tariff" */
export type Core_Tariff_Min_Order_By = {
  customer_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_type?: InputMaybe<Order_By>;
  tariff_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_tariff" */
export type Core_Tariff_Mutation_Response = {
  __typename?: 'core_tariff_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Tariff>;
};

/** input type for inserting object relation for remote table "core_tariff" */
export type Core_Tariff_Obj_Rel_Insert_Input = {
  data: Core_Tariff_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Tariff_On_Conflict>;
};

/** on_conflict condition type for table "core_tariff" */
export type Core_Tariff_On_Conflict = {
  constraint: Core_Tariff_Constraint;
  update_columns?: Array<Core_Tariff_Update_Column>;
  where?: InputMaybe<Core_Tariff_Bool_Exp>;
};

/** Ordering options when selecting data from "core_tariff". */
export type Core_Tariff_Order_By = {
  core_agreements_aggregate?: InputMaybe<Core_Agreement_Aggregate_Order_By>;
  core_customertype?: InputMaybe<Core_Customertype_Order_By>;
  core_energycharges_aggregate?: InputMaybe<Core_Energycharge_Aggregate_Order_By>;
  core_sitechargeestimates_aggregate?: InputMaybe<Core_Sitechargeestimate_Aggregate_Order_By>;
  core_sundrycharges_aggregate?: InputMaybe<Core_Sundrycharge_Aggregate_Order_By>;
  customer_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_type?: InputMaybe<Order_By>;
  tariff_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_tariff */
export type Core_Tariff_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_tariff" */
export enum Core_Tariff_Select_Column {
  /** column name */
  CustomerTypeId = 'customer_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentType = 'payment_type',
  /** column name */
  TariffName = 'tariff_name'
}

/** input type for updating data in table "core_tariff" */
export type Core_Tariff_Set_Input = {
  customer_type_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  payment_type?: InputMaybe<Scalars['String']>;
  tariff_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Tariff_Stddev_Fields = {
  __typename?: 'core_tariff_stddev_fields';
  customer_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_tariff" */
export type Core_Tariff_Stddev_Order_By = {
  customer_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Tariff_Stddev_Pop_Fields = {
  __typename?: 'core_tariff_stddev_pop_fields';
  customer_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_tariff" */
export type Core_Tariff_Stddev_Pop_Order_By = {
  customer_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Tariff_Stddev_Samp_Fields = {
  __typename?: 'core_tariff_stddev_samp_fields';
  customer_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_tariff" */
export type Core_Tariff_Stddev_Samp_Order_By = {
  customer_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_tariff" */
export type Core_Tariff_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Tariff_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Tariff_Stream_Cursor_Value_Input = {
  customer_type_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  payment_type?: InputMaybe<Scalars['String']>;
  tariff_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Tariff_Sum_Fields = {
  __typename?: 'core_tariff_sum_fields';
  customer_type_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_tariff" */
export type Core_Tariff_Sum_Order_By = {
  customer_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "core_tariff" */
export enum Core_Tariff_Update_Column {
  /** column name */
  CustomerTypeId = 'customer_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentType = 'payment_type',
  /** column name */
  TariffName = 'tariff_name'
}

export type Core_Tariff_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Tariff_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Tariff_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Tariff_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Tariff_Var_Pop_Fields = {
  __typename?: 'core_tariff_var_pop_fields';
  customer_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_tariff" */
export type Core_Tariff_Var_Pop_Order_By = {
  customer_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Tariff_Var_Samp_Fields = {
  __typename?: 'core_tariff_var_samp_fields';
  customer_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_tariff" */
export type Core_Tariff_Var_Samp_Order_By = {
  customer_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Tariff_Variance_Fields = {
  __typename?: 'core_tariff_variance_fields';
  customer_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_tariff" */
export type Core_Tariff_Variance_Order_By = {
  customer_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_user" */
export type Core_User = {
  __typename?: 'core_user';
  /** An object relationship */
  authtoken_token?: Maybe<Authtoken_Token>;
  company_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  core_company?: Maybe<Core_Company>;
  /** An array relationship */
  core_disruptions: Array<Core_Disruptions>;
  /** An aggregate relationship */
  core_disruptions_aggregate: Core_Disruptions_Aggregate;
  /** An array relationship */
  core_logfacialrecognitions: Array<Core_Logfacialrecognition>;
  /** An aggregate relationship */
  core_logfacialrecognitions_aggregate: Core_Logfacialrecognition_Aggregate;
  /** An array relationship */
  core_personaleimages: Array<Core_Personaleimage>;
  /** An aggregate relationship */
  core_personaleimages_aggregate: Core_Personaleimage_Aggregate;
  /** An array relationship */
  core_personnelassigneds: Array<Core_Personnelassigned>;
  /** An aggregate relationship */
  core_personnelassigneds_aggregate: Core_Personnelassigned_Aggregate;
  /** An array relationship */
  core_requestaccesses: Array<Core_Requestaccess>;
  /** An aggregate relationship */
  core_requestaccesses_aggregate: Core_Requestaccess_Aggregate;
  /** An object relationship */
  core_role: Core_Role;
  /** An array relationship */
  core_user_groups: Array<Core_User_Groups>;
  /** An aggregate relationship */
  core_user_groups_aggregate: Core_User_Groups_Aggregate;
  /** An array relationship */
  core_user_user_permissions: Array<Core_User_User_Permissions>;
  /** An aggregate relationship */
  core_user_user_permissions_aggregate: Core_User_User_Permissions_Aggregate;
  /** An array relationship */
  django_admin_logs: Array<Django_Admin_Log>;
  /** An aggregate relationship */
  django_admin_logs_aggregate: Django_Admin_Log_Aggregate;
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['bigint'];
  is_active: Scalars['Boolean'];
  is_staff: Scalars['Boolean'];
  is_superuser: Scalars['Boolean'];
  last_login?: Maybe<Scalars['timestamptz']>;
  last_name: Scalars['String'];
  password: Scalars['String'];
  role_id: Scalars['bigint'];
  uuid?: Maybe<Scalars['String']>;
};


/** columns and relationships of "core_user" */
export type Core_UserCore_DisruptionsArgs = {
  distinct_on?: InputMaybe<Array<Core_Disruptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Disruptions_Order_By>>;
  where?: InputMaybe<Core_Disruptions_Bool_Exp>;
};


/** columns and relationships of "core_user" */
export type Core_UserCore_Disruptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Disruptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Disruptions_Order_By>>;
  where?: InputMaybe<Core_Disruptions_Bool_Exp>;
};


/** columns and relationships of "core_user" */
export type Core_UserCore_LogfacialrecognitionsArgs = {
  distinct_on?: InputMaybe<Array<Core_Logfacialrecognition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logfacialrecognition_Order_By>>;
  where?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
};


/** columns and relationships of "core_user" */
export type Core_UserCore_Logfacialrecognitions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Logfacialrecognition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logfacialrecognition_Order_By>>;
  where?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
};


/** columns and relationships of "core_user" */
export type Core_UserCore_PersonaleimagesArgs = {
  distinct_on?: InputMaybe<Array<Core_Personaleimage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Personaleimage_Order_By>>;
  where?: InputMaybe<Core_Personaleimage_Bool_Exp>;
};


/** columns and relationships of "core_user" */
export type Core_UserCore_Personaleimages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Personaleimage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Personaleimage_Order_By>>;
  where?: InputMaybe<Core_Personaleimage_Bool_Exp>;
};


/** columns and relationships of "core_user" */
export type Core_UserCore_PersonnelassignedsArgs = {
  distinct_on?: InputMaybe<Array<Core_Personnelassigned_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Personnelassigned_Order_By>>;
  where?: InputMaybe<Core_Personnelassigned_Bool_Exp>;
};


/** columns and relationships of "core_user" */
export type Core_UserCore_Personnelassigneds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Personnelassigned_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Personnelassigned_Order_By>>;
  where?: InputMaybe<Core_Personnelassigned_Bool_Exp>;
};


/** columns and relationships of "core_user" */
export type Core_UserCore_RequestaccessesArgs = {
  distinct_on?: InputMaybe<Array<Core_Requestaccess_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Requestaccess_Order_By>>;
  where?: InputMaybe<Core_Requestaccess_Bool_Exp>;
};


/** columns and relationships of "core_user" */
export type Core_UserCore_Requestaccesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Requestaccess_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Requestaccess_Order_By>>;
  where?: InputMaybe<Core_Requestaccess_Bool_Exp>;
};


/** columns and relationships of "core_user" */
export type Core_UserCore_User_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Core_User_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_Groups_Order_By>>;
  where?: InputMaybe<Core_User_Groups_Bool_Exp>;
};


/** columns and relationships of "core_user" */
export type Core_UserCore_User_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_User_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_Groups_Order_By>>;
  where?: InputMaybe<Core_User_Groups_Bool_Exp>;
};


/** columns and relationships of "core_user" */
export type Core_UserCore_User_User_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Core_User_User_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_User_Permissions_Order_By>>;
  where?: InputMaybe<Core_User_User_Permissions_Bool_Exp>;
};


/** columns and relationships of "core_user" */
export type Core_UserCore_User_User_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_User_User_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_User_Permissions_Order_By>>;
  where?: InputMaybe<Core_User_User_Permissions_Bool_Exp>;
};


/** columns and relationships of "core_user" */
export type Core_UserDjango_Admin_LogsArgs = {
  distinct_on?: InputMaybe<Array<Django_Admin_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Django_Admin_Log_Order_By>>;
  where?: InputMaybe<Django_Admin_Log_Bool_Exp>;
};


/** columns and relationships of "core_user" */
export type Core_UserDjango_Admin_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Django_Admin_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Django_Admin_Log_Order_By>>;
  where?: InputMaybe<Django_Admin_Log_Bool_Exp>;
};

/** aggregated selection of "core_user" */
export type Core_User_Aggregate = {
  __typename?: 'core_user_aggregate';
  aggregate?: Maybe<Core_User_Aggregate_Fields>;
  nodes: Array<Core_User>;
};

export type Core_User_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Core_User_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Core_User_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Core_User_Aggregate_Bool_Exp_Count>;
};

export type Core_User_Aggregate_Bool_Exp_Bool_And = {
  arguments: Core_User_Select_Column_Core_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_User_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Core_User_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Core_User_Select_Column_Core_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_User_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Core_User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_user" */
export type Core_User_Aggregate_Fields = {
  __typename?: 'core_user_aggregate_fields';
  avg?: Maybe<Core_User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_User_Max_Fields>;
  min?: Maybe<Core_User_Min_Fields>;
  stddev?: Maybe<Core_User_Stddev_Fields>;
  stddev_pop?: Maybe<Core_User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_User_Stddev_Samp_Fields>;
  sum?: Maybe<Core_User_Sum_Fields>;
  var_pop?: Maybe<Core_User_Var_Pop_Fields>;
  var_samp?: Maybe<Core_User_Var_Samp_Fields>;
  variance?: Maybe<Core_User_Variance_Fields>;
};


/** aggregate fields of "core_user" */
export type Core_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_user" */
export type Core_User_Aggregate_Order_By = {
  avg?: InputMaybe<Core_User_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_User_Max_Order_By>;
  min?: InputMaybe<Core_User_Min_Order_By>;
  stddev?: InputMaybe<Core_User_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_User_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_User_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_User_Sum_Order_By>;
  var_pop?: InputMaybe<Core_User_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_User_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_User_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_user" */
export type Core_User_Arr_Rel_Insert_Input = {
  data: Array<Core_User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_User_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_User_Avg_Fields = {
  __typename?: 'core_user_avg_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_user" */
export type Core_User_Avg_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_user". All fields are combined with a logical 'AND'. */
export type Core_User_Bool_Exp = {
  _and?: InputMaybe<Array<Core_User_Bool_Exp>>;
  _not?: InputMaybe<Core_User_Bool_Exp>;
  _or?: InputMaybe<Array<Core_User_Bool_Exp>>;
  authtoken_token?: InputMaybe<Authtoken_Token_Bool_Exp>;
  company_id?: InputMaybe<Bigint_Comparison_Exp>;
  core_company?: InputMaybe<Core_Company_Bool_Exp>;
  core_disruptions?: InputMaybe<Core_Disruptions_Bool_Exp>;
  core_disruptions_aggregate?: InputMaybe<Core_Disruptions_Aggregate_Bool_Exp>;
  core_logfacialrecognitions?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
  core_logfacialrecognitions_aggregate?: InputMaybe<Core_Logfacialrecognition_Aggregate_Bool_Exp>;
  core_personaleimages?: InputMaybe<Core_Personaleimage_Bool_Exp>;
  core_personaleimages_aggregate?: InputMaybe<Core_Personaleimage_Aggregate_Bool_Exp>;
  core_personnelassigneds?: InputMaybe<Core_Personnelassigned_Bool_Exp>;
  core_personnelassigneds_aggregate?: InputMaybe<Core_Personnelassigned_Aggregate_Bool_Exp>;
  core_requestaccesses?: InputMaybe<Core_Requestaccess_Bool_Exp>;
  core_requestaccesses_aggregate?: InputMaybe<Core_Requestaccess_Aggregate_Bool_Exp>;
  core_role?: InputMaybe<Core_Role_Bool_Exp>;
  core_user_groups?: InputMaybe<Core_User_Groups_Bool_Exp>;
  core_user_groups_aggregate?: InputMaybe<Core_User_Groups_Aggregate_Bool_Exp>;
  core_user_user_permissions?: InputMaybe<Core_User_User_Permissions_Bool_Exp>;
  core_user_user_permissions_aggregate?: InputMaybe<Core_User_User_Permissions_Aggregate_Bool_Exp>;
  django_admin_logs?: InputMaybe<Django_Admin_Log_Bool_Exp>;
  django_admin_logs_aggregate?: InputMaybe<Django_Admin_Log_Aggregate_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_staff?: InputMaybe<Boolean_Comparison_Exp>;
  is_superuser?: InputMaybe<Boolean_Comparison_Exp>;
  last_login?: InputMaybe<Timestamptz_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  role_id?: InputMaybe<Bigint_Comparison_Exp>;
  uuid?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_user" */
export enum Core_User_Constraint {
  /** unique or primary key constraint on columns "email" */
  CoreUserEmailKey = 'core_user_email_key',
  /** unique or primary key constraint on columns "id" */
  CoreUserPkey = 'core_user_pkey',
  /** unique or primary key constraint on columns "uuid" */
  CoreUserUuidKey = 'core_user_uuid_key'
}

/** columns and relationships of "core_user_groups" */
export type Core_User_Groups = {
  __typename?: 'core_user_groups';
  /** An object relationship */
  auth_group: Auth_Group;
  /** An object relationship */
  core_user: Core_User;
  group_id: Scalars['Int'];
  id: Scalars['bigint'];
  user_id: Scalars['bigint'];
};

/** aggregated selection of "core_user_groups" */
export type Core_User_Groups_Aggregate = {
  __typename?: 'core_user_groups_aggregate';
  aggregate?: Maybe<Core_User_Groups_Aggregate_Fields>;
  nodes: Array<Core_User_Groups>;
};

export type Core_User_Groups_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_User_Groups_Aggregate_Bool_Exp_Count>;
};

export type Core_User_Groups_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_User_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_User_Groups_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_user_groups" */
export type Core_User_Groups_Aggregate_Fields = {
  __typename?: 'core_user_groups_aggregate_fields';
  avg?: Maybe<Core_User_Groups_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_User_Groups_Max_Fields>;
  min?: Maybe<Core_User_Groups_Min_Fields>;
  stddev?: Maybe<Core_User_Groups_Stddev_Fields>;
  stddev_pop?: Maybe<Core_User_Groups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_User_Groups_Stddev_Samp_Fields>;
  sum?: Maybe<Core_User_Groups_Sum_Fields>;
  var_pop?: Maybe<Core_User_Groups_Var_Pop_Fields>;
  var_samp?: Maybe<Core_User_Groups_Var_Samp_Fields>;
  variance?: Maybe<Core_User_Groups_Variance_Fields>;
};


/** aggregate fields of "core_user_groups" */
export type Core_User_Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_User_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_user_groups" */
export type Core_User_Groups_Aggregate_Order_By = {
  avg?: InputMaybe<Core_User_Groups_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_User_Groups_Max_Order_By>;
  min?: InputMaybe<Core_User_Groups_Min_Order_By>;
  stddev?: InputMaybe<Core_User_Groups_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_User_Groups_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_User_Groups_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_User_Groups_Sum_Order_By>;
  var_pop?: InputMaybe<Core_User_Groups_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_User_Groups_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_User_Groups_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_user_groups" */
export type Core_User_Groups_Arr_Rel_Insert_Input = {
  data: Array<Core_User_Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_User_Groups_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_User_Groups_Avg_Fields = {
  __typename?: 'core_user_groups_avg_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_user_groups" */
export type Core_User_Groups_Avg_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_user_groups". All fields are combined with a logical 'AND'. */
export type Core_User_Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Core_User_Groups_Bool_Exp>>;
  _not?: InputMaybe<Core_User_Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Core_User_Groups_Bool_Exp>>;
  auth_group?: InputMaybe<Auth_Group_Bool_Exp>;
  core_user?: InputMaybe<Core_User_Bool_Exp>;
  group_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_user_groups" */
export enum Core_User_Groups_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreUserGroupsPkey = 'core_user_groups_pkey',
  /** unique or primary key constraint on columns "group_id", "user_id" */
  CoreUserGroupsUserIdGroupIdC82fcad1Uniq = 'core_user_groups_user_id_group_id_c82fcad1_uniq'
}

/** input type for incrementing numeric columns in table "core_user_groups" */
export type Core_User_Groups_Inc_Input = {
  group_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_user_groups" */
export type Core_User_Groups_Insert_Input = {
  auth_group?: InputMaybe<Auth_Group_Obj_Rel_Insert_Input>;
  core_user?: InputMaybe<Core_User_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_User_Groups_Max_Fields = {
  __typename?: 'core_user_groups_max_fields';
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "core_user_groups" */
export type Core_User_Groups_Max_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_User_Groups_Min_Fields = {
  __typename?: 'core_user_groups_min_fields';
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "core_user_groups" */
export type Core_User_Groups_Min_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_user_groups" */
export type Core_User_Groups_Mutation_Response = {
  __typename?: 'core_user_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_User_Groups>;
};

/** on_conflict condition type for table "core_user_groups" */
export type Core_User_Groups_On_Conflict = {
  constraint: Core_User_Groups_Constraint;
  update_columns?: Array<Core_User_Groups_Update_Column>;
  where?: InputMaybe<Core_User_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "core_user_groups". */
export type Core_User_Groups_Order_By = {
  auth_group?: InputMaybe<Auth_Group_Order_By>;
  core_user?: InputMaybe<Core_User_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_user_groups */
export type Core_User_Groups_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_user_groups" */
export enum Core_User_Groups_Select_Column {
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "core_user_groups" */
export type Core_User_Groups_Set_Input = {
  group_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_User_Groups_Stddev_Fields = {
  __typename?: 'core_user_groups_stddev_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_user_groups" */
export type Core_User_Groups_Stddev_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_User_Groups_Stddev_Pop_Fields = {
  __typename?: 'core_user_groups_stddev_pop_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_user_groups" */
export type Core_User_Groups_Stddev_Pop_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_User_Groups_Stddev_Samp_Fields = {
  __typename?: 'core_user_groups_stddev_samp_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_user_groups" */
export type Core_User_Groups_Stddev_Samp_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_user_groups" */
export type Core_User_Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_User_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_User_Groups_Stream_Cursor_Value_Input = {
  group_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_User_Groups_Sum_Fields = {
  __typename?: 'core_user_groups_sum_fields';
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_user_groups" */
export type Core_User_Groups_Sum_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_user_groups" */
export enum Core_User_Groups_Update_Column {
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export type Core_User_Groups_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_User_Groups_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_User_Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_User_Groups_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_User_Groups_Var_Pop_Fields = {
  __typename?: 'core_user_groups_var_pop_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_user_groups" */
export type Core_User_Groups_Var_Pop_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_User_Groups_Var_Samp_Fields = {
  __typename?: 'core_user_groups_var_samp_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_user_groups" */
export type Core_User_Groups_Var_Samp_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_User_Groups_Variance_Fields = {
  __typename?: 'core_user_groups_variance_fields';
  group_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_user_groups" */
export type Core_User_Groups_Variance_Order_By = {
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "core_user" */
export type Core_User_Inc_Input = {
  company_id?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['bigint']>;
  role_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_user" */
export type Core_User_Insert_Input = {
  authtoken_token?: InputMaybe<Authtoken_Token_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['bigint']>;
  core_company?: InputMaybe<Core_Company_Obj_Rel_Insert_Input>;
  core_disruptions?: InputMaybe<Core_Disruptions_Arr_Rel_Insert_Input>;
  core_logfacialrecognitions?: InputMaybe<Core_Logfacialrecognition_Arr_Rel_Insert_Input>;
  core_personaleimages?: InputMaybe<Core_Personaleimage_Arr_Rel_Insert_Input>;
  core_personnelassigneds?: InputMaybe<Core_Personnelassigned_Arr_Rel_Insert_Input>;
  core_requestaccesses?: InputMaybe<Core_Requestaccess_Arr_Rel_Insert_Input>;
  core_role?: InputMaybe<Core_Role_Obj_Rel_Insert_Input>;
  core_user_groups?: InputMaybe<Core_User_Groups_Arr_Rel_Insert_Input>;
  core_user_user_permissions?: InputMaybe<Core_User_User_Permissions_Arr_Rel_Insert_Input>;
  django_admin_logs?: InputMaybe<Django_Admin_Log_Arr_Rel_Insert_Input>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_staff?: InputMaybe<Scalars['Boolean']>;
  is_superuser?: InputMaybe<Scalars['Boolean']>;
  last_login?: InputMaybe<Scalars['timestamptz']>;
  last_name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['bigint']>;
  uuid?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_User_Max_Fields = {
  __typename?: 'core_user_max_fields';
  company_id?: Maybe<Scalars['bigint']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_login?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['bigint']>;
  uuid?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "core_user" */
export type Core_User_Max_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_login?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_User_Min_Fields = {
  __typename?: 'core_user_min_fields';
  company_id?: Maybe<Scalars['bigint']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  last_login?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['bigint']>;
  uuid?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "core_user" */
export type Core_User_Min_Order_By = {
  company_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_login?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_user" */
export type Core_User_Mutation_Response = {
  __typename?: 'core_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_User>;
};

/** input type for inserting object relation for remote table "core_user" */
export type Core_User_Obj_Rel_Insert_Input = {
  data: Core_User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_User_On_Conflict>;
};

/** on_conflict condition type for table "core_user" */
export type Core_User_On_Conflict = {
  constraint: Core_User_Constraint;
  update_columns?: Array<Core_User_Update_Column>;
  where?: InputMaybe<Core_User_Bool_Exp>;
};

/** Ordering options when selecting data from "core_user". */
export type Core_User_Order_By = {
  authtoken_token?: InputMaybe<Authtoken_Token_Order_By>;
  company_id?: InputMaybe<Order_By>;
  core_company?: InputMaybe<Core_Company_Order_By>;
  core_disruptions_aggregate?: InputMaybe<Core_Disruptions_Aggregate_Order_By>;
  core_logfacialrecognitions_aggregate?: InputMaybe<Core_Logfacialrecognition_Aggregate_Order_By>;
  core_personaleimages_aggregate?: InputMaybe<Core_Personaleimage_Aggregate_Order_By>;
  core_personnelassigneds_aggregate?: InputMaybe<Core_Personnelassigned_Aggregate_Order_By>;
  core_requestaccesses_aggregate?: InputMaybe<Core_Requestaccess_Aggregate_Order_By>;
  core_role?: InputMaybe<Core_Role_Order_By>;
  core_user_groups_aggregate?: InputMaybe<Core_User_Groups_Aggregate_Order_By>;
  core_user_user_permissions_aggregate?: InputMaybe<Core_User_User_Permissions_Aggregate_Order_By>;
  django_admin_logs_aggregate?: InputMaybe<Django_Admin_Log_Aggregate_Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_staff?: InputMaybe<Order_By>;
  is_superuser?: InputMaybe<Order_By>;
  last_login?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_user */
export type Core_User_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_user" */
export enum Core_User_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsStaff = 'is_staff',
  /** column name */
  IsSuperuser = 'is_superuser',
  /** column name */
  LastLogin = 'last_login',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Password = 'password',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  Uuid = 'uuid'
}

/** select "core_user_aggregate_bool_exp_bool_and_arguments_columns" columns of table "core_user" */
export enum Core_User_Select_Column_Core_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsStaff = 'is_staff',
  /** column name */
  IsSuperuser = 'is_superuser'
}

/** select "core_user_aggregate_bool_exp_bool_or_arguments_columns" columns of table "core_user" */
export enum Core_User_Select_Column_Core_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsStaff = 'is_staff',
  /** column name */
  IsSuperuser = 'is_superuser'
}

/** input type for updating data in table "core_user" */
export type Core_User_Set_Input = {
  company_id?: InputMaybe<Scalars['bigint']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_staff?: InputMaybe<Scalars['Boolean']>;
  is_superuser?: InputMaybe<Scalars['Boolean']>;
  last_login?: InputMaybe<Scalars['timestamptz']>;
  last_name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['bigint']>;
  uuid?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_User_Stddev_Fields = {
  __typename?: 'core_user_stddev_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_user" */
export type Core_User_Stddev_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_User_Stddev_Pop_Fields = {
  __typename?: 'core_user_stddev_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_user" */
export type Core_User_Stddev_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_User_Stddev_Samp_Fields = {
  __typename?: 'core_user_stddev_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_user" */
export type Core_User_Stddev_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_user" */
export type Core_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_User_Stream_Cursor_Value_Input = {
  company_id?: InputMaybe<Scalars['bigint']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_staff?: InputMaybe<Scalars['Boolean']>;
  is_superuser?: InputMaybe<Scalars['Boolean']>;
  last_login?: InputMaybe<Scalars['timestamptz']>;
  last_name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['bigint']>;
  uuid?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_User_Sum_Fields = {
  __typename?: 'core_user_sum_fields';
  company_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  role_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_user" */
export type Core_User_Sum_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_user" */
export enum Core_User_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsStaff = 'is_staff',
  /** column name */
  IsSuperuser = 'is_superuser',
  /** column name */
  LastLogin = 'last_login',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Password = 'password',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  Uuid = 'uuid'
}

export type Core_User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_User_Bool_Exp;
};

/** columns and relationships of "core_user_user_permissions" */
export type Core_User_User_Permissions = {
  __typename?: 'core_user_user_permissions';
  /** An object relationship */
  auth_permission: Auth_Permission;
  /** An object relationship */
  core_user: Core_User;
  id: Scalars['bigint'];
  permission_id: Scalars['Int'];
  user_id: Scalars['bigint'];
};

/** aggregated selection of "core_user_user_permissions" */
export type Core_User_User_Permissions_Aggregate = {
  __typename?: 'core_user_user_permissions_aggregate';
  aggregate?: Maybe<Core_User_User_Permissions_Aggregate_Fields>;
  nodes: Array<Core_User_User_Permissions>;
};

export type Core_User_User_Permissions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_User_User_Permissions_Aggregate_Bool_Exp_Count>;
};

export type Core_User_User_Permissions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_User_User_Permissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_User_User_Permissions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_user_user_permissions" */
export type Core_User_User_Permissions_Aggregate_Fields = {
  __typename?: 'core_user_user_permissions_aggregate_fields';
  avg?: Maybe<Core_User_User_Permissions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_User_User_Permissions_Max_Fields>;
  min?: Maybe<Core_User_User_Permissions_Min_Fields>;
  stddev?: Maybe<Core_User_User_Permissions_Stddev_Fields>;
  stddev_pop?: Maybe<Core_User_User_Permissions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_User_User_Permissions_Stddev_Samp_Fields>;
  sum?: Maybe<Core_User_User_Permissions_Sum_Fields>;
  var_pop?: Maybe<Core_User_User_Permissions_Var_Pop_Fields>;
  var_samp?: Maybe<Core_User_User_Permissions_Var_Samp_Fields>;
  variance?: Maybe<Core_User_User_Permissions_Variance_Fields>;
};


/** aggregate fields of "core_user_user_permissions" */
export type Core_User_User_Permissions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_User_User_Permissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_user_user_permissions" */
export type Core_User_User_Permissions_Aggregate_Order_By = {
  avg?: InputMaybe<Core_User_User_Permissions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_User_User_Permissions_Max_Order_By>;
  min?: InputMaybe<Core_User_User_Permissions_Min_Order_By>;
  stddev?: InputMaybe<Core_User_User_Permissions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_User_User_Permissions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_User_User_Permissions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_User_User_Permissions_Sum_Order_By>;
  var_pop?: InputMaybe<Core_User_User_Permissions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_User_User_Permissions_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_User_User_Permissions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_user_user_permissions" */
export type Core_User_User_Permissions_Arr_Rel_Insert_Input = {
  data: Array<Core_User_User_Permissions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_User_User_Permissions_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_User_User_Permissions_Avg_Fields = {
  __typename?: 'core_user_user_permissions_avg_fields';
  id?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_user_user_permissions" */
export type Core_User_User_Permissions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_user_user_permissions". All fields are combined with a logical 'AND'. */
export type Core_User_User_Permissions_Bool_Exp = {
  _and?: InputMaybe<Array<Core_User_User_Permissions_Bool_Exp>>;
  _not?: InputMaybe<Core_User_User_Permissions_Bool_Exp>;
  _or?: InputMaybe<Array<Core_User_User_Permissions_Bool_Exp>>;
  auth_permission?: InputMaybe<Auth_Permission_Bool_Exp>;
  core_user?: InputMaybe<Core_User_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  permission_id?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_user_user_permissions" */
export enum Core_User_User_Permissions_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreUserUserPermissionsPkey = 'core_user_user_permissions_pkey',
  /** unique or primary key constraint on columns "user_id", "permission_id" */
  CoreUserUserPermissionsUserIdPermissionId_73ea0daaUniq = 'core_user_user_permissions_user_id_permission_id_73ea0daa_uniq'
}

/** input type for incrementing numeric columns in table "core_user_user_permissions" */
export type Core_User_User_Permissions_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  permission_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_user_user_permissions" */
export type Core_User_User_Permissions_Insert_Input = {
  auth_permission?: InputMaybe<Auth_Permission_Obj_Rel_Insert_Input>;
  core_user?: InputMaybe<Core_User_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  permission_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Core_User_User_Permissions_Max_Fields = {
  __typename?: 'core_user_user_permissions_max_fields';
  id?: Maybe<Scalars['bigint']>;
  permission_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "core_user_user_permissions" */
export type Core_User_User_Permissions_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_User_User_Permissions_Min_Fields = {
  __typename?: 'core_user_user_permissions_min_fields';
  id?: Maybe<Scalars['bigint']>;
  permission_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "core_user_user_permissions" */
export type Core_User_User_Permissions_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_user_user_permissions" */
export type Core_User_User_Permissions_Mutation_Response = {
  __typename?: 'core_user_user_permissions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_User_User_Permissions>;
};

/** on_conflict condition type for table "core_user_user_permissions" */
export type Core_User_User_Permissions_On_Conflict = {
  constraint: Core_User_User_Permissions_Constraint;
  update_columns?: Array<Core_User_User_Permissions_Update_Column>;
  where?: InputMaybe<Core_User_User_Permissions_Bool_Exp>;
};

/** Ordering options when selecting data from "core_user_user_permissions". */
export type Core_User_User_Permissions_Order_By = {
  auth_permission?: InputMaybe<Auth_Permission_Order_By>;
  core_user?: InputMaybe<Core_User_Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_user_user_permissions */
export type Core_User_User_Permissions_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_user_user_permissions" */
export enum Core_User_User_Permissions_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "core_user_user_permissions" */
export type Core_User_User_Permissions_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  permission_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Core_User_User_Permissions_Stddev_Fields = {
  __typename?: 'core_user_user_permissions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_user_user_permissions" */
export type Core_User_User_Permissions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_User_User_Permissions_Stddev_Pop_Fields = {
  __typename?: 'core_user_user_permissions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_user_user_permissions" */
export type Core_User_User_Permissions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_User_User_Permissions_Stddev_Samp_Fields = {
  __typename?: 'core_user_user_permissions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_user_user_permissions" */
export type Core_User_User_Permissions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_user_user_permissions" */
export type Core_User_User_Permissions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_User_User_Permissions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_User_User_Permissions_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  permission_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Core_User_User_Permissions_Sum_Fields = {
  __typename?: 'core_user_user_permissions_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  permission_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_user_user_permissions" */
export type Core_User_User_Permissions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_user_user_permissions" */
export enum Core_User_User_Permissions_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  UserId = 'user_id'
}

export type Core_User_User_Permissions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_User_User_Permissions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_User_User_Permissions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_User_User_Permissions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_User_User_Permissions_Var_Pop_Fields = {
  __typename?: 'core_user_user_permissions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_user_user_permissions" */
export type Core_User_User_Permissions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_User_User_Permissions_Var_Samp_Fields = {
  __typename?: 'core_user_user_permissions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_user_user_permissions" */
export type Core_User_User_Permissions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_User_User_Permissions_Variance_Fields = {
  __typename?: 'core_user_user_permissions_variance_fields';
  id?: Maybe<Scalars['Float']>;
  permission_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_user_user_permissions" */
export type Core_User_User_Permissions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Core_User_Var_Pop_Fields = {
  __typename?: 'core_user_var_pop_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_user" */
export type Core_User_Var_Pop_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_User_Var_Samp_Fields = {
  __typename?: 'core_user_var_samp_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_user" */
export type Core_User_Var_Samp_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_User_Variance_Fields = {
  __typename?: 'core_user_variance_fields';
  company_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  role_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_user" */
export type Core_User_Variance_Order_By = {
  company_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_visit" */
export type Core_Visit = {
  __typename?: 'core_visit';
  comment?: Maybe<Scalars['String']>;
  /** An object relationship */
  core_job: Core_Job;
  /** An array relationship */
  core_requestaccesses: Array<Core_Requestaccess>;
  /** An aggregate relationship */
  core_requestaccesses_aggregate: Core_Requestaccess_Aggregate;
  /** An object relationship */
  core_visitstatusenum?: Maybe<Core_Visitstatusenum>;
  end_datetime: Scalars['timestamptz'];
  id: Scalars['bigint'];
  job_id: Scalars['bigint'];
  start_datetime: Scalars['timestamptz'];
  status_id?: Maybe<Scalars['bigint']>;
  visit_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "core_visit" */
export type Core_VisitCore_RequestaccessesArgs = {
  distinct_on?: InputMaybe<Array<Core_Requestaccess_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Requestaccess_Order_By>>;
  where?: InputMaybe<Core_Requestaccess_Bool_Exp>;
};


/** columns and relationships of "core_visit" */
export type Core_VisitCore_Requestaccesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Requestaccess_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Requestaccess_Order_By>>;
  where?: InputMaybe<Core_Requestaccess_Bool_Exp>;
};

/** aggregated selection of "core_visit" */
export type Core_Visit_Aggregate = {
  __typename?: 'core_visit_aggregate';
  aggregate?: Maybe<Core_Visit_Aggregate_Fields>;
  nodes: Array<Core_Visit>;
};

export type Core_Visit_Aggregate_Bool_Exp = {
  count?: InputMaybe<Core_Visit_Aggregate_Bool_Exp_Count>;
};

export type Core_Visit_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Core_Visit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Core_Visit_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "core_visit" */
export type Core_Visit_Aggregate_Fields = {
  __typename?: 'core_visit_aggregate_fields';
  avg?: Maybe<Core_Visit_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Visit_Max_Fields>;
  min?: Maybe<Core_Visit_Min_Fields>;
  stddev?: Maybe<Core_Visit_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Visit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Visit_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Visit_Sum_Fields>;
  var_pop?: Maybe<Core_Visit_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Visit_Var_Samp_Fields>;
  variance?: Maybe<Core_Visit_Variance_Fields>;
};


/** aggregate fields of "core_visit" */
export type Core_Visit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Visit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "core_visit" */
export type Core_Visit_Aggregate_Order_By = {
  avg?: InputMaybe<Core_Visit_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Core_Visit_Max_Order_By>;
  min?: InputMaybe<Core_Visit_Min_Order_By>;
  stddev?: InputMaybe<Core_Visit_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Core_Visit_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Core_Visit_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Core_Visit_Sum_Order_By>;
  var_pop?: InputMaybe<Core_Visit_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Core_Visit_Var_Samp_Order_By>;
  variance?: InputMaybe<Core_Visit_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "core_visit" */
export type Core_Visit_Arr_Rel_Insert_Input = {
  data: Array<Core_Visit_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Visit_On_Conflict>;
};

/** aggregate avg on columns */
export type Core_Visit_Avg_Fields = {
  __typename?: 'core_visit_avg_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "core_visit" */
export type Core_Visit_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "core_visit". All fields are combined with a logical 'AND'. */
export type Core_Visit_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Visit_Bool_Exp>>;
  _not?: InputMaybe<Core_Visit_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Visit_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  core_job?: InputMaybe<Core_Job_Bool_Exp>;
  core_requestaccesses?: InputMaybe<Core_Requestaccess_Bool_Exp>;
  core_requestaccesses_aggregate?: InputMaybe<Core_Requestaccess_Aggregate_Bool_Exp>;
  core_visitstatusenum?: InputMaybe<Core_Visitstatusenum_Bool_Exp>;
  end_datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  job_id?: InputMaybe<Bigint_Comparison_Exp>;
  start_datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
  status_id?: InputMaybe<Bigint_Comparison_Exp>;
  visit_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_visit" */
export enum Core_Visit_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreVisitPkey = 'core_visit_pkey',
  /** unique or primary key constraint on columns "visit_id" */
  CoreVisitVisitIdKey = 'core_visit_visit_id_key'
}

/** input type for incrementing numeric columns in table "core_visit" */
export type Core_Visit_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  job_id?: InputMaybe<Scalars['bigint']>;
  status_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_visit" */
export type Core_Visit_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  core_job?: InputMaybe<Core_Job_Obj_Rel_Insert_Input>;
  core_requestaccesses?: InputMaybe<Core_Requestaccess_Arr_Rel_Insert_Input>;
  core_visitstatusenum?: InputMaybe<Core_Visitstatusenum_Obj_Rel_Insert_Input>;
  end_datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  job_id?: InputMaybe<Scalars['bigint']>;
  start_datetime?: InputMaybe<Scalars['timestamptz']>;
  status_id?: InputMaybe<Scalars['bigint']>;
  visit_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Visit_Max_Fields = {
  __typename?: 'core_visit_max_fields';
  comment?: Maybe<Scalars['String']>;
  end_datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  job_id?: Maybe<Scalars['bigint']>;
  start_datetime?: Maybe<Scalars['timestamptz']>;
  status_id?: Maybe<Scalars['bigint']>;
  visit_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "core_visit" */
export type Core_Visit_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  end_datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  start_datetime?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Core_Visit_Min_Fields = {
  __typename?: 'core_visit_min_fields';
  comment?: Maybe<Scalars['String']>;
  end_datetime?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  job_id?: Maybe<Scalars['bigint']>;
  start_datetime?: Maybe<Scalars['timestamptz']>;
  status_id?: Maybe<Scalars['bigint']>;
  visit_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "core_visit" */
export type Core_Visit_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  end_datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  start_datetime?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "core_visit" */
export type Core_Visit_Mutation_Response = {
  __typename?: 'core_visit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Visit>;
};

/** input type for inserting object relation for remote table "core_visit" */
export type Core_Visit_Obj_Rel_Insert_Input = {
  data: Core_Visit_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Visit_On_Conflict>;
};

/** on_conflict condition type for table "core_visit" */
export type Core_Visit_On_Conflict = {
  constraint: Core_Visit_Constraint;
  update_columns?: Array<Core_Visit_Update_Column>;
  where?: InputMaybe<Core_Visit_Bool_Exp>;
};

/** Ordering options when selecting data from "core_visit". */
export type Core_Visit_Order_By = {
  comment?: InputMaybe<Order_By>;
  core_job?: InputMaybe<Core_Job_Order_By>;
  core_requestaccesses_aggregate?: InputMaybe<Core_Requestaccess_Aggregate_Order_By>;
  core_visitstatusenum?: InputMaybe<Core_Visitstatusenum_Order_By>;
  end_datetime?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  start_datetime?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
  visit_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_visit */
export type Core_Visit_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_visit" */
export enum Core_Visit_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  EndDatetime = 'end_datetime',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  StartDatetime = 'start_datetime',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  VisitId = 'visit_id'
}

/** input type for updating data in table "core_visit" */
export type Core_Visit_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  end_datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  job_id?: InputMaybe<Scalars['bigint']>;
  start_datetime?: InputMaybe<Scalars['timestamptz']>;
  status_id?: InputMaybe<Scalars['bigint']>;
  visit_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Visit_Stddev_Fields = {
  __typename?: 'core_visit_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "core_visit" */
export type Core_Visit_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Core_Visit_Stddev_Pop_Fields = {
  __typename?: 'core_visit_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "core_visit" */
export type Core_Visit_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Core_Visit_Stddev_Samp_Fields = {
  __typename?: 'core_visit_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "core_visit" */
export type Core_Visit_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "core_visit" */
export type Core_Visit_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Visit_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Visit_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  end_datetime?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  job_id?: InputMaybe<Scalars['bigint']>;
  start_datetime?: InputMaybe<Scalars['timestamptz']>;
  status_id?: InputMaybe<Scalars['bigint']>;
  visit_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Visit_Sum_Fields = {
  __typename?: 'core_visit_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  job_id?: Maybe<Scalars['bigint']>;
  status_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "core_visit" */
export type Core_Visit_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** update columns of table "core_visit" */
export enum Core_Visit_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  EndDatetime = 'end_datetime',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  StartDatetime = 'start_datetime',
  /** column name */
  StatusId = 'status_id',
  /** column name */
  VisitId = 'visit_id'
}

export type Core_Visit_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Visit_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Visit_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Visit_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Visit_Var_Pop_Fields = {
  __typename?: 'core_visit_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "core_visit" */
export type Core_Visit_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Core_Visit_Var_Samp_Fields = {
  __typename?: 'core_visit_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "core_visit" */
export type Core_Visit_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Core_Visit_Variance_Fields = {
  __typename?: 'core_visit_variance_fields';
  id?: Maybe<Scalars['Float']>;
  job_id?: Maybe<Scalars['Float']>;
  status_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "core_visit" */
export type Core_Visit_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  status_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "core_visitstatusenum" */
export type Core_Visitstatusenum = {
  __typename?: 'core_visitstatusenum';
  /** An array relationship */
  core_visits: Array<Core_Visit>;
  /** An aggregate relationship */
  core_visits_aggregate: Core_Visit_Aggregate;
  id: Scalars['bigint'];
  status: Scalars['String'];
};


/** columns and relationships of "core_visitstatusenum" */
export type Core_VisitstatusenumCore_VisitsArgs = {
  distinct_on?: InputMaybe<Array<Core_Visit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Visit_Order_By>>;
  where?: InputMaybe<Core_Visit_Bool_Exp>;
};


/** columns and relationships of "core_visitstatusenum" */
export type Core_VisitstatusenumCore_Visits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Visit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Visit_Order_By>>;
  where?: InputMaybe<Core_Visit_Bool_Exp>;
};

/** aggregated selection of "core_visitstatusenum" */
export type Core_Visitstatusenum_Aggregate = {
  __typename?: 'core_visitstatusenum_aggregate';
  aggregate?: Maybe<Core_Visitstatusenum_Aggregate_Fields>;
  nodes: Array<Core_Visitstatusenum>;
};

/** aggregate fields of "core_visitstatusenum" */
export type Core_Visitstatusenum_Aggregate_Fields = {
  __typename?: 'core_visitstatusenum_aggregate_fields';
  avg?: Maybe<Core_Visitstatusenum_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Core_Visitstatusenum_Max_Fields>;
  min?: Maybe<Core_Visitstatusenum_Min_Fields>;
  stddev?: Maybe<Core_Visitstatusenum_Stddev_Fields>;
  stddev_pop?: Maybe<Core_Visitstatusenum_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Core_Visitstatusenum_Stddev_Samp_Fields>;
  sum?: Maybe<Core_Visitstatusenum_Sum_Fields>;
  var_pop?: Maybe<Core_Visitstatusenum_Var_Pop_Fields>;
  var_samp?: Maybe<Core_Visitstatusenum_Var_Samp_Fields>;
  variance?: Maybe<Core_Visitstatusenum_Variance_Fields>;
};


/** aggregate fields of "core_visitstatusenum" */
export type Core_Visitstatusenum_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Core_Visitstatusenum_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Core_Visitstatusenum_Avg_Fields = {
  __typename?: 'core_visitstatusenum_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "core_visitstatusenum". All fields are combined with a logical 'AND'. */
export type Core_Visitstatusenum_Bool_Exp = {
  _and?: InputMaybe<Array<Core_Visitstatusenum_Bool_Exp>>;
  _not?: InputMaybe<Core_Visitstatusenum_Bool_Exp>;
  _or?: InputMaybe<Array<Core_Visitstatusenum_Bool_Exp>>;
  core_visits?: InputMaybe<Core_Visit_Bool_Exp>;
  core_visits_aggregate?: InputMaybe<Core_Visit_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_visitstatusenum" */
export enum Core_Visitstatusenum_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoreVisitstatusenumPkey = 'core_visitstatusenum_pkey'
}

/** input type for incrementing numeric columns in table "core_visitstatusenum" */
export type Core_Visitstatusenum_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "core_visitstatusenum" */
export type Core_Visitstatusenum_Insert_Input = {
  core_visits?: InputMaybe<Core_Visit_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Core_Visitstatusenum_Max_Fields = {
  __typename?: 'core_visitstatusenum_max_fields';
  id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Core_Visitstatusenum_Min_Fields = {
  __typename?: 'core_visitstatusenum_min_fields';
  id?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "core_visitstatusenum" */
export type Core_Visitstatusenum_Mutation_Response = {
  __typename?: 'core_visitstatusenum_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_Visitstatusenum>;
};

/** input type for inserting object relation for remote table "core_visitstatusenum" */
export type Core_Visitstatusenum_Obj_Rel_Insert_Input = {
  data: Core_Visitstatusenum_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Core_Visitstatusenum_On_Conflict>;
};

/** on_conflict condition type for table "core_visitstatusenum" */
export type Core_Visitstatusenum_On_Conflict = {
  constraint: Core_Visitstatusenum_Constraint;
  update_columns?: Array<Core_Visitstatusenum_Update_Column>;
  where?: InputMaybe<Core_Visitstatusenum_Bool_Exp>;
};

/** Ordering options when selecting data from "core_visitstatusenum". */
export type Core_Visitstatusenum_Order_By = {
  core_visits_aggregate?: InputMaybe<Core_Visit_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: core_visitstatusenum */
export type Core_Visitstatusenum_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "core_visitstatusenum" */
export enum Core_Visitstatusenum_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "core_visitstatusenum" */
export type Core_Visitstatusenum_Set_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Core_Visitstatusenum_Stddev_Fields = {
  __typename?: 'core_visitstatusenum_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Core_Visitstatusenum_Stddev_Pop_Fields = {
  __typename?: 'core_visitstatusenum_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Core_Visitstatusenum_Stddev_Samp_Fields = {
  __typename?: 'core_visitstatusenum_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "core_visitstatusenum" */
export type Core_Visitstatusenum_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_Visitstatusenum_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_Visitstatusenum_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Core_Visitstatusenum_Sum_Fields = {
  __typename?: 'core_visitstatusenum_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "core_visitstatusenum" */
export enum Core_Visitstatusenum_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status'
}

export type Core_Visitstatusenum_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Core_Visitstatusenum_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Core_Visitstatusenum_Set_Input>;
  /** filter the rows which have to be updated */
  where: Core_Visitstatusenum_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_Visitstatusenum_Var_Pop_Fields = {
  __typename?: 'core_visitstatusenum_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Core_Visitstatusenum_Var_Samp_Fields = {
  __typename?: 'core_visitstatusenum_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Core_Visitstatusenum_Variance_Fields = {
  __typename?: 'core_visitstatusenum_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "django_admin_log" */
export type Django_Admin_Log = {
  __typename?: 'django_admin_log';
  action_flag: Scalars['smallint'];
  action_time: Scalars['timestamptz'];
  change_message: Scalars['String'];
  content_type_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  core_user: Core_User;
  /** An object relationship */
  django_content_type?: Maybe<Django_Content_Type>;
  id: Scalars['Int'];
  object_id?: Maybe<Scalars['String']>;
  object_repr: Scalars['String'];
  user_id: Scalars['bigint'];
};

/** aggregated selection of "django_admin_log" */
export type Django_Admin_Log_Aggregate = {
  __typename?: 'django_admin_log_aggregate';
  aggregate?: Maybe<Django_Admin_Log_Aggregate_Fields>;
  nodes: Array<Django_Admin_Log>;
};

export type Django_Admin_Log_Aggregate_Bool_Exp = {
  count?: InputMaybe<Django_Admin_Log_Aggregate_Bool_Exp_Count>;
};

export type Django_Admin_Log_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Django_Admin_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Django_Admin_Log_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "django_admin_log" */
export type Django_Admin_Log_Aggregate_Fields = {
  __typename?: 'django_admin_log_aggregate_fields';
  avg?: Maybe<Django_Admin_Log_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Django_Admin_Log_Max_Fields>;
  min?: Maybe<Django_Admin_Log_Min_Fields>;
  stddev?: Maybe<Django_Admin_Log_Stddev_Fields>;
  stddev_pop?: Maybe<Django_Admin_Log_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Django_Admin_Log_Stddev_Samp_Fields>;
  sum?: Maybe<Django_Admin_Log_Sum_Fields>;
  var_pop?: Maybe<Django_Admin_Log_Var_Pop_Fields>;
  var_samp?: Maybe<Django_Admin_Log_Var_Samp_Fields>;
  variance?: Maybe<Django_Admin_Log_Variance_Fields>;
};


/** aggregate fields of "django_admin_log" */
export type Django_Admin_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Django_Admin_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "django_admin_log" */
export type Django_Admin_Log_Aggregate_Order_By = {
  avg?: InputMaybe<Django_Admin_Log_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Django_Admin_Log_Max_Order_By>;
  min?: InputMaybe<Django_Admin_Log_Min_Order_By>;
  stddev?: InputMaybe<Django_Admin_Log_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Django_Admin_Log_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Django_Admin_Log_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Django_Admin_Log_Sum_Order_By>;
  var_pop?: InputMaybe<Django_Admin_Log_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Django_Admin_Log_Var_Samp_Order_By>;
  variance?: InputMaybe<Django_Admin_Log_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "django_admin_log" */
export type Django_Admin_Log_Arr_Rel_Insert_Input = {
  data: Array<Django_Admin_Log_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Django_Admin_Log_On_Conflict>;
};

/** aggregate avg on columns */
export type Django_Admin_Log_Avg_Fields = {
  __typename?: 'django_admin_log_avg_fields';
  action_flag?: Maybe<Scalars['Float']>;
  content_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "django_admin_log" */
export type Django_Admin_Log_Avg_Order_By = {
  action_flag?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "django_admin_log". All fields are combined with a logical 'AND'. */
export type Django_Admin_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Django_Admin_Log_Bool_Exp>>;
  _not?: InputMaybe<Django_Admin_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Django_Admin_Log_Bool_Exp>>;
  action_flag?: InputMaybe<Smallint_Comparison_Exp>;
  action_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  change_message?: InputMaybe<String_Comparison_Exp>;
  content_type_id?: InputMaybe<Int_Comparison_Exp>;
  core_user?: InputMaybe<Core_User_Bool_Exp>;
  django_content_type?: InputMaybe<Django_Content_Type_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  object_id?: InputMaybe<String_Comparison_Exp>;
  object_repr?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "django_admin_log" */
export enum Django_Admin_Log_Constraint {
  /** unique or primary key constraint on columns "id" */
  DjangoAdminLogPkey = 'django_admin_log_pkey'
}

/** input type for incrementing numeric columns in table "django_admin_log" */
export type Django_Admin_Log_Inc_Input = {
  action_flag?: InputMaybe<Scalars['smallint']>;
  content_type_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "django_admin_log" */
export type Django_Admin_Log_Insert_Input = {
  action_flag?: InputMaybe<Scalars['smallint']>;
  action_time?: InputMaybe<Scalars['timestamptz']>;
  change_message?: InputMaybe<Scalars['String']>;
  content_type_id?: InputMaybe<Scalars['Int']>;
  core_user?: InputMaybe<Core_User_Obj_Rel_Insert_Input>;
  django_content_type?: InputMaybe<Django_Content_Type_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  object_id?: InputMaybe<Scalars['String']>;
  object_repr?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Django_Admin_Log_Max_Fields = {
  __typename?: 'django_admin_log_max_fields';
  action_flag?: Maybe<Scalars['smallint']>;
  action_time?: Maybe<Scalars['timestamptz']>;
  change_message?: Maybe<Scalars['String']>;
  content_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  object_id?: Maybe<Scalars['String']>;
  object_repr?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "django_admin_log" */
export type Django_Admin_Log_Max_Order_By = {
  action_flag?: InputMaybe<Order_By>;
  action_time?: InputMaybe<Order_By>;
  change_message?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  object_id?: InputMaybe<Order_By>;
  object_repr?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Django_Admin_Log_Min_Fields = {
  __typename?: 'django_admin_log_min_fields';
  action_flag?: Maybe<Scalars['smallint']>;
  action_time?: Maybe<Scalars['timestamptz']>;
  change_message?: Maybe<Scalars['String']>;
  content_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  object_id?: Maybe<Scalars['String']>;
  object_repr?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "django_admin_log" */
export type Django_Admin_Log_Min_Order_By = {
  action_flag?: InputMaybe<Order_By>;
  action_time?: InputMaybe<Order_By>;
  change_message?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  object_id?: InputMaybe<Order_By>;
  object_repr?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "django_admin_log" */
export type Django_Admin_Log_Mutation_Response = {
  __typename?: 'django_admin_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Django_Admin_Log>;
};

/** on_conflict condition type for table "django_admin_log" */
export type Django_Admin_Log_On_Conflict = {
  constraint: Django_Admin_Log_Constraint;
  update_columns?: Array<Django_Admin_Log_Update_Column>;
  where?: InputMaybe<Django_Admin_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "django_admin_log". */
export type Django_Admin_Log_Order_By = {
  action_flag?: InputMaybe<Order_By>;
  action_time?: InputMaybe<Order_By>;
  change_message?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  core_user?: InputMaybe<Core_User_Order_By>;
  django_content_type?: InputMaybe<Django_Content_Type_Order_By>;
  id?: InputMaybe<Order_By>;
  object_id?: InputMaybe<Order_By>;
  object_repr?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: django_admin_log */
export type Django_Admin_Log_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "django_admin_log" */
export enum Django_Admin_Log_Select_Column {
  /** column name */
  ActionFlag = 'action_flag',
  /** column name */
  ActionTime = 'action_time',
  /** column name */
  ChangeMessage = 'change_message',
  /** column name */
  ContentTypeId = 'content_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectId = 'object_id',
  /** column name */
  ObjectRepr = 'object_repr',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "django_admin_log" */
export type Django_Admin_Log_Set_Input = {
  action_flag?: InputMaybe<Scalars['smallint']>;
  action_time?: InputMaybe<Scalars['timestamptz']>;
  change_message?: InputMaybe<Scalars['String']>;
  content_type_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  object_id?: InputMaybe<Scalars['String']>;
  object_repr?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Django_Admin_Log_Stddev_Fields = {
  __typename?: 'django_admin_log_stddev_fields';
  action_flag?: Maybe<Scalars['Float']>;
  content_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "django_admin_log" */
export type Django_Admin_Log_Stddev_Order_By = {
  action_flag?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Django_Admin_Log_Stddev_Pop_Fields = {
  __typename?: 'django_admin_log_stddev_pop_fields';
  action_flag?: Maybe<Scalars['Float']>;
  content_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "django_admin_log" */
export type Django_Admin_Log_Stddev_Pop_Order_By = {
  action_flag?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Django_Admin_Log_Stddev_Samp_Fields = {
  __typename?: 'django_admin_log_stddev_samp_fields';
  action_flag?: Maybe<Scalars['Float']>;
  content_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "django_admin_log" */
export type Django_Admin_Log_Stddev_Samp_Order_By = {
  action_flag?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "django_admin_log" */
export type Django_Admin_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Django_Admin_Log_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Django_Admin_Log_Stream_Cursor_Value_Input = {
  action_flag?: InputMaybe<Scalars['smallint']>;
  action_time?: InputMaybe<Scalars['timestamptz']>;
  change_message?: InputMaybe<Scalars['String']>;
  content_type_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  object_id?: InputMaybe<Scalars['String']>;
  object_repr?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Django_Admin_Log_Sum_Fields = {
  __typename?: 'django_admin_log_sum_fields';
  action_flag?: Maybe<Scalars['smallint']>;
  content_type_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "django_admin_log" */
export type Django_Admin_Log_Sum_Order_By = {
  action_flag?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "django_admin_log" */
export enum Django_Admin_Log_Update_Column {
  /** column name */
  ActionFlag = 'action_flag',
  /** column name */
  ActionTime = 'action_time',
  /** column name */
  ChangeMessage = 'change_message',
  /** column name */
  ContentTypeId = 'content_type_id',
  /** column name */
  Id = 'id',
  /** column name */
  ObjectId = 'object_id',
  /** column name */
  ObjectRepr = 'object_repr',
  /** column name */
  UserId = 'user_id'
}

export type Django_Admin_Log_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Django_Admin_Log_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Django_Admin_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Django_Admin_Log_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Django_Admin_Log_Var_Pop_Fields = {
  __typename?: 'django_admin_log_var_pop_fields';
  action_flag?: Maybe<Scalars['Float']>;
  content_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "django_admin_log" */
export type Django_Admin_Log_Var_Pop_Order_By = {
  action_flag?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Django_Admin_Log_Var_Samp_Fields = {
  __typename?: 'django_admin_log_var_samp_fields';
  action_flag?: Maybe<Scalars['Float']>;
  content_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "django_admin_log" */
export type Django_Admin_Log_Var_Samp_Order_By = {
  action_flag?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Django_Admin_Log_Variance_Fields = {
  __typename?: 'django_admin_log_variance_fields';
  action_flag?: Maybe<Scalars['Float']>;
  content_type_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "django_admin_log" */
export type Django_Admin_Log_Variance_Order_By = {
  action_flag?: InputMaybe<Order_By>;
  content_type_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "django_content_type" */
export type Django_Content_Type = {
  __typename?: 'django_content_type';
  app_label: Scalars['String'];
  /** An array relationship */
  auth_permissions: Array<Auth_Permission>;
  /** An aggregate relationship */
  auth_permissions_aggregate: Auth_Permission_Aggregate;
  /** An array relationship */
  django_admin_logs: Array<Django_Admin_Log>;
  /** An aggregate relationship */
  django_admin_logs_aggregate: Django_Admin_Log_Aggregate;
  id: Scalars['Int'];
  model: Scalars['String'];
};


/** columns and relationships of "django_content_type" */
export type Django_Content_TypeAuth_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Permission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Permission_Order_By>>;
  where?: InputMaybe<Auth_Permission_Bool_Exp>;
};


/** columns and relationships of "django_content_type" */
export type Django_Content_TypeAuth_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Permission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Permission_Order_By>>;
  where?: InputMaybe<Auth_Permission_Bool_Exp>;
};


/** columns and relationships of "django_content_type" */
export type Django_Content_TypeDjango_Admin_LogsArgs = {
  distinct_on?: InputMaybe<Array<Django_Admin_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Django_Admin_Log_Order_By>>;
  where?: InputMaybe<Django_Admin_Log_Bool_Exp>;
};


/** columns and relationships of "django_content_type" */
export type Django_Content_TypeDjango_Admin_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Django_Admin_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Django_Admin_Log_Order_By>>;
  where?: InputMaybe<Django_Admin_Log_Bool_Exp>;
};

/** aggregated selection of "django_content_type" */
export type Django_Content_Type_Aggregate = {
  __typename?: 'django_content_type_aggregate';
  aggregate?: Maybe<Django_Content_Type_Aggregate_Fields>;
  nodes: Array<Django_Content_Type>;
};

/** aggregate fields of "django_content_type" */
export type Django_Content_Type_Aggregate_Fields = {
  __typename?: 'django_content_type_aggregate_fields';
  avg?: Maybe<Django_Content_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Django_Content_Type_Max_Fields>;
  min?: Maybe<Django_Content_Type_Min_Fields>;
  stddev?: Maybe<Django_Content_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Django_Content_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Django_Content_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Django_Content_Type_Sum_Fields>;
  var_pop?: Maybe<Django_Content_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Django_Content_Type_Var_Samp_Fields>;
  variance?: Maybe<Django_Content_Type_Variance_Fields>;
};


/** aggregate fields of "django_content_type" */
export type Django_Content_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Django_Content_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Django_Content_Type_Avg_Fields = {
  __typename?: 'django_content_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "django_content_type". All fields are combined with a logical 'AND'. */
export type Django_Content_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Django_Content_Type_Bool_Exp>>;
  _not?: InputMaybe<Django_Content_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Django_Content_Type_Bool_Exp>>;
  app_label?: InputMaybe<String_Comparison_Exp>;
  auth_permissions?: InputMaybe<Auth_Permission_Bool_Exp>;
  auth_permissions_aggregate?: InputMaybe<Auth_Permission_Aggregate_Bool_Exp>;
  django_admin_logs?: InputMaybe<Django_Admin_Log_Bool_Exp>;
  django_admin_logs_aggregate?: InputMaybe<Django_Admin_Log_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  model?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "django_content_type" */
export enum Django_Content_Type_Constraint {
  /** unique or primary key constraint on columns "model", "app_label" */
  DjangoContentTypeAppLabelModel_76bd3d3bUniq = 'django_content_type_app_label_model_76bd3d3b_uniq',
  /** unique or primary key constraint on columns "id" */
  DjangoContentTypePkey = 'django_content_type_pkey'
}

/** input type for incrementing numeric columns in table "django_content_type" */
export type Django_Content_Type_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "django_content_type" */
export type Django_Content_Type_Insert_Input = {
  app_label?: InputMaybe<Scalars['String']>;
  auth_permissions?: InputMaybe<Auth_Permission_Arr_Rel_Insert_Input>;
  django_admin_logs?: InputMaybe<Django_Admin_Log_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  model?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Django_Content_Type_Max_Fields = {
  __typename?: 'django_content_type_max_fields';
  app_label?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  model?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Django_Content_Type_Min_Fields = {
  __typename?: 'django_content_type_min_fields';
  app_label?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  model?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "django_content_type" */
export type Django_Content_Type_Mutation_Response = {
  __typename?: 'django_content_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Django_Content_Type>;
};

/** input type for inserting object relation for remote table "django_content_type" */
export type Django_Content_Type_Obj_Rel_Insert_Input = {
  data: Django_Content_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Django_Content_Type_On_Conflict>;
};

/** on_conflict condition type for table "django_content_type" */
export type Django_Content_Type_On_Conflict = {
  constraint: Django_Content_Type_Constraint;
  update_columns?: Array<Django_Content_Type_Update_Column>;
  where?: InputMaybe<Django_Content_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "django_content_type". */
export type Django_Content_Type_Order_By = {
  app_label?: InputMaybe<Order_By>;
  auth_permissions_aggregate?: InputMaybe<Auth_Permission_Aggregate_Order_By>;
  django_admin_logs_aggregate?: InputMaybe<Django_Admin_Log_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  model?: InputMaybe<Order_By>;
};

/** primary key columns input for table: django_content_type */
export type Django_Content_Type_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "django_content_type" */
export enum Django_Content_Type_Select_Column {
  /** column name */
  AppLabel = 'app_label',
  /** column name */
  Id = 'id',
  /** column name */
  Model = 'model'
}

/** input type for updating data in table "django_content_type" */
export type Django_Content_Type_Set_Input = {
  app_label?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  model?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Django_Content_Type_Stddev_Fields = {
  __typename?: 'django_content_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Django_Content_Type_Stddev_Pop_Fields = {
  __typename?: 'django_content_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Django_Content_Type_Stddev_Samp_Fields = {
  __typename?: 'django_content_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "django_content_type" */
export type Django_Content_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Django_Content_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Django_Content_Type_Stream_Cursor_Value_Input = {
  app_label?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  model?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Django_Content_Type_Sum_Fields = {
  __typename?: 'django_content_type_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "django_content_type" */
export enum Django_Content_Type_Update_Column {
  /** column name */
  AppLabel = 'app_label',
  /** column name */
  Id = 'id',
  /** column name */
  Model = 'model'
}

export type Django_Content_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Django_Content_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Django_Content_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Django_Content_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Django_Content_Type_Var_Pop_Fields = {
  __typename?: 'django_content_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Django_Content_Type_Var_Samp_Fields = {
  __typename?: 'django_content_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Django_Content_Type_Variance_Fields = {
  __typename?: 'django_content_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "django_migrations" */
export type Django_Migrations = {
  __typename?: 'django_migrations';
  app: Scalars['String'];
  applied: Scalars['timestamptz'];
  id: Scalars['bigint'];
  name: Scalars['String'];
};

/** aggregated selection of "django_migrations" */
export type Django_Migrations_Aggregate = {
  __typename?: 'django_migrations_aggregate';
  aggregate?: Maybe<Django_Migrations_Aggregate_Fields>;
  nodes: Array<Django_Migrations>;
};

/** aggregate fields of "django_migrations" */
export type Django_Migrations_Aggregate_Fields = {
  __typename?: 'django_migrations_aggregate_fields';
  avg?: Maybe<Django_Migrations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Django_Migrations_Max_Fields>;
  min?: Maybe<Django_Migrations_Min_Fields>;
  stddev?: Maybe<Django_Migrations_Stddev_Fields>;
  stddev_pop?: Maybe<Django_Migrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Django_Migrations_Stddev_Samp_Fields>;
  sum?: Maybe<Django_Migrations_Sum_Fields>;
  var_pop?: Maybe<Django_Migrations_Var_Pop_Fields>;
  var_samp?: Maybe<Django_Migrations_Var_Samp_Fields>;
  variance?: Maybe<Django_Migrations_Variance_Fields>;
};


/** aggregate fields of "django_migrations" */
export type Django_Migrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Django_Migrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Django_Migrations_Avg_Fields = {
  __typename?: 'django_migrations_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "django_migrations". All fields are combined with a logical 'AND'. */
export type Django_Migrations_Bool_Exp = {
  _and?: InputMaybe<Array<Django_Migrations_Bool_Exp>>;
  _not?: InputMaybe<Django_Migrations_Bool_Exp>;
  _or?: InputMaybe<Array<Django_Migrations_Bool_Exp>>;
  app?: InputMaybe<String_Comparison_Exp>;
  applied?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "django_migrations" */
export enum Django_Migrations_Constraint {
  /** unique or primary key constraint on columns "id" */
  DjangoMigrationsPkey = 'django_migrations_pkey'
}

/** input type for incrementing numeric columns in table "django_migrations" */
export type Django_Migrations_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "django_migrations" */
export type Django_Migrations_Insert_Input = {
  app?: InputMaybe<Scalars['String']>;
  applied?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Django_Migrations_Max_Fields = {
  __typename?: 'django_migrations_max_fields';
  app?: Maybe<Scalars['String']>;
  applied?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Django_Migrations_Min_Fields = {
  __typename?: 'django_migrations_min_fields';
  app?: Maybe<Scalars['String']>;
  applied?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "django_migrations" */
export type Django_Migrations_Mutation_Response = {
  __typename?: 'django_migrations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Django_Migrations>;
};

/** on_conflict condition type for table "django_migrations" */
export type Django_Migrations_On_Conflict = {
  constraint: Django_Migrations_Constraint;
  update_columns?: Array<Django_Migrations_Update_Column>;
  where?: InputMaybe<Django_Migrations_Bool_Exp>;
};

/** Ordering options when selecting data from "django_migrations". */
export type Django_Migrations_Order_By = {
  app?: InputMaybe<Order_By>;
  applied?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: django_migrations */
export type Django_Migrations_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "django_migrations" */
export enum Django_Migrations_Select_Column {
  /** column name */
  App = 'app',
  /** column name */
  Applied = 'applied',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "django_migrations" */
export type Django_Migrations_Set_Input = {
  app?: InputMaybe<Scalars['String']>;
  applied?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Django_Migrations_Stddev_Fields = {
  __typename?: 'django_migrations_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Django_Migrations_Stddev_Pop_Fields = {
  __typename?: 'django_migrations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Django_Migrations_Stddev_Samp_Fields = {
  __typename?: 'django_migrations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "django_migrations" */
export type Django_Migrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Django_Migrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Django_Migrations_Stream_Cursor_Value_Input = {
  app?: InputMaybe<Scalars['String']>;
  applied?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['bigint']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Django_Migrations_Sum_Fields = {
  __typename?: 'django_migrations_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "django_migrations" */
export enum Django_Migrations_Update_Column {
  /** column name */
  App = 'app',
  /** column name */
  Applied = 'applied',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type Django_Migrations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Django_Migrations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Django_Migrations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Django_Migrations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Django_Migrations_Var_Pop_Fields = {
  __typename?: 'django_migrations_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Django_Migrations_Var_Samp_Fields = {
  __typename?: 'django_migrations_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Django_Migrations_Variance_Fields = {
  __typename?: 'django_migrations_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "django_session" */
export type Django_Session = {
  __typename?: 'django_session';
  expire_date: Scalars['timestamptz'];
  session_data: Scalars['String'];
  session_key: Scalars['String'];
};

/** aggregated selection of "django_session" */
export type Django_Session_Aggregate = {
  __typename?: 'django_session_aggregate';
  aggregate?: Maybe<Django_Session_Aggregate_Fields>;
  nodes: Array<Django_Session>;
};

/** aggregate fields of "django_session" */
export type Django_Session_Aggregate_Fields = {
  __typename?: 'django_session_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Django_Session_Max_Fields>;
  min?: Maybe<Django_Session_Min_Fields>;
};


/** aggregate fields of "django_session" */
export type Django_Session_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Django_Session_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "django_session". All fields are combined with a logical 'AND'. */
export type Django_Session_Bool_Exp = {
  _and?: InputMaybe<Array<Django_Session_Bool_Exp>>;
  _not?: InputMaybe<Django_Session_Bool_Exp>;
  _or?: InputMaybe<Array<Django_Session_Bool_Exp>>;
  expire_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  session_data?: InputMaybe<String_Comparison_Exp>;
  session_key?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "django_session" */
export enum Django_Session_Constraint {
  /** unique or primary key constraint on columns "session_key" */
  DjangoSessionPkey = 'django_session_pkey'
}

/** input type for inserting data into table "django_session" */
export type Django_Session_Insert_Input = {
  expire_date?: InputMaybe<Scalars['timestamptz']>;
  session_data?: InputMaybe<Scalars['String']>;
  session_key?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Django_Session_Max_Fields = {
  __typename?: 'django_session_max_fields';
  expire_date?: Maybe<Scalars['timestamptz']>;
  session_data?: Maybe<Scalars['String']>;
  session_key?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Django_Session_Min_Fields = {
  __typename?: 'django_session_min_fields';
  expire_date?: Maybe<Scalars['timestamptz']>;
  session_data?: Maybe<Scalars['String']>;
  session_key?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "django_session" */
export type Django_Session_Mutation_Response = {
  __typename?: 'django_session_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Django_Session>;
};

/** on_conflict condition type for table "django_session" */
export type Django_Session_On_Conflict = {
  constraint: Django_Session_Constraint;
  update_columns?: Array<Django_Session_Update_Column>;
  where?: InputMaybe<Django_Session_Bool_Exp>;
};

/** Ordering options when selecting data from "django_session". */
export type Django_Session_Order_By = {
  expire_date?: InputMaybe<Order_By>;
  session_data?: InputMaybe<Order_By>;
  session_key?: InputMaybe<Order_By>;
};

/** primary key columns input for table: django_session */
export type Django_Session_Pk_Columns_Input = {
  session_key: Scalars['String'];
};

/** select columns of table "django_session" */
export enum Django_Session_Select_Column {
  /** column name */
  ExpireDate = 'expire_date',
  /** column name */
  SessionData = 'session_data',
  /** column name */
  SessionKey = 'session_key'
}

/** input type for updating data in table "django_session" */
export type Django_Session_Set_Input = {
  expire_date?: InputMaybe<Scalars['timestamptz']>;
  session_data?: InputMaybe<Scalars['String']>;
  session_key?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "django_session" */
export type Django_Session_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Django_Session_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Django_Session_Stream_Cursor_Value_Input = {
  expire_date?: InputMaybe<Scalars['timestamptz']>;
  session_data?: InputMaybe<Scalars['String']>;
  session_key?: InputMaybe<Scalars['String']>;
};

/** update columns of table "django_session" */
export enum Django_Session_Update_Column {
  /** column name */
  ExpireDate = 'expire_date',
  /** column name */
  SessionData = 'session_data',
  /** column name */
  SessionKey = 'session_key'
}

export type Django_Session_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Django_Session_Set_Input>;
  /** filter the rows which have to be updated */
  where: Django_Session_Bool_Exp;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

export type Getcompanywidesitechargecostandunitsbycompanyid_Args = {
  v_company_id?: InputMaybe<Scalars['Int']>;
};

export type Getsitechargecostandunitsbysiteid_Args = {
  v_site_id?: InputMaybe<Scalars['Int']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "auth_group" */
  delete_auth_group?: Maybe<Auth_Group_Mutation_Response>;
  /** delete single row from the table: "auth_group" */
  delete_auth_group_by_pk?: Maybe<Auth_Group>;
  /** delete data from the table: "auth_group_permissions" */
  delete_auth_group_permissions?: Maybe<Auth_Group_Permissions_Mutation_Response>;
  /** delete single row from the table: "auth_group_permissions" */
  delete_auth_group_permissions_by_pk?: Maybe<Auth_Group_Permissions>;
  /** delete data from the table: "auth_permission" */
  delete_auth_permission?: Maybe<Auth_Permission_Mutation_Response>;
  /** delete single row from the table: "auth_permission" */
  delete_auth_permission_by_pk?: Maybe<Auth_Permission>;
  /** delete data from the table: "authtoken_token" */
  delete_authtoken_token?: Maybe<Authtoken_Token_Mutation_Response>;
  /** delete single row from the table: "authtoken_token" */
  delete_authtoken_token_by_pk?: Maybe<Authtoken_Token>;
  /** delete data from the table: "core_agreement" */
  delete_core_agreement?: Maybe<Core_Agreement_Mutation_Response>;
  /** delete single row from the table: "core_agreement" */
  delete_core_agreement_by_pk?: Maybe<Core_Agreement>;
  /** delete data from the table: "core_box" */
  delete_core_box?: Maybe<Core_Box_Mutation_Response>;
  /** delete single row from the table: "core_box" */
  delete_core_box_by_pk?: Maybe<Core_Box>;
  /** delete data from the table: "core_boxstatus" */
  delete_core_boxstatus?: Maybe<Core_Boxstatus_Mutation_Response>;
  /** delete single row from the table: "core_boxstatus" */
  delete_core_boxstatus_by_pk?: Maybe<Core_Boxstatus>;
  /** delete data from the table: "core_cabin" */
  delete_core_cabin?: Maybe<Core_Cabin_Mutation_Response>;
  /** delete single row from the table: "core_cabin" */
  delete_core_cabin_by_pk?: Maybe<Core_Cabin>;
  /** delete data from the table: "core_camera" */
  delete_core_camera?: Maybe<Core_Camera_Mutation_Response>;
  /** delete single row from the table: "core_camera" */
  delete_core_camera_by_pk?: Maybe<Core_Camera>;
  /** delete data from the table: "core_company" */
  delete_core_company?: Maybe<Core_Company_Mutation_Response>;
  /** delete single row from the table: "core_company" */
  delete_core_company_by_pk?: Maybe<Core_Company>;
  /** delete data from the table: "core_companysitechargeestimate" */
  delete_core_companysitechargeestimate?: Maybe<Core_Companysitechargeestimate_Mutation_Response>;
  /** delete single row from the table: "core_companysitechargeestimate" */
  delete_core_companysitechargeestimate_by_pk?: Maybe<Core_Companysitechargeestimate>;
  /** delete data from the table: "core_customertype" */
  delete_core_customertype?: Maybe<Core_Customertype_Mutation_Response>;
  /** delete single row from the table: "core_customertype" */
  delete_core_customertype_by_pk?: Maybe<Core_Customertype>;
  /** delete data from the table: "core_disruptions" */
  delete_core_disruptions?: Maybe<Core_Disruptions_Mutation_Response>;
  /** delete single row from the table: "core_disruptions" */
  delete_core_disruptions_by_pk?: Maybe<Core_Disruptions>;
  /** delete data from the table: "core_disruptionstype" */
  delete_core_disruptionstype?: Maybe<Core_Disruptionstype_Mutation_Response>;
  /** delete single row from the table: "core_disruptionstype" */
  delete_core_disruptionstype_by_pk?: Maybe<Core_Disruptionstype>;
  /** delete data from the table: "core_energycharge" */
  delete_core_energycharge?: Maybe<Core_Energycharge_Mutation_Response>;
  /** delete single row from the table: "core_energycharge" */
  delete_core_energycharge_by_pk?: Maybe<Core_Energycharge>;
  /** delete data from the table: "core_equipment" */
  delete_core_equipment?: Maybe<Core_Equipment_Mutation_Response>;
  /** delete single row from the table: "core_equipment" */
  delete_core_equipment_by_pk?: Maybe<Core_Equipment>;
  /** delete data from the table: "core_equipmentcondition" */
  delete_core_equipmentcondition?: Maybe<Core_Equipmentcondition_Mutation_Response>;
  /** delete single row from the table: "core_equipmentcondition" */
  delete_core_equipmentcondition_by_pk?: Maybe<Core_Equipmentcondition>;
  /** delete data from the table: "core_equipmentimage" */
  delete_core_equipmentimage?: Maybe<Core_Equipmentimage_Mutation_Response>;
  /** delete single row from the table: "core_equipmentimage" */
  delete_core_equipmentimage_by_pk?: Maybe<Core_Equipmentimage>;
  /** delete data from the table: "core_equipmentmake" */
  delete_core_equipmentmake?: Maybe<Core_Equipmentmake_Mutation_Response>;
  /** delete single row from the table: "core_equipmentmake" */
  delete_core_equipmentmake_by_pk?: Maybe<Core_Equipmentmake>;
  /** delete data from the table: "core_equipmentmodel" */
  delete_core_equipmentmodel?: Maybe<Core_Equipmentmodel_Mutation_Response>;
  /** delete single row from the table: "core_equipmentmodel" */
  delete_core_equipmentmodel_by_pk?: Maybe<Core_Equipmentmodel>;
  /** delete data from the table: "core_equipmentpart" */
  delete_core_equipmentpart?: Maybe<Core_Equipmentpart_Mutation_Response>;
  /** delete single row from the table: "core_equipmentpart" */
  delete_core_equipmentpart_by_pk?: Maybe<Core_Equipmentpart>;
  /** delete data from the table: "core_equipmentstatus" */
  delete_core_equipmentstatus?: Maybe<Core_Equipmentstatus_Mutation_Response>;
  /** delete single row from the table: "core_equipmentstatus" */
  delete_core_equipmentstatus_by_pk?: Maybe<Core_Equipmentstatus>;
  /** delete data from the table: "core_equipmenttype" */
  delete_core_equipmenttype?: Maybe<Core_Equipmenttype_Mutation_Response>;
  /** delete single row from the table: "core_equipmenttype" */
  delete_core_equipmenttype_by_pk?: Maybe<Core_Equipmenttype>;
  /** delete data from the table: "core_esp" */
  delete_core_esp?: Maybe<Core_Esp_Mutation_Response>;
  /** delete single row from the table: "core_esp" */
  delete_core_esp_by_pk?: Maybe<Core_Esp>;
  /** delete data from the table: "core_espstatus" */
  delete_core_espstatus?: Maybe<Core_Espstatus_Mutation_Response>;
  /** delete single row from the table: "core_espstatus" */
  delete_core_espstatus_by_pk?: Maybe<Core_Espstatus>;
  /** delete data from the table: "core_espstatusnextstages" */
  delete_core_espstatusnextstages?: Maybe<Core_Espstatusnextstages_Mutation_Response>;
  /** delete single row from the table: "core_espstatusnextstages" */
  delete_core_espstatusnextstages_by_pk?: Maybe<Core_Espstatusnextstages>;
  /** delete data from the table: "core_eventlog" */
  delete_core_eventlog?: Maybe<Core_Eventlog_Mutation_Response>;
  /** delete single row from the table: "core_eventlog" */
  delete_core_eventlog_by_pk?: Maybe<Core_Eventlog>;
  /** delete data from the table: "core_incident" */
  delete_core_incident?: Maybe<Core_Incident_Mutation_Response>;
  /** delete single row from the table: "core_incident" */
  delete_core_incident_by_pk?: Maybe<Core_Incident>;
  /** delete data from the table: "core_job" */
  delete_core_job?: Maybe<Core_Job_Mutation_Response>;
  /** delete single row from the table: "core_job" */
  delete_core_job_by_pk?: Maybe<Core_Job>;
  /** delete data from the table: "core_jobstatus" */
  delete_core_jobstatus?: Maybe<Core_Jobstatus_Mutation_Response>;
  /** delete single row from the table: "core_jobstatus" */
  delete_core_jobstatus_by_pk?: Maybe<Core_Jobstatus>;
  /** delete data from the table: "core_jobstatusenum" */
  delete_core_jobstatusenum?: Maybe<Core_Jobstatusenum_Mutation_Response>;
  /** delete single row from the table: "core_jobstatusenum" */
  delete_core_jobstatusenum_by_pk?: Maybe<Core_Jobstatusenum>;
  /** delete data from the table: "core_log" */
  delete_core_log?: Maybe<Core_Log_Mutation_Response>;
  /** delete single row from the table: "core_log" */
  delete_core_log_by_pk?: Maybe<Core_Log>;
  /** delete data from the table: "core_logfacialrecognition" */
  delete_core_logfacialrecognition?: Maybe<Core_Logfacialrecognition_Mutation_Response>;
  /** delete single row from the table: "core_logfacialrecognition" */
  delete_core_logfacialrecognition_by_pk?: Maybe<Core_Logfacialrecognition>;
  /** delete data from the table: "core_logimage" */
  delete_core_logimage?: Maybe<Core_Logimage_Mutation_Response>;
  /** delete single row from the table: "core_logimage" */
  delete_core_logimage_by_pk?: Maybe<Core_Logimage>;
  /** delete data from the table: "core_logsuspiciosactivity" */
  delete_core_logsuspiciosactivity?: Maybe<Core_Logsuspiciosactivity_Mutation_Response>;
  /** delete single row from the table: "core_logsuspiciosactivity" */
  delete_core_logsuspiciosactivity_by_pk?: Maybe<Core_Logsuspiciosactivity>;
  /** delete data from the table: "core_meter" */
  delete_core_meter?: Maybe<Core_Meter_Mutation_Response>;
  /** delete single row from the table: "core_meter" */
  delete_core_meter_by_pk?: Maybe<Core_Meter>;
  /** delete data from the table: "core_meterreading" */
  delete_core_meterreading?: Maybe<Core_Meterreading_Mutation_Response>;
  /** delete single row from the table: "core_meterreading" */
  delete_core_meterreading_by_pk?: Maybe<Core_Meterreading>;
  /** delete data from the table: "core_metric" */
  delete_core_metric?: Maybe<Core_Metric_Mutation_Response>;
  /** delete single row from the table: "core_metric" */
  delete_core_metric_by_pk?: Maybe<Core_Metric>;
  /** delete data from the table: "core_municipality" */
  delete_core_municipality?: Maybe<Core_Municipality_Mutation_Response>;
  /** delete single row from the table: "core_municipality" */
  delete_core_municipality_by_pk?: Maybe<Core_Municipality>;
  /** delete data from the table: "core_noneuserpersonale" */
  delete_core_noneuserpersonale?: Maybe<Core_Noneuserpersonale_Mutation_Response>;
  /** delete single row from the table: "core_noneuserpersonale" */
  delete_core_noneuserpersonale_by_pk?: Maybe<Core_Noneuserpersonale>;
  /** delete data from the table: "core_paymenttype" */
  delete_core_paymenttype?: Maybe<Core_Paymenttype_Mutation_Response>;
  /** delete single row from the table: "core_paymenttype" */
  delete_core_paymenttype_by_pk?: Maybe<Core_Paymenttype>;
  /** delete data from the table: "core_personaleimage" */
  delete_core_personaleimage?: Maybe<Core_Personaleimage_Mutation_Response>;
  /** delete single row from the table: "core_personaleimage" */
  delete_core_personaleimage_by_pk?: Maybe<Core_Personaleimage>;
  /** delete data from the table: "core_personnelassigned" */
  delete_core_personnelassigned?: Maybe<Core_Personnelassigned_Mutation_Response>;
  /** delete single row from the table: "core_personnelassigned" */
  delete_core_personnelassigned_by_pk?: Maybe<Core_Personnelassigned>;
  /** delete data from the table: "core_powerprovider" */
  delete_core_powerprovider?: Maybe<Core_Powerprovider_Mutation_Response>;
  /** delete single row from the table: "core_powerprovider" */
  delete_core_powerprovider_by_pk?: Maybe<Core_Powerprovider>;
  /** delete data from the table: "core_powersourcetype" */
  delete_core_powersourcetype?: Maybe<Core_Powersourcetype_Mutation_Response>;
  /** delete single row from the table: "core_powersourcetype" */
  delete_core_powersourcetype_by_pk?: Maybe<Core_Powersourcetype>;
  /** delete data from the table: "core_province" */
  delete_core_province?: Maybe<Core_Province_Mutation_Response>;
  /** delete single row from the table: "core_province" */
  delete_core_province_by_pk?: Maybe<Core_Province>;
  /** delete data from the table: "core_rectifieralarm" */
  delete_core_rectifieralarm?: Maybe<Core_Rectifieralarm_Mutation_Response>;
  /** delete single row from the table: "core_rectifieralarm" */
  delete_core_rectifieralarm_by_pk?: Maybe<Core_Rectifieralarm>;
  /** delete data from the table: "core_requestaccess" */
  delete_core_requestaccess?: Maybe<Core_Requestaccess_Mutation_Response>;
  /** delete single row from the table: "core_requestaccess" */
  delete_core_requestaccess_by_pk?: Maybe<Core_Requestaccess>;
  /** delete data from the table: "core_res_company_wide_charge_estimate" */
  delete_core_res_company_wide_charge_estimate?: Maybe<Core_Res_Company_Wide_Charge_Estimate_Mutation_Response>;
  /** delete data from the table: "core_resjobstatus" */
  delete_core_resjobstatus?: Maybe<Core_Resjobstatus_Mutation_Response>;
  /** delete single row from the table: "core_resjobstatus" */
  delete_core_resjobstatus_by_pk?: Maybe<Core_Resjobstatus>;
  /** delete data from the table: "core_role" */
  delete_core_role?: Maybe<Core_Role_Mutation_Response>;
  /** delete single row from the table: "core_role" */
  delete_core_role_by_pk?: Maybe<Core_Role>;
  /** delete data from the table: "core_router" */
  delete_core_router?: Maybe<Core_Router_Mutation_Response>;
  /** delete single row from the table: "core_router" */
  delete_core_router_by_pk?: Maybe<Core_Router>;
  /** delete data from the table: "core_securitycallouts" */
  delete_core_securitycallouts?: Maybe<Core_Securitycallouts_Mutation_Response>;
  /** delete single row from the table: "core_securitycallouts" */
  delete_core_securitycallouts_by_pk?: Maybe<Core_Securitycallouts>;
  /** delete data from the table: "core_securitycalloutsstatus" */
  delete_core_securitycalloutsstatus?: Maybe<Core_Securitycalloutsstatus_Mutation_Response>;
  /** delete single row from the table: "core_securitycalloutsstatus" */
  delete_core_securitycalloutsstatus_by_pk?: Maybe<Core_Securitycalloutsstatus>;
  /** delete data from the table: "core_simcard" */
  delete_core_simcard?: Maybe<Core_Simcard_Mutation_Response>;
  /** delete single row from the table: "core_simcard" */
  delete_core_simcard_by_pk?: Maybe<Core_Simcard>;
  /** delete data from the table: "core_simcardstatus" */
  delete_core_simcardstatus?: Maybe<Core_Simcardstatus_Mutation_Response>;
  /** delete single row from the table: "core_simcardstatus" */
  delete_core_simcardstatus_by_pk?: Maybe<Core_Simcardstatus>;
  /** delete data from the table: "core_site" */
  delete_core_site?: Maybe<Core_Site_Mutation_Response>;
  /** delete single row from the table: "core_site" */
  delete_core_site_by_pk?: Maybe<Core_Site>;
  /** delete data from the table: "core_sitechargeestimate" */
  delete_core_sitechargeestimate?: Maybe<Core_Sitechargeestimate_Mutation_Response>;
  /** delete single row from the table: "core_sitechargeestimate" */
  delete_core_sitechargeestimate_by_pk?: Maybe<Core_Sitechargeestimate>;
  /** delete data from the table: "core_siteregion" */
  delete_core_siteregion?: Maybe<Core_Siteregion_Mutation_Response>;
  /** delete single row from the table: "core_siteregion" */
  delete_core_siteregion_by_pk?: Maybe<Core_Siteregion>;
  /** delete data from the table: "core_sitesecuritycoverage" */
  delete_core_sitesecuritycoverage?: Maybe<Core_Sitesecuritycoverage_Mutation_Response>;
  /** delete single row from the table: "core_sitesecuritycoverage" */
  delete_core_sitesecuritycoverage_by_pk?: Maybe<Core_Sitesecuritycoverage>;
  /** delete data from the table: "core_sitestatus" */
  delete_core_sitestatus?: Maybe<Core_Sitestatus_Mutation_Response>;
  /** delete single row from the table: "core_sitestatus" */
  delete_core_sitestatus_by_pk?: Maybe<Core_Sitestatus>;
  /** delete data from the table: "core_sitetype" */
  delete_core_sitetype?: Maybe<Core_Sitetype_Mutation_Response>;
  /** delete single row from the table: "core_sitetype" */
  delete_core_sitetype_by_pk?: Maybe<Core_Sitetype>;
  /** delete data from the table: "core_sundrycharge" */
  delete_core_sundrycharge?: Maybe<Core_Sundrycharge_Mutation_Response>;
  /** delete single row from the table: "core_sundrycharge" */
  delete_core_sundrycharge_by_pk?: Maybe<Core_Sundrycharge>;
  /** delete data from the table: "core_sundrychargetype" */
  delete_core_sundrychargetype?: Maybe<Core_Sundrychargetype_Mutation_Response>;
  /** delete single row from the table: "core_sundrychargetype" */
  delete_core_sundrychargetype_by_pk?: Maybe<Core_Sundrychargetype>;
  /** delete data from the table: "core_tariff" */
  delete_core_tariff?: Maybe<Core_Tariff_Mutation_Response>;
  /** delete single row from the table: "core_tariff" */
  delete_core_tariff_by_pk?: Maybe<Core_Tariff>;
  /** delete data from the table: "core_user" */
  delete_core_user?: Maybe<Core_User_Mutation_Response>;
  /** delete single row from the table: "core_user" */
  delete_core_user_by_pk?: Maybe<Core_User>;
  /** delete data from the table: "core_user_groups" */
  delete_core_user_groups?: Maybe<Core_User_Groups_Mutation_Response>;
  /** delete single row from the table: "core_user_groups" */
  delete_core_user_groups_by_pk?: Maybe<Core_User_Groups>;
  /** delete data from the table: "core_user_user_permissions" */
  delete_core_user_user_permissions?: Maybe<Core_User_User_Permissions_Mutation_Response>;
  /** delete single row from the table: "core_user_user_permissions" */
  delete_core_user_user_permissions_by_pk?: Maybe<Core_User_User_Permissions>;
  /** delete data from the table: "core_visit" */
  delete_core_visit?: Maybe<Core_Visit_Mutation_Response>;
  /** delete single row from the table: "core_visit" */
  delete_core_visit_by_pk?: Maybe<Core_Visit>;
  /** delete data from the table: "core_visitstatusenum" */
  delete_core_visitstatusenum?: Maybe<Core_Visitstatusenum_Mutation_Response>;
  /** delete single row from the table: "core_visitstatusenum" */
  delete_core_visitstatusenum_by_pk?: Maybe<Core_Visitstatusenum>;
  /** delete data from the table: "django_admin_log" */
  delete_django_admin_log?: Maybe<Django_Admin_Log_Mutation_Response>;
  /** delete single row from the table: "django_admin_log" */
  delete_django_admin_log_by_pk?: Maybe<Django_Admin_Log>;
  /** delete data from the table: "django_content_type" */
  delete_django_content_type?: Maybe<Django_Content_Type_Mutation_Response>;
  /** delete single row from the table: "django_content_type" */
  delete_django_content_type_by_pk?: Maybe<Django_Content_Type>;
  /** delete data from the table: "django_migrations" */
  delete_django_migrations?: Maybe<Django_Migrations_Mutation_Response>;
  /** delete single row from the table: "django_migrations" */
  delete_django_migrations_by_pk?: Maybe<Django_Migrations>;
  /** delete data from the table: "django_session" */
  delete_django_session?: Maybe<Django_Session_Mutation_Response>;
  /** delete single row from the table: "django_session" */
  delete_django_session_by_pk?: Maybe<Django_Session>;
  /** insert data into the table: "auth_group" */
  insert_auth_group?: Maybe<Auth_Group_Mutation_Response>;
  /** insert a single row into the table: "auth_group" */
  insert_auth_group_one?: Maybe<Auth_Group>;
  /** insert data into the table: "auth_group_permissions" */
  insert_auth_group_permissions?: Maybe<Auth_Group_Permissions_Mutation_Response>;
  /** insert a single row into the table: "auth_group_permissions" */
  insert_auth_group_permissions_one?: Maybe<Auth_Group_Permissions>;
  /** insert data into the table: "auth_permission" */
  insert_auth_permission?: Maybe<Auth_Permission_Mutation_Response>;
  /** insert a single row into the table: "auth_permission" */
  insert_auth_permission_one?: Maybe<Auth_Permission>;
  /** insert data into the table: "authtoken_token" */
  insert_authtoken_token?: Maybe<Authtoken_Token_Mutation_Response>;
  /** insert a single row into the table: "authtoken_token" */
  insert_authtoken_token_one?: Maybe<Authtoken_Token>;
  /** insert data into the table: "core_agreement" */
  insert_core_agreement?: Maybe<Core_Agreement_Mutation_Response>;
  /** insert a single row into the table: "core_agreement" */
  insert_core_agreement_one?: Maybe<Core_Agreement>;
  /** insert data into the table: "core_box" */
  insert_core_box?: Maybe<Core_Box_Mutation_Response>;
  /** insert a single row into the table: "core_box" */
  insert_core_box_one?: Maybe<Core_Box>;
  /** insert data into the table: "core_boxstatus" */
  insert_core_boxstatus?: Maybe<Core_Boxstatus_Mutation_Response>;
  /** insert a single row into the table: "core_boxstatus" */
  insert_core_boxstatus_one?: Maybe<Core_Boxstatus>;
  /** insert data into the table: "core_cabin" */
  insert_core_cabin?: Maybe<Core_Cabin_Mutation_Response>;
  /** insert a single row into the table: "core_cabin" */
  insert_core_cabin_one?: Maybe<Core_Cabin>;
  /** insert data into the table: "core_camera" */
  insert_core_camera?: Maybe<Core_Camera_Mutation_Response>;
  /** insert a single row into the table: "core_camera" */
  insert_core_camera_one?: Maybe<Core_Camera>;
  /** insert data into the table: "core_company" */
  insert_core_company?: Maybe<Core_Company_Mutation_Response>;
  /** insert a single row into the table: "core_company" */
  insert_core_company_one?: Maybe<Core_Company>;
  /** insert data into the table: "core_companysitechargeestimate" */
  insert_core_companysitechargeestimate?: Maybe<Core_Companysitechargeestimate_Mutation_Response>;
  /** insert a single row into the table: "core_companysitechargeestimate" */
  insert_core_companysitechargeestimate_one?: Maybe<Core_Companysitechargeestimate>;
  /** insert data into the table: "core_customertype" */
  insert_core_customertype?: Maybe<Core_Customertype_Mutation_Response>;
  /** insert a single row into the table: "core_customertype" */
  insert_core_customertype_one?: Maybe<Core_Customertype>;
  /** insert data into the table: "core_disruptions" */
  insert_core_disruptions?: Maybe<Core_Disruptions_Mutation_Response>;
  /** insert a single row into the table: "core_disruptions" */
  insert_core_disruptions_one?: Maybe<Core_Disruptions>;
  /** insert data into the table: "core_disruptionstype" */
  insert_core_disruptionstype?: Maybe<Core_Disruptionstype_Mutation_Response>;
  /** insert a single row into the table: "core_disruptionstype" */
  insert_core_disruptionstype_one?: Maybe<Core_Disruptionstype>;
  /** insert data into the table: "core_energycharge" */
  insert_core_energycharge?: Maybe<Core_Energycharge_Mutation_Response>;
  /** insert a single row into the table: "core_energycharge" */
  insert_core_energycharge_one?: Maybe<Core_Energycharge>;
  /** insert data into the table: "core_equipment" */
  insert_core_equipment?: Maybe<Core_Equipment_Mutation_Response>;
  /** insert a single row into the table: "core_equipment" */
  insert_core_equipment_one?: Maybe<Core_Equipment>;
  /** insert data into the table: "core_equipmentcondition" */
  insert_core_equipmentcondition?: Maybe<Core_Equipmentcondition_Mutation_Response>;
  /** insert a single row into the table: "core_equipmentcondition" */
  insert_core_equipmentcondition_one?: Maybe<Core_Equipmentcondition>;
  /** insert data into the table: "core_equipmentimage" */
  insert_core_equipmentimage?: Maybe<Core_Equipmentimage_Mutation_Response>;
  /** insert a single row into the table: "core_equipmentimage" */
  insert_core_equipmentimage_one?: Maybe<Core_Equipmentimage>;
  /** insert data into the table: "core_equipmentmake" */
  insert_core_equipmentmake?: Maybe<Core_Equipmentmake_Mutation_Response>;
  /** insert a single row into the table: "core_equipmentmake" */
  insert_core_equipmentmake_one?: Maybe<Core_Equipmentmake>;
  /** insert data into the table: "core_equipmentmodel" */
  insert_core_equipmentmodel?: Maybe<Core_Equipmentmodel_Mutation_Response>;
  /** insert a single row into the table: "core_equipmentmodel" */
  insert_core_equipmentmodel_one?: Maybe<Core_Equipmentmodel>;
  /** insert data into the table: "core_equipmentpart" */
  insert_core_equipmentpart?: Maybe<Core_Equipmentpart_Mutation_Response>;
  /** insert a single row into the table: "core_equipmentpart" */
  insert_core_equipmentpart_one?: Maybe<Core_Equipmentpart>;
  /** insert data into the table: "core_equipmentstatus" */
  insert_core_equipmentstatus?: Maybe<Core_Equipmentstatus_Mutation_Response>;
  /** insert a single row into the table: "core_equipmentstatus" */
  insert_core_equipmentstatus_one?: Maybe<Core_Equipmentstatus>;
  /** insert data into the table: "core_equipmenttype" */
  insert_core_equipmenttype?: Maybe<Core_Equipmenttype_Mutation_Response>;
  /** insert a single row into the table: "core_equipmenttype" */
  insert_core_equipmenttype_one?: Maybe<Core_Equipmenttype>;
  /** insert data into the table: "core_esp" */
  insert_core_esp?: Maybe<Core_Esp_Mutation_Response>;
  /** insert a single row into the table: "core_esp" */
  insert_core_esp_one?: Maybe<Core_Esp>;
  /** insert data into the table: "core_espstatus" */
  insert_core_espstatus?: Maybe<Core_Espstatus_Mutation_Response>;
  /** insert a single row into the table: "core_espstatus" */
  insert_core_espstatus_one?: Maybe<Core_Espstatus>;
  /** insert data into the table: "core_espstatusnextstages" */
  insert_core_espstatusnextstages?: Maybe<Core_Espstatusnextstages_Mutation_Response>;
  /** insert a single row into the table: "core_espstatusnextstages" */
  insert_core_espstatusnextstages_one?: Maybe<Core_Espstatusnextstages>;
  /** insert data into the table: "core_eventlog" */
  insert_core_eventlog?: Maybe<Core_Eventlog_Mutation_Response>;
  /** insert a single row into the table: "core_eventlog" */
  insert_core_eventlog_one?: Maybe<Core_Eventlog>;
  /** insert data into the table: "core_incident" */
  insert_core_incident?: Maybe<Core_Incident_Mutation_Response>;
  /** insert a single row into the table: "core_incident" */
  insert_core_incident_one?: Maybe<Core_Incident>;
  /** insert data into the table: "core_job" */
  insert_core_job?: Maybe<Core_Job_Mutation_Response>;
  /** insert a single row into the table: "core_job" */
  insert_core_job_one?: Maybe<Core_Job>;
  /** insert data into the table: "core_jobstatus" */
  insert_core_jobstatus?: Maybe<Core_Jobstatus_Mutation_Response>;
  /** insert a single row into the table: "core_jobstatus" */
  insert_core_jobstatus_one?: Maybe<Core_Jobstatus>;
  /** insert data into the table: "core_jobstatusenum" */
  insert_core_jobstatusenum?: Maybe<Core_Jobstatusenum_Mutation_Response>;
  /** insert a single row into the table: "core_jobstatusenum" */
  insert_core_jobstatusenum_one?: Maybe<Core_Jobstatusenum>;
  /** insert data into the table: "core_log" */
  insert_core_log?: Maybe<Core_Log_Mutation_Response>;
  /** insert a single row into the table: "core_log" */
  insert_core_log_one?: Maybe<Core_Log>;
  /** insert data into the table: "core_logfacialrecognition" */
  insert_core_logfacialrecognition?: Maybe<Core_Logfacialrecognition_Mutation_Response>;
  /** insert a single row into the table: "core_logfacialrecognition" */
  insert_core_logfacialrecognition_one?: Maybe<Core_Logfacialrecognition>;
  /** insert data into the table: "core_logimage" */
  insert_core_logimage?: Maybe<Core_Logimage_Mutation_Response>;
  /** insert a single row into the table: "core_logimage" */
  insert_core_logimage_one?: Maybe<Core_Logimage>;
  /** insert data into the table: "core_logsuspiciosactivity" */
  insert_core_logsuspiciosactivity?: Maybe<Core_Logsuspiciosactivity_Mutation_Response>;
  /** insert a single row into the table: "core_logsuspiciosactivity" */
  insert_core_logsuspiciosactivity_one?: Maybe<Core_Logsuspiciosactivity>;
  /** insert data into the table: "core_meter" */
  insert_core_meter?: Maybe<Core_Meter_Mutation_Response>;
  /** insert a single row into the table: "core_meter" */
  insert_core_meter_one?: Maybe<Core_Meter>;
  /** insert data into the table: "core_meterreading" */
  insert_core_meterreading?: Maybe<Core_Meterreading_Mutation_Response>;
  /** insert a single row into the table: "core_meterreading" */
  insert_core_meterreading_one?: Maybe<Core_Meterreading>;
  /** insert data into the table: "core_metric" */
  insert_core_metric?: Maybe<Core_Metric_Mutation_Response>;
  /** insert a single row into the table: "core_metric" */
  insert_core_metric_one?: Maybe<Core_Metric>;
  /** insert data into the table: "core_municipality" */
  insert_core_municipality?: Maybe<Core_Municipality_Mutation_Response>;
  /** insert a single row into the table: "core_municipality" */
  insert_core_municipality_one?: Maybe<Core_Municipality>;
  /** insert data into the table: "core_noneuserpersonale" */
  insert_core_noneuserpersonale?: Maybe<Core_Noneuserpersonale_Mutation_Response>;
  /** insert a single row into the table: "core_noneuserpersonale" */
  insert_core_noneuserpersonale_one?: Maybe<Core_Noneuserpersonale>;
  /** insert data into the table: "core_paymenttype" */
  insert_core_paymenttype?: Maybe<Core_Paymenttype_Mutation_Response>;
  /** insert a single row into the table: "core_paymenttype" */
  insert_core_paymenttype_one?: Maybe<Core_Paymenttype>;
  /** insert data into the table: "core_personaleimage" */
  insert_core_personaleimage?: Maybe<Core_Personaleimage_Mutation_Response>;
  /** insert a single row into the table: "core_personaleimage" */
  insert_core_personaleimage_one?: Maybe<Core_Personaleimage>;
  /** insert data into the table: "core_personnelassigned" */
  insert_core_personnelassigned?: Maybe<Core_Personnelassigned_Mutation_Response>;
  /** insert a single row into the table: "core_personnelassigned" */
  insert_core_personnelassigned_one?: Maybe<Core_Personnelassigned>;
  /** insert data into the table: "core_powerprovider" */
  insert_core_powerprovider?: Maybe<Core_Powerprovider_Mutation_Response>;
  /** insert a single row into the table: "core_powerprovider" */
  insert_core_powerprovider_one?: Maybe<Core_Powerprovider>;
  /** insert data into the table: "core_powersourcetype" */
  insert_core_powersourcetype?: Maybe<Core_Powersourcetype_Mutation_Response>;
  /** insert a single row into the table: "core_powersourcetype" */
  insert_core_powersourcetype_one?: Maybe<Core_Powersourcetype>;
  /** insert data into the table: "core_province" */
  insert_core_province?: Maybe<Core_Province_Mutation_Response>;
  /** insert a single row into the table: "core_province" */
  insert_core_province_one?: Maybe<Core_Province>;
  /** insert data into the table: "core_rectifieralarm" */
  insert_core_rectifieralarm?: Maybe<Core_Rectifieralarm_Mutation_Response>;
  /** insert a single row into the table: "core_rectifieralarm" */
  insert_core_rectifieralarm_one?: Maybe<Core_Rectifieralarm>;
  /** insert data into the table: "core_requestaccess" */
  insert_core_requestaccess?: Maybe<Core_Requestaccess_Mutation_Response>;
  /** insert a single row into the table: "core_requestaccess" */
  insert_core_requestaccess_one?: Maybe<Core_Requestaccess>;
  /** insert data into the table: "core_res_company_wide_charge_estimate" */
  insert_core_res_company_wide_charge_estimate?: Maybe<Core_Res_Company_Wide_Charge_Estimate_Mutation_Response>;
  /** insert a single row into the table: "core_res_company_wide_charge_estimate" */
  insert_core_res_company_wide_charge_estimate_one?: Maybe<Core_Res_Company_Wide_Charge_Estimate>;
  /** insert data into the table: "core_resjobstatus" */
  insert_core_resjobstatus?: Maybe<Core_Resjobstatus_Mutation_Response>;
  /** insert a single row into the table: "core_resjobstatus" */
  insert_core_resjobstatus_one?: Maybe<Core_Resjobstatus>;
  /** insert data into the table: "core_role" */
  insert_core_role?: Maybe<Core_Role_Mutation_Response>;
  /** insert a single row into the table: "core_role" */
  insert_core_role_one?: Maybe<Core_Role>;
  /** insert data into the table: "core_router" */
  insert_core_router?: Maybe<Core_Router_Mutation_Response>;
  /** insert a single row into the table: "core_router" */
  insert_core_router_one?: Maybe<Core_Router>;
  /** insert data into the table: "core_securitycallouts" */
  insert_core_securitycallouts?: Maybe<Core_Securitycallouts_Mutation_Response>;
  /** insert a single row into the table: "core_securitycallouts" */
  insert_core_securitycallouts_one?: Maybe<Core_Securitycallouts>;
  /** insert data into the table: "core_securitycalloutsstatus" */
  insert_core_securitycalloutsstatus?: Maybe<Core_Securitycalloutsstatus_Mutation_Response>;
  /** insert a single row into the table: "core_securitycalloutsstatus" */
  insert_core_securitycalloutsstatus_one?: Maybe<Core_Securitycalloutsstatus>;
  /** insert data into the table: "core_simcard" */
  insert_core_simcard?: Maybe<Core_Simcard_Mutation_Response>;
  /** insert a single row into the table: "core_simcard" */
  insert_core_simcard_one?: Maybe<Core_Simcard>;
  /** insert data into the table: "core_simcardstatus" */
  insert_core_simcardstatus?: Maybe<Core_Simcardstatus_Mutation_Response>;
  /** insert a single row into the table: "core_simcardstatus" */
  insert_core_simcardstatus_one?: Maybe<Core_Simcardstatus>;
  /** insert data into the table: "core_site" */
  insert_core_site?: Maybe<Core_Site_Mutation_Response>;
  /** insert a single row into the table: "core_site" */
  insert_core_site_one?: Maybe<Core_Site>;
  /** insert data into the table: "core_sitechargeestimate" */
  insert_core_sitechargeestimate?: Maybe<Core_Sitechargeestimate_Mutation_Response>;
  /** insert a single row into the table: "core_sitechargeestimate" */
  insert_core_sitechargeestimate_one?: Maybe<Core_Sitechargeestimate>;
  /** insert data into the table: "core_siteregion" */
  insert_core_siteregion?: Maybe<Core_Siteregion_Mutation_Response>;
  /** insert a single row into the table: "core_siteregion" */
  insert_core_siteregion_one?: Maybe<Core_Siteregion>;
  /** insert data into the table: "core_sitesecuritycoverage" */
  insert_core_sitesecuritycoverage?: Maybe<Core_Sitesecuritycoverage_Mutation_Response>;
  /** insert a single row into the table: "core_sitesecuritycoverage" */
  insert_core_sitesecuritycoverage_one?: Maybe<Core_Sitesecuritycoverage>;
  /** insert data into the table: "core_sitestatus" */
  insert_core_sitestatus?: Maybe<Core_Sitestatus_Mutation_Response>;
  /** insert a single row into the table: "core_sitestatus" */
  insert_core_sitestatus_one?: Maybe<Core_Sitestatus>;
  /** insert data into the table: "core_sitetype" */
  insert_core_sitetype?: Maybe<Core_Sitetype_Mutation_Response>;
  /** insert a single row into the table: "core_sitetype" */
  insert_core_sitetype_one?: Maybe<Core_Sitetype>;
  /** insert data into the table: "core_sundrycharge" */
  insert_core_sundrycharge?: Maybe<Core_Sundrycharge_Mutation_Response>;
  /** insert a single row into the table: "core_sundrycharge" */
  insert_core_sundrycharge_one?: Maybe<Core_Sundrycharge>;
  /** insert data into the table: "core_sundrychargetype" */
  insert_core_sundrychargetype?: Maybe<Core_Sundrychargetype_Mutation_Response>;
  /** insert a single row into the table: "core_sundrychargetype" */
  insert_core_sundrychargetype_one?: Maybe<Core_Sundrychargetype>;
  /** insert data into the table: "core_tariff" */
  insert_core_tariff?: Maybe<Core_Tariff_Mutation_Response>;
  /** insert a single row into the table: "core_tariff" */
  insert_core_tariff_one?: Maybe<Core_Tariff>;
  /** insert data into the table: "core_user" */
  insert_core_user?: Maybe<Core_User_Mutation_Response>;
  /** insert data into the table: "core_user_groups" */
  insert_core_user_groups?: Maybe<Core_User_Groups_Mutation_Response>;
  /** insert a single row into the table: "core_user_groups" */
  insert_core_user_groups_one?: Maybe<Core_User_Groups>;
  /** insert a single row into the table: "core_user" */
  insert_core_user_one?: Maybe<Core_User>;
  /** insert data into the table: "core_user_user_permissions" */
  insert_core_user_user_permissions?: Maybe<Core_User_User_Permissions_Mutation_Response>;
  /** insert a single row into the table: "core_user_user_permissions" */
  insert_core_user_user_permissions_one?: Maybe<Core_User_User_Permissions>;
  /** insert data into the table: "core_visit" */
  insert_core_visit?: Maybe<Core_Visit_Mutation_Response>;
  /** insert a single row into the table: "core_visit" */
  insert_core_visit_one?: Maybe<Core_Visit>;
  /** insert data into the table: "core_visitstatusenum" */
  insert_core_visitstatusenum?: Maybe<Core_Visitstatusenum_Mutation_Response>;
  /** insert a single row into the table: "core_visitstatusenum" */
  insert_core_visitstatusenum_one?: Maybe<Core_Visitstatusenum>;
  /** insert data into the table: "django_admin_log" */
  insert_django_admin_log?: Maybe<Django_Admin_Log_Mutation_Response>;
  /** insert a single row into the table: "django_admin_log" */
  insert_django_admin_log_one?: Maybe<Django_Admin_Log>;
  /** insert data into the table: "django_content_type" */
  insert_django_content_type?: Maybe<Django_Content_Type_Mutation_Response>;
  /** insert a single row into the table: "django_content_type" */
  insert_django_content_type_one?: Maybe<Django_Content_Type>;
  /** insert data into the table: "django_migrations" */
  insert_django_migrations?: Maybe<Django_Migrations_Mutation_Response>;
  /** insert a single row into the table: "django_migrations" */
  insert_django_migrations_one?: Maybe<Django_Migrations>;
  /** insert data into the table: "django_session" */
  insert_django_session?: Maybe<Django_Session_Mutation_Response>;
  /** insert a single row into the table: "django_session" */
  insert_django_session_one?: Maybe<Django_Session>;
  /** update data of the table: "auth_group" */
  update_auth_group?: Maybe<Auth_Group_Mutation_Response>;
  /** update single row of the table: "auth_group" */
  update_auth_group_by_pk?: Maybe<Auth_Group>;
  /** update multiples rows of table: "auth_group" */
  update_auth_group_many?: Maybe<Array<Maybe<Auth_Group_Mutation_Response>>>;
  /** update data of the table: "auth_group_permissions" */
  update_auth_group_permissions?: Maybe<Auth_Group_Permissions_Mutation_Response>;
  /** update single row of the table: "auth_group_permissions" */
  update_auth_group_permissions_by_pk?: Maybe<Auth_Group_Permissions>;
  /** update multiples rows of table: "auth_group_permissions" */
  update_auth_group_permissions_many?: Maybe<Array<Maybe<Auth_Group_Permissions_Mutation_Response>>>;
  /** update data of the table: "auth_permission" */
  update_auth_permission?: Maybe<Auth_Permission_Mutation_Response>;
  /** update single row of the table: "auth_permission" */
  update_auth_permission_by_pk?: Maybe<Auth_Permission>;
  /** update multiples rows of table: "auth_permission" */
  update_auth_permission_many?: Maybe<Array<Maybe<Auth_Permission_Mutation_Response>>>;
  /** update data of the table: "authtoken_token" */
  update_authtoken_token?: Maybe<Authtoken_Token_Mutation_Response>;
  /** update single row of the table: "authtoken_token" */
  update_authtoken_token_by_pk?: Maybe<Authtoken_Token>;
  /** update multiples rows of table: "authtoken_token" */
  update_authtoken_token_many?: Maybe<Array<Maybe<Authtoken_Token_Mutation_Response>>>;
  /** update data of the table: "core_agreement" */
  update_core_agreement?: Maybe<Core_Agreement_Mutation_Response>;
  /** update single row of the table: "core_agreement" */
  update_core_agreement_by_pk?: Maybe<Core_Agreement>;
  /** update multiples rows of table: "core_agreement" */
  update_core_agreement_many?: Maybe<Array<Maybe<Core_Agreement_Mutation_Response>>>;
  /** update data of the table: "core_box" */
  update_core_box?: Maybe<Core_Box_Mutation_Response>;
  /** update single row of the table: "core_box" */
  update_core_box_by_pk?: Maybe<Core_Box>;
  /** update multiples rows of table: "core_box" */
  update_core_box_many?: Maybe<Array<Maybe<Core_Box_Mutation_Response>>>;
  /** update data of the table: "core_boxstatus" */
  update_core_boxstatus?: Maybe<Core_Boxstatus_Mutation_Response>;
  /** update single row of the table: "core_boxstatus" */
  update_core_boxstatus_by_pk?: Maybe<Core_Boxstatus>;
  /** update multiples rows of table: "core_boxstatus" */
  update_core_boxstatus_many?: Maybe<Array<Maybe<Core_Boxstatus_Mutation_Response>>>;
  /** update data of the table: "core_cabin" */
  update_core_cabin?: Maybe<Core_Cabin_Mutation_Response>;
  /** update single row of the table: "core_cabin" */
  update_core_cabin_by_pk?: Maybe<Core_Cabin>;
  /** update multiples rows of table: "core_cabin" */
  update_core_cabin_many?: Maybe<Array<Maybe<Core_Cabin_Mutation_Response>>>;
  /** update data of the table: "core_camera" */
  update_core_camera?: Maybe<Core_Camera_Mutation_Response>;
  /** update single row of the table: "core_camera" */
  update_core_camera_by_pk?: Maybe<Core_Camera>;
  /** update multiples rows of table: "core_camera" */
  update_core_camera_many?: Maybe<Array<Maybe<Core_Camera_Mutation_Response>>>;
  /** update data of the table: "core_company" */
  update_core_company?: Maybe<Core_Company_Mutation_Response>;
  /** update single row of the table: "core_company" */
  update_core_company_by_pk?: Maybe<Core_Company>;
  /** update multiples rows of table: "core_company" */
  update_core_company_many?: Maybe<Array<Maybe<Core_Company_Mutation_Response>>>;
  /** update data of the table: "core_companysitechargeestimate" */
  update_core_companysitechargeestimate?: Maybe<Core_Companysitechargeestimate_Mutation_Response>;
  /** update single row of the table: "core_companysitechargeestimate" */
  update_core_companysitechargeestimate_by_pk?: Maybe<Core_Companysitechargeestimate>;
  /** update multiples rows of table: "core_companysitechargeestimate" */
  update_core_companysitechargeestimate_many?: Maybe<Array<Maybe<Core_Companysitechargeestimate_Mutation_Response>>>;
  /** update data of the table: "core_customertype" */
  update_core_customertype?: Maybe<Core_Customertype_Mutation_Response>;
  /** update single row of the table: "core_customertype" */
  update_core_customertype_by_pk?: Maybe<Core_Customertype>;
  /** update multiples rows of table: "core_customertype" */
  update_core_customertype_many?: Maybe<Array<Maybe<Core_Customertype_Mutation_Response>>>;
  /** update data of the table: "core_disruptions" */
  update_core_disruptions?: Maybe<Core_Disruptions_Mutation_Response>;
  /** update single row of the table: "core_disruptions" */
  update_core_disruptions_by_pk?: Maybe<Core_Disruptions>;
  /** update multiples rows of table: "core_disruptions" */
  update_core_disruptions_many?: Maybe<Array<Maybe<Core_Disruptions_Mutation_Response>>>;
  /** update data of the table: "core_disruptionstype" */
  update_core_disruptionstype?: Maybe<Core_Disruptionstype_Mutation_Response>;
  /** update single row of the table: "core_disruptionstype" */
  update_core_disruptionstype_by_pk?: Maybe<Core_Disruptionstype>;
  /** update multiples rows of table: "core_disruptionstype" */
  update_core_disruptionstype_many?: Maybe<Array<Maybe<Core_Disruptionstype_Mutation_Response>>>;
  /** update data of the table: "core_energycharge" */
  update_core_energycharge?: Maybe<Core_Energycharge_Mutation_Response>;
  /** update single row of the table: "core_energycharge" */
  update_core_energycharge_by_pk?: Maybe<Core_Energycharge>;
  /** update multiples rows of table: "core_energycharge" */
  update_core_energycharge_many?: Maybe<Array<Maybe<Core_Energycharge_Mutation_Response>>>;
  /** update data of the table: "core_equipment" */
  update_core_equipment?: Maybe<Core_Equipment_Mutation_Response>;
  /** update single row of the table: "core_equipment" */
  update_core_equipment_by_pk?: Maybe<Core_Equipment>;
  /** update multiples rows of table: "core_equipment" */
  update_core_equipment_many?: Maybe<Array<Maybe<Core_Equipment_Mutation_Response>>>;
  /** update data of the table: "core_equipmentcondition" */
  update_core_equipmentcondition?: Maybe<Core_Equipmentcondition_Mutation_Response>;
  /** update single row of the table: "core_equipmentcondition" */
  update_core_equipmentcondition_by_pk?: Maybe<Core_Equipmentcondition>;
  /** update multiples rows of table: "core_equipmentcondition" */
  update_core_equipmentcondition_many?: Maybe<Array<Maybe<Core_Equipmentcondition_Mutation_Response>>>;
  /** update data of the table: "core_equipmentimage" */
  update_core_equipmentimage?: Maybe<Core_Equipmentimage_Mutation_Response>;
  /** update single row of the table: "core_equipmentimage" */
  update_core_equipmentimage_by_pk?: Maybe<Core_Equipmentimage>;
  /** update multiples rows of table: "core_equipmentimage" */
  update_core_equipmentimage_many?: Maybe<Array<Maybe<Core_Equipmentimage_Mutation_Response>>>;
  /** update data of the table: "core_equipmentmake" */
  update_core_equipmentmake?: Maybe<Core_Equipmentmake_Mutation_Response>;
  /** update single row of the table: "core_equipmentmake" */
  update_core_equipmentmake_by_pk?: Maybe<Core_Equipmentmake>;
  /** update multiples rows of table: "core_equipmentmake" */
  update_core_equipmentmake_many?: Maybe<Array<Maybe<Core_Equipmentmake_Mutation_Response>>>;
  /** update data of the table: "core_equipmentmodel" */
  update_core_equipmentmodel?: Maybe<Core_Equipmentmodel_Mutation_Response>;
  /** update single row of the table: "core_equipmentmodel" */
  update_core_equipmentmodel_by_pk?: Maybe<Core_Equipmentmodel>;
  /** update multiples rows of table: "core_equipmentmodel" */
  update_core_equipmentmodel_many?: Maybe<Array<Maybe<Core_Equipmentmodel_Mutation_Response>>>;
  /** update data of the table: "core_equipmentpart" */
  update_core_equipmentpart?: Maybe<Core_Equipmentpart_Mutation_Response>;
  /** update single row of the table: "core_equipmentpart" */
  update_core_equipmentpart_by_pk?: Maybe<Core_Equipmentpart>;
  /** update multiples rows of table: "core_equipmentpart" */
  update_core_equipmentpart_many?: Maybe<Array<Maybe<Core_Equipmentpart_Mutation_Response>>>;
  /** update data of the table: "core_equipmentstatus" */
  update_core_equipmentstatus?: Maybe<Core_Equipmentstatus_Mutation_Response>;
  /** update single row of the table: "core_equipmentstatus" */
  update_core_equipmentstatus_by_pk?: Maybe<Core_Equipmentstatus>;
  /** update multiples rows of table: "core_equipmentstatus" */
  update_core_equipmentstatus_many?: Maybe<Array<Maybe<Core_Equipmentstatus_Mutation_Response>>>;
  /** update data of the table: "core_equipmenttype" */
  update_core_equipmenttype?: Maybe<Core_Equipmenttype_Mutation_Response>;
  /** update single row of the table: "core_equipmenttype" */
  update_core_equipmenttype_by_pk?: Maybe<Core_Equipmenttype>;
  /** update multiples rows of table: "core_equipmenttype" */
  update_core_equipmenttype_many?: Maybe<Array<Maybe<Core_Equipmenttype_Mutation_Response>>>;
  /** update data of the table: "core_esp" */
  update_core_esp?: Maybe<Core_Esp_Mutation_Response>;
  /** update single row of the table: "core_esp" */
  update_core_esp_by_pk?: Maybe<Core_Esp>;
  /** update multiples rows of table: "core_esp" */
  update_core_esp_many?: Maybe<Array<Maybe<Core_Esp_Mutation_Response>>>;
  /** update data of the table: "core_espstatus" */
  update_core_espstatus?: Maybe<Core_Espstatus_Mutation_Response>;
  /** update single row of the table: "core_espstatus" */
  update_core_espstatus_by_pk?: Maybe<Core_Espstatus>;
  /** update multiples rows of table: "core_espstatus" */
  update_core_espstatus_many?: Maybe<Array<Maybe<Core_Espstatus_Mutation_Response>>>;
  /** update data of the table: "core_espstatusnextstages" */
  update_core_espstatusnextstages?: Maybe<Core_Espstatusnextstages_Mutation_Response>;
  /** update single row of the table: "core_espstatusnextstages" */
  update_core_espstatusnextstages_by_pk?: Maybe<Core_Espstatusnextstages>;
  /** update multiples rows of table: "core_espstatusnextstages" */
  update_core_espstatusnextstages_many?: Maybe<Array<Maybe<Core_Espstatusnextstages_Mutation_Response>>>;
  /** update data of the table: "core_eventlog" */
  update_core_eventlog?: Maybe<Core_Eventlog_Mutation_Response>;
  /** update single row of the table: "core_eventlog" */
  update_core_eventlog_by_pk?: Maybe<Core_Eventlog>;
  /** update multiples rows of table: "core_eventlog" */
  update_core_eventlog_many?: Maybe<Array<Maybe<Core_Eventlog_Mutation_Response>>>;
  /** update data of the table: "core_incident" */
  update_core_incident?: Maybe<Core_Incident_Mutation_Response>;
  /** update single row of the table: "core_incident" */
  update_core_incident_by_pk?: Maybe<Core_Incident>;
  /** update multiples rows of table: "core_incident" */
  update_core_incident_many?: Maybe<Array<Maybe<Core_Incident_Mutation_Response>>>;
  /** update data of the table: "core_job" */
  update_core_job?: Maybe<Core_Job_Mutation_Response>;
  /** update single row of the table: "core_job" */
  update_core_job_by_pk?: Maybe<Core_Job>;
  /** update multiples rows of table: "core_job" */
  update_core_job_many?: Maybe<Array<Maybe<Core_Job_Mutation_Response>>>;
  /** update data of the table: "core_jobstatus" */
  update_core_jobstatus?: Maybe<Core_Jobstatus_Mutation_Response>;
  /** update single row of the table: "core_jobstatus" */
  update_core_jobstatus_by_pk?: Maybe<Core_Jobstatus>;
  /** update multiples rows of table: "core_jobstatus" */
  update_core_jobstatus_many?: Maybe<Array<Maybe<Core_Jobstatus_Mutation_Response>>>;
  /** update data of the table: "core_jobstatusenum" */
  update_core_jobstatusenum?: Maybe<Core_Jobstatusenum_Mutation_Response>;
  /** update single row of the table: "core_jobstatusenum" */
  update_core_jobstatusenum_by_pk?: Maybe<Core_Jobstatusenum>;
  /** update multiples rows of table: "core_jobstatusenum" */
  update_core_jobstatusenum_many?: Maybe<Array<Maybe<Core_Jobstatusenum_Mutation_Response>>>;
  /** update data of the table: "core_log" */
  update_core_log?: Maybe<Core_Log_Mutation_Response>;
  /** update single row of the table: "core_log" */
  update_core_log_by_pk?: Maybe<Core_Log>;
  /** update multiples rows of table: "core_log" */
  update_core_log_many?: Maybe<Array<Maybe<Core_Log_Mutation_Response>>>;
  /** update data of the table: "core_logfacialrecognition" */
  update_core_logfacialrecognition?: Maybe<Core_Logfacialrecognition_Mutation_Response>;
  /** update single row of the table: "core_logfacialrecognition" */
  update_core_logfacialrecognition_by_pk?: Maybe<Core_Logfacialrecognition>;
  /** update multiples rows of table: "core_logfacialrecognition" */
  update_core_logfacialrecognition_many?: Maybe<Array<Maybe<Core_Logfacialrecognition_Mutation_Response>>>;
  /** update data of the table: "core_logimage" */
  update_core_logimage?: Maybe<Core_Logimage_Mutation_Response>;
  /** update single row of the table: "core_logimage" */
  update_core_logimage_by_pk?: Maybe<Core_Logimage>;
  /** update multiples rows of table: "core_logimage" */
  update_core_logimage_many?: Maybe<Array<Maybe<Core_Logimage_Mutation_Response>>>;
  /** update data of the table: "core_logsuspiciosactivity" */
  update_core_logsuspiciosactivity?: Maybe<Core_Logsuspiciosactivity_Mutation_Response>;
  /** update single row of the table: "core_logsuspiciosactivity" */
  update_core_logsuspiciosactivity_by_pk?: Maybe<Core_Logsuspiciosactivity>;
  /** update multiples rows of table: "core_logsuspiciosactivity" */
  update_core_logsuspiciosactivity_many?: Maybe<Array<Maybe<Core_Logsuspiciosactivity_Mutation_Response>>>;
  /** update data of the table: "core_meter" */
  update_core_meter?: Maybe<Core_Meter_Mutation_Response>;
  /** update single row of the table: "core_meter" */
  update_core_meter_by_pk?: Maybe<Core_Meter>;
  /** update multiples rows of table: "core_meter" */
  update_core_meter_many?: Maybe<Array<Maybe<Core_Meter_Mutation_Response>>>;
  /** update data of the table: "core_meterreading" */
  update_core_meterreading?: Maybe<Core_Meterreading_Mutation_Response>;
  /** update single row of the table: "core_meterreading" */
  update_core_meterreading_by_pk?: Maybe<Core_Meterreading>;
  /** update multiples rows of table: "core_meterreading" */
  update_core_meterreading_many?: Maybe<Array<Maybe<Core_Meterreading_Mutation_Response>>>;
  /** update data of the table: "core_metric" */
  update_core_metric?: Maybe<Core_Metric_Mutation_Response>;
  /** update single row of the table: "core_metric" */
  update_core_metric_by_pk?: Maybe<Core_Metric>;
  /** update multiples rows of table: "core_metric" */
  update_core_metric_many?: Maybe<Array<Maybe<Core_Metric_Mutation_Response>>>;
  /** update data of the table: "core_municipality" */
  update_core_municipality?: Maybe<Core_Municipality_Mutation_Response>;
  /** update single row of the table: "core_municipality" */
  update_core_municipality_by_pk?: Maybe<Core_Municipality>;
  /** update multiples rows of table: "core_municipality" */
  update_core_municipality_many?: Maybe<Array<Maybe<Core_Municipality_Mutation_Response>>>;
  /** update data of the table: "core_noneuserpersonale" */
  update_core_noneuserpersonale?: Maybe<Core_Noneuserpersonale_Mutation_Response>;
  /** update single row of the table: "core_noneuserpersonale" */
  update_core_noneuserpersonale_by_pk?: Maybe<Core_Noneuserpersonale>;
  /** update multiples rows of table: "core_noneuserpersonale" */
  update_core_noneuserpersonale_many?: Maybe<Array<Maybe<Core_Noneuserpersonale_Mutation_Response>>>;
  /** update data of the table: "core_paymenttype" */
  update_core_paymenttype?: Maybe<Core_Paymenttype_Mutation_Response>;
  /** update single row of the table: "core_paymenttype" */
  update_core_paymenttype_by_pk?: Maybe<Core_Paymenttype>;
  /** update multiples rows of table: "core_paymenttype" */
  update_core_paymenttype_many?: Maybe<Array<Maybe<Core_Paymenttype_Mutation_Response>>>;
  /** update data of the table: "core_personaleimage" */
  update_core_personaleimage?: Maybe<Core_Personaleimage_Mutation_Response>;
  /** update single row of the table: "core_personaleimage" */
  update_core_personaleimage_by_pk?: Maybe<Core_Personaleimage>;
  /** update multiples rows of table: "core_personaleimage" */
  update_core_personaleimage_many?: Maybe<Array<Maybe<Core_Personaleimage_Mutation_Response>>>;
  /** update data of the table: "core_personnelassigned" */
  update_core_personnelassigned?: Maybe<Core_Personnelassigned_Mutation_Response>;
  /** update single row of the table: "core_personnelassigned" */
  update_core_personnelassigned_by_pk?: Maybe<Core_Personnelassigned>;
  /** update multiples rows of table: "core_personnelassigned" */
  update_core_personnelassigned_many?: Maybe<Array<Maybe<Core_Personnelassigned_Mutation_Response>>>;
  /** update data of the table: "core_powerprovider" */
  update_core_powerprovider?: Maybe<Core_Powerprovider_Mutation_Response>;
  /** update single row of the table: "core_powerprovider" */
  update_core_powerprovider_by_pk?: Maybe<Core_Powerprovider>;
  /** update multiples rows of table: "core_powerprovider" */
  update_core_powerprovider_many?: Maybe<Array<Maybe<Core_Powerprovider_Mutation_Response>>>;
  /** update data of the table: "core_powersourcetype" */
  update_core_powersourcetype?: Maybe<Core_Powersourcetype_Mutation_Response>;
  /** update single row of the table: "core_powersourcetype" */
  update_core_powersourcetype_by_pk?: Maybe<Core_Powersourcetype>;
  /** update multiples rows of table: "core_powersourcetype" */
  update_core_powersourcetype_many?: Maybe<Array<Maybe<Core_Powersourcetype_Mutation_Response>>>;
  /** update data of the table: "core_province" */
  update_core_province?: Maybe<Core_Province_Mutation_Response>;
  /** update single row of the table: "core_province" */
  update_core_province_by_pk?: Maybe<Core_Province>;
  /** update multiples rows of table: "core_province" */
  update_core_province_many?: Maybe<Array<Maybe<Core_Province_Mutation_Response>>>;
  /** update data of the table: "core_rectifieralarm" */
  update_core_rectifieralarm?: Maybe<Core_Rectifieralarm_Mutation_Response>;
  /** update single row of the table: "core_rectifieralarm" */
  update_core_rectifieralarm_by_pk?: Maybe<Core_Rectifieralarm>;
  /** update multiples rows of table: "core_rectifieralarm" */
  update_core_rectifieralarm_many?: Maybe<Array<Maybe<Core_Rectifieralarm_Mutation_Response>>>;
  /** update data of the table: "core_requestaccess" */
  update_core_requestaccess?: Maybe<Core_Requestaccess_Mutation_Response>;
  /** update single row of the table: "core_requestaccess" */
  update_core_requestaccess_by_pk?: Maybe<Core_Requestaccess>;
  /** update multiples rows of table: "core_requestaccess" */
  update_core_requestaccess_many?: Maybe<Array<Maybe<Core_Requestaccess_Mutation_Response>>>;
  /** update data of the table: "core_res_company_wide_charge_estimate" */
  update_core_res_company_wide_charge_estimate?: Maybe<Core_Res_Company_Wide_Charge_Estimate_Mutation_Response>;
  /** update multiples rows of table: "core_res_company_wide_charge_estimate" */
  update_core_res_company_wide_charge_estimate_many?: Maybe<Array<Maybe<Core_Res_Company_Wide_Charge_Estimate_Mutation_Response>>>;
  /** update data of the table: "core_resjobstatus" */
  update_core_resjobstatus?: Maybe<Core_Resjobstatus_Mutation_Response>;
  /** update single row of the table: "core_resjobstatus" */
  update_core_resjobstatus_by_pk?: Maybe<Core_Resjobstatus>;
  /** update multiples rows of table: "core_resjobstatus" */
  update_core_resjobstatus_many?: Maybe<Array<Maybe<Core_Resjobstatus_Mutation_Response>>>;
  /** update data of the table: "core_role" */
  update_core_role?: Maybe<Core_Role_Mutation_Response>;
  /** update single row of the table: "core_role" */
  update_core_role_by_pk?: Maybe<Core_Role>;
  /** update multiples rows of table: "core_role" */
  update_core_role_many?: Maybe<Array<Maybe<Core_Role_Mutation_Response>>>;
  /** update data of the table: "core_router" */
  update_core_router?: Maybe<Core_Router_Mutation_Response>;
  /** update single row of the table: "core_router" */
  update_core_router_by_pk?: Maybe<Core_Router>;
  /** update multiples rows of table: "core_router" */
  update_core_router_many?: Maybe<Array<Maybe<Core_Router_Mutation_Response>>>;
  /** update data of the table: "core_securitycallouts" */
  update_core_securitycallouts?: Maybe<Core_Securitycallouts_Mutation_Response>;
  /** update single row of the table: "core_securitycallouts" */
  update_core_securitycallouts_by_pk?: Maybe<Core_Securitycallouts>;
  /** update multiples rows of table: "core_securitycallouts" */
  update_core_securitycallouts_many?: Maybe<Array<Maybe<Core_Securitycallouts_Mutation_Response>>>;
  /** update data of the table: "core_securitycalloutsstatus" */
  update_core_securitycalloutsstatus?: Maybe<Core_Securitycalloutsstatus_Mutation_Response>;
  /** update single row of the table: "core_securitycalloutsstatus" */
  update_core_securitycalloutsstatus_by_pk?: Maybe<Core_Securitycalloutsstatus>;
  /** update multiples rows of table: "core_securitycalloutsstatus" */
  update_core_securitycalloutsstatus_many?: Maybe<Array<Maybe<Core_Securitycalloutsstatus_Mutation_Response>>>;
  /** update data of the table: "core_simcard" */
  update_core_simcard?: Maybe<Core_Simcard_Mutation_Response>;
  /** update single row of the table: "core_simcard" */
  update_core_simcard_by_pk?: Maybe<Core_Simcard>;
  /** update multiples rows of table: "core_simcard" */
  update_core_simcard_many?: Maybe<Array<Maybe<Core_Simcard_Mutation_Response>>>;
  /** update data of the table: "core_simcardstatus" */
  update_core_simcardstatus?: Maybe<Core_Simcardstatus_Mutation_Response>;
  /** update single row of the table: "core_simcardstatus" */
  update_core_simcardstatus_by_pk?: Maybe<Core_Simcardstatus>;
  /** update multiples rows of table: "core_simcardstatus" */
  update_core_simcardstatus_many?: Maybe<Array<Maybe<Core_Simcardstatus_Mutation_Response>>>;
  /** update data of the table: "core_site" */
  update_core_site?: Maybe<Core_Site_Mutation_Response>;
  /** update single row of the table: "core_site" */
  update_core_site_by_pk?: Maybe<Core_Site>;
  /** update multiples rows of table: "core_site" */
  update_core_site_many?: Maybe<Array<Maybe<Core_Site_Mutation_Response>>>;
  /** update data of the table: "core_sitechargeestimate" */
  update_core_sitechargeestimate?: Maybe<Core_Sitechargeestimate_Mutation_Response>;
  /** update single row of the table: "core_sitechargeestimate" */
  update_core_sitechargeestimate_by_pk?: Maybe<Core_Sitechargeestimate>;
  /** update multiples rows of table: "core_sitechargeestimate" */
  update_core_sitechargeestimate_many?: Maybe<Array<Maybe<Core_Sitechargeestimate_Mutation_Response>>>;
  /** update data of the table: "core_siteregion" */
  update_core_siteregion?: Maybe<Core_Siteregion_Mutation_Response>;
  /** update single row of the table: "core_siteregion" */
  update_core_siteregion_by_pk?: Maybe<Core_Siteregion>;
  /** update multiples rows of table: "core_siteregion" */
  update_core_siteregion_many?: Maybe<Array<Maybe<Core_Siteregion_Mutation_Response>>>;
  /** update data of the table: "core_sitesecuritycoverage" */
  update_core_sitesecuritycoverage?: Maybe<Core_Sitesecuritycoverage_Mutation_Response>;
  /** update single row of the table: "core_sitesecuritycoverage" */
  update_core_sitesecuritycoverage_by_pk?: Maybe<Core_Sitesecuritycoverage>;
  /** update multiples rows of table: "core_sitesecuritycoverage" */
  update_core_sitesecuritycoverage_many?: Maybe<Array<Maybe<Core_Sitesecuritycoverage_Mutation_Response>>>;
  /** update data of the table: "core_sitestatus" */
  update_core_sitestatus?: Maybe<Core_Sitestatus_Mutation_Response>;
  /** update single row of the table: "core_sitestatus" */
  update_core_sitestatus_by_pk?: Maybe<Core_Sitestatus>;
  /** update multiples rows of table: "core_sitestatus" */
  update_core_sitestatus_many?: Maybe<Array<Maybe<Core_Sitestatus_Mutation_Response>>>;
  /** update data of the table: "core_sitetype" */
  update_core_sitetype?: Maybe<Core_Sitetype_Mutation_Response>;
  /** update single row of the table: "core_sitetype" */
  update_core_sitetype_by_pk?: Maybe<Core_Sitetype>;
  /** update multiples rows of table: "core_sitetype" */
  update_core_sitetype_many?: Maybe<Array<Maybe<Core_Sitetype_Mutation_Response>>>;
  /** update data of the table: "core_sundrycharge" */
  update_core_sundrycharge?: Maybe<Core_Sundrycharge_Mutation_Response>;
  /** update single row of the table: "core_sundrycharge" */
  update_core_sundrycharge_by_pk?: Maybe<Core_Sundrycharge>;
  /** update multiples rows of table: "core_sundrycharge" */
  update_core_sundrycharge_many?: Maybe<Array<Maybe<Core_Sundrycharge_Mutation_Response>>>;
  /** update data of the table: "core_sundrychargetype" */
  update_core_sundrychargetype?: Maybe<Core_Sundrychargetype_Mutation_Response>;
  /** update single row of the table: "core_sundrychargetype" */
  update_core_sundrychargetype_by_pk?: Maybe<Core_Sundrychargetype>;
  /** update multiples rows of table: "core_sundrychargetype" */
  update_core_sundrychargetype_many?: Maybe<Array<Maybe<Core_Sundrychargetype_Mutation_Response>>>;
  /** update data of the table: "core_tariff" */
  update_core_tariff?: Maybe<Core_Tariff_Mutation_Response>;
  /** update single row of the table: "core_tariff" */
  update_core_tariff_by_pk?: Maybe<Core_Tariff>;
  /** update multiples rows of table: "core_tariff" */
  update_core_tariff_many?: Maybe<Array<Maybe<Core_Tariff_Mutation_Response>>>;
  /** update data of the table: "core_user" */
  update_core_user?: Maybe<Core_User_Mutation_Response>;
  /** update single row of the table: "core_user" */
  update_core_user_by_pk?: Maybe<Core_User>;
  /** update data of the table: "core_user_groups" */
  update_core_user_groups?: Maybe<Core_User_Groups_Mutation_Response>;
  /** update single row of the table: "core_user_groups" */
  update_core_user_groups_by_pk?: Maybe<Core_User_Groups>;
  /** update multiples rows of table: "core_user_groups" */
  update_core_user_groups_many?: Maybe<Array<Maybe<Core_User_Groups_Mutation_Response>>>;
  /** update multiples rows of table: "core_user" */
  update_core_user_many?: Maybe<Array<Maybe<Core_User_Mutation_Response>>>;
  /** update data of the table: "core_user_user_permissions" */
  update_core_user_user_permissions?: Maybe<Core_User_User_Permissions_Mutation_Response>;
  /** update single row of the table: "core_user_user_permissions" */
  update_core_user_user_permissions_by_pk?: Maybe<Core_User_User_Permissions>;
  /** update multiples rows of table: "core_user_user_permissions" */
  update_core_user_user_permissions_many?: Maybe<Array<Maybe<Core_User_User_Permissions_Mutation_Response>>>;
  /** update data of the table: "core_visit" */
  update_core_visit?: Maybe<Core_Visit_Mutation_Response>;
  /** update single row of the table: "core_visit" */
  update_core_visit_by_pk?: Maybe<Core_Visit>;
  /** update multiples rows of table: "core_visit" */
  update_core_visit_many?: Maybe<Array<Maybe<Core_Visit_Mutation_Response>>>;
  /** update data of the table: "core_visitstatusenum" */
  update_core_visitstatusenum?: Maybe<Core_Visitstatusenum_Mutation_Response>;
  /** update single row of the table: "core_visitstatusenum" */
  update_core_visitstatusenum_by_pk?: Maybe<Core_Visitstatusenum>;
  /** update multiples rows of table: "core_visitstatusenum" */
  update_core_visitstatusenum_many?: Maybe<Array<Maybe<Core_Visitstatusenum_Mutation_Response>>>;
  /** update data of the table: "django_admin_log" */
  update_django_admin_log?: Maybe<Django_Admin_Log_Mutation_Response>;
  /** update single row of the table: "django_admin_log" */
  update_django_admin_log_by_pk?: Maybe<Django_Admin_Log>;
  /** update multiples rows of table: "django_admin_log" */
  update_django_admin_log_many?: Maybe<Array<Maybe<Django_Admin_Log_Mutation_Response>>>;
  /** update data of the table: "django_content_type" */
  update_django_content_type?: Maybe<Django_Content_Type_Mutation_Response>;
  /** update single row of the table: "django_content_type" */
  update_django_content_type_by_pk?: Maybe<Django_Content_Type>;
  /** update multiples rows of table: "django_content_type" */
  update_django_content_type_many?: Maybe<Array<Maybe<Django_Content_Type_Mutation_Response>>>;
  /** update data of the table: "django_migrations" */
  update_django_migrations?: Maybe<Django_Migrations_Mutation_Response>;
  /** update single row of the table: "django_migrations" */
  update_django_migrations_by_pk?: Maybe<Django_Migrations>;
  /** update multiples rows of table: "django_migrations" */
  update_django_migrations_many?: Maybe<Array<Maybe<Django_Migrations_Mutation_Response>>>;
  /** update data of the table: "django_session" */
  update_django_session?: Maybe<Django_Session_Mutation_Response>;
  /** update single row of the table: "django_session" */
  update_django_session_by_pk?: Maybe<Django_Session>;
  /** update multiples rows of table: "django_session" */
  update_django_session_many?: Maybe<Array<Maybe<Django_Session_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_Auth_GroupArgs = {
  where: Auth_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Group_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Group_PermissionsArgs = {
  where: Auth_Group_Permissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Group_Permissions_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_PermissionArgs = {
  where: Auth_Permission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Permission_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Authtoken_TokenArgs = {
  where: Authtoken_Token_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Authtoken_Token_By_PkArgs = {
  key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Core_AgreementArgs = {
  where: Core_Agreement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Agreement_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_BoxArgs = {
  where: Core_Box_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Box_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_BoxstatusArgs = {
  where: Core_Boxstatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Boxstatus_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_CabinArgs = {
  where: Core_Cabin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Cabin_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_CameraArgs = {
  where: Core_Camera_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Camera_By_PkArgs = {
  camera_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Core_CompanyArgs = {
  where: Core_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Company_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_CompanysitechargeestimateArgs = {
  where: Core_Companysitechargeestimate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Companysitechargeestimate_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_CustomertypeArgs = {
  where: Core_Customertype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Customertype_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_DisruptionsArgs = {
  where: Core_Disruptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Disruptions_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_DisruptionstypeArgs = {
  where: Core_Disruptionstype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Disruptionstype_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_EnergychargeArgs = {
  where: Core_Energycharge_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Energycharge_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_EquipmentArgs = {
  where: Core_Equipment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Equipment_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_EquipmentconditionArgs = {
  where: Core_Equipmentcondition_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Equipmentcondition_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_EquipmentimageArgs = {
  where: Core_Equipmentimage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Equipmentimage_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_EquipmentmakeArgs = {
  where: Core_Equipmentmake_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Equipmentmake_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_EquipmentmodelArgs = {
  where: Core_Equipmentmodel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Equipmentmodel_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_EquipmentpartArgs = {
  where: Core_Equipmentpart_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Equipmentpart_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_EquipmentstatusArgs = {
  where: Core_Equipmentstatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Equipmentstatus_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_EquipmenttypeArgs = {
  where: Core_Equipmenttype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Equipmenttype_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_EspArgs = {
  where: Core_Esp_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Esp_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_EspstatusArgs = {
  where: Core_Espstatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Espstatus_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_EspstatusnextstagesArgs = {
  where: Core_Espstatusnextstages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Espstatusnextstages_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_EventlogArgs = {
  where: Core_Eventlog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Eventlog_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_IncidentArgs = {
  where: Core_Incident_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Incident_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_JobArgs = {
  where: Core_Job_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Job_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_JobstatusArgs = {
  where: Core_Jobstatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Jobstatus_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_JobstatusenumArgs = {
  where: Core_Jobstatusenum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Jobstatusenum_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_LogArgs = {
  where: Core_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Log_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_LogfacialrecognitionArgs = {
  where: Core_Logfacialrecognition_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Logfacialrecognition_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_LogimageArgs = {
  where: Core_Logimage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Logimage_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_LogsuspiciosactivityArgs = {
  where: Core_Logsuspiciosactivity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Logsuspiciosactivity_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_MeterArgs = {
  where: Core_Meter_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Meter_By_PkArgs = {
  serial_number: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Core_MeterreadingArgs = {
  where: Core_Meterreading_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Meterreading_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_MetricArgs = {
  where: Core_Metric_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Metric_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_MunicipalityArgs = {
  where: Core_Municipality_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Municipality_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_NoneuserpersonaleArgs = {
  where: Core_Noneuserpersonale_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Noneuserpersonale_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Core_PaymenttypeArgs = {
  where: Core_Paymenttype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Paymenttype_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_PersonaleimageArgs = {
  where: Core_Personaleimage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Personaleimage_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_PersonnelassignedArgs = {
  where: Core_Personnelassigned_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Personnelassigned_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_PowerproviderArgs = {
  where: Core_Powerprovider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Powerprovider_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_PowersourcetypeArgs = {
  where: Core_Powersourcetype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Powersourcetype_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_ProvinceArgs = {
  where: Core_Province_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Province_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_RectifieralarmArgs = {
  where: Core_Rectifieralarm_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Rectifieralarm_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_RequestaccessArgs = {
  where: Core_Requestaccess_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Requestaccess_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_Res_Company_Wide_Charge_EstimateArgs = {
  where: Core_Res_Company_Wide_Charge_Estimate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_ResjobstatusArgs = {
  where: Core_Resjobstatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Resjobstatus_By_PkArgs = {
  job_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_RoleArgs = {
  where: Core_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Role_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_RouterArgs = {
  where: Core_Router_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Router_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_SecuritycalloutsArgs = {
  where: Core_Securitycallouts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Securitycallouts_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_SecuritycalloutsstatusArgs = {
  where: Core_Securitycalloutsstatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Securitycalloutsstatus_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_SimcardArgs = {
  where: Core_Simcard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Simcard_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_SimcardstatusArgs = {
  where: Core_Simcardstatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Simcardstatus_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_SiteArgs = {
  where: Core_Site_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Site_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_SitechargeestimateArgs = {
  where: Core_Sitechargeestimate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Sitechargeestimate_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_SiteregionArgs = {
  where: Core_Siteregion_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Siteregion_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_SitesecuritycoverageArgs = {
  where: Core_Sitesecuritycoverage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Sitesecuritycoverage_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_SitestatusArgs = {
  where: Core_Sitestatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Sitestatus_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_SitetypeArgs = {
  where: Core_Sitetype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Sitetype_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_SundrychargeArgs = {
  where: Core_Sundrycharge_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Sundrycharge_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_SundrychargetypeArgs = {
  where: Core_Sundrychargetype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Sundrychargetype_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_TariffArgs = {
  where: Core_Tariff_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Tariff_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_UserArgs = {
  where: Core_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_User_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_User_GroupsArgs = {
  where: Core_User_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_User_Groups_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_User_User_PermissionsArgs = {
  where: Core_User_User_Permissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_User_User_Permissions_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_VisitArgs = {
  where: Core_Visit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Visit_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Core_VisitstatusenumArgs = {
  where: Core_Visitstatusenum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_Visitstatusenum_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Django_Admin_LogArgs = {
  where: Django_Admin_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Django_Admin_Log_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Django_Content_TypeArgs = {
  where: Django_Content_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Django_Content_Type_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Django_MigrationsArgs = {
  where: Django_Migrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Django_Migrations_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Django_SessionArgs = {
  where: Django_Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Django_Session_By_PkArgs = {
  session_key: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_Auth_GroupArgs = {
  objects: Array<Auth_Group_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Group_OneArgs = {
  object: Auth_Group_Insert_Input;
  on_conflict?: InputMaybe<Auth_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Group_PermissionsArgs = {
  objects: Array<Auth_Group_Permissions_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Group_Permissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Group_Permissions_OneArgs = {
  object: Auth_Group_Permissions_Insert_Input;
  on_conflict?: InputMaybe<Auth_Group_Permissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_PermissionArgs = {
  objects: Array<Auth_Permission_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Permission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Permission_OneArgs = {
  object: Auth_Permission_Insert_Input;
  on_conflict?: InputMaybe<Auth_Permission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Authtoken_TokenArgs = {
  objects: Array<Authtoken_Token_Insert_Input>;
  on_conflict?: InputMaybe<Authtoken_Token_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Authtoken_Token_OneArgs = {
  object: Authtoken_Token_Insert_Input;
  on_conflict?: InputMaybe<Authtoken_Token_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_AgreementArgs = {
  objects: Array<Core_Agreement_Insert_Input>;
  on_conflict?: InputMaybe<Core_Agreement_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Agreement_OneArgs = {
  object: Core_Agreement_Insert_Input;
  on_conflict?: InputMaybe<Core_Agreement_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_BoxArgs = {
  objects: Array<Core_Box_Insert_Input>;
  on_conflict?: InputMaybe<Core_Box_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Box_OneArgs = {
  object: Core_Box_Insert_Input;
  on_conflict?: InputMaybe<Core_Box_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_BoxstatusArgs = {
  objects: Array<Core_Boxstatus_Insert_Input>;
  on_conflict?: InputMaybe<Core_Boxstatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Boxstatus_OneArgs = {
  object: Core_Boxstatus_Insert_Input;
  on_conflict?: InputMaybe<Core_Boxstatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_CabinArgs = {
  objects: Array<Core_Cabin_Insert_Input>;
  on_conflict?: InputMaybe<Core_Cabin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Cabin_OneArgs = {
  object: Core_Cabin_Insert_Input;
  on_conflict?: InputMaybe<Core_Cabin_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_CameraArgs = {
  objects: Array<Core_Camera_Insert_Input>;
  on_conflict?: InputMaybe<Core_Camera_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Camera_OneArgs = {
  object: Core_Camera_Insert_Input;
  on_conflict?: InputMaybe<Core_Camera_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_CompanyArgs = {
  objects: Array<Core_Company_Insert_Input>;
  on_conflict?: InputMaybe<Core_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Company_OneArgs = {
  object: Core_Company_Insert_Input;
  on_conflict?: InputMaybe<Core_Company_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_CompanysitechargeestimateArgs = {
  objects: Array<Core_Companysitechargeestimate_Insert_Input>;
  on_conflict?: InputMaybe<Core_Companysitechargeestimate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Companysitechargeestimate_OneArgs = {
  object: Core_Companysitechargeestimate_Insert_Input;
  on_conflict?: InputMaybe<Core_Companysitechargeestimate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_CustomertypeArgs = {
  objects: Array<Core_Customertype_Insert_Input>;
  on_conflict?: InputMaybe<Core_Customertype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Customertype_OneArgs = {
  object: Core_Customertype_Insert_Input;
  on_conflict?: InputMaybe<Core_Customertype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_DisruptionsArgs = {
  objects: Array<Core_Disruptions_Insert_Input>;
  on_conflict?: InputMaybe<Core_Disruptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Disruptions_OneArgs = {
  object: Core_Disruptions_Insert_Input;
  on_conflict?: InputMaybe<Core_Disruptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_DisruptionstypeArgs = {
  objects: Array<Core_Disruptionstype_Insert_Input>;
  on_conflict?: InputMaybe<Core_Disruptionstype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Disruptionstype_OneArgs = {
  object: Core_Disruptionstype_Insert_Input;
  on_conflict?: InputMaybe<Core_Disruptionstype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_EnergychargeArgs = {
  objects: Array<Core_Energycharge_Insert_Input>;
  on_conflict?: InputMaybe<Core_Energycharge_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Energycharge_OneArgs = {
  object: Core_Energycharge_Insert_Input;
  on_conflict?: InputMaybe<Core_Energycharge_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_EquipmentArgs = {
  objects: Array<Core_Equipment_Insert_Input>;
  on_conflict?: InputMaybe<Core_Equipment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Equipment_OneArgs = {
  object: Core_Equipment_Insert_Input;
  on_conflict?: InputMaybe<Core_Equipment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_EquipmentconditionArgs = {
  objects: Array<Core_Equipmentcondition_Insert_Input>;
  on_conflict?: InputMaybe<Core_Equipmentcondition_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Equipmentcondition_OneArgs = {
  object: Core_Equipmentcondition_Insert_Input;
  on_conflict?: InputMaybe<Core_Equipmentcondition_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_EquipmentimageArgs = {
  objects: Array<Core_Equipmentimage_Insert_Input>;
  on_conflict?: InputMaybe<Core_Equipmentimage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Equipmentimage_OneArgs = {
  object: Core_Equipmentimage_Insert_Input;
  on_conflict?: InputMaybe<Core_Equipmentimage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_EquipmentmakeArgs = {
  objects: Array<Core_Equipmentmake_Insert_Input>;
  on_conflict?: InputMaybe<Core_Equipmentmake_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Equipmentmake_OneArgs = {
  object: Core_Equipmentmake_Insert_Input;
  on_conflict?: InputMaybe<Core_Equipmentmake_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_EquipmentmodelArgs = {
  objects: Array<Core_Equipmentmodel_Insert_Input>;
  on_conflict?: InputMaybe<Core_Equipmentmodel_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Equipmentmodel_OneArgs = {
  object: Core_Equipmentmodel_Insert_Input;
  on_conflict?: InputMaybe<Core_Equipmentmodel_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_EquipmentpartArgs = {
  objects: Array<Core_Equipmentpart_Insert_Input>;
  on_conflict?: InputMaybe<Core_Equipmentpart_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Equipmentpart_OneArgs = {
  object: Core_Equipmentpart_Insert_Input;
  on_conflict?: InputMaybe<Core_Equipmentpart_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_EquipmentstatusArgs = {
  objects: Array<Core_Equipmentstatus_Insert_Input>;
  on_conflict?: InputMaybe<Core_Equipmentstatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Equipmentstatus_OneArgs = {
  object: Core_Equipmentstatus_Insert_Input;
  on_conflict?: InputMaybe<Core_Equipmentstatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_EquipmenttypeArgs = {
  objects: Array<Core_Equipmenttype_Insert_Input>;
  on_conflict?: InputMaybe<Core_Equipmenttype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Equipmenttype_OneArgs = {
  object: Core_Equipmenttype_Insert_Input;
  on_conflict?: InputMaybe<Core_Equipmenttype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_EspArgs = {
  objects: Array<Core_Esp_Insert_Input>;
  on_conflict?: InputMaybe<Core_Esp_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Esp_OneArgs = {
  object: Core_Esp_Insert_Input;
  on_conflict?: InputMaybe<Core_Esp_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_EspstatusArgs = {
  objects: Array<Core_Espstatus_Insert_Input>;
  on_conflict?: InputMaybe<Core_Espstatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Espstatus_OneArgs = {
  object: Core_Espstatus_Insert_Input;
  on_conflict?: InputMaybe<Core_Espstatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_EspstatusnextstagesArgs = {
  objects: Array<Core_Espstatusnextstages_Insert_Input>;
  on_conflict?: InputMaybe<Core_Espstatusnextstages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Espstatusnextstages_OneArgs = {
  object: Core_Espstatusnextstages_Insert_Input;
  on_conflict?: InputMaybe<Core_Espstatusnextstages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_EventlogArgs = {
  objects: Array<Core_Eventlog_Insert_Input>;
  on_conflict?: InputMaybe<Core_Eventlog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Eventlog_OneArgs = {
  object: Core_Eventlog_Insert_Input;
  on_conflict?: InputMaybe<Core_Eventlog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_IncidentArgs = {
  objects: Array<Core_Incident_Insert_Input>;
  on_conflict?: InputMaybe<Core_Incident_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Incident_OneArgs = {
  object: Core_Incident_Insert_Input;
  on_conflict?: InputMaybe<Core_Incident_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_JobArgs = {
  objects: Array<Core_Job_Insert_Input>;
  on_conflict?: InputMaybe<Core_Job_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Job_OneArgs = {
  object: Core_Job_Insert_Input;
  on_conflict?: InputMaybe<Core_Job_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_JobstatusArgs = {
  objects: Array<Core_Jobstatus_Insert_Input>;
  on_conflict?: InputMaybe<Core_Jobstatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Jobstatus_OneArgs = {
  object: Core_Jobstatus_Insert_Input;
  on_conflict?: InputMaybe<Core_Jobstatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_JobstatusenumArgs = {
  objects: Array<Core_Jobstatusenum_Insert_Input>;
  on_conflict?: InputMaybe<Core_Jobstatusenum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Jobstatusenum_OneArgs = {
  object: Core_Jobstatusenum_Insert_Input;
  on_conflict?: InputMaybe<Core_Jobstatusenum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_LogArgs = {
  objects: Array<Core_Log_Insert_Input>;
  on_conflict?: InputMaybe<Core_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Log_OneArgs = {
  object: Core_Log_Insert_Input;
  on_conflict?: InputMaybe<Core_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_LogfacialrecognitionArgs = {
  objects: Array<Core_Logfacialrecognition_Insert_Input>;
  on_conflict?: InputMaybe<Core_Logfacialrecognition_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Logfacialrecognition_OneArgs = {
  object: Core_Logfacialrecognition_Insert_Input;
  on_conflict?: InputMaybe<Core_Logfacialrecognition_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_LogimageArgs = {
  objects: Array<Core_Logimage_Insert_Input>;
  on_conflict?: InputMaybe<Core_Logimage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Logimage_OneArgs = {
  object: Core_Logimage_Insert_Input;
  on_conflict?: InputMaybe<Core_Logimage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_LogsuspiciosactivityArgs = {
  objects: Array<Core_Logsuspiciosactivity_Insert_Input>;
  on_conflict?: InputMaybe<Core_Logsuspiciosactivity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Logsuspiciosactivity_OneArgs = {
  object: Core_Logsuspiciosactivity_Insert_Input;
  on_conflict?: InputMaybe<Core_Logsuspiciosactivity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_MeterArgs = {
  objects: Array<Core_Meter_Insert_Input>;
  on_conflict?: InputMaybe<Core_Meter_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Meter_OneArgs = {
  object: Core_Meter_Insert_Input;
  on_conflict?: InputMaybe<Core_Meter_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_MeterreadingArgs = {
  objects: Array<Core_Meterreading_Insert_Input>;
  on_conflict?: InputMaybe<Core_Meterreading_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Meterreading_OneArgs = {
  object: Core_Meterreading_Insert_Input;
  on_conflict?: InputMaybe<Core_Meterreading_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_MetricArgs = {
  objects: Array<Core_Metric_Insert_Input>;
  on_conflict?: InputMaybe<Core_Metric_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Metric_OneArgs = {
  object: Core_Metric_Insert_Input;
  on_conflict?: InputMaybe<Core_Metric_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_MunicipalityArgs = {
  objects: Array<Core_Municipality_Insert_Input>;
  on_conflict?: InputMaybe<Core_Municipality_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Municipality_OneArgs = {
  object: Core_Municipality_Insert_Input;
  on_conflict?: InputMaybe<Core_Municipality_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_NoneuserpersonaleArgs = {
  objects: Array<Core_Noneuserpersonale_Insert_Input>;
  on_conflict?: InputMaybe<Core_Noneuserpersonale_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Noneuserpersonale_OneArgs = {
  object: Core_Noneuserpersonale_Insert_Input;
  on_conflict?: InputMaybe<Core_Noneuserpersonale_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_PaymenttypeArgs = {
  objects: Array<Core_Paymenttype_Insert_Input>;
  on_conflict?: InputMaybe<Core_Paymenttype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Paymenttype_OneArgs = {
  object: Core_Paymenttype_Insert_Input;
  on_conflict?: InputMaybe<Core_Paymenttype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_PersonaleimageArgs = {
  objects: Array<Core_Personaleimage_Insert_Input>;
  on_conflict?: InputMaybe<Core_Personaleimage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Personaleimage_OneArgs = {
  object: Core_Personaleimage_Insert_Input;
  on_conflict?: InputMaybe<Core_Personaleimage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_PersonnelassignedArgs = {
  objects: Array<Core_Personnelassigned_Insert_Input>;
  on_conflict?: InputMaybe<Core_Personnelassigned_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Personnelassigned_OneArgs = {
  object: Core_Personnelassigned_Insert_Input;
  on_conflict?: InputMaybe<Core_Personnelassigned_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_PowerproviderArgs = {
  objects: Array<Core_Powerprovider_Insert_Input>;
  on_conflict?: InputMaybe<Core_Powerprovider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Powerprovider_OneArgs = {
  object: Core_Powerprovider_Insert_Input;
  on_conflict?: InputMaybe<Core_Powerprovider_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_PowersourcetypeArgs = {
  objects: Array<Core_Powersourcetype_Insert_Input>;
  on_conflict?: InputMaybe<Core_Powersourcetype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Powersourcetype_OneArgs = {
  object: Core_Powersourcetype_Insert_Input;
  on_conflict?: InputMaybe<Core_Powersourcetype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_ProvinceArgs = {
  objects: Array<Core_Province_Insert_Input>;
  on_conflict?: InputMaybe<Core_Province_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Province_OneArgs = {
  object: Core_Province_Insert_Input;
  on_conflict?: InputMaybe<Core_Province_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_RectifieralarmArgs = {
  objects: Array<Core_Rectifieralarm_Insert_Input>;
  on_conflict?: InputMaybe<Core_Rectifieralarm_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Rectifieralarm_OneArgs = {
  object: Core_Rectifieralarm_Insert_Input;
  on_conflict?: InputMaybe<Core_Rectifieralarm_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_RequestaccessArgs = {
  objects: Array<Core_Requestaccess_Insert_Input>;
  on_conflict?: InputMaybe<Core_Requestaccess_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Requestaccess_OneArgs = {
  object: Core_Requestaccess_Insert_Input;
  on_conflict?: InputMaybe<Core_Requestaccess_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Res_Company_Wide_Charge_EstimateArgs = {
  objects: Array<Core_Res_Company_Wide_Charge_Estimate_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Res_Company_Wide_Charge_Estimate_OneArgs = {
  object: Core_Res_Company_Wide_Charge_Estimate_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Core_ResjobstatusArgs = {
  objects: Array<Core_Resjobstatus_Insert_Input>;
  on_conflict?: InputMaybe<Core_Resjobstatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Resjobstatus_OneArgs = {
  object: Core_Resjobstatus_Insert_Input;
  on_conflict?: InputMaybe<Core_Resjobstatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_RoleArgs = {
  objects: Array<Core_Role_Insert_Input>;
  on_conflict?: InputMaybe<Core_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Role_OneArgs = {
  object: Core_Role_Insert_Input;
  on_conflict?: InputMaybe<Core_Role_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_RouterArgs = {
  objects: Array<Core_Router_Insert_Input>;
  on_conflict?: InputMaybe<Core_Router_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Router_OneArgs = {
  object: Core_Router_Insert_Input;
  on_conflict?: InputMaybe<Core_Router_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_SecuritycalloutsArgs = {
  objects: Array<Core_Securitycallouts_Insert_Input>;
  on_conflict?: InputMaybe<Core_Securitycallouts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Securitycallouts_OneArgs = {
  object: Core_Securitycallouts_Insert_Input;
  on_conflict?: InputMaybe<Core_Securitycallouts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_SecuritycalloutsstatusArgs = {
  objects: Array<Core_Securitycalloutsstatus_Insert_Input>;
  on_conflict?: InputMaybe<Core_Securitycalloutsstatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Securitycalloutsstatus_OneArgs = {
  object: Core_Securitycalloutsstatus_Insert_Input;
  on_conflict?: InputMaybe<Core_Securitycalloutsstatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_SimcardArgs = {
  objects: Array<Core_Simcard_Insert_Input>;
  on_conflict?: InputMaybe<Core_Simcard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Simcard_OneArgs = {
  object: Core_Simcard_Insert_Input;
  on_conflict?: InputMaybe<Core_Simcard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_SimcardstatusArgs = {
  objects: Array<Core_Simcardstatus_Insert_Input>;
  on_conflict?: InputMaybe<Core_Simcardstatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Simcardstatus_OneArgs = {
  object: Core_Simcardstatus_Insert_Input;
  on_conflict?: InputMaybe<Core_Simcardstatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_SiteArgs = {
  objects: Array<Core_Site_Insert_Input>;
  on_conflict?: InputMaybe<Core_Site_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Site_OneArgs = {
  object: Core_Site_Insert_Input;
  on_conflict?: InputMaybe<Core_Site_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_SitechargeestimateArgs = {
  objects: Array<Core_Sitechargeestimate_Insert_Input>;
  on_conflict?: InputMaybe<Core_Sitechargeestimate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Sitechargeestimate_OneArgs = {
  object: Core_Sitechargeestimate_Insert_Input;
  on_conflict?: InputMaybe<Core_Sitechargeestimate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_SiteregionArgs = {
  objects: Array<Core_Siteregion_Insert_Input>;
  on_conflict?: InputMaybe<Core_Siteregion_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Siteregion_OneArgs = {
  object: Core_Siteregion_Insert_Input;
  on_conflict?: InputMaybe<Core_Siteregion_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_SitesecuritycoverageArgs = {
  objects: Array<Core_Sitesecuritycoverage_Insert_Input>;
  on_conflict?: InputMaybe<Core_Sitesecuritycoverage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Sitesecuritycoverage_OneArgs = {
  object: Core_Sitesecuritycoverage_Insert_Input;
  on_conflict?: InputMaybe<Core_Sitesecuritycoverage_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_SitestatusArgs = {
  objects: Array<Core_Sitestatus_Insert_Input>;
  on_conflict?: InputMaybe<Core_Sitestatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Sitestatus_OneArgs = {
  object: Core_Sitestatus_Insert_Input;
  on_conflict?: InputMaybe<Core_Sitestatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_SitetypeArgs = {
  objects: Array<Core_Sitetype_Insert_Input>;
  on_conflict?: InputMaybe<Core_Sitetype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Sitetype_OneArgs = {
  object: Core_Sitetype_Insert_Input;
  on_conflict?: InputMaybe<Core_Sitetype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_SundrychargeArgs = {
  objects: Array<Core_Sundrycharge_Insert_Input>;
  on_conflict?: InputMaybe<Core_Sundrycharge_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Sundrycharge_OneArgs = {
  object: Core_Sundrycharge_Insert_Input;
  on_conflict?: InputMaybe<Core_Sundrycharge_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_SundrychargetypeArgs = {
  objects: Array<Core_Sundrychargetype_Insert_Input>;
  on_conflict?: InputMaybe<Core_Sundrychargetype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Sundrychargetype_OneArgs = {
  object: Core_Sundrychargetype_Insert_Input;
  on_conflict?: InputMaybe<Core_Sundrychargetype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_TariffArgs = {
  objects: Array<Core_Tariff_Insert_Input>;
  on_conflict?: InputMaybe<Core_Tariff_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Tariff_OneArgs = {
  object: Core_Tariff_Insert_Input;
  on_conflict?: InputMaybe<Core_Tariff_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_UserArgs = {
  objects: Array<Core_User_Insert_Input>;
  on_conflict?: InputMaybe<Core_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_User_GroupsArgs = {
  objects: Array<Core_User_Groups_Insert_Input>;
  on_conflict?: InputMaybe<Core_User_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_User_Groups_OneArgs = {
  object: Core_User_Groups_Insert_Input;
  on_conflict?: InputMaybe<Core_User_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_User_OneArgs = {
  object: Core_User_Insert_Input;
  on_conflict?: InputMaybe<Core_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_User_User_PermissionsArgs = {
  objects: Array<Core_User_User_Permissions_Insert_Input>;
  on_conflict?: InputMaybe<Core_User_User_Permissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_User_User_Permissions_OneArgs = {
  object: Core_User_User_Permissions_Insert_Input;
  on_conflict?: InputMaybe<Core_User_User_Permissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_VisitArgs = {
  objects: Array<Core_Visit_Insert_Input>;
  on_conflict?: InputMaybe<Core_Visit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Visit_OneArgs = {
  object: Core_Visit_Insert_Input;
  on_conflict?: InputMaybe<Core_Visit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_VisitstatusenumArgs = {
  objects: Array<Core_Visitstatusenum_Insert_Input>;
  on_conflict?: InputMaybe<Core_Visitstatusenum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_Visitstatusenum_OneArgs = {
  object: Core_Visitstatusenum_Insert_Input;
  on_conflict?: InputMaybe<Core_Visitstatusenum_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Django_Admin_LogArgs = {
  objects: Array<Django_Admin_Log_Insert_Input>;
  on_conflict?: InputMaybe<Django_Admin_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Django_Admin_Log_OneArgs = {
  object: Django_Admin_Log_Insert_Input;
  on_conflict?: InputMaybe<Django_Admin_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Django_Content_TypeArgs = {
  objects: Array<Django_Content_Type_Insert_Input>;
  on_conflict?: InputMaybe<Django_Content_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Django_Content_Type_OneArgs = {
  object: Django_Content_Type_Insert_Input;
  on_conflict?: InputMaybe<Django_Content_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Django_MigrationsArgs = {
  objects: Array<Django_Migrations_Insert_Input>;
  on_conflict?: InputMaybe<Django_Migrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Django_Migrations_OneArgs = {
  object: Django_Migrations_Insert_Input;
  on_conflict?: InputMaybe<Django_Migrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Django_SessionArgs = {
  objects: Array<Django_Session_Insert_Input>;
  on_conflict?: InputMaybe<Django_Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Django_Session_OneArgs = {
  object: Django_Session_Insert_Input;
  on_conflict?: InputMaybe<Django_Session_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_GroupArgs = {
  _inc?: InputMaybe<Auth_Group_Inc_Input>;
  _set?: InputMaybe<Auth_Group_Set_Input>;
  where: Auth_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Group_By_PkArgs = {
  _inc?: InputMaybe<Auth_Group_Inc_Input>;
  _set?: InputMaybe<Auth_Group_Set_Input>;
  pk_columns: Auth_Group_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Group_ManyArgs = {
  updates: Array<Auth_Group_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Group_PermissionsArgs = {
  _inc?: InputMaybe<Auth_Group_Permissions_Inc_Input>;
  _set?: InputMaybe<Auth_Group_Permissions_Set_Input>;
  where: Auth_Group_Permissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Group_Permissions_By_PkArgs = {
  _inc?: InputMaybe<Auth_Group_Permissions_Inc_Input>;
  _set?: InputMaybe<Auth_Group_Permissions_Set_Input>;
  pk_columns: Auth_Group_Permissions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Group_Permissions_ManyArgs = {
  updates: Array<Auth_Group_Permissions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_PermissionArgs = {
  _inc?: InputMaybe<Auth_Permission_Inc_Input>;
  _set?: InputMaybe<Auth_Permission_Set_Input>;
  where: Auth_Permission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Permission_By_PkArgs = {
  _inc?: InputMaybe<Auth_Permission_Inc_Input>;
  _set?: InputMaybe<Auth_Permission_Set_Input>;
  pk_columns: Auth_Permission_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Permission_ManyArgs = {
  updates: Array<Auth_Permission_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Authtoken_TokenArgs = {
  _inc?: InputMaybe<Authtoken_Token_Inc_Input>;
  _set?: InputMaybe<Authtoken_Token_Set_Input>;
  where: Authtoken_Token_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Authtoken_Token_By_PkArgs = {
  _inc?: InputMaybe<Authtoken_Token_Inc_Input>;
  _set?: InputMaybe<Authtoken_Token_Set_Input>;
  pk_columns: Authtoken_Token_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Authtoken_Token_ManyArgs = {
  updates: Array<Authtoken_Token_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_AgreementArgs = {
  _inc?: InputMaybe<Core_Agreement_Inc_Input>;
  _set?: InputMaybe<Core_Agreement_Set_Input>;
  where: Core_Agreement_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Agreement_By_PkArgs = {
  _inc?: InputMaybe<Core_Agreement_Inc_Input>;
  _set?: InputMaybe<Core_Agreement_Set_Input>;
  pk_columns: Core_Agreement_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Agreement_ManyArgs = {
  updates: Array<Core_Agreement_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_BoxArgs = {
  _inc?: InputMaybe<Core_Box_Inc_Input>;
  _set?: InputMaybe<Core_Box_Set_Input>;
  where: Core_Box_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Box_By_PkArgs = {
  _inc?: InputMaybe<Core_Box_Inc_Input>;
  _set?: InputMaybe<Core_Box_Set_Input>;
  pk_columns: Core_Box_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Box_ManyArgs = {
  updates: Array<Core_Box_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_BoxstatusArgs = {
  _inc?: InputMaybe<Core_Boxstatus_Inc_Input>;
  _set?: InputMaybe<Core_Boxstatus_Set_Input>;
  where: Core_Boxstatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Boxstatus_By_PkArgs = {
  _inc?: InputMaybe<Core_Boxstatus_Inc_Input>;
  _set?: InputMaybe<Core_Boxstatus_Set_Input>;
  pk_columns: Core_Boxstatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Boxstatus_ManyArgs = {
  updates: Array<Core_Boxstatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_CabinArgs = {
  _inc?: InputMaybe<Core_Cabin_Inc_Input>;
  _set?: InputMaybe<Core_Cabin_Set_Input>;
  where: Core_Cabin_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Cabin_By_PkArgs = {
  _inc?: InputMaybe<Core_Cabin_Inc_Input>;
  _set?: InputMaybe<Core_Cabin_Set_Input>;
  pk_columns: Core_Cabin_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Cabin_ManyArgs = {
  updates: Array<Core_Cabin_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_CameraArgs = {
  _inc?: InputMaybe<Core_Camera_Inc_Input>;
  _set?: InputMaybe<Core_Camera_Set_Input>;
  where: Core_Camera_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Camera_By_PkArgs = {
  _inc?: InputMaybe<Core_Camera_Inc_Input>;
  _set?: InputMaybe<Core_Camera_Set_Input>;
  pk_columns: Core_Camera_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Camera_ManyArgs = {
  updates: Array<Core_Camera_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_CompanyArgs = {
  _inc?: InputMaybe<Core_Company_Inc_Input>;
  _set?: InputMaybe<Core_Company_Set_Input>;
  where: Core_Company_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Company_By_PkArgs = {
  _inc?: InputMaybe<Core_Company_Inc_Input>;
  _set?: InputMaybe<Core_Company_Set_Input>;
  pk_columns: Core_Company_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Company_ManyArgs = {
  updates: Array<Core_Company_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_CompanysitechargeestimateArgs = {
  _inc?: InputMaybe<Core_Companysitechargeestimate_Inc_Input>;
  _set?: InputMaybe<Core_Companysitechargeestimate_Set_Input>;
  where: Core_Companysitechargeestimate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Companysitechargeestimate_By_PkArgs = {
  _inc?: InputMaybe<Core_Companysitechargeestimate_Inc_Input>;
  _set?: InputMaybe<Core_Companysitechargeestimate_Set_Input>;
  pk_columns: Core_Companysitechargeestimate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Companysitechargeestimate_ManyArgs = {
  updates: Array<Core_Companysitechargeestimate_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_CustomertypeArgs = {
  _inc?: InputMaybe<Core_Customertype_Inc_Input>;
  _set?: InputMaybe<Core_Customertype_Set_Input>;
  where: Core_Customertype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Customertype_By_PkArgs = {
  _inc?: InputMaybe<Core_Customertype_Inc_Input>;
  _set?: InputMaybe<Core_Customertype_Set_Input>;
  pk_columns: Core_Customertype_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Customertype_ManyArgs = {
  updates: Array<Core_Customertype_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_DisruptionsArgs = {
  _inc?: InputMaybe<Core_Disruptions_Inc_Input>;
  _set?: InputMaybe<Core_Disruptions_Set_Input>;
  where: Core_Disruptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Disruptions_By_PkArgs = {
  _inc?: InputMaybe<Core_Disruptions_Inc_Input>;
  _set?: InputMaybe<Core_Disruptions_Set_Input>;
  pk_columns: Core_Disruptions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Disruptions_ManyArgs = {
  updates: Array<Core_Disruptions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_DisruptionstypeArgs = {
  _inc?: InputMaybe<Core_Disruptionstype_Inc_Input>;
  _set?: InputMaybe<Core_Disruptionstype_Set_Input>;
  where: Core_Disruptionstype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Disruptionstype_By_PkArgs = {
  _inc?: InputMaybe<Core_Disruptionstype_Inc_Input>;
  _set?: InputMaybe<Core_Disruptionstype_Set_Input>;
  pk_columns: Core_Disruptionstype_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Disruptionstype_ManyArgs = {
  updates: Array<Core_Disruptionstype_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_EnergychargeArgs = {
  _inc?: InputMaybe<Core_Energycharge_Inc_Input>;
  _set?: InputMaybe<Core_Energycharge_Set_Input>;
  where: Core_Energycharge_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Energycharge_By_PkArgs = {
  _inc?: InputMaybe<Core_Energycharge_Inc_Input>;
  _set?: InputMaybe<Core_Energycharge_Set_Input>;
  pk_columns: Core_Energycharge_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Energycharge_ManyArgs = {
  updates: Array<Core_Energycharge_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_EquipmentArgs = {
  _inc?: InputMaybe<Core_Equipment_Inc_Input>;
  _set?: InputMaybe<Core_Equipment_Set_Input>;
  where: Core_Equipment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Equipment_By_PkArgs = {
  _inc?: InputMaybe<Core_Equipment_Inc_Input>;
  _set?: InputMaybe<Core_Equipment_Set_Input>;
  pk_columns: Core_Equipment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Equipment_ManyArgs = {
  updates: Array<Core_Equipment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_EquipmentconditionArgs = {
  _inc?: InputMaybe<Core_Equipmentcondition_Inc_Input>;
  _set?: InputMaybe<Core_Equipmentcondition_Set_Input>;
  where: Core_Equipmentcondition_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Equipmentcondition_By_PkArgs = {
  _inc?: InputMaybe<Core_Equipmentcondition_Inc_Input>;
  _set?: InputMaybe<Core_Equipmentcondition_Set_Input>;
  pk_columns: Core_Equipmentcondition_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Equipmentcondition_ManyArgs = {
  updates: Array<Core_Equipmentcondition_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_EquipmentimageArgs = {
  _inc?: InputMaybe<Core_Equipmentimage_Inc_Input>;
  _set?: InputMaybe<Core_Equipmentimage_Set_Input>;
  where: Core_Equipmentimage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Equipmentimage_By_PkArgs = {
  _inc?: InputMaybe<Core_Equipmentimage_Inc_Input>;
  _set?: InputMaybe<Core_Equipmentimage_Set_Input>;
  pk_columns: Core_Equipmentimage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Equipmentimage_ManyArgs = {
  updates: Array<Core_Equipmentimage_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_EquipmentmakeArgs = {
  _inc?: InputMaybe<Core_Equipmentmake_Inc_Input>;
  _set?: InputMaybe<Core_Equipmentmake_Set_Input>;
  where: Core_Equipmentmake_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Equipmentmake_By_PkArgs = {
  _inc?: InputMaybe<Core_Equipmentmake_Inc_Input>;
  _set?: InputMaybe<Core_Equipmentmake_Set_Input>;
  pk_columns: Core_Equipmentmake_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Equipmentmake_ManyArgs = {
  updates: Array<Core_Equipmentmake_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_EquipmentmodelArgs = {
  _inc?: InputMaybe<Core_Equipmentmodel_Inc_Input>;
  _set?: InputMaybe<Core_Equipmentmodel_Set_Input>;
  where: Core_Equipmentmodel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Equipmentmodel_By_PkArgs = {
  _inc?: InputMaybe<Core_Equipmentmodel_Inc_Input>;
  _set?: InputMaybe<Core_Equipmentmodel_Set_Input>;
  pk_columns: Core_Equipmentmodel_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Equipmentmodel_ManyArgs = {
  updates: Array<Core_Equipmentmodel_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_EquipmentpartArgs = {
  _inc?: InputMaybe<Core_Equipmentpart_Inc_Input>;
  _set?: InputMaybe<Core_Equipmentpart_Set_Input>;
  where: Core_Equipmentpart_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Equipmentpart_By_PkArgs = {
  _inc?: InputMaybe<Core_Equipmentpart_Inc_Input>;
  _set?: InputMaybe<Core_Equipmentpart_Set_Input>;
  pk_columns: Core_Equipmentpart_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Equipmentpart_ManyArgs = {
  updates: Array<Core_Equipmentpart_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_EquipmentstatusArgs = {
  _inc?: InputMaybe<Core_Equipmentstatus_Inc_Input>;
  _set?: InputMaybe<Core_Equipmentstatus_Set_Input>;
  where: Core_Equipmentstatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Equipmentstatus_By_PkArgs = {
  _inc?: InputMaybe<Core_Equipmentstatus_Inc_Input>;
  _set?: InputMaybe<Core_Equipmentstatus_Set_Input>;
  pk_columns: Core_Equipmentstatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Equipmentstatus_ManyArgs = {
  updates: Array<Core_Equipmentstatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_EquipmenttypeArgs = {
  _inc?: InputMaybe<Core_Equipmenttype_Inc_Input>;
  _set?: InputMaybe<Core_Equipmenttype_Set_Input>;
  where: Core_Equipmenttype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Equipmenttype_By_PkArgs = {
  _inc?: InputMaybe<Core_Equipmenttype_Inc_Input>;
  _set?: InputMaybe<Core_Equipmenttype_Set_Input>;
  pk_columns: Core_Equipmenttype_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Equipmenttype_ManyArgs = {
  updates: Array<Core_Equipmenttype_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_EspArgs = {
  _inc?: InputMaybe<Core_Esp_Inc_Input>;
  _set?: InputMaybe<Core_Esp_Set_Input>;
  where: Core_Esp_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Esp_By_PkArgs = {
  _inc?: InputMaybe<Core_Esp_Inc_Input>;
  _set?: InputMaybe<Core_Esp_Set_Input>;
  pk_columns: Core_Esp_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Esp_ManyArgs = {
  updates: Array<Core_Esp_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_EspstatusArgs = {
  _inc?: InputMaybe<Core_Espstatus_Inc_Input>;
  _set?: InputMaybe<Core_Espstatus_Set_Input>;
  where: Core_Espstatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Espstatus_By_PkArgs = {
  _inc?: InputMaybe<Core_Espstatus_Inc_Input>;
  _set?: InputMaybe<Core_Espstatus_Set_Input>;
  pk_columns: Core_Espstatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Espstatus_ManyArgs = {
  updates: Array<Core_Espstatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_EspstatusnextstagesArgs = {
  _inc?: InputMaybe<Core_Espstatusnextstages_Inc_Input>;
  _set?: InputMaybe<Core_Espstatusnextstages_Set_Input>;
  where: Core_Espstatusnextstages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Espstatusnextstages_By_PkArgs = {
  _inc?: InputMaybe<Core_Espstatusnextstages_Inc_Input>;
  _set?: InputMaybe<Core_Espstatusnextstages_Set_Input>;
  pk_columns: Core_Espstatusnextstages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Espstatusnextstages_ManyArgs = {
  updates: Array<Core_Espstatusnextstages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_EventlogArgs = {
  _inc?: InputMaybe<Core_Eventlog_Inc_Input>;
  _set?: InputMaybe<Core_Eventlog_Set_Input>;
  where: Core_Eventlog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Eventlog_By_PkArgs = {
  _inc?: InputMaybe<Core_Eventlog_Inc_Input>;
  _set?: InputMaybe<Core_Eventlog_Set_Input>;
  pk_columns: Core_Eventlog_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Eventlog_ManyArgs = {
  updates: Array<Core_Eventlog_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_IncidentArgs = {
  _inc?: InputMaybe<Core_Incident_Inc_Input>;
  _set?: InputMaybe<Core_Incident_Set_Input>;
  where: Core_Incident_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Incident_By_PkArgs = {
  _inc?: InputMaybe<Core_Incident_Inc_Input>;
  _set?: InputMaybe<Core_Incident_Set_Input>;
  pk_columns: Core_Incident_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Incident_ManyArgs = {
  updates: Array<Core_Incident_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_JobArgs = {
  _inc?: InputMaybe<Core_Job_Inc_Input>;
  _set?: InputMaybe<Core_Job_Set_Input>;
  where: Core_Job_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Job_By_PkArgs = {
  _inc?: InputMaybe<Core_Job_Inc_Input>;
  _set?: InputMaybe<Core_Job_Set_Input>;
  pk_columns: Core_Job_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Job_ManyArgs = {
  updates: Array<Core_Job_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_JobstatusArgs = {
  _inc?: InputMaybe<Core_Jobstatus_Inc_Input>;
  _set?: InputMaybe<Core_Jobstatus_Set_Input>;
  where: Core_Jobstatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Jobstatus_By_PkArgs = {
  _inc?: InputMaybe<Core_Jobstatus_Inc_Input>;
  _set?: InputMaybe<Core_Jobstatus_Set_Input>;
  pk_columns: Core_Jobstatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Jobstatus_ManyArgs = {
  updates: Array<Core_Jobstatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_JobstatusenumArgs = {
  _inc?: InputMaybe<Core_Jobstatusenum_Inc_Input>;
  _set?: InputMaybe<Core_Jobstatusenum_Set_Input>;
  where: Core_Jobstatusenum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Jobstatusenum_By_PkArgs = {
  _inc?: InputMaybe<Core_Jobstatusenum_Inc_Input>;
  _set?: InputMaybe<Core_Jobstatusenum_Set_Input>;
  pk_columns: Core_Jobstatusenum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Jobstatusenum_ManyArgs = {
  updates: Array<Core_Jobstatusenum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_LogArgs = {
  _inc?: InputMaybe<Core_Log_Inc_Input>;
  _set?: InputMaybe<Core_Log_Set_Input>;
  where: Core_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Log_By_PkArgs = {
  _inc?: InputMaybe<Core_Log_Inc_Input>;
  _set?: InputMaybe<Core_Log_Set_Input>;
  pk_columns: Core_Log_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Log_ManyArgs = {
  updates: Array<Core_Log_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_LogfacialrecognitionArgs = {
  _inc?: InputMaybe<Core_Logfacialrecognition_Inc_Input>;
  _set?: InputMaybe<Core_Logfacialrecognition_Set_Input>;
  where: Core_Logfacialrecognition_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Logfacialrecognition_By_PkArgs = {
  _inc?: InputMaybe<Core_Logfacialrecognition_Inc_Input>;
  _set?: InputMaybe<Core_Logfacialrecognition_Set_Input>;
  pk_columns: Core_Logfacialrecognition_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Logfacialrecognition_ManyArgs = {
  updates: Array<Core_Logfacialrecognition_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_LogimageArgs = {
  _inc?: InputMaybe<Core_Logimage_Inc_Input>;
  _set?: InputMaybe<Core_Logimage_Set_Input>;
  where: Core_Logimage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Logimage_By_PkArgs = {
  _inc?: InputMaybe<Core_Logimage_Inc_Input>;
  _set?: InputMaybe<Core_Logimage_Set_Input>;
  pk_columns: Core_Logimage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Logimage_ManyArgs = {
  updates: Array<Core_Logimage_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_LogsuspiciosactivityArgs = {
  _inc?: InputMaybe<Core_Logsuspiciosactivity_Inc_Input>;
  _set?: InputMaybe<Core_Logsuspiciosactivity_Set_Input>;
  where: Core_Logsuspiciosactivity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Logsuspiciosactivity_By_PkArgs = {
  _inc?: InputMaybe<Core_Logsuspiciosactivity_Inc_Input>;
  _set?: InputMaybe<Core_Logsuspiciosactivity_Set_Input>;
  pk_columns: Core_Logsuspiciosactivity_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Logsuspiciosactivity_ManyArgs = {
  updates: Array<Core_Logsuspiciosactivity_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_MeterArgs = {
  _inc?: InputMaybe<Core_Meter_Inc_Input>;
  _set?: InputMaybe<Core_Meter_Set_Input>;
  where: Core_Meter_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Meter_By_PkArgs = {
  _inc?: InputMaybe<Core_Meter_Inc_Input>;
  _set?: InputMaybe<Core_Meter_Set_Input>;
  pk_columns: Core_Meter_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Meter_ManyArgs = {
  updates: Array<Core_Meter_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_MeterreadingArgs = {
  _inc?: InputMaybe<Core_Meterreading_Inc_Input>;
  _set?: InputMaybe<Core_Meterreading_Set_Input>;
  where: Core_Meterreading_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Meterreading_By_PkArgs = {
  _inc?: InputMaybe<Core_Meterreading_Inc_Input>;
  _set?: InputMaybe<Core_Meterreading_Set_Input>;
  pk_columns: Core_Meterreading_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Meterreading_ManyArgs = {
  updates: Array<Core_Meterreading_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_MetricArgs = {
  _inc?: InputMaybe<Core_Metric_Inc_Input>;
  _set?: InputMaybe<Core_Metric_Set_Input>;
  where: Core_Metric_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Metric_By_PkArgs = {
  _inc?: InputMaybe<Core_Metric_Inc_Input>;
  _set?: InputMaybe<Core_Metric_Set_Input>;
  pk_columns: Core_Metric_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Metric_ManyArgs = {
  updates: Array<Core_Metric_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_MunicipalityArgs = {
  _inc?: InputMaybe<Core_Municipality_Inc_Input>;
  _set?: InputMaybe<Core_Municipality_Set_Input>;
  where: Core_Municipality_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Municipality_By_PkArgs = {
  _inc?: InputMaybe<Core_Municipality_Inc_Input>;
  _set?: InputMaybe<Core_Municipality_Set_Input>;
  pk_columns: Core_Municipality_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Municipality_ManyArgs = {
  updates: Array<Core_Municipality_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_NoneuserpersonaleArgs = {
  _inc?: InputMaybe<Core_Noneuserpersonale_Inc_Input>;
  _set?: InputMaybe<Core_Noneuserpersonale_Set_Input>;
  where: Core_Noneuserpersonale_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Noneuserpersonale_By_PkArgs = {
  _inc?: InputMaybe<Core_Noneuserpersonale_Inc_Input>;
  _set?: InputMaybe<Core_Noneuserpersonale_Set_Input>;
  pk_columns: Core_Noneuserpersonale_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Noneuserpersonale_ManyArgs = {
  updates: Array<Core_Noneuserpersonale_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_PaymenttypeArgs = {
  _inc?: InputMaybe<Core_Paymenttype_Inc_Input>;
  _set?: InputMaybe<Core_Paymenttype_Set_Input>;
  where: Core_Paymenttype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Paymenttype_By_PkArgs = {
  _inc?: InputMaybe<Core_Paymenttype_Inc_Input>;
  _set?: InputMaybe<Core_Paymenttype_Set_Input>;
  pk_columns: Core_Paymenttype_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Paymenttype_ManyArgs = {
  updates: Array<Core_Paymenttype_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_PersonaleimageArgs = {
  _inc?: InputMaybe<Core_Personaleimage_Inc_Input>;
  _set?: InputMaybe<Core_Personaleimage_Set_Input>;
  where: Core_Personaleimage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Personaleimage_By_PkArgs = {
  _inc?: InputMaybe<Core_Personaleimage_Inc_Input>;
  _set?: InputMaybe<Core_Personaleimage_Set_Input>;
  pk_columns: Core_Personaleimage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Personaleimage_ManyArgs = {
  updates: Array<Core_Personaleimage_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_PersonnelassignedArgs = {
  _inc?: InputMaybe<Core_Personnelassigned_Inc_Input>;
  _set?: InputMaybe<Core_Personnelassigned_Set_Input>;
  where: Core_Personnelassigned_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Personnelassigned_By_PkArgs = {
  _inc?: InputMaybe<Core_Personnelassigned_Inc_Input>;
  _set?: InputMaybe<Core_Personnelassigned_Set_Input>;
  pk_columns: Core_Personnelassigned_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Personnelassigned_ManyArgs = {
  updates: Array<Core_Personnelassigned_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_PowerproviderArgs = {
  _inc?: InputMaybe<Core_Powerprovider_Inc_Input>;
  _set?: InputMaybe<Core_Powerprovider_Set_Input>;
  where: Core_Powerprovider_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Powerprovider_By_PkArgs = {
  _inc?: InputMaybe<Core_Powerprovider_Inc_Input>;
  _set?: InputMaybe<Core_Powerprovider_Set_Input>;
  pk_columns: Core_Powerprovider_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Powerprovider_ManyArgs = {
  updates: Array<Core_Powerprovider_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_PowersourcetypeArgs = {
  _inc?: InputMaybe<Core_Powersourcetype_Inc_Input>;
  _set?: InputMaybe<Core_Powersourcetype_Set_Input>;
  where: Core_Powersourcetype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Powersourcetype_By_PkArgs = {
  _inc?: InputMaybe<Core_Powersourcetype_Inc_Input>;
  _set?: InputMaybe<Core_Powersourcetype_Set_Input>;
  pk_columns: Core_Powersourcetype_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Powersourcetype_ManyArgs = {
  updates: Array<Core_Powersourcetype_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_ProvinceArgs = {
  _inc?: InputMaybe<Core_Province_Inc_Input>;
  _set?: InputMaybe<Core_Province_Set_Input>;
  where: Core_Province_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Province_By_PkArgs = {
  _inc?: InputMaybe<Core_Province_Inc_Input>;
  _set?: InputMaybe<Core_Province_Set_Input>;
  pk_columns: Core_Province_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Province_ManyArgs = {
  updates: Array<Core_Province_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_RectifieralarmArgs = {
  _inc?: InputMaybe<Core_Rectifieralarm_Inc_Input>;
  _set?: InputMaybe<Core_Rectifieralarm_Set_Input>;
  where: Core_Rectifieralarm_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Rectifieralarm_By_PkArgs = {
  _inc?: InputMaybe<Core_Rectifieralarm_Inc_Input>;
  _set?: InputMaybe<Core_Rectifieralarm_Set_Input>;
  pk_columns: Core_Rectifieralarm_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Rectifieralarm_ManyArgs = {
  updates: Array<Core_Rectifieralarm_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_RequestaccessArgs = {
  _inc?: InputMaybe<Core_Requestaccess_Inc_Input>;
  _set?: InputMaybe<Core_Requestaccess_Set_Input>;
  where: Core_Requestaccess_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Requestaccess_By_PkArgs = {
  _inc?: InputMaybe<Core_Requestaccess_Inc_Input>;
  _set?: InputMaybe<Core_Requestaccess_Set_Input>;
  pk_columns: Core_Requestaccess_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Requestaccess_ManyArgs = {
  updates: Array<Core_Requestaccess_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Res_Company_Wide_Charge_EstimateArgs = {
  _inc?: InputMaybe<Core_Res_Company_Wide_Charge_Estimate_Inc_Input>;
  _set?: InputMaybe<Core_Res_Company_Wide_Charge_Estimate_Set_Input>;
  where: Core_Res_Company_Wide_Charge_Estimate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Res_Company_Wide_Charge_Estimate_ManyArgs = {
  updates: Array<Core_Res_Company_Wide_Charge_Estimate_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_ResjobstatusArgs = {
  _inc?: InputMaybe<Core_Resjobstatus_Inc_Input>;
  _set?: InputMaybe<Core_Resjobstatus_Set_Input>;
  where: Core_Resjobstatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Resjobstatus_By_PkArgs = {
  _inc?: InputMaybe<Core_Resjobstatus_Inc_Input>;
  _set?: InputMaybe<Core_Resjobstatus_Set_Input>;
  pk_columns: Core_Resjobstatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Resjobstatus_ManyArgs = {
  updates: Array<Core_Resjobstatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_RoleArgs = {
  _inc?: InputMaybe<Core_Role_Inc_Input>;
  _set?: InputMaybe<Core_Role_Set_Input>;
  where: Core_Role_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Role_By_PkArgs = {
  _inc?: InputMaybe<Core_Role_Inc_Input>;
  _set?: InputMaybe<Core_Role_Set_Input>;
  pk_columns: Core_Role_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Role_ManyArgs = {
  updates: Array<Core_Role_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_RouterArgs = {
  _inc?: InputMaybe<Core_Router_Inc_Input>;
  _set?: InputMaybe<Core_Router_Set_Input>;
  where: Core_Router_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Router_By_PkArgs = {
  _inc?: InputMaybe<Core_Router_Inc_Input>;
  _set?: InputMaybe<Core_Router_Set_Input>;
  pk_columns: Core_Router_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Router_ManyArgs = {
  updates: Array<Core_Router_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_SecuritycalloutsArgs = {
  _inc?: InputMaybe<Core_Securitycallouts_Inc_Input>;
  _set?: InputMaybe<Core_Securitycallouts_Set_Input>;
  where: Core_Securitycallouts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Securitycallouts_By_PkArgs = {
  _inc?: InputMaybe<Core_Securitycallouts_Inc_Input>;
  _set?: InputMaybe<Core_Securitycallouts_Set_Input>;
  pk_columns: Core_Securitycallouts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Securitycallouts_ManyArgs = {
  updates: Array<Core_Securitycallouts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_SecuritycalloutsstatusArgs = {
  _inc?: InputMaybe<Core_Securitycalloutsstatus_Inc_Input>;
  _set?: InputMaybe<Core_Securitycalloutsstatus_Set_Input>;
  where: Core_Securitycalloutsstatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Securitycalloutsstatus_By_PkArgs = {
  _inc?: InputMaybe<Core_Securitycalloutsstatus_Inc_Input>;
  _set?: InputMaybe<Core_Securitycalloutsstatus_Set_Input>;
  pk_columns: Core_Securitycalloutsstatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Securitycalloutsstatus_ManyArgs = {
  updates: Array<Core_Securitycalloutsstatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_SimcardArgs = {
  _inc?: InputMaybe<Core_Simcard_Inc_Input>;
  _set?: InputMaybe<Core_Simcard_Set_Input>;
  where: Core_Simcard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Simcard_By_PkArgs = {
  _inc?: InputMaybe<Core_Simcard_Inc_Input>;
  _set?: InputMaybe<Core_Simcard_Set_Input>;
  pk_columns: Core_Simcard_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Simcard_ManyArgs = {
  updates: Array<Core_Simcard_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_SimcardstatusArgs = {
  _inc?: InputMaybe<Core_Simcardstatus_Inc_Input>;
  _set?: InputMaybe<Core_Simcardstatus_Set_Input>;
  where: Core_Simcardstatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Simcardstatus_By_PkArgs = {
  _inc?: InputMaybe<Core_Simcardstatus_Inc_Input>;
  _set?: InputMaybe<Core_Simcardstatus_Set_Input>;
  pk_columns: Core_Simcardstatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Simcardstatus_ManyArgs = {
  updates: Array<Core_Simcardstatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_SiteArgs = {
  _inc?: InputMaybe<Core_Site_Inc_Input>;
  _set?: InputMaybe<Core_Site_Set_Input>;
  where: Core_Site_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Site_By_PkArgs = {
  _inc?: InputMaybe<Core_Site_Inc_Input>;
  _set?: InputMaybe<Core_Site_Set_Input>;
  pk_columns: Core_Site_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Site_ManyArgs = {
  updates: Array<Core_Site_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_SitechargeestimateArgs = {
  _inc?: InputMaybe<Core_Sitechargeestimate_Inc_Input>;
  _set?: InputMaybe<Core_Sitechargeestimate_Set_Input>;
  where: Core_Sitechargeestimate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Sitechargeestimate_By_PkArgs = {
  _inc?: InputMaybe<Core_Sitechargeestimate_Inc_Input>;
  _set?: InputMaybe<Core_Sitechargeestimate_Set_Input>;
  pk_columns: Core_Sitechargeestimate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Sitechargeestimate_ManyArgs = {
  updates: Array<Core_Sitechargeestimate_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_SiteregionArgs = {
  _inc?: InputMaybe<Core_Siteregion_Inc_Input>;
  _set?: InputMaybe<Core_Siteregion_Set_Input>;
  where: Core_Siteregion_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Siteregion_By_PkArgs = {
  _inc?: InputMaybe<Core_Siteregion_Inc_Input>;
  _set?: InputMaybe<Core_Siteregion_Set_Input>;
  pk_columns: Core_Siteregion_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Siteregion_ManyArgs = {
  updates: Array<Core_Siteregion_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_SitesecuritycoverageArgs = {
  _inc?: InputMaybe<Core_Sitesecuritycoverage_Inc_Input>;
  _set?: InputMaybe<Core_Sitesecuritycoverage_Set_Input>;
  where: Core_Sitesecuritycoverage_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Sitesecuritycoverage_By_PkArgs = {
  _inc?: InputMaybe<Core_Sitesecuritycoverage_Inc_Input>;
  _set?: InputMaybe<Core_Sitesecuritycoverage_Set_Input>;
  pk_columns: Core_Sitesecuritycoverage_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Sitesecuritycoverage_ManyArgs = {
  updates: Array<Core_Sitesecuritycoverage_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_SitestatusArgs = {
  _inc?: InputMaybe<Core_Sitestatus_Inc_Input>;
  _set?: InputMaybe<Core_Sitestatus_Set_Input>;
  where: Core_Sitestatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Sitestatus_By_PkArgs = {
  _inc?: InputMaybe<Core_Sitestatus_Inc_Input>;
  _set?: InputMaybe<Core_Sitestatus_Set_Input>;
  pk_columns: Core_Sitestatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Sitestatus_ManyArgs = {
  updates: Array<Core_Sitestatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_SitetypeArgs = {
  _inc?: InputMaybe<Core_Sitetype_Inc_Input>;
  _set?: InputMaybe<Core_Sitetype_Set_Input>;
  where: Core_Sitetype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Sitetype_By_PkArgs = {
  _inc?: InputMaybe<Core_Sitetype_Inc_Input>;
  _set?: InputMaybe<Core_Sitetype_Set_Input>;
  pk_columns: Core_Sitetype_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Sitetype_ManyArgs = {
  updates: Array<Core_Sitetype_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_SundrychargeArgs = {
  _inc?: InputMaybe<Core_Sundrycharge_Inc_Input>;
  _set?: InputMaybe<Core_Sundrycharge_Set_Input>;
  where: Core_Sundrycharge_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Sundrycharge_By_PkArgs = {
  _inc?: InputMaybe<Core_Sundrycharge_Inc_Input>;
  _set?: InputMaybe<Core_Sundrycharge_Set_Input>;
  pk_columns: Core_Sundrycharge_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Sundrycharge_ManyArgs = {
  updates: Array<Core_Sundrycharge_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_SundrychargetypeArgs = {
  _inc?: InputMaybe<Core_Sundrychargetype_Inc_Input>;
  _set?: InputMaybe<Core_Sundrychargetype_Set_Input>;
  where: Core_Sundrychargetype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Sundrychargetype_By_PkArgs = {
  _inc?: InputMaybe<Core_Sundrychargetype_Inc_Input>;
  _set?: InputMaybe<Core_Sundrychargetype_Set_Input>;
  pk_columns: Core_Sundrychargetype_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Sundrychargetype_ManyArgs = {
  updates: Array<Core_Sundrychargetype_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_TariffArgs = {
  _inc?: InputMaybe<Core_Tariff_Inc_Input>;
  _set?: InputMaybe<Core_Tariff_Set_Input>;
  where: Core_Tariff_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Tariff_By_PkArgs = {
  _inc?: InputMaybe<Core_Tariff_Inc_Input>;
  _set?: InputMaybe<Core_Tariff_Set_Input>;
  pk_columns: Core_Tariff_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Tariff_ManyArgs = {
  updates: Array<Core_Tariff_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_UserArgs = {
  _inc?: InputMaybe<Core_User_Inc_Input>;
  _set?: InputMaybe<Core_User_Set_Input>;
  where: Core_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_User_By_PkArgs = {
  _inc?: InputMaybe<Core_User_Inc_Input>;
  _set?: InputMaybe<Core_User_Set_Input>;
  pk_columns: Core_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_User_GroupsArgs = {
  _inc?: InputMaybe<Core_User_Groups_Inc_Input>;
  _set?: InputMaybe<Core_User_Groups_Set_Input>;
  where: Core_User_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_User_Groups_By_PkArgs = {
  _inc?: InputMaybe<Core_User_Groups_Inc_Input>;
  _set?: InputMaybe<Core_User_Groups_Set_Input>;
  pk_columns: Core_User_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_User_Groups_ManyArgs = {
  updates: Array<Core_User_Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_User_ManyArgs = {
  updates: Array<Core_User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_User_User_PermissionsArgs = {
  _inc?: InputMaybe<Core_User_User_Permissions_Inc_Input>;
  _set?: InputMaybe<Core_User_User_Permissions_Set_Input>;
  where: Core_User_User_Permissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_User_User_Permissions_By_PkArgs = {
  _inc?: InputMaybe<Core_User_User_Permissions_Inc_Input>;
  _set?: InputMaybe<Core_User_User_Permissions_Set_Input>;
  pk_columns: Core_User_User_Permissions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_User_User_Permissions_ManyArgs = {
  updates: Array<Core_User_User_Permissions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_VisitArgs = {
  _inc?: InputMaybe<Core_Visit_Inc_Input>;
  _set?: InputMaybe<Core_Visit_Set_Input>;
  where: Core_Visit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Visit_By_PkArgs = {
  _inc?: InputMaybe<Core_Visit_Inc_Input>;
  _set?: InputMaybe<Core_Visit_Set_Input>;
  pk_columns: Core_Visit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Visit_ManyArgs = {
  updates: Array<Core_Visit_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_VisitstatusenumArgs = {
  _inc?: InputMaybe<Core_Visitstatusenum_Inc_Input>;
  _set?: InputMaybe<Core_Visitstatusenum_Set_Input>;
  where: Core_Visitstatusenum_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Visitstatusenum_By_PkArgs = {
  _inc?: InputMaybe<Core_Visitstatusenum_Inc_Input>;
  _set?: InputMaybe<Core_Visitstatusenum_Set_Input>;
  pk_columns: Core_Visitstatusenum_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_Visitstatusenum_ManyArgs = {
  updates: Array<Core_Visitstatusenum_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Django_Admin_LogArgs = {
  _inc?: InputMaybe<Django_Admin_Log_Inc_Input>;
  _set?: InputMaybe<Django_Admin_Log_Set_Input>;
  where: Django_Admin_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Django_Admin_Log_By_PkArgs = {
  _inc?: InputMaybe<Django_Admin_Log_Inc_Input>;
  _set?: InputMaybe<Django_Admin_Log_Set_Input>;
  pk_columns: Django_Admin_Log_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Django_Admin_Log_ManyArgs = {
  updates: Array<Django_Admin_Log_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Django_Content_TypeArgs = {
  _inc?: InputMaybe<Django_Content_Type_Inc_Input>;
  _set?: InputMaybe<Django_Content_Type_Set_Input>;
  where: Django_Content_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Django_Content_Type_By_PkArgs = {
  _inc?: InputMaybe<Django_Content_Type_Inc_Input>;
  _set?: InputMaybe<Django_Content_Type_Set_Input>;
  pk_columns: Django_Content_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Django_Content_Type_ManyArgs = {
  updates: Array<Django_Content_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Django_MigrationsArgs = {
  _inc?: InputMaybe<Django_Migrations_Inc_Input>;
  _set?: InputMaybe<Django_Migrations_Set_Input>;
  where: Django_Migrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Django_Migrations_By_PkArgs = {
  _inc?: InputMaybe<Django_Migrations_Inc_Input>;
  _set?: InputMaybe<Django_Migrations_Set_Input>;
  pk_columns: Django_Migrations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Django_Migrations_ManyArgs = {
  updates: Array<Django_Migrations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Django_SessionArgs = {
  _set?: InputMaybe<Django_Session_Set_Input>;
  where: Django_Session_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Django_Session_By_PkArgs = {
  _set?: InputMaybe<Django_Session_Set_Input>;
  pk_columns: Django_Session_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Django_Session_ManyArgs = {
  updates: Array<Django_Session_Updates>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "auth_group" */
  auth_group: Array<Auth_Group>;
  /** fetch aggregated fields from the table: "auth_group" */
  auth_group_aggregate: Auth_Group_Aggregate;
  /** fetch data from the table: "auth_group" using primary key columns */
  auth_group_by_pk?: Maybe<Auth_Group>;
  /** An array relationship */
  auth_group_permissions: Array<Auth_Group_Permissions>;
  /** An aggregate relationship */
  auth_group_permissions_aggregate: Auth_Group_Permissions_Aggregate;
  /** fetch data from the table: "auth_group_permissions" using primary key columns */
  auth_group_permissions_by_pk?: Maybe<Auth_Group_Permissions>;
  /** fetch data from the table: "auth_permission" */
  auth_permission: Array<Auth_Permission>;
  /** fetch aggregated fields from the table: "auth_permission" */
  auth_permission_aggregate: Auth_Permission_Aggregate;
  /** fetch data from the table: "auth_permission" using primary key columns */
  auth_permission_by_pk?: Maybe<Auth_Permission>;
  /** fetch data from the table: "authtoken_token" */
  authtoken_token: Array<Authtoken_Token>;
  /** fetch aggregated fields from the table: "authtoken_token" */
  authtoken_token_aggregate: Authtoken_Token_Aggregate;
  /** fetch data from the table: "authtoken_token" using primary key columns */
  authtoken_token_by_pk?: Maybe<Authtoken_Token>;
  /** fetch data from the table: "core_agreement" */
  core_agreement: Array<Core_Agreement>;
  /** fetch aggregated fields from the table: "core_agreement" */
  core_agreement_aggregate: Core_Agreement_Aggregate;
  /** fetch data from the table: "core_agreement" using primary key columns */
  core_agreement_by_pk?: Maybe<Core_Agreement>;
  /** fetch data from the table: "core_box" */
  core_box: Array<Core_Box>;
  /** fetch aggregated fields from the table: "core_box" */
  core_box_aggregate: Core_Box_Aggregate;
  /** fetch data from the table: "core_box" using primary key columns */
  core_box_by_pk?: Maybe<Core_Box>;
  /** fetch data from the table: "core_boxstatus" */
  core_boxstatus: Array<Core_Boxstatus>;
  /** fetch aggregated fields from the table: "core_boxstatus" */
  core_boxstatus_aggregate: Core_Boxstatus_Aggregate;
  /** fetch data from the table: "core_boxstatus" using primary key columns */
  core_boxstatus_by_pk?: Maybe<Core_Boxstatus>;
  /** fetch data from the table: "core_cabin" */
  core_cabin: Array<Core_Cabin>;
  /** fetch aggregated fields from the table: "core_cabin" */
  core_cabin_aggregate: Core_Cabin_Aggregate;
  /** fetch data from the table: "core_cabin" using primary key columns */
  core_cabin_by_pk?: Maybe<Core_Cabin>;
  /** fetch data from the table: "core_camera" */
  core_camera: Array<Core_Camera>;
  /** fetch aggregated fields from the table: "core_camera" */
  core_camera_aggregate: Core_Camera_Aggregate;
  /** fetch data from the table: "core_camera" using primary key columns */
  core_camera_by_pk?: Maybe<Core_Camera>;
  /** fetch data from the table: "core_company" */
  core_company: Array<Core_Company>;
  /** fetch aggregated fields from the table: "core_company" */
  core_company_aggregate: Core_Company_Aggregate;
  /** fetch data from the table: "core_company" using primary key columns */
  core_company_by_pk?: Maybe<Core_Company>;
  /** fetch data from the table: "core_companysitechargeestimate" */
  core_companysitechargeestimate: Array<Core_Companysitechargeestimate>;
  /** fetch aggregated fields from the table: "core_companysitechargeestimate" */
  core_companysitechargeestimate_aggregate: Core_Companysitechargeestimate_Aggregate;
  /** fetch data from the table: "core_companysitechargeestimate" using primary key columns */
  core_companysitechargeestimate_by_pk?: Maybe<Core_Companysitechargeestimate>;
  /** fetch data from the table: "core_customertype" */
  core_customertype: Array<Core_Customertype>;
  /** fetch aggregated fields from the table: "core_customertype" */
  core_customertype_aggregate: Core_Customertype_Aggregate;
  /** fetch data from the table: "core_customertype" using primary key columns */
  core_customertype_by_pk?: Maybe<Core_Customertype>;
  /** An array relationship */
  core_disruptions: Array<Core_Disruptions>;
  /** An aggregate relationship */
  core_disruptions_aggregate: Core_Disruptions_Aggregate;
  /** fetch data from the table: "core_disruptions" using primary key columns */
  core_disruptions_by_pk?: Maybe<Core_Disruptions>;
  /** fetch data from the table: "core_disruptionstype" */
  core_disruptionstype: Array<Core_Disruptionstype>;
  /** fetch aggregated fields from the table: "core_disruptionstype" */
  core_disruptionstype_aggregate: Core_Disruptionstype_Aggregate;
  /** fetch data from the table: "core_disruptionstype" using primary key columns */
  core_disruptionstype_by_pk?: Maybe<Core_Disruptionstype>;
  /** fetch data from the table: "core_energycharge" */
  core_energycharge: Array<Core_Energycharge>;
  /** fetch aggregated fields from the table: "core_energycharge" */
  core_energycharge_aggregate: Core_Energycharge_Aggregate;
  /** fetch data from the table: "core_energycharge" using primary key columns */
  core_energycharge_by_pk?: Maybe<Core_Energycharge>;
  /** fetch data from the table: "core_equipment" */
  core_equipment: Array<Core_Equipment>;
  /** fetch aggregated fields from the table: "core_equipment" */
  core_equipment_aggregate: Core_Equipment_Aggregate;
  /** fetch data from the table: "core_equipment" using primary key columns */
  core_equipment_by_pk?: Maybe<Core_Equipment>;
  /** fetch data from the table: "core_equipmentcondition" */
  core_equipmentcondition: Array<Core_Equipmentcondition>;
  /** fetch aggregated fields from the table: "core_equipmentcondition" */
  core_equipmentcondition_aggregate: Core_Equipmentcondition_Aggregate;
  /** fetch data from the table: "core_equipmentcondition" using primary key columns */
  core_equipmentcondition_by_pk?: Maybe<Core_Equipmentcondition>;
  /** fetch data from the table: "core_equipmentimage" */
  core_equipmentimage: Array<Core_Equipmentimage>;
  /** fetch aggregated fields from the table: "core_equipmentimage" */
  core_equipmentimage_aggregate: Core_Equipmentimage_Aggregate;
  /** fetch data from the table: "core_equipmentimage" using primary key columns */
  core_equipmentimage_by_pk?: Maybe<Core_Equipmentimage>;
  /** fetch data from the table: "core_equipmentmake" */
  core_equipmentmake: Array<Core_Equipmentmake>;
  /** fetch aggregated fields from the table: "core_equipmentmake" */
  core_equipmentmake_aggregate: Core_Equipmentmake_Aggregate;
  /** fetch data from the table: "core_equipmentmake" using primary key columns */
  core_equipmentmake_by_pk?: Maybe<Core_Equipmentmake>;
  /** fetch data from the table: "core_equipmentmodel" */
  core_equipmentmodel: Array<Core_Equipmentmodel>;
  /** fetch aggregated fields from the table: "core_equipmentmodel" */
  core_equipmentmodel_aggregate: Core_Equipmentmodel_Aggregate;
  /** fetch data from the table: "core_equipmentmodel" using primary key columns */
  core_equipmentmodel_by_pk?: Maybe<Core_Equipmentmodel>;
  /** fetch data from the table: "core_equipmentpart" */
  core_equipmentpart: Array<Core_Equipmentpart>;
  /** fetch aggregated fields from the table: "core_equipmentpart" */
  core_equipmentpart_aggregate: Core_Equipmentpart_Aggregate;
  /** fetch data from the table: "core_equipmentpart" using primary key columns */
  core_equipmentpart_by_pk?: Maybe<Core_Equipmentpart>;
  /** fetch data from the table: "core_equipmentstatus" */
  core_equipmentstatus: Array<Core_Equipmentstatus>;
  /** fetch aggregated fields from the table: "core_equipmentstatus" */
  core_equipmentstatus_aggregate: Core_Equipmentstatus_Aggregate;
  /** fetch data from the table: "core_equipmentstatus" using primary key columns */
  core_equipmentstatus_by_pk?: Maybe<Core_Equipmentstatus>;
  /** fetch data from the table: "core_equipmenttype" */
  core_equipmenttype: Array<Core_Equipmenttype>;
  /** fetch aggregated fields from the table: "core_equipmenttype" */
  core_equipmenttype_aggregate: Core_Equipmenttype_Aggregate;
  /** fetch data from the table: "core_equipmenttype" using primary key columns */
  core_equipmenttype_by_pk?: Maybe<Core_Equipmenttype>;
  /** fetch data from the table: "core_esp" */
  core_esp: Array<Core_Esp>;
  /** fetch aggregated fields from the table: "core_esp" */
  core_esp_aggregate: Core_Esp_Aggregate;
  /** fetch data from the table: "core_esp" using primary key columns */
  core_esp_by_pk?: Maybe<Core_Esp>;
  /** fetch data from the table: "core_espstatus" */
  core_espstatus: Array<Core_Espstatus>;
  /** fetch aggregated fields from the table: "core_espstatus" */
  core_espstatus_aggregate: Core_Espstatus_Aggregate;
  /** fetch data from the table: "core_espstatus" using primary key columns */
  core_espstatus_by_pk?: Maybe<Core_Espstatus>;
  /** An array relationship */
  core_espstatusnextstages: Array<Core_Espstatusnextstages>;
  /** An aggregate relationship */
  core_espstatusnextstages_aggregate: Core_Espstatusnextstages_Aggregate;
  /** fetch data from the table: "core_espstatusnextstages" using primary key columns */
  core_espstatusnextstages_by_pk?: Maybe<Core_Espstatusnextstages>;
  /** fetch data from the table: "core_eventlog" */
  core_eventlog: Array<Core_Eventlog>;
  /** fetch aggregated fields from the table: "core_eventlog" */
  core_eventlog_aggregate: Core_Eventlog_Aggregate;
  /** fetch data from the table: "core_eventlog" using primary key columns */
  core_eventlog_by_pk?: Maybe<Core_Eventlog>;
  /** fetch data from the table: "core_incident" */
  core_incident: Array<Core_Incident>;
  /** fetch aggregated fields from the table: "core_incident" */
  core_incident_aggregate: Core_Incident_Aggregate;
  /** fetch data from the table: "core_incident" using primary key columns */
  core_incident_by_pk?: Maybe<Core_Incident>;
  /** fetch data from the table: "core_job" */
  core_job: Array<Core_Job>;
  /** fetch aggregated fields from the table: "core_job" */
  core_job_aggregate: Core_Job_Aggregate;
  /** fetch data from the table: "core_job" using primary key columns */
  core_job_by_pk?: Maybe<Core_Job>;
  /** fetch data from the table: "core_jobstatus" */
  core_jobstatus: Array<Core_Jobstatus>;
  /** fetch aggregated fields from the table: "core_jobstatus" */
  core_jobstatus_aggregate: Core_Jobstatus_Aggregate;
  /** fetch data from the table: "core_jobstatus" using primary key columns */
  core_jobstatus_by_pk?: Maybe<Core_Jobstatus>;
  /** fetch data from the table: "core_jobstatusenum" */
  core_jobstatusenum: Array<Core_Jobstatusenum>;
  /** fetch aggregated fields from the table: "core_jobstatusenum" */
  core_jobstatusenum_aggregate: Core_Jobstatusenum_Aggregate;
  /** fetch data from the table: "core_jobstatusenum" using primary key columns */
  core_jobstatusenum_by_pk?: Maybe<Core_Jobstatusenum>;
  /** fetch data from the table: "core_log" */
  core_log: Array<Core_Log>;
  /** fetch aggregated fields from the table: "core_log" */
  core_log_aggregate: Core_Log_Aggregate;
  /** fetch data from the table: "core_log" using primary key columns */
  core_log_by_pk?: Maybe<Core_Log>;
  /** fetch data from the table: "core_logfacialrecognition" */
  core_logfacialrecognition: Array<Core_Logfacialrecognition>;
  /** fetch aggregated fields from the table: "core_logfacialrecognition" */
  core_logfacialrecognition_aggregate: Core_Logfacialrecognition_Aggregate;
  /** fetch data from the table: "core_logfacialrecognition" using primary key columns */
  core_logfacialrecognition_by_pk?: Maybe<Core_Logfacialrecognition>;
  /** fetch data from the table: "core_logimage" */
  core_logimage: Array<Core_Logimage>;
  /** fetch aggregated fields from the table: "core_logimage" */
  core_logimage_aggregate: Core_Logimage_Aggregate;
  /** fetch data from the table: "core_logimage" using primary key columns */
  core_logimage_by_pk?: Maybe<Core_Logimage>;
  /** fetch data from the table: "core_logsuspiciosactivity" */
  core_logsuspiciosactivity: Array<Core_Logsuspiciosactivity>;
  /** fetch aggregated fields from the table: "core_logsuspiciosactivity" */
  core_logsuspiciosactivity_aggregate: Core_Logsuspiciosactivity_Aggregate;
  /** fetch data from the table: "core_logsuspiciosactivity" using primary key columns */
  core_logsuspiciosactivity_by_pk?: Maybe<Core_Logsuspiciosactivity>;
  /** fetch data from the table: "core_meter" */
  core_meter: Array<Core_Meter>;
  /** fetch aggregated fields from the table: "core_meter" */
  core_meter_aggregate: Core_Meter_Aggregate;
  /** fetch data from the table: "core_meter" using primary key columns */
  core_meter_by_pk?: Maybe<Core_Meter>;
  /** fetch data from the table: "core_meterreading" */
  core_meterreading: Array<Core_Meterreading>;
  /** fetch aggregated fields from the table: "core_meterreading" */
  core_meterreading_aggregate: Core_Meterreading_Aggregate;
  /** fetch data from the table: "core_meterreading" using primary key columns */
  core_meterreading_by_pk?: Maybe<Core_Meterreading>;
  /** fetch data from the table: "core_metric" */
  core_metric: Array<Core_Metric>;
  /** fetch aggregated fields from the table: "core_metric" */
  core_metric_aggregate: Core_Metric_Aggregate;
  /** fetch data from the table: "core_metric" using primary key columns */
  core_metric_by_pk?: Maybe<Core_Metric>;
  /** fetch data from the table: "core_municipality" */
  core_municipality: Array<Core_Municipality>;
  /** fetch aggregated fields from the table: "core_municipality" */
  core_municipality_aggregate: Core_Municipality_Aggregate;
  /** fetch data from the table: "core_municipality" using primary key columns */
  core_municipality_by_pk?: Maybe<Core_Municipality>;
  /** fetch data from the table: "core_noneuserpersonale" */
  core_noneuserpersonale: Array<Core_Noneuserpersonale>;
  /** fetch aggregated fields from the table: "core_noneuserpersonale" */
  core_noneuserpersonale_aggregate: Core_Noneuserpersonale_Aggregate;
  /** fetch data from the table: "core_noneuserpersonale" using primary key columns */
  core_noneuserpersonale_by_pk?: Maybe<Core_Noneuserpersonale>;
  /** fetch data from the table: "core_paymenttype" */
  core_paymenttype: Array<Core_Paymenttype>;
  /** fetch aggregated fields from the table: "core_paymenttype" */
  core_paymenttype_aggregate: Core_Paymenttype_Aggregate;
  /** fetch data from the table: "core_paymenttype" using primary key columns */
  core_paymenttype_by_pk?: Maybe<Core_Paymenttype>;
  /** fetch data from the table: "core_personaleimage" */
  core_personaleimage: Array<Core_Personaleimage>;
  /** fetch aggregated fields from the table: "core_personaleimage" */
  core_personaleimage_aggregate: Core_Personaleimage_Aggregate;
  /** fetch data from the table: "core_personaleimage" using primary key columns */
  core_personaleimage_by_pk?: Maybe<Core_Personaleimage>;
  /** fetch data from the table: "core_personnelassigned" */
  core_personnelassigned: Array<Core_Personnelassigned>;
  /** fetch aggregated fields from the table: "core_personnelassigned" */
  core_personnelassigned_aggregate: Core_Personnelassigned_Aggregate;
  /** fetch data from the table: "core_personnelassigned" using primary key columns */
  core_personnelassigned_by_pk?: Maybe<Core_Personnelassigned>;
  /** fetch data from the table: "core_powerprovider" */
  core_powerprovider: Array<Core_Powerprovider>;
  /** fetch aggregated fields from the table: "core_powerprovider" */
  core_powerprovider_aggregate: Core_Powerprovider_Aggregate;
  /** fetch data from the table: "core_powerprovider" using primary key columns */
  core_powerprovider_by_pk?: Maybe<Core_Powerprovider>;
  /** fetch data from the table: "core_powersourcetype" */
  core_powersourcetype: Array<Core_Powersourcetype>;
  /** fetch aggregated fields from the table: "core_powersourcetype" */
  core_powersourcetype_aggregate: Core_Powersourcetype_Aggregate;
  /** fetch data from the table: "core_powersourcetype" using primary key columns */
  core_powersourcetype_by_pk?: Maybe<Core_Powersourcetype>;
  /** fetch data from the table: "core_province" */
  core_province: Array<Core_Province>;
  /** fetch aggregated fields from the table: "core_province" */
  core_province_aggregate: Core_Province_Aggregate;
  /** fetch data from the table: "core_province" using primary key columns */
  core_province_by_pk?: Maybe<Core_Province>;
  /** fetch data from the table: "core_rectifieralarm" */
  core_rectifieralarm: Array<Core_Rectifieralarm>;
  /** fetch aggregated fields from the table: "core_rectifieralarm" */
  core_rectifieralarm_aggregate: Core_Rectifieralarm_Aggregate;
  /** fetch data from the table: "core_rectifieralarm" using primary key columns */
  core_rectifieralarm_by_pk?: Maybe<Core_Rectifieralarm>;
  /** fetch data from the table: "core_requestaccess" */
  core_requestaccess: Array<Core_Requestaccess>;
  /** fetch aggregated fields from the table: "core_requestaccess" */
  core_requestaccess_aggregate: Core_Requestaccess_Aggregate;
  /** fetch data from the table: "core_requestaccess" using primary key columns */
  core_requestaccess_by_pk?: Maybe<Core_Requestaccess>;
  /** fetch data from the table: "core_res_company_wide_charge_estimate" */
  core_res_company_wide_charge_estimate: Array<Core_Res_Company_Wide_Charge_Estimate>;
  /** fetch aggregated fields from the table: "core_res_company_wide_charge_estimate" */
  core_res_company_wide_charge_estimate_aggregate: Core_Res_Company_Wide_Charge_Estimate_Aggregate;
  /** fetch data from the table: "core_resjobstatus" */
  core_resjobstatus: Array<Core_Resjobstatus>;
  /** fetch aggregated fields from the table: "core_resjobstatus" */
  core_resjobstatus_aggregate: Core_Resjobstatus_Aggregate;
  /** fetch data from the table: "core_resjobstatus" using primary key columns */
  core_resjobstatus_by_pk?: Maybe<Core_Resjobstatus>;
  /** fetch data from the table: "core_role" */
  core_role: Array<Core_Role>;
  /** fetch aggregated fields from the table: "core_role" */
  core_role_aggregate: Core_Role_Aggregate;
  /** fetch data from the table: "core_role" using primary key columns */
  core_role_by_pk?: Maybe<Core_Role>;
  /** fetch data from the table: "core_router" */
  core_router: Array<Core_Router>;
  /** fetch aggregated fields from the table: "core_router" */
  core_router_aggregate: Core_Router_Aggregate;
  /** fetch data from the table: "core_router" using primary key columns */
  core_router_by_pk?: Maybe<Core_Router>;
  /** An array relationship */
  core_securitycallouts: Array<Core_Securitycallouts>;
  /** An aggregate relationship */
  core_securitycallouts_aggregate: Core_Securitycallouts_Aggregate;
  /** fetch data from the table: "core_securitycallouts" using primary key columns */
  core_securitycallouts_by_pk?: Maybe<Core_Securitycallouts>;
  /** fetch data from the table: "core_securitycalloutsstatus" */
  core_securitycalloutsstatus: Array<Core_Securitycalloutsstatus>;
  /** fetch aggregated fields from the table: "core_securitycalloutsstatus" */
  core_securitycalloutsstatus_aggregate: Core_Securitycalloutsstatus_Aggregate;
  /** fetch data from the table: "core_securitycalloutsstatus" using primary key columns */
  core_securitycalloutsstatus_by_pk?: Maybe<Core_Securitycalloutsstatus>;
  /** fetch data from the table: "core_simcard" */
  core_simcard: Array<Core_Simcard>;
  /** fetch aggregated fields from the table: "core_simcard" */
  core_simcard_aggregate: Core_Simcard_Aggregate;
  /** fetch data from the table: "core_simcard" using primary key columns */
  core_simcard_by_pk?: Maybe<Core_Simcard>;
  /** fetch data from the table: "core_simcardstatus" */
  core_simcardstatus: Array<Core_Simcardstatus>;
  /** fetch aggregated fields from the table: "core_simcardstatus" */
  core_simcardstatus_aggregate: Core_Simcardstatus_Aggregate;
  /** fetch data from the table: "core_simcardstatus" using primary key columns */
  core_simcardstatus_by_pk?: Maybe<Core_Simcardstatus>;
  /** fetch data from the table: "core_site" */
  core_site: Array<Core_Site>;
  /** fetch aggregated fields from the table: "core_site" */
  core_site_aggregate: Core_Site_Aggregate;
  /** fetch data from the table: "core_site" using primary key columns */
  core_site_by_pk?: Maybe<Core_Site>;
  /** fetch data from the table: "core_sitechargeestimate" */
  core_sitechargeestimate: Array<Core_Sitechargeestimate>;
  /** fetch aggregated fields from the table: "core_sitechargeestimate" */
  core_sitechargeestimate_aggregate: Core_Sitechargeestimate_Aggregate;
  /** fetch data from the table: "core_sitechargeestimate" using primary key columns */
  core_sitechargeestimate_by_pk?: Maybe<Core_Sitechargeestimate>;
  /** fetch data from the table: "core_siteregion" */
  core_siteregion: Array<Core_Siteregion>;
  /** fetch aggregated fields from the table: "core_siteregion" */
  core_siteregion_aggregate: Core_Siteregion_Aggregate;
  /** fetch data from the table: "core_siteregion" using primary key columns */
  core_siteregion_by_pk?: Maybe<Core_Siteregion>;
  /** fetch data from the table: "core_sitesecuritycoverage" */
  core_sitesecuritycoverage: Array<Core_Sitesecuritycoverage>;
  /** fetch aggregated fields from the table: "core_sitesecuritycoverage" */
  core_sitesecuritycoverage_aggregate: Core_Sitesecuritycoverage_Aggregate;
  /** fetch data from the table: "core_sitesecuritycoverage" using primary key columns */
  core_sitesecuritycoverage_by_pk?: Maybe<Core_Sitesecuritycoverage>;
  /** fetch data from the table: "core_sitestatus" */
  core_sitestatus: Array<Core_Sitestatus>;
  /** fetch aggregated fields from the table: "core_sitestatus" */
  core_sitestatus_aggregate: Core_Sitestatus_Aggregate;
  /** fetch data from the table: "core_sitestatus" using primary key columns */
  core_sitestatus_by_pk?: Maybe<Core_Sitestatus>;
  /** fetch data from the table: "core_sitetype" */
  core_sitetype: Array<Core_Sitetype>;
  /** fetch aggregated fields from the table: "core_sitetype" */
  core_sitetype_aggregate: Core_Sitetype_Aggregate;
  /** fetch data from the table: "core_sitetype" using primary key columns */
  core_sitetype_by_pk?: Maybe<Core_Sitetype>;
  /** fetch data from the table: "core_sundrycharge" */
  core_sundrycharge: Array<Core_Sundrycharge>;
  /** fetch aggregated fields from the table: "core_sundrycharge" */
  core_sundrycharge_aggregate: Core_Sundrycharge_Aggregate;
  /** fetch data from the table: "core_sundrycharge" using primary key columns */
  core_sundrycharge_by_pk?: Maybe<Core_Sundrycharge>;
  /** fetch data from the table: "core_sundrychargetype" */
  core_sundrychargetype: Array<Core_Sundrychargetype>;
  /** fetch aggregated fields from the table: "core_sundrychargetype" */
  core_sundrychargetype_aggregate: Core_Sundrychargetype_Aggregate;
  /** fetch data from the table: "core_sundrychargetype" using primary key columns */
  core_sundrychargetype_by_pk?: Maybe<Core_Sundrychargetype>;
  /** fetch data from the table: "core_tariff" */
  core_tariff: Array<Core_Tariff>;
  /** fetch aggregated fields from the table: "core_tariff" */
  core_tariff_aggregate: Core_Tariff_Aggregate;
  /** fetch data from the table: "core_tariff" using primary key columns */
  core_tariff_by_pk?: Maybe<Core_Tariff>;
  /** fetch data from the table: "core_user" */
  core_user: Array<Core_User>;
  /** fetch aggregated fields from the table: "core_user" */
  core_user_aggregate: Core_User_Aggregate;
  /** fetch data from the table: "core_user" using primary key columns */
  core_user_by_pk?: Maybe<Core_User>;
  /** An array relationship */
  core_user_groups: Array<Core_User_Groups>;
  /** An aggregate relationship */
  core_user_groups_aggregate: Core_User_Groups_Aggregate;
  /** fetch data from the table: "core_user_groups" using primary key columns */
  core_user_groups_by_pk?: Maybe<Core_User_Groups>;
  /** An array relationship */
  core_user_user_permissions: Array<Core_User_User_Permissions>;
  /** An aggregate relationship */
  core_user_user_permissions_aggregate: Core_User_User_Permissions_Aggregate;
  /** fetch data from the table: "core_user_user_permissions" using primary key columns */
  core_user_user_permissions_by_pk?: Maybe<Core_User_User_Permissions>;
  /** fetch data from the table: "core_visit" */
  core_visit: Array<Core_Visit>;
  /** fetch aggregated fields from the table: "core_visit" */
  core_visit_aggregate: Core_Visit_Aggregate;
  /** fetch data from the table: "core_visit" using primary key columns */
  core_visit_by_pk?: Maybe<Core_Visit>;
  /** fetch data from the table: "core_visitstatusenum" */
  core_visitstatusenum: Array<Core_Visitstatusenum>;
  /** fetch aggregated fields from the table: "core_visitstatusenum" */
  core_visitstatusenum_aggregate: Core_Visitstatusenum_Aggregate;
  /** fetch data from the table: "core_visitstatusenum" using primary key columns */
  core_visitstatusenum_by_pk?: Maybe<Core_Visitstatusenum>;
  /** fetch data from the table: "django_admin_log" */
  django_admin_log: Array<Django_Admin_Log>;
  /** fetch aggregated fields from the table: "django_admin_log" */
  django_admin_log_aggregate: Django_Admin_Log_Aggregate;
  /** fetch data from the table: "django_admin_log" using primary key columns */
  django_admin_log_by_pk?: Maybe<Django_Admin_Log>;
  /** fetch data from the table: "django_content_type" */
  django_content_type: Array<Django_Content_Type>;
  /** fetch aggregated fields from the table: "django_content_type" */
  django_content_type_aggregate: Django_Content_Type_Aggregate;
  /** fetch data from the table: "django_content_type" using primary key columns */
  django_content_type_by_pk?: Maybe<Django_Content_Type>;
  /** fetch data from the table: "django_migrations" */
  django_migrations: Array<Django_Migrations>;
  /** fetch aggregated fields from the table: "django_migrations" */
  django_migrations_aggregate: Django_Migrations_Aggregate;
  /** fetch data from the table: "django_migrations" using primary key columns */
  django_migrations_by_pk?: Maybe<Django_Migrations>;
  /** fetch data from the table: "django_session" */
  django_session: Array<Django_Session>;
  /** fetch aggregated fields from the table: "django_session" */
  django_session_aggregate: Django_Session_Aggregate;
  /** fetch data from the table: "django_session" using primary key columns */
  django_session_by_pk?: Maybe<Django_Session>;
  /** execute function "getcompanywidesitechargecostandunitsbycompanyid" which returns "core_res_company_wide_charge_estimate" */
  getcompanywidesitechargecostandunitsbycompanyid: Array<Core_Res_Company_Wide_Charge_Estimate>;
  /** execute function "getcompanywidesitechargecostandunitsbycompanyid" and query aggregates on result of table type "core_res_company_wide_charge_estimate" */
  getcompanywidesitechargecostandunitsbycompanyid_aggregate: Core_Res_Company_Wide_Charge_Estimate_Aggregate;
  /** execute function "getsitechargecostandunitsbysiteid" which returns "core_sitechargeestimate" */
  getsitechargecostandunitsbysiteid: Array<Core_Sitechargeestimate>;
  /** execute function "getsitechargecostandunitsbysiteid" and query aggregates on result of table type "core_sitechargeestimate" */
  getsitechargecostandunitsbysiteid_aggregate: Core_Sitechargeestimate_Aggregate;
};


export type Query_RootAuth_GroupArgs = {
  distinct_on?: InputMaybe<Array<Auth_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Group_Order_By>>;
  where?: InputMaybe<Auth_Group_Bool_Exp>;
};


export type Query_RootAuth_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Group_Order_By>>;
  where?: InputMaybe<Auth_Group_Bool_Exp>;
};


export type Query_RootAuth_Group_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAuth_Group_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Group_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Group_Permissions_Order_By>>;
  where?: InputMaybe<Auth_Group_Permissions_Bool_Exp>;
};


export type Query_RootAuth_Group_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Group_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Group_Permissions_Order_By>>;
  where?: InputMaybe<Auth_Group_Permissions_Bool_Exp>;
};


export type Query_RootAuth_Group_Permissions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootAuth_PermissionArgs = {
  distinct_on?: InputMaybe<Array<Auth_Permission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Permission_Order_By>>;
  where?: InputMaybe<Auth_Permission_Bool_Exp>;
};


export type Query_RootAuth_Permission_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Permission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Permission_Order_By>>;
  where?: InputMaybe<Auth_Permission_Bool_Exp>;
};


export type Query_RootAuth_Permission_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAuthtoken_TokenArgs = {
  distinct_on?: InputMaybe<Array<Authtoken_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Authtoken_Token_Order_By>>;
  where?: InputMaybe<Authtoken_Token_Bool_Exp>;
};


export type Query_RootAuthtoken_Token_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Authtoken_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Authtoken_Token_Order_By>>;
  where?: InputMaybe<Authtoken_Token_Bool_Exp>;
};


export type Query_RootAuthtoken_Token_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootCore_AgreementArgs = {
  distinct_on?: InputMaybe<Array<Core_Agreement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Agreement_Order_By>>;
  where?: InputMaybe<Core_Agreement_Bool_Exp>;
};


export type Query_RootCore_Agreement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Agreement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Agreement_Order_By>>;
  where?: InputMaybe<Core_Agreement_Bool_Exp>;
};


export type Query_RootCore_Agreement_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_BoxArgs = {
  distinct_on?: InputMaybe<Array<Core_Box_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Box_Order_By>>;
  where?: InputMaybe<Core_Box_Bool_Exp>;
};


export type Query_RootCore_Box_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Box_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Box_Order_By>>;
  where?: InputMaybe<Core_Box_Bool_Exp>;
};


export type Query_RootCore_Box_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_BoxstatusArgs = {
  distinct_on?: InputMaybe<Array<Core_Boxstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Boxstatus_Order_By>>;
  where?: InputMaybe<Core_Boxstatus_Bool_Exp>;
};


export type Query_RootCore_Boxstatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Boxstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Boxstatus_Order_By>>;
  where?: InputMaybe<Core_Boxstatus_Bool_Exp>;
};


export type Query_RootCore_Boxstatus_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_CabinArgs = {
  distinct_on?: InputMaybe<Array<Core_Cabin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Cabin_Order_By>>;
  where?: InputMaybe<Core_Cabin_Bool_Exp>;
};


export type Query_RootCore_Cabin_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Cabin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Cabin_Order_By>>;
  where?: InputMaybe<Core_Cabin_Bool_Exp>;
};


export type Query_RootCore_Cabin_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_CameraArgs = {
  distinct_on?: InputMaybe<Array<Core_Camera_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Camera_Order_By>>;
  where?: InputMaybe<Core_Camera_Bool_Exp>;
};


export type Query_RootCore_Camera_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Camera_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Camera_Order_By>>;
  where?: InputMaybe<Core_Camera_Bool_Exp>;
};


export type Query_RootCore_Camera_By_PkArgs = {
  camera_id: Scalars['String'];
};


export type Query_RootCore_CompanyArgs = {
  distinct_on?: InputMaybe<Array<Core_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Company_Order_By>>;
  where?: InputMaybe<Core_Company_Bool_Exp>;
};


export type Query_RootCore_Company_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Company_Order_By>>;
  where?: InputMaybe<Core_Company_Bool_Exp>;
};


export type Query_RootCore_Company_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_CompanysitechargeestimateArgs = {
  distinct_on?: InputMaybe<Array<Core_Companysitechargeestimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Companysitechargeestimate_Order_By>>;
  where?: InputMaybe<Core_Companysitechargeestimate_Bool_Exp>;
};


export type Query_RootCore_Companysitechargeestimate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Companysitechargeestimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Companysitechargeestimate_Order_By>>;
  where?: InputMaybe<Core_Companysitechargeestimate_Bool_Exp>;
};


export type Query_RootCore_Companysitechargeestimate_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_CustomertypeArgs = {
  distinct_on?: InputMaybe<Array<Core_Customertype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Customertype_Order_By>>;
  where?: InputMaybe<Core_Customertype_Bool_Exp>;
};


export type Query_RootCore_Customertype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Customertype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Customertype_Order_By>>;
  where?: InputMaybe<Core_Customertype_Bool_Exp>;
};


export type Query_RootCore_Customertype_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_DisruptionsArgs = {
  distinct_on?: InputMaybe<Array<Core_Disruptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Disruptions_Order_By>>;
  where?: InputMaybe<Core_Disruptions_Bool_Exp>;
};


export type Query_RootCore_Disruptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Disruptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Disruptions_Order_By>>;
  where?: InputMaybe<Core_Disruptions_Bool_Exp>;
};


export type Query_RootCore_Disruptions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_DisruptionstypeArgs = {
  distinct_on?: InputMaybe<Array<Core_Disruptionstype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Disruptionstype_Order_By>>;
  where?: InputMaybe<Core_Disruptionstype_Bool_Exp>;
};


export type Query_RootCore_Disruptionstype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Disruptionstype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Disruptionstype_Order_By>>;
  where?: InputMaybe<Core_Disruptionstype_Bool_Exp>;
};


export type Query_RootCore_Disruptionstype_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_EnergychargeArgs = {
  distinct_on?: InputMaybe<Array<Core_Energycharge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Energycharge_Order_By>>;
  where?: InputMaybe<Core_Energycharge_Bool_Exp>;
};


export type Query_RootCore_Energycharge_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Energycharge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Energycharge_Order_By>>;
  where?: InputMaybe<Core_Energycharge_Bool_Exp>;
};


export type Query_RootCore_Energycharge_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_EquipmentArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipment_Order_By>>;
  where?: InputMaybe<Core_Equipment_Bool_Exp>;
};


export type Query_RootCore_Equipment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipment_Order_By>>;
  where?: InputMaybe<Core_Equipment_Bool_Exp>;
};


export type Query_RootCore_Equipment_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_EquipmentconditionArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentcondition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentcondition_Order_By>>;
  where?: InputMaybe<Core_Equipmentcondition_Bool_Exp>;
};


export type Query_RootCore_Equipmentcondition_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentcondition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentcondition_Order_By>>;
  where?: InputMaybe<Core_Equipmentcondition_Bool_Exp>;
};


export type Query_RootCore_Equipmentcondition_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_EquipmentimageArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentimage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentimage_Order_By>>;
  where?: InputMaybe<Core_Equipmentimage_Bool_Exp>;
};


export type Query_RootCore_Equipmentimage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentimage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentimage_Order_By>>;
  where?: InputMaybe<Core_Equipmentimage_Bool_Exp>;
};


export type Query_RootCore_Equipmentimage_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_EquipmentmakeArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentmake_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentmake_Order_By>>;
  where?: InputMaybe<Core_Equipmentmake_Bool_Exp>;
};


export type Query_RootCore_Equipmentmake_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentmake_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentmake_Order_By>>;
  where?: InputMaybe<Core_Equipmentmake_Bool_Exp>;
};


export type Query_RootCore_Equipmentmake_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_EquipmentmodelArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentmodel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentmodel_Order_By>>;
  where?: InputMaybe<Core_Equipmentmodel_Bool_Exp>;
};


export type Query_RootCore_Equipmentmodel_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentmodel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentmodel_Order_By>>;
  where?: InputMaybe<Core_Equipmentmodel_Bool_Exp>;
};


export type Query_RootCore_Equipmentmodel_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_EquipmentpartArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentpart_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentpart_Order_By>>;
  where?: InputMaybe<Core_Equipmentpart_Bool_Exp>;
};


export type Query_RootCore_Equipmentpart_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentpart_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentpart_Order_By>>;
  where?: InputMaybe<Core_Equipmentpart_Bool_Exp>;
};


export type Query_RootCore_Equipmentpart_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_EquipmentstatusArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentstatus_Order_By>>;
  where?: InputMaybe<Core_Equipmentstatus_Bool_Exp>;
};


export type Query_RootCore_Equipmentstatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentstatus_Order_By>>;
  where?: InputMaybe<Core_Equipmentstatus_Bool_Exp>;
};


export type Query_RootCore_Equipmentstatus_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_EquipmenttypeArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmenttype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmenttype_Order_By>>;
  where?: InputMaybe<Core_Equipmenttype_Bool_Exp>;
};


export type Query_RootCore_Equipmenttype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmenttype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmenttype_Order_By>>;
  where?: InputMaybe<Core_Equipmenttype_Bool_Exp>;
};


export type Query_RootCore_Equipmenttype_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_EspArgs = {
  distinct_on?: InputMaybe<Array<Core_Esp_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Esp_Order_By>>;
  where?: InputMaybe<Core_Esp_Bool_Exp>;
};


export type Query_RootCore_Esp_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Esp_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Esp_Order_By>>;
  where?: InputMaybe<Core_Esp_Bool_Exp>;
};


export type Query_RootCore_Esp_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_EspstatusArgs = {
  distinct_on?: InputMaybe<Array<Core_Espstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Espstatus_Order_By>>;
  where?: InputMaybe<Core_Espstatus_Bool_Exp>;
};


export type Query_RootCore_Espstatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Espstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Espstatus_Order_By>>;
  where?: InputMaybe<Core_Espstatus_Bool_Exp>;
};


export type Query_RootCore_Espstatus_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_EspstatusnextstagesArgs = {
  distinct_on?: InputMaybe<Array<Core_Espstatusnextstages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Espstatusnextstages_Order_By>>;
  where?: InputMaybe<Core_Espstatusnextstages_Bool_Exp>;
};


export type Query_RootCore_Espstatusnextstages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Espstatusnextstages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Espstatusnextstages_Order_By>>;
  where?: InputMaybe<Core_Espstatusnextstages_Bool_Exp>;
};


export type Query_RootCore_Espstatusnextstages_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_EventlogArgs = {
  distinct_on?: InputMaybe<Array<Core_Eventlog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Eventlog_Order_By>>;
  where?: InputMaybe<Core_Eventlog_Bool_Exp>;
};


export type Query_RootCore_Eventlog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Eventlog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Eventlog_Order_By>>;
  where?: InputMaybe<Core_Eventlog_Bool_Exp>;
};


export type Query_RootCore_Eventlog_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_IncidentArgs = {
  distinct_on?: InputMaybe<Array<Core_Incident_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Incident_Order_By>>;
  where?: InputMaybe<Core_Incident_Bool_Exp>;
};


export type Query_RootCore_Incident_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Incident_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Incident_Order_By>>;
  where?: InputMaybe<Core_Incident_Bool_Exp>;
};


export type Query_RootCore_Incident_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_JobArgs = {
  distinct_on?: InputMaybe<Array<Core_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Job_Order_By>>;
  where?: InputMaybe<Core_Job_Bool_Exp>;
};


export type Query_RootCore_Job_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Job_Order_By>>;
  where?: InputMaybe<Core_Job_Bool_Exp>;
};


export type Query_RootCore_Job_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_JobstatusArgs = {
  distinct_on?: InputMaybe<Array<Core_Jobstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Jobstatus_Order_By>>;
  where?: InputMaybe<Core_Jobstatus_Bool_Exp>;
};


export type Query_RootCore_Jobstatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Jobstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Jobstatus_Order_By>>;
  where?: InputMaybe<Core_Jobstatus_Bool_Exp>;
};


export type Query_RootCore_Jobstatus_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_JobstatusenumArgs = {
  distinct_on?: InputMaybe<Array<Core_Jobstatusenum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Jobstatusenum_Order_By>>;
  where?: InputMaybe<Core_Jobstatusenum_Bool_Exp>;
};


export type Query_RootCore_Jobstatusenum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Jobstatusenum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Jobstatusenum_Order_By>>;
  where?: InputMaybe<Core_Jobstatusenum_Bool_Exp>;
};


export type Query_RootCore_Jobstatusenum_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_LogArgs = {
  distinct_on?: InputMaybe<Array<Core_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Log_Order_By>>;
  where?: InputMaybe<Core_Log_Bool_Exp>;
};


export type Query_RootCore_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Log_Order_By>>;
  where?: InputMaybe<Core_Log_Bool_Exp>;
};


export type Query_RootCore_Log_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_LogfacialrecognitionArgs = {
  distinct_on?: InputMaybe<Array<Core_Logfacialrecognition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logfacialrecognition_Order_By>>;
  where?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
};


export type Query_RootCore_Logfacialrecognition_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Logfacialrecognition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logfacialrecognition_Order_By>>;
  where?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
};


export type Query_RootCore_Logfacialrecognition_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_LogimageArgs = {
  distinct_on?: InputMaybe<Array<Core_Logimage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logimage_Order_By>>;
  where?: InputMaybe<Core_Logimage_Bool_Exp>;
};


export type Query_RootCore_Logimage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Logimage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logimage_Order_By>>;
  where?: InputMaybe<Core_Logimage_Bool_Exp>;
};


export type Query_RootCore_Logimage_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_LogsuspiciosactivityArgs = {
  distinct_on?: InputMaybe<Array<Core_Logsuspiciosactivity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logsuspiciosactivity_Order_By>>;
  where?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
};


export type Query_RootCore_Logsuspiciosactivity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Logsuspiciosactivity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logsuspiciosactivity_Order_By>>;
  where?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
};


export type Query_RootCore_Logsuspiciosactivity_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_MeterArgs = {
  distinct_on?: InputMaybe<Array<Core_Meter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Meter_Order_By>>;
  where?: InputMaybe<Core_Meter_Bool_Exp>;
};


export type Query_RootCore_Meter_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Meter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Meter_Order_By>>;
  where?: InputMaybe<Core_Meter_Bool_Exp>;
};


export type Query_RootCore_Meter_By_PkArgs = {
  serial_number: Scalars['String'];
};


export type Query_RootCore_MeterreadingArgs = {
  distinct_on?: InputMaybe<Array<Core_Meterreading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Meterreading_Order_By>>;
  where?: InputMaybe<Core_Meterreading_Bool_Exp>;
};


export type Query_RootCore_Meterreading_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Meterreading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Meterreading_Order_By>>;
  where?: InputMaybe<Core_Meterreading_Bool_Exp>;
};


export type Query_RootCore_Meterreading_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_MetricArgs = {
  distinct_on?: InputMaybe<Array<Core_Metric_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Metric_Order_By>>;
  where?: InputMaybe<Core_Metric_Bool_Exp>;
};


export type Query_RootCore_Metric_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Metric_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Metric_Order_By>>;
  where?: InputMaybe<Core_Metric_Bool_Exp>;
};


export type Query_RootCore_Metric_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_MunicipalityArgs = {
  distinct_on?: InputMaybe<Array<Core_Municipality_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Municipality_Order_By>>;
  where?: InputMaybe<Core_Municipality_Bool_Exp>;
};


export type Query_RootCore_Municipality_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Municipality_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Municipality_Order_By>>;
  where?: InputMaybe<Core_Municipality_Bool_Exp>;
};


export type Query_RootCore_Municipality_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_NoneuserpersonaleArgs = {
  distinct_on?: InputMaybe<Array<Core_Noneuserpersonale_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Noneuserpersonale_Order_By>>;
  where?: InputMaybe<Core_Noneuserpersonale_Bool_Exp>;
};


export type Query_RootCore_Noneuserpersonale_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Noneuserpersonale_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Noneuserpersonale_Order_By>>;
  where?: InputMaybe<Core_Noneuserpersonale_Bool_Exp>;
};


export type Query_RootCore_Noneuserpersonale_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCore_PaymenttypeArgs = {
  distinct_on?: InputMaybe<Array<Core_Paymenttype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Paymenttype_Order_By>>;
  where?: InputMaybe<Core_Paymenttype_Bool_Exp>;
};


export type Query_RootCore_Paymenttype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Paymenttype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Paymenttype_Order_By>>;
  where?: InputMaybe<Core_Paymenttype_Bool_Exp>;
};


export type Query_RootCore_Paymenttype_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_PersonaleimageArgs = {
  distinct_on?: InputMaybe<Array<Core_Personaleimage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Personaleimage_Order_By>>;
  where?: InputMaybe<Core_Personaleimage_Bool_Exp>;
};


export type Query_RootCore_Personaleimage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Personaleimage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Personaleimage_Order_By>>;
  where?: InputMaybe<Core_Personaleimage_Bool_Exp>;
};


export type Query_RootCore_Personaleimage_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_PersonnelassignedArgs = {
  distinct_on?: InputMaybe<Array<Core_Personnelassigned_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Personnelassigned_Order_By>>;
  where?: InputMaybe<Core_Personnelassigned_Bool_Exp>;
};


export type Query_RootCore_Personnelassigned_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Personnelassigned_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Personnelassigned_Order_By>>;
  where?: InputMaybe<Core_Personnelassigned_Bool_Exp>;
};


export type Query_RootCore_Personnelassigned_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_PowerproviderArgs = {
  distinct_on?: InputMaybe<Array<Core_Powerprovider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Powerprovider_Order_By>>;
  where?: InputMaybe<Core_Powerprovider_Bool_Exp>;
};


export type Query_RootCore_Powerprovider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Powerprovider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Powerprovider_Order_By>>;
  where?: InputMaybe<Core_Powerprovider_Bool_Exp>;
};


export type Query_RootCore_Powerprovider_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_PowersourcetypeArgs = {
  distinct_on?: InputMaybe<Array<Core_Powersourcetype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Powersourcetype_Order_By>>;
  where?: InputMaybe<Core_Powersourcetype_Bool_Exp>;
};


export type Query_RootCore_Powersourcetype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Powersourcetype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Powersourcetype_Order_By>>;
  where?: InputMaybe<Core_Powersourcetype_Bool_Exp>;
};


export type Query_RootCore_Powersourcetype_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_ProvinceArgs = {
  distinct_on?: InputMaybe<Array<Core_Province_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Province_Order_By>>;
  where?: InputMaybe<Core_Province_Bool_Exp>;
};


export type Query_RootCore_Province_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Province_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Province_Order_By>>;
  where?: InputMaybe<Core_Province_Bool_Exp>;
};


export type Query_RootCore_Province_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_RectifieralarmArgs = {
  distinct_on?: InputMaybe<Array<Core_Rectifieralarm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Rectifieralarm_Order_By>>;
  where?: InputMaybe<Core_Rectifieralarm_Bool_Exp>;
};


export type Query_RootCore_Rectifieralarm_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Rectifieralarm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Rectifieralarm_Order_By>>;
  where?: InputMaybe<Core_Rectifieralarm_Bool_Exp>;
};


export type Query_RootCore_Rectifieralarm_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_RequestaccessArgs = {
  distinct_on?: InputMaybe<Array<Core_Requestaccess_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Requestaccess_Order_By>>;
  where?: InputMaybe<Core_Requestaccess_Bool_Exp>;
};


export type Query_RootCore_Requestaccess_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Requestaccess_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Requestaccess_Order_By>>;
  where?: InputMaybe<Core_Requestaccess_Bool_Exp>;
};


export type Query_RootCore_Requestaccess_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_Res_Company_Wide_Charge_EstimateArgs = {
  distinct_on?: InputMaybe<Array<Core_Res_Company_Wide_Charge_Estimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Res_Company_Wide_Charge_Estimate_Order_By>>;
  where?: InputMaybe<Core_Res_Company_Wide_Charge_Estimate_Bool_Exp>;
};


export type Query_RootCore_Res_Company_Wide_Charge_Estimate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Res_Company_Wide_Charge_Estimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Res_Company_Wide_Charge_Estimate_Order_By>>;
  where?: InputMaybe<Core_Res_Company_Wide_Charge_Estimate_Bool_Exp>;
};


export type Query_RootCore_ResjobstatusArgs = {
  distinct_on?: InputMaybe<Array<Core_Resjobstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Resjobstatus_Order_By>>;
  where?: InputMaybe<Core_Resjobstatus_Bool_Exp>;
};


export type Query_RootCore_Resjobstatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Resjobstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Resjobstatus_Order_By>>;
  where?: InputMaybe<Core_Resjobstatus_Bool_Exp>;
};


export type Query_RootCore_Resjobstatus_By_PkArgs = {
  job_id: Scalars['bigint'];
};


export type Query_RootCore_RoleArgs = {
  distinct_on?: InputMaybe<Array<Core_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Role_Order_By>>;
  where?: InputMaybe<Core_Role_Bool_Exp>;
};


export type Query_RootCore_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Role_Order_By>>;
  where?: InputMaybe<Core_Role_Bool_Exp>;
};


export type Query_RootCore_Role_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_RouterArgs = {
  distinct_on?: InputMaybe<Array<Core_Router_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Router_Order_By>>;
  where?: InputMaybe<Core_Router_Bool_Exp>;
};


export type Query_RootCore_Router_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Router_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Router_Order_By>>;
  where?: InputMaybe<Core_Router_Bool_Exp>;
};


export type Query_RootCore_Router_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_SecuritycalloutsArgs = {
  distinct_on?: InputMaybe<Array<Core_Securitycallouts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Securitycallouts_Order_By>>;
  where?: InputMaybe<Core_Securitycallouts_Bool_Exp>;
};


export type Query_RootCore_Securitycallouts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Securitycallouts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Securitycallouts_Order_By>>;
  where?: InputMaybe<Core_Securitycallouts_Bool_Exp>;
};


export type Query_RootCore_Securitycallouts_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_SecuritycalloutsstatusArgs = {
  distinct_on?: InputMaybe<Array<Core_Securitycalloutsstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Securitycalloutsstatus_Order_By>>;
  where?: InputMaybe<Core_Securitycalloutsstatus_Bool_Exp>;
};


export type Query_RootCore_Securitycalloutsstatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Securitycalloutsstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Securitycalloutsstatus_Order_By>>;
  where?: InputMaybe<Core_Securitycalloutsstatus_Bool_Exp>;
};


export type Query_RootCore_Securitycalloutsstatus_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_SimcardArgs = {
  distinct_on?: InputMaybe<Array<Core_Simcard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Simcard_Order_By>>;
  where?: InputMaybe<Core_Simcard_Bool_Exp>;
};


export type Query_RootCore_Simcard_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Simcard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Simcard_Order_By>>;
  where?: InputMaybe<Core_Simcard_Bool_Exp>;
};


export type Query_RootCore_Simcard_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_SimcardstatusArgs = {
  distinct_on?: InputMaybe<Array<Core_Simcardstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Simcardstatus_Order_By>>;
  where?: InputMaybe<Core_Simcardstatus_Bool_Exp>;
};


export type Query_RootCore_Simcardstatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Simcardstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Simcardstatus_Order_By>>;
  where?: InputMaybe<Core_Simcardstatus_Bool_Exp>;
};


export type Query_RootCore_Simcardstatus_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_SiteArgs = {
  distinct_on?: InputMaybe<Array<Core_Site_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Site_Order_By>>;
  where?: InputMaybe<Core_Site_Bool_Exp>;
};


export type Query_RootCore_Site_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Site_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Site_Order_By>>;
  where?: InputMaybe<Core_Site_Bool_Exp>;
};


export type Query_RootCore_Site_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_SitechargeestimateArgs = {
  distinct_on?: InputMaybe<Array<Core_Sitechargeestimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitechargeestimate_Order_By>>;
  where?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
};


export type Query_RootCore_Sitechargeestimate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Sitechargeestimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitechargeestimate_Order_By>>;
  where?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
};


export type Query_RootCore_Sitechargeestimate_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_SiteregionArgs = {
  distinct_on?: InputMaybe<Array<Core_Siteregion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Siteregion_Order_By>>;
  where?: InputMaybe<Core_Siteregion_Bool_Exp>;
};


export type Query_RootCore_Siteregion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Siteregion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Siteregion_Order_By>>;
  where?: InputMaybe<Core_Siteregion_Bool_Exp>;
};


export type Query_RootCore_Siteregion_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_SitesecuritycoverageArgs = {
  distinct_on?: InputMaybe<Array<Core_Sitesecuritycoverage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitesecuritycoverage_Order_By>>;
  where?: InputMaybe<Core_Sitesecuritycoverage_Bool_Exp>;
};


export type Query_RootCore_Sitesecuritycoverage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Sitesecuritycoverage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitesecuritycoverage_Order_By>>;
  where?: InputMaybe<Core_Sitesecuritycoverage_Bool_Exp>;
};


export type Query_RootCore_Sitesecuritycoverage_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_SitestatusArgs = {
  distinct_on?: InputMaybe<Array<Core_Sitestatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitestatus_Order_By>>;
  where?: InputMaybe<Core_Sitestatus_Bool_Exp>;
};


export type Query_RootCore_Sitestatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Sitestatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitestatus_Order_By>>;
  where?: InputMaybe<Core_Sitestatus_Bool_Exp>;
};


export type Query_RootCore_Sitestatus_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_SitetypeArgs = {
  distinct_on?: InputMaybe<Array<Core_Sitetype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitetype_Order_By>>;
  where?: InputMaybe<Core_Sitetype_Bool_Exp>;
};


export type Query_RootCore_Sitetype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Sitetype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitetype_Order_By>>;
  where?: InputMaybe<Core_Sitetype_Bool_Exp>;
};


export type Query_RootCore_Sitetype_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_SundrychargeArgs = {
  distinct_on?: InputMaybe<Array<Core_Sundrycharge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sundrycharge_Order_By>>;
  where?: InputMaybe<Core_Sundrycharge_Bool_Exp>;
};


export type Query_RootCore_Sundrycharge_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Sundrycharge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sundrycharge_Order_By>>;
  where?: InputMaybe<Core_Sundrycharge_Bool_Exp>;
};


export type Query_RootCore_Sundrycharge_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_SundrychargetypeArgs = {
  distinct_on?: InputMaybe<Array<Core_Sundrychargetype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sundrychargetype_Order_By>>;
  where?: InputMaybe<Core_Sundrychargetype_Bool_Exp>;
};


export type Query_RootCore_Sundrychargetype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Sundrychargetype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sundrychargetype_Order_By>>;
  where?: InputMaybe<Core_Sundrychargetype_Bool_Exp>;
};


export type Query_RootCore_Sundrychargetype_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_TariffArgs = {
  distinct_on?: InputMaybe<Array<Core_Tariff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Tariff_Order_By>>;
  where?: InputMaybe<Core_Tariff_Bool_Exp>;
};


export type Query_RootCore_Tariff_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Tariff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Tariff_Order_By>>;
  where?: InputMaybe<Core_Tariff_Bool_Exp>;
};


export type Query_RootCore_Tariff_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_UserArgs = {
  distinct_on?: InputMaybe<Array<Core_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_Order_By>>;
  where?: InputMaybe<Core_User_Bool_Exp>;
};


export type Query_RootCore_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_Order_By>>;
  where?: InputMaybe<Core_User_Bool_Exp>;
};


export type Query_RootCore_User_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_User_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Core_User_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_Groups_Order_By>>;
  where?: InputMaybe<Core_User_Groups_Bool_Exp>;
};


export type Query_RootCore_User_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_User_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_Groups_Order_By>>;
  where?: InputMaybe<Core_User_Groups_Bool_Exp>;
};


export type Query_RootCore_User_Groups_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_User_User_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Core_User_User_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_User_Permissions_Order_By>>;
  where?: InputMaybe<Core_User_User_Permissions_Bool_Exp>;
};


export type Query_RootCore_User_User_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_User_User_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_User_Permissions_Order_By>>;
  where?: InputMaybe<Core_User_User_Permissions_Bool_Exp>;
};


export type Query_RootCore_User_User_Permissions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_VisitArgs = {
  distinct_on?: InputMaybe<Array<Core_Visit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Visit_Order_By>>;
  where?: InputMaybe<Core_Visit_Bool_Exp>;
};


export type Query_RootCore_Visit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Visit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Visit_Order_By>>;
  where?: InputMaybe<Core_Visit_Bool_Exp>;
};


export type Query_RootCore_Visit_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootCore_VisitstatusenumArgs = {
  distinct_on?: InputMaybe<Array<Core_Visitstatusenum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Visitstatusenum_Order_By>>;
  where?: InputMaybe<Core_Visitstatusenum_Bool_Exp>;
};


export type Query_RootCore_Visitstatusenum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Visitstatusenum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Visitstatusenum_Order_By>>;
  where?: InputMaybe<Core_Visitstatusenum_Bool_Exp>;
};


export type Query_RootCore_Visitstatusenum_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDjango_Admin_LogArgs = {
  distinct_on?: InputMaybe<Array<Django_Admin_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Django_Admin_Log_Order_By>>;
  where?: InputMaybe<Django_Admin_Log_Bool_Exp>;
};


export type Query_RootDjango_Admin_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Django_Admin_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Django_Admin_Log_Order_By>>;
  where?: InputMaybe<Django_Admin_Log_Bool_Exp>;
};


export type Query_RootDjango_Admin_Log_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDjango_Content_TypeArgs = {
  distinct_on?: InputMaybe<Array<Django_Content_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Django_Content_Type_Order_By>>;
  where?: InputMaybe<Django_Content_Type_Bool_Exp>;
};


export type Query_RootDjango_Content_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Django_Content_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Django_Content_Type_Order_By>>;
  where?: InputMaybe<Django_Content_Type_Bool_Exp>;
};


export type Query_RootDjango_Content_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDjango_MigrationsArgs = {
  distinct_on?: InputMaybe<Array<Django_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Django_Migrations_Order_By>>;
  where?: InputMaybe<Django_Migrations_Bool_Exp>;
};


export type Query_RootDjango_Migrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Django_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Django_Migrations_Order_By>>;
  where?: InputMaybe<Django_Migrations_Bool_Exp>;
};


export type Query_RootDjango_Migrations_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootDjango_SessionArgs = {
  distinct_on?: InputMaybe<Array<Django_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Django_Session_Order_By>>;
  where?: InputMaybe<Django_Session_Bool_Exp>;
};


export type Query_RootDjango_Session_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Django_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Django_Session_Order_By>>;
  where?: InputMaybe<Django_Session_Bool_Exp>;
};


export type Query_RootDjango_Session_By_PkArgs = {
  session_key: Scalars['String'];
};


export type Query_RootGetcompanywidesitechargecostandunitsbycompanyidArgs = {
  args: Getcompanywidesitechargecostandunitsbycompanyid_Args;
  distinct_on?: InputMaybe<Array<Core_Res_Company_Wide_Charge_Estimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Res_Company_Wide_Charge_Estimate_Order_By>>;
  where?: InputMaybe<Core_Res_Company_Wide_Charge_Estimate_Bool_Exp>;
};


export type Query_RootGetcompanywidesitechargecostandunitsbycompanyid_AggregateArgs = {
  args: Getcompanywidesitechargecostandunitsbycompanyid_Args;
  distinct_on?: InputMaybe<Array<Core_Res_Company_Wide_Charge_Estimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Res_Company_Wide_Charge_Estimate_Order_By>>;
  where?: InputMaybe<Core_Res_Company_Wide_Charge_Estimate_Bool_Exp>;
};


export type Query_RootGetsitechargecostandunitsbysiteidArgs = {
  args: Getsitechargecostandunitsbysiteid_Args;
  distinct_on?: InputMaybe<Array<Core_Sitechargeestimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitechargeestimate_Order_By>>;
  where?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
};


export type Query_RootGetsitechargecostandunitsbysiteid_AggregateArgs = {
  args: Getsitechargecostandunitsbysiteid_Args;
  distinct_on?: InputMaybe<Array<Core_Sitechargeestimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitechargeestimate_Order_By>>;
  where?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "auth_group" */
  auth_group: Array<Auth_Group>;
  /** fetch aggregated fields from the table: "auth_group" */
  auth_group_aggregate: Auth_Group_Aggregate;
  /** fetch data from the table: "auth_group" using primary key columns */
  auth_group_by_pk?: Maybe<Auth_Group>;
  /** An array relationship */
  auth_group_permissions: Array<Auth_Group_Permissions>;
  /** An aggregate relationship */
  auth_group_permissions_aggregate: Auth_Group_Permissions_Aggregate;
  /** fetch data from the table: "auth_group_permissions" using primary key columns */
  auth_group_permissions_by_pk?: Maybe<Auth_Group_Permissions>;
  /** fetch data from the table in a streaming manner: "auth_group_permissions" */
  auth_group_permissions_stream: Array<Auth_Group_Permissions>;
  /** fetch data from the table in a streaming manner: "auth_group" */
  auth_group_stream: Array<Auth_Group>;
  /** fetch data from the table: "auth_permission" */
  auth_permission: Array<Auth_Permission>;
  /** fetch aggregated fields from the table: "auth_permission" */
  auth_permission_aggregate: Auth_Permission_Aggregate;
  /** fetch data from the table: "auth_permission" using primary key columns */
  auth_permission_by_pk?: Maybe<Auth_Permission>;
  /** fetch data from the table in a streaming manner: "auth_permission" */
  auth_permission_stream: Array<Auth_Permission>;
  /** fetch data from the table: "authtoken_token" */
  authtoken_token: Array<Authtoken_Token>;
  /** fetch aggregated fields from the table: "authtoken_token" */
  authtoken_token_aggregate: Authtoken_Token_Aggregate;
  /** fetch data from the table: "authtoken_token" using primary key columns */
  authtoken_token_by_pk?: Maybe<Authtoken_Token>;
  /** fetch data from the table in a streaming manner: "authtoken_token" */
  authtoken_token_stream: Array<Authtoken_Token>;
  /** fetch data from the table: "core_agreement" */
  core_agreement: Array<Core_Agreement>;
  /** fetch aggregated fields from the table: "core_agreement" */
  core_agreement_aggregate: Core_Agreement_Aggregate;
  /** fetch data from the table: "core_agreement" using primary key columns */
  core_agreement_by_pk?: Maybe<Core_Agreement>;
  /** fetch data from the table in a streaming manner: "core_agreement" */
  core_agreement_stream: Array<Core_Agreement>;
  /** fetch data from the table: "core_box" */
  core_box: Array<Core_Box>;
  /** fetch aggregated fields from the table: "core_box" */
  core_box_aggregate: Core_Box_Aggregate;
  /** fetch data from the table: "core_box" using primary key columns */
  core_box_by_pk?: Maybe<Core_Box>;
  /** fetch data from the table in a streaming manner: "core_box" */
  core_box_stream: Array<Core_Box>;
  /** fetch data from the table: "core_boxstatus" */
  core_boxstatus: Array<Core_Boxstatus>;
  /** fetch aggregated fields from the table: "core_boxstatus" */
  core_boxstatus_aggregate: Core_Boxstatus_Aggregate;
  /** fetch data from the table: "core_boxstatus" using primary key columns */
  core_boxstatus_by_pk?: Maybe<Core_Boxstatus>;
  /** fetch data from the table in a streaming manner: "core_boxstatus" */
  core_boxstatus_stream: Array<Core_Boxstatus>;
  /** fetch data from the table: "core_cabin" */
  core_cabin: Array<Core_Cabin>;
  /** fetch aggregated fields from the table: "core_cabin" */
  core_cabin_aggregate: Core_Cabin_Aggregate;
  /** fetch data from the table: "core_cabin" using primary key columns */
  core_cabin_by_pk?: Maybe<Core_Cabin>;
  /** fetch data from the table in a streaming manner: "core_cabin" */
  core_cabin_stream: Array<Core_Cabin>;
  /** fetch data from the table: "core_camera" */
  core_camera: Array<Core_Camera>;
  /** fetch aggregated fields from the table: "core_camera" */
  core_camera_aggregate: Core_Camera_Aggregate;
  /** fetch data from the table: "core_camera" using primary key columns */
  core_camera_by_pk?: Maybe<Core_Camera>;
  /** fetch data from the table in a streaming manner: "core_camera" */
  core_camera_stream: Array<Core_Camera>;
  /** fetch data from the table: "core_company" */
  core_company: Array<Core_Company>;
  /** fetch aggregated fields from the table: "core_company" */
  core_company_aggregate: Core_Company_Aggregate;
  /** fetch data from the table: "core_company" using primary key columns */
  core_company_by_pk?: Maybe<Core_Company>;
  /** fetch data from the table in a streaming manner: "core_company" */
  core_company_stream: Array<Core_Company>;
  /** fetch data from the table: "core_companysitechargeestimate" */
  core_companysitechargeestimate: Array<Core_Companysitechargeestimate>;
  /** fetch aggregated fields from the table: "core_companysitechargeestimate" */
  core_companysitechargeestimate_aggregate: Core_Companysitechargeestimate_Aggregate;
  /** fetch data from the table: "core_companysitechargeestimate" using primary key columns */
  core_companysitechargeestimate_by_pk?: Maybe<Core_Companysitechargeestimate>;
  /** fetch data from the table in a streaming manner: "core_companysitechargeestimate" */
  core_companysitechargeestimate_stream: Array<Core_Companysitechargeestimate>;
  /** fetch data from the table: "core_customertype" */
  core_customertype: Array<Core_Customertype>;
  /** fetch aggregated fields from the table: "core_customertype" */
  core_customertype_aggregate: Core_Customertype_Aggregate;
  /** fetch data from the table: "core_customertype" using primary key columns */
  core_customertype_by_pk?: Maybe<Core_Customertype>;
  /** fetch data from the table in a streaming manner: "core_customertype" */
  core_customertype_stream: Array<Core_Customertype>;
  /** An array relationship */
  core_disruptions: Array<Core_Disruptions>;
  /** An aggregate relationship */
  core_disruptions_aggregate: Core_Disruptions_Aggregate;
  /** fetch data from the table: "core_disruptions" using primary key columns */
  core_disruptions_by_pk?: Maybe<Core_Disruptions>;
  /** fetch data from the table in a streaming manner: "core_disruptions" */
  core_disruptions_stream: Array<Core_Disruptions>;
  /** fetch data from the table: "core_disruptionstype" */
  core_disruptionstype: Array<Core_Disruptionstype>;
  /** fetch aggregated fields from the table: "core_disruptionstype" */
  core_disruptionstype_aggregate: Core_Disruptionstype_Aggregate;
  /** fetch data from the table: "core_disruptionstype" using primary key columns */
  core_disruptionstype_by_pk?: Maybe<Core_Disruptionstype>;
  /** fetch data from the table in a streaming manner: "core_disruptionstype" */
  core_disruptionstype_stream: Array<Core_Disruptionstype>;
  /** fetch data from the table: "core_energycharge" */
  core_energycharge: Array<Core_Energycharge>;
  /** fetch aggregated fields from the table: "core_energycharge" */
  core_energycharge_aggregate: Core_Energycharge_Aggregate;
  /** fetch data from the table: "core_energycharge" using primary key columns */
  core_energycharge_by_pk?: Maybe<Core_Energycharge>;
  /** fetch data from the table in a streaming manner: "core_energycharge" */
  core_energycharge_stream: Array<Core_Energycharge>;
  /** fetch data from the table: "core_equipment" */
  core_equipment: Array<Core_Equipment>;
  /** fetch aggregated fields from the table: "core_equipment" */
  core_equipment_aggregate: Core_Equipment_Aggregate;
  /** fetch data from the table: "core_equipment" using primary key columns */
  core_equipment_by_pk?: Maybe<Core_Equipment>;
  /** fetch data from the table in a streaming manner: "core_equipment" */
  core_equipment_stream: Array<Core_Equipment>;
  /** fetch data from the table: "core_equipmentcondition" */
  core_equipmentcondition: Array<Core_Equipmentcondition>;
  /** fetch aggregated fields from the table: "core_equipmentcondition" */
  core_equipmentcondition_aggregate: Core_Equipmentcondition_Aggregate;
  /** fetch data from the table: "core_equipmentcondition" using primary key columns */
  core_equipmentcondition_by_pk?: Maybe<Core_Equipmentcondition>;
  /** fetch data from the table in a streaming manner: "core_equipmentcondition" */
  core_equipmentcondition_stream: Array<Core_Equipmentcondition>;
  /** fetch data from the table: "core_equipmentimage" */
  core_equipmentimage: Array<Core_Equipmentimage>;
  /** fetch aggregated fields from the table: "core_equipmentimage" */
  core_equipmentimage_aggregate: Core_Equipmentimage_Aggregate;
  /** fetch data from the table: "core_equipmentimage" using primary key columns */
  core_equipmentimage_by_pk?: Maybe<Core_Equipmentimage>;
  /** fetch data from the table in a streaming manner: "core_equipmentimage" */
  core_equipmentimage_stream: Array<Core_Equipmentimage>;
  /** fetch data from the table: "core_equipmentmake" */
  core_equipmentmake: Array<Core_Equipmentmake>;
  /** fetch aggregated fields from the table: "core_equipmentmake" */
  core_equipmentmake_aggregate: Core_Equipmentmake_Aggregate;
  /** fetch data from the table: "core_equipmentmake" using primary key columns */
  core_equipmentmake_by_pk?: Maybe<Core_Equipmentmake>;
  /** fetch data from the table in a streaming manner: "core_equipmentmake" */
  core_equipmentmake_stream: Array<Core_Equipmentmake>;
  /** fetch data from the table: "core_equipmentmodel" */
  core_equipmentmodel: Array<Core_Equipmentmodel>;
  /** fetch aggregated fields from the table: "core_equipmentmodel" */
  core_equipmentmodel_aggregate: Core_Equipmentmodel_Aggregate;
  /** fetch data from the table: "core_equipmentmodel" using primary key columns */
  core_equipmentmodel_by_pk?: Maybe<Core_Equipmentmodel>;
  /** fetch data from the table in a streaming manner: "core_equipmentmodel" */
  core_equipmentmodel_stream: Array<Core_Equipmentmodel>;
  /** fetch data from the table: "core_equipmentpart" */
  core_equipmentpart: Array<Core_Equipmentpart>;
  /** fetch aggregated fields from the table: "core_equipmentpart" */
  core_equipmentpart_aggregate: Core_Equipmentpart_Aggregate;
  /** fetch data from the table: "core_equipmentpart" using primary key columns */
  core_equipmentpart_by_pk?: Maybe<Core_Equipmentpart>;
  /** fetch data from the table in a streaming manner: "core_equipmentpart" */
  core_equipmentpart_stream: Array<Core_Equipmentpart>;
  /** fetch data from the table: "core_equipmentstatus" */
  core_equipmentstatus: Array<Core_Equipmentstatus>;
  /** fetch aggregated fields from the table: "core_equipmentstatus" */
  core_equipmentstatus_aggregate: Core_Equipmentstatus_Aggregate;
  /** fetch data from the table: "core_equipmentstatus" using primary key columns */
  core_equipmentstatus_by_pk?: Maybe<Core_Equipmentstatus>;
  /** fetch data from the table in a streaming manner: "core_equipmentstatus" */
  core_equipmentstatus_stream: Array<Core_Equipmentstatus>;
  /** fetch data from the table: "core_equipmenttype" */
  core_equipmenttype: Array<Core_Equipmenttype>;
  /** fetch aggregated fields from the table: "core_equipmenttype" */
  core_equipmenttype_aggregate: Core_Equipmenttype_Aggregate;
  /** fetch data from the table: "core_equipmenttype" using primary key columns */
  core_equipmenttype_by_pk?: Maybe<Core_Equipmenttype>;
  /** fetch data from the table in a streaming manner: "core_equipmenttype" */
  core_equipmenttype_stream: Array<Core_Equipmenttype>;
  /** fetch data from the table: "core_esp" */
  core_esp: Array<Core_Esp>;
  /** fetch aggregated fields from the table: "core_esp" */
  core_esp_aggregate: Core_Esp_Aggregate;
  /** fetch data from the table: "core_esp" using primary key columns */
  core_esp_by_pk?: Maybe<Core_Esp>;
  /** fetch data from the table in a streaming manner: "core_esp" */
  core_esp_stream: Array<Core_Esp>;
  /** fetch data from the table: "core_espstatus" */
  core_espstatus: Array<Core_Espstatus>;
  /** fetch aggregated fields from the table: "core_espstatus" */
  core_espstatus_aggregate: Core_Espstatus_Aggregate;
  /** fetch data from the table: "core_espstatus" using primary key columns */
  core_espstatus_by_pk?: Maybe<Core_Espstatus>;
  /** fetch data from the table in a streaming manner: "core_espstatus" */
  core_espstatus_stream: Array<Core_Espstatus>;
  /** An array relationship */
  core_espstatusnextstages: Array<Core_Espstatusnextstages>;
  /** An aggregate relationship */
  core_espstatusnextstages_aggregate: Core_Espstatusnextstages_Aggregate;
  /** fetch data from the table: "core_espstatusnextstages" using primary key columns */
  core_espstatusnextstages_by_pk?: Maybe<Core_Espstatusnextstages>;
  /** fetch data from the table in a streaming manner: "core_espstatusnextstages" */
  core_espstatusnextstages_stream: Array<Core_Espstatusnextstages>;
  /** fetch data from the table: "core_eventlog" */
  core_eventlog: Array<Core_Eventlog>;
  /** fetch aggregated fields from the table: "core_eventlog" */
  core_eventlog_aggregate: Core_Eventlog_Aggregate;
  /** fetch data from the table: "core_eventlog" using primary key columns */
  core_eventlog_by_pk?: Maybe<Core_Eventlog>;
  /** fetch data from the table in a streaming manner: "core_eventlog" */
  core_eventlog_stream: Array<Core_Eventlog>;
  /** fetch data from the table: "core_incident" */
  core_incident: Array<Core_Incident>;
  /** fetch aggregated fields from the table: "core_incident" */
  core_incident_aggregate: Core_Incident_Aggregate;
  /** fetch data from the table: "core_incident" using primary key columns */
  core_incident_by_pk?: Maybe<Core_Incident>;
  /** fetch data from the table in a streaming manner: "core_incident" */
  core_incident_stream: Array<Core_Incident>;
  /** fetch data from the table: "core_job" */
  core_job: Array<Core_Job>;
  /** fetch aggregated fields from the table: "core_job" */
  core_job_aggregate: Core_Job_Aggregate;
  /** fetch data from the table: "core_job" using primary key columns */
  core_job_by_pk?: Maybe<Core_Job>;
  /** fetch data from the table in a streaming manner: "core_job" */
  core_job_stream: Array<Core_Job>;
  /** fetch data from the table: "core_jobstatus" */
  core_jobstatus: Array<Core_Jobstatus>;
  /** fetch aggregated fields from the table: "core_jobstatus" */
  core_jobstatus_aggregate: Core_Jobstatus_Aggregate;
  /** fetch data from the table: "core_jobstatus" using primary key columns */
  core_jobstatus_by_pk?: Maybe<Core_Jobstatus>;
  /** fetch data from the table in a streaming manner: "core_jobstatus" */
  core_jobstatus_stream: Array<Core_Jobstatus>;
  /** fetch data from the table: "core_jobstatusenum" */
  core_jobstatusenum: Array<Core_Jobstatusenum>;
  /** fetch aggregated fields from the table: "core_jobstatusenum" */
  core_jobstatusenum_aggregate: Core_Jobstatusenum_Aggregate;
  /** fetch data from the table: "core_jobstatusenum" using primary key columns */
  core_jobstatusenum_by_pk?: Maybe<Core_Jobstatusenum>;
  /** fetch data from the table in a streaming manner: "core_jobstatusenum" */
  core_jobstatusenum_stream: Array<Core_Jobstatusenum>;
  /** fetch data from the table: "core_log" */
  core_log: Array<Core_Log>;
  /** fetch aggregated fields from the table: "core_log" */
  core_log_aggregate: Core_Log_Aggregate;
  /** fetch data from the table: "core_log" using primary key columns */
  core_log_by_pk?: Maybe<Core_Log>;
  /** fetch data from the table in a streaming manner: "core_log" */
  core_log_stream: Array<Core_Log>;
  /** fetch data from the table: "core_logfacialrecognition" */
  core_logfacialrecognition: Array<Core_Logfacialrecognition>;
  /** fetch aggregated fields from the table: "core_logfacialrecognition" */
  core_logfacialrecognition_aggregate: Core_Logfacialrecognition_Aggregate;
  /** fetch data from the table: "core_logfacialrecognition" using primary key columns */
  core_logfacialrecognition_by_pk?: Maybe<Core_Logfacialrecognition>;
  /** fetch data from the table in a streaming manner: "core_logfacialrecognition" */
  core_logfacialrecognition_stream: Array<Core_Logfacialrecognition>;
  /** fetch data from the table: "core_logimage" */
  core_logimage: Array<Core_Logimage>;
  /** fetch aggregated fields from the table: "core_logimage" */
  core_logimage_aggregate: Core_Logimage_Aggregate;
  /** fetch data from the table: "core_logimage" using primary key columns */
  core_logimage_by_pk?: Maybe<Core_Logimage>;
  /** fetch data from the table in a streaming manner: "core_logimage" */
  core_logimage_stream: Array<Core_Logimage>;
  /** fetch data from the table: "core_logsuspiciosactivity" */
  core_logsuspiciosactivity: Array<Core_Logsuspiciosactivity>;
  /** fetch aggregated fields from the table: "core_logsuspiciosactivity" */
  core_logsuspiciosactivity_aggregate: Core_Logsuspiciosactivity_Aggregate;
  /** fetch data from the table: "core_logsuspiciosactivity" using primary key columns */
  core_logsuspiciosactivity_by_pk?: Maybe<Core_Logsuspiciosactivity>;
  /** fetch data from the table in a streaming manner: "core_logsuspiciosactivity" */
  core_logsuspiciosactivity_stream: Array<Core_Logsuspiciosactivity>;
  /** fetch data from the table: "core_meter" */
  core_meter: Array<Core_Meter>;
  /** fetch aggregated fields from the table: "core_meter" */
  core_meter_aggregate: Core_Meter_Aggregate;
  /** fetch data from the table: "core_meter" using primary key columns */
  core_meter_by_pk?: Maybe<Core_Meter>;
  /** fetch data from the table in a streaming manner: "core_meter" */
  core_meter_stream: Array<Core_Meter>;
  /** fetch data from the table: "core_meterreading" */
  core_meterreading: Array<Core_Meterreading>;
  /** fetch aggregated fields from the table: "core_meterreading" */
  core_meterreading_aggregate: Core_Meterreading_Aggregate;
  /** fetch data from the table: "core_meterreading" using primary key columns */
  core_meterreading_by_pk?: Maybe<Core_Meterreading>;
  /** fetch data from the table in a streaming manner: "core_meterreading" */
  core_meterreading_stream: Array<Core_Meterreading>;
  /** fetch data from the table: "core_metric" */
  core_metric: Array<Core_Metric>;
  /** fetch aggregated fields from the table: "core_metric" */
  core_metric_aggregate: Core_Metric_Aggregate;
  /** fetch data from the table: "core_metric" using primary key columns */
  core_metric_by_pk?: Maybe<Core_Metric>;
  /** fetch data from the table in a streaming manner: "core_metric" */
  core_metric_stream: Array<Core_Metric>;
  /** fetch data from the table: "core_municipality" */
  core_municipality: Array<Core_Municipality>;
  /** fetch aggregated fields from the table: "core_municipality" */
  core_municipality_aggregate: Core_Municipality_Aggregate;
  /** fetch data from the table: "core_municipality" using primary key columns */
  core_municipality_by_pk?: Maybe<Core_Municipality>;
  /** fetch data from the table in a streaming manner: "core_municipality" */
  core_municipality_stream: Array<Core_Municipality>;
  /** fetch data from the table: "core_noneuserpersonale" */
  core_noneuserpersonale: Array<Core_Noneuserpersonale>;
  /** fetch aggregated fields from the table: "core_noneuserpersonale" */
  core_noneuserpersonale_aggregate: Core_Noneuserpersonale_Aggregate;
  /** fetch data from the table: "core_noneuserpersonale" using primary key columns */
  core_noneuserpersonale_by_pk?: Maybe<Core_Noneuserpersonale>;
  /** fetch data from the table in a streaming manner: "core_noneuserpersonale" */
  core_noneuserpersonale_stream: Array<Core_Noneuserpersonale>;
  /** fetch data from the table: "core_paymenttype" */
  core_paymenttype: Array<Core_Paymenttype>;
  /** fetch aggregated fields from the table: "core_paymenttype" */
  core_paymenttype_aggregate: Core_Paymenttype_Aggregate;
  /** fetch data from the table: "core_paymenttype" using primary key columns */
  core_paymenttype_by_pk?: Maybe<Core_Paymenttype>;
  /** fetch data from the table in a streaming manner: "core_paymenttype" */
  core_paymenttype_stream: Array<Core_Paymenttype>;
  /** fetch data from the table: "core_personaleimage" */
  core_personaleimage: Array<Core_Personaleimage>;
  /** fetch aggregated fields from the table: "core_personaleimage" */
  core_personaleimage_aggregate: Core_Personaleimage_Aggregate;
  /** fetch data from the table: "core_personaleimage" using primary key columns */
  core_personaleimage_by_pk?: Maybe<Core_Personaleimage>;
  /** fetch data from the table in a streaming manner: "core_personaleimage" */
  core_personaleimage_stream: Array<Core_Personaleimage>;
  /** fetch data from the table: "core_personnelassigned" */
  core_personnelassigned: Array<Core_Personnelassigned>;
  /** fetch aggregated fields from the table: "core_personnelassigned" */
  core_personnelassigned_aggregate: Core_Personnelassigned_Aggregate;
  /** fetch data from the table: "core_personnelassigned" using primary key columns */
  core_personnelassigned_by_pk?: Maybe<Core_Personnelassigned>;
  /** fetch data from the table in a streaming manner: "core_personnelassigned" */
  core_personnelassigned_stream: Array<Core_Personnelassigned>;
  /** fetch data from the table: "core_powerprovider" */
  core_powerprovider: Array<Core_Powerprovider>;
  /** fetch aggregated fields from the table: "core_powerprovider" */
  core_powerprovider_aggregate: Core_Powerprovider_Aggregate;
  /** fetch data from the table: "core_powerprovider" using primary key columns */
  core_powerprovider_by_pk?: Maybe<Core_Powerprovider>;
  /** fetch data from the table in a streaming manner: "core_powerprovider" */
  core_powerprovider_stream: Array<Core_Powerprovider>;
  /** fetch data from the table: "core_powersourcetype" */
  core_powersourcetype: Array<Core_Powersourcetype>;
  /** fetch aggregated fields from the table: "core_powersourcetype" */
  core_powersourcetype_aggregate: Core_Powersourcetype_Aggregate;
  /** fetch data from the table: "core_powersourcetype" using primary key columns */
  core_powersourcetype_by_pk?: Maybe<Core_Powersourcetype>;
  /** fetch data from the table in a streaming manner: "core_powersourcetype" */
  core_powersourcetype_stream: Array<Core_Powersourcetype>;
  /** fetch data from the table: "core_province" */
  core_province: Array<Core_Province>;
  /** fetch aggregated fields from the table: "core_province" */
  core_province_aggregate: Core_Province_Aggregate;
  /** fetch data from the table: "core_province" using primary key columns */
  core_province_by_pk?: Maybe<Core_Province>;
  /** fetch data from the table in a streaming manner: "core_province" */
  core_province_stream: Array<Core_Province>;
  /** fetch data from the table: "core_rectifieralarm" */
  core_rectifieralarm: Array<Core_Rectifieralarm>;
  /** fetch aggregated fields from the table: "core_rectifieralarm" */
  core_rectifieralarm_aggregate: Core_Rectifieralarm_Aggregate;
  /** fetch data from the table: "core_rectifieralarm" using primary key columns */
  core_rectifieralarm_by_pk?: Maybe<Core_Rectifieralarm>;
  /** fetch data from the table in a streaming manner: "core_rectifieralarm" */
  core_rectifieralarm_stream: Array<Core_Rectifieralarm>;
  /** fetch data from the table: "core_requestaccess" */
  core_requestaccess: Array<Core_Requestaccess>;
  /** fetch aggregated fields from the table: "core_requestaccess" */
  core_requestaccess_aggregate: Core_Requestaccess_Aggregate;
  /** fetch data from the table: "core_requestaccess" using primary key columns */
  core_requestaccess_by_pk?: Maybe<Core_Requestaccess>;
  /** fetch data from the table in a streaming manner: "core_requestaccess" */
  core_requestaccess_stream: Array<Core_Requestaccess>;
  /** fetch data from the table: "core_res_company_wide_charge_estimate" */
  core_res_company_wide_charge_estimate: Array<Core_Res_Company_Wide_Charge_Estimate>;
  /** fetch aggregated fields from the table: "core_res_company_wide_charge_estimate" */
  core_res_company_wide_charge_estimate_aggregate: Core_Res_Company_Wide_Charge_Estimate_Aggregate;
  /** fetch data from the table in a streaming manner: "core_res_company_wide_charge_estimate" */
  core_res_company_wide_charge_estimate_stream: Array<Core_Res_Company_Wide_Charge_Estimate>;
  /** fetch data from the table: "core_resjobstatus" */
  core_resjobstatus: Array<Core_Resjobstatus>;
  /** fetch aggregated fields from the table: "core_resjobstatus" */
  core_resjobstatus_aggregate: Core_Resjobstatus_Aggregate;
  /** fetch data from the table: "core_resjobstatus" using primary key columns */
  core_resjobstatus_by_pk?: Maybe<Core_Resjobstatus>;
  /** fetch data from the table in a streaming manner: "core_resjobstatus" */
  core_resjobstatus_stream: Array<Core_Resjobstatus>;
  /** fetch data from the table: "core_role" */
  core_role: Array<Core_Role>;
  /** fetch aggregated fields from the table: "core_role" */
  core_role_aggregate: Core_Role_Aggregate;
  /** fetch data from the table: "core_role" using primary key columns */
  core_role_by_pk?: Maybe<Core_Role>;
  /** fetch data from the table in a streaming manner: "core_role" */
  core_role_stream: Array<Core_Role>;
  /** fetch data from the table: "core_router" */
  core_router: Array<Core_Router>;
  /** fetch aggregated fields from the table: "core_router" */
  core_router_aggregate: Core_Router_Aggregate;
  /** fetch data from the table: "core_router" using primary key columns */
  core_router_by_pk?: Maybe<Core_Router>;
  /** fetch data from the table in a streaming manner: "core_router" */
  core_router_stream: Array<Core_Router>;
  /** An array relationship */
  core_securitycallouts: Array<Core_Securitycallouts>;
  /** An aggregate relationship */
  core_securitycallouts_aggregate: Core_Securitycallouts_Aggregate;
  /** fetch data from the table: "core_securitycallouts" using primary key columns */
  core_securitycallouts_by_pk?: Maybe<Core_Securitycallouts>;
  /** fetch data from the table in a streaming manner: "core_securitycallouts" */
  core_securitycallouts_stream: Array<Core_Securitycallouts>;
  /** fetch data from the table: "core_securitycalloutsstatus" */
  core_securitycalloutsstatus: Array<Core_Securitycalloutsstatus>;
  /** fetch aggregated fields from the table: "core_securitycalloutsstatus" */
  core_securitycalloutsstatus_aggregate: Core_Securitycalloutsstatus_Aggregate;
  /** fetch data from the table: "core_securitycalloutsstatus" using primary key columns */
  core_securitycalloutsstatus_by_pk?: Maybe<Core_Securitycalloutsstatus>;
  /** fetch data from the table in a streaming manner: "core_securitycalloutsstatus" */
  core_securitycalloutsstatus_stream: Array<Core_Securitycalloutsstatus>;
  /** fetch data from the table: "core_simcard" */
  core_simcard: Array<Core_Simcard>;
  /** fetch aggregated fields from the table: "core_simcard" */
  core_simcard_aggregate: Core_Simcard_Aggregate;
  /** fetch data from the table: "core_simcard" using primary key columns */
  core_simcard_by_pk?: Maybe<Core_Simcard>;
  /** fetch data from the table in a streaming manner: "core_simcard" */
  core_simcard_stream: Array<Core_Simcard>;
  /** fetch data from the table: "core_simcardstatus" */
  core_simcardstatus: Array<Core_Simcardstatus>;
  /** fetch aggregated fields from the table: "core_simcardstatus" */
  core_simcardstatus_aggregate: Core_Simcardstatus_Aggregate;
  /** fetch data from the table: "core_simcardstatus" using primary key columns */
  core_simcardstatus_by_pk?: Maybe<Core_Simcardstatus>;
  /** fetch data from the table in a streaming manner: "core_simcardstatus" */
  core_simcardstatus_stream: Array<Core_Simcardstatus>;
  /** fetch data from the table: "core_site" */
  core_site: Array<Core_Site>;
  /** fetch aggregated fields from the table: "core_site" */
  core_site_aggregate: Core_Site_Aggregate;
  /** fetch data from the table: "core_site" using primary key columns */
  core_site_by_pk?: Maybe<Core_Site>;
  /** fetch data from the table in a streaming manner: "core_site" */
  core_site_stream: Array<Core_Site>;
  /** fetch data from the table: "core_sitechargeestimate" */
  core_sitechargeestimate: Array<Core_Sitechargeestimate>;
  /** fetch aggregated fields from the table: "core_sitechargeestimate" */
  core_sitechargeestimate_aggregate: Core_Sitechargeestimate_Aggregate;
  /** fetch data from the table: "core_sitechargeestimate" using primary key columns */
  core_sitechargeestimate_by_pk?: Maybe<Core_Sitechargeestimate>;
  /** fetch data from the table in a streaming manner: "core_sitechargeestimate" */
  core_sitechargeestimate_stream: Array<Core_Sitechargeestimate>;
  /** fetch data from the table: "core_siteregion" */
  core_siteregion: Array<Core_Siteregion>;
  /** fetch aggregated fields from the table: "core_siteregion" */
  core_siteregion_aggregate: Core_Siteregion_Aggregate;
  /** fetch data from the table: "core_siteregion" using primary key columns */
  core_siteregion_by_pk?: Maybe<Core_Siteregion>;
  /** fetch data from the table in a streaming manner: "core_siteregion" */
  core_siteregion_stream: Array<Core_Siteregion>;
  /** fetch data from the table: "core_sitesecuritycoverage" */
  core_sitesecuritycoverage: Array<Core_Sitesecuritycoverage>;
  /** fetch aggregated fields from the table: "core_sitesecuritycoverage" */
  core_sitesecuritycoverage_aggregate: Core_Sitesecuritycoverage_Aggregate;
  /** fetch data from the table: "core_sitesecuritycoverage" using primary key columns */
  core_sitesecuritycoverage_by_pk?: Maybe<Core_Sitesecuritycoverage>;
  /** fetch data from the table in a streaming manner: "core_sitesecuritycoverage" */
  core_sitesecuritycoverage_stream: Array<Core_Sitesecuritycoverage>;
  /** fetch data from the table: "core_sitestatus" */
  core_sitestatus: Array<Core_Sitestatus>;
  /** fetch aggregated fields from the table: "core_sitestatus" */
  core_sitestatus_aggregate: Core_Sitestatus_Aggregate;
  /** fetch data from the table: "core_sitestatus" using primary key columns */
  core_sitestatus_by_pk?: Maybe<Core_Sitestatus>;
  /** fetch data from the table in a streaming manner: "core_sitestatus" */
  core_sitestatus_stream: Array<Core_Sitestatus>;
  /** fetch data from the table: "core_sitetype" */
  core_sitetype: Array<Core_Sitetype>;
  /** fetch aggregated fields from the table: "core_sitetype" */
  core_sitetype_aggregate: Core_Sitetype_Aggregate;
  /** fetch data from the table: "core_sitetype" using primary key columns */
  core_sitetype_by_pk?: Maybe<Core_Sitetype>;
  /** fetch data from the table in a streaming manner: "core_sitetype" */
  core_sitetype_stream: Array<Core_Sitetype>;
  /** fetch data from the table: "core_sundrycharge" */
  core_sundrycharge: Array<Core_Sundrycharge>;
  /** fetch aggregated fields from the table: "core_sundrycharge" */
  core_sundrycharge_aggregate: Core_Sundrycharge_Aggregate;
  /** fetch data from the table: "core_sundrycharge" using primary key columns */
  core_sundrycharge_by_pk?: Maybe<Core_Sundrycharge>;
  /** fetch data from the table in a streaming manner: "core_sundrycharge" */
  core_sundrycharge_stream: Array<Core_Sundrycharge>;
  /** fetch data from the table: "core_sundrychargetype" */
  core_sundrychargetype: Array<Core_Sundrychargetype>;
  /** fetch aggregated fields from the table: "core_sundrychargetype" */
  core_sundrychargetype_aggregate: Core_Sundrychargetype_Aggregate;
  /** fetch data from the table: "core_sundrychargetype" using primary key columns */
  core_sundrychargetype_by_pk?: Maybe<Core_Sundrychargetype>;
  /** fetch data from the table in a streaming manner: "core_sundrychargetype" */
  core_sundrychargetype_stream: Array<Core_Sundrychargetype>;
  /** fetch data from the table: "core_tariff" */
  core_tariff: Array<Core_Tariff>;
  /** fetch aggregated fields from the table: "core_tariff" */
  core_tariff_aggregate: Core_Tariff_Aggregate;
  /** fetch data from the table: "core_tariff" using primary key columns */
  core_tariff_by_pk?: Maybe<Core_Tariff>;
  /** fetch data from the table in a streaming manner: "core_tariff" */
  core_tariff_stream: Array<Core_Tariff>;
  /** fetch data from the table: "core_user" */
  core_user: Array<Core_User>;
  /** fetch aggregated fields from the table: "core_user" */
  core_user_aggregate: Core_User_Aggregate;
  /** fetch data from the table: "core_user" using primary key columns */
  core_user_by_pk?: Maybe<Core_User>;
  /** An array relationship */
  core_user_groups: Array<Core_User_Groups>;
  /** An aggregate relationship */
  core_user_groups_aggregate: Core_User_Groups_Aggregate;
  /** fetch data from the table: "core_user_groups" using primary key columns */
  core_user_groups_by_pk?: Maybe<Core_User_Groups>;
  /** fetch data from the table in a streaming manner: "core_user_groups" */
  core_user_groups_stream: Array<Core_User_Groups>;
  /** fetch data from the table in a streaming manner: "core_user" */
  core_user_stream: Array<Core_User>;
  /** An array relationship */
  core_user_user_permissions: Array<Core_User_User_Permissions>;
  /** An aggregate relationship */
  core_user_user_permissions_aggregate: Core_User_User_Permissions_Aggregate;
  /** fetch data from the table: "core_user_user_permissions" using primary key columns */
  core_user_user_permissions_by_pk?: Maybe<Core_User_User_Permissions>;
  /** fetch data from the table in a streaming manner: "core_user_user_permissions" */
  core_user_user_permissions_stream: Array<Core_User_User_Permissions>;
  /** fetch data from the table: "core_visit" */
  core_visit: Array<Core_Visit>;
  /** fetch aggregated fields from the table: "core_visit" */
  core_visit_aggregate: Core_Visit_Aggregate;
  /** fetch data from the table: "core_visit" using primary key columns */
  core_visit_by_pk?: Maybe<Core_Visit>;
  /** fetch data from the table in a streaming manner: "core_visit" */
  core_visit_stream: Array<Core_Visit>;
  /** fetch data from the table: "core_visitstatusenum" */
  core_visitstatusenum: Array<Core_Visitstatusenum>;
  /** fetch aggregated fields from the table: "core_visitstatusenum" */
  core_visitstatusenum_aggregate: Core_Visitstatusenum_Aggregate;
  /** fetch data from the table: "core_visitstatusenum" using primary key columns */
  core_visitstatusenum_by_pk?: Maybe<Core_Visitstatusenum>;
  /** fetch data from the table in a streaming manner: "core_visitstatusenum" */
  core_visitstatusenum_stream: Array<Core_Visitstatusenum>;
  /** fetch data from the table: "django_admin_log" */
  django_admin_log: Array<Django_Admin_Log>;
  /** fetch aggregated fields from the table: "django_admin_log" */
  django_admin_log_aggregate: Django_Admin_Log_Aggregate;
  /** fetch data from the table: "django_admin_log" using primary key columns */
  django_admin_log_by_pk?: Maybe<Django_Admin_Log>;
  /** fetch data from the table in a streaming manner: "django_admin_log" */
  django_admin_log_stream: Array<Django_Admin_Log>;
  /** fetch data from the table: "django_content_type" */
  django_content_type: Array<Django_Content_Type>;
  /** fetch aggregated fields from the table: "django_content_type" */
  django_content_type_aggregate: Django_Content_Type_Aggregate;
  /** fetch data from the table: "django_content_type" using primary key columns */
  django_content_type_by_pk?: Maybe<Django_Content_Type>;
  /** fetch data from the table in a streaming manner: "django_content_type" */
  django_content_type_stream: Array<Django_Content_Type>;
  /** fetch data from the table: "django_migrations" */
  django_migrations: Array<Django_Migrations>;
  /** fetch aggregated fields from the table: "django_migrations" */
  django_migrations_aggregate: Django_Migrations_Aggregate;
  /** fetch data from the table: "django_migrations" using primary key columns */
  django_migrations_by_pk?: Maybe<Django_Migrations>;
  /** fetch data from the table in a streaming manner: "django_migrations" */
  django_migrations_stream: Array<Django_Migrations>;
  /** fetch data from the table: "django_session" */
  django_session: Array<Django_Session>;
  /** fetch aggregated fields from the table: "django_session" */
  django_session_aggregate: Django_Session_Aggregate;
  /** fetch data from the table: "django_session" using primary key columns */
  django_session_by_pk?: Maybe<Django_Session>;
  /** fetch data from the table in a streaming manner: "django_session" */
  django_session_stream: Array<Django_Session>;
  /** execute function "getcompanywidesitechargecostandunitsbycompanyid" which returns "core_res_company_wide_charge_estimate" */
  getcompanywidesitechargecostandunitsbycompanyid: Array<Core_Res_Company_Wide_Charge_Estimate>;
  /** execute function "getcompanywidesitechargecostandunitsbycompanyid" and query aggregates on result of table type "core_res_company_wide_charge_estimate" */
  getcompanywidesitechargecostandunitsbycompanyid_aggregate: Core_Res_Company_Wide_Charge_Estimate_Aggregate;
  /** execute function "getsitechargecostandunitsbysiteid" which returns "core_sitechargeestimate" */
  getsitechargecostandunitsbysiteid: Array<Core_Sitechargeestimate>;
  /** execute function "getsitechargecostandunitsbysiteid" and query aggregates on result of table type "core_sitechargeestimate" */
  getsitechargecostandunitsbysiteid_aggregate: Core_Sitechargeestimate_Aggregate;
};


export type Subscription_RootAuth_GroupArgs = {
  distinct_on?: InputMaybe<Array<Auth_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Group_Order_By>>;
  where?: InputMaybe<Auth_Group_Bool_Exp>;
};


export type Subscription_RootAuth_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Group_Order_By>>;
  where?: InputMaybe<Auth_Group_Bool_Exp>;
};


export type Subscription_RootAuth_Group_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAuth_Group_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Group_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Group_Permissions_Order_By>>;
  where?: InputMaybe<Auth_Group_Permissions_Bool_Exp>;
};


export type Subscription_RootAuth_Group_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Group_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Group_Permissions_Order_By>>;
  where?: InputMaybe<Auth_Group_Permissions_Bool_Exp>;
};


export type Subscription_RootAuth_Group_Permissions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootAuth_Group_Permissions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Group_Permissions_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Group_Permissions_Bool_Exp>;
};


export type Subscription_RootAuth_Group_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Group_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Group_Bool_Exp>;
};


export type Subscription_RootAuth_PermissionArgs = {
  distinct_on?: InputMaybe<Array<Auth_Permission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Permission_Order_By>>;
  where?: InputMaybe<Auth_Permission_Bool_Exp>;
};


export type Subscription_RootAuth_Permission_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Permission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Permission_Order_By>>;
  where?: InputMaybe<Auth_Permission_Bool_Exp>;
};


export type Subscription_RootAuth_Permission_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAuth_Permission_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Auth_Permission_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Permission_Bool_Exp>;
};


export type Subscription_RootAuthtoken_TokenArgs = {
  distinct_on?: InputMaybe<Array<Authtoken_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Authtoken_Token_Order_By>>;
  where?: InputMaybe<Authtoken_Token_Bool_Exp>;
};


export type Subscription_RootAuthtoken_Token_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Authtoken_Token_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Authtoken_Token_Order_By>>;
  where?: InputMaybe<Authtoken_Token_Bool_Exp>;
};


export type Subscription_RootAuthtoken_Token_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootAuthtoken_Token_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Authtoken_Token_Stream_Cursor_Input>>;
  where?: InputMaybe<Authtoken_Token_Bool_Exp>;
};


export type Subscription_RootCore_AgreementArgs = {
  distinct_on?: InputMaybe<Array<Core_Agreement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Agreement_Order_By>>;
  where?: InputMaybe<Core_Agreement_Bool_Exp>;
};


export type Subscription_RootCore_Agreement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Agreement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Agreement_Order_By>>;
  where?: InputMaybe<Core_Agreement_Bool_Exp>;
};


export type Subscription_RootCore_Agreement_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Agreement_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Agreement_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Agreement_Bool_Exp>;
};


export type Subscription_RootCore_BoxArgs = {
  distinct_on?: InputMaybe<Array<Core_Box_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Box_Order_By>>;
  where?: InputMaybe<Core_Box_Bool_Exp>;
};


export type Subscription_RootCore_Box_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Box_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Box_Order_By>>;
  where?: InputMaybe<Core_Box_Bool_Exp>;
};


export type Subscription_RootCore_Box_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Box_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Box_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Box_Bool_Exp>;
};


export type Subscription_RootCore_BoxstatusArgs = {
  distinct_on?: InputMaybe<Array<Core_Boxstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Boxstatus_Order_By>>;
  where?: InputMaybe<Core_Boxstatus_Bool_Exp>;
};


export type Subscription_RootCore_Boxstatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Boxstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Boxstatus_Order_By>>;
  where?: InputMaybe<Core_Boxstatus_Bool_Exp>;
};


export type Subscription_RootCore_Boxstatus_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Boxstatus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Boxstatus_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Boxstatus_Bool_Exp>;
};


export type Subscription_RootCore_CabinArgs = {
  distinct_on?: InputMaybe<Array<Core_Cabin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Cabin_Order_By>>;
  where?: InputMaybe<Core_Cabin_Bool_Exp>;
};


export type Subscription_RootCore_Cabin_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Cabin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Cabin_Order_By>>;
  where?: InputMaybe<Core_Cabin_Bool_Exp>;
};


export type Subscription_RootCore_Cabin_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Cabin_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Cabin_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Cabin_Bool_Exp>;
};


export type Subscription_RootCore_CameraArgs = {
  distinct_on?: InputMaybe<Array<Core_Camera_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Camera_Order_By>>;
  where?: InputMaybe<Core_Camera_Bool_Exp>;
};


export type Subscription_RootCore_Camera_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Camera_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Camera_Order_By>>;
  where?: InputMaybe<Core_Camera_Bool_Exp>;
};


export type Subscription_RootCore_Camera_By_PkArgs = {
  camera_id: Scalars['String'];
};


export type Subscription_RootCore_Camera_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Camera_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Camera_Bool_Exp>;
};


export type Subscription_RootCore_CompanyArgs = {
  distinct_on?: InputMaybe<Array<Core_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Company_Order_By>>;
  where?: InputMaybe<Core_Company_Bool_Exp>;
};


export type Subscription_RootCore_Company_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Company_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Company_Order_By>>;
  where?: InputMaybe<Core_Company_Bool_Exp>;
};


export type Subscription_RootCore_Company_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Company_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Company_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Company_Bool_Exp>;
};


export type Subscription_RootCore_CompanysitechargeestimateArgs = {
  distinct_on?: InputMaybe<Array<Core_Companysitechargeestimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Companysitechargeestimate_Order_By>>;
  where?: InputMaybe<Core_Companysitechargeestimate_Bool_Exp>;
};


export type Subscription_RootCore_Companysitechargeestimate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Companysitechargeestimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Companysitechargeestimate_Order_By>>;
  where?: InputMaybe<Core_Companysitechargeestimate_Bool_Exp>;
};


export type Subscription_RootCore_Companysitechargeestimate_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Companysitechargeestimate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Companysitechargeestimate_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Companysitechargeestimate_Bool_Exp>;
};


export type Subscription_RootCore_CustomertypeArgs = {
  distinct_on?: InputMaybe<Array<Core_Customertype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Customertype_Order_By>>;
  where?: InputMaybe<Core_Customertype_Bool_Exp>;
};


export type Subscription_RootCore_Customertype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Customertype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Customertype_Order_By>>;
  where?: InputMaybe<Core_Customertype_Bool_Exp>;
};


export type Subscription_RootCore_Customertype_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Customertype_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Customertype_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Customertype_Bool_Exp>;
};


export type Subscription_RootCore_DisruptionsArgs = {
  distinct_on?: InputMaybe<Array<Core_Disruptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Disruptions_Order_By>>;
  where?: InputMaybe<Core_Disruptions_Bool_Exp>;
};


export type Subscription_RootCore_Disruptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Disruptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Disruptions_Order_By>>;
  where?: InputMaybe<Core_Disruptions_Bool_Exp>;
};


export type Subscription_RootCore_Disruptions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Disruptions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Disruptions_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Disruptions_Bool_Exp>;
};


export type Subscription_RootCore_DisruptionstypeArgs = {
  distinct_on?: InputMaybe<Array<Core_Disruptionstype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Disruptionstype_Order_By>>;
  where?: InputMaybe<Core_Disruptionstype_Bool_Exp>;
};


export type Subscription_RootCore_Disruptionstype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Disruptionstype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Disruptionstype_Order_By>>;
  where?: InputMaybe<Core_Disruptionstype_Bool_Exp>;
};


export type Subscription_RootCore_Disruptionstype_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Disruptionstype_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Disruptionstype_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Disruptionstype_Bool_Exp>;
};


export type Subscription_RootCore_EnergychargeArgs = {
  distinct_on?: InputMaybe<Array<Core_Energycharge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Energycharge_Order_By>>;
  where?: InputMaybe<Core_Energycharge_Bool_Exp>;
};


export type Subscription_RootCore_Energycharge_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Energycharge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Energycharge_Order_By>>;
  where?: InputMaybe<Core_Energycharge_Bool_Exp>;
};


export type Subscription_RootCore_Energycharge_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Energycharge_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Energycharge_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Energycharge_Bool_Exp>;
};


export type Subscription_RootCore_EquipmentArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipment_Order_By>>;
  where?: InputMaybe<Core_Equipment_Bool_Exp>;
};


export type Subscription_RootCore_Equipment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipment_Order_By>>;
  where?: InputMaybe<Core_Equipment_Bool_Exp>;
};


export type Subscription_RootCore_Equipment_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Equipment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Equipment_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Equipment_Bool_Exp>;
};


export type Subscription_RootCore_EquipmentconditionArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentcondition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentcondition_Order_By>>;
  where?: InputMaybe<Core_Equipmentcondition_Bool_Exp>;
};


export type Subscription_RootCore_Equipmentcondition_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentcondition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentcondition_Order_By>>;
  where?: InputMaybe<Core_Equipmentcondition_Bool_Exp>;
};


export type Subscription_RootCore_Equipmentcondition_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Equipmentcondition_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Equipmentcondition_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Equipmentcondition_Bool_Exp>;
};


export type Subscription_RootCore_EquipmentimageArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentimage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentimage_Order_By>>;
  where?: InputMaybe<Core_Equipmentimage_Bool_Exp>;
};


export type Subscription_RootCore_Equipmentimage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentimage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentimage_Order_By>>;
  where?: InputMaybe<Core_Equipmentimage_Bool_Exp>;
};


export type Subscription_RootCore_Equipmentimage_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Equipmentimage_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Equipmentimage_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Equipmentimage_Bool_Exp>;
};


export type Subscription_RootCore_EquipmentmakeArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentmake_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentmake_Order_By>>;
  where?: InputMaybe<Core_Equipmentmake_Bool_Exp>;
};


export type Subscription_RootCore_Equipmentmake_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentmake_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentmake_Order_By>>;
  where?: InputMaybe<Core_Equipmentmake_Bool_Exp>;
};


export type Subscription_RootCore_Equipmentmake_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Equipmentmake_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Equipmentmake_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Equipmentmake_Bool_Exp>;
};


export type Subscription_RootCore_EquipmentmodelArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentmodel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentmodel_Order_By>>;
  where?: InputMaybe<Core_Equipmentmodel_Bool_Exp>;
};


export type Subscription_RootCore_Equipmentmodel_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentmodel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentmodel_Order_By>>;
  where?: InputMaybe<Core_Equipmentmodel_Bool_Exp>;
};


export type Subscription_RootCore_Equipmentmodel_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Equipmentmodel_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Equipmentmodel_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Equipmentmodel_Bool_Exp>;
};


export type Subscription_RootCore_EquipmentpartArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentpart_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentpart_Order_By>>;
  where?: InputMaybe<Core_Equipmentpart_Bool_Exp>;
};


export type Subscription_RootCore_Equipmentpart_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentpart_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentpart_Order_By>>;
  where?: InputMaybe<Core_Equipmentpart_Bool_Exp>;
};


export type Subscription_RootCore_Equipmentpart_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Equipmentpart_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Equipmentpart_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Equipmentpart_Bool_Exp>;
};


export type Subscription_RootCore_EquipmentstatusArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentstatus_Order_By>>;
  where?: InputMaybe<Core_Equipmentstatus_Bool_Exp>;
};


export type Subscription_RootCore_Equipmentstatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmentstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmentstatus_Order_By>>;
  where?: InputMaybe<Core_Equipmentstatus_Bool_Exp>;
};


export type Subscription_RootCore_Equipmentstatus_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Equipmentstatus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Equipmentstatus_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Equipmentstatus_Bool_Exp>;
};


export type Subscription_RootCore_EquipmenttypeArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmenttype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmenttype_Order_By>>;
  where?: InputMaybe<Core_Equipmenttype_Bool_Exp>;
};


export type Subscription_RootCore_Equipmenttype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Equipmenttype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Equipmenttype_Order_By>>;
  where?: InputMaybe<Core_Equipmenttype_Bool_Exp>;
};


export type Subscription_RootCore_Equipmenttype_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Equipmenttype_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Equipmenttype_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Equipmenttype_Bool_Exp>;
};


export type Subscription_RootCore_EspArgs = {
  distinct_on?: InputMaybe<Array<Core_Esp_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Esp_Order_By>>;
  where?: InputMaybe<Core_Esp_Bool_Exp>;
};


export type Subscription_RootCore_Esp_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Esp_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Esp_Order_By>>;
  where?: InputMaybe<Core_Esp_Bool_Exp>;
};


export type Subscription_RootCore_Esp_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Esp_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Esp_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Esp_Bool_Exp>;
};


export type Subscription_RootCore_EspstatusArgs = {
  distinct_on?: InputMaybe<Array<Core_Espstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Espstatus_Order_By>>;
  where?: InputMaybe<Core_Espstatus_Bool_Exp>;
};


export type Subscription_RootCore_Espstatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Espstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Espstatus_Order_By>>;
  where?: InputMaybe<Core_Espstatus_Bool_Exp>;
};


export type Subscription_RootCore_Espstatus_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Espstatus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Espstatus_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Espstatus_Bool_Exp>;
};


export type Subscription_RootCore_EspstatusnextstagesArgs = {
  distinct_on?: InputMaybe<Array<Core_Espstatusnextstages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Espstatusnextstages_Order_By>>;
  where?: InputMaybe<Core_Espstatusnextstages_Bool_Exp>;
};


export type Subscription_RootCore_Espstatusnextstages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Espstatusnextstages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Espstatusnextstages_Order_By>>;
  where?: InputMaybe<Core_Espstatusnextstages_Bool_Exp>;
};


export type Subscription_RootCore_Espstatusnextstages_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Espstatusnextstages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Espstatusnextstages_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Espstatusnextstages_Bool_Exp>;
};


export type Subscription_RootCore_EventlogArgs = {
  distinct_on?: InputMaybe<Array<Core_Eventlog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Eventlog_Order_By>>;
  where?: InputMaybe<Core_Eventlog_Bool_Exp>;
};


export type Subscription_RootCore_Eventlog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Eventlog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Eventlog_Order_By>>;
  where?: InputMaybe<Core_Eventlog_Bool_Exp>;
};


export type Subscription_RootCore_Eventlog_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Eventlog_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Eventlog_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Eventlog_Bool_Exp>;
};


export type Subscription_RootCore_IncidentArgs = {
  distinct_on?: InputMaybe<Array<Core_Incident_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Incident_Order_By>>;
  where?: InputMaybe<Core_Incident_Bool_Exp>;
};


export type Subscription_RootCore_Incident_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Incident_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Incident_Order_By>>;
  where?: InputMaybe<Core_Incident_Bool_Exp>;
};


export type Subscription_RootCore_Incident_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Incident_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Incident_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Incident_Bool_Exp>;
};


export type Subscription_RootCore_JobArgs = {
  distinct_on?: InputMaybe<Array<Core_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Job_Order_By>>;
  where?: InputMaybe<Core_Job_Bool_Exp>;
};


export type Subscription_RootCore_Job_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Job_Order_By>>;
  where?: InputMaybe<Core_Job_Bool_Exp>;
};


export type Subscription_RootCore_Job_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Job_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Job_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Job_Bool_Exp>;
};


export type Subscription_RootCore_JobstatusArgs = {
  distinct_on?: InputMaybe<Array<Core_Jobstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Jobstatus_Order_By>>;
  where?: InputMaybe<Core_Jobstatus_Bool_Exp>;
};


export type Subscription_RootCore_Jobstatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Jobstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Jobstatus_Order_By>>;
  where?: InputMaybe<Core_Jobstatus_Bool_Exp>;
};


export type Subscription_RootCore_Jobstatus_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Jobstatus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Jobstatus_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Jobstatus_Bool_Exp>;
};


export type Subscription_RootCore_JobstatusenumArgs = {
  distinct_on?: InputMaybe<Array<Core_Jobstatusenum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Jobstatusenum_Order_By>>;
  where?: InputMaybe<Core_Jobstatusenum_Bool_Exp>;
};


export type Subscription_RootCore_Jobstatusenum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Jobstatusenum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Jobstatusenum_Order_By>>;
  where?: InputMaybe<Core_Jobstatusenum_Bool_Exp>;
};


export type Subscription_RootCore_Jobstatusenum_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Jobstatusenum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Jobstatusenum_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Jobstatusenum_Bool_Exp>;
};


export type Subscription_RootCore_LogArgs = {
  distinct_on?: InputMaybe<Array<Core_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Log_Order_By>>;
  where?: InputMaybe<Core_Log_Bool_Exp>;
};


export type Subscription_RootCore_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Log_Order_By>>;
  where?: InputMaybe<Core_Log_Bool_Exp>;
};


export type Subscription_RootCore_Log_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Log_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Log_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Log_Bool_Exp>;
};


export type Subscription_RootCore_LogfacialrecognitionArgs = {
  distinct_on?: InputMaybe<Array<Core_Logfacialrecognition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logfacialrecognition_Order_By>>;
  where?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
};


export type Subscription_RootCore_Logfacialrecognition_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Logfacialrecognition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logfacialrecognition_Order_By>>;
  where?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
};


export type Subscription_RootCore_Logfacialrecognition_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Logfacialrecognition_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Logfacialrecognition_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Logfacialrecognition_Bool_Exp>;
};


export type Subscription_RootCore_LogimageArgs = {
  distinct_on?: InputMaybe<Array<Core_Logimage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logimage_Order_By>>;
  where?: InputMaybe<Core_Logimage_Bool_Exp>;
};


export type Subscription_RootCore_Logimage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Logimage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logimage_Order_By>>;
  where?: InputMaybe<Core_Logimage_Bool_Exp>;
};


export type Subscription_RootCore_Logimage_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Logimage_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Logimage_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Logimage_Bool_Exp>;
};


export type Subscription_RootCore_LogsuspiciosactivityArgs = {
  distinct_on?: InputMaybe<Array<Core_Logsuspiciosactivity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logsuspiciosactivity_Order_By>>;
  where?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
};


export type Subscription_RootCore_Logsuspiciosactivity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Logsuspiciosactivity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Logsuspiciosactivity_Order_By>>;
  where?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
};


export type Subscription_RootCore_Logsuspiciosactivity_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Logsuspiciosactivity_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Logsuspiciosactivity_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Logsuspiciosactivity_Bool_Exp>;
};


export type Subscription_RootCore_MeterArgs = {
  distinct_on?: InputMaybe<Array<Core_Meter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Meter_Order_By>>;
  where?: InputMaybe<Core_Meter_Bool_Exp>;
};


export type Subscription_RootCore_Meter_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Meter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Meter_Order_By>>;
  where?: InputMaybe<Core_Meter_Bool_Exp>;
};


export type Subscription_RootCore_Meter_By_PkArgs = {
  serial_number: Scalars['String'];
};


export type Subscription_RootCore_Meter_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Meter_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Meter_Bool_Exp>;
};


export type Subscription_RootCore_MeterreadingArgs = {
  distinct_on?: InputMaybe<Array<Core_Meterreading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Meterreading_Order_By>>;
  where?: InputMaybe<Core_Meterreading_Bool_Exp>;
};


export type Subscription_RootCore_Meterreading_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Meterreading_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Meterreading_Order_By>>;
  where?: InputMaybe<Core_Meterreading_Bool_Exp>;
};


export type Subscription_RootCore_Meterreading_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Meterreading_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Meterreading_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Meterreading_Bool_Exp>;
};


export type Subscription_RootCore_MetricArgs = {
  distinct_on?: InputMaybe<Array<Core_Metric_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Metric_Order_By>>;
  where?: InputMaybe<Core_Metric_Bool_Exp>;
};


export type Subscription_RootCore_Metric_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Metric_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Metric_Order_By>>;
  where?: InputMaybe<Core_Metric_Bool_Exp>;
};


export type Subscription_RootCore_Metric_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Metric_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Metric_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Metric_Bool_Exp>;
};


export type Subscription_RootCore_MunicipalityArgs = {
  distinct_on?: InputMaybe<Array<Core_Municipality_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Municipality_Order_By>>;
  where?: InputMaybe<Core_Municipality_Bool_Exp>;
};


export type Subscription_RootCore_Municipality_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Municipality_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Municipality_Order_By>>;
  where?: InputMaybe<Core_Municipality_Bool_Exp>;
};


export type Subscription_RootCore_Municipality_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Municipality_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Municipality_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Municipality_Bool_Exp>;
};


export type Subscription_RootCore_NoneuserpersonaleArgs = {
  distinct_on?: InputMaybe<Array<Core_Noneuserpersonale_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Noneuserpersonale_Order_By>>;
  where?: InputMaybe<Core_Noneuserpersonale_Bool_Exp>;
};


export type Subscription_RootCore_Noneuserpersonale_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Noneuserpersonale_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Noneuserpersonale_Order_By>>;
  where?: InputMaybe<Core_Noneuserpersonale_Bool_Exp>;
};


export type Subscription_RootCore_Noneuserpersonale_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCore_Noneuserpersonale_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Noneuserpersonale_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Noneuserpersonale_Bool_Exp>;
};


export type Subscription_RootCore_PaymenttypeArgs = {
  distinct_on?: InputMaybe<Array<Core_Paymenttype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Paymenttype_Order_By>>;
  where?: InputMaybe<Core_Paymenttype_Bool_Exp>;
};


export type Subscription_RootCore_Paymenttype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Paymenttype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Paymenttype_Order_By>>;
  where?: InputMaybe<Core_Paymenttype_Bool_Exp>;
};


export type Subscription_RootCore_Paymenttype_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Paymenttype_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Paymenttype_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Paymenttype_Bool_Exp>;
};


export type Subscription_RootCore_PersonaleimageArgs = {
  distinct_on?: InputMaybe<Array<Core_Personaleimage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Personaleimage_Order_By>>;
  where?: InputMaybe<Core_Personaleimage_Bool_Exp>;
};


export type Subscription_RootCore_Personaleimage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Personaleimage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Personaleimage_Order_By>>;
  where?: InputMaybe<Core_Personaleimage_Bool_Exp>;
};


export type Subscription_RootCore_Personaleimage_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Personaleimage_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Personaleimage_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Personaleimage_Bool_Exp>;
};


export type Subscription_RootCore_PersonnelassignedArgs = {
  distinct_on?: InputMaybe<Array<Core_Personnelassigned_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Personnelassigned_Order_By>>;
  where?: InputMaybe<Core_Personnelassigned_Bool_Exp>;
};


export type Subscription_RootCore_Personnelassigned_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Personnelassigned_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Personnelassigned_Order_By>>;
  where?: InputMaybe<Core_Personnelassigned_Bool_Exp>;
};


export type Subscription_RootCore_Personnelassigned_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Personnelassigned_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Personnelassigned_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Personnelassigned_Bool_Exp>;
};


export type Subscription_RootCore_PowerproviderArgs = {
  distinct_on?: InputMaybe<Array<Core_Powerprovider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Powerprovider_Order_By>>;
  where?: InputMaybe<Core_Powerprovider_Bool_Exp>;
};


export type Subscription_RootCore_Powerprovider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Powerprovider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Powerprovider_Order_By>>;
  where?: InputMaybe<Core_Powerprovider_Bool_Exp>;
};


export type Subscription_RootCore_Powerprovider_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Powerprovider_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Powerprovider_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Powerprovider_Bool_Exp>;
};


export type Subscription_RootCore_PowersourcetypeArgs = {
  distinct_on?: InputMaybe<Array<Core_Powersourcetype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Powersourcetype_Order_By>>;
  where?: InputMaybe<Core_Powersourcetype_Bool_Exp>;
};


export type Subscription_RootCore_Powersourcetype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Powersourcetype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Powersourcetype_Order_By>>;
  where?: InputMaybe<Core_Powersourcetype_Bool_Exp>;
};


export type Subscription_RootCore_Powersourcetype_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Powersourcetype_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Powersourcetype_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Powersourcetype_Bool_Exp>;
};


export type Subscription_RootCore_ProvinceArgs = {
  distinct_on?: InputMaybe<Array<Core_Province_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Province_Order_By>>;
  where?: InputMaybe<Core_Province_Bool_Exp>;
};


export type Subscription_RootCore_Province_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Province_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Province_Order_By>>;
  where?: InputMaybe<Core_Province_Bool_Exp>;
};


export type Subscription_RootCore_Province_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Province_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Province_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Province_Bool_Exp>;
};


export type Subscription_RootCore_RectifieralarmArgs = {
  distinct_on?: InputMaybe<Array<Core_Rectifieralarm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Rectifieralarm_Order_By>>;
  where?: InputMaybe<Core_Rectifieralarm_Bool_Exp>;
};


export type Subscription_RootCore_Rectifieralarm_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Rectifieralarm_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Rectifieralarm_Order_By>>;
  where?: InputMaybe<Core_Rectifieralarm_Bool_Exp>;
};


export type Subscription_RootCore_Rectifieralarm_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Rectifieralarm_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Rectifieralarm_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Rectifieralarm_Bool_Exp>;
};


export type Subscription_RootCore_RequestaccessArgs = {
  distinct_on?: InputMaybe<Array<Core_Requestaccess_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Requestaccess_Order_By>>;
  where?: InputMaybe<Core_Requestaccess_Bool_Exp>;
};


export type Subscription_RootCore_Requestaccess_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Requestaccess_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Requestaccess_Order_By>>;
  where?: InputMaybe<Core_Requestaccess_Bool_Exp>;
};


export type Subscription_RootCore_Requestaccess_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Requestaccess_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Requestaccess_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Requestaccess_Bool_Exp>;
};


export type Subscription_RootCore_Res_Company_Wide_Charge_EstimateArgs = {
  distinct_on?: InputMaybe<Array<Core_Res_Company_Wide_Charge_Estimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Res_Company_Wide_Charge_Estimate_Order_By>>;
  where?: InputMaybe<Core_Res_Company_Wide_Charge_Estimate_Bool_Exp>;
};


export type Subscription_RootCore_Res_Company_Wide_Charge_Estimate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Res_Company_Wide_Charge_Estimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Res_Company_Wide_Charge_Estimate_Order_By>>;
  where?: InputMaybe<Core_Res_Company_Wide_Charge_Estimate_Bool_Exp>;
};


export type Subscription_RootCore_Res_Company_Wide_Charge_Estimate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Res_Company_Wide_Charge_Estimate_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Res_Company_Wide_Charge_Estimate_Bool_Exp>;
};


export type Subscription_RootCore_ResjobstatusArgs = {
  distinct_on?: InputMaybe<Array<Core_Resjobstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Resjobstatus_Order_By>>;
  where?: InputMaybe<Core_Resjobstatus_Bool_Exp>;
};


export type Subscription_RootCore_Resjobstatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Resjobstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Resjobstatus_Order_By>>;
  where?: InputMaybe<Core_Resjobstatus_Bool_Exp>;
};


export type Subscription_RootCore_Resjobstatus_By_PkArgs = {
  job_id: Scalars['bigint'];
};


export type Subscription_RootCore_Resjobstatus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Resjobstatus_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Resjobstatus_Bool_Exp>;
};


export type Subscription_RootCore_RoleArgs = {
  distinct_on?: InputMaybe<Array<Core_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Role_Order_By>>;
  where?: InputMaybe<Core_Role_Bool_Exp>;
};


export type Subscription_RootCore_Role_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Role_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Role_Order_By>>;
  where?: InputMaybe<Core_Role_Bool_Exp>;
};


export type Subscription_RootCore_Role_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Role_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Role_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Role_Bool_Exp>;
};


export type Subscription_RootCore_RouterArgs = {
  distinct_on?: InputMaybe<Array<Core_Router_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Router_Order_By>>;
  where?: InputMaybe<Core_Router_Bool_Exp>;
};


export type Subscription_RootCore_Router_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Router_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Router_Order_By>>;
  where?: InputMaybe<Core_Router_Bool_Exp>;
};


export type Subscription_RootCore_Router_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Router_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Router_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Router_Bool_Exp>;
};


export type Subscription_RootCore_SecuritycalloutsArgs = {
  distinct_on?: InputMaybe<Array<Core_Securitycallouts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Securitycallouts_Order_By>>;
  where?: InputMaybe<Core_Securitycallouts_Bool_Exp>;
};


export type Subscription_RootCore_Securitycallouts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Securitycallouts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Securitycallouts_Order_By>>;
  where?: InputMaybe<Core_Securitycallouts_Bool_Exp>;
};


export type Subscription_RootCore_Securitycallouts_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Securitycallouts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Securitycallouts_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Securitycallouts_Bool_Exp>;
};


export type Subscription_RootCore_SecuritycalloutsstatusArgs = {
  distinct_on?: InputMaybe<Array<Core_Securitycalloutsstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Securitycalloutsstatus_Order_By>>;
  where?: InputMaybe<Core_Securitycalloutsstatus_Bool_Exp>;
};


export type Subscription_RootCore_Securitycalloutsstatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Securitycalloutsstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Securitycalloutsstatus_Order_By>>;
  where?: InputMaybe<Core_Securitycalloutsstatus_Bool_Exp>;
};


export type Subscription_RootCore_Securitycalloutsstatus_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Securitycalloutsstatus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Securitycalloutsstatus_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Securitycalloutsstatus_Bool_Exp>;
};


export type Subscription_RootCore_SimcardArgs = {
  distinct_on?: InputMaybe<Array<Core_Simcard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Simcard_Order_By>>;
  where?: InputMaybe<Core_Simcard_Bool_Exp>;
};


export type Subscription_RootCore_Simcard_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Simcard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Simcard_Order_By>>;
  where?: InputMaybe<Core_Simcard_Bool_Exp>;
};


export type Subscription_RootCore_Simcard_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Simcard_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Simcard_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Simcard_Bool_Exp>;
};


export type Subscription_RootCore_SimcardstatusArgs = {
  distinct_on?: InputMaybe<Array<Core_Simcardstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Simcardstatus_Order_By>>;
  where?: InputMaybe<Core_Simcardstatus_Bool_Exp>;
};


export type Subscription_RootCore_Simcardstatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Simcardstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Simcardstatus_Order_By>>;
  where?: InputMaybe<Core_Simcardstatus_Bool_Exp>;
};


export type Subscription_RootCore_Simcardstatus_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Simcardstatus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Simcardstatus_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Simcardstatus_Bool_Exp>;
};


export type Subscription_RootCore_SiteArgs = {
  distinct_on?: InputMaybe<Array<Core_Site_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Site_Order_By>>;
  where?: InputMaybe<Core_Site_Bool_Exp>;
};


export type Subscription_RootCore_Site_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Site_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Site_Order_By>>;
  where?: InputMaybe<Core_Site_Bool_Exp>;
};


export type Subscription_RootCore_Site_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Site_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Site_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Site_Bool_Exp>;
};


export type Subscription_RootCore_SitechargeestimateArgs = {
  distinct_on?: InputMaybe<Array<Core_Sitechargeestimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitechargeestimate_Order_By>>;
  where?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
};


export type Subscription_RootCore_Sitechargeestimate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Sitechargeestimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitechargeestimate_Order_By>>;
  where?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
};


export type Subscription_RootCore_Sitechargeestimate_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Sitechargeestimate_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Sitechargeestimate_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
};


export type Subscription_RootCore_SiteregionArgs = {
  distinct_on?: InputMaybe<Array<Core_Siteregion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Siteregion_Order_By>>;
  where?: InputMaybe<Core_Siteregion_Bool_Exp>;
};


export type Subscription_RootCore_Siteregion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Siteregion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Siteregion_Order_By>>;
  where?: InputMaybe<Core_Siteregion_Bool_Exp>;
};


export type Subscription_RootCore_Siteregion_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Siteregion_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Siteregion_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Siteregion_Bool_Exp>;
};


export type Subscription_RootCore_SitesecuritycoverageArgs = {
  distinct_on?: InputMaybe<Array<Core_Sitesecuritycoverage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitesecuritycoverage_Order_By>>;
  where?: InputMaybe<Core_Sitesecuritycoverage_Bool_Exp>;
};


export type Subscription_RootCore_Sitesecuritycoverage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Sitesecuritycoverage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitesecuritycoverage_Order_By>>;
  where?: InputMaybe<Core_Sitesecuritycoverage_Bool_Exp>;
};


export type Subscription_RootCore_Sitesecuritycoverage_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Sitesecuritycoverage_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Sitesecuritycoverage_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Sitesecuritycoverage_Bool_Exp>;
};


export type Subscription_RootCore_SitestatusArgs = {
  distinct_on?: InputMaybe<Array<Core_Sitestatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitestatus_Order_By>>;
  where?: InputMaybe<Core_Sitestatus_Bool_Exp>;
};


export type Subscription_RootCore_Sitestatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Sitestatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitestatus_Order_By>>;
  where?: InputMaybe<Core_Sitestatus_Bool_Exp>;
};


export type Subscription_RootCore_Sitestatus_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Sitestatus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Sitestatus_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Sitestatus_Bool_Exp>;
};


export type Subscription_RootCore_SitetypeArgs = {
  distinct_on?: InputMaybe<Array<Core_Sitetype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitetype_Order_By>>;
  where?: InputMaybe<Core_Sitetype_Bool_Exp>;
};


export type Subscription_RootCore_Sitetype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Sitetype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitetype_Order_By>>;
  where?: InputMaybe<Core_Sitetype_Bool_Exp>;
};


export type Subscription_RootCore_Sitetype_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Sitetype_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Sitetype_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Sitetype_Bool_Exp>;
};


export type Subscription_RootCore_SundrychargeArgs = {
  distinct_on?: InputMaybe<Array<Core_Sundrycharge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sundrycharge_Order_By>>;
  where?: InputMaybe<Core_Sundrycharge_Bool_Exp>;
};


export type Subscription_RootCore_Sundrycharge_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Sundrycharge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sundrycharge_Order_By>>;
  where?: InputMaybe<Core_Sundrycharge_Bool_Exp>;
};


export type Subscription_RootCore_Sundrycharge_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Sundrycharge_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Sundrycharge_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Sundrycharge_Bool_Exp>;
};


export type Subscription_RootCore_SundrychargetypeArgs = {
  distinct_on?: InputMaybe<Array<Core_Sundrychargetype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sundrychargetype_Order_By>>;
  where?: InputMaybe<Core_Sundrychargetype_Bool_Exp>;
};


export type Subscription_RootCore_Sundrychargetype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Sundrychargetype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sundrychargetype_Order_By>>;
  where?: InputMaybe<Core_Sundrychargetype_Bool_Exp>;
};


export type Subscription_RootCore_Sundrychargetype_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Sundrychargetype_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Sundrychargetype_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Sundrychargetype_Bool_Exp>;
};


export type Subscription_RootCore_TariffArgs = {
  distinct_on?: InputMaybe<Array<Core_Tariff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Tariff_Order_By>>;
  where?: InputMaybe<Core_Tariff_Bool_Exp>;
};


export type Subscription_RootCore_Tariff_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Tariff_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Tariff_Order_By>>;
  where?: InputMaybe<Core_Tariff_Bool_Exp>;
};


export type Subscription_RootCore_Tariff_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Tariff_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Tariff_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Tariff_Bool_Exp>;
};


export type Subscription_RootCore_UserArgs = {
  distinct_on?: InputMaybe<Array<Core_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_Order_By>>;
  where?: InputMaybe<Core_User_Bool_Exp>;
};


export type Subscription_RootCore_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_Order_By>>;
  where?: InputMaybe<Core_User_Bool_Exp>;
};


export type Subscription_RootCore_User_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_User_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Core_User_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_Groups_Order_By>>;
  where?: InputMaybe<Core_User_Groups_Bool_Exp>;
};


export type Subscription_RootCore_User_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_User_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_Groups_Order_By>>;
  where?: InputMaybe<Core_User_Groups_Bool_Exp>;
};


export type Subscription_RootCore_User_Groups_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_User_Groups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_User_Groups_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_User_Groups_Bool_Exp>;
};


export type Subscription_RootCore_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_User_Bool_Exp>;
};


export type Subscription_RootCore_User_User_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Core_User_User_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_User_Permissions_Order_By>>;
  where?: InputMaybe<Core_User_User_Permissions_Bool_Exp>;
};


export type Subscription_RootCore_User_User_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_User_User_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_User_User_Permissions_Order_By>>;
  where?: InputMaybe<Core_User_User_Permissions_Bool_Exp>;
};


export type Subscription_RootCore_User_User_Permissions_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_User_User_Permissions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_User_User_Permissions_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_User_User_Permissions_Bool_Exp>;
};


export type Subscription_RootCore_VisitArgs = {
  distinct_on?: InputMaybe<Array<Core_Visit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Visit_Order_By>>;
  where?: InputMaybe<Core_Visit_Bool_Exp>;
};


export type Subscription_RootCore_Visit_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Visit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Visit_Order_By>>;
  where?: InputMaybe<Core_Visit_Bool_Exp>;
};


export type Subscription_RootCore_Visit_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Visit_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Visit_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Visit_Bool_Exp>;
};


export type Subscription_RootCore_VisitstatusenumArgs = {
  distinct_on?: InputMaybe<Array<Core_Visitstatusenum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Visitstatusenum_Order_By>>;
  where?: InputMaybe<Core_Visitstatusenum_Bool_Exp>;
};


export type Subscription_RootCore_Visitstatusenum_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Core_Visitstatusenum_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Visitstatusenum_Order_By>>;
  where?: InputMaybe<Core_Visitstatusenum_Bool_Exp>;
};


export type Subscription_RootCore_Visitstatusenum_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootCore_Visitstatusenum_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Core_Visitstatusenum_Stream_Cursor_Input>>;
  where?: InputMaybe<Core_Visitstatusenum_Bool_Exp>;
};


export type Subscription_RootDjango_Admin_LogArgs = {
  distinct_on?: InputMaybe<Array<Django_Admin_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Django_Admin_Log_Order_By>>;
  where?: InputMaybe<Django_Admin_Log_Bool_Exp>;
};


export type Subscription_RootDjango_Admin_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Django_Admin_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Django_Admin_Log_Order_By>>;
  where?: InputMaybe<Django_Admin_Log_Bool_Exp>;
};


export type Subscription_RootDjango_Admin_Log_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDjango_Admin_Log_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Django_Admin_Log_Stream_Cursor_Input>>;
  where?: InputMaybe<Django_Admin_Log_Bool_Exp>;
};


export type Subscription_RootDjango_Content_TypeArgs = {
  distinct_on?: InputMaybe<Array<Django_Content_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Django_Content_Type_Order_By>>;
  where?: InputMaybe<Django_Content_Type_Bool_Exp>;
};


export type Subscription_RootDjango_Content_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Django_Content_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Django_Content_Type_Order_By>>;
  where?: InputMaybe<Django_Content_Type_Bool_Exp>;
};


export type Subscription_RootDjango_Content_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDjango_Content_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Django_Content_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Django_Content_Type_Bool_Exp>;
};


export type Subscription_RootDjango_MigrationsArgs = {
  distinct_on?: InputMaybe<Array<Django_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Django_Migrations_Order_By>>;
  where?: InputMaybe<Django_Migrations_Bool_Exp>;
};


export type Subscription_RootDjango_Migrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Django_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Django_Migrations_Order_By>>;
  where?: InputMaybe<Django_Migrations_Bool_Exp>;
};


export type Subscription_RootDjango_Migrations_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootDjango_Migrations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Django_Migrations_Stream_Cursor_Input>>;
  where?: InputMaybe<Django_Migrations_Bool_Exp>;
};


export type Subscription_RootDjango_SessionArgs = {
  distinct_on?: InputMaybe<Array<Django_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Django_Session_Order_By>>;
  where?: InputMaybe<Django_Session_Bool_Exp>;
};


export type Subscription_RootDjango_Session_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Django_Session_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Django_Session_Order_By>>;
  where?: InputMaybe<Django_Session_Bool_Exp>;
};


export type Subscription_RootDjango_Session_By_PkArgs = {
  session_key: Scalars['String'];
};


export type Subscription_RootDjango_Session_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Django_Session_Stream_Cursor_Input>>;
  where?: InputMaybe<Django_Session_Bool_Exp>;
};


export type Subscription_RootGetcompanywidesitechargecostandunitsbycompanyidArgs = {
  args: Getcompanywidesitechargecostandunitsbycompanyid_Args;
  distinct_on?: InputMaybe<Array<Core_Res_Company_Wide_Charge_Estimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Res_Company_Wide_Charge_Estimate_Order_By>>;
  where?: InputMaybe<Core_Res_Company_Wide_Charge_Estimate_Bool_Exp>;
};


export type Subscription_RootGetcompanywidesitechargecostandunitsbycompanyid_AggregateArgs = {
  args: Getcompanywidesitechargecostandunitsbycompanyid_Args;
  distinct_on?: InputMaybe<Array<Core_Res_Company_Wide_Charge_Estimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Res_Company_Wide_Charge_Estimate_Order_By>>;
  where?: InputMaybe<Core_Res_Company_Wide_Charge_Estimate_Bool_Exp>;
};


export type Subscription_RootGetsitechargecostandunitsbysiteidArgs = {
  args: Getsitechargecostandunitsbysiteid_Args;
  distinct_on?: InputMaybe<Array<Core_Sitechargeestimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitechargeestimate_Order_By>>;
  where?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
};


export type Subscription_RootGetsitechargecostandunitsbysiteid_AggregateArgs = {
  args: Getsitechargecostandunitsbysiteid_Args;
  distinct_on?: InputMaybe<Array<Core_Sitechargeestimate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Core_Sitechargeestimate_Order_By>>;
  where?: InputMaybe<Core_Sitechargeestimate_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['bigint'];
  company_id?: InputMaybe<Scalars['bigint']>;
  first_name?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_staff?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  role_id?: InputMaybe<Scalars['bigint']>;
}>;


export type UpdateUserMutation = { __typename?: 'mutation_root', update_core_user?: { __typename?: 'core_user_mutation_response', returning: Array<{ __typename?: 'core_user', id: any }> } | null };

export type DeleteUserMutationVariables = Exact<{
  id?: InputMaybe<Scalars['bigint']>;
}>;


export type DeleteUserMutation = { __typename?: 'mutation_root', delete_core_user?: { __typename?: 'core_user_mutation_response', returning: Array<{ __typename?: 'core_user', id: any }> } | null };

export type FetchCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchCompaniesQuery = { __typename?: 'query_root', core_company: Array<{ __typename?: 'core_company', id: any, name: string }> };

export type FetchUserRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchUserRolesQuery = { __typename?: 'query_root', core_role: Array<{ __typename?: 'core_role', id: any, title: string }> };

export type FetchAccessTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchAccessTypeQuery = { __typename?: 'query_root', auth_permission: Array<{ __typename?: 'auth_permission', id: number, name: string }> };

export type FetchUsersQueryVariables = Exact<{
  id?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<Boolean_Comparison_Exp>;
  role?: InputMaybe<Bigint_Comparison_Exp>;
  companyName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
}>;


export type FetchUsersQuery = { __typename?: 'query_root', core_user: Array<{ __typename?: 'core_user', id: any, email: string, last_name: string, first_name: string, is_active: boolean, is_staff: boolean, core_company?: { __typename?: 'core_company', id: any, name: string } | null, core_role: { __typename?: 'core_role', id: any, title: string }, core_user_user_permissions: Array<{ __typename?: 'core_user_user_permissions', id: any, auth_permission: { __typename?: 'auth_permission', name: string, id: number } }> }> };


export const UpdateUserDocument = gql`
    mutation updateUser($id: bigint!, $company_id: bigint, $first_name: String, $is_active: Boolean, $is_staff: Boolean, $last_name: String, $role_id: bigint) {
  update_core_user(
    where: {id: {_eq: $id}}
    _set: {first_name: $first_name, is_active: $is_active, is_staff: $is_staff, last_name: $last_name, company_id: $company_id, role_id: $role_id}
  ) {
    returning {
      id
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      company_id: // value for 'company_id'
 *      first_name: // value for 'first_name'
 *      is_active: // value for 'is_active'
 *      is_staff: // value for 'is_staff'
 *      last_name: // value for 'last_name'
 *      role_id: // value for 'role_id'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: bigint) {
  delete_core_user(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const FetchCompaniesDocument = gql`
    query fetchCompanies {
  core_company {
    id
    name
  }
}
    `;

/**
 * __useFetchCompaniesQuery__
 *
 * To run a query within a React component, call `useFetchCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<FetchCompaniesQuery, FetchCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchCompaniesQuery, FetchCompaniesQueryVariables>(FetchCompaniesDocument, options);
      }
export function useFetchCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchCompaniesQuery, FetchCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchCompaniesQuery, FetchCompaniesQueryVariables>(FetchCompaniesDocument, options);
        }
export type FetchCompaniesQueryHookResult = ReturnType<typeof useFetchCompaniesQuery>;
export type FetchCompaniesLazyQueryHookResult = ReturnType<typeof useFetchCompaniesLazyQuery>;
export type FetchCompaniesQueryResult = Apollo.QueryResult<FetchCompaniesQuery, FetchCompaniesQueryVariables>;
export const FetchUserRolesDocument = gql`
    query fetchUserRoles {
  core_role {
    id
    title
  }
}
    `;

/**
 * __useFetchUserRolesQuery__
 *
 * To run a query within a React component, call `useFetchUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchUserRolesQuery(baseOptions?: Apollo.QueryHookOptions<FetchUserRolesQuery, FetchUserRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchUserRolesQuery, FetchUserRolesQueryVariables>(FetchUserRolesDocument, options);
      }
export function useFetchUserRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchUserRolesQuery, FetchUserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchUserRolesQuery, FetchUserRolesQueryVariables>(FetchUserRolesDocument, options);
        }
export type FetchUserRolesQueryHookResult = ReturnType<typeof useFetchUserRolesQuery>;
export type FetchUserRolesLazyQueryHookResult = ReturnType<typeof useFetchUserRolesLazyQuery>;
export type FetchUserRolesQueryResult = Apollo.QueryResult<FetchUserRolesQuery, FetchUserRolesQueryVariables>;
export const FetchAccessTypeDocument = gql`
    query fetchAccessType {
  auth_permission {
    id
    name
  }
}
    `;

/**
 * __useFetchAccessTypeQuery__
 *
 * To run a query within a React component, call `useFetchAccessTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAccessTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAccessTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAccessTypeQuery(baseOptions?: Apollo.QueryHookOptions<FetchAccessTypeQuery, FetchAccessTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchAccessTypeQuery, FetchAccessTypeQueryVariables>(FetchAccessTypeDocument, options);
      }
export function useFetchAccessTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAccessTypeQuery, FetchAccessTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchAccessTypeQuery, FetchAccessTypeQueryVariables>(FetchAccessTypeDocument, options);
        }
export type FetchAccessTypeQueryHookResult = ReturnType<typeof useFetchAccessTypeQuery>;
export type FetchAccessTypeLazyQueryHookResult = ReturnType<typeof useFetchAccessTypeLazyQuery>;
export type FetchAccessTypeQueryResult = Apollo.QueryResult<FetchAccessTypeQuery, FetchAccessTypeQueryVariables>;
export const FetchUsersDocument = gql`
    query fetchUsers($id: bigint_comparison_exp, $status: Boolean_comparison_exp, $role: bigint_comparison_exp, $companyName: String, $name: String) {
  core_user(
    where: {id: $id, is_active: $status, _or: [{first_name: {_ilike: $name}}, {last_name: {_ilike: $name}}], core_company: {name: {_ilike: $companyName}}, core_role: {id: $role}}
  ) {
    id
    email
    last_name
    first_name
    is_active
    is_staff
    core_company {
      id
      name
    }
    core_role {
      id
      title
    }
    core_user_user_permissions {
      id
      auth_permission {
        name
        id
      }
    }
  }
}
    `;

/**
 * __useFetchUsersQuery__
 *
 * To run a query within a React component, call `useFetchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      role: // value for 'role'
 *      companyName: // value for 'companyName'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useFetchUsersQuery(baseOptions?: Apollo.QueryHookOptions<FetchUsersQuery, FetchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchUsersQuery, FetchUsersQueryVariables>(FetchUsersDocument, options);
      }
export function useFetchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchUsersQuery, FetchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchUsersQuery, FetchUsersQueryVariables>(FetchUsersDocument, options);
        }
export type FetchUsersQueryHookResult = ReturnType<typeof useFetchUsersQuery>;
export type FetchUsersLazyQueryHookResult = ReturnType<typeof useFetchUsersLazyQuery>;
export type FetchUsersQueryResult = Apollo.QueryResult<FetchUsersQuery, FetchUsersQueryVariables>;