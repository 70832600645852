export enum MediaTypesEnum {
    VIDEO = 'video',
    IMAGE = 'image',
    AUDIO = 'audio',
    PDF = 'pdf',
    DOC = 'doc',    // Microsoft Word Document
    XLS = 'xls',    // Microsoft Excel Spreadsheet
    PPT = 'ppt',    // Microsoft PowerPoint Presentation
    TXT = 'txt',    // Text File
    CSV = 'csv',    // Comma Separated Values
    ZIP = 'zip',    // ZIP Archive
    HTML = 'html',  // HTML File
    JSON = 'json',  // JSON File
    XML = 'xml',    // XML File
    SVG = 'svg',    // Scalable Vector Graphics
    OTHERS = 'others'
}