import AddIcon from "@mui/icons-material/Add";
import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useFetchUsersLazyQuery } from "../../../../generated/graphql";
import { useShuriPagination } from "../../../../lib/hooks";
import { IFilter } from "../../../../lib/interfaces";
import { Content, PageAppBar } from "../../../components";
import UsersTable from "../../../components/users/UsersTable";
import { UserFilterSearch } from "../../../components/users/user-filter-search";
import { AppBackgroundColor } from "../../../config";
import NewUserDrawer from "./NewUserDrawer";

export function UsersPage() {
  const [fetchUsers, { data, refetch }] = useFetchUsersLazyQuery();

  useEffect(() => {
    const variables = {
      name: "%%",
      companyName: "%%",
      role: { _is_null: false },
      status: { _is_null: false },
      id: { _is_null: false }
    };
    fetchUsers({ variables });
  }, [fetchUsers]);

  const { page, rowsPerPage, handleChangePage } = useShuriPagination(10);
  const [showCreateUser, setShowCreateUser] = useState(false);

  const [users, setUsers] = useState<any[]>([]);
  const [userTypeId, setUserTypeId] =
    useState<string | number | undefined>(undefined);
  const [accessTypeId, setAccessTypeId] =
    useState<string | number | undefined>(undefined);
  const [accountStatus, setAccountStatus] =
    useState<string | number | undefined>(undefined);

  useEffect(() => {
    setUsers(data?.core_user || []);
  }, [data]);

  const handleFilterUpdate = (filters: IFilter) => {
    const variables = {
      name: "%%",
      companyName: "%%",
      role: { _is_null: false },
      status: { _is_null: false },
      id: { _is_null: false }
    };
    switch (filters.filters[0]) {
      case "companyName":
        variables.companyName = `%${filters.value}%`;
        break;
      case "name":
        variables.name = `%${filters.value}%`;
        break;
      case "id":
        // @ts-expect-error
        variables.id = { _eq: parseInt(filters.value) };
        break;
      default:
        break;
    }
    fetchUsers({ variables });
  };

  useEffect(() => {
    let variables = {
      name: "%%",
      companyName: "%%",
      role: { _is_null: false },
      status: { _is_null: false },
      id: { _is_null: false }
    };
    if (userTypeId) {
      // @ts-expect-error
      variables.role = { _eq: userTypeId.toString() };
    }
    if (accountStatus) {
      // @ts-expect-error
      variables.status = { _eq: Boolean(accountStatus) };
    }
    fetchUsers({ variables });
  }, [userTypeId, accessTypeId, accountStatus, fetchUsers]);

  return (
    <Box>
      <PageAppBar
        title="User Management"
        sub="Manage user details here"
        ActionButton={() => (
          <Button
            size="medium"
            variant="contained"
            color="info"
            onClick={() => setShowCreateUser(true)}
            startIcon={<AddIcon />}
            sx={{ borderRadius: "28px", marginRight: "10px" }}
          >
            Create User
          </Button>
        )}
      />

      <Content>
        <Box height="auto" bgcolor={AppBackgroundColor} margin={4}>
          <Box
            color="white"
            marginBottom={2}
            display="flex"
            justifyContent="space-between"
          >
            <Box alignSelf="flex-end" bgcolor="red" />
            <UserFilterSearch
              users={users}
              onFilterUpdate={handleFilterUpdate}
            />
          </Box>
          <Box
            sx={{
              "@media (max-width: 600px)": {
                objectFit: "scale-down",
                display: "flex",
                width: "100%",
                margin: 0,
                overflowX: "hidden",
                paddingBottom: 10,
              },
            }}
          >
            <Box sx={{ width: "100%", overflowX: "auto" }}>
            
          <UsersTable
            users={users}
            refetch={refetch}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            userTypeId={userTypeId}
            setUserTypeId={setUserTypeId}
            accessTypeId={accessTypeId}
            setAccessTypeId={setAccessTypeId}
            accountStatus={accountStatus}
            setAccountStatus={setAccountStatus}
          />
            </Box>
          </Box>
        </Box>
      </Content>
      <NewUserDrawer
        isOpen={showCreateUser}
        onClose={() => setShowCreateUser(false)}
        onComplete={refetch}
      />
    </Box>
  );
}
