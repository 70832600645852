import {gql, useQuery} from "@apollo/client";

export const USER_SUB_DATA = gql`
    query USER_SUB_DATA {
        core_user {
            id
            first_name
            last_name
            email
        }
    }
`;

export const useUsersSubData = () => {
    const {data, error, loading, refetch} = useQuery(USER_SUB_DATA);
    return {data, error, loading, refetch};
};