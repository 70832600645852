import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import {perPage} from "../../../config";
import {IEventLogFilters} from "../../interfaces";

export const EVENT_LOGS_QUERY = gql`
    query EVENT_LOG_QUERY(
        $limit: Int
        $offset: Int
        $eventLogFilters: core_eventlog_bool_exp
    ) {
        core_eventlog(
            where: $eventLogFilters
            limit: $limit
            offset: $offset
            order_by: {updated_at: desc, created_at: desc}
        ) {
            created_at
            viewed_at
            event_log_type
            event_name
            event_priority
            site_id
            id
            source
            core_logs {
                authorised
                camera_id
                date_recorded
                event_log_id
                id
                log_type
                pic
            }
            core_site {
                id
                name
            }
        }

        core_eventlog_aggregate(where: $eventLogFilters) {
            aggregate {
                count
            }
        }
    }
`

const getFilters = ({event_log_type, event_name}: IEventLogFilters)=>{
    let filters = {};

    /**
     * Apply event_name filter in filters object if it is supplied.
     * event_name is searched.
     */
    filters = event_name ? {
        ...filters,
        event_name: { _ilike: `%${event_name}%`  }
    } : filters;

    /**
     * Apply event_log_type filter in filters object if it is supplied.
     * event_log_type is searched.
     */
    filters = event_log_type ? {
        ...filters,
        event_log_type: { _ilike: `%${event_log_type}%`  }
    } : filters;

    return {
        ...filters
    }
}


export const useEventLogs = (page = 1, limit = perPage, filters: IEventLogFilters = {}) => {
    const offset = page * limit;
    const {data, error, loading, refetch} = useQuery(EVENT_LOGS_QUERY, {
        variables: {
            offset,
            limit,
            eventLogFilters: getFilters(filters)
        }
    });

    const updateEventLogsData = async (updateFilters: IEventLogFilters,  filterPage = 1, filterLimit = perPage) => {
        await refetch({
            eventLogFilters: getFilters(updateFilters)
        });
    };
    return {data, error, loading, updateEventLogsData};
}
