import { IconButton, Typography } from "@mui/material";
import { Content, SchedulingTable, MenuBar, SearchBox, } from "../../../components"
import { ArrowBack } from "@mui/icons-material";

export function SchedulingPage(props: any) {
    return (<Content>
        <MenuBar>
            <IconButton color="secondary" onClick={() => window.history.back()}> <ArrowBack /></IconButton>
            <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 0.98, paddingLeft: 10 }}>
                Site Scheduling
            </Typography>
            <SearchBox placeholder="Search Sites..." />
        </MenuBar>
        <SchedulingTable></SchedulingTable>
    </Content>
    )
}