import { Box, Button, InputLabel, TextField } from "@mui/material";
import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import AuthLayout from "../../../layout/AuthLayout";

const ResetPassword = () => {
  const defaultValues = {
    username: "",
    code: "",
    password: "",
    confirmPassword: ""
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors }
  } = useForm({ defaultValues });

  useEffect(() => {
    const userEmail = window.sessionStorage.getItem("FORGOT_PASSWORD");
    if (userEmail) {
      setValue("username", userEmail);
    }
  }, [setValue]);

  const onResetPassword = async ({
    username,
    code,
    password
  }: typeof defaultValues) => {
    try {
      await Auth.forgotPasswordSubmit(username, code, password);
      window.sessionStorage.removeItem("FORGOT_PASSWORD");
      window.location.href = window.location.origin;
    } catch (error) {
      console.log({ error });
      // how validation msg and add alert toast
    }
  };

  return (
    <AuthLayout title="Reset Password">
      <Box marginY={3}>
        <InputLabel sx={{ color: "white" }}>Secure code</InputLabel>
        <TextField
          fullWidth
          type="number"
          color="info"
          {...register("code", { required: "Your OTP is required" })}
          variant="outlined"
          InputLabelProps={{ style: { color: "gray" } }}
          InputProps={{ style: { color: "white" } }}
        />
        {errors.code && (
          <InputLabel sx={{ color: "white" }}>{errors.code.message}</InputLabel>
        )}
      </Box>
      <Box marginY={3}>
        <InputLabel sx={{ color: "white" }}>Password</InputLabel>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Enter password"
          color="info"
          {...register("password", { required: "Your password is required" })}
          InputLabelProps={{ style: { color: "gray" } }}
          InputProps={{ style: { color: "white" } }}
        />
        {errors.password && (
          <InputLabel sx={{ color: "white" }}>
            {errors.password.message}
          </InputLabel>
        )}
      </Box>
      <Box marginY={3}>
        <InputLabel sx={{ color: "white" }}>Password</InputLabel>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Enter password"
          color="info"
          {...register("confirmPassword", {
            required: "Your password confirmation is required"
          })}
          InputLabelProps={{ style: { color: "gray" } }}
          InputProps={{ style: { color: "white" } }}
        />
        {errors.confirmPassword && (
          <InputLabel sx={{ color: "white" }}>
            {errors.confirmPassword.message}
          </InputLabel>
        )}
      </Box>

      <Button
        variant="contained"
        onClick={handleSubmit(onResetPassword)}
        sx={{ backgroundColor: "#4490AE", color: "black", width: "360px" }}
      >
        {isSubmitting ? "Loading" : "Submit"}
      </Button>
    </AuthLayout>
  );
};

export default ResetPassword;
