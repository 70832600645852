import {useShuriPagination, useSiteRectifiersAlarms} from "../../../../lib";
import {useParams} from "react-router-dom";
import {RectifierAlarmTable} from "../../rectifier";
import {perPage} from "../../../../config";
import {Button, DialogContent, DialogTitle} from "@mui/material";
import {useState} from "react";
import {ShuriStyledDialog, ShuriStyledDialogBackdrop} from "../../dialog";


export function RectifierAlarmMetricsTable() {
    const [open, setOpen] = useState<boolean>(false);
    return (
        <>
            <Button
                onClick={()=> setOpen(true)}
                sx={{textDecoration: 'capitalize'}}
                size="large"
                variant="text">View Detailed</Button>

            <ShuriStyledDialog
                maxWidth="md"
                fullWidth={true}
                open={open}
                onClose={()=> setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                BackdropComponent={ShuriStyledDialogBackdrop}
            >
                <DialogTitle id="alert-dialog-title">
                        All Site Rectifier Alarms
                </DialogTitle>

                <DialogContent>
                    <ShowAlarmMetricsTable/>
                </DialogContent>
            </ShuriStyledDialog>
        </>
    )
}

const ShowAlarmMetricsTable = () => {
    const {id} = useParams();
    const {page, rowsPerPage, handleChangePage, handleChangeRowsPerPage} = useShuriPagination(perPage);
    const {loading, data, error} = useSiteRectifiersAlarms(id, page, rowsPerPage);

    return (
        <>
            <RectifierAlarmTable
                data={data}
                loading={loading}
                error={error}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}/>
        </>
    )
}