import {styled} from "@mui/material/styles";
import {Box} from "@mui/material";
import {BaseColorLight, InputBaseColorLight} from "../../../config";

export const StyledDetailsContainer = styled(Box)(() => ({
    padding: '20px',
    '.details-tile': {
        display: 'flex',
        flexDirection: 'column',

        '&.dotted-right': {
            position: 'relative',
            paddingRight: 10,
            '&::after': {
                content: '""',
                position: 'absolute',
                top: '20%',
                right: 0,
                height: '60%',
                borderRight: '1px dashed',
                borderColor: InputBaseColorLight,
            }
        }
    },
    '.details-action-tile': {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    '.details-title': {
        fontFamily: 'Outfit',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '20px',
        color: BaseColorLight,
        paddingBottom: '5px',
    },
    '.details-value': {
        fontFamily: 'Outfit',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '28px',
        color: InputBaseColorLight,
        paddingBottom: '15px',
        paddingRight: '15px',
        // whiteSpace: 'nowrap',
        // overflow: 'hidden',
        // textOverflow: 'ellipsis'
    }
}));