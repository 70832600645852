import {Box, Button, Grid, Typography} from "@mui/material";
import {primaryBlue} from "../../../config";
import {StyledDetailsContainer} from "./StyledDetailsContainer";
import {useSiteContext} from "../../../../lib/context";
import {useNavigate, useParams} from "react-router-dom";

export function SiteDetailsInfo() {
    const {site: data, lastSeenData} = useSiteContext();
    const navigate = useNavigate();
    let {id} = useParams();
    const coreState = lastSeenData?.core_sitestate
    const siteReasons = coreState[0]?.core_statereasons
    const status = data?.core_site[0]?.core_sitestatus?.site_status
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Typography className="shuri-card-title">Details</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    }}>
                        <Button sx={{
                            color: primaryBlue,
                            textTransform: 'capitalize',
                        }}
                                onClick={() => {navigate(`/site/${id}/edit`)}}>
                            Edit Details
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <StyledDetailsContainer>
                <Grid container spacing={2}>
                    <Grid xs={6} md={4} item className="details-tile">
                        <Box className="details-title">Site Name</Box>
                        <Box className="details-value">{data?.core_site[0]?.name}</Box>
                    </Grid>
                    <Grid xs={6} md={4} item className="details-tile">
                        <Box className="details-title">Site ID</Box>
                        <Box className="details-value">{data?.core_site[0]?.site_id}</Box>
                    </Grid>
                    <Grid xs={6} md={4} item className="details-tile">
                        <Box className="details-title">Site Type</Box>
                        <Box className="details-value">{data?.core_site[0]?.core_sitetype?.site_type}</Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid xs={6} md={4} item className="details-tile">
                        <Box className="details-title">Site Location</Box>
                        <Box className="details-value">{data?.core_site[0]?.core_siteregion?.region_name}</Box>
                    </Grid>
                    <Grid xs={6} md={4} item className="details-tile">
                        <Box className="details-title">Site municipality</Box>
                        <Box className="details-value">{data?.core_site[0]?.municipality ?? "-"}</Box>
                    </Grid>
                    <Grid xs={6} md={4} item className="details-tile">
                        <Box className="details-title">Site status</Box>
                        <Box className="details-value">
                        {data?.core_site[0]?.is_enabled ?
                         `${status} : ${siteReasons?.map((reason: any) => {
                         return ( reason?.core_reasonenum?.reason)
                             })}` :
                             "Disabled"
                         }
                        </Box>

                    </Grid>
                </Grid> 
                <Grid container spacing={2}>
                    <Grid xs={6} md={4} item className="details-tile">
                        <Box className="details-title">Site Latitude</Box>
                        <Box className="details-value">{data?.core_site[0]?.longitude ?? "-"}</Box>
                    </Grid>
                    <Grid xs={6} md={4} item className="details-tile">
                        <Box className="details-title">Site Longitude</Box>
                        <Box className="details-value">{data?.core_site[0]?.latitude ?? "-"}</Box>
                    </Grid>
                </Grid>
            </StyledDetailsContainer>
        </>
    )
}