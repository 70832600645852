import gql from "graphql-tag";
import {useQuery} from "@apollo/client";

export const SITES_CHARGE_QUERY = gql`
    query siteChargeCostAndUnitsBySiteId($id: Int = "28") {
        getsitechargecostandunitsbysiteid(args: {v_site_id: $id}){
            cost
            total_units
            charge_period_start
            charge_period_end
        }
    }
`

export const useGetSiteCharge = (id: any) => {
    const {data, error, loading} = useQuery(SITES_CHARGE_QUERY, {
        variables: {
            id
        }
    });
    return {data, error, loading};
}
