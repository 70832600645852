import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {StyledTableCell, StyledTableRow} from "../../table";
import TableBody from "@mui/material/TableBody";
import {Link} from "react-router-dom";
import {AppBackgroundColor, primaryBlue} from "../../../config";
import {Box, Chip, TableFooter, Typography} from "@mui/material";
import {formatDate} from "../../../../lib/filters";
import {SiteAction} from "./SiteAction";
import ShuriTablePagination from "../../table/ShuriTablePagination";
import Table from "@mui/material/Table";
import {ITablePropsInterface} from "../../../../lib";
interface ISitesProp extends ITablePropsInterface{
    data: any;
    page: number;
    rowsPerPage: number;
    handleChangePage: any;
    onRefresh?: () => void
}

export function SitesTable({data, page, rowsPerPage, handleChangePage, onRefresh, handleChangeRowsPerPage}: ISitesProp) {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <StyledTableCell> Site ID</StyledTableCell>
                    <StyledTableCell>Site Name</StyledTableCell>
                    <StyledTableCell>Location</StyledTableCell>
                    <StyledTableCell>Security Coverage</StyledTableCell>
                    <StyledTableCell>Enabled | Disabled</StyledTableCell>
                    <StyledTableCell>Site Status</StyledTableCell>
                    <StyledTableCell>
                        Last  Seen
                    </StyledTableCell>
                    <StyledTableCell sx={{color: "#4C576B"}}>Actions</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data?.core_site.map((site: any) => {
                    const coreState  = site?.core_sitestates
                    const lastseen =  coreState?.[0]?.lastseen
                    const siteStaus = site?.core_sitestatus?.site_status
                    const isCovered = site?.core_sitesecuritycoverages[0]?.is_covered
                    return (
                        <StyledTableRow key={site.id}>
                            <StyledTableCell>
                                <Link
                                    style={{
                                        color: primaryBlue,
                                        textDecoration: 'underline',
                                        fontWeight: '400',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                    }}
                                    to={`/site/${site.id}`}>{site.site_id}</Link>
                            </StyledTableCell>
                            <StyledTableCell> {site.name}</StyledTableCell>
                            <StyledTableCell>
                                {site.core_siteregion.region_name}
                            </StyledTableCell>
                            <StyledTableCell>
                            <Chip
                                    sx={{
                                        backgroundColor: AppBackgroundColor,
                                        fontWeight: '500',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        border: 'none',
                                        padding: '2px 8px',
                                    }}
                                    label={
                                            isCovered
                                            ? "Available"
                                            : "Not Available"
                                    }
                                    color={isCovered ? 'success' : 'error'}
                                    size="small"
                                    variant="outlined"
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                            <Chip
                                    sx={{
                                        backgroundColor: AppBackgroundColor,
                                        fontWeight: '500',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        border: 'none',
                                        padding: '2px 8px',
                                    }}
                                    label={
                                        site?.is_enabled
                                            ? "Enabled"
                                            : "Disabled"
                                    }
                                    color={site?.is_enabled ? 'success' : 'error'}
                                    size="small"
                                    variant="outlined"
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                <Chip
                                    sx={{
                                        backgroundColor: AppBackgroundColor,
                                        fontWeight: '500',
                                        fontSize: '12px',
                                        lineHeight: '18px',
                                        border: 'none',
                                        padding: '2px 8px',

                                    }}
                                    label={
                                        siteStaus === "online"
                                            ? "Online"
                                            : "Offline"
                                    }
                                    color={siteStaus === "online" ? 'success' : 'error'}
                                    size="small"
                                    variant="outlined"
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'
                                }}>
                                    <Typography sx={{ marginLeft: '5px' }}>
                                        {lastseen ? formatDate(lastseen) : "--"}
                                    </Typography>
                                </Box>
                            </StyledTableCell>
                            <StyledTableCell>
                                <SiteAction site={site} onRefresh={onRefresh}/>
                            </StyledTableCell>
                        </StyledTableRow>
                    );
                })}
            </TableBody>
            <TableFooter>
                <TableRow sx={{border: 0, m: 1}}>
                    <ShuriTablePagination
                        colSpan={9}
                        count={data?.core_site_aggregate?.aggregate?.count ?? 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    )
}