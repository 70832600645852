import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { ADD_STATUS } from "../../../lib/hooks";
import { IFormProps } from ".";
import { Box, Button, Grid, TextField, InputLabel } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#26222a",
  color: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const initalState = {
  equipment_status: "",
};
export const StatusForm = React.forwardRef((props: IFormProps, ref: any) => {
  const [state, setState] = useState(initalState);
  const [addStatus, { data, loading, error }] = useMutation(ADD_STATUS, {
    onCompleted: () => {
      props.handleRefresh();
    },
  });

  const [success, setSuccess] = React.useState(false);

  const handleChange = (event: any) => {
    const value = event.target.value;
    setState({
      ...state,
      equipment_status: value,
    });
  };

  return (
    <Box sx={style}>
      <h1> Add New Equipment Status</h1>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          addStatus({
            variables: {
              equipment_status: state.equipment_status,
            },
          });
          setSuccess(true);
          props.closeHandler(true);
        }}
        sx={{ margin: 3 }}
      >
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={12}>
            <InputLabel id="demo-simple-select-label" sx={{ color: "#ADACAF" }}>
              Status
            </InputLabel>
            <TextField
              value={state.equipment_status}
              name="equipment_status"
              id="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChange}
              sx={{
                input: {
                  color: "black",
                  background: "white",
                  borderRadius: 2,
                },
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" type="submit" color="success">
              Add
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});
