import {gql, useQuery} from "@apollo/client";
import {perPage} from "../../../config";
import {getFilters} from "./site.hook";

export const SITES_CAMERAS_QUERY = gql`
    query SITES_CAMERAS_QUERY (
        $limit: Int
        $offset: Int
        $sitesFilters: core_site_bool_exp
    ) {
        core_site(
            limit: $limit
            offset: $offset
            where: $sitesFilters
            ) {
            id
            name
            site_id
            core_boxes {
                core_cameras {
                    camera_name
                    camera_id
                    box_id
                    audio_stream_uri
                    ip_address
                    video_stream_name
                    video_stream_uri
                }
            }
        }

        core_site_aggregate(where: $sitesFilters) {
            aggregate {
                count
            }
        }
    }
`;

export const useSiteCameras = (page = 1, limit = perPage, search?: string) => {
    const offset = page * limit;

    const {data, loading, error} = useQuery(SITES_CAMERAS_QUERY, {
        variables: {
            offset,
            limit,
            sitesFilters: {
                ...getFilters({name: search}),
                core_boxes: { core_cameras: {} }
            }
        }
    });
    return {data, loading, error}
}