import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { getFirstDateOfCurrentMonth } from "../../filters";
import { perPage } from "../../../config";

export const COMPANY_VISITS_QUERY = gql`
  query SITES_DISRUPTIONS($date: timestamptz) {
    core_visit(where: { start_datetime: { _gte: $date } }) {
      status_id
      start_datetime
      core_visitstatusenum {
        status
        id
      }
      core_job {
        site_id
        id
        ext_job_number
        description
        created_on
        core_personnelassigneds {
          personale_id
          job_id
          id
        }
      }
      id
      end_datetime
      comment
    }
  }
`;

export const useCompanyVisits = (
  date = getFirstDateOfCurrentMonth(),
  page = 1,
  limit = perPage
) => {
  const offset = page * limit;

  const { data, error, loading } = useQuery(COMPANY_VISITS_QUERY, {
    variables: { date },
  });
  return { data, error, loading };
};
