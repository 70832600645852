import React, {useEffect, useRef, useState} from "react";
import Hls from "hls.js";
import {IPlayerProps} from "../../../lib";
import {ShuriPlayerSkin} from "./shuri-video-player-skin";

interface IShuriLiveStreamPlayerProps extends IPlayerProps {
    h?: string
}

export function ShuriLiveStreamPlayer({onLoaded, url, onPlay, onStop, error, loading, empty}: IShuriLiveStreamPlayerProps) {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [play, setPlay] = useState(false);
    useEffect(() => {
        if (!videoRef.current || !url || url === '') return;
        const video = videoRef.current;
        let hls: Hls | undefined;
        if (Hls.isSupported()) {
            hls = new Hls({
                maxMaxBufferLength: 1,
                liveSyncDuration: 0.5,
                liveMaxLatencyDuration: 1,
                backBufferLength: 0,
                nudgeMaxRetry: 10,
            });

            hls.loadSource(url);
            hls.attachMedia(video);
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                video.play().catch(() => {});
                setPlay(true);
            });
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = url;
            video.addEventListener('loadedmetadata', () => {
                video.play().catch(() => {});
                setPlay(true);
            });
        }
        return () => {
            if (hls) hls.destroy();
            setPlay(false);
        };
    }, [url]);


    return (
        <>
            <ShuriPlayerSkin
                play={play}
                mediaRef={videoRef}
                live={true}
                url={url}
                onLoaded={onLoaded}
                onPlay={onPlay}
                onStop={onStop}
                error={error}
                loading={loading}
                empty={empty}
            />
        </>
    )
}