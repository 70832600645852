import { gql, useQuery } from "@apollo/client";

export const CALLOUTS_QUERY = gql`
  query MyQuery {
    core_securitycallouts(order_by: {created_on: desc}) {
      updated_at
      type_of_emergency
      site_id
      core_site {
        site_id
        name
        municipality
        address
        longitude
        latitude
      }
      resolved_at
      longitude
      logged_at
      latitude
      id
      created_on
      classification
      closed_on
      call_out_id
      core_securitycalloutsstatuses(limit: 1, order_by: {id: desc_nulls_last}) {
        id
        status
      }
    }
  }
`;

export const useCallouts = () => {
  const { data, loading, error, refetch } = useQuery(CALLOUTS_QUERY);
  return { data, loading, error, refetch };
};
