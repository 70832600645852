import {Core_Camera, Core_Site} from "../../../generated/graphql";
import React, {createContext, FC, ReactNode, useContext, useEffect, useState} from "react";
import {LayoutEnum} from "../../enums";
import {ADD_CAMERA_STREAMS_MUTATION, REMOVE_CAMERA_STREAMS_MUTATION, useCameraStreams} from "../../hooks";
import {useMutation} from "@apollo/client";
import {useNotification} from "../alerts";
import SkeletonLoader from "../../../ui/components/skeleton-loader";

export interface ICameraStream {
    core_site: Core_Site
    core_camera: Core_Camera | any
}

interface ICameraStreamsContext {
    streams: ICameraStream[],
    allStreams: ICameraStream[],
    handleSelectCameraStream: (camera: ICameraStream[]) => void,
    handleRemoveStream: (camera?: ICameraStream) => void,
    addOpen: boolean
    deletingStream: boolean
    toggleOpenForm: (status: boolean) => void
    preview?: ICameraStream
    setPreview: (preview?: ICameraStream) => void
    layout: LayoutEnum
    setLayout: (layout: LayoutEnum) => void
}

interface ICameraStreamsProvider {
    children: ReactNode;
    user_uuid: string;
}

const CameraStreamsContext = createContext<ICameraStreamsContext | null>(null);

export const CameraStreamsContextProvider: FC<ICameraStreamsProvider> = ({children, user_uuid}) => {
    const {data, loading, refetch} = useCameraStreams(0, 10, user_uuid);
    const [streams, setStreams] = useState<ICameraStream[]>(data?.core_stream_layout ?? []);
    const [addOpen, setAddOpen] = useState<boolean>(false);
    const [preview, setPreview] = useState<ICameraStream>();
    const [layout, setLayout] = useState(LayoutEnum.TILED4);
    const [createSiteStreams, {error: createError, data: createStreamData}] = useMutation(ADD_CAMERA_STREAMS_MUTATION)
    const [removeSiteStream, {error: removeStreamError, data: removeStreamData, loading: deletingStream}] = useMutation(REMOVE_CAMERA_STREAMS_MUTATION)
    const { notify } = useNotification();

    useEffect(()=>{
        if (createStreamData){
            refetch()
            notify({
                status: "success",
                open: true,
                message: "Streams added successfully!"
            });
        }
    }, [createStreamData])

    useEffect(()=>{
        if (createError){
            notify({
                status: "error",
                open: true,
                message:
                    "An error occurred trying to add streams, try again later!"
            });
        }
    }, [createError])

    useEffect(()=>{
        if (removeStreamData){
            refetch()
            notify({
                status: "success",
                open: true,
                message: "Stream removed successfully!"
            });
        }
    }, [removeStreamData])

    useEffect(()=>{
        if (removeStreamError){
            notify({
                status: "error",
                open: true,
                message:
                    "An error occurred trying to remove stream, try again later!"
            });
        }
    }, [removeStreamError])

    useEffect(()=>{
        if (data){
            setStreams(data?.core_stream_layout ?? []);
        }
    }, [data]);

    const handleSelectCameraStream = (cameras: ICameraStream[]) => {
        createCameraStream(cameras);
    }

    const createCameraStream = (cameras: ICameraStream[])=> {
        const data = cameras.map((camera)=>({
            user_uuid,
            camera_id: camera.core_camera.camera_id,
            site_id: camera.core_site.id
        }));

        createSiteStreams({
            variables: {
                objects: data
            },
            onCompleted(createSiteStreamData){
                if (createSiteStreamData) {
                    refetch()
                    notify({
                        status: "success",
                        open: true,
                        message: "Stream data updated successfully!"
                    });
                }
            },
            onError(error) {
                notify({
                    status: "error",
                    open: true,
                    message:
                        "An error occurred trying to add streams, try again later!"
                });
            }
        })
    }

    const handleRemoveStream = (camera?: ICameraStream) => {
        if (!camera){
           return  notify({
                status: "warning",
                open: true,
                message:
                    "Cannot remove camera at this point, no camera is selected."
            });
        }
        const data = {
            user_uuid,
            camera_id: camera.core_camera.camera_id,
            site_id: camera.core_site.id
        }

        removeSiteStream({
            variables: data,
            onCompleted(removeStreamData){
                if (removeStreamData) {
                    refetch()
                    notify({
                        status: "success",
                        open: true,
                        message: "Stream removed successfully!"
                    });
                }
            },
            onError(error) {
                notify({
                    status: "error",
                    open: true,
                    message:
                        "An error occurred trying to remove stream, try again later!"
                });
            }
        })
    }

    const toggleOpenForm = (state: boolean) => {
        setAddOpen(state);
    }

    return (
        <CameraStreamsContext.Provider value={{
            streams,
            allStreams: [],
            handleSelectCameraStream,
            handleRemoveStream,
            deletingStream,
            addOpen,
            toggleOpenForm,
            preview,
            setPreview,
            layout,
            setLayout,
        }}>
            {
                loading ? <SkeletonLoader/> : children
            }
        </CameraStreamsContext.Provider>
    )
}

export const useCameraStreamsContext = () => {
    const context = useContext(CameraStreamsContext);
    if (!context) {
        throw new Error("useCameraStreamsContext must be used within a CameraStreamsContextProvider");
    }
    return context;
};