import * as React from "react";
import { Content, MenuBar, SearchBox,ImgCarousel } from "../../../components";
import { Box, IconButton, Typography, Button } from "@mui/material";
import { ArrowBack, Add, FilterAlt } from "@mui/icons-material";
import { AppBackgroundColor } from "../../../config";
import { useInventory } from "../../../../lib/hooks";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TablePagination, TableFooter } from "@mui/material";
import { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";

export function AllInventoryPage() {
  const { data, error, loading } = useInventory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, data?.core_equipment.length - page * rowsPerPage);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (error) {
    return <h1>An error occurred </h1>;
  }
  if (loading) {
    return <p>Loading ....</p>;
  }

  return (
    <Box>
      <MenuBar>
        <IconButton color="secondary" onClick={() => window.history.back()}>
          <ArrowBack />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 0.98, paddingLeft: 10 }}
        >
          Inventory
        </Typography>
      </MenuBar>

      <Content>
        <Box
          sx={{
            height: "auto",
            backgroundSize: "cover",
            backgroundColor: AppBackgroundColor,
            margin: 4,
          }}
        >
          {" "}
          <Box
            sx={{
              color: "white",
              marginBottom: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Link to="/inventory/add-inventory">
              <Button
                startIcon={<Add />}
                variant="contained"
                sx={{
                  alignSelf: "center",
                  borderRadius: "4px",
                  backgroundColor: "#ffffff26",
                  color: "white",
                }}
              >
                Add Equipment
              </Button>
            </Link>
          </Box>
          <Box
            sx={{
              color: "white",
              marginBottom: 2,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <SearchBox placeholder="Search ..." />
            <Button
              startIcon={<FilterAlt />}
              variant="contained"
              sx={{
                alignSelf: "center",
                borderRadius: "4px",
                backgroundColor: "#ffffff26",
                color: "white",
              }}
            >
              Filter
            </Button>
          </Box>
          <Table>
            <TableHead sx={{ backgroundColor: "#110D19" }}>
              <TableRow
                sx={{
                  padding: 4,
                }}
              >
                <TableCell sx={{ color: "#4C576B" }}> Serial Number</TableCell>
                <TableCell sx={{ color: "#4C576B" }}> Type</TableCell>
                <TableCell sx={{ color: "#4C576B" }}> Status</TableCell>
                <TableCell sx={{ color: "#4C576B" }}> Model</TableCell>
                <TableCell sx={{ color: "#4C576B" }}> Make</TableCell>
                <TableCell sx={{ color: "#4C576B" }}> Part</TableCell>
                <TableCell sx={{ color: "#4C576B" }}> Condition</TableCell>
                <TableCell sx={{ color: "#4C576B" }}> Site</TableCell>
                <TableCell sx={{ color: "#4C576B" }}>Images</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: "#302D38" }}>
              {data?.core_equipment
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((equipment: any) => {
                  return (
                    <TableRow key={equipment.id}>
                      <TableCell sx={{ color: "white" }}>
                        {equipment.serial_number}
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        {equipment.core_equipmenttype.name}
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        {equipment.core_equipmentstatus.equipment_status}
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        {equipment.core_equipmentmodel.model_number}
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        {
                          equipment.core_equipmentmodel.core_equipmentmake
                            .equipment_make
                        }
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        {equipment.core_equipmentpart.equipment_part}
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        {equipment.core_equipmentcondition.condtion}
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        {equipment.core_site.site_id} {equipment.core_site.name}
                      </TableCell>
                      <TableCell sx={{ color: "white" }}>
                        <Accordion
                          sx={{ backgroundColor: "#302D38", color: "white" }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>
                              {equipment.core_equipmentimages.length}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <ImgCarousel core_equipmentimages= {equipment.core_equipmentimages} />
                          </AccordionDetails>
                        </Accordion>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <TableRow sx={{ border: 0, m: 1 }}>
                <TablePagination
                  sx={{ color: "white" }}
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={12}
                  count={data?.core_equipment.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
          <Box>
            <Box sx={{ padding: "0 1rem" }}>
              <Typography sx={{ color: "#fff", fontSize: 28 }}></Typography>
            </Box>
          </Box>
        </Box>
      </Content>
    </Box>
  );
}
