import {Tab} from "@mui/material";
import {styled} from "@mui/material/styles";
import {BaseColorLight} from "../../config";

interface StyledTabProps {
    label: string;
}

export const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))(({theme}) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.7)',
    padding: '8px 12px',
    flex: '1',
    minHeight: '36px',

    '&.Mui-selected': {
        color: '#fff',
        background: '#515050',
        boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
        borderRadius: '6px',
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
}));

export const StyledCompactTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))(({theme}) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    color: 'rgba(255, 255, 255, 0.7)',
    padding: '8px 12px',
    flex: '1',
    minHeight: '36px',
    border: '1px solid',
    borderLeft: 'none',
    borderColor: BaseColorLight,
    '&.Mui-selected': {
        color: '#fff',
        background: '#515050',
        boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
    '&:first-of-type': {
        borderRadius: '8px 0px 0px 8px',
        borderLeft: `1px solid ${BaseColorLight}`,
    },
    '&:last-child': {
        borderRadius: '0px 8px 8px 0px'
    }
}));