import {useSiteContext} from "../../../../lib/context";
import moment from "moment/moment";
import {Line} from "react-chartjs-2";
import React from "react";
import {
    CategoryScale,
    Chart as ChartJS, Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import {linChartOptions} from "../../../../config";
import {Box} from "@mui/material";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function ElectricCost()
{
    const {siteCharge} = useSiteContext();
    const data = {
        labels: siteCharge?.getsitechargecostandunitsbysiteid?.map((estimate: any) => {
            return moment(estimate.charge_period_start).format('MMMM YYYY')
        }),
        datasets: [
            {
                label: 'Electricity Cost',
                data: siteCharge?.getsitechargecostandunitsbysiteid?.map((estimate: any) => {
                    return estimate.cost
                }),
                fill: true,
                borderColor: '#4490AE',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                pointRadius: 2,
            }
        ]
    }
    return (
        <Box sx={{minHeight: '270px'}}>
            <Line options={linChartOptions('Months', 'Cost')} data={data}/>
        </Box>
    )
}