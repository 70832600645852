import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

export const VISITS_QUERY = gql`
  query {
    core_visit {
      id
      job_id
      comment
      start_datetime
      end_datetime
      core_visitstatusenum {
        status
        id
      }
      core_job {
        core_site {
          address
          id
          latitude
          longitude
          name
        }
        ext_job_number
        description
      }
    }
  }
`;

export const ADD_VISTS = gql`
  mutation (
    $ext_job_number: String
    $description: String
    $comment: String
    $site_id: bigint
    $status_id: bigint
    $personale_id: bigint
    $end_datetime: timestamptz
    $start_datetime: timestamptz
    $created_on: timestamptz
  ) {
    insert_core_visit(
      objects: {
        core_job: {
          data: {
            ext_job_number: $ext_job_number
            description: $description
            site_id: $site_id
            created_on: $created_on
            core_personnelassigneds: { data: { personale_id: $personale_id } }
          }
        }
        status_id: $status_id
        comment: $comment
        end_datetime: $end_datetime
        start_datetime: $start_datetime
      }
    ) {
      returning {
        end_datetime
        job_id
        start_datetime
        id
        core_job {
          created_on
          description
          ext_job_number
          id
          site_id
        }
      }
    }
  }
`;

export const useVisits = () => {
  const { data, error, loading } = useQuery(VISITS_QUERY);
  return { data, error, loading };
};
