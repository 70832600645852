import {Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography} from "@mui/material";
import React, {MouseEvent, useState} from "react";
import {StyledTableCell} from "./StyledTableCell";
import {ButtonBaseColor, ButtonBaseColorLight, TableBackgroundColor} from "../../config";
import {Settings} from "@mui/icons-material";
import {ShuriTablePaginationSettings} from "./ShuriTablePaginationSettings";

export interface IShuriPaginationProps {
    count: number;
    /**
     * The zero-based index of the current page.
     */
    page: number;
    /**
     * Callback fired when the page is changed.
     */
    onPageChange: (event: unknown, page: number) => void;

    /**
     * Callback fired when the page is changed.
     */
    onRowsPerPageChange?: any;

    /**
     * The number of rows per page.
     *
     * Set -1 to display all the rows.
     */
    rowsPerPage: number;
    /** Sets or retrieves the number columns in the table that the object should span. */
    colSpan: number;
}
export default function ShuriTablePagination({count, page, onPageChange, rowsPerPage, colSpan, onRowsPerPageChange}: IShuriPaginationProps) {
    const [settingsOpen, setSettingsOpen] = useState(false);
    const onNext = (event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | null) => {
        onPageChange(event,page + 1);
    };

    const onPrevious = (event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | null) => {
        onPageChange(event,page - 1);
    };

    const pages = Math.ceil(count/rowsPerPage);

    return (
        <>
            <StyledTableCell
                colSpan={colSpan}
                sx={{
                    backgroundColor: TableBackgroundColor,
                }}
            >
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'
                }}>
                    <Button
                        disabled={page === 0}
                        onClick={onPrevious}
                        sx={{
                            color: ButtonBaseColorLight,
                            borderColor: ButtonBaseColor
                        }}
                        variant="outlined"
                        size="large">
                        Previous
                    </Button>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <Typography>
                            Page {page + 1} of {pages}
                        </Typography>
                        <IconButton 
                            onClick={()=>setSettingsOpen(true)}
                            aria-label="settings" 
                            sx={{
                            color: ButtonBaseColorLight
                        }}>
                            <Settings />
                        </IconButton>
                    </Box>

                    <Button
                        disabled={page === (pages - 1)}
                        onClick={onNext}
                        sx={{
                            color: ButtonBaseColorLight,
                            borderColor: ButtonBaseColor
                        }}
                        variant="outlined"
                        size="large">
                        Next
                    </Button>
                </Box>
            </StyledTableCell>

            <Dialog onClose={()=>setSettingsOpen(false)} open={settingsOpen}>
                <DialogTitle>Pagination Settings</DialogTitle>
                <DialogContent>
                    <ShuriTablePaginationSettings
                        onPageChange={onPageChange}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        colSpan={colSpan}
                        count={count}
                        onRowsPerPageChange={onRowsPerPageChange}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}