import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
export const USERS_QUERY = gql`
  query {
    core_user {
      company_id
      core_company {
        name
      }
      core_role {
        title
      }
      last_name
      first_name
      id
      email
      is_active
      is_staff
    }
  }
`;

export const USER_GET_QUERY = gql`
query MyQuery($email: String) {
  core_user(where: {email: {_eq: $email}}) {
    id
    email
  }
}

`;

export const useUsers = () => {
  const { data, error, loading } = useQuery(USERS_QUERY);
  return { data, error, loading };
};

export const useGetUser = (email:any) => {
  const { data, error, loading } = useQuery(USER_GET_QUERY, {
    variables: { email },
  });
  return { data, error, loading };
};
