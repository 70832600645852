import {styled} from "@mui/material/styles";
import {FormLabel} from "@mui/material";
import {InputBaseColorLight} from "../../../config";

export const StyledFormLabel= styled(FormLabel)(({theme}) => ({
    color: InputBaseColorLight,
    fontFamily: 'Outfit',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '5px',

}));