import { useEffect, useState} from "react";
import { Button, FormControl, Grid } from "@mui/material";
import {
  StyledFormLabel,
  StyledFormTextField,
  StyledFormFieldError,
} from "../form";
import { useForm, useYupValidateForm } from "../../../lib/form/hooks";
import { FormEvent } from "react";
import * as Yup from "yup";
import { ICalloutsForm } from "./";
import { ShuriMuiFormSelect } from "../form/form-select/ShuriMuiFormSelect";
import {
  ISelectInput,
  ISite,
  useSiteSubData,
} from "../../../lib";

const calloutValidationSchema = Yup.object().shape({
  site_id: Yup.string().required("Site is required"),
  classification: Yup.string().required("Classification is required"),
  emergency_type: Yup.string().required("emergency Type is required"),
  installer_identifier: Yup.string().required("Company is required"),
});

export function AddCalloutsForm({
  callout,
  onFormSubmit,
  onFormErrors,
  has_submit = true,
  formRef,
  site_id,
}: ICalloutsForm) {
  const { inputs, handleChange } = useForm(callout);
  const { errors, validateField, validateForm, hasErrors } = useYupValidateForm(
    inputs,
    calloutValidationSchema
  );
  const { data: sitesData } = useSiteSubData();
  const [sites, setSites] = useState<ISelectInput[]>();


  useEffect(() => {
    if (sitesData) {
      setSites(
        sitesData?.core_site?.filter((site: ISite) => site?.core_sitesecuritycoverages?.[0]?.is_covered === true)?.map((site: ISite) => ({
          label: site.name,
          value: site.id,
        }))
      );
    }
  }, [sitesData]);


  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (await validateForm()) {
      return onFormSubmit(inputs);
    }
    return onFormErrors ? onFormErrors(true) : null;
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
        {!site_id && (
          <Grid item xs={12}>
            <FormControl color="secondary" size="small" fullWidth required>
              <StyledFormLabel>Select Site</StyledFormLabel>
              <ShuriMuiFormSelect
                placeholder="Select Site"
                options={sites ?? []}
                name="site_id"
                value={inputs.site_id ?? ""}
                handleChange={handleChange}
                handleBlur={() => validateField("site_id")}
                error={!!errors.site_id}
              />
              {errors.model_id && (
                <StyledFormFieldError>{errors.model_id}</StyledFormFieldError>
              )}
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl color="secondary" fullWidth required>
            <StyledFormLabel>Emergency Type</StyledFormLabel>
            <StyledFormTextField
              type="text"
              size="small"
              placeholder="Select Emergency Type"
              name="emergency_type"
              value={inputs.emergency_type ?? ""}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl color="secondary" size="small" fullWidth required>
            <StyledFormLabel>Classification</StyledFormLabel>
            <ShuriMuiFormSelect
              placeholder="Select Classification"
              options={[
                { label: "Real", value: "real" },
                { label: "Test", value: "test" },
              ]}
              name="classification"
              value={inputs.classification ?? ""}
              handleChange={handleChange}
              handleBlur={() => validateField("classification")}
              error={!!errors.classification}
            />
            {errors.model_id && (
              <StyledFormFieldError>{errors.model_id}</StyledFormFieldError>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl color="secondary" size="small" fullWidth required>
            <StyledFormLabel>Company</StyledFormLabel>
            <ShuriMuiFormSelect
              placeholder="Select Company"
              options={[{ label: "Raphta", value: 5 }]}
              name="installer_identifier"
              value={inputs.installer_identifier ?? ""}
              handleChange={handleChange}
              handleBlur={() => validateField("installer_identifier")}
              error={!!errors.installer_identifier}
            />
            {errors.model_id && (
              <StyledFormFieldError>{errors.model_id}</StyledFormFieldError>
            )}
          </FormControl>
        </Grid>
        {has_submit && (
          <Grid item xs={12}>
            <FormControl color="secondary" size="small" fullWidth required>
              <Button
                disabled={hasErrors}
                type="submit"
                color="info"
                variant="contained"
                size="large"
              >
                Save Changes
              </Button>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </form>
  );
}
