import {AccordionDetails, Box, IconButton, Typography} from "@mui/material";
import {SyntheticEvent, useEffect, useState} from "react";
import {EquipmentMetrics, RectifierAlarmMetrics, TelcomMetrics} from "./metrics";
import {ShuriStyledAccordion, ShuriStyledAccordionSummary} from "../accordion";
import {ExpandMore} from "@mui/icons-material";
import {IMetric, useSiteContext} from "../../../lib";

interface IMetricGrouped {
    device_type: string,
    created_on: Date | string,
    metrics: IMetric[]
}

export default function SiteMetrics() {
    const [expanded, setExpanded] = useState<string | false>();
    const {metrics, loadingMetrics} = useSiteContext();
    const [groupedData, setGroupedData] = useState<IMetricGrouped[]>([]);

    useEffect(() => {
        const groupData = () => {
            const groupedResult: { [key: string]: IMetricGrouped } = {};

            metrics.forEach(metric => {
                const {device_type, created_on} = metric;

                if (!groupedResult[device_type]) {
                    groupedResult[device_type] = {device_type, created_on, metrics: []};
                }

                groupedResult[device_type].metrics.push(metric);
            });

            setGroupedData(Object.values(groupedResult));
        };

        groupData();
    }, [metrics])


    const handleChange =
        (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (
        <>
            <ShuriStyledAccordion expanded={expanded === 'rectifier-alarms'} onChange={handleChange('rectifier-alarms')}>
                <ShuriStyledAccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="rectifier-alarms-content"
                    id="rectifier-alarms-header">
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}
                    >
                        <IconButton>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#fff" viewBox="0 0 256 256"><path d="M215.79,118.17a8,8,0,0,0-5-5.66L153.18,90.9l14.66-73.33a8,8,0,0,0-13.69-7l-112,120a8,8,0,0,0,3,13l57.63,21.61L88.16,238.43a8,8,0,0,0,13.69,7l112-120A8,8,0,0,0,215.79,118.17ZM109.37,214l10.47-52.38a8,8,0,0,0-5-9.06L62,132.71l84.62-90.66L136.16,94.43a8,8,0,0,0,5,9.06l52.8,19.8Z"></path></svg>
                        </IconButton>
                        <Typography>Alarms</Typography>
                    </Box>
                </ShuriStyledAccordionSummary>
                <AccordionDetails>
                    <RectifierAlarmMetrics/>
                </AccordionDetails>
            </ShuriStyledAccordion>

            <ShuriStyledAccordion expanded={expanded === 'telcoms'} onChange={handleChange('telcoms')}>
                <ShuriStyledAccordionSummary
                    expandIcon={<ExpandMore/>}
                    aria-controls="telcoms-content"
                    id="telcoms-header">
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center'
                        }}
                    >
                        <IconButton>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 20V10M12 20V4M6 20V14" stroke="white" strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                        </IconButton>
                        <Typography>Telcoms</Typography>
                    </Box>
                </ShuriStyledAccordionSummary>
                <AccordionDetails>
                    <TelcomMetrics/>
                </AccordionDetails>
            </ShuriStyledAccordion>

            {
                groupedData.map((metric, index) => (
                    <ShuriStyledAccordion expanded={expanded === metric.device_type}
                                          onChange={handleChange(metric.device_type)}
                                          key={`${metric.device_type}-${index}`}>
                        <ShuriStyledAccordionSummary
                            expandIcon={<ExpandMore/>}
                            aria-controls={`${metric.device_type}-content`}
                            id={`${metric.device_type}-header`}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'
                                }}
                            >
                                <IconButton>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_11922_30800)">
                                            <g clipPath="url(#clip1_11922_30800)">
                                                <path d="M8.3699 5.77832H6.396L13.2787 18.2925L16.2008 12.9788"
                                                      stroke="white" strokeWidth="2" strokeMiterlimit="10"
                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M10.0107 5.77832H20.1615L16.9918 11.5411" stroke="white"
                                                      strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                                <path d="M13.2788 5.77838V0.351562" stroke="white" strokeWidth="2"
                                                      strokeMiterlimit="10" strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                                <path d="M6.40625 18.2925H20.1511" stroke="white" strokeWidth="2"
                                                      strokeMiterlimit="10" strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                                <path d="M13.2788 23.6485V18.2217" stroke="white" strokeWidth="2"
                                                      strokeMiterlimit="10" strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                                <path d="M10.4434 18.311L7.97344 20.781H4.5105" stroke="white"
                                                      strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                            </g>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_11922_30800">
                                                <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                            <clipPath id="clip1_11922_30800">
                                                <rect width="24" height="24" fill="white"
                                                      transform="translate(0.335938)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>

                                </IconButton>
                                <Typography>{metric.device_type}</Typography>
                            </Box>
                        </ShuriStyledAccordionSummary>
                        <AccordionDetails>
                            <EquipmentMetrics
                                loading={loadingMetrics}
                                metrics={metric.metrics}
                                created_on={metric.created_on}
                                device_type={metric.device_type}/>
                        </AccordionDetails>
                    </ShuriStyledAccordion>
                ))
            }
        </>
    )
}