import {useState} from "react";
import {ShuriDialogAction} from "../../dialog/shuri-dialog-action";
import {Box, Button, DialogContentText, IconButton} from "@mui/material";
import {errorBase} from "../../../config";

interface IShuriFormCloseActionProps {
    label?: string;
    handleClick: () => void
}

export function ShuriFormCloseAction({label = 'Close', handleClick}: IShuriFormCloseActionProps) {
    const [open, setOpen] = useState(false);
    return (
        <ShuriDialogAction
            open={open}
            actionButton={
                <Button variant="outlined" color="inherit">
                    {label}
                </Button>
            }
            actionContent={<CloseActionBody/>}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            handleAction={()=>{
                handleClick();
                setOpen(false)
            }}
            confirmTitle={'Yes, Cancel'}
        />
    )
}

const CloseActionBody = () => {
    return (
        <>
            <DialogContentText id="alert-dialog-description-title" className="shuri-dialog-title">
                <IconButton sx={{
                    background: `${errorBase}50`,
                    '&:hover': {
                        background: `${errorBase}60`,
                    },
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={errorBase}
                         viewBox="0 0 256 256">
                        <path
                            d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm-8-80V80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,172Z"></path>
                    </svg>
                </IconButton>
            </DialogContentText>

            <Box className="shuri-dialog-description" id="alert-dialog-description">
                <DialogContentText id="alert-dialog-description-title" className="shuri-dialog-title">
                    Are you sure you want to cancel?
                </DialogContentText>

                <DialogContentText className="shuri-dialog-description" id="alert-dialog-description">
                    You will loose all the information you have captured so far.
                </DialogContentText>
            </Box>
        </>
    )
}