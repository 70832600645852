import { ShuriStyledDrawer } from "../../drawer";
import { useNotification, getAuthData, getClaims } from "../../../../lib";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import {
  BaseColorLight,
  ButtonBaseColor,
  ButtonBaseColorLight,
  InputBaseColorLight,
} from "../../../config";
import { Close } from "@mui/icons-material";
import { useState, useRef } from "react";
import { AddCalloutsForm } from "../../armed-response";
import { useParams } from "react-router-dom";
import { API } from "aws-amplify";

interface ICreateCalloutProps {
  onRefresh: () => void;
  site?: any;
  addOpen: boolean;
  setAddOpen: (state: boolean) => void;
}

const mapSiteInputs = (site?: any): any => {
  return {
    id: site?.type_id,
  };
};

export function CreateCallout({
  onRefresh,
  site,
  addOpen,
  setAddOpen,
}: ICreateCalloutProps) {
  const [hasErrors, setHasErrors] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const { notify } = useNotification();
  const { id } = useParams();
  const handleSubmitForm = async (values: any) => {
    /// pending implementation or removal
  };

  const handleSubmitting = async () => {
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      const values = Object.fromEntries(formData.entries());
      try {
        const { idToken } = await getAuthData();
        const claims= await getClaims();

        const token = idToken.jwtToken;
        const requestData = {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
          body: {
            ...(values.site_id ? {} : { site_id: id }),
            ...values,
            endpoint : claims.endpoint
          },
        };
        const response = await API.post(
          "shurikonektfeapi",
          "/aura-create-callout",
          requestData
        );
        if (response.statusCode === 200) {
          setAddOpen(false);
          notify({
            status: "success",
            open: true,
            message: "Callout created successfully!",
          });
          onRefresh();
        } else if (response.statusCode === 400) {
          notify({
            open: true,
            status: "error",
            message: `Error! ::: ${response.body.message}`,
          });
        } else {
          notify({
            open: true,
            status: "error",
            message: "Error! ::: Failed to create a callout!",
          });
        }
      } catch (error: any) {
        notify({
          open: true,
          status: "error",
          message: "Error! ::: Failed to create a callout!",
        });
      }
    }
  };

  return (
    <ShuriStyledDrawer
      anchor="right"
      open={addOpen}
      onClose={() => setAddOpen(false)}
    >
      <Box className="shuri-drawer-wrapper">
        <Box className="shuri-drawer-content">
          <Grid
            container
            spacing={2}
            sx={{
              paddingBottom: "30px",
            }}
          >
            <Grid item xs={10}>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "28px",
                  color: InputBaseColorLight,
                }}
              >
                Create Callout
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: BaseColorLight,
                }}
              >
                Fill the form below to create a new callout
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <IconButton
                aria-label="delete"
                size="large"
                color="secondary"
                onClick={() => setAddOpen(false)}
              >
                <Close fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>

          <AddCalloutsForm
            callout={mapSiteInputs(site)}
            onFormSubmit={handleSubmitForm}
            onFormErrors={(formHasErrors) => setHasErrors(formHasErrors)}
            has_submit={false}
            formRef={formRef}
            site_id={id}
          />
        </Box>
        <Box className="shuri-drawer-actions">
          <Button
            onClick={() => setAddOpen(false)}
            sx={{
              color: ButtonBaseColorLight,
              borderColor: ButtonBaseColor,
              textTransform: "capitalize",
            }}
            variant="outlined"
            size="large"
          >
            Cancel
          </Button>

          <Button
            sx={{
              textTransform: "capitalize",
            }}
            disabled={hasErrors}
            onClick={handleSubmitting}
            color="info"
            variant="contained"
            size="large"
          >
            Save Callout
          </Button>
        </Box>
      </Box>
    </ShuriStyledDrawer>
  );
}
