import {MouseEvent, useState} from "react";
import {IconButton, ListItemIcon, ListItemText, Menu} from "@mui/material";
import {Edit, MoreVert, Visibility} from "@mui/icons-material";
import {BaseColorLight} from "../../../config";
import {useNavigate} from "react-router-dom";
import {StyledMenuItem} from "../../action-menu";
import {ActivationControl, RemoveSiteAction, SiteCoverage} from "./site-actions";


interface ISiteActionProps {
    site: any;
    onRefresh?: () => void
}

export const SiteAction = ({site, onRefresh}: ISiteActionProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                sx={{
                    color: BaseColorLight
                }}
                aria-label={`more-${site.site_id}`}
                id={`site-action-${site.site_id}`}
                aria-controls={open ? `site-action-${site.site_id}` : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="false"
                onClick={handleClick}
            >
                <MoreVert/>
            </IconButton>

            <Menu
                id={`site-action-menu-${site.site_id}`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': `site-action-${site.site_id}`,
                    style: {
                        width: '20ch',
                    }
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <StyledMenuItem onClick={() => {
                    navigate(`/site/${site.id}`)
                    handleClose()
                }}>
                    <ListItemIcon>
                        <Visibility color="secondary"/>
                    </ListItemIcon>
                    <ListItemText>View Site</ListItemText>
                </StyledMenuItem>
                <StyledMenuItem
                    onClick={() => {
                        navigate(`/site/${site.id}/edit`)
                        handleClose()
                    }}>
                    <ListItemIcon>
                        <Edit color="secondary"/>
                    </ListItemIcon>
                    <ListItemText>Edit Site</ListItemText>
                </StyledMenuItem>
                <RemoveSiteAction onClose={handleClose} key={`site-remove-action-menu-${site.site_id}`} site={site}
                                  onRefreshSites={onRefresh}/>
                <ActivationControl onClose={handleClose} key={`site-activation-action-menu-${site.site_id}`} site={site}
                                   onRefreshSites={onRefresh}/>
                <SiteCoverage onClose={handleClose} key={`site-coverage-action-menu-${site.site_id}`} site={site}
                                   onRefreshSites={onRefresh}/>
            </Menu>
        </>
    )
}