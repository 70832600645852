import moment from "moment";

export const formatDate = (date: Date|string|undefined) => {
    if (!date) return;
    return moment(date).format('MMMM Do YYYY . h:mm:ss a')
};

export const formatDateNoTime = (date: Date|string|undefined) => {
    if (!date) return;
    return moment(date).format('MMMM Do YYYY')
};

export const formatDateToTimeZone = (date: Date, timeZone: string, options: Intl.DateTimeFormatOptions = {}) => {
    const timeZoneDate = new Date(date.toLocaleString('en-US', { timeZone }));
    return timeZoneDate.toLocaleDateString('en-US', options);
};

export function getCurrentDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}
export function getYearMonth() {
    const now = new Date();
    const year: number = now.getFullYear();
    const month: number = now.getMonth() + 1;
    return `${year}${month}`;
}
export function getPrevYearMonth() {
    const now = new Date();
    const year: number = now.getFullYear();
    const month: number = now.getMonth() ;
    return `${year}${month}`;
}

export function getFirstDateOfCurrentMonth() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = 1;
    return new Date(`${year}-${month}-${day}`).toISOString().slice(0, 10);
}

export function getBeginningDateTimeOfCurrentMonth() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = 1;
    const beginningOfMonth = new Date(`${year}-${month}-${day}T00:00:00`);
    return beginningOfMonth.toISOString();
}

export function daysUntil(targetDate: Date) {
    const timeDiff = new Date(targetDate).getTime() - new Date().getTime();
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
}

export function getDateAndTimeLast12Hours() {
    const now = new Date();
    const twelveHoursAgo = new Date(now.getTime() - 12 * 60 * 60 * 1000); // 12 hours ago from now
    const year = twelveHoursAgo.getFullYear();
    const month = String(twelveHoursAgo.getMonth() + 1).padStart(2, '0');
    const day = String(twelveHoursAgo.getDate()).padStart(2, '0');
    const hours = String(twelveHoursAgo.getHours()).padStart(2, '0');
    const minutes = String(twelveHoursAgo.getMinutes()).padStart(2, '0');
    const seconds = String(twelveHoursAgo.getSeconds()).padStart(2, '0');
    const fromDateAndTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    const toDateAndTime = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;
    return fromDateAndTime;
}

export function getTomorrowDate() {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(now.getDate() + 1);
    const year = tomorrow.getFullYear();
    const month = String(tomorrow.getMonth() + 1).padStart(2, '0');
    const day = String(tomorrow.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

export const getTimeFromDate = (date: Date | string | undefined): string | undefined => {
    if (!date) return;
    return moment(date).format('h:mm:ss');
  };