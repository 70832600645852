import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from "@mui/material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { CaretLeft, CaretRight, SignOut } from "@phosphor-icons/react";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import Shuri from "../../../assets/Shuri-White.png";
import { CollapseTextColor, SideBarBackgroundColor } from "../../config";
import { SideMenuItem, SideMenuItemProps } from "../side-menu-item";
import {GlobalSearchFilter} from "../global-search";
import { CollapsableMenuItem } from "./collapsable";

export interface ISideMenuProps {
    items: SideMenuItemProps[];
    isOpen: Boolean;
    onToggle: () => void;
    mobile:boolean|undefined;
}

export function SideMenu({ items, isOpen, onToggle}: ISideMenuProps) {
  const [username, setUsername] = useState("");

  const drawerWidth = 280;

  const onClickSearch = () => {
    if (!isOpen) {
      onToggle();
    }
  };

  const onGetUser = async () => {
    const user = await Auth.currentUserInfo();
    setUsername(user?.username);
  };

  useEffect(() => {
    onGetUser();
  }, []);

  const onLogout = async () => {
    await Auth.signOut();
  };

  return (
    <Drawer
      variant="permanent"
      PaperProps={{ elevation: 10 }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        background: SideBarBackgroundColor,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
          background: SideBarBackgroundColor
        },
      
      }}
    >
      
      <Box
        height={100}
        justifyItems="center"
        alignItems="center"
        display="flex"
        alignSelf="center"
      >
        <img
          alt="Shuri Logo"
          src={Shuri}
          style={{
            display: "flex",
            alignSelf: "center",
            marginTop: "20px",
            objectFit: "contain"
          }}
          height={isOpen ? 100 : 50}
          width={isOpen ? 100 : 50}
        />
      </Box>

      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: isOpen ? 230 : 50,
          borderRadius: "30px",
          marginLeft: "15px",
          marginTop: "30px",
          background: "rgba(55, 54, 55, 0.5)",
          marginBottom: "20px"
        }}
        onClick={onClickSearch}
      >


        {isOpen ? (
            <GlobalSearchFilter />
        ) :
            (
                <IconButton
                    type="button"
                    sx={{ p: "10px", color: "white" }}
                    aria-label="search"
                >
                    <SearchIcon />
                </IconButton>
            )}
      </Paper>

      <Box
        component="nav"
        style={{
          flex: 1,
          paddingRight: 10,
          backgroundColor: SideBarBackgroundColor
        }}
      >
        {/* {items.map((item) => (
          <SideMenuItem key={`menu-${item.label}`} {...item} />
        ))} */}
        {items.map((item)=>
          (
            item.children ===undefined?  <SideMenuItem key={`menu-${item.label}`} {...item} />
            :<CollapsableMenuItem key={`menu-${item.label}`} {...item} />
          )
        )}
      </Box>

      <Box
        onClick={onToggle}
        sx={{
          display: "flex",
          color: CollapseTextColor,
          marginLeft: "22px",
          marginBottom: "50px",
          cursor: "pointer"
        }}
      >
        {isOpen ? (
          <>
            <CaretLeft size={24} />
            <Typography sx={{ marginLeft: "22px" }}>Collapse</Typography>
          </>
        ) : (
          <CaretRight size={24} />
        )}
      </Box>

      <Divider
        color="white"
        sx={{
          width: "248px",
          height: "1px",
          textAlign: "center",
          marginLeft: "20px"
        }}
      />

      <List sx={{ width: "100%", maxWidth: 360, color: "white" }}>
        <ListItem alignItems="center">
          <ListItemAvatar>
            <Avatar alt="" src="" />
          </ListItemAvatar>
          <ListItemText
            primary={username}
            secondary={
              <>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="white"
                >
                  {username}
                </Typography>
              </>
            }
          />
          <Box sx={{ cursor: "pointer", color: "#FF632B" }} onClick={onLogout}>
            <SignOut size={25} />
          </Box>
        </ListItem>
      </List>

      <Box sx={{ height: 50, backgroundColor: "#19191A" }}></Box>
    </Drawer>
  );
}
