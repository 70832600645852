import {gql, useQuery} from "@apollo/client";

export const VISIT_STATUS_SUB_DATA_QUERY = gql`
    query VISIT_STATUS_SUB_DATA_QUERY {
        core_visitstatusenum {
            id
            status
        }
    }
`;

export const useVisitStatusesSubData = () => {
    const {data, error, loading, refetch} = useQuery(VISIT_STATUS_SUB_DATA_QUERY);
    return {data, error, loading, refetch};

};
