import React, {createContext, FC, ReactNode, useContext, useEffect, useState} from "react";
import {useEventLog} from "../../hooks";
import SkeletonLoader from "../../../ui/components/skeleton-loader";
import {Alert, AlertTitle} from "@mui/material";


interface IEventLogContext {
    eventlog: any;
}

interface IEventLogProvider {
    id: any;
    children: ReactNode;
}

const EventLogContext = createContext<IEventLogContext | null>(null);

export const EventLogContextProvider: FC<IEventLogProvider> = ({id, children}) => {
    const [eventlog, setEventlog] = useState<any>({});
    const {data, loading, error} = useEventLog(id);

    useEffect(() => {
        if (data) {
            const event_log = data.core_eventlog_by_pk;
            setEventlog(event_log);
        }
    }, [data]);

    if (loading) return (<SkeletonLoader/>)
    if (error) return (
        <Alert severity="error" variant="outlined">
            <AlertTitle>Error</AlertTitle>
            {error?.message + ' — ' ?? ''} <strong>An error occurred!</strong>
        </Alert>
    )

    return (
        <EventLogContext.Provider value={{eventlog}}>
            {children}
        </EventLogContext.Provider>
    )
}

export const useEventLogContext = () => {
    const context = useContext(EventLogContext);
    if (!context) {
        throw new Error("useEventLogContext must be used within a EventLogContextProvider");
    }
    return context;
};