import {FormEvent, useEffect, useRef, useState} from "react";
import { ICameraInput, initialCameraInput } from "../partials";
import { Add } from "@mui/icons-material";
import { Grid, Box, Button } from "@mui/material";
import { StyledFormRow } from "../../../form";
import { CreateSiteFormAction } from "../partials/CreateSiteFormAction";
import {AddCameraForm, ICameraFormRef} from "./AddCameraForm";
import {ADD_CAMERA_MUTATION, ICamera, ISite, UPDATE_CAMERA_MUTATION, useNotification} from "../../../../../lib";
import {useMutation} from "@apollo/client";
import { v4 as uuidv4 } from 'uuid';

interface IAddSiteCameras {
    cameras?: ICamera[];
    site?: ISite;
    refreshSite?: ()=> void
}

export const AddSiteCameras = ({cameras, refreshSite, site}: IAddSiteCameras)=> {
    const [cameraRows, setCameraRows] = useState<ICameraInput[]>([]);
    const cameraFormRefs = useRef<ICameraFormRef[] | null[]>([]);
    const [addCameraMutation, {error: createError}] = useMutation(ADD_CAMERA_MUTATION);
    const [updateCameraMutation, {error: updateError}] = useMutation(UPDATE_CAMERA_MUTATION);
    const {notify} = useNotification();


    useEffect(() => {
        if (createError) {
            notify({
                status: "error",
                open: true,
                message: 'An error occurred trying to create cameras, try again later!',
            });
        }
        if (updateError) {
            notify({
                status: "error",
                open: true,
                message: 'An error occurred trying to update cameras, try again later!',
            });
        }
    }, [createError, updateError])

    useEffect(()=>{
        if (cameras){
            setCameraRows(
                cameras.map(camera=>({
                    uid: camera.uid,
                    box_id: camera.box_id,
                    camera_name: camera.camera_name,
                    camera_id: camera.camera_id,
                    video_stream_name: camera.video_stream_name
                }))
            )
        }

    }, [cameras])

    const addCameraRow = () => {
        let box_id: number;
        if (site && site?.core_boxes && site?.core_boxes[0]) {
            box_id = site.core_boxes[0].id;
        }
        setCameraRows(prevRows => [...prevRows, {
            ...initialCameraInput,
            box_id,
            gen_key: new Date().toISOString()
        }]);
    };

    const removeCameraRow = (index: number) => {
        const updatedRows = [...cameraRows].filter((_, i) => i !== index);
        setCameraRows(updatedRows);
    }
    
    const handleCameraChange = (index: number, camera: ICameraInput) => {
        const updatedRows = [...cameraRows];
        updatedRows[index] = camera;
        setCameraRows(updatedRows);
    };

    const handleCancel = () => {
        const updatedRows = [...cameraRows].filter((camera) => camera.uid);
        setCameraRows(updatedRows);
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        /**
         * 1. Validate
         * 2. Handle sending data to graph query
         */

        // Validate each form and collect validation results
        if (!await handleFormsValidation()){
            const cameraInserts = cameraRows.map(camera => {
                if (!camera.uid){
                    camera.uid = uuidv4();
                }
                return {
                    ...camera,
                    gen_key: undefined
                }
            });

            await addCameraMutation({
                variables: {
                    objects: cameraInserts
                },
                onCompleted(createdCameraData) {
                    if (createdCameraData) {
                        if (refreshSite) {
                            refreshSite();
                        }
                        notify({
                            status: "success",
                            open: true,
                            message: "Cameras created successfully!",
                        });
                    }
                },
                onError(error) {
                    notify({
                        status: "error",
                        open: true,
                        message: 'An error occurred trying to create site cameras, try again later!',
                    });
                }
            })
        }
        else{
            notify({
                status: "error",
                open: true,
                message: 'Camera form validation error: Kindly provide valid fields before proceeding.',
            });
        }

    }

    const handleFormsValidation = async ()=> {
        const validationResults: boolean[] = [];
        await Promise.all(
            cameraRows.map(async (_, index) => {
                if (cameraFormRefs.current[index]) {
                    await cameraFormRefs.current[index]?.handleFormValidate();
                    validationResults[index] = cameraFormRefs.current[index]?.hasErrors as boolean;
                }
            })
        );
        return formsHasErrors(validationResults);
    }

    const formsHasErrors = (errorResults: boolean[]): boolean => {
        return errorResults?.some(hasError => hasError);
    };

    return (
        <form onSubmit={handleSubmit}>
        <StyledFormRow>
            <Grid container spacing={2} className="form-title-container">
                <Grid item xs={12} md={6}>
                    <Box className='form-row-title'>
                        Camera Configuration
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box className="form-row-action">
                        <Button disabled={cameraRows.length === 4} className="form-row-action-button" startIcon={<Add />}
                            onClick={addCameraRow}>
                            Add Camera
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            {
                cameraRows.map((camera, index) => (
                    <AddCameraForm
                        ref={ref => (cameraFormRefs.current[index] = ref)}
                        camera={camera}
                        onCameraChange={handleCameraChange}
                        key={`camera-form-${index}-${camera.gen_key}`}
                        index={index}
                        onRemove={removeCameraRow}
                    />
                ))
            }

            {
                cameraRows.length > 0 &&
                <CreateSiteFormAction
                    primaryLabel={"Save"}
                    secondaryLabel={"Clear"}
                    onSave={handleSubmit}
                    onCancel={() => handleCancel() }
                />
            }
        </StyledFormRow>
    </form>
    )
}