import React, {useEffect, useState} from "react";
import {BaseColorLight} from "../../../config";
import {isImage} from "../../../../lib/helpers";
import {Box} from "@mui/material";
import {ShuriImageDialog} from "../../image";

interface IShuriUploadPreviewsProps {
    previews: string[]
}

export function ShuriUploadPreviews({previews}: IShuriUploadPreviewsProps) {
    const [openImage, setOpenImage] = useState(false);
    const [selected, setSelected] = useState(0);
    if (previews.length === 0) {
        return <></>;
    }

    const handleOpen = (index: number) => {
        setOpenImage(true);
        setSelected(index);
    }

    return (
        <>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexWrap: 'wrap'
            }}>
                {
                    previews.map((filePreview, index) => {
                        if (filePreview && isImage(filePreview)) {
                            return (
                                <img
                                    onClick={()=>handleOpen(index)}
                                    key={`image-preview-${index}`}
                                    src={filePreview}
                                    alt="Preview"
                                    style={{
                                        cursor: "pointer",
                                        width: '60px',
                                        height: '60px',
                                        objectFit: 'cover',
                                        borderRadius: '4px',
                                        marginRight: '8px',
                                        marginBottom: '8px'
                                    }}
                                />
                            );
                        } else {
                            return <svg
                                style={{
                                    cursor: "pointer"
                                }}
                                key={`image-preview-${index}`}
                                xmlns="http://www.w3.org/2000/svg"
                                width="60"
                                height="60"
                                fill={BaseColorLight}
                                viewBox="0 0 256 256">
                                <path
                                    d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40v88a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48V216H176a8,8,0,0,0,0,16h24a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM108,128a52,52,0,0,0-48,32,36,36,0,0,0,0,72h48a52,52,0,0,0,0-104Zm0,88H60a20,20,0,0,1-3.81-39.64,8,8,0,0,0,16,.36,38,38,0,0,1,1.06-6.09,7.56,7.56,0,0,0,.27-1A36,36,0,1,1,108,216Z"></path>
                            </svg>;
                        }
                    })
                }
            </Box>

            <ShuriImageDialog
                selected={selected}
                images={previews}
                open={openImage}
                onClose={()=> setOpenImage(false)} />
        </>
    )
}


export const useFileUploadPreviews = (values: FileList | null) => {
    const [filePreviews, setFilePreviews] = useState<(string[])>([]);

    useEffect(() => {
        if (values) {
            readFilePreviews(values);
        }
    }, [values]);

    const readFilePreviews = (files: FileList | null) => {
        if (!files || files.length === 0) {
            setFilePreviews([]);
            return;
        }
        const readerPromises: Promise<string>[] = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();
            readerPromises.push(
                new Promise<string>((resolve) => {
                    reader.onload = () => {
                        resolve(reader.result as string);
                    };
                })
            );
            reader.readAsDataURL(file);
        }

        Promise.all(readerPromises).then((results) => {
            setFilePreviews(results);
        });
    }

    return {filePreviews, setFilePreviews, readFilePreviews};
}