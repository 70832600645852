import {FormControl, Grid} from "@mui/material";
import {ShuriMuiFormSelect, StyledFormLabel} from "../form";
import React from "react";
import {IShuriPaginationProps} from "./ShuriTablePagination";

export function ShuriTablePaginationSettings({count, page, onPageChange, rowsPerPage, onRowsPerPageChange }: IShuriPaginationProps) {
   const PAGE_OPTIONS = [
       {
           label: '10',
           value: 10,
       },
       {
           label: '20',
           value: 20,
       },
       {
           label: '50',
           value: 50,
       },
       {
           label: '100',
           value: 100,
       },
       {
           label: '150',
           value: 150,
       },
       {
           label: '200',
           value: 200,
       },
       {
           label: '250',
           value: 250,
       },
   ]

    const pageSum = Math.ceil(count/rowsPerPage);

    const PAGES = Array.from({ length: pageSum }, (_, index) => ({
        label: `${index + 1}`,
        value: index
    }));

    return (
        <Grid container spacing={2} sx={{marginBottom: '20px'}}>
            <Grid item xs={12}>
                <FormControl color="secondary" size="small" fullWidth required>
                    <StyledFormLabel>Page</StyledFormLabel>
                    <ShuriMuiFormSelect
                        placeholder='Select page'
                        options={PAGES}
                        value={page}
                        handleChange={(event)=>onPageChange(event, event.target.value as number)}
                        name='page'
                    />
                </FormControl>
            </Grid>
            {
                onRowsPerPageChange && (
                    <Grid item xs={12}>
                        <FormControl color="secondary" size="small" fullWidth required>
                            <StyledFormLabel>Items per page</StyledFormLabel>
                            <ShuriMuiFormSelect
                                placeholder='Select per page'
                                options={PAGE_OPTIONS}
                                value={rowsPerPage}
                                handleChange={onRowsPerPageChange}
                                name='rowsPerPage'
                            />
                        </FormControl>
                    </Grid>
                )
            }
        </Grid>
    )
}