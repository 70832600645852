export const endpoint = process.env.REACT_APP_API_URL;
export const googleApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
export const perPage = 5;

export const awsAccessKey = process.env.REACT_APP_AWS_ACCESS_KEY;
export const awsAccessSecret = process.env.REACT_APP_AWS_ACCESS_SECRET;

//others
export * from "./chart-config";
export * from "./metrics.config";
