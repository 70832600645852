import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

export const REQUESTS_ACCESS_QUERY = gql`
  query {
    core_requestaccess {
      id
      personale_id
      request_date
      core_visit {
        core_job {
          description
          ext_job_number
          id
        }
      }
    }
    core_site {
      address
      id
      latitude
      longitude
      name
      site_id
      core_sitestatus {
        site_status
      }
    }
  }
`;

export const useRequestsAccess = () => {
  const { data, error, loading } = useQuery(REQUESTS_ACCESS_QUERY);
  return { data, error, loading };
};
