import { Auth } from 'aws-amplify';


export const getAuthData = async ()=> {
    try {
        const data = await Auth.currentAuthenticatedUser({
            bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        return {
            ...data.signInUserSession,
        }
    }
    catch (e) {
        return null;
    }
}

export const getClaims = async ()=> {
    try {
        const { idToken } = await getAuthData();
        const claims = idToken.payload;
        return claims
    }
    catch (e) {
        return null;
    }
}