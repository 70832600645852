import { createTheme } from "@mui/material";

export const AppBackgroundColor = '#1f1f20';
export const AppDarkBackgroundColor = '#373637';
export const BaseColor = '#424242';
export const BaseDark = '#19191A';
export const BaseColorLight = '#9F9F9E';
export const TableBackgroundColor = '#2D2D2E';
export const NotificationCardColor:"rgba(253, 244, 244, 0.1)" = 'rgba(253, 244, 244, 0.1)'
export const primaryBlue = '#5BC0E9';
export const primaryDark = '#16303A';
export const successBase = '#6BC64D';
export const successDark = '';
export const errorBase = '#FF632B';
export const tableBorder = '#19191A';
export const TableCellBodyBackgroundColor = '#2D2D2E'
export const TableCellHeaderBackgroundColor = '#19191A'
export const ButtonBaseColorLight = '#D0D0D0';
export const ButtonBaseColor = '#515050';
export const InputBaseColor = '#7F7E7E';
export const InputBaseColorLight = '#F5F5F5';
export const HoverSideBarButtonColor = '#16303A'
export const HoverSideBarButtonTextColor = '#5BC0E9'
export const SideBarButtonTextColor = '#949493'
export const SideBarBackgroundColor = '#19191A'
export const CollapseTextColor = '#5BC0E9'
export const MetricsCardBackground = '#272729'
export const MetricsCardTitleColor = '#7F7E7E';
export const warningBase = '#FDB022';

export const AppTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: primaryBlue,
            dark: AppBackgroundColor
        },
        secondary: {
            main: BaseColorLight,
            dark: '#e4e4e9'
        },
        background: {
            default: AppBackgroundColor,
            paper: AppBackgroundColor
        },
        info: {
            main: primaryBlue,
            dark: primaryDark
        },
        success: {
            main: successBase
        },
        error: {
            main: errorBase
        },
        warning: {
            main: warningBase
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: AppBackgroundColor
                },
            }
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    backgroundColor: AppBackgroundColor
                },
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    backgroundColor: AppBackgroundColor,
                    fontSize: '14px'
                },
            }
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    backgroundColor:'#302f33',
                    boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
                    '@media (max-width: 780px)': {
                        width: '100%'
                    }
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 28,
                },
            },
        },
    },
});

declare module '@mui/material/styles' {
    export interface Theme {
        colors: {
            dark: string;
        };
    }
    // allow configuration using `createTheme`
    export interface ThemeOptions {
        colors?: {
            dark?: string;
        };
    }
}
