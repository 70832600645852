import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import {getFirstDateOfCurrentMonth} from "../../filters";

export const SITE_QUERY = gql`
    query SITE_INFO_QUERY($id: bigint = "28", $date: timestamptz = "2022-03-01") {
        core_site(where: {id: {_eq: $id}}) {
            id
            site_id
            name
            region_id
            site_type_id
            address
            effective_date
            created_on
            up_since
            is_enabled
            status_changed_at
            latitude
            longitude
            municipality
            core_siteregion {
                id
                region_code
                region_name
            }
            core_sitetype {
                id
                site_type
            }
            core_sitestatus {
                site_status
            }
            core_boxes {
                core_equipment {
                    id
                    site_id
                    serial_number
                    status_id
                    type_id
                    part_id
                    model_id
                    core_equipmenttype {
                        name
                        id
                    }
                    core_equipmentstatus {
                        equipment_status
                        id
                    }
                }
                site_id
                core_cameras {
                    video_stream_uri
                    box_id
                    audio_stream_uri
                    video_stream_name
                    camera_id
                }
                core_meters {
                    box_id
                    created_on
                    serial_number
                    
                }
            }
            core_disruptions_aggregate(where: {start_date: {_gte: $date}}) {
                aggregate {
                    count
                }
            }
            core_sitechargeestimates(order_by: {charge_period_end: desc}, limit: 1) {
                charge_period_end
                charge_period_start
                cost
                total_units
            }
            core_sitechargeestimates_aggregate {
                aggregate {
                    max {
                        charge_period_end
                    }
                }
                nodes {
                    charge_period_end
                    charge_period_start
                    cost
                    total_units
                }
            }
        }
        core_visit_aggregate(where: {core_job: {site_id: {_eq: $id}}}) {
            aggregate {
                count(columns: id)
            }
        }

        core_agreement(where: {site_id: {_eq: $id}}) {
            core_tariff {
                payment_type
            }
        }
    }
`;

export const SITE_CHILDREN_QUERY = gql`
    query SITE_INFO_QUERY($id: bigint) {
        core_site(where: {id: {_eq: $id}}) {
            core_boxes {
                core_boxstatuses {
                    status
                    id
                }
                core_cameras {
                    uid
                    audio_stream_uri
                    box_id
                    camera_id
                    video_stream_name
                    camera_name
                }
                core_meters {
                    id
                    box_id
                    created_on
                    serial_number
                }
                id
                site_id
                equipment_id
                upswift_id
                upswift_project
            }
            id
            latitude
            longitude
            name
            region_id
            site_id
            municipality
            is_enabled
            status_changed_at
            site_type_id
            site_status_id
            core_siteregion {
                id
                region_code
                region_name
            }
            core_sitetype {
                id
                site_type
            }
            core_equipments {
                id
                model_id
                serial_number
                site_id
                type_id
            }
            core_sitestatus {
                id
                site_status
            }
        }
    }
`;

export const ADD_SITE_MUTATION = gql`
    mutation ADD_SITE_MUTATION(
        $name: String
        $region_id: bigint
        $site_type_id: bigint
        $site_id: String
        $site_status_id: bigint
        $municipality: String
        $latitude: float8
        $longitude: float8
        $created_on: timestamptz
    ) {
        insert_core_site(
            objects: {
                name: $name
                region_id: $region_id
                site_type_id: $site_type_id
                site_id: $site_id
                site_status_id: $site_status_id
                municipality: $municipality
                latitude: $latitude
                longitude: $longitude
                created_on: $created_on
                is_enabled: false
            }
        ) {
            returning {
                id
                name
                region_id
                site_type_id
                site_id
                site_status_id
                municipality
                latitude
                longitude
            }
        }
    }
`;

export const UPDATE_SITE_MUTATION = gql`
    mutation UPDATE_SITE_MUTATION(
        $id: bigint!
        $site_id: String
        $name: String
        $region_id: bigint
        $site_type_id: bigint
        $site_status_id: bigint
        $municipality: String
        $latitude: float8
        $longitude: float8
        $created_on: timestamptz
    ) {
        update_core_site_by_pk(
            pk_columns: { id: $id }
            _set: {
                name: $name
                region_id: $region_id
                site_id: $site_id
                site_type_id: $site_type_id
                site_status_id: $site_status_id
                municipality: $municipality
                latitude: $latitude
                longitude: $longitude
                created_on: $created_on
            }
        ) {
            id
            name
            region_id
            site_type_id
            site_id
            site_status_id
            municipality
            latitude
            longitude
        }
    }
`;

export const CHANGE_SITE_STATUS_MUTATION = gql`
    mutation UPDATE_SITE_MUTATION(
        $id: bigint!
        $is_enabled: Boolean
        $status_changed_at: timestamptz
    ) {
        update_core_site_by_pk(
            pk_columns: { id: $id }
            _set: {
                is_enabled: $is_enabled
                status_changed_at: $status_changed_at
            }
        ) {
            id
            name
            region_id
            site_type_id
            site_id
            site_status_id
            municipality
            latitude
            longitude
            is_enabled
            status_changed_at
        }
    }
`;

export const DELETE_SITE_MUTATION = gql`
    mutation DELETE_SITE_MUTATION($id: bigint!) {
        delete_core_site(where: {id: {_eq: $id}}){
            returning {
                id
            }
        }
    }
`;

export const useSite = (id: any, date = "2022-02-01") => {
    const {data, error, loading} = useQuery(SITE_QUERY, {
        variables: {id, date},
        skip: !id,
    });
    return {data, error, loading};
}

export const useSiteChildren = (id?: string) => {
    const {data, error, loading, refetch} = useQuery(SITE_CHILDREN_QUERY, {
        variables: {id},
        skip: !id,
    });

    const handleRefetch = () => {
        refetch({id})
    }
    return {data, error, loading, handleRefetch};
}

