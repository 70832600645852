import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from "react";
import { useVisitStatusesSubData } from "../../hooks";
import useEmail from "../../hooks/email/email.hook";
import { useJobSubData } from "../../hooks/job/visit.sub.hook";
import { IJob, ISelectInput } from "../../interfaces";

type JobsContextType = {
  createVisitOpen: boolean;
  setCreateVisitOpen: (status: boolean) => void;
  visitStatus: ISelectInput[];
  jobsOptions: ISelectInput[];
  onVisitCreateOpen: (visitId?: number, jobId?: number) => void;
  visitJobId?: number | string;
  editVisitId?: number;
  setVisitJobId: (id: number | string) => void;
  onDeleteJob: (job: IJob) => void;
};

const JobsContext = createContext<JobsContextType | null>(null);

type JobsContextProviderProps = {
  children: ReactNode;
};

export const JobsContextProvider: React.FC<JobsContextProviderProps> = ({
  children
}) => {
  const [createVisitOpen, setCreateVisitOpen] = useState(false);
  const [visitJobId, setVisitJobId] = useState<number | string>();
  const [editVisitId, setEditVisitId] = useState<number>();
  const { data: visitStatusData } = useVisitStatusesSubData();
  const { data: jobsData } = useJobSubData();
  const [visitStatus, setVisitStatus] = useState<ISelectInput[]>([]);
  const [jobsOptions, setJobsOptions] = useState<ISelectInput[]>([]);

  useEffect(() => {
    if (visitStatusData) {
      setVisitStatus(
        visitStatusData?.core_visitstatusenum?.map((status: any) => ({
          label: status.status,
          value: status.id
        })) ?? []
      );
    }

    if (jobsData) {
      setJobsOptions(
        jobsData?.core_job?.map((job: IJob) => ({
          label: `${job.ext_job_number} - ${job.core_site.name}`,
          value: job.id
        })) ?? []
      );
    }
  }, [visitStatusData, jobsData]);

  useEffect(() => {
    if (!createVisitOpen) {
      setVisitJobId("");
      setEditVisitId(undefined);
    }
  }, [createVisitOpen]);

  const onVisitCreateOpen = (visitId?: number, jobId?: number | string) => {
    setCreateVisitOpen(true);
    setVisitJobId(jobId ?? "");
    setEditVisitId(visitId);
  };

  const { sendEmail } = useEmail();

  const onDeleteJob = async (job: IJob) => {
    try {
      await sendEmail("DELETE_JOB_EMAIL", {
        subject: "Assigned Job Details",
        toAddress: job.core_personnelassigneds[0].core_user.email,
        emailData: {
          name: job.core_personnelassigneds[0].core_user.first_name,
          jobId: job.id,
          jobDate: job.created_on,
          siteName: job.core_site.name
        }
      });
    } catch (error) {
      console.log("deleted", error);
    }
  };

  return (
    <JobsContext.Provider
      value={{
        createVisitOpen,
        setCreateVisitOpen,
        visitStatus,
        onVisitCreateOpen,
        visitJobId,
        editVisitId,
        jobsOptions,
        setVisitJobId,
        onDeleteJob
      }}
    >
      {children}
    </JobsContext.Provider>
  );
};

export const useJobsContext = () => {
  const context = useContext(JobsContext);
  if (!context) {
    throw new Error("useJobsContext must be used within a JobsContextProvider");
  }
  return context;
};
