import gql from "graphql-tag";

export const ADD_VISIT_MUTATION = gql`
    mutation (
        $comment: String
        $status_id: bigint
        $job_id: bigint
        $end_datetime: timestamptz
        $start_datetime: timestamptz
    ) {
        insert_core_visit(
            objects: {
                job_id: $job_id
                status_id: $status_id
                comment: $comment
                end_datetime: $end_datetime
                start_datetime: $start_datetime
            }
        ) {
            returning {
                id
                status_id
                job_id
                comment
                end_datetime
                start_datetime
                core_job {
                    created_on
                    description
                    ext_job_number
                    id
                    site_id
                }
            }
        }
    }
`;

export const UPDATE_VISIT_MUTATION = gql`
    mutation UPDATE_VISIT_MUTATION(
        $visit_id: bigint!
        $comment: String
        $status_id: bigint
        $end_datetime: timestamptz
        $start_datetime: timestamptz
    ) {
        update_core_visit_by_pk(
            pk_columns: { id: $visit_id }
            _set: {
                status_id: $status_id
                comment: $comment
                end_datetime: $end_datetime
                start_datetime: $start_datetime
            }
        ) {
            id
            status_id
            comment
            end_datetime
            start_datetime
        }

    }
`;
