import {useSiteContext} from "../../../../lib/context";
import {Box, Grid, ListItemIcon, ListItemText, Typography} from "@mui/material";
import {StyledDetailsContainer} from "./StyledDetailsContainer";
import {ShuriActionMenu, StyledMenuItem} from "../../action-menu";
import {Delete, Edit, Visibility} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";


export function SiteDetailsCameras()
{
    const navigate = useNavigate();
    let {id} = useParams();
    const {site: data} = useSiteContext();
    const cameras = data?.core_site[0]?.core_boxes[0]?.core_cameras;
    if (!cameras) return <></>
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Typography className="shuri-card-title">Cameras</Typography>
                </Grid>
            </Grid>

            <StyledDetailsContainer>
                {
                    cameras.map((camera: any)=>(
                        <Grid container spacing={2} key={camera.camera_id}>
                            <Grid xs={6} md={4} item className="details-tile">
                                <Box className="details-title">Camera ID</Box>
                                <Box className="details-value">{camera?.camera_id ?? "-"}</Box>
                            </Grid>
                            <Grid xs={6} md={4} item className="details-tile">
                                <Box className="details-title">Stream Name</Box>
                                <Box className="details-value">{camera?.video_stream_name ?? "-"}</Box>
                            </Grid>
                            <Grid xs={6} md={4} item className="details-action-tile">
                                <ShuriActionMenu id={`camera-action-${camera.camera_id}`} >
                                    {/*<StyledMenuItem onClick={()=>{*/}
                                    {/*    navigate(`/site/${id}`)*/}
                                    {/*}}>*/}
                                    {/*    <ListItemIcon>*/}
                                    {/*        <Visibility color="secondary" />*/}
                                    {/*    </ListItemIcon>*/}
                                    {/*    <ListItemText>View Camera</ListItemText>*/}
                                    {/*</StyledMenuItem>*/}
                                    <StyledMenuItem
                                        onClick={()=>{
                                            navigate(`/site/${id}/edit`)
                                        }}>
                                        <ListItemIcon>
                                            <Edit color="secondary" />
                                        </ListItemIcon>
                                        <ListItemText>Edit Camera</ListItemText>
                                    </StyledMenuItem>
                                    {/*<StyledMenuItem  onClick={()=>{}}>*/}
                                    {/*    <ListItemIcon>*/}
                                    {/*        <Delete color="secondary" />*/}
                                    {/*    </ListItemIcon>*/}
                                    {/*    <ListItemText>Delete Camera</ListItemText>*/}
                                    {/*</StyledMenuItem>*/}
                                </ShuriActionMenu>
                            </Grid>
                        </Grid>
                    ))
                }
            </StyledDetailsContainer>
        </>
    )
}