import {
    Button, DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import {ShuriStyledDialog, ShuriStyledDialogBackdrop} from "./shuri-styled-dialog";
import {BaseColor, ButtonBaseColor, ButtonBaseColorLight, errorBase} from "../../config";
import {ReactNode} from "react";
import {Box} from "@mui/system";

interface IShuriDialogActionProps {
    onClose: () => void;
    onOpen: () => void;
    handleAction?: () => void;
    open: boolean;
    actionContent: ReactNode;
    actionTitle?: ReactNode
    actionButton: ReactNode
    confirmTitle?: string
}

export function ShuriDialogAction({
                                      onOpen,
                                      onClose,
                                      open,
                                      actionContent,
                                      handleAction,
                                      actionTitle,
                                      actionButton,
                                      confirmTitle = 'Confirm'
                                  }: IShuriDialogActionProps) {
    return (
        <>
            <Box sx={{
                cursor: 'pointer'
            }} onClick={onOpen}>
                {actionButton}
            </Box>

            <ShuriStyledDialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                BackdropComponent={ShuriStyledDialogBackdrop}
            >
                <DialogTitle id="alert-dialog-title">
                    {actionTitle}
                </DialogTitle>

                <DialogContent>
                    {actionContent}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAction ?? onClose}
                            variant="contained"
                            autoFocus
                            size="large"
                            sx={{
                                color: BaseColor,
                                borderColor: errorBase,
                                backgroundColor: errorBase,
                                textTransform: 'capitalize',
                                '&:hover': {
                                    background: `${errorBase}95`,
                                },
                            }}
                    >
                        {confirmTitle}
                    </Button>

                    <Button onClick={onClose}
                            variant="outlined"
                            size="large"
                            sx={{
                                color: ButtonBaseColorLight,
                                borderColor: ButtonBaseColor,
                                textTransform: 'capitalize',
                                '&:hover': {
                                    borderColor: `${ButtonBaseColor}95`,
                                },
                            }}
                    >
                        Go Back
                    </Button>
                </DialogActions>
            </ShuriStyledDialog>
        </>
    )
}