import {StyledPageCard} from "../../page";
import {IShuriTab, ShuriCompactTabs} from "../../tab";
import ElectricCost from "./electric-cost";
import {ElectricUnits} from "./electric-units";
import SiteScheduledVisits from "./site-scheduled-visits";
import SiteUnplannedOutages from "./site-unplanned-outages";

export default function SiteActivities()
{
    const tabs: IShuriTab[] = [
        {
            title: 'Electric Cost',
            component: <ElectricCost />
        },
        {
            title: 'Electric units',
            component: <ElectricUnits  />
        },
        {
            title: 'Scheduled visits',
            component: <SiteScheduledVisits />
        },
        {
            title: 'Unplanned Outages',
            component: <SiteUnplannedOutages />
        },
    ]
    return (
        <StyledPageCard>
            <ShuriCompactTabs
                tabs={tabs}
                name="shuri-site-activities"/>
        </StyledPageCard>
    )
}