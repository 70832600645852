import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

export const INVENTORY_COMPONENTS_QUERY = gql`
  query {
    core_equipmenttype {
      name
      id
    }
    core_equipmentimage {
      image
      id
    }
    core_equipmentcondition {
      id
      condtion
    }
    core_equipmentstatus {
      equipment_status
      id
    }
    core_equipmentpart {
      equipment_part
      id
      model_id
    }
    core_equipmentmodel {
      id
      model_number
    }
    core_equipmentmake {
      id
      equipment_make
    }
    core_site {
      id
      name
      site_id
    }
  }
`;

export const ADD_MODEL = gql`
  mutation ($equipment_model: String, $model_number: String, $make_id: bigint) {
    insert_core_equipmentmodel(
      objects: {
        model_number: $model_number
        make_id: $make_id
        equipment_model: $equipment_model
      }
    ) {
      returning {
        make_id
        model_number
        id
        equipment_model
      }
    }
  }
`;

export const ADD_STATUS = gql`
  mutation ($equipment_status: String) {
    insert_core_equipmentstatus(
      objects: { equipment_status: $equipment_status }
    ) {
      returning {
        id
        equipment_status
      }
    }
  }
`;

export const ADD_MAKE = gql`
  mutation ($equipment_make: String) {
    insert_core_equipmentmake(objects: { equipment_make: $equipment_make }) {
      returning {
        equipment_make
      }
    }
  }
`;

export const ADD_PART = gql`
  mutation ($equipment_part: String, $model_id: bigint) {
    insert_core_equipmentpart(
      objects: { model_id: $model_id, equipment_part: $equipment_part }
    ) {
      returning {
        model_id
        id
        equipment_part
      }
    }
  }
`;

export const ADD_CONDITION = gql`
  mutation ($condtion: String) {
    insert_core_equipmentcondition(objects: { condtion: $condtion }) {
      returning {
        id
        condtion
      }
    }
  }
`;

export const ADD_TYPE = gql`
  mutation ($name: String) {
    insert_core_equipmenttype(objects: { name: $name }) {
      returning {
        name
        id
      }
    }
  }
`;

export const useInventoryComponents = () => {
  const { data, error, loading, refetch } = useQuery(
    INVENTORY_COMPONENTS_QUERY
  );
  return { data, error, loading, refetch };
};
