import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

export const SITES_COVERAGE_QUERY = gql`
  query MyQuery($id: bigint!) {
    core_sitesecuritycoverage(where: { site_id: { _eq: $id } }) {
      created_on
      id
      is_covered
      site_id
    }
  }
`;

export const useGetSecurityCoverage = (id: number) => {
  const { data, error, loading, refetch } = useQuery(SITES_COVERAGE_QUERY, {
    variables: {
      id,
    },
  });
  return { data, error, loading };
};
