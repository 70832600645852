import {StyledTableRow} from "./StyledTableRow";
import {StyledTableCell} from "./StyledTableCell";
import {ShuriSpinnerText} from "../loading";
import {Alert, AlertTitle, Typography} from "@mui/material";
import {ReactNode} from "react";

interface IShuriTableRowStatesProps {
    loading?: boolean;
    error?: any;
    data: any[];
    colSpan: number;
    emptyMessage?: string;
    loadingMessage?: string;
    loadingTextSx?: object
    errorMessage?: string;
    children?: ReactNode;
}

export function ShuriTableRowStates({
                                        loading = undefined,
                                        error,
                                        data,
                                        colSpan = 5,
                                        emptyMessage = "No data to display at this time",
                                        loadingMessage,
                                        loadingTextSx,
                                        errorMessage = "Could not load data ",
                                        children
                                    }: IShuriTableRowStatesProps) {
    if (loading) {
        return (
            <StyledTableRow>
                <StyledTableCell colSpan={colSpan}>
                    <ShuriSpinnerText
                        loadingText={loadingMessage}
                        loadingTextSx={loadingTextSx}
                    />
                </StyledTableCell>
            </StyledTableRow>
        )
    }
    ;

    if (error) {
        return (
            <StyledTableRow>
                <StyledTableCell colSpan={colSpan}>
                    <Alert severity="error" variant="outlined">
                        <AlertTitle>Error</AlertTitle>
                        {errorMessage} — <strong>an error occurred!</strong>
                    </Alert>
                </StyledTableCell>
            </StyledTableRow>
        )
    }
    ;

    if (data?.length === 0) {
        return (
            <StyledTableRow>
                <StyledTableCell colSpan={colSpan}>
                    <Typography>{emptyMessage}</Typography>
                </StyledTableCell>
            </StyledTableRow>
        )
    }

    return (
        <>
            {children}
        </>
    )
}