import {Button, Card, Box, Typography} from "@mui/material";
import {MetricsCardBackground, MetricsCardTitleColor} from "../../../config";
import {useNavigate} from "react-router-dom";

interface CounterTileProps {
    /**
     * Usage tile title
     */
    title: string,

    /**
     * Usage tile amount
     */
    value: number,

    /**
     * Usage tile unit of measure e.g. ZAR, kWh, etc
     */
    items: { label: string, value: number, color: string, icon: JSX.Element, path?: string }[],

    dark?: boolean
}

export function CounterTile(props: CounterTileProps) {
    const navigate = useNavigate();
    return (
        <Button sx={{padding: 0, width: '100%', borderRadius: '8px'}}>
            <Card sx={{
                minWidth: 220,
                borderRadius: '8px',
                backgroundColor: MetricsCardBackground,
                '@media (min-width: 1369px)': {width: '100%'}
            }}>
                <Box sx={{margin: 0, padding: 1, paddingTop: 0}}>
                    <Typography sx={{
                        fontSize: 14,
                        textAlign: 'left',
                        marginTop: '20px',
                        paddingTop: 0,
                        paddingLeft: '10px',
                        marginBottom: '10px',
                        color: MetricsCardTitleColor
                    }}>
                        {props.title}
                    </Typography>
                    <Box sx={{color: '#7F7E7E'}}>

                    </Box>
                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-start', flexDirection: 'column'}}>
                        {
                            props.items.map(item => {
                                return <Box
                                    onClick={()=>navigate(item.path ?? '/')}
                                    key={`count-item-${item.label}`}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: '20px',
                                        cursor: 'pointer',
                                        transition: 'all 0.2s ease',
                                        '&:hover': {
                                            paddingLeft: '2px'
                                        }
                                    }}>
                                    {item.icon}
                                    <Typography sx={{
                                        fontSize: '30px',
                                        fontWeight: 600,
                                        color: item.color,
                                        marginLeft: '20px',
                                        marginRight: '20px'
                                    }} gutterBottom>
                                        {item.value}
                                    </Typography>
                                    <Typography sx={{
                                        fontWeight: 500,
                                        display: 'flex',
                                        fontSize: 14,
                                        color: item.color,
                                        paddingRight: 3,
                                        alignItems: 'flex-start'
                                    }} gutterBottom>
                                        {item.label}
                                    </Typography>
                                </Box>;
                            })
                        }
                    </Box>
                </Box>
            </Card>
        </Button>
    );
}
