import { ShuriStyledDrawer } from "../../../../components/drawer";
import { useNotification, getAuthData, getClaims } from "../../../../../lib";
import { Avatar, Box, Button, Chip, Grid, IconButton, ListItem, ListItemText, Typography } from "@mui/material";
import {
  BaseColorLight,
  ButtonBaseColor,
  ButtonBaseColorLight,
  InputBaseColorLight,
} from "../../../../config";
import { Close } from "@mui/icons-material";
import { useState, useRef, useEffect } from "react";
import CreateRectiferAlarmForm from "./addAlarmPrioryForm";
import { useParams } from "react-router-dom";
import { API } from "aws-amplify";
import { useGetAlarmPriorities } from "../../../../../lib/hooks/notifications/notification.hooks";
import { ShuriList } from "../../../../components/page/page-app-bar/notifications";
import { StyledFormTextField } from "../../../../components";

interface ICreateAlarmPriority {
  site?: any;
  addOpen: boolean;
  setAddOpen: (state: boolean) => void;
  changeConfig:(alarm:any)=>void;
}



export function ListPriorities({
  site,
  addOpen,
  setAddOpen,
  changeConfig
}: ICreateAlarmPriority) {
  const {error,loading,data} = useGetAlarmPriorities()
  const [alarms,setAlarms] = useState<any[]>([])
 const renderColor=(status:any)=>{
    switch(status?.toLowerCase()){
        case 'high':
            return 'error';
        case 'medium':
            return 'success'
        case 'low':
            return  "warning"
    }
 }
useEffect(()=>{
    if(data){
    setAlarms([...data?.core_alarm])
    }
},[data])
const handleOnSearch =(value:any)=>{
    if(value.length >=2){
        let searchValue = value.toLowerCase()
        const filteredAlarms: any[] = alarms.filter(alarm => alarm.alarm_name.toLowerCase().includes(searchValue));
        setAlarms([...filteredAlarms])
    }
    else if(value.length <=0){
        setAlarms([...data?.core_alarm])
    }
}
if(error ||loading){
    return <span>Loading...</span>
}

  return (
    <ShuriStyledDrawer
      anchor="right"
      open={addOpen}
      onClose={() => setAddOpen(false)}
    >
      <Box className="shuri-drawer-wrapper">
        <Box className="shuri-drawer-content">
          <Grid
            container
            spacing={2}
            sx={{
              paddingBottom: "30px",
            }}
          >
            <Grid item xs={10}>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "28px",
                  color: InputBaseColorLight,
                }}
              >
                Rectifier Alarms
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: BaseColorLight,
                }}
              >
                List of rectifer alarm priority for notifications
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <IconButton
                aria-label="delete"
                size="large"
                color="secondary"
                onClick={() => setAddOpen(false)}
              >
                <Close fontSize="inherit" />
              </IconButton>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <StyledFormTextField placeholder="search"  onChange={e=>handleOnSearch(e.currentTarget.value)}/>
            </Grid>
          </Grid>
          <ShuriList>
            {alarms.map(
              (item: any, index: number) => (
                <ListItem

                  secondaryAction={
                    <Chip
                    sx={{width:100}}
                      color={renderColor(item?.priority_override?item?.priority_override:item?.priority)}
                      onClick={()=>changeConfig(item)}
                      avatar={
                        <Avatar>{item?.priority?.substring(0, 1)}</Avatar>
                      }
                      label={item?.priority_override?item?.priority_override:item?.priority}
                    ></Chip>
                  }
                >
                  <ListItemText primary={item?.alarm_name} />
                </ListItem>
              )
            )}
          </ShuriList>
        </Box>
        <Box className="shuri-drawer-actions">
          <Button
            onClick={() => setAddOpen(false)}
            sx={{
              color: ButtonBaseColorLight,
              borderColor: ButtonBaseColor,
              textTransform: "capitalize",
            }}
            variant="outlined"
            size="large"
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </ShuriStyledDrawer>
  );
}
