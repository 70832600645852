import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Shuri from "../../../assets/Shuri-White.png";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {  SideMenu } from "../../components";
import { Outlet } from "react-router-dom";
import { AppBackgroundColor } from "../../config";


const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const defaultTheme = createTheme();

export default function MobileLayout(props: { menuItems: any[] }) {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        {open && (
          <SideMenu
            isOpen={open}
            onToggle={() => setOpen((prev) => !prev)}
            items={props.menuItems}
            mobile={true}
          />
        )}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Box flex={1} sx={{ backgroundColor: AppBackgroundColor }}>
            <AppBar
              position="absolute"
              open={open}
              sx={{ backgroundColor: AppBackgroundColor }}
            >
              <Toolbar
                sx={{
                  pr: "24px",
                  justifyContent:'space-between'
                }}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer}
                  sx={{
                    marginRight: "36px",
                  }}
                >
                  <MenuIcon />
                </IconButton>
               {open === false&& <img
                  alt="Shuri Logo"
                  src={Shuri}
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    objectFit: "contain",
                  }}
                />}
                <IconButton color="inherit" sx={{ float:'right',padding:'0%',...(open && { display: "none" }),}} >
                  <Badge badgeContent={0} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </Toolbar>
            </AppBar>
            <Container maxWidth="lg" sx={{ mt: 5, mb: 4 }}>

            <Toolbar sx={{ height: "5vh" }} />
              <Outlet />
            </Container>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
