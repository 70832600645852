import {ICameraStream, useCameraStreamsContext} from "../../../lib";
import {ShuriStyledDrawer} from "../drawer";
import {Box, Button, Grid, IconButton, Typography} from "@mui/material";
import {BaseColorLight, ButtonBaseColor, ButtonBaseColorLight, InputBaseColorLight} from "../../config";
import {Close} from "@mui/icons-material";
import * as React from "react";
import {AddCameraForm} from "./add-camera-form";
import {Core_Camera, Core_Site} from "../../../generated/graphql";
import {useEffect, useState} from "react";

export function AddCameraStream() {
    const {addOpen, toggleOpenForm, handleSelectCameraStream, streams} = useCameraStreamsContext();
    const [selected, setSelected] = useState<ICameraStream[]>(streams);

    useEffect(()=>{
        if (streams){
            setSelected(streams)
        }
    }, [streams])

    const handleAddCamera = (
        core_camera: Core_Camera,
        core_site: Core_Site,
        checked: boolean
    ) => {
        const stream = {
            core_camera,
            core_site
        }

        if (checked) {
            setSelected((prevSelected) => [...prevSelected, stream]);
        } else {
            setSelected((prevSelected) =>
                prevSelected.filter((c) => c.core_camera.camera_id !== core_camera.camera_id)
            );
        }
    };

    const handleSubmitCameras = () => {
        handleSelectCameraStream(selected);
        toggleOpenForm(false)
    }

    return (
        <ShuriStyledDrawer
            anchor="right"
            open={addOpen}
            onClose={()=>toggleOpenForm(false)}
        >
            <Box className="shuri-drawer-wrapper">
                <Box className="shuri-drawer-content">
                    <Grid container spacing={2} sx={{
                        paddingBottom: '30px'
                    }}>
                        <Grid item xs={10}>
                            <Typography sx={{
                                fontFamily: 'Outfit',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '18px',
                                lineHeight: '28px',
                                color: InputBaseColorLight
                            }}>Select Camera Stream</Typography>
                            <Typography sx={{
                                fontFamily: 'Outfit',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: BaseColorLight
                            }}>Select streams from the available sites below</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                        }}>
                            <IconButton aria-label="delete" size="large" color="secondary" onClick={()=> toggleOpenForm(false)}>
                                <Close fontSize="inherit"/>
                            </IconButton>
                        </Grid>
                    </Grid>

                    <AddCameraForm
                        selected={selected}
                        onCameraAdd={handleAddCamera}
                    />

                </Box>

                <Box className="shuri-drawer-actions">
                    <Button
                        onClick={()=> toggleOpenForm(false)}
                        sx={{
                            color: ButtonBaseColorLight,
                            borderColor: ButtonBaseColor,
                            textTransform: 'capitalize'
                        }}
                        variant="outlined"
                        size="large">
                        Cancel
                    </Button>

                    <Button
                        disabled={selected.length === 0 && streams.length === 0}
                        sx={{
                            textTransform: 'capitalize'
                        }}
                        onClick={handleSubmitCameras}
                        color="info"
                        variant="contained"
                        size="large">
                        Save Cameras
                    </Button>
                </Box>
            </Box>
        </ShuriStyledDrawer>
    )
}