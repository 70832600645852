import {
    ApolloClient,
    ApolloLink,
    InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/link-error";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import {getAuthData} from "./authData";

function createClient({ headers, initialState, hasuraEndpoint}: any) {

     const authLink = setContext(async (_, { headers }) => {
         try {
             const { idToken} = await getAuthData();
             const token = idToken.jwtToken;
             return {
                 headers: {
                     ...headers,
                     Authorization: token ? `Bearer ${token}` : '',
                 },
             };
         } catch (error) {
             console.error(error);
             return { headers };
         }
     });
    return new ApolloClient({
        link: ApolloLink.from([
            authLink,
            onError(({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    graphQLErrors.forEach(({ message }) => {
                        if (message === 'Could not verify JWT: JWTExpired') {
                            window.location.href ='/'
                        }
                    });
                }
    
                if (graphQLErrors)
                    graphQLErrors.forEach(({ message, locations, path }) =>
                        console.log(
                            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                        )
                    );
                if (networkError)
                    console.log(
                        `[Network error]: ${networkError}. Backend is unreachable. Is it running?`
                    );
            }),
            createUploadLink({
                uri: hasuraEndpoint,
                fetchOptions: {
                    credentials: "include",
                }
            })
        ]),
        cache: new InMemoryCache().restore(initialState || {}),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
            },
            query: {
                fetchPolicy: 'network-only',
            },
            mutate: {
                errorPolicy: 'all',
            },
        },
    });
}

export default createClient;