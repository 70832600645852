import gql from "graphql-tag";
import {getFirstDateOfCurrentMonth} from "../../filters";
import {perPage} from "../../../config";
import {useQuery} from "@apollo/client";

const SITE_INVENTORIES_FIELDS = `
            id,
            serial_number,
            type_id,
            model_id,
            site_id,
            status_id,
            condition_id,
            part_id,
            serial_number,
            equipment_name,
            core_equipmenttype {
                name
                id
            }
            core_equipmentpart {
                equipment_part
                id
            }
            core_equipmentmodel {
                model_number
                id
                equipment_model
            }
            core_equipmentstatus {
                equipment_status
                id
            }
            core_equipmentcondition {
                condition
            }
            core_equipmentimages{
                id
                image
                created_on
                equipment_id
              }
`;

export const SITES_INVENTORY_QUERY = gql`
    query SITES_equipments($site_id: bigint, $limit: Int, $offset: Int) {
        core_equipment(where: {site_id: {_eq: $site_id}} limit: $limit, offset: $offset) {
            ${SITE_INVENTORIES_FIELDS}
        }
        core_equipment_aggregate(where: {site_id: {_eq: $site_id}} ) {
            aggregate {
                count
            }
        }
    }
`;

export const ALL_SITES_INVENTORY_QUERY = gql`
    query SITES_INVENTORIES($site_id: bigint) {
        core_equipment(where: {site_id: {_eq: $site_id}}) {
            ${SITE_INVENTORIES_FIELDS}
        }
    }
`;


export const useSiteInventory = (site_id: any, date = getFirstDateOfCurrentMonth(), page = 1, limit = perPage) => {
    const offset = page * limit;
    const {data, error, loading, refetch} = useQuery(SITES_INVENTORY_QUERY, {
        variables: {site_id, offset, limit},
    });

    const handleRefetch = () => {
        refetch({
            site_id, offset, limit
        })
    }
    return {data, error, loading, handleRefetch};
}

export const useAllSiteInventories = (site_id: any) => {
    const {data, error, loading, refetch} = useQuery(ALL_SITES_INVENTORY_QUERY, {
        variables: {site_id},
        skip: !site_id
    });
    const handleRefetch = () => {
        refetch({
            site_id
        })
    }
    return {data, error, loading, handleRefetch};
}