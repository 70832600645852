import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { ShuriTabs } from "../../../../components/tab";
import { useGetDashboard } from "../../../../../lib";
import ExpandDashboardPopUp from "./ExpandedModal";
import { ShuriSpinnerText } from "../../../../components";

/**
 * Renders dashbaord widgets in a tab view
 * @param props takes no pros
 * @returns
 */
export default function DashboardTabs(props: any) {
  const { id } = useParams();
  const [components, setComponents] = useState<any[]>([]);
  const { data } = useGetDashboard(id);

  useEffect(() => {
    let tabs = [] as any[];
    const components = data?.core_dashboard;
    if (components) {
      components.forEach((element: any) => {
        let tab = {
          title: element.widget_name,
          component: <WidgetTab url={element.url} />,
        };
        tabs.push(tab);
      });
      setComponents([...tabs]);
    }
    return () => {
      setComponents([]);
    };
  }, [id, data]);

  return (
    <Box>
      <ShuriTabs name="Dashboard" tabs={components} />
    </Box>
  );
}

const WidgetTab = (props: { url: any }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState(false);
  const [isLoaded, setIsloaded]  = useState(false);
  const sendHeight = () => {
    let height = document.body.scrollHeight;
    height = height / 2 + 100;
    console.log(height);
    return `${height}px`;
  };
  useEffect(()=>{
    //allow equicksight to load widget before showing the user 
    setTimeout(() => {
      setIsloaded(true)
    }, 2000);
  })
  return (
    <>
      <Box
        component={"div"}
        sx={{
          width: "100%",
          alignContent: "center",
          position: "relative",
          height: { xs: '50vh', sm: 400, md: 200, lg: '60vh', xl: '60vh' },
          padding: 1,
        }}
      >
        {!isLoaded&&<ShuriSpinnerText/>}
        <Box
          component={"iframe"}
          width={"100%"}
          height={"100%"}
          sx={{
            height: "100%",
            border: "none",
            display: `${isLoaded?'"block"':'none'}`,
            position: "static",
          }}
          src={`${props.url}`}
        ></Box>
        <Box
          onClick={() => {
            setIsOpen(true);
            setUrl(props.url);
          }}
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            zIndex: 2,
            color: "grey",
            marginLeft: 10,
            width: 200,
            cursor: "pointer",
            height: 40,
            padding: 10,
          }}
        >
          {/* this is to disable redirect icon  do not remove*/}.
        </Box>
      </Box>
      <ExpandDashboardPopUp
        url={url}
        isVisible={isOpen}
        handleClose={() => setIsOpen(false)}
      />
    </>
  );
};
