import {ISite} from "../../../lib/interfaces";
import {Box, Grid, Typography} from "@mui/material";
import {BaseColorLight} from "../../config";
import CustomTower from "../../../assets/CustomTower";
import {siteStatusColor} from "../../../lib/helpers";

interface ISiteTitleCardProps {
    site: ISite
}

export function SiteTitleCard({site}: ISiteTitleCardProps) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={1} sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}>
                <CustomTower color={
                    siteStatusColor(site?.core_sitestatus?.site_status)
                }/>
            </Grid>
            <Grid item xs={11}>
                <Box sx={{
                    paddingLeft: '15px',
                    flex: 1
                }}>
                    <Typography variant="h1" component="h2" sx={{
                        fontFamily: 'Outfit',
                        fontWeight: '500',
                        fontSize: '24px',
                        lineHeight: '32px',
                        color: 'white',
                    }}>
                        {site?.name} - {site?.site_id}
                    </Typography>
                    <Typography sx={{
                        fontWeight: '500',
                        fontSize: '18px',
                        lineHeight: '28px',
                        color: BaseColorLight,
                        paddingTop: '4px'
                    }}>
                        {site?.municipality}
                    </Typography>
                </Box>
            </Grid>

        </Grid>
    )
}