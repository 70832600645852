import { Box, Grid, Typography } from "@mui/material";
import { StyledDetailsContainer } from "../site-details/StyledDetailsContainer";
import { useSiteContext, calloutClassificationColor } from "../../../../lib";
export function PreviosCallouts() {
  const { callouts } = useSiteContext();
  if (!callouts) return <></>;
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography className="shuri-card-title">Previous Callouts</Typography>
        </Grid>
      </Grid>

      <StyledDetailsContainer>
        {callouts?.core_securitycallouts
          ?.filter((callout: any) => callout.closed_on !== null)
          .map((callout: any, index: any) => (
            <Grid key={index} container spacing={2}>
              <Grid xs={6} md={3} item className="details-tile">
                <Box className="details-title">Callout ID.</Box>
                <Box className="details-value">
                  {callout?.call_out_id ?? "-"}
                </Box>
              </Grid>
              <Grid xs={6} md={3} item className="details-tile">
                <Box className="details-title">Type of Emergency</Box>
                <Box className="details-value">
                  {callout?.type_of_emergency ?? "-"}
                </Box>
              </Grid>
              <Grid xs={6} md={3} item className="details-tile">
                <Box className="details-title">Location</Box>
                <Box className="details-value">
                  {callout?.longitude},{callout?.latitude}
                </Box>
              </Grid>              
              <Grid xs={6} md={3} item className="details-tile">
                <Box className="details-title">Details</Box>
                <Box className="details-value">
                    {callout?.core_securitycalloutsstatuses?.[0]?.status}
                </Box>
              </Grid>
              <Grid xs={6} md={3} item className="details-tile">
                <Box className="details-title">Classification</Box>
                <Box className="details-value"
                                 style={{
                                  color: calloutClassificationColor(callout.classification),
                              }}
                >
                  {callout?.classification ?? "-"}
                </Box>
              </Grid>
              <Grid xs={6} md={3} item className="details-tile">
                     <Box className="details-title">Actions</Box>
                        ---
                </Grid>
            </Grid>
          ))}
      </StyledDetailsContainer>
    </>
  );
}
