import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {Chip, Table, TableBody, TableFooter} from "@mui/material";
import * as React from "react";
import {formatDate, ITablePropsInterface} from "../../../lib";
import {ShuriTableRowStates, StyledTableCell, StyledTableRow} from "../table";
import {MetricsCardBackground} from "../../config";
import ShuriTablePagination from "../table/ShuriTablePagination";

export const alarmColor = (priority: string) => {
    let _color: 'error' | 'warning' | 'secondary';
    switch (priority) {
        case 'Critical':
            _color = 'error';
            break;
        case 'High':
            _color = 'warning';
            break;
        default:
            _color = 'secondary';
    }
    return _color;
}

interface IRectifierAlarmTableProps extends ITablePropsInterface {

}

export function RectifierAlarmTable({
                                        data,
                                        page,
                                        rowsPerPage,
                                        handleChangePage,
                                        handleChangeRowsPerPage,
                                        loading,
                                        error
                                    }: IRectifierAlarmTableProps) {

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <StyledTableCell>Create Date</StyledTableCell>
                    <StyledTableCell>Alarm Name</StyledTableCell>
                    <StyledTableCell>Alarm State</StyledTableCell>
                    <StyledTableCell>Priority</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <ShuriTableRowStates
                    colSpan={5}
                    data={data?.core_rectifieralarm ?? []}
                    loading={loading}
                    error={error}
                >
                    <>
                        {
                            data?.core_rectifieralarm.map((alarm: any, index: number) => (
                                <StyledTableRow key={alarm.id} sx={{
                                    color: 'white'
                                }}>
                                    <StyledTableCell>
                                        {
                                            formatDate(alarm.created_on)
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {
                                            alarm.alarm_name
                                        }
                                    </StyledTableCell>

                                    <StyledTableCell>
                                        {
                                            alarm.alarm_state
                                        }
                                    </StyledTableCell>

                                    <StyledTableCell>
                                        {
                                            alarm?.priority &&
                                            <Chip
                                                sx={{
                                                    backgroundColor: MetricsCardBackground,
                                                    fontWeight: '500',
                                                    fontSize: '12px',
                                                    lineHeight: '18px',
                                                    border: 'none',
                                                    padding: '2px 8px',

                                                }}
                                                label={alarm?.priority ?? ''}
                                                color={alarmColor(alarm?.priority)}
                                                size="small"
                                                variant="outlined"
                                            />
                                        }
                                    </StyledTableCell>

                                </StyledTableRow>
                            ))
                        }
                    </>
                </ShuriTableRowStates>
            </TableBody>

            {
                (data?.core_rectifieralarm_aggregate?.aggregate?.count || 0) > rowsPerPage &&
                <TableFooter>
                    <TableRow sx={{border: 0, m: 1}}>
                        <ShuriTablePagination
                            colSpan={5}
                            count={data?.core_rectifieralarm_aggregate?.aggregate?.count ?? 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            }
        </Table>
    )
}