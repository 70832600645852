import {BaseColorLight, errorBase, successBase} from "../../../ui/config";

export const siteStatusColor = (status?: string) => {
    if (!status) return BaseColorLight;
    const word = status.split(" ")[0];
    switch (word) {
        case 'pending':
            return BaseColorLight;
        case 'pending integration':
            return BaseColorLight;
        case 'pending build':
            return BaseColorLight;
        case 'online':
            return successBase;
        case 'live':
            return successBase;
        case 'offline':
            return errorBase;
        default:
            return BaseColorLight;
    }
}