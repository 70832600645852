import ReactPlayer from 'react-player';
import {IPlayerProps} from "../../../lib";
import {Box} from "@mui/material";
import {styled} from "@mui/material/styles";

interface IShuriVideoPlayerProps extends IPlayerProps {
}


export function ShuriVideoPlayer({h, url, onLoaded}: IShuriVideoPlayerProps) {
    return (
        <StyledVideoContainer>
            <ReactPlayer
                className="shuri-video-player"
                key={url}
                url={url}
                playing
                width='100%'
                height="100%"
                loop
                controls
                onLoadedData={onLoaded}
            />
        </StyledVideoContainer>
    )
}

export const StyledVideoContainer = styled(Box)(() => ({
    height: '100%',
    background: 'transparent',
    '.shuri-video-player': {
        borderRadius: '8px',
        'video': {
            objectFit: 'fill',
            borderRadius: '8px',
        }
    }
}));