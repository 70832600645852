import {IShuriNotify, ShuriNotify} from "../../../ui/components/notify";
import {createContext, FC, useContext, useState} from "react";
import {IStackAlert} from "../../interfaces";
import {useSnackbar, SnackbarProvider, SnackbarKey} from "notistack";
import {Close} from "@mui/icons-material";
import {IconButton, Button, Box } from "@mui/material";
import {styled} from "@mui/material/styles";


export const StyledAlertContainer = styled(Box)(_=> ({
  '.shuri-alert-root': {
    '>div': {
        width: '100%'
    }
  }
}));

export interface IShuriAlertContext {
    notify: (options: IShuriNotify) => void;
    showAlertNotification: (options: IStackAlert) => void;
}

interface IShuriAlertProviderProps {
    children: React.ReactNode;
}

interface AlertProps {
  snackbarKey: SnackbarKey;
  id: number
  handleViewClick: (id: number) => void;
}

const SnackbarActions = ({ snackbarKey, id , handleViewClick}: AlertProps) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <>
      <Button variant="text" sx={{ color: 'white' }}
        onClick={()=>{
          handleViewClick(id)
          closeSnackbar(snackbarKey);
        }}>
        View
      </Button>
      <IconButton onClick={() => closeSnackbar(snackbarKey)}>
        <Close sx={{ color: "white" }} />
      </IconButton>
    </>
  );
};

export const ShuriAlertContext = createContext<IShuriAlertContext>({
    notify: () => {
    },
    showAlertNotification: () => {
    },
});

const ShuriBaseAlertProvider: FC<IShuriAlertProviderProps> = ({
                                                                  children,
                                                              }) => {
    const [notification, setNotification] = useState<IShuriNotify>({
        status: "info",
        open: false,
        message: "This is a success message!",
        vertical: "top",
        horizontal: "right",
    });
    const {enqueueSnackbar} = useSnackbar();

    const notify = (options: IShuriNotify) => {
        setNotification({
            ...options,
            open: true,
            signature: Math.random().toString(36).substring(2) + Date.now().toString(36)
        });
    };

    const showAlertNotification = ({
                                       message,
                                       id,
                                       handleViewClick,
                                       variant,
                                       anchorOrigin = {
                                           vertical: 'top',
                                           horizontal: 'right'
                                       },
                                       persist = false,
                                       action = (key) => (
                                        <SnackbarActions snackbarKey={key} id={id} handleViewClick={handleViewClick} />
                                      )
                                   }: IStackAlert) => {
        enqueueSnackbar(message, {variant, persist, anchorOrigin,action,  preventDuplicate : true, key :id });
    };

    return (
        <ShuriAlertContext.Provider value={{notify, showAlertNotification}}>
            {children}
            <ShuriNotify {...notification} />
        </ShuriAlertContext.Provider>
    );
};

export const ShuriAlertProvider: FC<IShuriAlertProviderProps> = (props) => (
  <StyledAlertContainer>
    <SnackbarProvider
            classes={{
              containerRoot: 'shuri-alert-root'
          }}
               maxSnack={5}  >
      <ShuriBaseAlertProvider {...props} />
    </SnackbarProvider>
  </StyledAlertContainer>
);

export const useNotification = () => useContext(ShuriAlertContext);
