import {perPage} from "../../../config";
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";

export const QUERY_GENERATOR_ALARMS = gql`
query MyQuery2($_gte: timestamptz = "", $_lte: timestamptz = "", $offset: Int = 10, $limit: Int = 10, $site_id: bigint = "") {
    core_devicealarm(where: {created_at: {_gte: $_gte, _lte: $_lte}, site_id: {_eq: $site_id}}, offset: $offset, limit: $limit) {
      alarm_id
      alarm_name
      created_at
      device_name
      device_type
      id
      priority
      site_id
      status
    }
    core_devicealarm_aggregate(where: {created_at: {_gte: $_gte, _lte: $_lte}, site_id: {_eq: $site_id}}) {
      aggregate {
        count
      }
    }
  }
  
  
`;

export interface IGeneratorAlarmFilters {
    created_at:any,
    site_id:number
  }

  function getStartAndEndDateTime(inputDate: Date): { created_gte: string, created_lte: string } {
    // Clone the input date to avoid modifying the original object
    const clonedDate = new Date(inputDate);
    let output= {
        created_gte: `${clonedDate.getFullYear()}-${clonedDate.getMonth()+1}-${clonedDate.getDate()}T00:00`,
        created_lte:  `${clonedDate.getFullYear()}-${clonedDate.getMonth()+1}-${clonedDate.getDate()}T23:59`
    };
    return output;
}


export const useGeneratorAlarms = (filters?: IGeneratorAlarmFilters, page = 1, limit = perPage) => {
    const offset = page * limit;
    const day = filters?.created_at ? new Date(filters?.created_at) : new Date();
    let dates = getStartAndEndDateTime(day)
    let variabless= {
        limit:limit,
        offset:offset,
       _lte:dates.created_lte,
       _gte:dates.created_gte,
       site_id:filters?.site_id
      }
    const {data, error, loading, refetch} = useQuery(QUERY_GENERATOR_ALARMS, {
        variables: variabless
    });
    return {data, error, loading, refetch};
}