import {Box, Button, IconButton, Typography} from "@mui/material";
import {useCameraStreamsContext} from "../../../../lib";

export function NoCameraStreams() {
    const {toggleOpenForm} = useCameraStreamsContext();

    return (
        <Box sx={
            {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                flexDirection: 'column',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0
            }
        }>
            <IconButton disableFocusRipple disableRipple>
                <svg width="72" height="73" viewBox="0 0 72 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.72">
                        <path
                            d="M70.8103 21.0312C70.4515 20.8389 70.0472 20.7475 69.6406 20.7668C69.234 20.7861 68.8402 20.9154 68.5013 21.1409L58.5 27.7953V20.7499C58.5 19.5565 58.0259 18.4119 57.182 17.568C56.3381 16.7241 55.1935 16.2499 54 16.2499H31.7981C31.2014 16.2499 30.6291 16.487 30.2071 16.909C29.7852 17.3309 29.5481 17.9032 29.5481 18.4999C29.5481 19.0967 29.7852 19.669 30.2071 20.0909C30.6291 20.5129 31.2014 20.7499 31.7981 20.7499H54V45.3959C54 45.9926 54.2371 46.5649 54.659 46.9869C55.081 47.4088 55.6533 47.6459 56.25 47.6459C56.8467 47.6459 57.419 47.4088 57.841 46.9869C58.2629 46.5649 58.5 45.9926 58.5 45.3959V45.2187L68.5013 51.8871C68.8727 52.1283 69.3071 52.2546 69.75 52.25C70.3467 52.25 70.919 52.0129 71.341 51.5909C71.7629 51.169 72 50.5967 72 50V22.9999C71.9972 22.5951 71.8851 22.1985 71.6758 21.852C71.4664 21.5056 71.1674 21.222 70.8103 21.0312ZM67.5 45.7953L58.5 39.7962V33.2037L67.5 27.2187V45.7953ZM15.165 10.2368C14.9674 10.0141 14.7276 9.83279 14.4594 9.70343C14.1912 9.57407 13.9 9.49922 13.6027 9.48323C13.3054 9.46723 13.0079 9.51041 12.7274 9.61025C12.4469 9.71009 12.189 9.86461 11.9686 10.0649C11.7483 10.2651 11.5699 10.5071 11.4437 10.7768C11.3176 11.0465 11.2462 11.3386 11.2338 11.6361C11.2214 11.9335 11.2681 12.2305 11.3713 12.5098C11.4745 12.7891 11.6321 13.0451 11.835 13.2631L14.5491 16.2499H9C7.80653 16.2499 6.66193 16.7241 5.81802 17.568C4.97411 18.4119 4.5 19.5565 4.5 20.7499V52.25C4.5 53.4434 4.97411 54.588 5.81802 55.4319C6.66193 56.2758 7.80653 56.75 9 56.75H51.3675L56.835 62.7631C57.0326 62.9858 57.2724 63.1671 57.5406 63.2965C57.8088 63.4258 58.1 63.5007 58.3973 63.5167C58.6946 63.5327 58.9921 63.4895 59.2726 63.3896C59.5531 63.2898 59.811 63.1353 60.0314 62.935C60.2517 62.7348 60.4301 62.4928 60.5563 62.2231C60.6824 61.9534 60.7538 61.6613 60.7662 61.3638C60.7786 61.0664 60.7319 60.7694 60.6287 60.4901C60.5255 60.2108 60.3679 59.9548 60.165 59.7368L15.165 10.2368ZM9 52.25V20.7499H18.6412L47.2781 52.25H9Z"
                            fill="#F5F5F5"/>
                    </g>
                </svg>
            </IconButton>

            <Typography sx={{
                paddingBottom: '10px',
                paddingTop: '10px',
                fontSize: '22px',
                color: 'white',
                fontWeight: '500',
                lineHeight: '38px',
                wordWrap: 'break-word'
            }}>
                No cameras streams selected
            </Typography>
            <Typography
                sx={{
                    paddingBottom: '16px',
                    color: '#7F7E7E',
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '20px',
                    wordWrap: 'break-word'
                }}
            >
                There are no cameras selected for this view,<br/>please select a camera to view the live
                stream
            </Typography>
            <Button size="medium"
                    variant="contained"
                    color="info"
                    onClick={() => toggleOpenForm(true)}
                    sx={{borderRadius: '28px', marginRight: '10px', textTransform: 'capitalize'}}>
                Add Stream
            </Button>
        </Box>
    )
}