import {gql, useQuery} from "@apollo/client";

export const SITE_SUB_DATA = gql`
    query SITE_SUB_DATA {
        core_site {
            id
            name
            site_id
            core_sitesecuritycoverages {
                is_covered
            }
        }
    }
`;

export const useSiteSubData = () => {
    const {data, error, loading, refetch} = useQuery(SITE_SUB_DATA);
    return {data, error, loading, refetch};
};