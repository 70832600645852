import {Box, Button} from "@mui/material";
import {AppBackgroundColor} from "../../../../config";
import {ISitesFilters, useShuriPagination, useSitesPaginated, useSiteStatus} from "../../../../../lib";
import AddIcon from '@mui/icons-material/Add';
import {Content, SiteFilterSearch, PageAppBar, SitesTable, IFilterOption, SiteStatusFilter} from "../../../../components";
import {useNavigate, useSearchParams} from "react-router-dom";
import {IFilter} from "../../../../../lib";
import {useEffect, useState} from "react";

export function AllSitesPage() {
    const {page, rowsPerPage, handleChangePage, handleChangeRowsPerPage} = useShuriPagination(10);
    const [siteFilters, setSiteFilters] = useState<ISitesFilters>({});
    const [statusFilters, setSiteStatusFilter] = useState<IFilterOption[]>([])
    const [selectedFilter, setSelectedFilter] = useState<IFilterOption>()
    const {data, error, loading, updateSitesData} = useSitesPaginated(
        page,
        rowsPerPage,
        siteFilters
    );
    const {data: statusData,} = useSiteStatus()
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams) {
            const status = searchParams.get("site_filter");
            if (status){
              handleFilterFormParams(status)
            }
        }
    }, [searchParams, statusData]);

    useEffect(()=>{
      if (statusData){
        setSiteStatusFilter([...statusData.core_sitestatus.map((status:any)=>{
          return {
            label: status.site_status,
            value: status.id
          }
        }),
      {
        label: "At Risk",
        value: "at_risk"
      },
      {
        label: "Disabled",
        value: "disabled"
      }
      ])
      }
    },[statusData])

    const handleFilterFormParams = (status: string ) => {
      let value: string | number;
      if (status === 'at_risk' || status === 'disabled'){
          value = status
      }
      else{
          value = statusData?.core_sitestatus.find((coreStatus: any) => coreStatus.site_status == status)?.id;
      }
      handleStatusUpdate({label: "", value })
  }

    const handleFilterChange = ({value, filters, mappedFilters}: IFilter) => {
        setSiteFilters(mappedFilters);
        handleChangePage(null,0)
    }
    const handleStatusUpdate = (filter: IFilterOption) => {
      setSelectedFilter(filter)
      if (filter.value === 'at_risk'){
        setSiteFilters({
          ...siteFilters, 
          at_risk: true,
          is_disabled: undefined,
          site_status_id: undefined
        })
      }
      else if (filter.value === 'disabled'){
        setSiteFilters({
          ...siteFilters, 
          is_disabled: true,
          at_risk: undefined,
          site_status_id: undefined
        })
      }
      else{
        setSiteFilters({
          ...siteFilters, 
          site_status_id: filter.value,
          is_disabled: undefined,
          at_risk: undefined,        
        })
      }
    }
    const handleRefreshSites = async () => {
        await updateSitesData(siteFilters);
    }

    const RenderSitesData = () => {
        if (error) {
            return <h1>An error occurred </h1>;
        }
        if (loading) {
            return <p>Loading ....</p>;
        }
        return <SitesTable data={data}
                           page={page}
                           rowsPerPage={rowsPerPage}
                           handleChangePage={handleChangePage}
                           handleChangeRowsPerPage={handleChangeRowsPerPage}
                           onRefresh={handleRefreshSites}/>
    }

    return (
      <Box>
        <PageAppBar
          title="Site Management"
          sub="Manage your sites and details here."
          ActionButton={() => (
            <Button
              size="medium"
              variant="contained"
              color="info"
              onClick={() => navigate("/sites/add-site")}
              startIcon={<AddIcon />}
              sx={{ borderRadius: "28px", marginRight: "10px" }}
            >
              Create Site
            </Button>
          )}
        />
        <Content>
          <Box
            sx={{
              height: "auto",
              backgroundSize: "cover",
              backgroundColor: AppBackgroundColor,
              margin: 4,
            }}
          >
            <Box
              sx={{
                color: "white",
                marginBottom: 2,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <SiteStatusFilter filters={statusFilters} selected={selectedFilter?.value} onFilterUpdate={handleStatusUpdate} />
              <SiteFilterSearch
                sites={data?.core_site ?? []}
                onFilterUpdate={handleFilterChange}
              />
            </Box>
            <Box
              sx={{
                "@media (max-width: 600px)": {
                  objectFit: "scale-down",
                  display: "flex",
                  width: "100%",
                  margin: 0,
                  overflowX: "hidden",
                },
              }}
            >
              <Box sx={{ width: "100%", overflowX: "auto" }}>
                <RenderSitesData />
              </Box>
            </Box>
          </Box>
        </Content>
      </Box>
    );
}
