import {Box, Grid} from "@mui/material";
import {FormEvent, memo, useEffect, useRef, useState} from "react";
import {StyledFormRow} from "../../../form";
import {ADD_METER_MUTATION, UPDATE_METER_MUTATION, useNotification} from "../../../../../lib";
import {useMutation} from "@apollo/client";
import {Core_Box, Core_Meter} from "../../../../../generated/graphql";
import {AddSiteMeterForm, IMeterFormRef} from "./AddSiteMeterForm";
import {CreateSiteFormAction} from "../partials/CreateSiteFormAction";

interface IAddSiteMeterProps {
    meters: Core_Meter[]
    box: Core_Box,
    refreshSite?: () => void,
}

const initialState = (box: Core_Box) => [
    {
        box_id: box.id,
        serial_number: ''
    } as Core_Meter
]
export const AddSiteMeter = memo(({
                                      meters,
                                      box,
                                      refreshSite
                                  }: IAddSiteMeterProps) => {
    const {notify} = useNotification();
    const [meterRows, setMeterRows] = useState<Core_Meter[]>(
        meters?.map(meter => ({
            id: meter.id,
            box_id: meter.box_id,
            serial_number: meter.serial_number ?? ''
        } as Core_Meter)) ?? initialState(box)
    );
    const meterFormRefs = useRef<IMeterFormRef[] | null[]>([]);
    const [createSiteMeter, {error: createError}] = useMutation(ADD_METER_MUTATION);
    const [_, {error: updateError}] = useMutation(UPDATE_METER_MUTATION);

    useEffect(() => {
        if (createError) {
            notify({
                status: "error",
                open: true,
                message: 'An error occurred trying to create meters, try again later!',
            });
        }
        if (updateError) {
            notify({
                status: "error",
                open: true,
                message: 'An error occurred trying to update meters, try again later!',
            });
        }
    }, [createError, updateError])

    useEffect(() => {
        if (meters) {
            setMeterRows(meters?.map(meter => ({
                id: meter.id,
                box_id: meter.box_id,
                serial_number: meter.serial_number
            } as Core_Meter) ?? initialState(box)))
        }
    }, [meters]);

    const handleMeterChange = (index: number, meter: Core_Meter) => {
        const updatedRows = [...meterRows];
        updatedRows[index] = meter;
        setMeterRows(updatedRows);
    };

    const handleCancel = () => {
        const updatedRows = [...meterRows].filter((meter) => meter.id);
        setMeterRows(updatedRows.length > 0 ?  updatedRows : initialState(box));
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!await handleFormsValidation()) {
            const cameraInserts = meterRows.map(meter => {
                if (!meter.created_on) {
                    meter.created_on = new Date();
                }
                if (!meter.box_id) {
                    meter.box_id = box.id
                }
                return {
                    ...meter
                }
            });

            await createSiteMeter({
                variables: {
                    objects: cameraInserts
                },
                onCompleted(createdMeterData) {
                    if (createdMeterData) {
                        if (refreshSite) {
                            refreshSite()
                        }

                        notify({
                            status: "success",
                            open: true,
                            message: "Site meters created successfully!",
                        });
                    }
                },
                onError(error) {
                    notify({
                        status: "error",
                        open: true,
                        message: 'An error occurred trying to create site meters, try again later!',
                    });
                }
            })
        } else {
            notify({
                status: "error",
                open: true,
                message: 'Meter form validation error: Kindly provide valid fields before proceeding.',
            });
        }
    }

    const handleFormsValidation = async () => {
        const validationResults: boolean[] = [];
        await Promise.all(
            meterRows.map(async (_, index) => {
                if (meterFormRefs.current[index]) {
                    await meterFormRefs.current[index]?.handleFormValidate();
                    validationResults[index] = meterFormRefs.current[index]?.hasErrors as boolean;
                }
            })
        );
        return formsHasErrors(validationResults);
    }

    const formsHasErrors = (errorResults: boolean[]): boolean => {
        return errorResults?.some(hasError => hasError);
    };

    return (
        <form onSubmit={handleSubmit}>
            <StyledFormRow>
                <Grid container spacing={2} className="form-title-container">
                    <Grid item xs={12} md={12}>
                        <Box className='form-row-title'>
                            Site Meter
                        </Box>
                    </Grid>
                </Grid>

                {
                    meterRows.map((meter, index) => (
                        <AddSiteMeterForm
                            index={index}
                            meter={meter}
                            onMeterChange={handleMeterChange}
                            onRemove={() => handleCancel()}
                        />
                    ))
                }

                {
                    meterRows.length > 0 &&
                    <CreateSiteFormAction
                        primaryLabel={"Save"}
                        secondaryLabel={"Clear"}
                        onSave={handleSubmit}
                        onCancel={() => handleCancel()}
                    />
                }

            </StyledFormRow>
        </form>
    )
})