import {API} from 'aws-amplify'
import {useEffect, useState} from "react";
import {getAuthData} from "../../authData";
import {useNotification} from "../../context";

export const useLiveFeeds = (streamName: string) => {
    const [url, setUrl] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const {notify} = useNotification();

    const getLiveStreamUrl = async (streamName: string) => {
        setLoading(true);
        setError(undefined);
        try {
            const {idToken} = await getAuthData();
            const user = idToken.payload;
            const params = {
                streamName,
                userId: user.sub
            };
            const token = idToken.jwtToken;
            const requestData = {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                },
                queryStringParameters: params,
            }
            const response = await API.get('shurikonektfeapi', '/live-stream', requestData)
            if (response.statusCode !== 200) {
                setError("Could not get live feed. No response!");
                return;
            }
            const url = response.url;
            setUrl(url);
        } catch (error: any) {
            setError("Waiting for live camera feed...!");
            notify({
                open: true,
                status: 'error',
                message: "Error! ::: Could not get stream data, an error occurred!"
            });
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (streamName) {
            getLiveStreamUrl(streamName);
        }
    }, [streamName]);

    return {loading, error, url, getLiveStreamUrl}
}