import {IFilter, IFilterOption, IFilterSearchResults, IJob} from "../../../../lib/interfaces";
import React, {useEffect, useState} from "react";
import {primaryBlue} from "../../../config";
import {getFullNameFromUser} from "../../../../lib/helpers";
import {ShuriTableFilterSearch} from "../../table";

interface IJobsFilterSearchProps {
    jobs: IJob[],
    onFilterUpdate: (filter: IFilter) => void
}

const filter_options: IFilterOption[] = [
    {
        label: 'Site Name',
        value: 'site_name'
    },
    {
        label: 'Job Name',
        value: 'name'
    },
    {
        label: 'Job Number',
        value: 'ext_job_number'
    },
    {
        label: 'Assigned Worker',
        value: 'worker'
    }
]


export function JobsFilterSearch({jobs, onFilterUpdate}: IJobsFilterSearchProps)
{
    const [results, setResults] = useState<IFilterSearchResults[]>([]);
    useEffect(()=>{
        return setResults(
            jobs.map(job => ({
                link: `site/${job.id}`,
                title: `${job?.ext_job_number} - ${job?.name ?? ''}`,
                unique: job.ext_job_number,
                sub1: {
                    label: getFullNameFromUser(job?.core_personnelassigneds[0]?.core_user),
                    icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={primaryBlue} viewBox="0 0 256 256"><path d="M230.92,212c-15.23-26.33-38.7-45.21-66.09-54.16a72,72,0,1,0-73.66,0C63.78,166.78,40.31,185.66,25.08,212a8,8,0,1,0,13.85,8c18.84-32.56,52.14-52,89.07-52s70.23,19.44,89.07,52a8,8,0,1,0,13.85-8ZM72,96a56,56,0,1,1,56,56A56.06,56.06,0,0,1,72,96Z"></path></svg>                },
                sub2: {
                    label: job?.core_site?.name,
                    icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill={primaryBlue} viewBox="0 0 256 256"><path d="M128,88a40,40,0,1,0,40,40A40,40,0,0,0,128,88Zm0,64a24,24,0,1,1,24-24A24,24,0,0,1,128,152Zm73.71,7.14a80,80,0,0,1-14.08,22.2,8,8,0,0,1-11.92-10.67,63.95,63.95,0,0,0,0-85.33,8,8,0,1,1,11.92-10.67,80.08,80.08,0,0,1,14.08,84.47ZM69,103.09a64,64,0,0,0,11.26,67.58,8,8,0,0,1-11.92,10.67,79.93,79.93,0,0,1,0-106.67A8,8,0,1,1,80.29,85.34,63.77,63.77,0,0,0,69,103.09ZM248,128a119.58,119.58,0,0,1-34.29,84,8,8,0,1,1-11.42-11.2,103.9,103.9,0,0,0,0-145.56A8,8,0,1,1,213.71,44,119.58,119.58,0,0,1,248,128ZM53.71,200.78A8,8,0,1,1,42.29,212a119.87,119.87,0,0,1,0-168,8,8,0,1,1,11.42,11.2,103.9,103.9,0,0,0,0,145.56Z"></path></svg>                }
            }))
        );
    }, [jobs])

    return (
        <ShuriTableFilterSearch
            filter_options={filter_options}
            results={results}
            onFilterUpdate={onFilterUpdate}/>
    )
}