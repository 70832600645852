import * as React from "react";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";

export const PlusButton = (props: any) => {
  return (
    <Button
      onClick={(_) => props.handleOpen(props.topic)}
      startIcon={<Add />}
      variant="contained"
      sx={{
        marginLeft: 1,
        alignSelf: "bottom",
        borderRadius: "4px",
        backgroundColor: "#ffffff26",
        color: "white",
      }}
    >
      {props.children}
    </Button>
  );
};
