import gql from "graphql-tag";

export const ADD_JOB_MUTATION = gql`
  mutation ADD_JOBS(
    $ext_job_number: String
    $description: String
    $site_id: bigint
    $personale_id: bigint
    $status_id: bigint
    $visits: [core_visit_insert_input!]!
    $created_on: timestamptz
  ) {
    insert_core_job_one(
      object: {
        ext_job_number: $ext_job_number
        description: $description
        site_id: $site_id
        created_on: $created_on
        core_personnelassigneds: { data: { personale_id: $personale_id } }
        core_jobstatuses: {
          data: { status_id: $status_id, created_on: $created_on }
        }
        core_visits: { data: $visits }
      }
    ) {
      id
      ext_job_number
      description
      site_id
      created_on
      core_personnelassigneds {
        id
        core_user {
          id
          email
          first_name
          last_name
        }
      }
      core_visits {
        id
        start_datetime
        end_datetime
        comment
      }
      core_site {
        id
        name
        address
        municipality
      }
      core_jobstatuses {
        id
      }
    }
  }
`;

export const UPDATE_JOB_MUTATION = gql`
  mutation UPDATE_JOB_MUTATION(
    $job_id: bigint!
    $ext_job_number: String
    $description: String
    $site_id: bigint
    $personale_id: bigint
    $status_id: bigint
    $created_on: timestamptz
    $visits: [core_visit_insert_input!]!
  ) {
    update_core_job_by_pk(
      pk_columns: { id: $job_id }
      _set: {
        ext_job_number: $ext_job_number
        description: $description
        site_id: $site_id
      }
    ) {
      id
      ext_job_number
      description
      site_id
    }

    insert_core_personnelassigned(
      objects: { personale_id: $personale_id, job_id: $job_id }
      on_conflict: {
        constraint: core_personnelassigned_pkey
        update_columns: [personale_id]
      }
    ) {
      returning {
        id
        personale_id
      }
    }

    insert_core_jobstatus(
      objects: {
        status_id: $status_id
        job_id: $job_id
        created_on: $created_on
      }
      on_conflict: {
        constraint: core_jobstatus_pkey
        update_columns: [status_id]
      }
    ) {
      returning {
        id
        status_id
      }
    }

    insert_core_visit(
      objects: $visits
      on_conflict: {
        constraint: core_visit_pkey
        update_columns: [status_id, comment, start_datetime, end_datetime]
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_JOB_MUTATION = gql`
  mutation DELETE_JOB_MUTATION($id: bigint!) {
    delete_core_job(where: {id: {_eq: $id}}){
      returning {
        id
      }
    }
  }
`;
