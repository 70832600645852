import {Box, Grid, Typography, Button, ButtonGroup } from "@mui/material";
import {StyledDetailsContainer} from "../site-details/StyledDetailsContainer";
import {useSiteContext,calloutClassificationColor } from "../../../../lib";
import AddIcon from "@mui/icons-material/Add";
import {CreateCallout} from "./create-callout";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {CancelCalloutAction, CalloutChat} from "../../calllout-actions"
export function ActiveCallouts() {
    const {callouts, site, updateCallouts, coverage} = useSiteContext();
    const [addOpen, setAddOpen] = useState(false);
    const { search } = useLocation();

    useEffect(() => {
        if (search && search.includes("create-open")) {
            setAddOpen(true);
        }
    }, [search]);
    const handleRefreshCallouts = async () => {
        updateCallouts();
    };
    let isCovered;
    if (coverage?.core_sitesecuritycoverage[0]?.is_covered) {
        isCovered = true;
    } else {
        isCovered = false;
    }
    if (!callouts) return <></>;
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={10} md={8}>
                    <Typography className="shuri-card-title">Active Callouts</Typography>
                </Grid>
                <Grid item xs={2} md={4} justifyContent="flex-end" display="flex">
                    <Button
                        disabled={!isCovered}
                        size="medium"
                        variant="contained"
                        color="info"
                        onClick={() => setAddOpen(true)}
                        startIcon={<AddIcon/>}
                        sx={{
                            borderRadius: "28px",
                            marginRight: "10px",
                            textTransform: "capitalize",
                        }}
                    >
                        Create Callout
                    </Button>
                </Grid>
            </Grid>

            <StyledDetailsContainer>
                {callouts?.core_securitycallouts
                    ?.filter((callout: any) => callout.closed_on === null)
                    .map((callout: any, index: any) => (
                        <Grid key={index} container spacing={2}>
                            <Grid xs={6} md={3} item className="details-tile">
                                <Box className="details-title">Callout ID.</Box>
                                <Box className="details-value">
                                    {callout?.call_out_id ?? "-"}
                                </Box>
                            </Grid>
                            <Grid xs={6} md={3} item className="details-tile">
                                <Box className="details-title">Type of Emergency</Box>
                                <Box className="details-value">
                                    {callout?.type_of_emergency ?? "-"}
                                </Box>
                            </Grid>
                            <Grid xs={6} md={3} item className="details-tile">
                                <Box className="details-title">Location</Box>
                                <Box className="details-value">
                                    {callout?.longitude},{callout?.latitude}
                                </Box>
                            </Grid>
                            <Grid xs={6} md={3} item className="details-tile">
                                <Box className="details-title">Details</Box>
                                <Box className="details-value">
                                     {callout?.core_securitycalloutsstatuses?.[0]?.status}
                                </Box>
                            </Grid>
                            <Grid xs={6} md={3} item className="details-tile">
                                <Box className="details-title">Classification</Box>
                                <Box
                                    className="details-value"
                                    style={{
                                        color: calloutClassificationColor(callout.classification),
                                    }}
                                >
                                    {callout?.classification ?? "-"}
                                </Box>
                            </Grid>
                            <Grid xs={6} md={3} item className="details-tile">
                                <Box className="details-title">Actions</Box>
                                <ButtonGroup
                                    size="small"
                                    disableElevation
                                    variant="text"
                                    aria-label="Action Buttons"
                                >
                                    <CancelCalloutAction callout={callout} handleRefreshCallouts={updateCallouts} />
                                    <CalloutChat callout={callout} />
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    ))}
            </StyledDetailsContainer>

            <CreateCallout
                site={{site}}
                onRefresh={handleRefreshCallouts}
                addOpen={addOpen}
                setAddOpen={setAddOpen}
            />
        </>
    );
}
