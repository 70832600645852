import {
  Content,
  CounterTile,
  MainTileContainer,
  Map,
  StandardTile,
  UsageTile,
  SiteTile,
  PageAppBar,
  DisruptionsTile,
} from "../../../components";
import { Box, Grid } from "@mui/material";
import { UnitOfMeasures } from "../../../../enums";
import { History, Person2Outlined, WarningOutlined } from "@mui/icons-material";
import {useEffect, useState} from "react";
import tower from "../../../../assets/big-tower.png";
import {
  useCompanySummary,
  useSites,
  useEventLogsNotifications,
  useLatestCompanyWideSiteChargeUnits
} from "../../../../lib";
import CustomTower from "../../../../assets/CustomTower";
import {isLoadShedding } from "../../../../utils";
import {useSearchParams} from "react-router-dom";
import {Core_Site} from "../../../../generated/graphql";
import {getYearMonth, getPrevYearMonth } from '../../../../lib'

export function DashboardLandingPage(props: any) {
  const [selectedSite, setSelectedSite] = useState<any>(null);
  const { error, loading, data } = useSites();
  const {
    data: eventData
  } = useEventLogsNotifications();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(()=>{
    handleSiteFromSearch();
  }, [searchParams]);

  useEffect(()=>{
    removeSelectedSiteParam();
  }, []);

  useEffect(()=>{
    if (data){
      handleSiteFromSearch();
    }
  }, [data]);

  const handleSiteFromSearch = ()=> {
    if (searchParams){
      const selectedSiteId = searchParams.get("selectedSiteId");
      if (selectedSiteId){
        const site = data?.core_site.find((site: Core_Site) => site.id == selectedSiteId);
        setSelectedSite(site ?? null);
      }
    }
  }

  const removeSelectedSiteParam = () => {
    if (searchParams.has('selectedSiteId')) {
      searchParams.delete('selectedSiteId');
      setSearchParams(searchParams);
    }
  }

  const siteTiles = (selectedSite: any) => {

    const latestEstimatePeriodEnd =
      selectedSite?.core_sitechargeestimates_aggregate?.aggregate.max
        .charge_period_end;
    const latestEstimate = latestEstimatePeriodEnd
      ? selectedSite?.core_sitechargeestimates_aggregate?.nodes.find(
          (node: any) => {
            return node.charge_period_end === latestEstimatePeriodEnd;
          }
        )
      : { cost: 0, total_units: 0 };
    const plannedMaintenance =
      selectedSite.core_visits_aggregate?.aggregate?.count ?? 0;
    // const unplannedEvents =
    //   selectedSite.core_disruptions_aggregate?.aggregate?.count ?? 0;
    // TODO: unauthorized access

    return [
      { component: SiteTile, props: { site: selectedSite } },
      {
        component: UsageTile,
        props: {
          title: "Electric Usage",
          amount: latestEstimate.cost,
          unitOfMeasure: UnitOfMeasures.Currency,
        },
      },
      {
        component: UsageTile,
        props: {
          title: "Electric Units",
          amount: latestEstimate.total_units,
          unitOfMeasure: UnitOfMeasures.KWH,
        },
      },
      {
        component: StandardTile,
        props: {
          title: "Planned Maintenance",
          value: plannedMaintenance,
          icon: <History color="warning" />,
        },
      },
      {
        component: DisruptionsTile,
        props: {
          title: "Loadshedding Data",
          count: selectedSite?.core_disruptions?.length,
          value: selectedSite?.core_disruptions,
          icon: <WarningOutlined color="warning" />,
        },
      },
      {
        component: StandardTile,
        props: {
          title: "Data Pool Balance",
          value: "-- MB",
          icon: <Person2Outlined color="error" />,
        },
      },
      {
        component: StandardTile,
        props: {
          title: "Pending Issues",
          value: eventData?.core_eventlog.filter(
            (log: any) => log.site_id === selectedSite?.id
          ).length,
          icon: <Person2Outlined color="error" />,
        },
      },
    ];
  };

  const {
    error: companySummaryError,
    loading: companySummaryLoading,
    data: companySummaryData,
  } = useCompanySummary();
  const year_month = getYearMonth()
  const prev_year_month = getPrevYearMonth()

  const {
    error: companySiteChargeEstimateError,
    loading: companySiteChargeEstimateLoading,
    data: companySiteChargeEstimateData,
  } = useLatestCompanyWideSiteChargeUnits(year_month);

  const {
    data: companyPrevSiteChargeEstimateData,
  } = useLatestCompanyWideSiteChargeUnits(prev_year_month);
  const unitCost = companySiteChargeEstimateData?.getcompanywidesitechargecostandunitsbycompanyid[0]
  const prevUnitCost = companyPrevSiteChargeEstimateData?.getcompanywidesitechargecostandunitsbycompanyid[0]
  
  let percentageChange = {units: 0, cost:0}

  if (unitCost && prevUnitCost){
    const percentageCostChange = (((unitCost.cost - prevUnitCost.cost) / prevUnitCost.cost) * 100).toFixed(2);
    const percentageUnitChange = (((unitCost.units - prevUnitCost.units) / prevUnitCost.units) * 100).toFixed(2);
    percentageChange = {units: parseFloat(percentageUnitChange), cost: parseFloat(percentageCostChange)}
  }
  
  const totalOnlineSites = data?.core_site.filter((site: any) => {
    return site?.core_sitestatus?.site_status === "online" && site?.is_enabled;
  }).length;

  const totalOfflineSites = data?.core_site.filter((site: any) => {
    return site?.core_sitestatus?.site_status === "offline" && site?.is_enabled;
  }).length;

  const AtRiskSites = data?.core_site.filter((site: any) => {
    return site?.at_risk && site?.is_enabled;
  });
  const totalAtRiskSites = AtRiskSites?.length 
  
  const totalDisabledRiskSites= data?.core_site.filter((site: any) => {
    return !site?.is_enabled;
  }).length;

  const totalPlannedMaintenance =
    companySummaryData?.core_visit_aggregate?.aggregate?.count ?? 0;

    const totalLoadShedSites= data?.core_site.filter((site: any) => {
      return  isLoadShedding(site?.core_disruptions);
    }).length;  


  const tiles = [
    {
      component: CounterTile,
      props: {
        title: "Total Sites",
        value: data?.core_site.length,
        items: [
          {
            label: "Online",
            value: totalOnlineSites,
            color: "#12B76A",
            icon: <CustomTower color="#12B76A" />,
            path: '/sites?site_filter=online'
          },
          {
            label: "Offline",
            value: totalOfflineSites,
            color: "#FF0000",
            icon: <CustomTower color="#FF0000" />,
            path: '/sites?site_filter=offline'
          },
          {
            label: "At Risk",
            value: totalAtRiskSites,
            color: "#FF632B",
            icon: <CustomTower color="#FF632B" />,
            path: '/sites?site_filter=at_risk'
          },
          {
            label: "Disabled",
            value: totalDisabledRiskSites,
            color: "#746F6F",
            icon: <CustomTower color="#746F6F" />,
            path: '/sites?site_filter=disabled'
          },
        ],
      },
    },
    {
      component: UsageTile,
      props: {
        title: "Electric Usage",
        amount: unitCost ? unitCost.cost : 0,
        percentageChange: percentageChange ?  percentageChange.cost : 0,
        unitOfMeasure: UnitOfMeasures.Currency,
        path: '/dashboard/electrical'
      },
    },
    {
      component: UsageTile,
      props: {
        title: "Electric Units",
        amount: unitCost? unitCost.units : 0,
        percentageChange: percentageChange ?  percentageChange.units : 0,
        unitOfMeasure: UnitOfMeasures.KWH,
        path: '/dashboard/electrical'
      },
    },
    {
      component: StandardTile,
      props: {
        title: "Planned Maintenance",
        value: totalPlannedMaintenance,
        icon: <History color="warning" />,
        path: '/jobs'
      },
    },
    {
      component: StandardTile,
      props: {
        title: " Currently Loadshed Sites",
        value: totalLoadShedSites,
        icon: <WarningOutlined color="warning" />,
        path: '/notifications'
      },
    },
    {
      component: StandardTile,
      props: {
        title: "Unplanned Access",
        value: "0/10",
        icon: <Person2Outlined color="error" />,
        path: '/event-logs'
      },
    },
    {
      component: StandardTile,
      props: {
        title: "Pending Issues",
        value: eventData?.core_eventlog?.length,
        icon: <Person2Outlined color="error" />,
        path: '/jobs'
      },
    },
  ];

  const onMarkerClick = (site: any) => {
    if (selectedSite?.id === site.id) {
      return;
    }
    setSelectedSite(site);
  };

  const onCloseMarkInfo = () => {
    setSelectedSite(null);
    removeSelectedSiteParam();
  };

  if (error || companySiteChargeEstimateError || companySummaryError) {
    return <h1>An error occurred </h1>;
  }
  if (loading || companySiteChargeEstimateLoading || companySummaryLoading) {
    return <p>Loading ....</p>;
  }

  return (
    <Content>
      <PageAppBar
        title="Home"
        sub="All sites Data"
        // ActionButton={()=>(
        //         <Button size="medium" variant="contained" color="info" startIcon={<AddIcon/>} sx={{
        //             borderRadius: '28px',
        //             marginRight: '10px'
        //         }}>
        //             Create Job
        //         </Button>
        //     )}
      />

      {/* {selectedSite && <Button sx={{backgroundColor: AppDarkBackgroundColor, color: 'white', position: 'absolute', right: '2%', marginTop: '40px'}}
                                onClick={onCloseMarkInfo}
                                endIcon={<HighlightOff sx={{color:'red'}}/>}>Close</Button>} */}
      <Grid container rowGap={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {selectedSite ? (
            <MainTileContainer
              siteId={selectedSite?.id}
              subtitle={`Up Since: ${
                selectedSite?.up_since || "01 JAN 2022 15:33:00"
              }`}
              title={`Site: ${selectedSite.site_id} - ${selectedSite.name}`}
              titleColor={""}
              dark
              tiles={siteTiles(selectedSite)}
            />
          ) : (
            <MainTileContainer
              subtitle="All Sites Data (Monthly)"
              tiles={tiles}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              height: "66vh",
              flexGrow: 1,
              backgroundColor: "#696a6b",
              border: "1px solid yellow",
            }}
          >
            <Map
              atRiskSites={AtRiskSites}
              data={data}
              onMarkerClick={onMarkerClick}
              selectedSiteId={selectedSite?.id}
              onCloseMarkInfo={onCloseMarkInfo}
            />
          </Box>
        </Grid>
      </Grid>
    </Content>
  );
}


function Tower(props: { color: string }) {
  return (
    <Box>
      <img
        alt="Tower Image"
        src={tower}
        style={{ height: 240, width: 140, marginBottom: 85 }}
      />
    </Box>
  );
}
