import {ChangeEvent, useState} from "react";
import {ValidationError} from "yup";

export function useForm(initial: any = {}) {
    const [inputs, setInputs] = useState(initial);

    function handleChange(e: ChangeEvent<HTMLElement | HTMLInputElement | any> | any) {
        let {value, name, type}: any = e.target;

        if (type === 'number') {
            value = value === '' ? NaN : parseFloat(value);
        }

        if (type === 'file') {
            [value] = e.target?.files
            console.log(value);
        }

        setInputs({
            ...inputs,
            [name]: value
        })
    }

    function resetForm() {
        setInputs(initial)
    }

    function clearForm() {
        const blankState = Object.fromEntries(
            Object.entries(inputs).map(([key, ,]) => [key, ''])
        );

        setInputs(blankState)
    }

    return {
        inputs,
        handleChange,
        resetForm,
        clearForm,
        setInputs
    }
}

export const useValidateForm = (values: { [key: string]: any }, validators?: { [key: string]: Function }, requiredFields?: string[]) => {
    const [errors, setErrors] = useState({});

    const validateFieldValidators = (name: string) => {
        if (!validators) return;
        const value = values[name];
        const validator = validators[name];
        const error = validator ? validator(value) : null;
        setErrors((prevState) => ({
            ...prevState,
            [name]: error,
        }));
    };

    const validateField = (name: string) => {
        if (!requiredFields) return;
        const value = values[name];
        const error = requiredFields.includes(name) && value.trim() === '' ? 'This field is required' : null;
        setErrors((prevState) => ({
            ...prevState,
            [name]: error,
        }));
    }

    const validateForm = () => {
        if (validators) {
            return Object.keys(values).forEach((name) => validateFieldValidators(name));
        }
        if (requiredFields) {
            requiredFields.forEach((name) => validateField(name));
        }

        return false;
    };

    return {
        errors,
        validateField,
        validateForm,
    };
};

export const useYupValidateForm = (values: { [key: string]: any }, schema?: any) => {
    const [errors, setErrors] = useState<{ [key: string]: string | null }>({});;
    const [hasErrors, setHasErrors] = useState(false);
    const validateField = async (name: string) => {
        if (!schema) return;
        try {
            await schema.validateAt(name, values);
            setErrors((prevState) => ({
                ...prevState,
                [name]: null,
            }));
            setHasErrors(false);
        } catch (error) {
            if (error instanceof ValidationError) {
                setErrors((prevState) => ({
                    ...prevState,
                    [name]: (error as Error).message,
                }));
                setHasErrors(true);
            }
        }
    };

    const validateForm = async () => {
        if (schema) {
            try {
                await schema.validate(values, { abortEarly: false });
                setErrors({});
                setHasErrors(false);
                return true;
            } catch (error) {
                if (error instanceof ValidationError) {
                    const fieldErrors: { [key: string]: string } = {};
                    error.inner.forEach((err) => {
                        const { path, message } = err;
                        fieldErrors[path  as string] = message;
                    });
                    setErrors(fieldErrors);
                    setHasErrors(true);
                }
                return false;
            }
        }
        return true;
    };

    return {
        errors,
        validateField,
        validateForm,
        hasErrors
    };
};