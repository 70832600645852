import {Grid} from "@mui/material";
import SiteCameras from "./site-cameras";
import SiteActivities from "./site-activities/site-activities";

export default function SiteDashboard() {
    return (
        <Grid container rowSpacing={2} columnSpacing={0}>
            <Grid item xs={12}>
                <SiteCameras />
            </Grid>
            <Grid item xs={12}>
                <SiteActivities />
            </Grid>
        </Grid>
    )
}
