import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
  Modal,
} from "@mui/material";
import { useInventoryComponents, ADD_INVENTORY } from "../../../lib/hooks";
import { useMutation } from "@apollo/client";
import {
  InputBox,
  PlusButton,
  AlertSnackBar,
  ModelForm,
  StatusForm,
  MakeForm,
  PartForm,
  ConditionForm,
  TypeForm,
} from "../../components/inventory-form";

export function AddInventory() {
  const [success, setSuccess] = React.useState(false);
  const {
    data: inventories,
    error: inventoriesErrors,
    loading: loadingInventories,
    refetch,
  } = useInventoryComponents();

  const [addInventory, { data, loading, error }] = useMutation(ADD_INVENTORY, {
    onCompleted: () => {
      refetch();
    },
  });

  const initalState = {
    site_id: "",
    serial_number: "",
    condition: "",
    make: "",
    model: "",
    status: "",
    part: null,
    type: "",
    topic: "",
    image: "",
    open: false,
  };

  const [state, setState] = useState(initalState);

  const handleReset = () => {
    setState(initalState);
  };
  const handleToastClose = () => {
    setSuccess(false);
  };

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };
  const handleOpen = (set_topic: any) => {
    setState({
      ...state,
      open: true,
      topic: set_topic,
    });
  };
  const handleChange = (event: any) => {
    const value = event.target.value;
    switch (event.target.name) {
      case "site_id":
        setState({
          ...state,
          site_id: value,
        });
        break;

      case "serial_number":
        setState({
          ...state,
          serial_number: value,
        });
        break;

      case "condition":
        setState({
          ...state,
          condition: value,
        });
        break;

      case "make":
        setState({
          ...state,
          make: value,
        });
        break;
      case "model":
        setState({
          ...state,
          model: value,
        });
        break;

      case "status":
        setState({
          ...state,
          status: value,
        });
        break;

      case "part":
        setState({
          ...state,
          part: value,
        });
        break;

      case "type":
        setState({
          ...state,
          type: value,
        });
        break;

      case "image":
        setState({
          ...state,
          image: value,
        });
        break;

      default:
    }
  };

  let ModalToDisplay = (
    <ModelForm closeHandler={handleClose} handleRefresh={refetch} />
  );

  if (state.topic === "model") {
    ModalToDisplay = (
      <ModelForm closeHandler={handleClose} handleRefresh={refetch} />
    );
  } else if (state.topic === "status") {
    ModalToDisplay = (
      <StatusForm closeHandler={handleClose} handleRefresh={refetch} />
    );
  } else if (state.topic === "make") {
    ModalToDisplay = (
      <MakeForm closeHandler={handleClose} handleRefresh={refetch} />
    );
  } else if (state.topic === "part") {
    ModalToDisplay = (
      <PartForm closeHandler={handleClose} handleRefresh={refetch} />
    );
  } else if (state.topic === "condition") {
    ModalToDisplay = (
      <ConditionForm closeHandler={handleClose} handleRefresh={refetch} />
    );
  } else if (state.topic === "type") {
    ModalToDisplay = (
      <TypeForm closeHandler={handleClose} handleRefresh={refetch} />
    );
  }

  if (inventoriesErrors) {
    return <h1>An error occurred </h1>;
  }
  if (loadingInventories) {
    return <p>Loading ....</p>;
  }

  return (
    <Container maxWidth="xl">
      <Card className="container" sx={{ bgcolor: "#cfe8fc" }}>
        <CardHeader
          title="Add Equipment"
          sx={{ bgcolor: "#161221", color: "#ffffff" }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            background: "#26222A",
          }}
        >
          <Box
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              addInventory({
                variables: {
                  serial_number: state.serial_number,
                  condition_id: state.condition,
                  type_id: state.type,
                  part_id: state.part,
                  site_id: state.site_id,
                  status_id: state.status,
                  model_id: state.model,
                  image: state.image,
                  created_on: new Date(),
                },
              });
              if (data) {
              }
              handleReset();
              setSuccess(true);
            }}
            sx={{ margin: 3 }}
          >
            <Grid container spacing={3} item xs={12}>
              <Grid item xs={4}>
                <InputLabel sx={{ color: "#ADACAF" }}>Serial Number</InputLabel>
                <TextField
                  value={state.serial_number}
                  name="serial_number"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                  sx={{
                    input: {
                      color: "black",
                      background: "white",
                      borderRadius: 2,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel sx={{ color: "#ADACAF" }}>Image URL</InputLabel>
                <TextField
                  value={state.image}
                  name="image"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                  sx={{
                    input: {
                      color: "black",
                      background: "white",
                      borderRadius: 2,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel sx={{ color: "#ADACAF" }}>Site</InputLabel>
                <TextField
                  value={state.site_id}
                  name="site_id"
                  onChange={handleChange}
                  fullWidth
                  select
                  sx={{
                    color: "#black",
                    background: "white",
                    borderRadius: 2,
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          backgroundColor: "white",
                          maxHeight: 300,
                        },
                      },
                    },
                  }}
                >
                  {inventories.core_site.map((item: any) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.site_id ? item.site_id : " "} --{" "}
                        {item.name ? item.name : item.site_id}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <InputLabel sx={{ color: "#ADACAF" }}>Make</InputLabel>
                <InputBox>
                  <TextField
                    value={state.make}
                    name="make"
                    onChange={handleChange}
                    fullWidth
                    select
                    sx={{
                      color: "#black",
                      background: "white",
                      borderRadius: 2,
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          sx: {
                            backgroundColor: "white",
                            maxHeight: 300,
                          },
                        },
                      },
                    }}
                  >
                    {inventories.core_equipmentmake.map((item: any) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.equipment_make}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  <PlusButton handleOpen={handleOpen} topic="make" />
                </InputBox>
              </Grid>
              <Grid item xs={4}>
                <InputLabel sx={{ color: "#ADACAF" }}>Model</InputLabel>
                <InputBox>
                  <TextField
                    value={state.model}
                    name="model"
                    onChange={handleChange}
                    fullWidth
                    select
                    sx={{
                      color: "#black",
                      background: "white",
                      borderRadius: 2,
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          sx: {
                            backgroundColor: "white",
                            maxHeight: 300,
                          },
                        },
                      },
                    }}
                  >
                    {inventories.core_equipmentmodel.map((item: any) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.model_number}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  <PlusButton handleOpen={handleOpen} topic="model" />
                </InputBox>
              </Grid>
              <Grid item xs={4}>
                <InputLabel sx={{ color: "#ADACAF" }}>Status</InputLabel>
                <InputBox>
                  <TextField
                    value={state.status}
                    name="status"
                    onChange={handleChange}
                    fullWidth
                    select
                    sx={{
                      color: "#black",
                      background: "white",
                      borderRadius: 2,
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          sx: {
                            backgroundColor: "white",
                            maxHeight: 300,
                          },
                        },
                      },
                    }}
                  >
                    {inventories.core_equipmentstatus.map((item: any) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.equipment_status}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  <PlusButton handleOpen={handleOpen} topic="status" />
                </InputBox>
              </Grid>
              <Grid item xs={4}>
                <InputLabel sx={{ color: "#ADACAF" }}>Part</InputLabel>
                <InputBox>
                  <TextField
                    value={state.part}
                    name="part"
                    onChange={handleChange}
                    fullWidth
                    select
                    sx={{
                      color: "#black",
                      background: "white",
                      borderRadius: 2,
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          sx: {
                            backgroundColor: "white",
                            maxHeight: 300,
                          },
                        },
                      },
                    }}
                  >
                    {inventories.core_equipmentpart
                      .filter((item: any) => item.model_id === state.model)
                      .map((item: any) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.equipment_part}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                  <PlusButton handleOpen={handleOpen} topic="part" />
                </InputBox>
              </Grid>
              <Grid item xs={4}>
                <InputLabel sx={{ color: "#ADACAF" }}>Condition</InputLabel>
                <InputBox>
                  <TextField
                    value={state.condition}
                    name="condition"
                    onChange={handleChange}
                    fullWidth
                    select
                    sx={{
                      color: "#black",
                      background: "white",
                      borderRadius: 2,
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          sx: {
                            backgroundColor: "white",
                            maxHeight: 300,
                          },
                        },
                      },
                    }}
                  >
                    {inventories.core_equipmentcondition.map((item: any) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.condtion}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  <PlusButton handleOpen={handleOpen} topic="condition" />
                </InputBox>
              </Grid>
              <Grid item xs={4}>
                <InputLabel sx={{ color: "#ADACAF" }}>Type</InputLabel>
                <InputBox>
                  <TextField
                    value={state.type}
                    name="type"
                    onChange={handleChange}
                    fullWidth
                    select
                    sx={{
                      color: "#black",
                      background: "white",
                      borderRadius: 2,
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          sx: {
                            backgroundColor: "white",
                            maxHeight: 300,
                          },
                        },
                      },
                    }}
                  >
                    {inventories.core_equipmenttype.map((item: any) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  <PlusButton handleOpen={handleOpen} topic="type" />
                </InputBox>
              </Grid>
              <Grid item xs={2}>
                <Button variant="contained" type="submit" color="success">
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Card>
      <AlertSnackBar
        open={success}
        title="Equipment"
        handleToastClose={handleToastClose}
      />
      <Modal
        open={state.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {ModalToDisplay}
      </Modal>
    </Container>
  );
}
