import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { useGetDashboard } from "../../../../../lib";
import ExpandDashboardPopUp from "./ExpandedModal";

/**
 * Renders dashbaord widgets in a grid view
 * @param props takes no pros
 * @returns
 */
export default function DashboardGrid(props: any) {
  const { id } = useParams();
  const [components, setComponents] = useState<any[]>([]);
  const { error, loading, data } = useGetDashboard(id);
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState(false);
  useEffect(() => {
    const components = data?.core_dashboard;
    if (components) {
      setComponents([...components]);
    }
  }, [id, data]);

  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <Grid container spacing={2} rowGap={2}>
        {components.map((item: any, index: any) => (
          <Grid item xs={12} sm={12} md={12} lg={6}>
            {/* <Box  height={'50vh'} component={'iframe'}  src={`${item.url}`}></Box> */}
            <Box
              component={"div"}
              sx={{
                position: "relative",
                width: "100%",
                height: { xs: "100%", sm: "100%", md: "100%", lg: "100%" },
                minHeight: { xs: "100%", sm: 100, md: 200, lg: 300, xl: 700 },
              }}
            >
              <iframe
                style={{ width: "100%", height: "100%", border: "none" }}
                src={item.url}
                title="Embedded Dashboard"
              ></iframe>
              <Box
                onClick={() => {
                  setIsOpen(true);
                  setUrl(item.url);
                }}
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  zIndex: 2,
                  color: "grey",
                  marginLeft: 10,
                  width: 200,
                  height: 40,
                  padding: 10,
                }}
              >
                {/* this is to disable redirect icon  do not remove*/}.
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
      <ExpandDashboardPopUp
        url={url}
        isVisible={isOpen}
        handleClose={() => setIsOpen(false)}
      />
    </Box>
  );
}
