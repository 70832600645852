import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import {
  GoogleMap,
  InfoWindow,
  MarkerF as Marker,
  useJsApiLoader
} from "@react-google-maps/api";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import towerPending from "../../../assets/tower.svg";
import towerOffline from "../../../assets/towerOffline.png";
import towerOnline from "../../../assets/towerOnline.png";
import { googleApiKey } from "../../../config";
import { Core_Securitycallouts } from "../../../generated/graphql";
import { formatDate } from "../../../lib";
import "./index.css";
const containerStyle = {
  width: "100%",
  height: "120%"
};

export function CalloutsMap(props: {
  data: any;
  onMarkerClick: (callout: any) => void;
  selectedCallout: Core_Securitycallouts | null;
  onCloseMarkInfo: () => void;
}) {
  const [mapZoom, setMapZoom] = useState(8);
  const defaultInitPos = useMemo(() => {
    return { lat: -26.902601816368087, lng: 27.457943115823962 };
  }, []);
  const [initPos, setInitPos] = useState(defaultInitPos);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: `${googleApiKey}`,
    libraries: ["geometry", "drawing"]
  });

  const checkStatus = (status: any) => {
    if (status === "online") {
      return towerOnline;
    }
    if (status?.includes("pending")) {
      return towerPending;
    } else {
      return towerOffline;
    }
  };

  const mapRef = useRef<GoogleMap | null>();

  useEffect(() => {
    if (props.selectedCallout) {
      setInitPos({
        lat: props.selectedCallout.latitude,
        lng: props.selectedCallout.longitude
      });
      setMapZoom(17);
    } else {
      setInitPos(defaultInitPos);
      setMapZoom(8);
    }
  }, [props.selectedCallout, defaultInitPos]);

  return isLoaded ? (
    <GoogleMap
      ref={(ref) => (mapRef.current = ref)}
      mapContainerStyle={containerStyle}
      center={initPos}
      zoom={mapZoom}
    >
      {props.data.core_securitycallouts?.filter((callout: any) => callout.closed_on === null)?.map(
        (callout: Core_Securitycallouts) => {
          const isResolved = Boolean(callout.closed_on);
          return (
            <Marker
              options={{
                icon: checkStatus(isResolved ? "" : "pending")
              }}
              key={callout.id}
              title={callout?.core_site?.name}
              onClick={() => props.onMarkerClick(callout)}
              position={{
                lat: callout?.core_site?.latitude,
                lng: callout?.core_site?.longitude
              }}
            >
              {props.selectedCallout?.id === callout.id ? (
                <InfoWindow onCloseClick={() => props.onCloseMarkInfo()}>
                  <Box
                    sx={{
                      width: "320px",
                      maxHeight: "140px",
                      backgroundColor: "white"
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "16px", fontWeight: "500" }}
                    >{`${callout?.core_site?.site_id} - ${callout?.core_site?.name}`}</Typography>
                    <Box sx={{ display: "flex", color: "#EE9408" }}>
                      <Box sx={{ marginTop: "12px" }}>
                        <Typography sx={{ fontSize: "12px" }}>
                          {callout.type_of_emergency}:
                        </Typography>
                        <Typography sx={{ fontSize: "12px" }}>
                          {formatDate(callout.created_on)}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "12px"
                      }}
                    >
                      <Box>
                        <Typography sx={{ fontSize: "12px" }}>
                          Last Updated
                        </Typography>
                        <Typography sx={{ fontSize: "12px" }}>
                          {formatDate(callout.updated_at)}
                        </Typography>
                      </Box>
                      <Button
                        sx={{
                          color: "#5BC0E9",
                          fontSize: "14px",
                          textTransform: "capitalize"
                        }}
                      >
                        <Link
                          style={{ color: "#5BC0E9" }}
                          to={`/site/${callout.site_id}?armed-response`}
                        >
                          View Callout
                        </Link>
                        <ChevronRightIcon sx={{ color: "#5BC0E9" }} />
                      </Button>
                    </Box>
                  </Box>
                </InfoWindow>
              ) : null}
            </Marker>
          );
        }
      )}
    </GoogleMap>
  ) : null;
}
