import {Box, Grid, Typography} from "@mui/material";
import {StyledDetailsContainer} from "./StyledDetailsContainer";
import {useSiteContext} from "../../../../lib";

export function SiteDetailsBox() {
    const {site: data} = useSiteContext();
    const box = data?.core_site[0]?.core_boxes[0];
    if (!box) return <></>
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Typography className="shuri-card-title">Shuri Edge Device</Typography>
                </Grid>
            </Grid>

            <StyledDetailsContainer>
                <Grid container spacing={2}>
                    <Grid xs={6} md={4} item className="details-tile">
                        <Box className="details-title">Shuri Device Type</Box>
                        <Box className="details-value">{box?.core_equipment?.core_equipmenttype?.name ?? "-"}</Box>
                    </Grid>
                    <Grid xs={6} md={4} item className="details-tile">
                        <Box className="details-title">Serial Number</Box>
                        <Box className="details-value">{box?.core_equipment?.serial_number ?? "-"}</Box>
                    </Grid>
                    <Grid xs={6} md={4} item className="details-tile">
                        <Box className="details-title">Status</Box>
                        <Box className="details-value">{box?.core_equipment?.core_equipmentstatus?.equipment_status ?? "-"}</Box>
                    </Grid>
                </Grid>
            </StyledDetailsContainer>
        </>
    )
}