import {ISelectInput} from "../../interfaces";

interface IMapSelectData {
    name: string;
    id: string | number;
}

export const mapNameAndIdToSelect = (data: IMapSelectData[]) => {
    return data?.map((item)=>({
        label: item.name,
        value: item.id
    })) ?? [];
}

export const getSelectLabelFromValue = (data: ISelectInput[], value: string | number) =>{
    const select_object = data.find(obj => obj.value === value);
    return select_object ? select_object.label : undefined;
}