import {Box, Grid, FormControl} from "@mui/material";
import {FormEvent, memo, useEffect, useState} from "react";
import {StyledFormRow, StyledFormLabel, StyledFormTextField} from "../../../form";
import {ShuriMuiFormSelect} from "../../../form/form-select/ShuriMuiFormSelect";
import {CreateSiteFormAction} from "../partials/CreateSiteFormAction";
import {useForm} from "../../../../../lib/form/hooks";
import {IEquipment, ISelectInput, ISite} from "../../../../../lib/interfaces";
import {useParams} from "react-router-dom";
import {useMutation} from "@apollo/client";
import {ADD_SITE_BOX, UPDATE_SITE_BOX} from "../../../../../lib/hooks";
import {useNotification} from "../../../../../lib/context";

interface IAddSiteEdgeDeviceProps {
    site?: ISite,
    device?: any,
    refreshSite?: () => void,
    inventories?: IEquipment[]
}

export const AddSiteEdgeDevice = memo(({
                                           site,
                                           device,
                                           refreshSite,
                                           inventories
                                       }: IAddSiteEdgeDeviceProps) => {
    const {id} = useParams();
    const { notify } = useNotification();
    const {inputs, handleChange, clearForm, setInputs} = useForm({
        equipment_id: '',
        site_id: id ?? ''
    });
    const [equipmentOptions, setEquipmentOptions] = useState<ISelectInput[]>([]);

    const [createSiteBox] = useMutation(ADD_SITE_BOX, {
        variables: inputs
    });

    const [updateSiteBox] = useMutation(UPDATE_SITE_BOX, {
        variables: inputs
    });

    useEffect(() => {
        const options = inventories?.map(inventory => ({
            label: inventory?.core_equipmentmodel?.equipment_model ?? '',
            value: inventory.id
        }));
        setEquipmentOptions(options ?? []);
    }, [inventories])

    useEffect(() => {
        if (device) {
            setInputs({
                equipment_id: device?.equipment_id ?? '',
                site_id: id ?? ''
            })
        }
    }, [device, id, setInputs]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!device){
           await createSiteBox({
                variables: {
                    ...inputs,
                    created_on: new Date(),
                    date_modified: new Date(),
                    site_id: site?.id
                },
               onCompleted(createdBoxData) {
                    if (createdBoxData){
                        if (refreshSite) {
                            refreshSite()
                        }

                        notify({
                            status: "success",
                            open: true,
                            message: "Site edge device created successfully!",
                        });
                    }
               },
               onError(error){
                   console.log(error, "error");
                   notify({
                       status: "error",
                       open: true,
                       message: 'An error occurred trying to create site edge device, try again later!',
                   });
               }
            })
        }
        else{
            await updateSiteBox({
                variables: {
                    ...inputs,
                    id: device.id,
                    // created_on: device.created_on,
                    date_modified: new Date(),
                },
                onCompleted(updatedBoxData) {
                    if (updatedBoxData){
                        if (refreshSite) {
                            refreshSite()
                        }
                        notify({
                            status: "success",
                            open: true,
                            message: "Site edge device updated successfully!",
                        });
                    }
                },
                onError(error){
                    console.log(error, "error");
                    notify({
                        status: "error",
                        open: true,
                        message: 'An error occurred trying to update site edge device, try again later!',
                    });
                }
            })
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <StyledFormRow>
                <Box className='form-row-title'>
                    Site Black Box
                </Box>
                <Grid container spacing={2}>

                    <Grid item xs={12} md={6}>
                        <FormControl color="secondary" size="small" fullWidth required >
                            <StyledFormLabel>Shuri Device Equipment</StyledFormLabel>
                            <ShuriMuiFormSelect
                                disabled={!!device?.equipment_id}
                                placeholder='Select Equipment'
                                options={equipmentOptions ?? []}
                                value={inputs.equipment_id}
                                handleChange={handleChange}
                                name='equipment_id'/>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>
                        <CreateSiteFormAction
                            primaryLabel={"Save"}
                            secondaryLabel={"Clear"}
                            onSave={handleSubmit}
                            onCancel={() => {
                            }}/>
                    </Grid>

                </Grid>
            </StyledFormRow>
        </form>
    )
})