import {gql, useQuery} from "@apollo/client";
import {perPage} from "../../../../config";

export const INCIDENT_SUSPICIOUS_ACTIVITIES_QUERY = gql`
    query INCIDENT_SUSPICIOUS_ACTIVITIES_QUERY(
        $incident_id: bigint,
        $limit: Int
        $offset: Int) {
        core_logsuspiciosactivity(where: {incident_id: {_eq: $incident_id}}, limit: $limit, offset: $offset) {
            camera_id
            confidence
            created_on
            description
            distance
            id
            image
            incident_id
            is_suspicious
            label
            media
            core_camera {
                camera_name
                camera_id
            }
            core_incident {
                site_id
                id
            }
        }
        core_logsuspiciosactivity_aggregate(where: {incident_id: {_eq: $incident_id}}) {
            aggregate {
                count
            }
        }
    }
`;

export const useIncidentSuspiciousActivities = (incident_id?: number, page = 1, limit = perPage) => {
    const offset = page * limit;
    const {data, error, loading, refetch} = useQuery(INCIDENT_SUSPICIOUS_ACTIVITIES_QUERY, {
        variables: {
            offset,
            limit,
            incident_id
        },
        skip: !incident_id,
    });

    return {data, error, loading, refetch};
}