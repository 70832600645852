import {
  Button,
  Card,
  CardContent,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import { MetricsCardBackground, MetricsCardTitleColor , errorBase} from "../../../config";
import { getTimeFromDate } from "../../../../lib";

interface DisruptionValueProp {
  start_date: String;
  end_date: String;
}
interface DisruptionsTileProps {

  title: string;

  count: number;

  value: DisruptionValueProp[];


  icon: React.ReactNode;

  dark?: boolean;
}

export function DisruptionsTile(props: DisruptionsTileProps) {
  return (
    <Button sx={{ padding: 0, width: "100%" }}>
      <Card
        sx={{
          width: "100%",
          height: 170,
          borderRadius: "8px",
          backgroundColor: MetricsCardBackground,
        }}
      >
        <CardContent sx={{ margin: 0, padding: 1, paddingTop: 0 }}>
          <Stack rowGap={1}>
          <Box
              sx={{
                display: "flex",
                width: "100%",
                height: 20,
                color: "white",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Typography
                sx={{
                  fontSize: 14,
                  textTransform: "capitalize",
                  color: MetricsCardTitleColor,
                }}
                gutterBottom
              >
                {props.title}:
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  textTransform: "capitalize",
                  color: errorBase,
                  marginLeft: "20px", 
                }}
                gutterBottom
              >
                Stage {props.count}
              </Typography>
            </Box>
            {props.value?.map((disruption, index: number) => (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "left",
                }}
                key={index}
              >
                <Typography
                  sx={{ fontSize: "14px", color: errorBase }}
                  gutterBottom
                >
                  {getTimeFromDate(disruption.start_date.toString())}-
                  {getTimeFromDate(disruption.end_date.toString())}
                </Typography>
              </Box>
            ))}
          </Stack>
        </CardContent>
      </Card>
    </Button>
  );
}
