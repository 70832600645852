import {Box, CircularProgress, Typography} from "@mui/material";

interface IShuriSpinnerTextProps {
    loadingText?: string;
    loadingTextSx?: object
}
export function ShuriSpinnerText({loadingText = 'Loading please wait...', loadingTextSx={}}: IShuriSpinnerTextProps)
{
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%'
        }}>
            <CircularProgress
                size="20px"
            />
            <Typography sx={{
                marginLeft: '10px',
                ...loadingTextSx
            }}>
                {loadingText}
            </Typography>
        </Box>
    )
}