import { Button, Card, CardContent, Box, Typography, Stack } from "@mui/material";
import { MetricsCardBackground, MetricsCardTitleColor } from "../../../config";
import {useNavigate} from "react-router-dom";

interface StandardTileProps {
    /**
     * Usage tile title
     */
    title: string,

    /**
     * Usage tile amount
     */
    value: number,

    /**
     * Usage tile unit of measure e.g. ZAR, kWh, etc
     */
    icon: React.ReactNode,

    dark?: boolean,

    path?: string
}

export function StandardTile(props: StandardTileProps) {
    const navigate = useNavigate();

    return (
        <Button
            onClick={()=>navigate(props.path ?? '/')}
            sx={{
                padding: 0,
                width: "100%",
                transition: 'all 0.2s ease',
                '&:hover': {
                    marginLeft: '2px'
                }
            }}>
            <Card sx={{ width: '100%', height: 170, borderRadius: '8px', backgroundColor: MetricsCardBackground }}>
                <CardContent sx={{ margin: 0, padding: 1, paddingTop: 0 }}>
                  <Stack rowGap={4}>
                  <Box sx={{ display: 'flex', width: '100%', height: 40, color: 'white', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
                        <Typography sx={{ fontSize: 14, textTransform: 'capitalize', color: MetricsCardTitleColor }} gutterBottom>
                            {props.title}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', width: '100%',justifyContent: 'left' }}>
                        <Typography sx={{ fontSize: 25, color: 'white' }} gutterBottom>
                            {props.value}
                        </Typography>
                    </Box>
                  </Stack>
                </CardContent>
            </Card>
        </Button>
    );
}
