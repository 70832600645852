import {ICamera} from "../../../lib/interfaces";
import {Grid} from "@mui/material";
import {CameraDisplay} from "./camera-display";
import {Core_Camera, Core_Site} from "../../../generated/graphql";

interface ICamerasContainerProps {
    cameras: Core_Camera[] | ICamera[]
    site: Core_Site
}

export function CamerasContainer({cameras, site}: ICamerasContainerProps) {
    const size = cameras.length > 1 ? 6 : 12;
    return (
        <Grid container spacing={2}>
            {
                cameras.map((camera, index) => (
                    <Grid md={size} xs={12} item key={`camera-container-grid-${camera.camera_id}`}>
                        <CameraDisplay
                            site={site}
                            camera={camera}/>
                    </Grid>
                ))
            }
        </Grid>
    )
}