import {Table, TableBody, TableFooter, TableHead, TableRow} from "@mui/material";
import {ShuriTableRowStates, StyledTableCell, StyledTableRow} from "../table";
import {formatDate} from "../../../lib/filters";
import ShuriTablePagination from "../table/ShuriTablePagination";
import {ITablePropsInterface} from "../../../lib/interfaces";

interface IUnplannedOutagesTableProps extends ITablePropsInterface{}
export default function UnplannedOutagesTable({
                                                  data,
                                                  page,
                                                  rowsPerPage,
                                                  handleChangePage,
                                                  loading,
                                                  error
                                              }: IUnplannedOutagesTableProps) {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <StyledTableCell>Date / Time</StyledTableCell>
                    <StyledTableCell>Site Name</StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                    <StyledTableCell>Root Cause</StyledTableCell>
                    <StyledTableCell>Type</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <ShuriTableRowStates
                    colSpan={5}
                    data={data?.core_disruptions ?? []}
                    loading={loading}
                    error={error}
                >
                    <>
                        {
                            data?.core_disruptions?.map((disruption: any) => (
                                <StyledTableRow key={disruption.id} sx={{
                                    color: 'white'
                                }}>
                                    <StyledTableCell component="th" scope="row">
                                        {disruption?.start_date? formatDate(disruption?.start_date) : '-'}
                                    </StyledTableCell>
                                    <StyledTableCell>{disruption?.core_site?.site_id ?? '-'} - {disruption?.core_site?.name ?? '-'}</StyledTableCell>
                                    <StyledTableCell>{disruption?.description ?? '-'}</StyledTableCell>
                                    <StyledTableCell>{disruption?.root_cause}</StyledTableCell>
                                    <StyledTableCell>{disruption?.core_disruptionstype?.name ?? '-'}</StyledTableCell>
                                </StyledTableRow>
                            ))
                        }
                    </>
                </ShuriTableRowStates>
            </TableBody>

            {
                (data?.core_disruptions_aggregate?.aggregate?.count || 0) > rowsPerPage &&
                <TableFooter>
                    <TableRow sx={{border: 0, m: 1}}>
                        <ShuriTablePagination
                            colSpan={5}
                            count={data?.core_disruptions_aggregate?.aggregate?.count ?? 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    </TableRow>
                </TableFooter>
            }
        </Table>
    )
}