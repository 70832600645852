import gql from "graphql-tag";
import {useQuery} from "@apollo/client";

export const COMPANY_QUERY = gql`
    query COMPANY_QUERY {
        core_company {
            name
            id
            phone
            email
            created_on
            address
        }
    }
`;

export const useCompany = () => {
    const {data, error, loading} = useQuery(COMPANY_QUERY);
    return {data, error, loading};
}