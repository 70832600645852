import {AddMoreCamera, CameraDisplay} from "../../camera";
import {ICameraStream, LayoutEnum, useCameraStreamsContext} from "../../../../lib";
import {Box, Grid} from "@mui/material";
import {useEffect, useState} from "react";

interface ICameraStreamsGridProps {
    cameras: ICameraStream[]
}

export function CameraStreamsGrid({cameras}: ICameraStreamsGridProps) {
    const initialSize = 6;
    const [size, setSize] = useState(initialSize);
    const {toggleOpenForm, preview, setPreview, layout, handleRemoveStream, deletingStream} = useCameraStreamsContext();

    useEffect(() => {
        let definedSize: number;
        switch (layout) {
            case LayoutEnum.TILED4:
                definedSize = 6;
                setPreview(undefined)
                break;
            case LayoutEnum.TILED9:
                definedSize = 4;
                setPreview(undefined)
                break;
            case LayoutEnum.HIGHLIGHT:
                definedSize = 4;
                if (cameras.length > 0) {
                    setPreview(cameras[0])
                }
                break;
            default:
                definedSize = size
        }
        setSize(definedSize);
    }, [layout])

    const handlePreview = (camera: ICameraStream) => {
        if (layout !== LayoutEnum.HIGHLIGHT) return;
        setPreview(camera)
    }
    return (
        <>

            {layout === LayoutEnum.HIGHLIGHT && preview && (
                <Grid container spacing={2} sx={{
                    marginBottom: '20px'
                }}>
                    <Grid md={12} xs={12} item>
                        <CameraDisplay
                            has_delete
                            deletingStream={deletingStream}
                            onDelete={handleRemoveStream}
                            site={preview.core_site}
                            camera={preview.core_camera}/>
                    </Grid>
                </Grid>
            )}

            <Box>
                <Grid container spacing={2}>
                    {
                        cameras.map((camera, index) => {
                            if (camera?.core_camera?.camera_id !== preview?.core_camera?.camera_id){
                                return (
                                    <Grid
                                        onClick={() => handlePreview(camera)}
                                        sx={{
                                            cursor: 'pointer'
                                        }}
                                        md={size}
                                        xs={12}
                                        item
                                        key={`camera-container-grid-${camera.core_camera.camera_id}`}>
                                        <CameraDisplay
                                            has_delete
                                            deletingStream={deletingStream}
                                            onDelete={handleRemoveStream}
                                            site={camera.core_site}
                                            camera={camera.core_camera}/>
                                    </Grid>
                                )
                            }
                        })
                    }
                    <Grid md={size} xs={12} item key={`camera-container-grid-add-new`}>
                                <AddMoreCamera
                                    onClickAction={() => toggleOpenForm(true)}
                                    sub=""
                                    title="Add Camera Stream"/>
                    </Grid>

                </Grid>
            </Box>
        </>
    )
}