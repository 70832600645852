import {ShuriStyledDrawer} from "../../../drawer";
import {
    ADD_VISIT_MUTATION,
    UPDATE_VISIT_MUTATION,
    useJobsContext,
    useNotification,
    useVisitDetails
} from "../../../../../lib";
import {useYupValidateForm} from "../../../../../lib/form/hooks";
import {AddJobVisitForm, IVisitInput, mapVisitDetailsInput, validationSchema} from "../../create-job/add-visit-details";
import {Box, Button, Grid, IconButton, Typography} from "@mui/material";
import {BaseColorLight, ButtonBaseColor, ButtonBaseColorLight, InputBaseColorLight} from "../../../../config";
import {Close} from "@mui/icons-material";
import * as React from "react";
import {useEffect, useState} from "react";
import {useMutation} from "@apollo/client";

interface ICreateVisitProps {
    onRefresh: ()=> void
}
export function CreateVisit({onRefresh}: ICreateVisitProps) {
    const [jobId, setJobId] = useState<number | string>();
    const {
        createVisitOpen,
        visitStatus,
        setCreateVisitOpen,
        jobsOptions,
        visitJobId,
        editVisitId,
    } = useJobsContext()
    const {data: visitData, handleRefetch} = useVisitDetails(editVisitId);
    const [inputs, setInputs] = useState<IVisitInput>({
        job_id: visitJobId ?? '',
        status_id: '',
        comment: '',
        start_datetime: '',
        end_datetime: '',
    });
    const {errors, validateField, hasErrors, validateForm} = useYupValidateForm(inputs, validationSchema);
    const [addVisitMutation, {error: createError}] = useMutation(ADD_VISIT_MUTATION);
    const [updateVisitMutation, {error: updateError}] = useMutation(UPDATE_VISIT_MUTATION);
    const {notify} = useNotification();

    useEffect(() => {
        if (visitJobId) {
            setInputs((prevInputs) => ({
                ...prevInputs,
                job_id: visitJobId,
            }));

            setJobId(visitJobId);
        }
    }, [visitJobId]);

    useEffect(() => {
        if (visitData?.core_visit_by_pk) {
            setJobId(visitData?.core_visit_by_pk?.job_id);
            setInputs((prevInputs) => ({
                ...prevInputs,
                ...mapVisitDetailsInput(visitData.core_visit_by_pk),
            }));
        }

    }, [visitData]);

    useEffect(() => {
        if (createError) {
            notify({
                status: "error",
                open: true,
                message: 'An error occurred trying to create visit, try again later!',
            });
        }
        if (updateError) {
            notify({
                status: "error",
                open: true,
                message: 'An error occurred trying to update visit, try again later!',
            });
        }
    }, [createError, updateError])


    const handleVisitUpdate = (index: number, visit: IVisitInput) => {
        setInputs(visit);
    }

    const handleSubmit = async () => {
        if (await validateForm()) {
            const {
                job_id,
                status_id,
                end_datetime,
                start_datetime,
                comment
            } = inputs;
            if (!editVisitId) {
                await addVisitMutation({
                    variables: {
                        job_id,
                        status_id,
                        end_datetime,
                        start_datetime,
                        comment
                    },
                    onCompleted(createdVisitData) {
                        if (createdVisitData) {
                            onRefresh();
                            setCreateVisitOpen(false)
                            notify({
                                status: "success",
                                open: true,
                                message: "Visit created successfully!",
                            });
                        }
                    },
                    onError(error) {
                        notify({
                            status: "error",
                            open: true,
                            message: 'An error occurred trying to create visit, try again later!',
                        });
                    }
                })
            } else {
                await updateVisitMutation({
                    variables: {
                        visit_id: editVisitId,
                        status_id,
                        end_datetime,
                        start_datetime,
                        comment
                    },
                    onCompleted(updatedJobData) {
                        if (updatedJobData) {
                            handleRefetch();
                            onRefresh();
                            setCreateVisitOpen(false)
                            notify({
                                status: "success",
                                open: true,
                                message: "Visit updated successfully!",
                            });
                        }
                    },
                    onError(error) {
                        notify({
                            status: "error",
                            open: true,
                            message: 'An error occurred trying to create visit, try again later!',
                        });
                    }
                })
            }
        }
    }

    return (
        <ShuriStyledDrawer
            anchor="right"
            open={createVisitOpen}
            onClose={() => setCreateVisitOpen(false)}
        >
            <Box className="shuri-drawer-wrapper">
                <Box className="shuri-drawer-content">
                    <Grid container spacing={2} sx={{
                        paddingBottom: '30px'
                    }}>
                        <Grid item xs={10}>
                            <Typography sx={{
                                fontFamily: 'Outfit',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '18px',
                                lineHeight: '28px',
                                color: InputBaseColorLight
                            }}>Create Visit </Typography>
                            <Typography sx={{
                                fontFamily: 'Outfit',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '20px',
                                color: BaseColorLight
                            }}>Fill the form below to create a new site visit</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                        }}>
                            <IconButton aria-label="delete" size="large" color="secondary"
                                        onClick={() => setCreateVisitOpen(false)}>
                                <Close fontSize="inherit"/>
                            </IconButton>
                        </Grid>
                    </Grid>

                    <AddJobVisitForm
                        isCreate={true}
                        jobId={jobId}
                        onDelete={(_) => {
                        }}
                        visit={inputs}
                        status={visitStatus ?? []}
                        jobs={jobsOptions ?? []}
                        index={0}
                        formErrors={errors}
                        onInputChange={handleVisitUpdate}
                        validateInputField={validateField}
                    />
                </Box>

                <Box className="shuri-drawer-actions">
                    <Button
                        onClick={() => setCreateVisitOpen(false)}
                        sx={{
                            color: ButtonBaseColorLight,
                            borderColor: ButtonBaseColor,
                            textTransform: 'capitalize',
                        }}
                        variant="outlined"
                        size="large">
                        Cancel
                    </Button>

                    <Button
                        sx={{
                            textTransform: 'capitalize',
                        }}
                        disabled={hasErrors}
                        onClick={() => handleSubmit()}
                        color="info"
                        variant="contained"
                        size="large">
                        Save Visit
                    </Button>
                </Box>
            </Box>


        </ShuriStyledDrawer>
    )
}