import {ICameraInput, IEquipmentInput} from "./create-site.interface";


export const initialCameraInput: ICameraInput = {
    camera_name: '',
    box_id: undefined,
    camera_id: '',
    video_stream_name: '',
    gen_key: ''
};

export const initialEquipmentInput: IEquipmentInput = {
    type_id: '',
    equipment_name: '',
    model_id: '',
    owner: '',
    gen_key: ''
};