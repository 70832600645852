import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

export const SITES_SECURITY_CALLOUTS = gql`
  query MyQuery($id: bigint!) {
    core_securitycallouts(where: { site_id: { _eq: $id } }) {
      classification
      call_out_id
      closed_on
      created_on
      id
      latitude
      longitude
      site_id
      type_of_emergency
      core_securitycalloutsstatuses(limit: 1, order_by: {id: desc_nulls_last}) {
        id
        status
      }
    }
  }
`;

export const useGetSecurityCallouts = (id: number) => {
  const { data, error, loading, refetch } = useQuery(SITES_SECURITY_CALLOUTS, {
    variables: {
      id,
    },
  });
  const updateCallouts = async () => {
    await refetch();
  };
  return { data, error, loading,updateCallouts };
};
