import {Box, Button, Typography} from "@mui/material";
import {ShuriLiveStreamPlayer} from "../video";
import React from "react";
import {InputBaseColor, InputBaseColorLight, MetricsCardBackground} from "../../config";
import {useNavigate, useParams} from "react-router-dom";

export function AddCamera() {
    const navigate = useNavigate();
    const {id} = useParams();

    return (
        <Box style={{
            position: 'relative'
        }}>
            <Box sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 10,
                backgroundColor: MetricsCardBackground,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '8px'
            }}>
                <Box sx={{
                    marginBottom: '30px'
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                         width="68"
                         height="68"
                         fill={InputBaseColorLight}
                         viewBox="0 0 256 256">
                        <path
                            d="M251.77,73a8,8,0,0,0-8.21.39L208,97.05V72a16,16,0,0,0-16-16H113.06a8,8,0,0,0,0,16H192v87.63a8,8,0,0,0,16,0V159l35.56,23.71A8,8,0,0,0,248,184a8,8,0,0,0,8-8V80A8,8,0,0,0,251.77,73ZM240,161.05l-32-21.33V116.28L240,95ZM53.92,34.62A8,8,0,1,0,42.08,45.38L51.73,56H32A16,16,0,0,0,16,72V184a16,16,0,0,0,16,16H182.64l19.44,21.38a8,8,0,1,0,11.84-10.76ZM32,184V72H66.28L168.1,184Z"></path>
                    </svg>
                </Box>
                <Typography
                    sx={{
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontSize: '24px',
                        lineHeight: '38px',
                        textAlign: 'center',
                        color: '#FFFFFF',
                        marginBottom: '10px'
                    }}
                >No cameras configured</Typography>
                <Typography
                    sx={{
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontSize: '14px',
                        lineHeight: '20px',
                        textAlign: 'center',
                        color: InputBaseColor,
                        marginBottom: '16px'
                    }}
                >There are no cameras configured for this site. Would you like to add a camera?</Typography>

                <Button variant="contained" color="info" onClick={() => navigate(`/site/${id}/edit`)}>Add Camera</Button>

            </Box>
            <ShuriLiveStreamPlayer
                empty={true}
                key={''}
                h={'350px'}
                url={''}
                onLoaded={() => {
                }}/>
        </Box>
    )
}