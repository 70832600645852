import {
    Content,
    PageAppBar,
    StyledPageContainer,
    CreateSiteForm
} from "../../../../components";

export const CreateSitePage = () => {
    return (
        <>
            <PageAppBar
                title="Create Site"
                sub="Complete the form below to create a new site"
            />
            <Content>
                <StyledPageContainer>
                    <CreateSiteForm />
                </StyledPageContainer>
            </Content>
        </>
    )
}