import {gql} from "apollo-boost";

export const ADD_METER_MUTATION = gql`
    mutation(
        $objects: [core_meter_insert_input!]!
    ){
        insert_core_meter(
            objects: $objects
            on_conflict: {
                constraint: core_meter_id_key
                update_columns: [
                    serial_number
                ]
            }
        ){
            returning {
                id
                box_id
                serial_number
            }
        }
    }
`;

export const UPDATE_METER_MUTATION = gql`
    mutation(
        $id: bigint!
        $serial_number: String
    ){
        update_core_meter_by_pk(
            pk_columns: { id: $id }
            _set: {
                serial_number: $serial_number
            }
        ){
            id
            box_id
            serial_number
        }
    }
`;