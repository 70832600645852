import { alpha, InputBase, styled } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {BaseColorLight} from "../../config";
import React, {ChangeEvent, ChangeEventHandler} from "react";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: BaseColorLight,
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: 300,
    // [theme.breakpoints.up('sm')]: {
    //     marginLeft: theme.spacing(1),
    //     width: 'auto',
    // },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        maxWidth: 300,
        minWidth: 200
        // [theme.breakpoints.up('sm')]: {
        //     width: '12ch',
        //     '&:focus': {
        //         width: '20ch',
        //     },
        // },
    },
}));

interface ISearchProps {
    placeholder: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onClick?: React.MouseEventHandler<HTMLElement>;
    id?: string;
    value?: string;
}

export function SearchBox({ placeholder, onChange, onClick, id, value }: ISearchProps) {
    return <Search className={'shuri-search'}>
        <SearchIconWrapper>
            <SearchIcon sx={{
                color: BaseColorLight
            }} />
        </SearchIconWrapper>
        <StyledInputBase
            placeholder={placeholder}
            inputProps={{ 'aria-label': 'search' }}
            onChange={onChange}
            onClick={onClick}
            aria-describedby={id}
            value={value}
        />
    </Search>
}
