import { Button, Card, CardContent, Box, Typography, Stack } from "@mui/material";
import { UnitOfMeasures } from "../../../../enums";
import { formatAmount } from "../../../../utils";
import { PercentageChangeIcon } from "../../percentage-change-icon";
import {
  MetricsCardBackground,
  MetricsCardTitleColor,
} from "../../../config";
import {useNavigate} from "react-router-dom";
interface UsageTileProps {
  /**
   * Usage tile title
   */
  title: string;

  /**
   * Usage tile amount
   */
  amount: number;

  /**
   * Usage tile unit of measure e.g. ZAR, kWh, etc
   */
  unitOfMeasure?: UnitOfMeasures;

  /**
   * Usage tile percentage change value
   */
  percentageChange?: number;

  dark?: boolean;

  path?: string;
}

export function UsageTile(props: UsageTileProps) {
    const navigate = useNavigate();

    return (
    <Button
        onClick={()=>navigate(props.path ?? '/')}
        sx={{
        padding: 0,
        width: "100%",
        transition: 'all 0.2s ease',
        '&:hover': {
            marginLeft: '2px'
        }
    }}>
      <Card
        sx={{
          width: "100%",
          height: 170,
          borderRadius: "8px",
          backgroundColor: MetricsCardBackground,
        }}
      >
        <CardContent sx={{ margin: 0, padding: 1, paddingTop: 0 }}>
          <Stack rowGap={4}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: 40,
              color: "white",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize:{sm:14,md:14,lg:14,xl:12},
                textTransform: "capitalize",
                color: MetricsCardTitleColor,
              }}
              gutterBottom
            >
              {props.title}
            </Typography>

            {props.percentageChange && (
              <Box
                sx={{
                  background: "#1F1F20",
                  padding: "0 20px",
                  borderRadius: "16px",
                }}
              >
                <PercentageChangeIcon value={props.percentageChange} />
              </Box>
            )}
          </Box>

          <Box sx={{ display: "flex", width: "100%",justifyContent:'left' }}>
            {props.unitOfMeasure && (
              <Typography sx={{fontSize:{md:20,lg:25,xl:25}, color: "white" , marginRight: '5px'}} gutterBottom>
                {props.unitOfMeasure} 
              </Typography>
            )}
            <Typography sx={{ fontSize:{md:20,lg:25,xl:25}, color: "white" }} gutterBottom>
              {formatAmount(props.amount)}
            </Typography>
          </Box>
          </Stack>
        </CardContent>
      </Card>
    </Button>
  );
}
