import { ButtonGroup, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IJob, useJobsContext } from "../../../../lib";
import { InputBaseColor } from "../../../config";
import {RemoveJobAction} from "./job-actions/remove-job-action";

interface IJobTableActionsProps {
  job: IJob;
  onRefreshJobs: () => void;
}
export function JobTableActions({ job, onRefreshJobs }: IJobTableActionsProps) {
  const navigate = useNavigate();
  const { onVisitCreateOpen } = useJobsContext();

  return (
    <ButtonGroup
      size="small"
      disableElevation
      variant="text"
      aria-label="Job Table Action Buttons"
    >
      <IconButton
        onClick={() => onVisitCreateOpen(undefined, job.id)}
        aria-label="Create Visit"
        size="small"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill={InputBaseColor}
          viewBox="0 0 256 256"
        >
          <path d="M168,152a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,152Zm-8-40H96a8,8,0,0,0,0,16h64a8,8,0,0,0,0-16Zm56-64V216a16,16,0,0,1-16,16H56a16,16,0,0,1-16-16V48A16,16,0,0,1,56,32H92.26a47.92,47.92,0,0,1,71.48,0H200A16,16,0,0,1,216,48ZM96,64h64a32,32,0,0,0-64,0ZM200,48H173.25A47.93,47.93,0,0,1,176,64v8a8,8,0,0,1-8,8H88a8,8,0,0,1-8-8V64a47.93,47.93,0,0,1,2.75-16H56V216H200Z"></path>
        </svg>
      </IconButton>
      <IconButton
        onClick={() => navigate(`/jobs/${job.id}/edit`)}
        aria-label="Edit Job"
        size="small"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill={InputBaseColor}
          viewBox="0 0 256 256"
        >
          <path d="M227.31,73.37,182.63,28.68a16,16,0,0,0-22.63,0L36.69,152A15.86,15.86,0,0,0,32,163.31V208a16,16,0,0,0,16,16H92.69A15.86,15.86,0,0,0,104,219.31L227.31,96a16,16,0,0,0,0-22.63ZM51.31,160,136,75.31,152.69,92,68,176.68ZM48,179.31,76.69,208H48Zm48,25.38L79.31,188,164,103.31,180.69,120Zm96-96L147.31,64l24-24L216,84.68Z"></path>
        </svg>
      </IconButton>

      <RemoveJobAction
          job={job}
          onClose={()=>{}}
          onRefreshJobs={()=> onRefreshJobs ? onRefreshJobs() : () => {}}
      />
    </ButtonGroup>
  );
}
