import {Box, Grid} from "@mui/material";
import {IDetectionFilters, useEventLogContext, useShuriPagination} from "../../../../../lib";
import {useEffect, useState} from "react";
import {DetectionsTable} from "../../../detection";
import {useDetections} from "../../../../../lib/hooks/detection";

export function EventLogDetection() {
    const {eventlog} = useEventLogContext();
    const {page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = useShuriPagination(4);
    const [detectionFilters, setDetectionFilters] = useState<IDetectionFilters>();

    const {
        data: detections,
        refetch: refreshDetections
    } = useDetections(detectionFilters, page, rowsPerPage)

    useEffect(() => {
        if (Object.keys(eventlog).length !== 0) {
            refreshDetections()

            setDetectionFilters({
                site_id: eventlog.site_id,
                created_at: eventlog.created_at,
                event_log_type: eventlog.event_log_type
            });
        }
    }, [eventlog, refreshDetections]);

    return (
        <Grid container spacing={2} sx={{
            paddingTop: '20px'
        }}>
            <Grid item xs={12}>
                <Box className="shuri-card-title sub">
                    Detections
                </Box>
                <DetectionsTable
                    data={detections}
                    handleChangePage={handleChangePage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
    )
}