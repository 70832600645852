import {StyledPageCard} from "../../page";
import {ActiveCallouts,PreviosCallouts} from ".";

export function ArmedResponseDetails() {
    return (
        <StyledPageCard>
            <ActiveCallouts/>
            <PreviosCallouts/>
        </StyledPageCard>
    )
}