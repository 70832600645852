import { Box, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import Shuri from "../../assets/Shuri-White.png";
import TowerBackground from "../../assets/background.png";

type Props = PropsWithChildren & {
  title: String;
};

const AuthLayout = ({ children, title }: Props) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${TowerBackground})`
      }}
    >
      <Box marginLeft="160px" width="360px">
        <Box
          display="flex"
          marginBottom="20px"
          width="100%"
          justifyContent="center"
        >
          <img
            alt="Shuri Logo"
            src={Shuri}
            style={{
              objectFit: "contain"
            }}
          />
        </Box>
        <Typography color="#F9F9F9" fontWeight="bold" fontSize={28}>
          {title}
        </Typography>
        {children}
      </Box>
    </Box>
  );
};

export default AuthLayout;
