import {useParams} from "react-router-dom";
import {useShuriPagination, useSiteDisruptions} from "../../../../lib/hooks";
import {getCurrentDate} from "../../../../lib/filters";
import UnplannedOutagesTable from "../../unplanned-outages/unplanned-outages-table";

export default function SiteUnplannedOutages()
{
    const {id} = useParams();
    const {page, rowsPerPage, handleChangePage } = useShuriPagination(4);
    const date = getCurrentDate();
    const {data, error, loading} = useSiteDisruptions(id, date, page, rowsPerPage);

    return (
        <UnplannedOutagesTable
            data={data}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            error={error}
            loading={loading}
        />
    )
}