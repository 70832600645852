import gql from "graphql-tag";

export const ADD_CAMERA_MUTATION = gql`
    mutation (
        $objects: [core_camera_insert_input!]!
    ) {
        insert_core_camera(
            objects: $objects
            on_conflict: {
                constraint:  core_camera_pkey
                update_columns: [
                    camera_name
                    video_stream_name
                ]
            }
        ) {
            returning {
                camera_id
                camera_name
                box_id
                audio_stream_uri
                ip_address
                video_stream_name
                video_stream_uri
                core_box {
                    core_site {
                        name
                        site_id
                        id
                    }
                }
            }
        }
    }
`;

export const UPDATE_CAMERA_MUTATION = gql`
    mutation UPDATE_VISIT_MUTATION(
        $camera_id: String!
        $camera_name: String
        $box_id: bigint
        $video_stream_name: String
    ) {
        update_core_camera_by_pk(
            pk_columns: { camera_id: $camera_id }
            _set: {
                camera_name: $camera_name
                video_stream_name: $video_stream_name
            }
        ) {
            camera_id
            camera_name
            box_id
            audio_stream_uri
            ip_address
            video_stream_name
            video_stream_uri
            core_box {
                core_site {
                    name
                    site_id
                    id
                }
            }
        }

    }
`;