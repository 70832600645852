import {SearchBox} from "../search-box";
import React, {ChangeEvent, useEffect, useState} from "react";
import {
    Alert, AlertTitle,
    Box, Card, CardContent,
    Checkbox,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popper, Typography
} from "@mui/material";
import {AppDarkBackgroundColor, BaseColor, BaseColorLight} from "../../config";
import {Close} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import {IFilterOption, ISearchFilterProps} from "../../../lib/interfaces";
import {useNavigate} from "react-router-dom";
import {ShuriSpinnerText} from "../loading";

const StyledTitle = styled(Typography)(({theme}) => ({
    color: 'white',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px'
}));

const StyledCard = styled(Card)(() => ({
    width: '100%',
    padding: '8px',
    border: '0.2px solid',
    borderColor: BaseColor,
    borderRadius: '4px',
    minHeight: '86px'
}));

interface IShuriTableFilterSearchProps extends ISearchFilterProps {
    filter_options: IFilterOption[]
}

export function ShuriTableFilterSearch({filter_options, onFilterUpdate, results, loading, error}: IShuriTableFilterSearchProps) {
    const [filterValue, setFilterValue] = useState<string>('');
    const [filterOptions, setFilterOptions] = useState<string[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const navigate = useNavigate();

    useEffect(() => {
        handleUpdate();
    }, [filterValue, filterOptions])

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (anchorEl) return;
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFilterValue(event.target.value);
    }

    const handleFilterChange = (value: string) => () => {
        const isChecked = filterOptions.includes(value);
        if (!isChecked) {
            setFilterOptions([...filterOptions, value]);
        } else {
            setFilterOptions(filterOptions.filter((item) => item !== value));
        }
    };

    const handleUpdate = () => {
        const mappedFilters: Record<string, string> = filterOptions.reduce((acc: Record<string, string>, key) => {
            acc[key] = filterValue;
            return acc;
        }, {});

        const filter = {
            value: filterValue,
            filters: filterOptions,
            mappedFilters
        };

        if (filterOptions.length > 0 || filterValue === '') {
            onFilterUpdate(filter);
        }
    }

    const RenderResults = () => {
        if (results.length > 0 && filterOptions.length > 0 && filterValue) {
            return (
                <>
                    {
                        results.map((res, index) => (
                            <ListItem key={`search-filter-${res.unique}-${index}`}
                                      sx={{
                                          padding: 0,
                                          margin: 0,
                                          marginBottom: '8px'
                                      }}
                            >
                                <ListItemButton
                                    onClick={() => {
                                        navigate(res.link)
                                    }}
                                    sx={{
                                        padding: 0,
                                        margin: 0
                                    }}>
                                    <StyledCard
                                        raised={false}
                                        sx={{
                                            width: '100%',
                                            color: 'white'
                                        }}>
                                        <CardContent>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center'
                                                }}>
                                                <Typography variant="subtitle1" component="div" sx={{
                                                    marginLeft: '5px',
                                                    fontWeight: '700',
                                                    lineHeight: '18px',
                                                    fontSize: '12px'
                                                }}>
                                                    {res.title}
                                                </Typography>
                                            </Box>

                                            {
                                                res.sub1 &&
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    lineHeight: '18px',
                                                    fontSize: '12px'
                                                }}>
                                                    <IconButton color="info" size="small">
                                                        {
                                                            res.sub1.icon
                                                        }
                                                    </IconButton>
                                                    <Typography
                                                        sx={{
                                                            lineHeight: '18px',
                                                            fontSize: '12px'
                                                        }}
                                                        variant="body1">{res.sub1.label}</Typography>
                                                </Box>
                                            }

                                            {
                                                res.sub2 &&
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    lineHeight: '18px',
                                                    fontSize: '12px'
                                                }}>
                                                    <IconButton color="info" size="small">
                                                        {
                                                            res.sub2.icon
                                                        }
                                                    </IconButton>
                                                    <Typography
                                                        sx={{
                                                            lineHeight: '18px',
                                                            fontSize: '12px'
                                                        }}
                                                        variant="body1">{res.sub2.label}</Typography>
                                                </Box>
                                            }
                                        </CardContent>
                                    </StyledCard>
                                </ListItemButton>
                            </ListItem>
                        ))
                    }
                </>
            );
        }
        return (
            <ListItem sx={{
                height: '100%',
                padding: 0,
                margin: 0
            }}>
                <StyledCard
                    raised={false}
                    sx={{
                        width: '100%',
                        height: '100%'
                    }}>
                    <CardContent
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            height: '100%',
                            textAlign: 'center'
                        }}>
                        <svg width="41" height="30" viewBox="0 0 41 30" fill="none" xmlns="http://www.w3.org/2000/svg"
                             style={{marginBottom: '16px'}}>
                            <path
                                d="M6.12341 29.2884H35.1023C38.6011 29.2884 40.359 27.5474 40.359 24.0987V14.188C40.359 12.6813 40.1413 11.9279 39.4048 11.0072L33.6123 3.85871C31.6535 1.44807 30.783 0.711426 27.8198 0.711426H13.4058C10.4426 0.711426 9.57206 1.44807 7.61334 3.85871L1.82091 11.0072C1.08434 11.9279 0.866699 12.6813 0.866699 14.188V24.0987C0.866699 27.5641 2.64127 29.2884 6.12341 29.2884ZM20.6044 18.5909C18.2439 18.5909 16.7205 16.5317 16.7205 14.5898V14.5396C16.7205 13.8364 16.302 13.1835 15.4482 13.1835H4.39906C3.84663 13.1835 3.72941 12.6981 4.01406 12.3465L10.225 4.51164C11.062 3.47371 11.9995 3.05521 13.2216 3.05521H28.004C29.2261 3.05521 30.1636 3.47371 31.0007 4.51164L37.2116 12.3465C37.4961 12.6981 37.3788 13.1835 36.8266 13.1835H25.7774C24.9236 13.1835 24.5051 13.8364 24.5051 14.5396V14.5898C24.5051 16.5317 22.9816 18.5909 20.6044 18.5909ZM6.17363 26.5931C4.49949 26.5931 3.56198 25.7059 3.56198 23.9648V15.6277H14.1758C14.5943 18.7583 17.206 21.0686 20.6044 21.0686C24.0028 21.0686 26.6312 18.7416 27.033 15.6277H37.6634V23.9648C37.6634 25.7059 36.7093 26.5931 35.0519 26.5931H6.17363Z"
                                fill="#F5F5F5"/>
                        </svg>
                        <Typography variant="subtitle1" component="div" color="white" sx={{marginBottom: '16px'}}>
                            Data Not Found
                        </Typography>

                        <Typography color={BaseColorLight}>
                            Please try other filter options or try another keyword.
                        </Typography>
                    </CardContent>
                </StyledCard>
            </ListItem>
        )
    }

    return (
        <>
            <SearchBox
                placeholder="Search"
                onChange={handleInputChange}
                onClick={handleOpen}
                value={filterValue}
                id={id}
            />

            <Popper id={id} open={open} anchorEl={anchorEl}
                    sx={{
                        minWidth: '40vw',
                        borderRadius: '4px',
                        zIndex: '1500',
                        maxHeight: '600px',
                    }}
                    popperOptions={{
                        placement: 'bottom-end'
                    }}
            >
                <Box sx={{
                    borderRadius: '4px',
                    padding: '16px',
                    bgcolor: AppDarkBackgroundColor,
                    width: '100%',
                    color: 'white'
                }}>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <StyledTitle>
                                Filter By
                            </StyledTitle>
                            <IconButton
                                onClick={handleClose}
                                aria-label="close"
                                size="large"
                                sx={{
                                    position: 'absolute',
                                    right: 0,
                                    top: 0,
                                    color: 'white'
                                }}
                            >
                                <Close/>
                            </IconButton>
                            <List>
                                {
                                    filter_options.map(filter => (
                                        <ListItem key={filter.value} sx={{
                                            padding: 0,
                                            margin: 0,
                                            fontWeight: '400',
                                            fontSize: '12px',
                                            lineHeight: '18px'
                                        }}>
                                            <ListItemButton
                                                color="secondary"
                                                role={undefined}
                                                onClick={handleFilterChange(filter.value)}
                                                dense>
                                                <ListItemIcon color="secondary">
                                                    <Checkbox
                                                        sx={{
                                                            color: 'white'
                                                        }}
                                                        color="secondary"
                                                        edge="start"
                                                        checked={filterOptions.includes(filter.value)}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{'aria-labelledby': `checkbox-list-label-${filter.value}`}}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={filter.value} primary={filter.label}/>
                                            </ListItemButton>
                                        </ListItem>
                                    ))
                                }

                            </List>
                        </Grid>

                        <Grid item xs={12} md={8} sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                        }}>
                            <StyledTitle>
                                Filter Results
                            </StyledTitle>

                            <List sx={{
                                flex: 1,
                                maxHeight: '400px',
                                overflowY: 'scroll'
                            }}>

                                {
                                    (loading || error) ?
                                        (
                                            <>
                                                {loading &&
                                                    <ShuriSpinnerText
                                                    loadingText={"Loading search results, please wait..."}
                                                    loadingTextSx={{}}
                                                />
                                                }

                                                {
                                                    error &&
                                                    <Alert severity="error" variant="outlined">
                                                        <AlertTitle>Error</AlertTitle>
                                                        <strong>An error occurred getting search results! Try again later</strong>
                                                    </Alert>
                                                }
                                            </>

                                        ): <RenderResults/>
                                }
                            </List>
                        </Grid>
                    </Grid>
                </Box>
            </Popper>
        </>
    )
}