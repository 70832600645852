import {ITablePropsInterface} from "../../../lib";
import {Chip, Table, TableBody, TableFooter, TableHead, TableRow} from "@mui/material";
import {ShuriTableRowStates, StyledTableCell, StyledTableRow} from "../table";
import ShuriTablePagination from "../table/ShuriTablePagination";
import * as React from "react";
import {formatDate} from "../../../lib";
import {BaseColorLight, MetricsCardBackground, primaryBlue} from "../../config";
import {Link} from "react-router-dom";

interface IEventLogsTable extends ITablePropsInterface {

}

export const logColor = (priority: string) => {
    let _color: 'error' | 'warning' | 'secondary';
    switch (priority) {
        case 'High':
            _color = 'error';
            break;
        case 'Medium':
            _color = 'warning';
            break;
        default:
            _color = 'secondary';
    }
    return _color;
}

export function EventLogsTable({
                                   data,
                                   page,
                                   rowsPerPage,
                                   handleChangePage,
                                   handleChangeRowsPerPage,
                                   loading,
                                   error
                               }: IEventLogsTable) {

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <StyledTableCell>#</StyledTableCell>
                    <StyledTableCell>Event Type</StyledTableCell>
                    <StyledTableCell>Event Name</StyledTableCell>
                    <StyledTableCell>Site Name</StyledTableCell>
                    <StyledTableCell>Date & Time</StyledTableCell>
                    <StyledTableCell>Source</StyledTableCell>
                    <StyledTableCell>Priority</StyledTableCell>
                    <StyledTableCell>Actions</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <ShuriTableRowStates
                    colSpan={8}
                    data={data?.core_eventlog ?? []}
                    loading={loading}
                    error={error}
                >
                    <>
                        {data?.core_eventlog.map((log: any, index: number) => (
                            <StyledTableRow className={(log.viewed_at == null && log.id) ? 'shuri-row-highlighted' : ''} key={log.id} sx={{
                                color: 'white'
                            }}>
                                <StyledTableCell component="th" scope="row">
                                    <Link
                                        style={{
                                            color: primaryBlue,
                                            textDecoration: 'underline',
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                        }}
                                        to={`/event-log/${log.id}`}>EL{log.id}</Link>
                                </StyledTableCell>

                                <StyledTableCell >
                                    {
                                        log.event_log_type
                                    }
                                </StyledTableCell>
                                <StyledTableCell>
                                    {log.event_name}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {log.core_site.name}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {
                                        formatDate(log.created_at)
                                    }
                                </StyledTableCell>
                                <StyledTableCell>
                                    {log.source}
                                </StyledTableCell>
                                <StyledTableCell>

                                    <Chip
                                        sx={{
                                            backgroundColor: MetricsCardBackground,
                                            fontWeight: '500',
                                            fontSize: '12px',
                                            lineHeight: '18px',
                                            border: 'none',
                                            padding: '2px 8px',

                                        }}
                                        label={log.event_priority}
                                        color={logColor(log.event_priority)}
                                        size="small"
                                        variant="outlined"
                                    />

                                </StyledTableCell>
                                <StyledTableCell>
                                    <Link
                                        style={{
                                            color: BaseColorLight,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            textTransform: 'capitalize',
                                            whiteSpace: 'nowrap'
                                        }}
                                        to={`/event-log/${log.id}`}>
                                        View Event
                                    </Link>
                                </StyledTableCell>

                            </StyledTableRow>
                        ))}
                    </>
                </ShuriTableRowStates>
            </TableBody>

            {
                (data?.core_eventlog_aggregate?.aggregate?.count || 0) > rowsPerPage &&
                <TableFooter>
                    <TableRow sx={{border: 0, m: 1}}>
                        <ShuriTablePagination
                            colSpan={8}
                            count={data?.core_eventlog_aggregate?.aggregate?.count ?? 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            }
        </Table>
    )
}