import {perPage} from "../../../config";
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";

export const QUERY_RECTIFIER_ALARMS = gql`
query MyQuery($site_id: bigint, $created_gte: timestamptz, $created_lte: timestamptz, $offset: Int, $limit: Int) {
    core_rectifieralarm(where: {site_id: {_eq: $site_id}, created_on: {_gte: $created_gte, _lte: $created_lte}}, offset: $offset, limit: $limit, order_by: {created_on: desc}) {
      alarm_id
      alarm_name
      alarm_state
      created_on
      id
      site_id
      event_log_id
    }
    core_rectifieralarm_aggregate(where: {site_id: {_eq: $site_id}, created_on: {_gte: $created_gte, _lte: $created_lte}}) {
      aggregate {
        count
      }
    }
  }
  
`;

export interface IRectifierAlarmFilters {
    created_at:any,
    site_id:number
  }

  function getStartAndEndDateTime(inputDate: Date): { created_gte: string, created_lte: string } {
    // Clone the input date to avoid modifying the original object
    const clonedDate = new Date(inputDate);

    // Set the time to the start of the day (midnight)
    clonedDate.setHours(0, 0, 0, 0);

    // Create a new Date object for the next day
    const nextDay = new Date(clonedDate);
    nextDay.setDate(nextDay.getDate() + 1);

    // Set the time to the end of the day (just before midnight)
    nextDay.setMilliseconds(nextDay.getMilliseconds() - 1);

    return {
        created_gte: clonedDate.toISOString(),
        created_lte: nextDay.toISOString()
    };
}


export const useRectifierAlarms = (filters?: IRectifierAlarmFilters, page = 1, limit = perPage) => {
    const offset = page * limit;
    const day = filters?.created_at ? new Date(filters?.created_at) : new Date();
    let dates = getStartAndEndDateTime(day)
    let variabless= {
        limit:limit,
        offset:offset,
        ...dates,
        site_id:filters?.site_id
      }
    const {data, error, loading, refetch} = useQuery(QUERY_RECTIFIER_ALARMS, {
        variables: variabless
    });
    return {data, error, loading, refetch};
}