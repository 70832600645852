import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  parentDiv: {
    width: "100%", 
    height: "100vh", 
    overflow: "hidden", 
  },
  iframe: {
    width: "100%", // Make the iframe fill the entire width of the parent div
    height: "100%", // Make the iframe fill the entire height of the parent div
    objectFit: "cover",
    border: 'none',
  },
}));

export default function ExpandDashboardPopUp(props: {
  isVisible: boolean;
  handleClose: any;
  url: any;
}) {
  const classes = useStyles();
  return (
    <Dialog fullScreen open={props.isVisible}>
      <DialogTitle>
        <IconButton sx={{ float: "right" }} onClick={() => props.handleClose()}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.parentDiv} component={"div"}>
          <Box
            component={"iframe"}
            src={`${props.url}`}
            className={classes.iframe}
          ></Box>
          <Box
            onClick={() => {
              props.handleClose();
            }}
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              zIndex: 2,
              color: "grey",
              marginLeft: 10,
              width: 200,
              cursor: "pointer",
              height: 40,
              padding: 10,
            }}
          >
            {/* this is to disable redirect icon  do not remove*/}.
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
