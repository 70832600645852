import * as React from "react";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
interface IAlertSnackBarProps {
  title: string;
  open: boolean;
  handleToastClose: () => void;
}

export const AlertSnackBar = (props: IAlertSnackBarProps) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={props.open}
      autoHideDuration={6000}
      onClose={props.handleToastClose}
    >
      <Alert severity="success" sx={{ width: "100%" }}>
        {props.title} record created successfully!
      </Alert>
    </Snackbar>
  );
};
