import {gql, useQuery} from "@apollo/client";

export const JOB_STATUS = gql`
    query JOB_STATUS {
        core_jobstatusenum {
            id
            status
        }
    }
`;

export const useJobStatus = () => {
    const {data, error, loading, refetch} = useQuery(JOB_STATUS);
    return {data, error, loading, refetch};
};