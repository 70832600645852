import {useSiteContext} from "../../../../lib/context";
import {Grid} from "@mui/material";
import {MetricsCard} from "../metrics-card";
import {UnitOfMeasures} from "../../../../enums";
import {daysUntil, moneyFilter} from "../../../../lib/filters";

export function TelcomMetrics()
{
    const {site: data} = useSiteContext();
    const latestEstimatePeriodEnd = data?.core_site[0]
        ?.core_sitechargeestimates_aggregate
        ?.aggregate?.max?.charge_period_end
    const latestEstimate = latestEstimatePeriodEnd
        ? data?.core_site[0]?.core_sitechargeestimates_aggregate?.nodes?.find((node: any) => { return node.charge_period_end === latestEstimatePeriodEnd})
        : { cost: 0, total_units: 0 }
    const chargePeriodEnd = data?.core_site[0]?.core_sitechargeestimates[0]?.charge_period_end ?? Date.now();

    return (
        <Grid container spacing={2}>
            <Grid xs={12} item >
                <MetricsCard
                    title="Electric usage"
                    value={`${latestEstimate.total_units ?? 0} ${UnitOfMeasures.KWH}`}
                />
            </Grid>

            <Grid xs={12} item >
                <MetricsCard
                    title="Till outage"
                    value={`${daysUntil(chargePeriodEnd) > 0 ? daysUntil(chargePeriodEnd) : 0} ${UnitOfMeasures.Days}`}
                />
            </Grid>

            <Grid xs={12} item >
                <MetricsCard
                    title="Rand value usage"
                    value={moneyFilter(latestEstimate.cost ?? 0, UnitOfMeasures.Currency)}
                    percentageChange={-5}
                />
            </Grid>

            <Grid xs={12} item>
                <MetricsCard
                    title='Unplanned Outage'
                    value={data?.core_site[0]?.core_disruptions_aggregate?.aggregate?.count ?? 0}
                />
            </Grid>

            <Grid xs={12} item>
                <MetricsCard
                    title='Unauthorized Access'
                    value="0"
                />
            </Grid>

            <Grid xs={12} item>
                <MetricsCard
                    title='Scheduled Visits'
                    value={data?.core_visit_aggregate?.aggregate?.count ?? 0}
                />
            </Grid>
        </Grid>
    )
}