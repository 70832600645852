import {styled} from "@mui/material/styles";
import {Box} from "@mui/material";
import {MetricsCardBackground} from "../../../config";

export const StyledPageContainer = styled(Box)(({theme}) => ({
    padding: '8px 32px',
    height: '100%'
}));

export const StyledPageCard = styled(Box)(({theme}) => ({
    padding: '32px 24px',
    borderRadius: '8px',
    background: MetricsCardBackground,

    '.shuri-card-title': {
        color: 'white',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '38px',

        '&.sub': {
            fontSize: '16px'
        }
    }
}));
