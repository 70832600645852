import { Core_Securitycallouts } from "../../../generated/graphql";
import { useState } from "react";
import { getAuthData, getClaims, useNotification } from "../../../lib";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { ShuriStyledDialog, ShuriStyledDialogBackdrop } from "../dialog";
import {
  BaseColor,
  ButtonBaseColor,
  ButtonBaseColorLight,
  errorBase,
} from "../../config";
import { API } from "aws-amplify";
import NotInterestedIcon from "@mui/icons-material/NotInterested";

interface ICancelCalloutActionProps {
  handleRefreshCallouts: () => void;
  callout: Core_Securitycallouts;
}

export function CancelCalloutAction({
  callout,
  handleRefreshCallouts,
}: ICancelCalloutActionProps) {
  const [open, setOpen] = useState(false);
  const { notify } = useNotification();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = async () => {
    try {
      const { idToken } = await getAuthData();
      const claims = await getClaims();
      const token = idToken.jwtToken;
      const requestData = {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
        body: {
          id: callout?.id,
          callout_id: callout?.call_out_id,
          endpoint: claims.endpoint,
        },
      };
      const response = await API.post(
        "shurikonektfeapi",
        "/aura-cancel-callout",
        requestData
      );
      if (response.statusCode === 200) {
        handleRefreshCallouts()
        setOpen(false);
        notify({
          status: "success",
          open: true,
          message: "Callout canceled successfully!",
        });
      } else if (response.statusCode === 400) {
        notify({
          open: true,
          status: "error",
          message: `Error! ::: ${response.body.message}`,
        });
      } else {
        notify({
          open: true,
          status: "error",
          message: "Error! ::: Failed to cancel a callout!",
        });
      }
    } catch (error) {
      notify({
        open: true,
        status: "error",
        message: "Error! ::: Failed to cancel callout!",
      });
    }
  };

  return (
    <div>
      <IconButton
        onClick={() => handleClickOpen()}
        aria-label="Cancel Callout"
        size="small"
        disabled={callout?.closed_on ? true : false }
      >
     <NotInterestedIcon color="secondary" />
      </IconButton>

      <ShuriStyledDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropComponent={ShuriStyledDialogBackdrop}
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton
            sx={{
              background: `${errorBase}50`,
              "&:hover": {
                background: `${errorBase}60`,
              },
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill={errorBase}
              viewBox="0 0 256 256"
            >
              <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm-8-80V80a8,8,0,0,1,16,0v56a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,172Z"></path>
            </svg>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description-title"
            className="shuri-dialog-title"
          >
            Cancel Callout ?
          </DialogContentText>

          <DialogContentText
            className="shuri-dialog-description"
            id="alert-dialog-description"
          >
            Are you sure you would like to cancel callout :{" "}
            {callout?.call_out_id}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleRemove}
            variant="contained"
            autoFocus
            size="large"
            sx={{
              color: BaseColor,
              borderColor: errorBase,
              backgroundColor: errorBase,
              textTransform: "capitalize",
              "&:hover": {
                background: `${errorBase}95`,
              },
            }}
          >
            Confirm
          </Button>

          <Button
            onClick={handleClose}
            variant="outlined"
            size="large"
            sx={{
              color: ButtonBaseColorLight,
              borderColor: ButtonBaseColor,
              textTransform: "capitalize",
              "&:hover": {
                borderColor: `${ButtonBaseColor}95`,
              },
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </ShuriStyledDialog>
    </div>
  );
}
