import { useState } from "react";
import { getAuthData, getClaims } from "../../authData";
import { API } from "aws-amplify";
import { IUploadMediaInterface } from "../../interfaces";
import axios from "axios";

export const useUploadMedia = () => {
  const [urls, setUrls] = useState<string[] | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const uploadMedia = async ({ files, data }: IUploadMediaInterface) => {
    setLoading(true);
    try {
      const { idToken } = await getAuthData();
      const claims = await getClaims();
      const token = idToken.jwtToken;

      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }

      const requestData = {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      };

      const responseUrls = await API.get(
        "shurikonektfeapi",
        `/get-signed-url?equipmentID=${data.equipmentID}&numFiles=${files.length}&companyID=${claims?.companyID}`,
        requestData
      );

      const config = {
        headers: {
          "Content-Type": "image/jpeg",
        },
      };
      if (responseUrls.length > 0) {
        for (let i = 0; i < files.length; i++) {
          const uploadFile = files[i];
          const url = responseUrls[i].uploadURL;
          await axios
            .put(url, uploadFile, config)
            .then((_) => {})
            .catch((error) => {
              if (error.response) {
                setError(
                  `Could not upload media. An error occurred with ${error.response.status}`
                );
              }
            });
        }
        if (!error) {
          setUrls(responseUrls);
        }
      } else {
        setError(
          "Could not upload media. An error occurred with : failed to generate signed urls!"
        );
      }
    } catch (e) {
      setError("Could not upload media. An error occurred!");
    } finally {
      setLoading(false);
    }
  };
  return { urls, loading, error, uploadMedia };
};
