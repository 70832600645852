import { useMutation } from "@apollo/client";
import { Box, CSSObject, Container, Theme, Toolbar, styled } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import {
  CalendarBlank,
  Clock,
  Gauge,
  GlobeSimple,
  House,
  PoliceCar,
  Shield,
  UsersThree,
  VideoCamera
} from "@phosphor-icons/react";
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useNotification } from "../../../../lib/context";
import { getCurrentDate } from "../../../../lib/filters";
import {
  UPDATE_EVENT_LOG,
  useEventLogsNotifications
} from "../../../../lib/hooks";
import { SideMenu, SideMenuItemProps } from "../../../components";
import { AppBackgroundColor, SideBarButtonTextColor } from "../../../config";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileLayout from "../../../layout/Mobile/mobileLayout";
import { AppContextProvider } from "../../../../lib/context/application";
import { Auth } from "aws-amplify";


const sideMenuItems: SideMenuItemProps[] = [
  {
    label: "Home",
    icon: <House color={SideBarButtonTextColor} size={22} />,
    link: "/",
  },
  {
    label: "Dashboard",
    icon: <Gauge color={SideBarButtonTextColor} size={22} />,
    link: "/dashboard",
    children: [
      {
        label: "Alarms",
        icon:<FiberManualRecordOutlinedIcon  sx={{fontSize:10,color:SideBarButtonTextColor}}/>,
        link: "/dashboard/alarms",
      },
      {
        label: "Generators",
        icon:<FiberManualRecordOutlinedIcon  sx={{fontSize:10,color:SideBarButtonTextColor}}/>,
        link: "/dashboard/generators",
      },
      {
        label: "Security",
        icon: <FiberManualRecordOutlinedIcon sx={{fontSize:10,color:SideBarButtonTextColor}} />,
        link: "/dashboard/events",
      },
      {
        label: "Sites",
        icon:<FiberManualRecordOutlinedIcon sx={{fontSize:10,color:SideBarButtonTextColor}} />,
        link: "/dashboard/sites",
      },
      {
        label: "Simcards",
        icon:<FiberManualRecordOutlinedIcon  sx={{fontSize:10,color:SideBarButtonTextColor}}/>,
        link: "/dashboard/simcards",
      },
    ],
  },
  {
    label: "Sites",
    icon: <GlobeSimple color={SideBarButtonTextColor} size={22} />,
    link: "/sites",
  },
  {
    label: "Event Log",
    icon: <Clock color={SideBarButtonTextColor} size={22} />,
    link: "/event-logs",
  },
  {
    label: "Camera Streams",
    icon: <VideoCamera color={SideBarButtonTextColor} size={22} />,
    link: "/camera-streams",
  },
  {
    label: "Armed Response",
    icon: <PoliceCar color={SideBarButtonTextColor} size={22} />,
    link: "/armed-response",
  },
  {
    label: "Callout Logs",
    icon: <Shield color={SideBarButtonTextColor} size={22} />,
    link: "/callout-logs",
  },
  {
    label: "Jobs",
    icon: <CalendarBlank color={SideBarButtonTextColor} size={22} />,
    link: "/jobs",
  },
  {
    label: "Users",
    icon: <UsersThree color={SideBarButtonTextColor} size={22} />,
    link: "/users",
  },
  // {
  //   label: "Companies",
  //   icon: <BriefcaseMetal color={SideBarButtonTextColor} size={22} />,
  //   link: "/companies"
  // },
  // { label: "Settings", icon: <Gear color={SideBarButtonTextColor} size={22} /> }
];
const drawerWidth = 280;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

export function DashboardRoot(props: any) {
  const [isOpen, setIsOpen] = useState(true);
  const [currentUser,setCurrentUser] = useState<any>(null)
  const theme = useTheme();
  const isSmallScreen = useMediaQuery('(max-width:600px)')
  const { data, loading, error, upEventLogsNotifications } =
    useEventLogsNotifications();

  const { showAlertNotification } = useNotification();
  const navigate = useNavigate();

  const [updateEventLog] = useMutation(UPDATE_EVENT_LOG, {});

  const onGetUser = async () => {
    try {
     const user = await Auth.currentUserInfo();
     setCurrentUser(user);
    } catch (error) {
     console.log(error);
    }
   };

  const handleViewClick = (id: number) => {
    updateEventLog({
      variables: {
        id: id,
        viewed_on: getCurrentDate()
      }
    });
    navigate(`event-log/${id}`);
  };

  useEffect(() => {
    data?.core_eventlog?.forEach((eventLog: any) => {
      // showAlertNotification({
      //   variant: "error",
      //   handleViewClick: handleViewClick,
      //   id: eventLog?.id,
      //   persist: true,
      //   message: `${eventLog?.event_name} (${eventLog?.core_site?.name})`
      // });
    });
  }, [data, showAlertNotification]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // upEventLogsNotifications();
    }, 6000);

    return () => clearInterval(intervalId);
  }, []);

useEffect(()=>{
  onGetUser()
},[])

  return (<AppContextProvider currentUser={currentUser}>
    {isSmallScreen === false?
    <Box sx={{ display: "flex" }}>
      <Drawer variant="permanent" anchor="left" open={isOpen}>
        <SideMenu
          isOpen={isOpen}
          onToggle={() => setIsOpen((prev) => !prev)}
          items={sideMenuItems}
          mobile={undefined}
        />
      </Drawer>
      <Box flex={1} sx={{ backgroundColor: AppBackgroundColor }}>
        <Toolbar sx={{ height: "5vh" }} />
        <Outlet />
      </Box>
    </Box>:<MobileLayout menuItems={sideMenuItems}/>}
  </AppContextProvider>
  );
}


