import gql from "graphql-tag";
import {useQuery} from "@apollo/client";

export const SITE_TYPES_QUERY = gql`
    query SITE_TYPES_QUERY {
        core_sitetype {
                id
                site_type
        }
    }
`;

export const useSiteTypes = () => {
    const {data, error, loading} = useQuery(SITE_TYPES_QUERY);
    return {data, error, loading};
}