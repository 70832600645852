import {ChangeEventHandler, FocusEventHandler, useEffect, useState} from "react";
import {
    Box,
    FormControl,
    Grid
} from "@mui/material";
import {
    StyledFormFieldError,
    StyledFormLabel,
    StyledFormRow,
    StyledFormTextField
} from "../../../form";
import {ShuriMuiFormSelect} from "../../../form";
import {
    getFullNameFromUser,
    IJob,
    ISelectInput,
    ISite,
    IUser, useJobStatus,
    useSiteSubData,
    useUsersSubData
} from "../../../../../lib";

interface IJobInput {
    site_id: number | string;
    status_id: number | string;
    personale_id: number | string;
    ext_job_number: string;
    description: string;
}

type IJobInputErrors = {
    [K in keyof IJobInput]?: string;
};

interface IAddJobDetailsFormProps {
    inputs: IJobInput;
    handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | any,
    handleBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
    errors: IJobInputErrors,
    validateField: (name: string) => void;
}

export function AddJobDetailsForm({inputs, handleChange, validateField, errors}: IAddJobDetailsFormProps) {
    const {data: usersData} = useUsersSubData();
    const {data: sitesData} = useSiteSubData();
    const {data: statusData} = useJobStatus();
    const [users, setUsers] = useState<ISelectInput[]>();
    const [sites, setSites] = useState<ISelectInput[]>();
    const [statuses, setStatuses] = useState<ISelectInput[]>();

    useEffect(()=>{
        if (usersData){
            setUsers(usersData?.core_user?.map((user: IUser) => ({
                label: getFullNameFromUser(user),
                value: user.id
            })))
        }

        if (sitesData){
            setSites(sitesData?.core_site?.map((site: ISite) => ({
                label: site.name,
                value: site.id
            })))
        }

        if (statusData){
            setStatuses(statusData?.core_jobstatusenum?.map((status: { id: number, status: string }) => ({
                label: status.status,
                value: status.id
            })))
        }
    }, [usersData, sitesData, statusData])

    return (
        <StyledFormRow>
            <Box className='form-row-title'>
                Job Details
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                    <FormControl color="secondary" fullWidth required>
                        <StyledFormLabel>External Job Number</StyledFormLabel>
                        <StyledFormTextField
                            type="text"
                            size='small'
                            placeholder="Enter external job number"
                            name="ext_job_number"
                            value={inputs.ext_job_number}
                            onChange={handleChange}
                            onBlur={() => validateField('ext_job_number')}
                            error={!!errors.ext_job_number}
                            helperText={errors.ext_job_number}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                    <FormControl color="secondary" size="small" fullWidth required>
                        <StyledFormLabel>Site</StyledFormLabel>
                        <ShuriMuiFormSelect
                            placeholder='Select Site'
                            options={sites ?? []}
                            value={inputs.site_id}
                            handleChange={handleChange}
                            name='site_id'
                            handleBlur={() => validateField('site_id')}
                            error={!!errors.site_id}
                        />
                        {errors.site_id && <StyledFormFieldError>{errors.site_id}</StyledFormFieldError>}
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                    <FormControl color="secondary" size="small" fullWidth required>
                        <StyledFormLabel>Assign Worker</StyledFormLabel>
                        <ShuriMuiFormSelect
                            placeholder='Select team member'
                            options={users ?? []}
                            value={inputs.personale_id}
                            handleChange={handleChange}
                            name='personale_id'
                            handleBlur={() => validateField('personale_id')}
                            error={!!errors.personale_id}
                        />
                        {errors.personale_id && <StyledFormFieldError>{errors.personale_id}</StyledFormFieldError>}
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                    <FormControl color="secondary" size="small" fullWidth required>
                        <StyledFormLabel>Job status</StyledFormLabel>
                        <ShuriMuiFormSelect
                            placeholder='Select status'
                            options={statuses ?? []}
                            value={inputs.status_id}
                            handleChange={handleChange}
                            name='status_id'
                            handleBlur={() => validateField('status_id')}
                            error={!!errors.status_id}
                        />
                        {errors.status_id && <StyledFormFieldError>{errors.status_id}</StyledFormFieldError>}
                    </FormControl>
                </Grid>


                <Grid item xs={12}>
                    <FormControl color="secondary" size="small" fullWidth required>
                        <StyledFormLabel>Description</StyledFormLabel>
                        <StyledFormTextField
                            type="text"
                            size='small'
                            placeholder="A little about the company and the team you will be working with."
                            name="description"
                            value={inputs.description}
                            onChange={handleChange}
                            onBlur={() => validateField('description')}
                            error={!!errors.description}
                            helperText={errors.description}
                            multiline
                            rows={5}
                        />
                    </FormControl>
                </Grid>

            </Grid>
        </StyledFormRow>
    )
}

export function mapJobDetailsInput(job?: IJob): IJobInput {
    return {
        site_id: job?.site_id ?? '',
        personale_id: job?.core_personnelassigneds[0]?.personale_id ?? '',
        ext_job_number: job?.ext_job_number ?? '',
        description: job?.description ?? '',
        status_id: job?.core_jobstatuses[0]?.status_id ?? ''
    }
}