import {PageAppBar, StyledBreadcrumbChild} from "../../page";
import React, {useEffect} from "react";
import {useEventLogContext, getCurrentDate, UPDATE_EVENT_LOG} from "../../../../lib";
import {useMutation} from "@apollo/client";
import {Button} from "@mui/material";
import {AppDarkBackgroundColor, InputBaseColorLight} from "../../../config";
import AddIcon from "@mui/icons-material/Add";
import {useNavigate} from "react-router-dom";

export function EventLogHeadSection()
{
    const navigate = useNavigate();
    const [updateEventLog] = useMutation(UPDATE_EVENT_LOG, {
   
    });
  
    const {eventlog} = useEventLogContext();
    useEffect(() => {
        if (eventlog.viewed_at==null && eventlog.id) {
            updateEventLog({
                variables: {
                  id: eventlog.id,
                  viewed_on: getCurrentDate()
                }
              })
        }
    }, [eventlog]);
    
    return (
        <PageAppBar
            title="Event Details"
            breadcrumbs={[
                <StyledBreadcrumbChild key='eventlog-details-title'>
                    {eventlog?.id ?? 'Event Log'}
                </StyledBreadcrumbChild>
            ]}
            ActionButton={()=>(
                <>
                    <Button
                        size="medium"
                        variant="outlined"
                        color="inherit"
                        onClick={() => navigate(`/site/${eventlog.site_id}?armed-response?create-open`)}
                        sx={{
                            color: InputBaseColorLight,
                            backgroundColor: AppDarkBackgroundColor,
                            borderRadius: '28px',
                            marginRight: '10px',
                            textTransform: 'capitalize',
                            border: 'none'
                        }}>
                        Create Callout
                    </Button>

                    <Button size="medium"
                            variant="contained"
                            color="info"
                            onClick={() => navigate('/jobs/create')}
                            startIcon={<AddIcon/>} sx={{
                        borderRadius: '28px', marginRight: '10px', textTransform: 'capitalize'
                    }}>
                        Create Job
                    </Button>
                </>
            )}
        />
    )
}