import {Box, IconButton, Typography} from "@mui/material";
import {LayoutEnum} from "../../../lib";
import {BaseColorLight, InputBaseColorLight} from "../../config";

interface ILayoutToggleProps {
    layout: LayoutEnum,
    onLayoutSelect: (layout: LayoutEnum ) => void
}

export function LayoutToggle({layout, onLayoutSelect}: ILayoutToggleProps) {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}>
            <Typography sx={{
                paddingRight: '5px'
            }}>Layout</Typography>
            <IconButton size="small" onClick={()=> onLayoutSelect(LayoutEnum.TILED9)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M19.9999 2.40002H4.0001C3.11954 2.40002 2.40002 3.12026 2.40002 4.0001V20.0002C2.40002 20.8798 3.11954 21.6 4.0001 21.6H20.0002C20.8798 21.6 21.6 20.8798 21.6 19.9999V4.0001C21.6 3.12026 20.8798 2.40002 19.9999 2.40002ZM19.9999 6.79994H17.2001V4.0001H19.9999V6.79994ZM12.7999 6.79994V4.0001H15.6V6.80018L12.7999 6.79994ZM15.6 8.40002V11.2001H12.7999V8.40002H15.6ZM11.2001 6.79994H8.40002V4.0001H11.2001V6.79994ZM11.2001 8.40002V11.2001H8.40002V8.40002H11.2001ZM6.79994 11.2001H4.0001V8.40002H6.80018L6.79994 11.2001ZM6.79994 12.7999V15.6H4.0001V12.7999H6.79994ZM8.40002 12.7999H11.2001V15.6H8.40002V12.7999ZM11.2001 17.2001V19.9999H8.40002V17.2001H11.2001ZM12.7999 17.2001H15.6V19.9999H12.7999V17.2001ZM12.7999 15.6V12.7999H15.6V15.6H12.7999ZM17.2001 12.7999H19.9999V15.6H17.2001V12.7999ZM17.2001 11.2001V8.40002H19.9999V11.2001H17.2001ZM6.79994 4.0001V6.80018H4.0001V4.0001H6.79994ZM4.0001 17.2001H6.80018V19.9999H4.0001V17.2001ZM17.2001 19.9999V17.2001H19.9999V19.9999H17.2001Z"
                        fill={layout === LayoutEnum.TILED9 ? InputBaseColorLight : BaseColorLight  }/>
                </svg>
            </IconButton>
            <IconButton size="small" onClick={()=> onLayoutSelect(LayoutEnum.TILED4)}>
                <svg xmlns="http://www.w3.org/2000/svg"
                     width="28"
                     height="28"
                     fill={layout === LayoutEnum.TILED4 ? InputBaseColorLight :BaseColorLight  }
                     viewBox="0 0 256 256"><path d="M200,40H56A16,16,0,0,0,40,56V200a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V56A16,16,0,0,0,200,40Zm0,80H136V56h64ZM120,56v64H56V56ZM56,136h64v64H56Zm144,64H136V136h64v64Z"></path></svg>
            </IconButton>
            <IconButton size="small" onClick={()=> onLayoutSelect(LayoutEnum.HIGHLIGHT)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.0001 21.6H20.0002C20.8798 21.6 21.6 20.8798 21.6 19.9999V4.0001C21.6 3.12026 20.8798 2.40002 19.9999 2.40002H4.0001C3.12026 2.40002 2.40002 3.12026 2.40002 4.0001V20.0002C2.40002 20.8798 3.12026 21.6 4.0001 21.6ZM4.0001 15.6007H11.2001V19.9999H4.0001V15.6007ZM12.7999 19.9999V15.6007H19.9999V19.9999H12.7999ZM19.9999 4.0001V14.0009H4.0001V4.0001H19.9999Z"
                        fill={layout === LayoutEnum.HIGHLIGHT ? InputBaseColorLight :BaseColorLight  }
                    />
                </svg>
            </IconButton>
        </Box>
    )
}