
export function getStartAndEndDateTime(inputDate: Date): { created_gte: string, created_lte: string } {
    // Clone the input date to avoid modifying the original object
    const clonedDate = new Date(inputDate);

    // Set the time to the start of the day (midnight)
    clonedDate.setHours(0, 0, 0, 0);

    // Create a new Date object for the next day
    const nextDay = new Date(clonedDate);
    nextDay.setDate(nextDay.getDate() + 1);

    // Set the time to the end of the day (just before midnight)
    nextDay.setMilliseconds(nextDay.getMilliseconds() - 1);

    return {
        created_gte: clonedDate.toISOString(),
        created_lte: nextDay.toISOString()
    };
}