import { Box, FormControl } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ShuriMuiFormSelect,
  StyledFormLabel,
  StyledFormTextField
} from "../../../../components";
import { useGetEquitmentWithAlarms } from "../../../../../lib/hooks/notifications/notification.hooks";
import { useApolloClient } from "@apollo/client";

export default function CreateRectiferAlarmForm({ formRef, errors,intialData }: any) {
  const { loading, error, data, fetch_alarms } = useGetEquitmentWithAlarms();
  const [priority, setPriority] = useState<any>("High");
  const [model, setModel] = useState<any>(null);
  const [alarm,setAlarm] = useState<any>(null);
  const [modelOpts, setModelOpts] = useState<any[]>([]);
  const [alarms, setAlarms] = useState<any[]>([]);
  const [allAlarms,setAllAlarms] = useState<any[]>([])
  const client = useApolloClient();
  const handleOnsubmit = (event: any) => {
    event.preventDefault();
    let data = new FormData(event.currentTarget);
    let model = {
      alarm_name: data.get("alarm_name"),
      alarm_id: data.get("alarm_id"),
      model: data.get("model"),
      priority: data.get("priority"),
    };
    console.log(model);
  };
  const handleOnModelChange = async (id: any) => {
    let data = await fetch_alarms(client, { id: id });
    setModel(id);
    setAllAlarms(data?.core_alarm)
    let options: any[] = [];
    if (data) {
      data?.core_alarm?.map((item: any) => {
        options.push({
          label: item?.alarm_name,
          value: item?.id,
        });
      });
    }
    setAlarms([...options]);
  };

  const handleSetAlarm=(alarm:any)=>{
    allAlarms.map((item)=>{
        if(item.id===alarm){
            console.log(item)
        }
    })
   let ss= alarms.find((item)=>item?.id===alarm)
   setAlarm(alarm)
  }
  useEffect(() => {
    let options: any[] = [];
    data?.core_equipment_with_alarm?.map((item: any, index: number) => {
      options.push({
        label: item.core_equipmentmodel?.equipment_model,
        value: item.id,
      });
    });
    handleOnModelChange(intialData?.equipment_with_alarms_id)
    setAlarm(intialData?.id)
    setModelOpts([...options]);
    if(intialData?.priority_override){

        setPriority(intialData?.priority_override)
    }
    else{
        setPriority(intialData?.priority)
    }
  }, [data]);
  return (
    <Box
      component={"form"}
      onSubmit={(event) => handleOnsubmit(event)}
      ref={formRef}
    >
      <FormControl color="secondary" size="small" fullWidth required>
        <StyledFormLabel>Please Select Equipment</StyledFormLabel>
        {loading === false && (
          <ShuriMuiFormSelect
            placeholder="Select equipment"
            options={modelOpts}
            name="equipment_with_alarms_id"
            value={model}
            handleChange={(value) => handleOnModelChange(value.target.value)}
          />
        )}
      </FormControl>

      {intialData!==null && (
        <>
          <FormControl color="secondary" size="small" fullWidth required>
            <StyledFormLabel>{intialData !==null?'Selected Alarm':'Please Select Alarm'}</StyledFormLabel>
            <ShuriMuiFormSelect
              placeholder="Select alarm by name"
              options={alarms}
              name="alarm_name"
              value={intialData?.id}
              handleChange={(value) => handleSetAlarm(value.target.value)}
            />
          </FormControl>
       <FormControl color="secondary" size="small" fullWidth >
       <StyledFormLabel>Model Priority</StyledFormLabel>
       <StyledFormTextField disabled value={intialData?.priority}  />
       </FormControl >
          <FormControl color="secondary" size="small" fullWidth required>
            <StyledFormLabel>Select Priority</StyledFormLabel>
            <ShuriMuiFormSelect
              placeholder="Select Priority"
              options={[
                {
                  label: "High",
                  value: "High",
                },
                {
                    label: "Critical",
                    value: "Critical",
                  },
                {
                  label: "Medium",
                  value: "Medium",
                },
                {
                  label: "Low",
                  value: "low",
                },
              ]}
              name="priority_override"
              value={priority}
              handleChange={(value) => setPriority(value.target.value)}
              handleBlur={() => console.log("site_id")}
            />
          </FormControl>
        </>
      )}
    </Box>
  );
}
