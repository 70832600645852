import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography
} from "@mui/material";
import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../../../layout/AuthLayout";
import SetPasswordModal from "./SetPasswordModal";

const LoginPage = () => {
  const defaultValues = {
    password: "",
    username: ""
  };
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors }
  } = useForm({ defaultValues });

  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [user, setUser] = useState<any | null>(null);

  const onSignIn = async ({ username, password }: typeof defaultValues) => {
    try {
      setErrorMessage(null);
      const user = await Auth.signIn(username, password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setUser(user);
        setShowNewPasswordModal(true);
      }
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  const navigate = useNavigate();

  return (
    <AuthLayout title="Login">
      <Box marginY={3}>
        <InputLabel sx={{ color: "white" }}>Email</InputLabel>
        <TextField
          fullWidth
          color="info"
          variant="outlined"
          placeholder="Enter email"
          InputProps={{ style: { color: "white" } }}
          InputLabelProps={{ style: { color: "gray" } }}
          {...register("username", {
            required: "Your email address is required"
          })}
        />
        {errors.username && (
          <InputLabel sx={{ color: "white" }}>
            {errors?.username.message}
          </InputLabel>
        )}
      </Box>
      <Box marginY={3}>
        <InputLabel sx={{ color: "white" }}>Password</InputLabel>
        <OutlinedInput
          color="info"
          sx={{ color: "white" }}
          {...register("password", {
            required: "Password is required"
          })}
          fullWidth
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword((prev) => !prev)}
                // onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? (
                  <VisibilityOff sx={{ color: "gray" }} />
                ) : (
                  <Visibility sx={{ color: "gray" }} />
                )}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
        />
      </Box>

      <Box
        display="flex"
        alignItems="center"
        flexDirection="row"
        marginY={3}
        justifyContent="space-between"
      >
        <FormGroup>
          <FormControlLabel
            required
            label="Remember me"
            sx={{ color: "white" }}
            control={<Checkbox color="info" sx={{ color: "grey" }} />}
          />
        </FormGroup>
        <Typography
          color="#5BC0E9"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/forgot-password")}
        >
          Forgot Password
        </Typography>
      </Box>
      <Button
        variant="contained"
        onClick={handleSubmit(onSignIn)}
        sx={{ backgroundColor: "#4490AE", color: "black", width: "360px" }}
      >
        {isSubmitting ? "Loading" : "Sign In"}
      </Button>
      {errorMessage && (
        <Typography
          sx={{ marginTop: "10px", textAlign: "center", color: "red" }}
        >
          {errorMessage}
        </Typography>
      )}
      <SetPasswordModal
        user={user}
        isVisible={showNewPasswordModal}
        onClose={() => setShowNewPasswordModal(false)}
      />
    </AuthLayout>
  );
};

export default LoginPage;
