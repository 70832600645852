import {StyledPageCard} from "../../page";
import {Box} from "@mui/material";
import {EventLogDetection} from "./event-log-detection";
import {useEventLogContext} from "../../../../lib";
import { EventLogRectifierAlarms } from "./event-log-alarms/RectifierAlarm";
import { EventLogGeneratorAlarms } from "./event-log-alarms/GeneratorAlarms";

export function EventLogInsightDetailsSection() {
    const {eventlog} = useEventLogContext();
    return (
        <StyledPageCard>
            <Box className="shuri-card-title">
                Insight Details
            </Box>

            {
                eventlog?.event_log_type?.toLowerCase() === 'Security Detection'.toLowerCase() &&
                <EventLogDetection />
            }
            {
                eventlog?.event_log_type?.toLowerCase() === 'Rectifier'.toLowerCase()&&<EventLogRectifierAlarms/>
            }
            {
                eventlog?.event_log_type?.toLowerCase()==="Generator".toLowerCase() &&<EventLogGeneratorAlarms/>
            }
        </StyledPageCard>
    )
}