import {useParams} from "react-router-dom";
import {StyledPageCard} from "../../page";
import {AddSiteCameras} from "./add-cameras";
import {AddSiteContacts} from "./add-contacts";
import {AddSiteEdgeDevice} from "./add-edge-device";
import {AddSiteDetails} from "./add-site-details";
import {AddSiteInventories} from "./add-site-inventory";
import {useAllSiteInventories, useSiteChildren} from "../../../../lib";
import {AddSiteMeter} from "./add-site-meter";

export const CreateSiteForm = () => {
    const {id} = useParams();
    const {data, handleRefetch} = useSiteChildren(id);
    const {data: inventories, handleRefetch: refreshInventories} = useAllSiteInventories(id);

    return (
        <StyledPageCard>
            <AddSiteDetails
                site={data?.core_site[0]}
                refreshSite={handleRefetch}
            />
            {
                id &&
                <>
                    <AddSiteContacts/>
                    <AddSiteEdgeDevice
                        site={data?.core_site[0]}
                        device={data?.core_site[0]?.core_boxes[0]}
                        inventories={inventories?.core_equipment}
                        refreshSite={handleRefetch}/>
                    {data?.core_site[0]?.core_boxes?.length > 0 &&
                        <>
                            <AddSiteMeter
                                box={data?.core_site[0]?.core_boxes[0]}
                                meters={data?.core_site[0]?.core_boxes[0]?.core_meters}
                                refreshSite={handleRefetch}
                            />

                            <AddSiteCameras
                                site={data?.core_site[0]}
                                cameras={data?.core_site[0]?.core_boxes[0]?.core_cameras}
                                refreshSite={handleRefetch}
                            />
                        </>
                    }

                    <AddSiteInventories
                        refreshInventories={refreshInventories}
                    />
                </>
            }
        </StyledPageCard>
    )
}