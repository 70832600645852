import {Button} from "@mui/material";
import {JobStatusEnum} from "../../../../lib";
import {ShuriStyledFilterButtons} from "../../table";

interface IJobsStatusFilterProps {
    onFilterUpdate: (status?: string)=> void;
    status?: JobStatusEnum | string
}

export function JobsStatusFilter({status, onFilterUpdate}: IJobsStatusFilterProps)
{
    return (
        <ShuriStyledFilterButtons variant="outlined" aria-label="outlined button group" color="secondary" size="small">
            <Button
                className={!status ? 'selected' : ''}
                onClick={()=> onFilterUpdate()}>All</Button>
            <Button
                className={status === JobStatusEnum.OPEN ? 'selected' : ''}
                onClick={()=> onFilterUpdate(JobStatusEnum.OPEN)}>Open</Button>
            <Button
                className={status === JobStatusEnum.CLOSED ? 'selected' : ''}
                onClick={()=> onFilterUpdate(JobStatusEnum.CLOSED)}>Closed</Button>
        </ShuriStyledFilterButtons>
    )
}