import gql from "graphql-tag";
import {useQuery} from "@apollo/client";

export const VISIT_DETAILS_QUERY = gql`
    query VISIT_DETAILS_QUERY(
        $id: bigint!
    ) {
        core_visit_by_pk(id: $id) {
            id
            start_datetime
            end_datetime
            comment
            job_id
            status_id
            visit_id
            core_job {
                id
                created_on
                description
                ext_job_number
                site_id
            }
            core_requestaccesses {
                id
                personale_id
                request_date
                site_id
                visit_id
                access_reference
            }
            core_visitstatusenum {
                id
                status
            }
        }
    }
`;

export const useVisitDetails = (id?: number) => {
    const {data, error, loading, refetch} = useQuery(VISIT_DETAILS_QUERY, {
        variables: {id},
        skip: !id,
    });

    const handleRefetch = () => {
        refetch({id})
    }
    return {data, error, loading, handleRefetch};
}