import {StyledFormSelect} from "./index";
import {MenuItem, Typography} from "@mui/material";
import {getSelectLabelFromValue} from "../../../../lib";
import {InputBaseColor, InputBaseColorLight} from "../../../config";
import * as React from "react";
import {IShuriMuiFormSelect} from "../../../../lib";

export const ShuriMuiFormSelect = ({options, name, handleChange,handleBlur, value, placeholder, disabled, error}: IShuriMuiFormSelect) => (
    <StyledFormSelect
        className={error ? 'has-error': ''}
        disabled={disabled}
        displayEmpty
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        renderValue={(selected: any) => {
            if (!selected) {
                return <Typography className='select-placeholder'>{placeholder}</Typography>;
            }
            return getSelectLabelFromValue(options, selected);
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        IconComponent={
            (props)=>(
                <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={InputBaseColorLight} viewBox="0 0 256 256"><path d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path></svg>            )
        }
    >
        <MenuItem disabled value="">
            <Typography className='select-placeholder' sx={{
                color: InputBaseColor,
            }}>{placeholder}</Typography>
        </MenuItem>
        {options.map((option) => (
            <MenuItem
                className='select-item'
                key={`company-select-input-${option.value}-${name}`}
                value={option.value}
                sx={{
                    color: InputBaseColorLight,
                }}
            >
                {option.label}
            </MenuItem>
        ))}
    </StyledFormSelect>
)