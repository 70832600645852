import {Alert, AlertTitle, Box, Grid, Typography} from "@mui/material";
import {ShuriSpinnerText} from "../../loading";
import {MetricsCard} from "../metrics-card";
import {formatDate, useSiteRectifiersAlarms} from "../../../../lib";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {BaseColorLight} from "../../../config";
import {RectifierAlarmMetricsTable} from "./rectifier-alarm-metrics-table";
import {perPage} from "../../../../config";

export function RectifierAlarmMetrics() {
    const [alarms, setAlarms] = useState([]);
    const {id} = useParams();
    const {loading, data, error} = useSiteRectifiersAlarms(id);

    useEffect(() => {
        if (data) {
            setAlarms(data?.core_rectifieralarm ?? []);
        }
    }, [data]);


    return (
        <Grid container spacing={2}>
            {loading &&
                <Box sx={{
                    paddingTop: '6px'
                }}>
                    <ShuriSpinnerText loadingText={"Loading metrics, ☕ please wait..."}/>
                </Box>
            }

            {
                error &&
                <Alert severity="error" variant="outlined">
                    <AlertTitle>Error</AlertTitle>
                    <strong>An error occurred loading site rectifier alarms!</strong>
                </Alert>
            }

            {alarms && alarms.length > 0 ? (
                    <>
                        {
                            alarms.map((alarm: any) => (
                                <Grid xs={12} item key={`rectifier-metric-${alarm.id}`}>
                                    <MetricsCard
                                        title={`Last updated - ${formatDate(alarm.created_on)}`}
                                        value={alarm.alarm_name}
                                        status={alarm.priority}
                                    />
                                </Grid>
                            ))
                        }

                        {data?.core_rectifieralarm_aggregate?.aggregate?.count > perPage &&
                            <Box sx={{
                                paddingTop: '20px',
                                paddingLeft: '20px'
                            }}>
                                These are summary cards for the first 5 or less recent alarms.
                                <RectifierAlarmMetricsTable/>
                            </Box>
                        }
                    </>
                )
                :
                <Typography sx={{color: BaseColorLight}}>No Rectifier alarms found</Typography>
            }
        </Grid>
    )
}