import {useEventLogs} from "../../../../../lib";
import {useShuriPagination} from "../../../../../lib";
import {Content, EventLogsSearchFilter, PageAppBar} from "../../../../components";
import {Box} from "@mui/material";
import {AppBackgroundColor} from "../../../../config";
import {EventLogsTable} from "../../../../components";
import {IFilter} from "../../../../../lib";
import {useState} from "react";

export function AllEventLogsPage() {
    const {page, rowsPerPage, handleChangePage, handleChangeRowsPerPage} = useShuriPagination(10);
    const [filters, setFilters] = useState<Record<string, string>>();
    const {data, error, loading} = useEventLogs(page, rowsPerPage, filters);

    const handleFilterChange = async ({mappedFilters}: IFilter) => {
        handleChangePage(null,0)
        setFilters(mappedFilters);
    }

    return (
      <Box>
        <PageAppBar title="Event Log" sub="All Event Data" />
        <Content>
          <Box
            sx={{
              height: "auto",
              backgroundSize: "cover",
              backgroundColor: AppBackgroundColor,
              margin: 4,
            }}
          >
            <Box
              sx={{
                color: "white",
                marginBottom: 2,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <EventLogsSearchFilter
                logs={data?.core_eventlog ?? []}
                onFilterUpdate={handleFilterChange}
              />
            </Box>
            <Box
              sx={{
                "@media (max-width: 600px)": {
                  objectFit: "scale-down",
                  display: "flex",
                  width: "100%",
                  margin: 0,
                  overflowX: "hidden",
                },
              }}
            >
              <Box sx={{ width: "100%", overflowX: "auto" }}>
                <EventLogsTable
                  data={data}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  loading={loading}
                  error={error}
                />
              </Box>
            </Box>
          </Box>
        </Content>
      </Box>
    );
}
