import {Box, Typography} from "@mui/material";
import {RingLoader} from "react-spinners";
import {primaryBlue} from "../../config";
import {VideoBackground} from "../../../assets";

export function ShuriVideoError({loading, error}: { loading: boolean, error?: string }) {
    if (!loading && !error) return <></>;
    return (
        <Box sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 10,
            background: `url('${VideoBackground}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundColor: `#9685DE`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '16px'
        }}>
            {
                loading &&
                <Box sx={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <RingLoader color={primaryBlue}/>
                    <Typography>Waiting for video...</Typography>
                </Box>
            }

            {
                error && <Typography sx={{
                    padding: '20px 10px 0px 10px',
                    textAlign: 'center'
                }}>{error}!</Typography>
            }
        </Box>
    )
}