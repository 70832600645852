import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import { string } from 'prop-types';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Button, Typography } from '@mui/material';
import { DeployButton } from '../deploy-button';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
        
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,

       
    },
}));

function createData(
    name: string,
    employeeId: number,
    role: string,
    status: string,
    button: any
  
    

    
) {

    return  { name, employeeId, role, status, button};
    
    
}


// export function AvailabilityIndicator(props: { status: "Available" | "Not Available" }){
//     return (

//         <Chip variant="outlined" label={props.status} color={props.status === "Available"? "success" : "error"} />

//     );
// }


const rows = [

    
    
    createData('Jane Cooper', 21159, 'Engineer', 'Available', <DeployButton/>),
    createData('Wade Warren', 32237, 'Engineer', 'Not available', <Button sx={{ backgroundColor: "#5A5A5A" }}><Typography sx={{ color: 'white' }}>Deploy</Typography></Button>),
    createData('Esther Howard', 54262, 'Engineer', 'Available', <DeployButton/>),
    createData('Cameron Williamson', 88305, 'Engineer', 'Not available', <Button sx={{ backgroundColor: "#5A5A5A" }}><Typography sx={{ color: 'white' }}>Deploy</Typography></Button> ),
    createData('Brooklyn Simmons', 69462, 'Administrator', 'Not available', <Button sx={{ backgroundColor: "#5A5A5A" }}><Typography sx={{ color: 'white' }}>Deploy</Typography></Button>),
    createData('Leslie Alexander', 85694, 'Administrator', 'Available', <DeployButton/>),
    createData('Jenny Wilson', 78698, 'Engineer', 'Available', <DeployButton/>),
    
];



export function SchedulingTable() {
    return (

        <Box sx={{ position: 'absolute', right: '240px', top: '100px', bottom: 0, margin: 'auto' }}>

            <Table sx={{ width: 1200, backgroundColor: 'grey' }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Employee Name</StyledTableCell>
                        <StyledTableCell align="right">Employee Id</StyledTableCell>
                        <StyledTableCell align="right">Role&nbsp;</StyledTableCell>
                        <StyledTableCell align="right">Status&nbsp;</StyledTableCell>
                        <StyledTableCell align="right">Action&nbsp;</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <StyledTableRow key={row.name}>
                            <StyledTableCell component="th" scope="row">
                                {row.name}
                            </StyledTableCell>
                            <StyledTableCell align="right">{row.employeeId}</StyledTableCell>
                            <StyledTableCell  align="right">{row.role}</StyledTableCell>
                            <StyledTableCell align="right">{row.status}</StyledTableCell>
                            <StyledTableCell align="right">{row.button}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>

    );
}
