import { AppBar, Badge, Box, IconButton, Toolbar, Typography } from "@mui/material";
import {Link} from "react-router-dom";
import { AppBarAction } from "../AppBarAction";
import { ProfileButton } from "../profile-button";


export function MenuBar(props: { children: any }) {

    return <AppBar position="fixed" elevation={3} sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        width: { sm: `calc(98% - 250px)` }
    }}>
        <Toolbar sx={{ flexWrap: 'nowrap', height: '5vh', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' , alignItems: 'center', gap: '40px', flex: '1 1 auto' , '@media (max-width: 780px)':{
                    marginRight: '1px',
                    minWidth: '5rem'
            }
            }}>
                {props.children}
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', '@media (max-width: 780px)':{
                    display: 'none',
                    position: 'absolute',
                    top: '100%',
                    maxWidth: '30rem',
                    background: '#fff',
                    color: 'black',
                }}}>
                <IconButton color="inherit">
                </IconButton>
                <Box sx={{ width: 20 }} />
                <AppBarAction icon={<Badge badgeContent={4} color="info" sx={{ fontSize: 20, width: 120 }}><Link to={"/notifications"}>Notifications</Link></Badge>} />
                <ProfileButton username="t.mathala@gmail.com" />
            </Box>
        </Toolbar>
    </AppBar>
}
