import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useInventoryComponents, ADD_PART } from "../../../lib/hooks";
import { InputBox, IFormProps } from "./";
import {
  Box,
  Button,
  Grid,
  TextField,
  InputLabel,
  MenuItem,
} from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#26222a",
  color: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const initalState = {
  equipment_part: "",
  model_id: "",
};
export const PartForm = React.forwardRef((props: IFormProps, ref: any) => {
  const [state, setState] = useState(initalState);
  const [addPart, { data, loading, error }] = useMutation(ADD_PART, {
    onCompleted: () => {
      props.handleRefresh();
    },
  });

  const [success, setSuccess] = React.useState(false);
  const inventory_components_data = useInventoryComponents();

  const handleChange = (event: any) => {
    const value = event.target.value;
    switch (event.target.name) {
      case "equipment_part":
        setState({
          ...state,
          equipment_part: value,
        });
        break;

      case "model_id":
        setState({
          ...state,
          model_id: value,
        });
        break;

      default:
    }
  };

  return (
    <Box sx={style}>
      <h1> Add New Equipment Part</h1>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          addPart({
            variables: {
              equipment_part: state.equipment_part,
              model_id: state.model_id,
            },
          });
          setSuccess(true);
          props.closeHandler(true);
        }}
        sx={{ margin: 3 }}
      >
        <Grid container spacing={3} item xs={12}>
          <Grid item xs={12}>
            <InputLabel id="demo-simple-select-label" sx={{ color: "#ADACAF" }}>
              Part
            </InputLabel>
            <TextField
              value={state.equipment_part}
              name="equipment_part"
              id="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChange}
              sx={{
                input: {
                  color: "black",
                  background: "white",
                  borderRadius: 2,
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel id="demo-simple-select-label" sx={{ color: "#ADACAF" }}>
              Model
            </InputLabel>
            <InputBox>
              <TextField
                value={state.model_id}
                name="model_id"
                onChange={handleChange}
                fullWidth
                select
                sx={{
                  color: "#black",
                  background: "white",
                  borderRadius: 2,
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      sx: {
                        backgroundColor: "white",
                        maxHeight: 300,
                      },
                    },
                  },
                }}
              >
                {inventory_components_data.data.core_equipmentmodel.map(
                  (item: any) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.model_number}
                      </MenuItem>
                    );
                  }
                )}
              </TextField>
            </InputBox>
          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" type="submit" color="success">
              Add
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});
