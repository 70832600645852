import { Button, Card, Box, Typography } from "@mui/material";
import { ArrowUp, ArrowDown } from "@phosphor-icons/react";
import { MetricsCardBackground, MetricsCardTitleColor } from "../../../config";
import Tower from '../../../../assets/CustomTower';
import {formatDate} from "../../../../lib/filters";

interface SiteTileProps {
    /**
     * Usage tile title
     */
    title: string,

    /**
     * Usage tile amount
     */
    value: number,

    /**
     * Usage tile unit of measure e.g. ZAR, kWh, etc
     */
    items: { label: string, value: number, color: string, icon: JSX.Element }[],

    dark?: boolean
}

export function SiteTile(props: any) {

    const TowerIcon = (status: string) => {
        if (status === 'online') {
            return <Tower color="#12B76A"/>
        }
        if (status?.includes('offline')) {
            return <Tower color="#746F6F"/>
        } 
        else {
            return <Tower color="#FF632B"/>
        }
    }

    const ArrowIcon = (status: string) => {
        if (status === 'online') {
            return <ArrowUp color="#12B76A"/>
        }
        if (status?.includes('offline')) {
            return <ArrowDown color="#746F6F"/>
        } 
        else {
            return <ArrowDown color="# F79009"/>
        }
    }
    const coreState  = props.site?.core_sitestates
    const lastseen =  coreState?.[0]?.lastseen
    const status = props.site?.core_sitestatus?.site_status
    return (
        <Button sx={{ padding: 0, width: '100%' }}>
            <Card sx={{ minWidth: 220, height: '22.3rem', borderRadius: '8px', padding: '30px 20px', backgroundColor: MetricsCardBackground, '@media (min-width: 1369px)': {width: '100%'} }}>
                <Box sx={{ margin: 0, padding: 1, paddingTop: 0 }}>
                    <Typography sx={{ fontSize: 14, textAlign: 'left', marginTop: '20px',paddingTop: 0, paddingLeft: '10px', marginBottom: '10px', color: MetricsCardTitleColor , textTransform: "capitalize",}}>
                        Site Details
                    </Typography>

                    <Box>
                        {TowerIcon(status)}
                    </Box>
                    <Typography sx={{textAlign: 'left', color: 'white', margin: '20px 0 10px 10px', textTransform: 'capitalize'}}>
                        {props.site.name} {props.site.site_id}
                    </Typography>

                    <Box sx={{display: 'flex', paddingLeft: '6px'}}>
                        {ArrowIcon(status)}
                        <Typography sx={{textAlign: 'left', color: MetricsCardTitleColor, textTransform: 'capitalize', fontSize: 12, paddingLeft: '6px'}}>
                           Last Seen {formatDate(lastseen)}
                    </Typography>
                    </Box>

                </Box>
            </Card>
        </Button>
    );
}
