import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { float } from "aws-sdk/clients/cloudfront";

export const COMPANY_SUMMARY_METRICS = gql`
  query (
    $startDate: timestamptz
    $endDate: timestamptz
    $offsetDataBalance: float8
  ) {
    core_simcardstatus_aggregate(
      where: {
        created_on: { _gte: $startDate, _lte: $endDate }
        data_balance_in_mb: { _lt: $offsetDataBalance }
      }
    ) {
      aggregate {
        count
      }
    }
    core_eventlog {
      event_priority
      event_log_type
    }
    core_jobstatus {
      core_jobstatusenum {
        id
        status
      }
    }
    core_visitstatusenum {
      status
      id
    }
    core_job {
      id
    }
    core_simcardstatus(
      where: {
        created_on: { _gte: $startDate, _lte: $endDate }
        data_balance_in_mb: { _lt: $offsetDataBalance }
      }
    ) {
      id
      created_on
      data_balance_in_mb
      core_simcard {
        created_on
        network_name
        msisdn
        imsi
        id
        iccid
        core_router {
          core_box {
            core_site {
              site_id
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const useCompanyMetricsSummary = (
  startDate: any,
  endDate: any,
  offsetDataBalance: float
) => {
  const { data, error, loading } = useQuery(COMPANY_SUMMARY_METRICS, {
    variables: { startDate, endDate, offsetDataBalance },
  });
  return { data, error, loading };
};
