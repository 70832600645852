import {Add} from "@mui/icons-material";
import {Grid, Box, Button} from "@mui/material";
import {useState} from "react";
import {StyledFormRow} from "../../../form";
import {SiteInventories} from "../../../site/site-inventories";
import {ShuriStyledDrawer} from "../../../drawer";
import {CreateInventories} from "../../../inventories/create-inventories";
import * as React from "react";

export const AddSiteInventories = ({refreshInventories}: {refreshInventories?: ()=>void}) => {
    const [createOpen, setCreateOpen] = useState(false);

    return (
        <>
            <StyledFormRow>
                <Grid container spacing={2} className="form-title-container">
                    <Grid item xs={12} md={6}>
                        <Box className='form-row-title'>
                            Site Inventory
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box className="form-row-action">
                            <Button className="form-row-action-button" startIcon={<Add/>}
                                    onClick={() => setCreateOpen(true)}>
                                Add Equipment
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

                <SiteInventories/>
            </StyledFormRow>

            <ShuriStyledDrawer
                anchor="right"
                open={createOpen}
                onClose={() => setCreateOpen(false)}
            >
                <CreateInventories
                    refreshInventories={refreshInventories}
                    dismissForm={() => setCreateOpen(false)}
                />
            </ShuriStyledDrawer>
        </>
    )
}