import {Core_Meter} from "../../../../../generated/graphql";
import * as Yup from "yup";
import {forwardRef, useEffect, useImperativeHandle} from "react";
import {useForm, useYupValidateForm} from "../../../../../lib/form/hooks";
import {FormControl, Grid} from "@mui/material";
import {StyledFormFieldError, StyledFormLabel, StyledFormTextField} from "../../../form";


interface IAddSiteMeterFormProps {
    index: number;
    meter: Core_Meter;
    onMeterChange: (index: number, meter: Core_Meter) => void,
    onRemove: (index: number) => void,
    onFormValidate?: (index: number, hasErrors: boolean) => void,
}

export interface IMeterFormRef {
    handleFormValidate: () => Promise<void>;
    hasErrors: boolean;
}

const validationSchema = Yup.object().shape({
    serial_number: Yup.string().required('Serial Number is required'),
    box_id: Yup.string().required('Box Id is required')
});

export const AddSiteMeterForm = forwardRef<IMeterFormRef | null, IAddSiteMeterFormProps>(({meter, index, onMeterChange, onRemove, onFormValidate}, ref)=>{
    const {inputs, handleChange} = useForm(meter);
    const {errors, validateField, validateForm, hasErrors} = useYupValidateForm(inputs, validationSchema);

    useEffect(() => {
        onMeterChange(index, inputs);
        handleFormValidate();
    }, [inputs]);

    const handleFormValidate = async () => {
        await validateForm();
    }

    useImperativeHandle(ref, () => ({
        handleFormValidate,
        hasErrors
    }));

    return(
        <Grid container spacing={2} sx={{marginBottom: '20px'}}>
            <Grid item xs={12} md={6}>
                <FormControl color="secondary" fullWidth required>
                    <StyledFormLabel>Serial number</StyledFormLabel>
                    <StyledFormTextField
                        disabled={!!meter?.id}
                        type="text"
                        size='small'
                        placeholder="Enter serial number"
                        name="serial_number"
                        value={inputs.serial_number}
                        onChange={handleChange}
                        onBlur={() => validateField('serial_number')}
                        error={!!errors.serial_number}
                        helperText={errors.serial_number}
                    />
                    {errors.box_id && <StyledFormFieldError>{errors.box_id}</StyledFormFieldError>}
                </FormControl>
            </Grid>
        </Grid>
    )
});