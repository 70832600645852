import {StyledPageCard} from "../page";
import {Typography} from "@mui/material";
import {useSiteContext, ICamera} from "../../../lib";
import {AddCamera, CameraDisplay, CamerasContainer} from "../camera";
import {IShuriTab, ShuriCompactTabs} from "../tab";

export default function SiteCameras() {
    const {site: data} = useSiteContext();
    const cameras = data?.core_site[0]?.core_boxes[0]?.core_cameras ?? [];
    const cameraTabs = (): IShuriTab[] => {
        const tabs = cameras?.map((camera: ICamera) => ({
            title: `Camera - ${camera.camera_id}`,
            component: <CameraDisplay
                site={data.core_site[0]}
                camera={camera}
            />
        }));
        if (tabs.length === 0) {
            return [
                {
                    title: "No Camera",
                    component: <AddCamera/>,
                    skipTitle: true
                }
            ]
        }
        return [
            ...tabs,
            {
                title: "All Cameras",
                component: <CamerasContainer site={data} cameras={cameras}/>
            }
        ];
    }
    const siteCameras = cameraTabs();

    return (
        <StyledPageCard>
            <Typography className="shuri-card-title"> Cameras</Typography>
            <ShuriCompactTabs
                tabs={siteCameras}
                name="shuri-site-cameras"/>
        </StyledPageCard>
    )
}