import React from 'react'

const  CustomTower = (props:any) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill={props.color} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9835_271337)">
      <path d="M25.5156 21.1309L28.2717 16.7891" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.2314 17.8398L22.593 21.2039" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M29.8888 37.2988H15.4697" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.2832 37.373L24.1032 44.2691L30.5258 39.0827" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M31.2267 30.4141L28.7461 32.7775" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M26.37 35.0399L24.1031 37.199L24 37.2974L16.7803 30.4199" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M24.1035 26.0371V43.9844" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M24.1035 9.59766V20.7722" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M33.3477 39.791L34.2685 44.0859" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M32.2393 34.6226L29.8243 23.3548L27.8105 13.9629" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.1377 14.2065L21.0855 9.78242C21.1324 9.56586 21.3236 9.41211 21.5449 9.41211H26.4555C26.6768 9.41211 26.868 9.56586 26.9149 9.78242L27.123 10.7555" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19.3125 18.0508L18.1753 23.3542L16.2188 32.4836" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.5286 35.7051L13.7314 44.0873" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.4074 47.2967H9.65863C9.34082 47.2967 9.08301 47.0389 9.08301 46.722V44.8452C9.08301 44.5273 9.34082 44.2695 9.65863 44.2695H24.1028H38.3406C38.6584 44.2695 38.9162 44.5273 38.9162 44.8452V46.722C38.9162 47.0389 38.6584 47.2967 38.3406 47.2967H15.6887" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M26.793 23.3555H32.862" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.3203 23.3555H21.4977" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.2578 30.3262H30.9415" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.3873 26.2302H12.0625V20.4805H12.3873C13.8059 20.4805 14.956 21.6305 14.956 23.0492V23.6615C14.956 25.0801 13.806 26.2302 12.3873 26.2302Z" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M35.6127 20.4805H35.9374V26.2302H35.6127C34.1941 26.2302 33.0439 25.0801 33.0439 23.6614V23.0491C33.0439 21.6305 34.194 20.4805 35.6127 20.4805Z" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M32.5071 39.7916C33.8842 39.7916 35.0006 38.6753 35.0006 37.2982C35.0006 35.9211 33.8842 34.8047 32.5071 34.8047C31.13 34.8047 30.0137 35.9211 30.0137 37.2982C30.0137 38.6753 31.13 39.7916 32.5071 39.7916Z" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M26.001 25.1875L31.2626 30.2714" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.835 30.1811L22.1511 25.1875" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22.3428 9.06866V5.387C22.3428 5.12797 22.5528 4.91797 22.8118 4.91797H25.37C25.629 4.91797 25.839 5.12797 25.839 5.387V9.06866" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M24.1035 0.703125V4.72941" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M33.2666 0.703125C34.3371 1.77544 35.0008 3.25481 35.0008 4.88541C35.0008 6.516 34.3372 7.99538 33.2666 9.06769" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M31.167 2.80273C31.7001 3.33664 32.0296 4.0723 32.0296 4.8853C32.0296 5.6983 31.7001 6.43395 31.167 6.96786" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.9158 0.703125C13.8453 1.77544 13.1816 3.25481 13.1816 4.88541C13.1816 6.516 13.8452 7.99538 14.9158 9.06769" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.0149 2.80273C16.4819 3.33664 16.1523 4.0723 16.1523 4.8853C16.1523 5.6983 16.4819 6.43395 17.0149 6.96786" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M24.1273 25.8483C25.5044 25.8483 26.6207 24.7319 26.6207 23.3548C26.6207 21.9777 25.5044 20.8613 24.1273 20.8613C22.7502 20.8613 21.6338 21.9777 21.6338 23.3548C21.6338 24.7319 22.7502 25.8483 24.1273 25.8483Z" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.498 16.3828H28.1551" stroke={props.color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19.547 17.8382C20.5147 17.8382 21.2991 17.0537 21.2991 16.0861C21.2991 15.1184 20.5147 14.334 19.547 14.334C18.5794 14.334 17.7949 15.1184 17.7949 16.0861C17.7949 17.0537 18.5794 17.8382 19.547 17.8382Z" stroke="#515050" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_9835_271337">
      <rect width="48" height="48" fill="white"/>
      </clipPath>
      </defs>
  </svg>
  )
}

export default CustomTower;
 