import { Box } from "@mui/material";
import { useCallouts, useShuriPagination } from "../../../../../lib/hooks";
import { IFilter } from "../../../../../lib/interfaces";
import {
  CalloutLogsSearchFilter,
  CalloutLogsTable,
  Content,
  PageAppBar
} from "../../../../components";
import { AppBackgroundColor } from "../../../../config";

export function AllCalloutLogsPage() {
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = useShuriPagination(10);
  const { data, error, loading, refetch } = useCallouts();
  const handleFilterChange = async ({
    value,
    filters,
    mappedFilters
  }: IFilter) => {
    // await updateEventLogsData(mappedFilters)
  };
  return (
    <Box>
      <PageAppBar title="Callout Log" sub="All Callout Data" />
      <Content>
        <Box
          sx={{
            height: "auto",
            backgroundSize: "cover",
            backgroundColor: AppBackgroundColor,
            margin: 4,
          }}
        >
          <Box
            sx={{
              color: "white",
              marginBottom: 2,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CalloutLogsSearchFilter
              logs={data?.core_securitycallouts ?? []}
              onFilterUpdate={handleFilterChange}
            />
          </Box>
          <Box
            sx={{
              "@media (max-width: 600px)": {
                objectFit: "scale-down",
                display: "flex",
                width: "100%",
                margin: 0,
                overflowX: "hidden",
                paddingBottom: 10,
              },
            }}
          >
            <Box sx={{ width: "100%", overflowX: "auto" }}>
              <CalloutLogsTable
                data={data}
                page={page}
                rowsPerPage={rowsPerPage}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                loading={loading}
                error={error}
                refetch={refetch}
              />
            </Box>
          </Box>
        </Box>
      </Content>
    </Box>
  );
}
export default AllCalloutLogsPage;
