import {MediaTypesEnum} from "../../enums";

export const checkFileType = async (url: string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob.type;
}

export const getFileTypeFromUrl = (url: string) => {
    if (url) {
        const urlObj = new URL(url);
        const pathname = urlObj.pathname;
        const extension = pathname.split('.').pop();

        if (extension) {
            if (['jpg', 'jpeg', 'png', 'gif'].includes(extension.toLowerCase())) {
                return MediaTypesEnum.IMAGE;
            } else if (['mp4', 'avi', 'mkv'].includes(extension.toLowerCase())) {
                return MediaTypesEnum.VIDEO;
            }
        }
    }

    return MediaTypesEnum.OTHERS; // others type
}
