import {Box, Button} from "@mui/material";
import {Content, CreateVisit, JobsFilterSearch, JobsStatusFilter, JobsTable, PageAppBar} from "../../../../components";
import AddIcon from "@mui/icons-material/Add";
import {AppBackgroundColor, AppDarkBackgroundColor, InputBaseColorLight} from "../../../../config";
import {useNavigate} from "react-router-dom";
import {IJobsFilters, IFilter, useShuriPagination, useJobs, JobsContextProvider, useJobsContext} from "../../../../../lib";
import {useState} from "react";

const AllJobsPageContainer = () => {
    const navigate = useNavigate();
    const {page, rowsPerPage, handleChangePage, handleChangeRowsPerPage} = useShuriPagination(10);
    const [jobFilters, setJobFilters] = useState<IJobsFilters>({});

    const {data, error, loading, updateJobsData} = useJobs(
        page,
        rowsPerPage,
        jobFilters
    );
    const {onVisitCreateOpen, createVisitOpen} = useJobsContext()

    const handleRefreshJobs = async () => {
        await updateJobsData(jobFilters);
    }

    const handleFilterChange = ({value, filters, mappedFilters}: IFilter) => {
        setJobFilters(mappedFilters);
        handleChangePage(null,0)
    }

    const handleStatusUpdate = (status?: string) => {
        const filters = {
            ...jobFilters,
            status
        }
        setJobFilters(filters);
        handleChangePage(null,0)
    }
    return (
        <Box>
            <PageAppBar
                title="Job Management"
                sub="Manage your jobs and worker tasks here."
                ActionButton={() => (
                    <>
                        <Button
                            size="medium"
                            variant="outlined"
                            color="inherit"
                            onClick={() => onVisitCreateOpen()}
                            sx={{
                                color: InputBaseColorLight,
                                backgroundColor: AppDarkBackgroundColor,
                                borderRadius: '28px',
                                marginRight: '10px',
                                textTransform: 'capitalize',
                                border: 'none'
                            }}>
                            Create Visit
                        </Button>

                        <Button size="medium"
                                variant="contained"
                                color="info"
                                onClick={() => navigate('/jobs/create')}
                                startIcon={<AddIcon/>} sx={{
                            borderRadius: '28px', marginRight: '10px', textTransform: 'capitalize'
                        }}>
                            Create Job
                        </Button>
                    </>
                )}
            />
            <Content>
            <Box
            sx={{
              "@media (max-width: 600px)": {
                objectFit: "scale-down",
                display: "flex",
                width: "100%",
                margin: 0,
                overflowX: "hidden",
                paddingBottom: 10,
              },
            }}
          >
            <Box sx={{ width: "100%", overflowX: "auto" }}>
            <Box
                    sx={{
                        height: "auto",
                        backgroundSize: "cover",
                        backgroundColor: AppBackgroundColor,
                        margin: 4,
                    }}
                >
                    <Box
                        sx={{
                            color: "white",
                            marginBottom: 2,
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <JobsStatusFilter
                            onFilterUpdate={handleStatusUpdate}
                            status={jobFilters.status}/>
                        <JobsFilterSearch jobs={data?.core_job ?? []} onFilterUpdate={handleFilterChange}/>
                    </Box>

                    <JobsTable
                        data={data}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        onRefresh={handleRefreshJobs}
                        loading={loading}
                        error={error}
                    />
                </Box>
            </Box>
          </Box>
             
            </Content>

            {
                createVisitOpen && <CreateVisit
                onRefresh={handleRefreshJobs}
                />
            }
        </Box>
    )
}


export const AllJobsPage = () => (
    <JobsContextProvider>
        <AllJobsPageContainer/>
    </JobsContextProvider>
)