import gql from "graphql-tag";
import { getFirstDateOfCurrentMonth } from "../../filters";
import { perPage } from "../../../config";
import { useQuery } from "@apollo/client";

export const SITES_VISITS_QUERY = gql`
  query SITES_VISITIS($site_id: bigint, $limit: Int, $offset: Int) {
    core_visit(
      where: { core_job: { site_id: { _eq: $site_id } } }
      limit: $limit
      offset: $offset
    ) {
      start_datetime
      end_datetime
      id
      core_visitstatusenum {
        status
      }
      core_job {
        id
        ext_job_number
        description
        core_personnelassigneds {
          core_user {
            email
            first_name
            last_name
          }
        }
      }
    }
    core_visit_aggregate(where: { core_job: { site_id: { _eq: $site_id } } }) {
      aggregate {
        count(columns: id)
      }
    }
  }
`;

export const useSiteVisits = (
  site_id: any,
  date = getFirstDateOfCurrentMonth(),
  page = 1,
  limit = perPage
) => {
  const offset = page * limit;
  const { data, error, loading } = useQuery(SITES_VISITS_QUERY, {
    variables: { site_id, offset, limit },
  });
  return { data, error, loading };
};
