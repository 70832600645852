import { AccountCircle, KeyboardArrowDown } from "@mui/icons-material"
import { Box, IconButton, Typography } from "@mui/material"
import { Container } from "@mui/system"

interface AppBarActionProps {
    icon: React.ReactNode,
}

export function AppBarAction(props: AppBarActionProps) {
    return <Box sx={{paddingRight: 2}}>
        <IconButton
            size="large"
            edge="end"
            aria-haspopup="true"
            color="inherit">
            {props.icon}
        </IconButton>
    </Box>
}