import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { linChartOptions } from "../../../../../config";
import { Box } from "@mui/material";
import { useCompanyWideSiteChargeUnits } from "../../../../../lib/hooks";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function ElectricCost(props: { selectedDates?: any }) {
  const start_date = props.selectedDates[0];
  const end_date = props.selectedDates[1];

  const { data, error, loading } = useCompanyWideSiteChargeUnits(
    start_date,
    end_date
  );
  const graphData = {
    labels: data?.getcompanywidesitechargecostandunitsbycompanyid?.map(
      (estimate: any) => {
        const date = new Date(
          parseInt(estimate.year_month.substring(0, 4)), // Extract the year as an integer
          parseInt(estimate.year_month.substring(4)) - 1 // Extract the month as an integer (zero-indexed)
        );
        return moment(date).format("MMMM YYYY");
      }
    ),
    datasets: [
      {
        label: "Electricity Cost",
        data: data?.getcompanywidesitechargecostandunitsbycompanyid?.map(
          (estimate: any) => {
            return estimate.cost;
          }
        ),
        fill: true,
        borderColor: "#4490AE",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        pointRadius: 2,
      },
    ],
  };

  return (
    <Box sx={{ minHeight: "270px" }}>
      <Line options={linChartOptions("Months", "Cost")} data={graphData} />
    </Box>
  );
}
