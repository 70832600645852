import {Content, CreateJobForm, PageAppBar, StyledPageContainer} from "../../../../components";

export function CreateJobPage()
{
    return (
        <>
            <PageAppBar
                title="Create Job"
                sub="Fill the form below to create a new job"
            />
            <Content>
                <StyledPageContainer>
                    <CreateJobForm />
                </StyledPageContainer>
            </Content>
        </>
    )
}