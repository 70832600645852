import { ComponentMeta, ComponentStory } from "@storybook/react";
export function formatAmount(value: number) {
  return new Intl.NumberFormat().format(value);
}

export class StoryMaker<P, T extends React.ComponentType<P>> {
  private component: T;
  private Template: ComponentStory<T>;

  constructor(component: T) {
    this.component = component;
    this.Template = (args: any) => <this.component {...args} />;
  }

  static create<I>(component: React.ComponentType<I>) {
    return new StoryMaker<I, typeof component>(component);
  }

  private getStory(storyGrouping: string) {
    return {
      title: `${storyGrouping}/${this.component.name}`,
      component: this.component,
      argTypes: {},
    } as ComponentMeta<T>;
  }

  getComponentStory() {
    return this.getStory("Components");
  }

  getPageStory() {
    return this.getStory("Page");
  }

  createExample(args?: P) {
    let obj = this.Template.bind({});
    obj.args = { ...args };
    return obj;
  }
}

export const convertMediaNameToUrl = (
  media: string,
  site_id: string | undefined,
  modelName: string,
  camera_id: string,
  incident_id?: number
) => {
  const mediaPrefix = process.env.REACT_APP_MEDIA_PREFIX;
  return `${mediaPrefix}/camera-feeds/site/${site_id}/camera/${camera_id}/incident/${incident_id}/suspact/${media}`;
};

export const sortByNotNull = (data: any) => {
  return data?.slice().sort((a: any, b: any) => {
    if (b?.closed_on === null && a?.closed_on === null) {
      return 0;
    } else if (b?.closed_on === null) {
      return 1;
    } else if (a?.closed_on === null) {
      return -1;
    } else {
      return b?.closed_on.localeCompare(a?.closed_on);
    }
  });
};


export const isLoadShedding = (esps: { start_date: String; end_date: String }[]): boolean => {
    return esps?.some((esp) => {
      const currentTime = new Date();
      const startDate = new Date(esp?.start_date?.toString());
      const endDate = new Date(esp?.end_date?.toString());
      return startDate <= currentTime && currentTime <= endDate;
    });

};
