import { useMutation } from "@apollo/client";
import { Add } from "@mui/icons-material";
import { Alert, Box, Button, Grid } from "@mui/material";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { number, object, string } from "yup";
import {
  ADD_JOB_MUTATION,
  ISelectInput,
  IVisit,
  UPDATE_JOB_MUTATION,
  useJobDetails,
  useNotification,
  useVisitStatusesSubData
} from "../../../../lib";
import { useForm, useYupValidateForm } from "../../../../lib/form/hooks";
import useEmail from "../../../../lib/hooks/email/email.hook";
import {
  ShuriFormCloseAction,
  StyledFormActionRow,
  StyledFormRow
} from "../../form";
import { StyledPageCard } from "../../page";
import { AddJobDetailsForm, mapJobDetailsInput } from "./add-job-details";
import {
  AddJobVisitForm,
  IVisitInput,
  jobVisitInitialState,
  mapVisitDetailsInput
} from "./add-visit-details";
import { useSendNotification } from "../../../../lib/hooks/notifications/notification.hooks";

const today = new Date();
const validationSchema = object().shape({
  site_id: number()
    .typeError("Site is required. Provide a valid value")
    .required("Site is required"),
  ext_job_number: string()
    .typeError("Job Number is required. Provide a valid value")
    .max(8, "Job number too long")
    .required("External Job Number is required"),
  description: string()
    .typeError("Description required. Provide a valid value")
    .required("Description is required"),
  personale_id: number()
    .typeError("Worker is required. Provide a valid value")
    .required("Worker is required"),
  status_id: number()
    .typeError("Status is required. Provide a valid value")
    .required("Status is required")
  // visits: array(
  //     object().shape({
  //         status_id: number().typeError('Status is required. Provide a valid value').required('Status is required'),
  //         start_datetime: date().typeError('Start Date is required. Provide a valid value').required('Start Date is required')
  //             // .min(today, 'Start Date must be greater than now')
  //             .max(ref('end_datetime'), 'Start Date must be before End Date'),
  //         end_datetime: date().typeError('End Date is required. Provide a valid value').required('End Date is required')
  //             .min(ref('start_datetime'), 'End Date must be after Start Date'),
  //         comment: string().typeError('Comment is required. Provide a valid value').required('Comment is required'),
  //     })
  // )
});

export function CreateJobForm() {
  const { id } = useParams();
  const { data: jobData, handleRefetch } = useJobDetails(id);
  const { inputs, handleChange, setInputs } = useForm({
    ...mapJobDetailsInput(jobData?.core_job_by_pk),
    visits:
      jobData?.core_job_by_pk?.core_visits?.map((visit: IVisit) => ({
        ...mapVisitDetailsInput(visit),
        gen_key: new Date().toISOString()
      })) ?? []
  });
  const { errors, validateField, validateForm, hasErrors } = useYupValidateForm(
    inputs,
    validationSchema
  );
  const visitRowsContainerRef = useRef<HTMLDivElement | null>(null);
  const { data: visitStatusData } = useVisitStatusesSubData();
  const [visitStatus, setVisitStatus] = useState<ISelectInput[]>();
  const [addJobMutation, { error: createError }] =
    useMutation(ADD_JOB_MUTATION);
  const [updateJobMutation, { error: updateError }] =
    useMutation(UPDATE_JOB_MUTATION);
  const { notify } = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    if (jobData?.core_job_by_pk) {
      setInputs({
        ...mapJobDetailsInput(jobData?.core_job_by_pk),
        visits:
          jobData?.core_job_by_pk?.core_visits?.map((visit: IVisit) =>
            mapVisitDetailsInput(visit)
          ) ?? []
      });
    }
  }, [jobData]);

  useEffect(() => {
    if (visitStatusData) {
      setVisitStatus(
        visitStatusData.core_visitstatusenum?.map((status: any) => ({
          label: status.status,
          value: status.id
        }))
      );
    }
  }, [visitStatusData]);

  useEffect(() => {
    if (createError) {
      notify({
        status: "error",
        open: true,
        message: "An error occurred trying to create job, try again later!"
      });
    }
    if (updateError) {
      notify({
        status: "error",
        open: true,
        message: "An error occurred trying to update job, try again later!"
      });
    }
  }, [createError, updateError]);

  const handleAddVisitRow = () => {
    setInputs({
      ...inputs,
      visits: [
        ...inputs.visits,
        {
          ...jobVisitInitialState,
          gen_key: new Date().toISOString()
        }
      ]
    });

    if (visitRowsContainerRef.current) {
      const lastVisitRow = visitRowsContainerRef.current
        .lastElementChild as HTMLElement;
      if (lastVisitRow) {
        lastVisitRow.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }
  };

  const handleRemoveVisit = (index: number) => {
    const updatedVisits = [...inputs.visits].filter((_, i) => i !== index);
    const updatedInputs = {
      ...inputs,
      visits: [...updatedVisits]
    };
    setInputs(updatedInputs);
  };

  const handleVisitUpdate = (index: number, visit: IVisitInput) => {
    const updatedVisits = [...inputs.visits];
    updatedVisits[index] = visit;
    const updatedInputs = {
      ...inputs,
      visits: [...updatedVisits]
    };
    setInputs(updatedInputs);
  };

  const { sendEmail } = useEmail();
const {sendNotification} = useSendNotification();
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (await validateForm()) {
      const {
        site_id,
        personale_id,
        status_id,
        ext_job_number,
        description,
        visits
      } = inputs;
      if (!id) {
        await addJobMutation({
          variables: {
            site_id,
            personale_id,
            status_id,
            ext_job_number,
            description,
            created_on: today,
            visits: visits.map((visit: IVisitInput) => ({
              ...visit,
              gen_key: undefined,
              job_id: undefined
            }))
          },
          onCompleted: async (createdJobData) => {
            if (createdJobData) {
              try {
                await sendEmail("NEW_JOB_EMAIL", {
                  toAddress:
                    createdJobData.insert_core_job_one
                      .core_personnelassigneds[0].core_user.email,
                  subject: "A new job has been assigned to you",
                  emailData: {
                    name: createdJobData.insert_core_job_one
                      .core_personnelassigneds[0].core_user.first_name,
                    jobName: "Site Visit",
                    jobId: createdJobData.insert_core_job_one.id,
                    siteName: createdJobData.insert_core_job_one.core_site.name,
                    companyName: "",
                    siteAddress:
                      createdJobData.insert_core_job_one.core_site.municipality,
                    visits: createdJobData.insert_core_job_one.core_visits.map(
                      (visit: any) => ({
                        sn: visit.id,
                        startDate: visit.start_datetime,
                        endDate: visit.end_datetime,
                        comment: visit.comment
                      })
                    )
                  }
                });

                await sendNotification(
                  {
                    "event_type":"Job Assigned To You",
                    "email": createdJobData.insert_core_job_one
                    .core_personnelassigneds[0].core_user.email,
                    "object_id":createdJobData.insert_core_job_one.id,
                    "object_type":"job",
                    "user_id":createdJobData.insert_core_job_one
                    .core_personnelassigneds[0].core_user.id
                }
                )
              } catch (error) {
                console.log({ error });
              }
              navigate(`/jobs`);
              notify({
                status: "success",
                open: true,
                message: "Job created successfully!"
              });
            }
          },
          onError(error) {
            notify({
              status: "error",
              open: true,
              message:
                "An error occurred trying to create job, try again later!"
            });
          }
        });
      } else {
        await updateJobMutation({
          variables: {
            job_id: id,
            site_id,
            personale_id,
            status_id,
            ext_job_number,
            description,
            created_on: today,
            visits: visits.map((visit: IVisitInput) => ({
              ...visit,
              job_id: id,
              gen_key: undefined
            }))
          },
          onCompleted(updatedJobData) {
            if (updatedJobData) {
              handleRefetch();
              navigate(`/jobs`);
              notify({
                status: "success",
                open: true,
                message: "Job updated successfully!"
              });
            }
          },
          onError(error) {
            notify({
              status: "error",
              open: true,
              message:
                "An error occurred trying to create job, try again later!"
            });
          }
        });
      }
    }
  };

  return (
    <StyledPageCard>
      <form onSubmit={handleSubmit}>
        <AddJobDetailsForm
          inputs={inputs}
          handleChange={handleChange}
          errors={errors}
          validateField={validateField}
        />

        <StyledFormRow>
          <Grid container spacing={2} className="form-title-container">
            <Grid item xs={12} md={6}>
              <Box className="form-row-title">Site Visits</Box>
              <Box>
                {errors.visits && (
                  <Alert severity="error">Visits forms has errors.</Alert>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="form-row-action">
                <Button
                  className="form-row-action-button"
                  startIcon={<Add />}
                  onClick={handleAddVisitRow}
                >
                  Add Visit
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Box ref={visitRowsContainerRef}>
            {inputs.visits.map((visit: IVisitInput, index: number) => (
              <AddJobVisitForm
                validateInputField={validateField}
                onDelete={handleRemoveVisit}
                key={`visit-form-${index}-${visit.gen_key}`}
                visit={visit}
                status={visitStatus ?? []}
                index={index}
                onInputChange={handleVisitUpdate}
              />
            ))}
          </Box>
        </StyledFormRow>

        <StyledFormActionRow>
          <ShuriFormCloseAction
            label="Cancel"
            handleClick={() => navigate("/jobs")}
          />

          <Button
            variant="contained"
            color="info"
            type="submit"
            disabled={hasErrors}
          >
            Save Job
          </Button>
        </StyledFormActionRow>
      </form>
    </StyledPageCard>
  );
}
