import {Accordion} from "@mui/material";
import {styled} from "@mui/material/styles";
import { AppBackgroundColor } from "../../config";

export const ShuriStyledAccordion = styled(Accordion)(({theme}) =>({
    boxShadow: 'none',
    marginBottom: '10px',
    borderColor: 'transparent',
    borderTop: 'none',
    "@media (max-width: 600px)":{
      background:AppBackgroundColor
    }
}));