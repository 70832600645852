import {holderFn, ICamera, ICameraStream, useLiveFeedControlsHooks} from "../../../lib";
import React, {useEffect, useState} from "react";
import {useLiveFeeds} from "../../../lib";
import {ShuriLiveStreamPlayer, ShuriVideoError, ShuriVideoMeta} from "../video";
import {Box} from "@mui/material";
import {Core_Camera, Core_Site} from "../../../generated/graphql";
import {CameraDialog} from "./camera-dialog";

interface ICameraDisplayProps {
    camera: Core_Camera | ICamera;
    site: Core_Site;
    onDelete?: (camera?: ICameraStream) => void;
    deletingStream?: boolean;
    has_delete?: boolean
}

interface ICameraRenderProps {
    has_close?: boolean
}

export function CameraDisplay({
                                  camera, site, onDelete = holderFn, deletingStream = false, has_delete = false
                              }: ICameraDisplayProps) {
    const {url, loading, error, getLiveStreamUrl} = useLiveFeeds(camera.video_stream_name);
    const [loaded, setLoaded] = useState(false);
    const [fullscreen, setFullScreen] = useState(false);
    const [toggling, setToggling] = useState(false);
    const {toggleStream, loading: toggleLoading} = useLiveFeedControlsHooks();
    let streamTimeout: NodeJS.Timeout;
    let streamInterval: NodeJS.Timeout | string | number | undefined;

    useEffect(() => {
        if (loaded) {
            streamInterval = setInterval(keepStreamAlive, 10000);
        } else {
            clearInterval(streamInterval);
        }

        return () => {
            if (streamInterval !== null) {
                clearInterval(streamInterval);
            }
        };
    }, [loaded]);

    const handleVideoLoad = () => {
        setLoaded(true);
    };

    const errorMessage = () => {
        if (toggleLoading || toggling || loading) return;
        if (error) return "Could not load video"
        if (!loaded) return "Click Play to start the stream";
    }

    const keepStreamAlive = async () => {
        try {
            await updateStream('keep-alive');
        } catch (error) {
            console.error('Error keeping stream alive:', error);
        }
    };

    const updateStream = async (status: 'start' | 'stop' | 'keep-alive') => {
        const data = {
            stream_name: camera.video_stream_name,
            site_id: site.site_id ?? '',
            action: status,
        };
        await toggleStream(data);
        if (status !== 'keep-alive') {
            setToggling(true);
            const waitTime = status === "start" ? 10000 : 15000;
            clearTimeout(streamTimeout);
            streamTimeout = setTimeout(async () => {
                await getLiveStreamUrl(camera.video_stream_name);
                setToggling(false);
            }, waitTime);
        } else {
            setToggling(false);
        }

        if (status === 'stop') {
            clearInterval(streamInterval);
        }
    }


    const RenderCamera = ({has_close}: ICameraRenderProps) => (
        <Box style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <ShuriVideoMeta
                camera={{
                    core_camera: camera,
                    core_site: site
                }}
                has_delete={has_delete}
                has_close={has_close}
                status={site?.core_sitestatus?.site_status}
                description={`${camera?.camera_name ?? ''}`}
                onFullScreen={() => setFullScreen(!fullscreen)}
                onClose={() => setFullScreen(false)}
                onDelete={onDelete}
                deleting={deletingStream}
                fullscreen={fullscreen}
            />

            <Box style={{
                position: 'relative',
                height: '100%',
                width: '100%',
            }}>
                <ShuriVideoError
                    loading={toggleLoading || toggling || loading}
                    error={!(toggleLoading || toggling || loading) ? errorMessage() : undefined}
                />
                <ShuriLiveStreamPlayer
                    key={url}
                    h={'350px'}
                    url={url}
                    onLoaded={handleVideoLoad}
                    loading={loading}
                    error={!loading ? errorMessage() : undefined}
                    onPlay={() => updateStream('start')}
                    onStop={() => updateStream('stop')}
                />
            </Box>
        </Box>
    )

    return (
        <>
            <RenderCamera/>

            <CameraDialog
                open={fullscreen}
                onClose={() => setFullScreen(false)}
            >
                <RenderCamera
                    has_close
                />
            </CameraDialog>
        </>
    )
}