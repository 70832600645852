import { Box, Divider, Grid, Typography } from "@mui/material";
import { BaseColor, BaseColorLight, InputBaseColor } from "../../../config";
import { FC, ReactNode } from "react";
import { PageBreadcrumbs } from "../page-breadcrumbs";
import Notifications from "./notifications";

interface IPageAppBarProps {
    title: string
    sub?: string;
    subcomponent?: ReactNode;
    ActionButton?: FC;
    breadcrumbs?: ReactNode[]
}

export function PageAppBar({ title, sub, subcomponent, ActionButton, breadcrumbs = [] }: IPageAppBarProps) {
    return (
        <Box sx={{
            margin: 4,
            marginTop: '-75px'
        }}>

            {
                breadcrumbs?.length > 0 &&
                <Box
                    sx={{
                        paddingTop: '20px',
                        marginBottom: '-30px'
                    }}>
                    <PageBreadcrumbs
                        breadcrumbs={breadcrumbs} />
                </Box>
            }
            <Grid container spacing={2} sx={{
                padding: '30px 0'
            }}>
                <Grid item xs={12} md={8} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Box sx={{
                        flex: 1
                    }}>
                        <Typography variant="h1" component="h2" sx={{
                            fontFamily: 'Outfit',
                            fontWeight: '500',
                            fontSize: '30px',
                            lineHeight: '38px',
                            color: 'white',
                        }}>
                            {title}
                        </Typography>
                        {
                            sub &&
                            <Typography sx={{
                                fontWeight: '400',
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: BaseColorLight,
                                paddingTop: '4px'
                            }}>
                                {sub}
                            </Typography>
                        }
                        {
                            subcomponent && (
                                <Box sx={{
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    lineHeight: '24px',
                                    color: InputBaseColor
                                }}>
                                    {subcomponent}
                                </Box>
                            )
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Box sx={{
                        flex: 1,
                        display: { xs: 'none', md: 'flex' },
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    }}>
                        <Box>
                            {ActionButton && <ActionButton />}
                        </Box>
                        <Notifications />
                    </Box>
                </Grid>
            </Grid>

            <Divider
                sx={{
                    color: 'white',
                    backgroundColor: BaseColor
                }}
            />
        </Box>
    )
}