import React, { useEffect, useState} from "react";
import { ApolloProvider } from '@apollo/client'
import { useAuthenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import createClient from '../../lib/createClient'
import { getClaims } from '../../lib';
import { AddInventory } from './add-inventory'
import { AddJob } from './add-job'
import './App.css'
import ForgotPassword from './authentication/ForgotPassword'
import LoginPage from './authentication/login'
import ResetPassword from './authentication/ResetPassword'
import {
  AllAccessRequestsPage,
  AllArmedResponsePage,
  AllCalloutLogsPage,
  AllCameraStreamsPage,
  AllEventLogsPage,
  AllInventoryPage,
  AllJobsPage,
  AllSitesPage,
  AllVisitsPage,
  CreateJobPage,
  CreateSitePage,
  DashboardLandingPage,
  DashboardPage,
  DashboardRoot,
  DashboardSite,
  EventLogPage,
  NotificationsPage,
  SchedulingPage,
  UsersPage
} from './dashboard'
import {AuthUserContextProvider} from "../../lib";
import { SitesExplorer } from "./dashboard/sites/site-explorer";


const router = createBrowserRouter([
  {
    path: '/',
    element: <DashboardRoot />,
    children: [
      { path: '/', element: <DashboardLandingPage /> },
      { path: '/site/:id', element: <DashboardSite /> },
      { path: '/sites', element: <AllSitesPage /> },
      { path: '/sites/:filter', element: <SitesExplorer /> },
      { path: '/sites/add-site', element: <CreateSitePage /> },
      { path: '/site/:id/edit', element: <CreateSitePage /> },
      { path: '/scheduling/:id', element: <SchedulingPage /> },
      { path: '/event-logs', element: <AllEventLogsPage /> },
      { path: '/event-log/:id', element: <EventLogPage /> },
      { path: '/visits', element: <AllVisitsPage /> },
      { path: '/jobs', element: <AllJobsPage /> },
      { path: '/jobs/create', element: <CreateJobPage /> },
      { path: '/jobs/:id/edit', element: <CreateJobPage /> },
      { path: '/jobs/add-jobs', element: <AddJob /> },
      { path: '/access-requests', element: <AllAccessRequestsPage /> },
      { path: '/inventory', element: <AllInventoryPage /> },
      { path: '/inventory/add-inventory', element: <AddInventory /> },
      { path: '/users', element: <UsersPage /> },
      { path: '/dashboard/:id', element: <DashboardPage /> },
      { path: '/*', element: <DashboardLandingPage /> },
      { path: '*', element: <DashboardLandingPage /> },
      { path: '/notifications', element: <NotificationsPage /> },
      { path: '/camera-streams', element: <AllCameraStreamsPage /> },
      { path: '/armed-response', element: <AllArmedResponsePage /> },
      { path: '/callout-logs', element: <AllCalloutLogsPage /> },
    ]
  }
])

const AuthRoutes = createBrowserRouter([
  {
    path: '/',
    children: [
      { path: '/', element: <LoginPage /> },
      { path: '/forgot-password', element: <ForgotPassword /> },
      { path: '/reset-password', element: <ResetPassword /> }
    ]
  }
])

export const App = () => {
  const [companyEndpoint, setEndpoint] = useState(null);
  const { authStatus } = useAuthenticator((context) => [
    context.authStatus,
    context.user
  ])
  useEffect(() => {
    async function fetchData() {
      if (authStatus === "authenticated") {
        try {
          const claims= await getClaims();
          setEndpoint(claims.endpoint);
        } catch (error) {}
      }
    }

    fetchData();
  }, [authStatus]);

  if (authStatus === 'authenticated' && companyEndpoint ) {
    const client = createClient({hasuraEndpoint : companyEndpoint})
    return (
      <ApolloProvider client={client}>
        <AuthUserContextProvider>
          <RouterProvider router={router} />;
        </AuthUserContextProvider>
      </ApolloProvider>
    )
  }
  return <RouterProvider router={AuthRoutes} />
}
