export function moneyFilter(amount = 0, currency='ZAR') {
    const options = {
        style: 'currency',
        currency,
        minimumFractionDigits: 2
    }

    const formatter = new Intl.NumberFormat('en-US', options);

    return formatter.format(amount)
}