import {
    Alert,
    AlertTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide
} from "@mui/material";
import {forwardRef, ReactElement, Ref, useEffect, useState} from "react";
import {TransitionProps} from "@mui/material/transitions";
import {ICameraStream, LayoutEnum, useCameraByIdQuery, useCameraStreamsContext} from "../../../lib";
import {ShuriSpinnerText} from "../loading";
import {errorBase} from "../../config";

interface AddCameraDialogProps {
    open: boolean
    camera_id?: string;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function AddCameraDialog({camera_id, open}: AddCameraDialogProps) {
    const [isOpen, setOpen] = useState(open);
    const [isAvailable, setIsAvailable] = useState(false);
    const [camera, setCamera] = useState<ICameraStream>();
    const {data, loading, error} = useCameraByIdQuery(camera_id);
    const {handleSelectCameraStream, streams, setPreview, setLayout} = useCameraStreamsContext();

    useEffect(() => {
        setOpen(open)
    }, [open]);

    useEffect(() => {
        if (data) {
            const cameraData = {
                core_camera: data?.core_camera_by_pk,
                core_site: data?.core_camera_by_pk?.core_box?.core_site
            }
            setCamera(cameraData);
            setIsAvailable(!!data?.core_camera_by_pk);
        }
    }, [data]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddStream = () => {
        if (camera) {
            handleSelectCameraStream([
                ...streams,
                camera
            ]);
            setPreview(camera);
            setLayout(LayoutEnum.HIGHLIGHT)
            setOpen(false);
        }
    }

    const RenderContent = () => {
        if (loading) {
            return (
                <ShuriSpinnerText
                    loadingText={`Checking camera availability, kindly wait...`}
                />
            )
        }
        if (error) {
            return (
                <Alert severity="error" variant="outlined">
                    <AlertTitle>Error</AlertTitle>
                    <strong>An error occurred when verifying stream availability!</strong>
                </Alert>
            )
        }

        if (!isAvailable) {
            return (
                <DialogContentText id="alert-dialog-slide-description-404">
                    <strong style={{color: errorBase}}>Oops!</strong> The camera you are trying to access is not available. It may have been
                    recently removed or never existed. Try again please or
                    contact support for further assistance.
                </DialogContentText>
            )
        }

        return (
            <DialogContentText id="alert-dialog-slide-description">
                The camera you are trying to access is not in the current list of streams selected for this
                page.
                Would you like to add the stream and preview it's content?
            </DialogContentText>
        )
    }

    return (
        <>
            <Dialog
                open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Add camera to page streams?"}</DialogTitle>
                <DialogContent>
                    <RenderContent/>
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{
                            textTransform: 'capitalize'
                        }}
                        onClick={handleClose}>Dismiss</Button>
                    {
                        isAvailable &&
                        <Button
                            disabled={!isAvailable}
                            sx={{
                                textTransform: 'capitalize'
                            }}
                            onClick={handleAddStream}>Add Stream</Button>
                    }
                </DialogActions>
            </Dialog>
        </>
    )
}