import SiteDashboard from "./site-dashboard";
import {ShuriTabs} from "../tab";
import {Box} from "@mui/material";
import {SiteDetails} from "./site-details";
import {SiteInventories} from "./site-inventories";
import {ArmedResponseDetails} from "./site-armed-response";

export default function SiteContainer() {
    const tabs = [
        {
            title: 'Dashboard',
            component: <SiteDashboard/>
        },
        {
            title: 'Details',
            component: <SiteDetails/>
        },
        {
            title: 'Inventory',
            component: <SiteInventories/>
        },
        {
            title: 'Armed Response',
            component: <ArmedResponseDetails/>
        }
    ]
    return (
        <Box>
            <ShuriTabs
                name='site-details'
                tabs={tabs}
            />
        </Box>
    )
}