export function calculateHoursDifference(startDate: Date, endDate: Date): string {
    const millisecondsPerMinute = 1000 * 60; // Number of milliseconds in a minute
    const millisecondsPerHour = millisecondsPerMinute * 60; // Number of milliseconds in an hour
    const millisecondsPerSecond = 1000; // Number of milliseconds in a second
    
    const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
    const secondsDifference = differenceInMilliseconds / millisecondsPerSecond;

    if (secondsDifference < 60) {
        return `${Math.round(secondsDifference)} second${secondsDifference > 1 ? 's' : ''} ago`;
    }

    const minutesDifference = differenceInMilliseconds / millisecondsPerMinute;
    if (minutesDifference < 60) {
        return `${Math.round(minutesDifference)} minute${minutesDifference > 1 ? 's' : ''} ago`;
    }

    const hoursDifference = differenceInMilliseconds / millisecondsPerHour;

    switch (true) {
        case hoursDifference < 24:
            return `${Math.round(hoursDifference)} hour${hoursDifference > 1 ? 's' : ''} ago`;
        case hoursDifference < 24 * 7:
            const days = Math.floor(hoursDifference / 24);
            return `${days} day${days > 1 ? 's' : ''} ago`;
        case hoursDifference < 24 * 7 * 4:
            const weeks = Math.floor(hoursDifference / (24 * 7));
            return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
        case hoursDifference < 24 * 7 * 4 * 12:
            const months = Math.floor(hoursDifference / (24 * 7 * 4));
            return `${months} month${months > 1 ? 's' : ''} ago`;
        default:
            const years = Math.floor(hoursDifference / (24 * 7 * 4 * 12));
            return `${years} year${years > 1 ? 's' : ''} ago`;
    }
}
