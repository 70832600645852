import * as React from 'react';
import {styled} from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {Box} from '@mui/material';
import {ICamera} from "../../../lib/interfaces";
import {BaseColor} from "../../config";


const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({theme}) => ({
    border: '1px solid #7F7E7E',
    borderRadius: '8px',
    flex: '1',
    padding: '5px',
    '& .MuiToggleButtonGroup-grouped': {
        padding: '16px',
        color: 'white',
        flex: 1,
        marginRight: theme.spacing(1),
        maxWidth: '360px',
        minWidth: '90px',
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
            color: 'white'
        },
        '&.Mui-selected': {
            backgroundColor: BaseColor,
            borderRadius: '8px',
        },
        '@media (max-width: 780px)': {
            width: '18%'
        }
    },
}));

interface ICameraButtons {
    cameras?: ICamera[],
    onSelectCamera: (index: number) => void,
    onSelectAll: (value: string) => void
}

export function ToggleButtons({
                                  cameras = [],
                                  onSelectCamera,
                                  onSelectAll
                              }: ICameraButtons) {
    const [alignment, setAlignment] = React.useState('left');

    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        setAlignment(newAlignment);
    };

    return (
        <Box sx={{
            flex: '1',
            fontSize: '14px',
            display: 'flex',
            gap: '4px',
        }}
        >
            <StyledToggleButtonGroup
                value={alignment}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
            >
                <ToggleButton
                    onClick={() => {
                        onSelectAll('all')
                    }}
                    value={'camera-button-all'}>
                    All Cameras
                </ToggleButton>
                {
                    cameras.map((camera: ICamera, index: number) => (
                        <ToggleButton
                            key={`camera-button-${index}`}
                            onClick={() => {
                                onSelectCamera(index)
                            }}
                            value={`camera-button-${index}`}>
                            <>
                                Camera {index}
                            </>
                        </ToggleButton>
                    ))
                }
            </StyledToggleButtonGroup>
        </Box>
    );
}
