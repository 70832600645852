import {useMediaUrl} from "../../../lib/hooks/media";
import React, {ReactNode, useState} from "react";
import {Box} from "@mui/material";
import {ShuriVideoDialog} from "../video";
import {ShuriImageDialog} from "../image";
import {MediaTypesEnum} from "../../../lib";

interface IDetectionVideoDialogProps {
    event: any
    children: ReactNode;
}
export function DetectionVideoDialog({event, children}: IDetectionVideoDialogProps) {
    const [open, setOpen] = useState(false);

    const ShowMedia = ({show}: {show: boolean}) => {
        const media = event.media;
        const [loaded, setLoaded] = useState(false);
        const {url, type, loading} = useMediaUrl(media)

        const handleMediaLoad = () => {
            setLoaded(true);
        };

        const errorMessage = () => {
            if (!event) return "No video resource!"
            if (!loaded) return "Video not loaded, please wait...";
        }

        if (type === MediaTypesEnum.IMAGE){
            return (
                <ShuriImageDialog
                    selected={0}
                    images={[url ?? '']}
                    open={show}
                    onClose={() => setOpen(false)} />
            )
        }

        return (
            <ShuriVideoDialog
                loading={loading}
                url={url}
                open={show}
                onClose={() => setOpen(false)}
                onLoaded={handleMediaLoad}
                error={errorMessage()}
                title={`${event?.core_camera?.camera_name ?? ''} - ${event?.core_camera?.camera_id}`}
            />
        )
    }

    return (
        <>
            <Box onClick={() => setOpen(true)} sx={{
                cursor: 'pointer'
            }}>
                {children}
            </Box>

            {
                open && <ShowMedia show={open} />
            }
        </>
    )
}
