import {getAuthData} from "../../authData";
import {API} from "aws-amplify";
import {useEffect, useState} from "react";
import {getFileTypeFromUrl} from "../../helpers";


const fetchMediaUrl = async (media: string) => {
    const { idToken } = await getAuthData();
    const token = idToken.jwtToken;

    const requestData = {
        headers: {
            Authorization: token ? `Bearer ${token}` : "",
        },
    };

    return await API.get(
        "shurikonektfeapi",
        `/get-share-url?objectKey=${media}`,
        requestData
    );
};


export const useMediaUrl = (media?: string) => {
    const [url, setUrl] = useState<string | undefined>();
    const [type, setType] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();

    useEffect(() => {
       if (media){
           (async ()=>{
               setLoading(true);
               setError(undefined);
               try {
                   const response = await fetchMediaUrl(media);
                   setUrl(response);

                   const fileType = getFileTypeFromUrl(response);
                   setType(fileType ?? undefined)
               }
               catch (e) {
                   setError("Error fetching media URL");
               }
               finally {
                   setLoading(false);
               }
           })()
       }
    }, [media]);

    return { url, type, loading, error, setUrl };
}