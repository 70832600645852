import {Box, Typography} from "@mui/material";
import {InputBaseColor, InputBaseColorLight, MetricsCardBackground} from "../../config";
import {ShuriLiveStreamPlayer} from "../video";
import React from "react";

interface IAddMoreCameraProps {
    title: string;
    sub: string;
    onClickAction: () => void
}

export function AddMoreCamera(
    {title = 'Add Camera', sub = '', onClickAction}: IAddMoreCameraProps
) {
    return (
        <Box style={{
            position: 'relative',
            height: '100%',
        }}>
            <Box
                onClick={onClickAction}
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    zIndex: 10,
                    backgroundColor: MetricsCardBackground,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '8px',
                    cursor: 'pointer'
                }}>
                <Box>
                    <svg xmlns="http://www.w3.org/2000/svg"
                         width="68"
                         height="68"
                         fill={InputBaseColorLight}
                         viewBox="0 0 256 256">
                        <path
                            d="M251.77,73a8,8,0,0,0-8.21.39L208,97.05V72a16,16,0,0,0-16-16H32A16,16,0,0,0,16,72V184a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V159l35.56,23.71A8,8,0,0,0,248,184a8,8,0,0,0,8-8V80A8,8,0,0,0,251.77,73ZM192,184H32V72H192V184Zm48-22.95-32-21.33V116.28L240,95Z"></path>
                    </svg>
                </Box>
                <Typography
                    sx={{
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontSize: '24px',
                        lineHeight: '20px',
                        textAlign: 'center',
                        color: '#FFFFFF',
                        marginBottom: '10px',
                        '&:hover': {
                            textDecoration: 'underline'
                        }
                    }}
                >{title}</Typography>
                <Typography
                    sx={{
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontSize: '14px',
                        lineHeight: '20px',
                        textAlign: 'center',
                        color: InputBaseColor,
                        marginBottom: '16px'
                    }}
                >{sub}</Typography>
            </Box>
            <ShuriLiveStreamPlayer
                empty={true}
                key={''}
                h={'350px'}
                url={''}
                onLoaded={() => {
                }}/>
        </Box>
    )
}