import gql from "graphql-tag";
import {useQuery} from "@apollo/client";

export const PAYMENT_TYPE = gql`
    query PAYMENT_TYPE($id: bigint = "28") {
        core_agreement(where: {site_id: {_eq: $id}}) {
            core_tariff {
                    payment_type
            }
            site_id
        }
    }
`;

export const useSitePaymentType = (id: number) => {
    const {data, error, loading} = useQuery(PAYMENT_TYPE, {
        variables: {id}
    });
    return {data, error, loading};
}
