import gql from "graphql-tag";
import {useQuery} from "@apollo/client";

export const EQUIPMENT_TYPE_QUERY = gql`
    query EQUIPMENT_TYPE_QUERY {
        core_equipmenttype {
            name
            id
        }
    }
`;

export const useEquipmentType = () => {
    const {data, error, loading} = useQuery(EQUIPMENT_TYPE_QUERY);
    return {data, error, loading};
}