import {useParams} from "react-router-dom";
import {getFirstDateOfCurrentMonth} from "../../../../../lib";
import {SiteContextProvider} from "../../../../../lib";
import SiteComponent from "../../../../components/site";

export function DashboardSite(props: any) {
    let {id} = useParams();
    return (
        <SiteContextProvider id={id} date={getFirstDateOfCurrentMonth()}>
            <SiteComponent/>
        </SiteContextProvider>
    )
}
