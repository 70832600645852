import { Box, Card, Typography, IconButton, ButtonGroup } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { MapPinLine, PoliceCar } from "@phosphor-icons/react";
import format from "date-fns/format";
import { Core_Securitycallouts } from "../../../generated/graphql";
import { MetricsCardBackground, SideBarButtonTextColor } from "../../config";
import { CancelCalloutAction, CalloutChat } from "../calllout-actions";
type ICalloutSummaryCardProps = {
  callout: Core_Securitycallouts;
  onClick: () => void;
  handleRefreshCallouts: () => void;
};
export function CalloutSummaryCard({
  callout,
  onClick,
  handleRefreshCallouts,
}: ICalloutSummaryCardProps) {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        minWidth: 300,
        marginRight: "10px",
        borderRadius: "8px",
        backgroundColor: MetricsCardBackground,
        "@media (max-width: 600px)": {
          minWidth: 150,
          marginRight: "10px",
          marginBottom: "10px",
          borderRadius: "8px",
          backgroundColor: "gray",
        },
      }}
      onClick={onClick}
    >
      <Typography
        sx={{
          fontSize: 8,
          textAlign: "right",
          marginTop: "20px",
          paddingTop: 0,
          paddingLeft: "10px",
          paddingRight: "10px",
          marginBottom: "10px",
          marginLeft: "auto",
        }}
      >
        {format(new Date(callout.created_on), "MMMM dd yyyy HH:mm:ss")}
      </Typography>
      <Typography
        sx={{
          fontSize: 14,
          textAlign: "left",
          marginTop: "20px",
          paddingTop: 0,
          paddingLeft: "10px",
          marginBottom: "10px",
          "@media (max-width: 600px)": {
            fontSize: 10,
          },
        }}
      >
        {callout.type_of_emergency}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", paddingLeft: "10px" }}>
        <MapPinLine color={SideBarButtonTextColor} size={22} />
        <Typography
          sx={{
            fontSize: 12,
            textAlign: "left",
            marginTop: "20px",
            paddingTop: 0,
            paddingLeft: "10px",
          }}
        >
          {callout?.core_site?.name} ({callout.core_site?.site_id} )
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", paddingLeft: "10px" }}>
        <MapPinLine color={SideBarButtonTextColor} size={22} />
        <Typography
          sx={{
            fontSize: 12,
            textAlign: "left",
            marginTop: "20px",
            paddingTop: 0,
            paddingLeft: "10px",
          }}
        >
          {callout?.core_site?.municipality}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", paddingLeft: "10px" }}>
        <PoliceCar color={SideBarButtonTextColor} size={22} />
        <Typography
          sx={{
            fontSize: 12,
            textAlign: "left",
            marginTop: "20px",
            paddingTop: 0,
            paddingLeft: "10px",
            marginBottom: "20px",
          }}
        >
          {callout?.core_securitycalloutsstatuses?.[0]?.status}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "right", paddingLeft: "10px" }}>
        <ButtonGroup
          size="small"
          disableElevation
          variant="text"
          aria-label="Action Buttons"
        >
          <IconButton
            onClick={() => navigate(`/site/${callout?.site_id}?armed-response`)}
            aria-label="View Callout"
            size="small"
          >
            <Visibility color="secondary" />
          </IconButton>
          <CalloutChat callout={callout} />
          <CancelCalloutAction
            callout={callout}
            handleRefreshCallouts={handleRefreshCallouts}
          />
        </ButtonGroup>
      </Box>
    </Card>
  );
}
