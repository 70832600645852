import {Box, Grid} from "@mui/material";
import { useEventLogContext, useShuriPagination} from "../../../../../lib";
import {useEffect, useState} from "react";
import { useRectifierAlarms } from "../../../../../lib/hooks/rectifier-alarms";
import { IRectifierAlarmFilters } from "../../../../../lib/hooks/rectifier-alarms";
import { RectifierAlarmsTable } from "./RectifierAlarmTable";
export function EventLogRectifierAlarms() {
    const {eventlog} = useEventLogContext();
    const {page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = useShuriPagination(4);
    const [rectifierFilters, setRectifierFilters] = useState<IRectifierAlarmFilters>();

    const {
        data: rectifiers,
        refetch: refreshRectifiers
    } = useRectifierAlarms(rectifierFilters, page, rowsPerPage)

    useEffect(() => {
        if (Object.keys(eventlog).length !== 0) {
            refreshRectifiers()
            let created_at = new Date(eventlog.created_at)
            created_at.setHours(0, 0, 0, 999);
            setRectifierFilters({
                site_id: eventlog.site_id,
                created_at: created_at.toISOString()
            });
        }
    }, [eventlog, refreshRectifiers]);

    return (
        <Grid container spacing={2} sx={{
            paddingTop: '20px'
        }}>
            <Grid item xs={12}>
                <Box className="shuri-card-title sub">
                    Active Alarms
                </Box>
                <RectifierAlarmsTable
                    data={rectifiers}
                    handleChangePage={handleChangePage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Grid>
        </Grid>
    )
}