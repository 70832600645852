import {Box, Dialog, DialogContent, IconButton} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import {BaseColorLight} from "../../config";

interface IShuriImageDialogProps {
    images: string[]
    open: boolean;
    onClose: () => void
    title?: string;
    selected?: number
}

export function ShuriImageDialog({images, open, onClose, title, selected = 0}: IShuriImageDialogProps) {
    const [currentImageIndex, setCurrentImageIndex] = useState(selected);

    useEffect(()=>{
        setCurrentImageIndex(selected)
    }, [selected]);

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex: number) => (prevIndex + 1) % images.length);
    };

    const handlePrevImage = () => {
        setCurrentImageIndex((prevIndex: number) => (prevIndex - 1 + images.length) % images.length);
    };


    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth
        >
            <DialogContent sx={{
                padding: 0
            }}>
                <Box style={{
                    position: 'relative',
                    borderRadius: '8px',
                }}>
                    <img src={images[currentImageIndex]} alt={`Image ${currentImageIndex + 1}`}
                         style={{width: '100%', maxHeight: '800px'}}/>

                    {
                        title &&
                        <Box sx={{
                            width: '100%',
                            maxHeight: '100px',
                            padding: '10px',
                            color: BaseColorLight,
                        }}>
                            {title}
                        </Box>
                    }
                    <Box sx={{
                        width: '100%',
                        maxHeight: '100px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '10px 0'
                    }}>
                        <IconButton size="small" color="secondary" onClick={handlePrevImage}
                                    disabled={images.length === 1}>
                            <ChevronLeft/>Previous
                        </IconButton>
                        <IconButton size="small" color="secondary" onClick={handleNextImage}
                                    disabled={images.length === 1}>
                            Next<ChevronRight/>
                        </IconButton>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}