import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { Authenticator } from "@aws-amplify/ui-react";
import { ThemeProvider } from "@emotion/react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { ShuriAlertProvider } from "./lib/context";
import reportWebVitals from "./reportWebVitals";
import { AppTheme } from "./ui/config";
import { App } from "./ui/pages";

Amplify.configure(awsconfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={AppTheme}>
      <Authenticator.Provider>
        <ShuriAlertProvider>
          <App />
        </ShuriAlertProvider>
      </Authenticator.Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
