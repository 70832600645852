import {IVisit, formatDate} from "../../../../lib";
import TableHead from "@mui/material/TableHead";
import {ShuriTableRowStates, StyledTableCell, StyledTableRow} from "../../table";
import {IconButton, Table, TableBody} from "@mui/material";
import {ButtonBaseColor} from "../../../config";
import {ShuriStyledStatusChip} from "../../chip";
import {JobVisitsTableActions} from "./job-visits-table-actions";

interface IJobVisitsTableProps {
    visits: IVisit[]
}

export function JobVisitsTable({visits}: IJobVisitsTableProps) {
    return (
        <Table>
            <TableHead>
                <StyledTableRow className={"shuri-child-row-head"}>
                    <StyledTableCell style={{backgroundColor: 'inherit'}}/>
                    <StyledTableCell style={{backgroundColor: 'inherit'}}>#</StyledTableCell>
                    <StyledTableCell style={{backgroundColor: 'inherit'}}>Start Date</StyledTableCell>
                    <StyledTableCell style={{backgroundColor: 'inherit'}}>End Date</StyledTableCell>
                    <StyledTableCell style={{backgroundColor: 'inherit'}}>Status</StyledTableCell>
                    <StyledTableCell style={{backgroundColor: 'inherit'}} colSpan={2}>Comment</StyledTableCell>
                    <StyledTableCell style={{backgroundColor: 'inherit'}}>Actions</StyledTableCell>
                </StyledTableRow>
            </TableHead>
            <TableBody>
                <ShuriTableRowStates
                    colSpan={8}
                    data={visits ?? []}
                    loading={false}
                    error={""}
                >
                    <>
                        {
                            visits.map((visit: IVisit, index: number) => (
                                <StyledTableRow key={visit.id} className={"shuri-child-row"}>
                                    <StyledTableCell>
                                        <IconButton
                                            sx={{
                                                opacity: .3
                                            }}
                                            color="secondary"
                                            aria-label="expand row"
                                            size="small"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 fill={ButtonBaseColor} viewBox="0 0 256 256">
                                                <path
                                                    d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm45.66-93.66a8,8,0,0,1,0,11.32l-32,32a8,8,0,0,1-11.32-11.32L148.69,136H88a8,8,0,0,1,0-16h60.69l-18.35-18.34a8,8,0,0,1,11.32-11.32Z"></path>
                                            </svg>
                                        </IconButton>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {index + 1}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {formatDate(visit.start_datetime)}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {formatDate(visit.end_datetime)}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <ShuriStyledStatusChip
                                            label={
                                                visit?.core_visitstatusenum?.status ?? ''
                                            }
                                            color={visit?.core_visitstatusenum?.status === 'completed' ? 'success' : 'error'}
                                            size="small"
                                            variant="outlined"
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell colSpan={2}>
                                        {visit?.comment ?? ''}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <JobVisitsTableActions visit={visit}/>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        }
                    </>
                </ShuriTableRowStates>
            </TableBody>
        </Table>
    )
}