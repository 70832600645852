import {styled} from "@mui/material/styles";
import {TextField} from "@mui/material";
import {InputBaseColor, InputBaseColorLight} from "../../../config";

export const StyledFormTextField= styled(TextField)(({theme}) => ({
    '.MuiInputBase-root': {
        borderRadius: '8px',
        filter: 'drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05))',
        '&:hover': {
            'fieldset': {
                borderColor: InputBaseColor
            }
        },
        '&.Mui-focused': {
            'fieldset': {
                borderColor: InputBaseColor
            }
        },
        '.MuiInputBase-input': {
            color: InputBaseColorLight,
            '&::placeholder': {
                color: InputBaseColor,
                fontSize: '14px'
            }
        }
    },
    'fieldset': {
        borderColor: InputBaseColor
    },
    '.MuiFormHelperText-root': {
        marginLeft: 0
    }
}));