import {gql, useQuery} from "@apollo/client";

export const SITE_SIM_DATA = gql`
query MyQuery($site_id: bigint) {
    core_simcardstatus_aggregate(where: {core_simcard: {core_router: {core_box: {site_id: {_eq: $site_id}}}}}, order_by: {created_on: desc, simcard_id: desc}, distinct_on: simcard_id) {
      aggregate {
        sum {
          data_balance_in_mb
        }
      }
    }
} 
`;

export const useSiteSimData = (id: number) => {
    const {data, error, loading, refetch} = useQuery(SITE_SIM_DATA, {variables: {"site_id" : id}});
    return {data, error, loading, refetch};
    
};