import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

export const COMPANY_WIDE_SITE_CHARGE_UNITS = gql`
  query MyQuery($id: Int = 1) {
    getcompanywidesitechargecostandunitsbycompanyid(
      args: { v_company_id: $id }
    ) {
      cost
      units
      year_month
    }
  }
`;

export const lATEST_COMPANY_WIDE_SITE_CHARGE = gql`
  query MyQuery($id: Int = 1, $year_month: String) {
    getcompanywidesitechargecostandunitsbycompanyid(
      args: { v_company_id: $id }
      , distinct_on: year_month,
      where: { year_month: { _eq: $year_month } }
    ) {
      units
      cost
      year_month
    }
  }
`;

export const useCompanyWideSiteChargeUnits = (
  start_date?: any,
  end_date?: any
) => {
  const { data, error, loading } = useQuery(COMPANY_WIDE_SITE_CHARGE_UNITS, {
    variables: { start_date, end_date },
  });
  return { data, error, loading };
};

export const useLatestCompanyWideSiteChargeUnits = (
  year_month: string,
  id?: number
) => {
  const { data, error, loading } = useQuery(lATEST_COMPANY_WIDE_SITE_CHARGE, {
    variables: { year_month, id },
  });
  return { data, error, loading };
};
