import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import {perPage} from "../../../config";
import {getCurrentDate, getTomorrowDate} from "../../filters";

export const SITES_QUERY = gql`
    query($start_date : timestamptz, $end_date : timestamptz) {
        core_site{
            address
            id
            latitude
            longitude
            municipality
            name
            site_id
            is_enabled
            at_risk
            status_changed_at
            core_sitestatus {
                site_status
            }
            core_disruptions(limit: 12, distinct_on: start_date, where: { start_date: {_gte: $start_date}, end_date: {_lt: $end_date}}, 
            order_by: {start_date: desc}) {
                start_date
                end_date
            }
            core_sitechargeestimates_aggregate {
                aggregate {
                    max {
                        charge_period_end
                    }
                }
                nodes {
                    charge_period_end
                    charge_period_start
                    cost
                    total_units
                }
            }
            core_siteregion {
                region_name
            }
            core_disruptions_aggregate(limit: 12, distinct_on: start_date, where: { start_date: {_gte: $start_date}, end_date: {_lt: $end_date}}, 
                order_by: {start_date: desc}) {
                aggregate {
                    count
                }
            }
            core_metrics_aggregate {
                aggregate {
                  max {
                    created_on
                  }
                }
              }
              core_sitestates(order_by: {created_on: desc}, limit: 1) {
                site_id
                lastseen
                created_on
                core_sitestatus {
                  site_status
                  id
                }
                core_statereasons {
                  core_reasonenum {
                    id
                    reason
                  }
                }
              }

              
        }
    }
`

const siteFields = `
            id
            site_id
            name
            latitude
            longitude
            municipality
            up_since
            is_enabled
            at_risk
            status_changed_at
            core_sitestatus {
                site_status
            }
            core_sitesecuritycoverages {
                is_covered
                created_on
                id
                site_id
              }
            address
            core_siteregion {
                region_code
                region_name
            }
            core_metrics_aggregate {
                aggregate {
                  max {
                    created_on
                  }
                }
              }
              core_sitestates(order_by: {created_on: desc}, limit: 1) {
                site_id
                lastseen
                created_on
                core_sitestatus {
                  site_status
                  id
                }
                core_statereasons {
                  core_reasonenum {
                    id
                    reason
                  }
                }
              }
`;

export const SITES_PAGINATED = gql`
    query SITES_PAGINATED(
        $limit: Int
        $offset: Int
        $sitesFilters: core_site_bool_exp
    ) {
        core_site(
            where: $sitesFilters
            limit: $limit
            offset: $offset
            order_by: {id: asc}
        ) {
            ${siteFields}
        }
        core_site_aggregate(where: $sitesFilters) {
            aggregate {
                count
            }
        }
    }
`;

export const getFilters = ({site_id, name, region_name, municipality, site_status_id, is_disabled, at_risk}: ISitesFilters) => {
    let sitesFilters = {};
    /**
     * Apply site_id filter in sitesFilters object if it is supplied.
     * site_id value is not searched but uses equals comparator
     */
    sitesFilters = site_id ? {
        ...sitesFilters,
        site_id: {_ilike: `%${site_id}%`}
    } : sitesFilters;

    /**
     * Apply name filter in sitesFilters object if it is supplied.
     * name is searched.
     */
    sitesFilters = name ? {
        ...sitesFilters,
        name: {_ilike: `%${name}%`}
    } : sitesFilters;

    /**
     * Apply municipality filter in sitesFilters object if it is supplied.
     * municipality is searched using like.
     */
    sitesFilters = municipality ? {
        ...sitesFilters,
        municipality: {_ilike: `%${municipality}%`}
    } : sitesFilters;

    /**
     * Apply region_name filter in sitesFilters object if it is supplied.
     * region_name uses equal comparator.
     */
    sitesFilters = region_name ? {
        ...sitesFilters,
        core_siteregion: {region_name: {_ilike: `%${region_name}%`}}
    } : sitesFilters;

    /**
     * Apply site_status_id filter in sitesFilters object if it is supplied.
     * site_status_id uses equal comparator.
     */
    sitesFilters = site_status_id ? {
        ...sitesFilters,
        site_status_id: {_eq: site_status_id}
    } : sitesFilters;

    /**
     * Apply is_enabled filter in sitesFilters object if it is supplied.
     * is_enabled uses equal comparator.
     */
    sitesFilters = is_disabled ? {
        ...sitesFilters,
        is_enabled: {_eq: false}
    } : {
        ...sitesFilters,
        is_enabled: {_eq: true}
    };

       /**
     * Apply at_risk filter in sitesFilters object if it is supplied.
     * at_risk uses equal comparator.
     */
    sitesFilters = at_risk ? {
        ...sitesFilters,
        at_risk: {_eq: at_risk}
    } : sitesFilters
    
    return {
        ...sitesFilters,
    }
}

export const useSites = () => {
    const start_date = getCurrentDate()
    const end_date = getTomorrowDate()
    const {data, error, loading} = useQuery(SITES_QUERY, { variables: { start_date, end_date} });
    return {data, error, loading};
}

export interface ISitesFilters {
    site_id?: string;
    name?: string;
    municipality?: string;
    region_name?: string
    site_status_id? : number | string;
    is_disabled?: boolean;
    at_risk?: boolean;
}

export const useSitesPaginated = (page = 1, limit = perPage, filters: ISitesFilters = {}) => {
    const offset = page * limit;
    const {data, error, loading, refetch} = useQuery(SITES_PAGINATED, {
        variables: {
            offset,
            limit,
            sitesFilters: getFilters(filters)
        }
    });

    const updateSitesData = async (updateFilters: ISitesFilters) => {
        await refetch({
            offset,
            limit,
            sitesFilters: getFilters(updateFilters)
        });
    };


    return {data, error, loading, updateSitesData};
}