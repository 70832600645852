export const siteEquipmentStatusColor = (status: string) => {
    if (!status) return 'yellow';
    const word = status.split(" ")[0];
    switch (word) {
        case 'error':
            return 'red';
        case 'online':
            return 'green';
        case 'offline':
            return 'red';
        case 'faulty':
            return 'red';
        case 'damaged':
            return 'yellow';
        default:
            return 'yellow';
    }
}