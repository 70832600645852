import { ShuriStyledDrawer } from "../../../../components/drawer";
import { useNotification, getAuthData, getClaims } from "../../../../../lib";
import { Box, Button, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import {
  BaseColorLight,
  ButtonBaseColor,
  ButtonBaseColorLight,
  InputBaseColorLight,
} from "../../../../config";
import { Close } from "@mui/icons-material";
import { useState, useRef, useEffect } from "react";
import CreateRectiferAlarmForm from "./addAlarmPrioryForm";
import * as Yup from "yup";
import { useCreatePriorityMutation, useGetAlarmPriorities } from "../../../../../lib/hooks/notifications/notification.hooks";
import { useYupValidateForm } from "../../../../../lib/form/hooks";

interface IRectifierAlarmModel{
    alarm_id: number;
    alarm_name: string;
    equipment_with_alarms_id: string;
    priority_override: string;
    priority:string,
    id:number
}



interface ICreateAlarmPriority {
  addOpen: boolean;
  setAddOpen: (state: boolean) => void;
  currentAlarm?:IRectifierAlarmModel
}



const rectifierAlarmSchema = Yup.object().shape({
    alarm_id: Yup.string().required("alarm id is required"),
    alarm_name: Yup.string().required("alarm name is required"),
    model: Yup.string(),
    priority_override: Yup.string(),
  });

export function CreateRectifierAlarmPriority({
  addOpen,
  setAddOpen,
  currentAlarm
}: ICreateAlarmPriority) {
var modell:IRectifierAlarmModel|{}={}
const [model,setModel] = useState<IRectifierAlarmModel|{}>({})
  const [hasErrors, setHasErrors] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const {updateriority,loading} = useCreatePriorityMutation()
  const {refetch} = useGetAlarmPriorities()
  const {validateForm,errors}=useYupValidateForm(model,rectifierAlarmSchema)

  const handleSubmitting = async () => {
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      //model is empty on first click we n
      let override = formData.get('priority_override')
      let values ={
        id:currentAlarm?.id,
        priority_override:override
      }
      let resp= await updateriority(values)
      refetch()
      setAddOpen(false)
    }
  };
  return (
    <ShuriStyledDrawer
      anchor="right"
      open={addOpen}
      onClose={() => setAddOpen(false)}
    >
      <Box className="shuri-drawer-wrapper">
        <Box className="shuri-drawer-content">
          <Grid
            container
            spacing={2}
            sx={{
              paddingBottom: "30px",
            }}
          >
            <Grid item xs={10}>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "28px",
                  color: InputBaseColorLight,
                }}
              >
                Create Alarm Priority 
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: BaseColorLight,
                }}
              >
                Fill the form below to create a new alarm priority
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <IconButton
                aria-label="delete"
                size="large"
                color="secondary"
                onClick={() => setAddOpen(false)}
              >
                <Close fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>

          <CreateRectiferAlarmForm
            formRef = {formRef}
            errors={errors}
            intialData={currentAlarm}
          />
        </Box>
        <Box className="shuri-drawer-actions">
          <Button
            onClick={() => setAddOpen(false)}
            sx={{
              color: ButtonBaseColorLight,
              borderColor: ButtonBaseColor,
              textTransform: "capitalize",
            }}
            variant="outlined"
            size="large"
          >
            Cancel
          </Button>

          <Button
            sx={{
              textTransform: "capitalize",
            }}
            disabled={hasErrors}
            onClick={handleSubmitting}
            color="info"
            variant="contained"
            size="large"
            endIcon={loading&&<CircularProgress size={12}/>}
          >
            Save
          </Button>
        </Box>
      </Box>
    </ShuriStyledDrawer>
  );
}
