import {styled} from "@mui/material/styles";
import {Backdrop, Dialog} from "@mui/material";
import {AppBackgroundColor, BaseColorLight, InputBaseColorLight} from "../../config";

export const ShuriStyledDialog = styled(Dialog)(({theme}) => ({
    color: 'white',
    '.MuiDialog-paper': {
        background: AppBackgroundColor,
    },
    '.shuri-dialog-title': {
        color: InputBaseColorLight,
        fontSize: '18px',
        fontFamily: 'Outfit',
        fontWeight: '500',
        lineHeight: '28px',
        wordWrap: 'break-word',
        paddingBottom: '15px',
    },

    '.shuri-dialog-description': {
        color: BaseColorLight,
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '20px',
        wordWrap: 'break-word',
        display: 'flex',
        flexDirection: 'column'
    },
    '.shuri-dialog-actions': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

export const ShuriStyledDialogBackdrop = styled(Backdrop, {
    name: 'MuiModal', slot: 'Backdrop', overridesResolver: (props, styles) => {
        return styles.backdrop;
    },
})({
    zIndex: -1,
    background: 'rgba(25, 25, 26, 0.90)'
})