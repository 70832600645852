import {gql, useQuery} from "@apollo/client";

export const DASHBOARD_QUERY = gql`
query MyQuery($id:String!) {
    core_dashboard(where: {dashboard_id: {_eq: $id}}) {
      widget_name
      url
      dashboard_id
      id
    }
}
`

export const useGetDashboard = (id :any) => {
    const {data, error, loading} = useQuery(DASHBOARD_QUERY, {
        variables: {id}
    });
    return {data, error, loading};
}