import React, {ReactNode, useState} from "react";
import {StyledCompactTabs} from "./styled-tabs";
import {StyledCompactTab} from "./styled-tab";
import {Box} from "@mui/material";
import {ShuriTabPanel} from "./tab-panel";

export interface IShuriTab {
    title: string;
    component: ReactNode;
    skipTitle?: boolean
}

interface IShuriTabsProps {
    tabs: IShuriTab[];
    name: string
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function ShuriCompactTabs({tabs, name}: IShuriTabsProps) {
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <>
            <Box>
                <StyledCompactTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="Shuri compact styled tabs"
                >
                    {
                        tabs.map((tab, index) => {
                            if (!tab.skipTitle) return (
                                <StyledCompactTab
                                    key={`${name}-${index}-shuri-compact-tab`}
                                    label={tab.title}
                                    {...a11yProps(index)}  />
                            )
                        })
                    }
                </StyledCompactTabs>
            </Box>

            <Box>
                {
                    tabs.map((tab, index) => (
                        <ShuriTabPanel
                            key={`${name}-${index}-shuri-compact-tab-panel`}
                            value={value}
                            index={index}>
                            {tab.component}
                        </ShuriTabPanel>
                    ))
                }
            </Box>

        </>
    )
}