import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, {useEffect, useState } from "react";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import { primaryDark } from "../../../config";
import {
  ChevronRightOutlined,
  Close,
  TextSnippetOutlined,
} from "@mui/icons-material";
import { ShuriStyledFilterButtons } from "../../table";
import { useNavigate } from "react-router-dom";
import { calculateHoursDifference } from "./uitils";
import { Auth } from "aws-amplify";
import { useAppContext } from "../../../../lib/context/application";
import { useMutation } from "@apollo/client";
import { UPDATE_NOTIFICATION_MUTATION } from "../../../../lib/hooks/notifications/notification.hooks";
import { getCurrentDate } from "../../../../lib";

export default function Notifications(props: any) {
  const [open, setOpen] = useState(false);
  const [notiEl, setNotiEl] = useState(null as any);
  const [notiCount,setNotiCount] = useState(0)
  const [filter,setFilter] = useState<String>('unread')
  const navigate = useNavigate();
  const {notifications:appNotifications,updateNotificationData,notificationCount} = useAppContext()

  const handleNofiticationFilter = (value: any) => {
    setFilter(value);
    switch (value) {
      case "unread": {
        updateNotificationData(true)
        break;
      }
      case "read": {
        updateNotificationData(false)
        break;
      }
      case 'archieved':{
        console.log(value,'archieved')
        break;
      }
    }
  };
  const handleOpenNotifications = (event: any) => {
    setOpen(!open);
    setNotiEl(event?.target);
  };
  const handleViewLog = () => {
    navigate("/notifications");
  };
  useEffect(()=>{
    return()=>{
      handleNofiticationFilter("unread");
    }
  },[])
  return (
    <Box>
      <IconButton
        size="medium"
        aria-label="show 17 new notifications"
        color="info"
        sx={{
          backgroundColor: primaryDark,
          borderRadius: "8px",
        }}
        onClick={handleOpenNotifications}
      >
        <Badge badgeContent={filter==='unread'?notificationCount:0} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Popper open={open} anchorEl={notiEl} placement="bottom">
        <Paper sx={{ width: 350 }} elevation={0}>
          <ShuriList sx={{ width: "100%",maxHeight:'80vh',overflow:'auto' }}>
            <ListItemButton>
              <ListItemText
                sx={{ my: 0 }}
                primary="Notifications"
                primaryTypographyProps={{
                  fontSize: 20,
                  fontWeight: "medium",
                  letterSpacing: 0,
                }}
              />
              <IconButton onClick={() => handleOpenNotifications(null)}>
                <Close />
              </IconButton>
            </ListItemButton>
            <ListItem sx={{ padding: 2, justifyContent: "center" }}>
              <NotificationFilter status={filter} handleChangeFilter={(value:any)=>handleNofiticationFilter(value)} />
            </ListItem>
            <Divider />


            {appNotifications?.map((item: any, key: number) => (
              <EventNotification
                type={item.notification_type}
                date={item.created_at}
                message={item?.object_type}
                id={item.id}
                logId={item.object_id}
                key={key}
              />
            ))}
          </ShuriList>
          <ListItemButton
            onClick={() => handleViewLog()}
            sx={{ textAlign: "center" }}
          >
            <ListItemText primary={"View All Notifications"} />
          </ListItemButton>
        </Paper>
      </Popper>
    </Box>
  );
}

export const ShuriList = styled(List)<{ component?: React.ElementType }>({
  "& .MuiListItemButton-root": {
    paddingLeft: 24,
    paddingRight: 24,
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 16,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
});

export function NotificationFilter(props: {status:any,handleChangeFilter:any}) {
  return (
    <ShuriStyledFilterButtons
      variant="outlined"
      aria-label="outlined button group"
      color="secondary"
      size="large"
    >
      <Button
       onClick={()=>props.handleChangeFilter('unread')}
      className={props.status==='unread'?'selected':''}
      >
        Unread
        </Button>
      <Button onClick={()=>props.handleChangeFilter('read')} className={props.status==='read'?'selected':''}>Read</Button>
      <Button onClick={()=>props.handleChangeFilter('archieved')} className={props.status==='archieved'?'selected':''}>archieved</Button>
    </ShuriStyledFilterButtons>
  );
}
/**
 * Render Event Notification List item
 * @param props
 * @returns
 */
export const EventNotification = (props: {
  type: string;
  message?: string;
  date?: any;
  id: number;
  logId: any;
  key:any;
}) => {
  const navigate = useNavigate();
  const [updateEventLog] = useMutation(UPDATE_NOTIFICATION_MUTATION, {});



  const handleViewLog = (logId: any,object_type:any,notificationId:any) => {
    updateEventLog({
      variables: {
        id: notificationId,
        viewed_on: getCurrentDate()
      }
    })
    if(object_type==='core_eventlog'){

      navigate(`/event-log/${logId}`);
    }
    else if(object_type==='job'){
      navigate(`/jobs/${logId}/edit`);
    }
  };
  return (
    <>
      <ListItemButton
        onClick={() => handleViewLog(props.logId,props.message,props.id)}
        key={props.key}
        alignItems="flex-start"
      >
        <ListItemIcon>
        <IconButton sx={{backgroundColor: primaryDark}}>
          <TextSnippetOutlined  color={"primary"}/>
        </IconButton>
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography sx={{ fontWeight: "bolder" }}>{props.type}</Typography>
          }
          secondary={
            <Stack>
              <Typography sx={{ fontSize: "small" }}>
              {props.message==='core_eventlog'?'Event Log':props.message==='job'?'Job':'Notification'}
              </Typography>
            </Stack>
          }
        />
        <Stack rowGap={4}>
          <Typography sx={{ fontSize: "small" }}>
            {calculateHoursDifference(
              new Date(props.date),
              new Date()
            ).toString()}
          </Typography>
          <Box
            sx={{
              width: "100%",
              position: "relative",
              justifyContent: "right",
              justifyItems: "right",
            }}
          >
            <Link
              sx={{
                float: "right",
                fontWeight: 600,
                display: "flex",
              }}
            >
              View
              <ChevronRightOutlined />
            </Link>
          </Box>
        </Stack>
      </ListItemButton>
      <Divider variant="inset" component="li" />
    </>
  );
};
