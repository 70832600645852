import { StyledPageCard } from "../../page";
import {Grid} from "@mui/material";
import {LogSummary} from "./event-log-summary";

export function EventLogDetailsSection()
{
    return (
        <StyledPageCard>
           <Grid container spacing={2}>
               <Grid  item xs={12}>
                   <LogSummary />
               </Grid>
           </Grid>
        </StyledPageCard>
    )
}
