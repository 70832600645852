import gql from "graphql-tag";
import {useQuery} from "@apollo/client";

export const SITE_STATUS_QUERY = gql`
    query SITE_STATUS_QUERY {
        core_sitestatus {
            id
            site_status
          }
    }
`;

export const useSiteStatus = () => {
    const {data, error, loading} = useQuery(SITE_STATUS_QUERY);
    return {data, error, loading};
}