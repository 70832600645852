import React from "react";
import Box from "@mui/material/Box";
import SiteMetrics from "./site-metrics";
import {Content} from "../content";
import SiteHeadSection from "./SiteHeadSection";
import {Grid} from "@mui/material";
import SiteContainer from "./site-container";

export default function SiteComponent() {
    return (
        <Box sx={{paddingTop:{xs:5}}}>
            <SiteHeadSection/>
            <Content sx={{
                padding: 4,
                paddingTop: 0
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <SiteMetrics />
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <SiteContainer />
                    </Grid>
                </Grid>
            </Content>
            {/*<Content>*/}
            {/*    <Box sx={{*/}
            {/*        paddingLeft: '25px',*/}
            {/*        paddingRight: '25px'*/}
            {/*    }}>*/}
            {/*        <StyledTabs*/}
            {/*            value={value}*/}
            {/*            onChange={handleChange}*/}
            {/*            aria-label="styled tabs example"*/}
            {/*        >*/}
            {/*            <StyledTab sx={{color: 'white', padding: '8px 12px', boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)' }}  label="Site Metrics" {...a11yProps(0)}  />*/}
            {/*            <StyledTab sx={{color: 'white'}} label="Camera Feeds" {...a11yProps(1)} />*/}
            {/*        </StyledTabs>*/}
            {/*    </Box>*/}

            {/*    <TabPanel value={value} index={0}>*/}
            {/*        <SiteContainer*/}
            {/*            dashboard={SiteMetrics}*/}
            {/*            content={CustomizedTabs}*/}
            {/*        />*/}
            {/*    </TabPanel>*/}

            {/*    <TabPanel value={value} index={1}>*/}
            {/*        <SiteContainer*/}
            {/*            dashboard={SiteCameras}*/}
            {/*            content={CustomizedTabs}*/}
            {/*        />*/}
            {/*    </TabPanel>*/}
            {/*</Content>*/}
        </Box>
    )
}