import React, {ChangeEvent, DragEvent, useEffect, useRef, useState} from 'react';
import {Box, Button, IconButton, Typography} from "@mui/material";
import {AppDarkBackgroundColor, BaseColorLight, ButtonBaseColor} from "../../../config";
import {ShuriUploadPreviews} from "./shuri-upload-previews";
import {ShuriSpinnerText} from "../../loading";

interface IShuriFormUploadProps {
    onChange?: (files: FileList | null) => void;
    onDrop?: (files: FileList | null) => void;
    accept?: string;
    labelText?: string;
    has_preview?: boolean;
    values: string[] | null,
    uploading?: boolean
}

export function ShuriFormUpload({
                                    onChange,
                                    onDrop,
                                    accept = ".svg,.png,.jpg,.jpeg,.gif",
                                    labelText,
                                    values,
                                    has_preview = true,
                                    uploading = false
                                }: IShuriFormUploadProps) {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (onChange) {
            onChange(files);
        }
    };

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (onDrop) {
            onDrop(files);
        }
    };

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: 0,
                margin: 0
            }}
        >
            <Box
                sx={{
                    borderRadius: '8px',
                    border: `1px dashed ${AppDarkBackgroundColor}`,
                    background: AppDarkBackgroundColor,
                    padding: '16px 24px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    alignSelf: 'stretch',
                    flex: '1 0 0',
                    color: BaseColorLight,
                    fontSize: '12px',
                    cursor: 'pointer'
                }}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={handleClick}
            >
                <IconButton
                    onClick={handleClick}
                    sx={{
                        background: ButtonBaseColor
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg"
                         width="28"
                         height="28"
                         fill="#fff" viewBox="0 0 256 256">
                        <path
                            d="M248,128a87.34,87.34,0,0,1-17.6,52.81,8,8,0,1,1-12.8-9.62A71.34,71.34,0,0,0,232,128a72,72,0,0,0-144,0,8,8,0,0,1-16,0,88,88,0,0,1,3.29-23.88C74.2,104,73.1,104,72,104a48,48,0,0,0,0,96H96a8,8,0,0,1,0,16H72A64,64,0,1,1,81.29,88.68,88,88,0,0,1,248,128Zm-90.34-5.66a8,8,0,0,0-11.32,0l-32,32a8,8,0,0,0,11.32,11.32L144,147.31V208a8,8,0,0,0,16,0V147.31l18.34,18.35a8,8,0,0,0,11.32-11.32Z"></path>
                    </svg>
                </IconButton>
                <Typography
                    sx={{
                        fontSize: '12px'
                    }}
                ><Button sx={{
                    color: 'white',
                    textTransform: 'capitalize'
                }}>Click to upload</Button> or drag and drop</Typography>
                <Typography sx={{
                    fontSize: '12px'
                }}>SVG, PNG, JPG or GIF (max. 800x400px)</Typography>
                <input
                    ref={fileInputRef}
                    type="file"
                    accept={accept}
                    style={{display: 'none'}}
                    onChange={handleFileUpload}
                    multiple
                />
            </Box>

            {has_preview &&
                <Box sx={{
                    paddingTop: '6px'
                }}>
                    <ShuriUploadPreviews previews={values ?? []}/>
                </Box>
            }

            {uploading &&
                <Box sx={{
                    paddingTop: '6px'
                }}>
                    <ShuriSpinnerText loadingText={"Uploading images, ☕ please wait..."}
                                      loadingTextSx={{
                                          color: BaseColorLight
                                      }} />
                </Box>
            }

        </Box>
    )
}