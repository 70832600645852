import {API} from 'aws-amplify'
import {useEffect, useState} from "react";
import {useNotification} from "../../context";
import {getAuthData} from "../../authData";

interface ILiveFeedControlHooksRequest {
    stream_name: string,
    action: string,
    site_id: string
}

export const useLiveFeedControlsHooks = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const {notify} = useNotification();


    const toggleStream = async (data: ILiveFeedControlHooksRequest) => {
        if (data.action !== 'keep-alive'){
            setLoading(true);
        }
        try {
            const {idToken} = await getAuthData();
            const token = idToken.jwtToken;
            const {action, stream_name, site_id} = data;
            const requestData = {
                headers: {
                    Authorization: token ? `Bearer ${token}` : "",
                },
                body: {
                    "action": action,
                    "stream_name": stream_name,
                    "site_id": site_id
                },
            };

            await API.post(
                "shurikonektfeapi",
                "/stream-control",
                requestData
            );
        } catch (e) {
            const message = "Could not invoke stream control action, an error occurred";
            setError(message);
            notify({
                open: true,
                status: 'error',
                message
            });
        } finally {
            setLoading(false)
        }
    }

    return {toggleStream, loading, error }
}