import gql from "graphql-tag";
import { ApolloClient } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { getStartAndEndDateTime } from "../../helpers/dateformatters";
import { getCurrentDate } from "../../filters";
import { useState } from "react";

const siteFields = `
            id
            site_id
            name
            latitude
            longitude
            municipality
            up_since
            is_enabled
            status_changed_at
            core_sitestatus {
                site_status
            }
            core_sitesecuritycoverages {
                is_covered
                created_on
                id
                site_id
              }
            address
            core_siteregion {
                region_code
                region_name
            }
            core_metrics_aggregate {
                aggregate {
                  max {
                    created_on
                  }
                }
              }
            core_sitestates(limit: 1, order_by: {lastseen: desc_nulls_last}) {
              status_id
              site_id
              lastseen
              id
              created_on
              core_sitestatus {
                site_status
              }
              core_statereasons {
                state_id
                created_on
                core_reasonenum {
                  reason
                }
              }
            }
      
`;
export const COMBINED_SITE_QUERY = gql`
  query CombinedQueries(
    $_gte: timestamptz = ""
    $_lte: timestamptz = ""
    $_gte_detections: timestamp = ""
    $_lte_detections: timestamp = "",
  ) {
    core_site(
      where: {
        _or: [
          {
            core_boxes: {
              core_routers: {
                core_simcards: {
                  core_simcardstatuses: {
                    data_balance_in_mb: { _lte: "10000" }
                    created_on: { _gte: $_gte, _lte: $_lte }
                  }
                }
              }
            }
          }
          {
            core_detections_aggregate: {
              count: {
                predicate: { _gt: 10 }
                filter: {
                  created_on: { _gte: $_gte_detections, _lte: $_lte_detections }
                }
              }
            }
          }
          { core_disruptions: { start_date: { _gte: $_gte, _lte: $_lte } } }
          {
            core_rectifieralarms_aggregate: {
              count: {
                predicate: { _gte: 10 }
                filter: {
                  alarm_state: { _eq: "Active" }
                  created_on: { _gte: $_gte, _lte: $_lte }
                }
              }
            }
          }
        ]
      }
    ){
      site_id
      id
    }
  }
`;

const ATRISKQUERY = gql`
query CombinedQueries($_gte: timestamptz = "", $_lte: timestamptz = "", $_gte_detections: timestamp = "", $_lte_detections: timestamp = "",$limit: Int = 10, $offset: Int = 10) {
    core_site(where: {_or: [{core_boxes: {core_routers: {core_simcards: {core_simcardstatuses: {data_balance_in_mb: {_lte: "10000"}, created_on: {_gte: $_gte, _lte: $_lte}}}}}}, {core_detections_aggregate: {count: {predicate: {_gt: 10}, filter: {created_on: {_gte: $_gte_detections, _lte: $_lte_detections}}}}}, {core_disruptions: {start_date: {_gte: $_gte, _lte: $_lte}}}, {core_rectifieralarms_aggregate: {count: {predicate: {_gte: 10}, filter: {alarm_state: {_eq: "Active"}, created_on: {_gte: $_gte, _lte: $_lte}}}}}]}, limit: $limit, offset: $offset) {
        ${siteFields}
    }
  core_site_aggregate(where: {_or: [{core_boxes: {core_routers: {core_simcards: {core_simcardstatuses: {data_balance_in_mb: {_lte: "10000"}, created_on: {_gte: $_gte, _lte: $_lte}}}}}}, {core_detections_aggregate: {count: {predicate: {_gt: 10}, filter: {created_on: {_gte: $_gte_detections, _lte: $_lte_detections}}}}}, {core_disruptions: {start_date: {_gte: $_gte, _lte: $_lte}}}, {core_rectifieralarms_aggregate: {count: {predicate: {_gte: 10}, filter: {alarm_state: {_eq: "Active"}, created_on: {_gte: $_gte, _lte: $_lte}}}}}]}) {
    aggregate {
        count
    }
}
  }
`;

const ONLINESITESQUERY = gql`
query MyQuery7($_gte: timestamptz = "", $_lte: timestamptz = "",$limit: Int = 10, $offset: Int = 10) {
    core_site(where: {core_metrics_aggregate: {count: {predicate: {_gte: 1}, filter: {created_on: {_gte: $_gte, _lte: $_lte}}}}}, limit: $limit, offset: $offset) {
        ${siteFields}
    }
    core_site_aggregate(where: {core_metrics_aggregate: {count: {predicate: {_gte: 1}, filter: {created_on: {_gte: $_gte, _lte: $_lte}}}}}) {
      aggregate {
          count
      }
  }
  }`;

const OFFLINESITESQUERY = gql`
  query MyQuery8($_gte: timestamptz = "", $_lte: timestamptz = "",$limit: Int = 10, $offset: Int = 10) {
    core_site(where: {core_metrics_aggregate: {count: {predicate: {_eq: 0}, filter: {created_on: {_gte: $_gte, _lte: $_lte}}}}}, limit: $limit, offset: $offset) {
        ${siteFields}
    }
    core_site_aggregate(where: {core_metrics_aggregate: {count: {predicate: {_eq: 0}, filter: {created_on: {_gte: $_gte, _lte: $_lte}}}}}) {
      aggregate {
          count
      }
  }
  }`;
  const DISABLEDSITESQUERY = gql`
    query MyQuery8($_gte: timestamptz = "", $_lte: timestamptz = "",$limit: Int = 10, $offset: Int = 10) {
      core_site(where: {core_metrics_aggregate: {count: {predicate: {_eq: 0}, filter: {created_on: {_gte: $_gte, _lte: $_lte}}}}, is_enabled: {_eq: false}}, limit: $limit, offset: $offset) {
       ${siteFields}
     }
     core_site_aggregate(where: {core_metrics_aggregate: {count: {predicate: {_eq: 0}, filter: {created_on: {_gte: $_gte, _lte: $_lte}}}}}) {
       aggregate {
           count
       }
   }
   }`;
export const useSiteAggrigation = () => {
  const currentdate = getCurrentDate();
  const { created_gte, created_lte } = getStartAndEndDateTime(
    new Date(currentdate)
  );
  const variables = {
    _lte: created_lte,
    _gte: created_gte,
    _gte_detections: created_gte,
    _lte_detections: created_lte,
  };
  const { loading, error, data, refetch } = useQuery(COMBINED_SITE_QUERY, {
    variables: variables,
  });
  return { loading, error, data };
};

export const useSiteExplorer = (client: ApolloClient<any>,limit:number,page:number) => {
  const [error, setError] = useState<any>(null);
  const [data,setData]= useState<any>(null);
  const currentdate = getCurrentDate();
  const { created_gte, created_lte } = getStartAndEndDateTime(
    new Date(currentdate)
  );
  const allAtRisk = async (offset: number) => {
    try {
      const variables = {
        _lte: created_lte,
        _gte: created_gte,
        _gte_detections: created_gte,
        _lte_detections: created_lte,
        limit: limit, 
        offset: offset
      };
      setData(null)
      const { data:results } = await client.query<any, any>({
        query: ATRISKQUERY,
        variables
      });
      setData(results)
    } catch (error) {
      setError(error);
    }
  };
  const allOnline = async (offset: number) => {
    let variables = {
      _lte: created_lte,
      _gte: created_gte,
      limit: limit, 
      offset: offset
    };
    try {
      setData(null)
      const { data:results } = await client.query<any, any>({
        query: ONLINESITESQUERY,
        variables,
      });
      setData(results)
    } catch (error) {
      setError(error);
    }
  };
  const offlinesites=async(offsett:number)=>{
    let variables = {
        _lte: created_lte,
        _gte: created_gte,
        limit: limit, 
        offset: offsett
      };
      try {
        setData(null)
        const { data:results } = await client.query<any, any>({
          query: OFFLINESITESQUERY,
          variables,
        });
        setData(results)
      } catch (error) {
        setError(error);
      }
  }
  const allDisabledSites=async(offsett:number)=>{
    let variables = {
        _lte: created_lte,
        _gte: created_gte,
        limit: limit, 
        offset: offsett
      };
      try {
        setData(null)
        const { data:results } = await client.query<any, any>({
          query: DISABLEDSITESQUERY,
          variables,
        });
        setData(results)
      } catch (error) {
        setError(error);
      }
  }
  return { allOnline, allAtRisk,offlinesites,allDisabledSites, error ,data};
};
