import {IFilter, IFilterOption, IFilterSearchResults} from "../../../lib/interfaces";
import {ShuriTableFilterSearch} from "../table";
import {useEffect, useState} from "react";
import {formatDate} from "../../../lib/filters";
import {primaryBlue} from "../../config";

interface IEventLogsSearchFilter {
    logs: any[],
    onFilterUpdate: (filter: IFilter) => void
}

const filter_options: IFilterOption[] = [
    {
        label: 'Log Name',
        value: 'event_name'
    },
    {
        label: 'Log Type',
        value: 'event_log_type'
    }
];

export function EventLogsSearchFilter({logs, onFilterUpdate}: IEventLogsSearchFilter)
{
    const [results, setResults] = useState<IFilterSearchResults[]>([]);

    useEffect(()=>{
        return setResults(
            logs.map(log => ({
                link: `/event-log/${log.id}`,
                title: `${log.event_name} - ${log.id}`,
                unique: log.id,
                sub1: {
                    label: `${formatDate(log.created_at)}`,
                    icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={primaryBlue} viewBox="0 0 256 256"><path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-96-88v64a8,8,0,0,1-16,0V132.94l-4.42,2.22a8,8,0,0,1-7.16-14.32l16-8A8,8,0,0,1,112,120Zm59.16,30.45L152,176h16a8,8,0,0,1,0,16H136a8,8,0,0,1-6.4-12.8l28.78-38.37A8,8,0,1,0,145.07,132a8,8,0,1,1-13.85-8A24,24,0,0,1,176,136,23.76,23.76,0,0,1,171.16,150.45Z"></path></svg>
                },
                sub2: {
                    label: log.event_log_type,
                    icon: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={primaryBlue} viewBox="0 0 256 256"><path d="M224,64H154.67L126.93,43.2a16.12,16.12,0,0,0-9.6-3.2H72A16,16,0,0,0,56,56V72H40A16,16,0,0,0,24,88V200a16,16,0,0,0,16,16H192.89A15.13,15.13,0,0,0,208,200.89V184h16.89A15.13,15.13,0,0,0,240,168.89V80A16,16,0,0,0,224,64ZM192,200H40V88H85.33l27.74,20.8a16.12,16.12,0,0,0,9.6,3.2H192Zm32-32H208V112a16,16,0,0,0-16-16H122.67L94.93,75.2a16.12,16.12,0,0,0-9.6-3.2H72V56h45.33l27.74,20.8a16.12,16.12,0,0,0,9.6,3.2H224Z"></path></svg>
                }
            }))
        );
    }, [logs]);

    return (
        <>
            <ShuriTableFilterSearch
                filter_options={filter_options}
                results={results}
                onFilterUpdate={onFilterUpdate}/>
        </>
    )
}