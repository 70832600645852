export const STATIC_VISITS = [
    {
        id: 1,
        core_job: {
            id: 1,
            ext_job_number: 'ASJN75SBJ2',
            description: 'Planned Service',
            core_jobstatus: {
                job_status: 'Completed'
            },
            core_personnelassigneds: {
                core_noneuserpersonale: {
                    first_name: 'Andy',
                    last_name: 'Songo'
                }
            }
        }
    },
    {
        id: 1,
        core_job: {
            id: 1,
            ext_job_number: 'T2JZ5SBJ2',
            description: 'Planned Inspection',
            core_jobstatus: {
                job_status: 'In Progress'
            },
            core_personnelassigneds: {
                core_noneuserpersonale: {
                    first_name: 'Bob',
                    last_name: 'Ross'
                }
            }
        }
    }
]

export const STATIC_CAMERAS = [
    {
        id: 1,
        serial_number: '2A3QW4EES5',
        video_stream_uri: 'https://cdn.coverr.co/videos/coverr-wind-turbines-in-the-field-7853/1080p.mp4',
        box_id: 21,
        audio_stream_uri: 'https://youtu.be/R_IaXwGzLho',
        thumbnail_url: 'https://images.unsplash.com/photo-1590856029826-c7a73142bbf1?w=200',
        camera_id: "1",
        camera_name: "Entrance Camera",
        video_stream_name: "CAF3T_AxisCamera_C9B7EFB8A5D3_livestream",
    },
    {
        id: 2,
        serial_number: '2A3QW4EES5',
        video_stream_uri: 'https://cdn.coverr.co/videos/coverr-wind-turbines-in-a-field-5831/1080p.mp4',
        box_id: 21,
        audio_stream_uri: 'https://youtu.be/R_IaXwGzLho',
        thumbnail_url: 'https://images.unsplash.com/photo-1590856029826-c7a73142bbf1?w=200',
        camera_id: "2",
        camera_name: "Lobby Camera",
        video_stream_name: "PKL7T_HikvisionCam_9E3FEDC6B7A4_livestream",
    },
    {
        id: 3,
        serial_number: '2A3QW4EES5',
        video_stream_uri: 'https://cdn.coverr.co/videos/coverr-a-skyscraper-in-panama-city-8854/1080p.mp4',
        box_id: 21,
        audio_stream_uri: 'https://youtu.be/R_IaXwGzLho',
        thumbnail_url: 'https://images.unsplash.com/photo-1590856029826-c7a73142bbf1?w=200',
        camera_id: "3",
        camera_name: "Conference Room Camera",
        video_stream_name: "CNF5T_SonyCamera_A1C3EFB7D5A1_livestream",
    },
    {
        id: 4,
        serial_number: '2A3QW4EES5',
        video_stream_uri: 'https://cdn.coverr.co/videos/coverr-villas-by-the-beach-1963/1080p.mp4',
        box_id: 21,
        audio_stream_uri: 'https://youtu.be/R_IaXwGzLho',
        thumbnail_url: 'https://images.unsplash.com/photo-1590856029826-c7a73142bbf1?w=200',
        camera_id: "4",
        camera_name: "Parking Lot Camera",
        video_stream_name: "LBB12_PanasonicCam_2F5DEBC8AB72_livestream",
    },

    {
        serial_number: '2A3QW4EES5',
        video_stream_uri: 'https://cdn.coverr.co/videos/coverr-villas-by-the-beach-1963/1080p.mp4',
        box_id: 22,
        audio_stream_uri: 'https://youtu.be/R_IaXwGzLho',
        thumbnail_url: 'https://images.unsplash.com/photo-1590856029826-c7a73142bbf1?w=200',
        camera_id: "6",
        camera_name: "Hallway Camera",
        video_stream_name: "HLW2T_PanasonicCam_4D7AEBE2C6F1_livestream",
    },
    {
        serial_number: '2A3QW4EES5',
        video_stream_uri: 'https://cdn.coverr.co/videos/coverr-villas-by-the-beach-1963/1080p.mp4',
        box_id: 23,
        audio_stream_uri: 'https://youtu.be/R_IaXwGzLho',
        thumbnail_url: 'https://images.unsplash.com/photo-1590856029826-c7a73142bbf1?w=200',
        camera_id: "7",
        camera_name: "Staircase Camera",
        video_stream_name: "STR8T_HikvisionCam_3A5BDCB8E6F7_livestream",
    },
    {
        serial_number: '2A3QW4EES5',
        video_stream_uri: 'https://cdn.coverr.co/videos/coverr-villas-by-the-beach-1963/1080p.mp4',
        box_id: 24,
        audio_stream_uri: 'https://youtu.be/R_IaXwGzLho',
        thumbnail_url: 'https://images.unsplash.com/photo-1590856029826-c7a73142bbf1?w=200',
        camera_id: "8",
        camera_name: "Elevator Camera",
        video_stream_name: "ELV1T_AxisCamera_7B5EFB3A9D1C2_livestream",
    },
    {
        serial_number: '2A3QW4EES5',
        video_stream_uri: 'https://cdn.coverr.co/videos/coverr-villas-by-the-beach-1963/1080p.mp4',
        box_id: 25,
        audio_stream_uri: 'https://youtu.be/R_IaXwGzLho',
        thumbnail_url: 'https://images.unsplash.com/photo-1590856029826-c7a73142bbf1?w=200',
        camera_id: "9",
        camera_name: "Office Camera 1",
        video_stream_name: "OFC9T_SonyCamera_F7B5EDA3C6D9_livestream",
    },
    {
        serial_number: '2A3QW4EES5',
        video_stream_uri: 'https://cdn.coverr.co/videos/coverr-villas-by-the-beach-1963/1080p.mp4',
        box_id: 26,
        audio_stream_uri: 'https://youtu.be/R_IaXwGzLho',
        thumbnail_url: 'https://images.unsplash.com/photo-1590856029826-c7a73142bbf1?w=200',
        camera_id: "10",
        camera_name: "Office Camera 2",
        video_stream_name: "OFC10T_FoscamCamera_1B5F3E6D7A9C8_livestream",
    },
]

export const defaultThumbnail = 'https://images.unsplash.com/photo-1590856029826-c7a73142bbf1?w=200'


export const STATIC_JOBS = [
    {
        created_on: "2023-06-12",
        description: "Replace power adaptor on Nokia switch",
        ext_job_number: "job-001",
        id: 1,
        job_name: 'Site Visit',
        status: 'Open',
        site_id: 7,
        core_site: {
          name: "Laerskool Parksig",
          site_id: "THB007"
        },
        core_jobstatuses: [],
        core_visits: [
          {
            id: 16,
            comment: "Please be sure to get the REF",
            start_datetime: "2023-07-13",
            end_datetime: "2023-07-13",
            visit_name: 'Site Visit',
            status: 'In Progress'
          }
        ],
        core_personnelassigneds: [
          {
            core_user: {
              first_name: "Palabadi",
              last_name: "Mahlaba"
            }
          }
        ]
    },
    {
        created_on: "2023-06-13",
        description: "Replace power adaptor on Nokia switch",
        ext_job_number: "job-001",
        id: 2,
        job_name: 'Site Visit',
        status: 'Closed',
        site_id: 7,
        core_site: {
          name: "Laerskool Parksig",
          site_id: "THB007"
        },
        core_jobstatuses: [],
        core_visits: [
          {
            id: 3,
            comment: "Please be sure to get the REF",
            start_datetime: "2023-06-12",
            end_datetime: "2023-06-13",
            visit_name: 'Site Visit',
            status: 'In Progress'
          }
        ],
        core_personnelassigneds: [
          {
            core_user: {
              first_name: "Palabadi",
              last_name: "Mahlaba"
            }
          }
        ]
    },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Open',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Closed',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'open',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Open',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Closed',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Closed',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Open',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Open',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Closed',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Open',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Closed',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Closed',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Closed',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Closed',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Closed',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Closed',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Closed',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Open',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Closed',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Open',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Open',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Open',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Closed',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Closed',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
    // {
    //     created_on: "2023-04-17",
    //     description: "Replace power adaptor on Nokia switch",
    //     ext_job_number: "job-001",
    //     id: 24,
    //     job_name: 'Site Visit',
    //     status: 'Open',
    //     site_id: 7,
    //     core_site: {
    //       name: "Laerskool Parksig",
    //       site_id: "THB007"
    //     },
    //     core_jobstatuses: [],
    //     core_visits: [
    //       {
    //         id: 16,
    //         comment: "Please be sure to get the REF",
    //         start_datetime: "2023-04-18",
    //         end_datetime: "2023-04-18",
    //         visit_name: 'Site Visit',
    //         status: 'In Progress'
    //       }
    //     ],
    //     core_personnelassigneds: [
    //       {
    //         core_user: {
    //           first_name: "Palabadi",
    //           last_name: "Mahlaba"
    //         }
    //       }
    //     ]
    // },
]