import {
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import {
  HoverSideBarButtonColor,
  HoverSideBarButtonTextColor,
  SideBarButtonTextColor,
} from "../../config";
import { useState } from "react";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

export interface SideMenuItemProps {
  label?: string;
  link?: string;
  icon?: React.ReactNode;
  children?: any[];
}

export function CollapsableMenuItem(props: SideMenuItemProps) {
  const { pathname } = useLocation();
  const [isExpand, setIsexpand] = useState(false);
  const isActive = pathname === props.link;
  const handleExpand = () => {
    setIsexpand(!isExpand);
  };

  return (
    <>
      <ListItemButton
        component={Link}
        to={"#"}
        onClick={(event) => {
          event.preventDefault();
          handleExpand();
        }}
        sx={{
          background: isExpand ? HoverSideBarButtonColor : undefined,
          marginBottom: "15px",
          marginLeft: "12px",
          color: "white",
          height: 35,
          cursor: "pointer",
          "&:hover": {
            background: HoverSideBarButtonColor,
          },
          borderRadius: "30px",
        }}
      >
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText
          sx={{
            color: SideBarButtonTextColor,
            fontFamily: "Outfit",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            "&:hover": { color: HoverSideBarButtonTextColor },
          }}
          primary={props.label}
        />
        {isExpand ? <ArrowDropUp /> : <ArrowDropDown />}
      </ListItemButton>
      <Collapse in={isExpand} sx={{ ml: 5 }}>
        {props.children?.map((item) => (
          <ListItemButton
            key={item.link}
            component={Link}
            to={item?.link!}
            sx={{
                background: pathname === item.link ? HoverSideBarButtonColor : undefined,
              marginBottom: "15px",
              marginLeft: "12px",
              color: "white",
              height: 35,
              cursor: "pointer",
              "&:hover": {
                background: HoverSideBarButtonColor,
              },
              borderRadius: "30px",
              
            }}
          >
            <ListItemIcon sx={{minWidth:'25px'}}>{item?.icon}</ListItemIcon>
            <ListItemText
              sx={{
                color: SideBarButtonTextColor,
                fontFamily: "Outfit",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                "&:hover": { color: HoverSideBarButtonTextColor },
              }}
              primary={item?.label}
            />
          </ListItemButton>
        ))}
      </Collapse>
    </>
  );
}
