import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { perPage } from "../../../config";
import { JobStatusEnum } from "../../enums";

export interface IJobsFilters {
  site_name?: string;
  name?: string;
  ext_job_number?: string;
  worker?: string;
  status?: JobStatusEnum | string;
}

export const JOBS_QUERY = gql`
  query JOBS_QUERY($limit: Int, $offset: Int, $jobFilters: core_job_bool_exp) {
    core_job(
      where: $jobFilters
      limit: $limit
      offset: $offset
      order_by: { created_on: desc }
    ) {
      id
      created_on
      description
      ext_job_number
      site_id
      core_site {
        id
        site_id
        name
      }
      core_jobstatuses(order_by: { created_on: desc }, limit: 1) {
        id
        job_id
        status_id
        comment
        core_jobstatusenum {
          id
          status
        }
      }
      core_visits {
        id
        job_id
        status_id
        start_datetime
        end_datetime
        core_visitstatusenum {
          status
          id
        }
        comment
      }
      core_personnelassigneds(order_by: { id: desc }, limit: 1) {
        core_user {
          id
          email
          first_name
          last_name
        }
      }
      core_resjobstatus {
        status
        job_id
        description
        created_on
        status_id
        comment
      }
    }
    core_job_aggregate(where: $jobFilters) {
      aggregate {
        count
      }
    }
  }
`;

export const getJobFilters = ({
  site_name,
  name,
  worker,
  ext_job_number,
  status
}: IJobsFilters) => {
  let jobFilters = {};

  /**
   * Apply site_name filter in jobFilters object if it is supplied.
   * The name should contain the site_name (case-insensitive search).
   */
  jobFilters = site_name
    ? {
        ...jobFilters,
        core_site: { name: { _ilike: `%${site_name}%` } }
      }
    : jobFilters;

  /**
   * Apply name filter in jobFilters object if it is supplied.
   * The name should contain the provided name (case-insensitive search).
   */
  jobFilters = name
    ? {
        ...jobFilters,
        name: { _ilike: `%${name}%` }
      }
    : jobFilters;

  /**
   * Apply worker filter in jobFilters object if it is supplied.
   * The worker's first_name or last_name should contain the provided worker (case-insensitive search).
   */
  jobFilters = worker
    ? {
        ...jobFilters,
        core_personnelassigneds: {
          core_user: {
            _or: [
              { first_name: { _like: `%${worker}%` } },
              { last_name: { _ilike: `%${worker}%` } }
            ]
          }
        }
      }
    : jobFilters;

  /**
   * Apply ext_job_number filter in jobFilters object if it is supplied.
   * The ext_job_number should contain the provided number (case-insensitive search).
   */
  jobFilters = ext_job_number
    ? {
        ...jobFilters,
        ext_job_number: { _ilike: `%${ext_job_number}%` }
      }
    : jobFilters;

  /**
   * Apply status filter in jobFilters object if it is supplied.
   * The status should contain the provided status either Open or Closed.
   */
  jobFilters = status
    ? {
        ...jobFilters,
        core_resjobstatus: {
          status: { _eq: status }
        }
      }
    : jobFilters;

  return {
    ...jobFilters
  };
};

export const useJobs = (
  page = 1,
  limit = perPage,
  filters: IJobsFilters = {}
) => {
  const offset = page * limit;
  const { data, error, loading, refetch } = useQuery(JOBS_QUERY, {
    variables: {
      offset,
      limit,
      jobFilters: getJobFilters(filters)
    }
  });

  const updateJobsData = async (updateFilters: IJobsFilters) => {
    await refetch({
      jobFilters: getJobFilters(updateFilters)
    });
  };
  return { data, error, loading, updateJobsData };
};
